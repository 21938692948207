import { makeStyles } from "@material-ui/core";
import { title } from "../common/index.styles";
import { backgroundAvatar } from "./menu/index.styles";

export default makeStyles(
  theme => {
    const tVisibility = theme.transitions.create("visibility", { duration: 0 });
    const tOpacity = theme.transitions.create("opacity", { duration: theme.transitions.duration.complex });
    return {
      root: { padding: theme.spacing(2) },
      row: { marginBottom: theme.spacing(2) },
      paper: { padding: theme.spacing(2.75, 5.5), height: "100%" },
      ul: { paddingLeft: 15 },
      title: title(theme),
      mb0: { marginBottom: 0 },
      backgroundAvatar: {
        ...backgroundAvatar,
        marginBottom: theme.spacing(3)
      },
      userAvatar: { width: 106, height: 106, fontSize: 50, backgroundColor: "#FFFFFF", color: "#004763" },
      line: { width: 30, height: 15, borderBottom: "solid 1px #D2D5D5" },
      uploadButtons: { margin: theme.spacing(1, 0, 4, 0) },
      reset: { textAlign: "center" },
      input: { paddingRight: "180px !important" },
      photoBox: {
        position: "relative",
        overflow: "hidden",
        "&:after": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          visibility: "hidden",
          opacity: 0,
          transition: `${tVisibility}, ${tOpacity}`
        }
      },
      hover: {
        "&[class*=my-profile-photoBox]:after": {
          visibility: "visible",
          opacity: 1
        }
      },
      error: {
        "&[class*=my-profile-photoBox]:after": {
          backgroundColor: "rgba(221,75,57,0.3) !important"
        }
      }
    };
  },
  { name: "my-profile" }
);
