import { Paper } from "@material-ui/core";
import React, { useState, Fragment, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FileNameIcon } from "../../components/common/documents/FileIcon";
import LinkButton from "../../components/common/LinkButton";
import Loading from "../../components/common/Loading";
import MaterialTable from "../../components/common/table/MaterialTable";
import useViewer from "../../components/common/viewer/useViewer";
import { loadPendingVendors, openFile } from "../../redux/actions/vendor";

const List = () => {
  const store = useSelector(({ vendor }) => ({ vendor }), shallowEqual);
  const {
    vendor: { pending: vendors }
  } = store || {};
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const hideLoading = () => setLoading(false);
  useEffect(() => {
    setLoading(true);
    dispatch(loadPendingVendors(hideLoading, hideLoading));
  }, []);
  const { onViewFile, fileViewer } = useViewer({
    dispatchAction: p => {
      setLoading(true);
      dispatch(openFile({ ...p, onSuccess: hideLoading, onError: hideLoading }));
    }
  });
  const tableProps = {
    columns: [
      {
        title: "Name",
        field: "name",
        render: rowData => <Link to={`/vendor/${rowData.id}`}>{rowData.name}</Link>
      },
      { title: "Tax ID", field: "taxID" },
      {
        title: "Incoming Bill",
        field: "fileName",
        render: r =>
          r.fileId && (
            <LinkButton onClick={() => onViewFile(r.fileId, { indexingStatusId: 6 })}>
              <FileNameIcon type={r.fileType} name={r.fileName} cssName="tc-link" />
            </LinkButton>
          )
      },
      { title: "Date", field: "statusOn", type: "datetime" },
      { title: "Status", field: "status" }
    ],
    data: vendors,
    hidePaging: vendors.length <= 50,
    options: { pageSize: 50 }
  };

  return (
    <Fragment key="clientListWrapper">
      {loading && <Loading float />}
      <div className="tc-page-content">
        <Paper className="tc-p2">
          <MaterialTable {...tableProps} />
        </Paper>
      </div>
      {fileViewer}
    </Fragment>
  );
};
export default List;
