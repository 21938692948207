import { CLAIM, API } from "../../../actions/actionTypes";
import { getSuccess, setSuccess } from "../../../actions/claim/info/damagedProperty";
import { takeLatest, takeLatestPost } from "../../common/sagaGenerator";

const url = `${API.URL}/claim/propertyDamaged`;

export default [
  takeLatest({
    type: CLAIM.INFO.PROPERTY.GET,
    actionSuccess: getSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading Damaged Property",
    url
  }),
  takeLatestPost({
    type: CLAIM.INFO.PROPERTY.SET,
    actionSuccess: setSuccess,
    params: ({ property }) => property,
    successMessage: "Property Damaged information successfully saved",
    errorMessage: "Unable to save the information",
    url
  })
];
