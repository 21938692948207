import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 0, 0, 0),
    height: "100%"
  },
  container: {
    padding: theme.spacing(2, 2, 2, 2)
  },
  paper: {
    height: "100%",
    width: "100%",
    padding: theme.spacing(2, 2, 2, 2)
  },
  title: {
    color: "#428302",
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 5
  },
  titleSearch: {
    color: "#004763",
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 5
  },
  containerh: {
    padding: theme.spacing(1, 20, 1, 3),
    backgroundColor: "#F4F8F9",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.4)",
    margin: theme.spacing(0, 0, 0, 0),
    width: "100%"
  },
  titleh: { color: "#004763", fontSize: 24, paddingRight: theme.spacing(10) },
  cancelButton: {
    margin: theme.spacing(3, 1, 1, 0)
  },
  saveButton: {
    margin: theme.spacing(3, 0, 1, 0)
  }
}));
