import * as yup from "yup";
import { dateSchema as date, isDate, isNumber, rootParent, isBeforeToday, isAfterDate, isEmail } from "../../../../../common/yup";

const evalChild = (single, ltgText) => fnValidate => (val, context) => {
  const { coverageTypes, multipleClaimants } = rootParent(context);
  const anyLob = coverageTypes.some(x => x.lossTypeGroups.some(ltg => ltg.text === ltgText && ltg.checked));
  if (!anyLob || single === multipleClaimants) return true;
  return typeof fnValidate === "function" ? fnValidate(val) : Boolean(val);
};

const number = () => yup.number().nullable();
const string = () => yup.string().nullable();

const damagedVehicle = single => {
  const evalDm = evalChild(single, "Vehicle Damage");
  const evalDriver = (level, fnValidate) => (val, context) => {
    const { isDriverOwner } = context.from[level].value;
    return isDriverOwner || evalDm(fnValidate)(val, context);
  };
  const evalOwner = (level, fnValidate) => (val, context) => {
    const { hasLienholder, isDriverOwner, isLienholderClaimant } = context.from[level].value;
    return !hasLienholder || isDriverOwner || !isLienholderClaimant || evalDm(fnValidate)(val, context);
  };
  const evalLienh = (level, fnValidate) => (val, context) => {
    const { hasLienholder, isLienholderClaimant } = context.from[level].value;
    return !hasLienholder || isLienholderClaimant || evalDm(fnValidate)(val, context);
  };
  return yup.object().shape({
    isLienholderClaimant: yup.boolean(),
    hasLienholder: yup.boolean(),
    isDriverOwner: yup.boolean(),
    vehicle: yup.object().shape({
      number: yup.number().test("vehicle", "Vehicle # Required", evalDm(isNumber)),
      vehicleYear: yup.number().test("vehicle", "Vehicle Year Required", evalDm(isNumber)),
      vehicleMakeId: yup.number().test("vehicle", "Vehicle Make Required", evalDm(isNumber)),
      model: yup.string().test("vehicle", "Vehicle Model Required", evalDm()),
      vehicleBodyTypeId: yup.number().test("vehicle", "Vehicle Body Type Required", evalDm(isNumber)),
      vin: yup.string().test("vehicle", "V.I.N. # Required", evalDm()),
      plateNumber: yup.string().test("vehicle", "Vehicle Plate Number Required", evalDm()),
      vehicleStateId: yup.number().test("vehicle", "Vehicle State Required", evalDm(isNumber))
    }),
    driver: yup.object().shape({
      firstName: yup.string().test("driver", "Driver's First Name Required", evalDriver(1)),
      lastName: yup.string().test("driver", "Driver's Last Name Required", evalDriver(1)),
      location: yup.object().shape({
        address1: yup.string().test("driver", "Driver's Address Required", evalDriver(2)),
        city: yup.string().test("driver", "Driver's City Required", evalDriver(2)),
        stateId: yup.number().test("driver", "Driver's State Required", evalDriver(2, isNumber)),
        zipCode: yup.string().test("driver", "Driver's Zip Code Required", evalDriver(2))
      })
    }),
    vehicleOwner: yup.object().shape({
      firstName: yup.string().test("vehicleOwner", "Owner's First Name Required", evalOwner(1)),
      lastName: yup.string().test("vehicleOwner", "Owner's Last Name Required", evalOwner(1)),
      dateofBirth: yup.date().test("vehicleOwner", "Valid Owner's Date of Birth Required", evalOwner(1, isBeforeToday)),
      location: yup.object().shape({
        address1: yup.string().test("vehicleOwner", "Driver's Address Required", evalOwner(2)),
        city: yup.string().test("vehicleOwner", "Driver's City Required", evalOwner(2)),
        stateId: yup.number().test("vehicleOwner", "Driver's State Required", evalOwner(2, isNumber)),
        zipCode: yup.string().test("vehicleOwner", "Driver's Zip Code Required", evalOwner(2))
      })
    }),
    lienHolder: yup.object().shape({
      name: yup.string().test("lienHolder", "LienHolder Name Required", evalLienh(1)),
      location: yup.object().shape({
        address1: yup.string().test("lienHolder", "LienHolder Address 1 Required", evalLienh(2)),
        city: yup.string().test("lienHolder", "LienHolder City Required", evalLienh(2)),
        stateId: yup.number().test("lienHolder", "LienHolder State Required", evalLienh(2, isNumber)),
        zipCode: yup.string().test("lienHolder", "LienHolder Zip Code Required", evalLienh(2))
      })
    })
  });
};

const bodilyInjury = single => {
  const evalBI = evalChild(single, "Bodily Injury");
  const evalTreat = fnValidate => (val, context) => {
    return !context?.parent?.treatmentReceived || evalBI(fnValidate)(val, context);
  };
  return yup.object().shape({
    injuryTypeId: yup.number().test("bodilyInjury", "Injury Type Required", evalBI(isNumber)),
    isDeath: yup.boolean().nullable(),
    claimantDateofDeath: date("Claimant Date of Death Required", (v, c) => !c?.parent?.isDeath || isDate(v)),
    parentBodyPartId: yup.number().test("bodilyInjury", "Body Part Group Required", evalBI(isNumber)),
    bodyPartId: yup.number().test("bodilyInjury", "Body Part Required", evalBI(isNumber)),
    treatmentReceived: yup.boolean(),
    treatmentTypeId: yup.number().test("bodilyInjury", "Initial Treatment Type Required", evalTreat(isNumber)),
    physicianName: yup.string().test("bodilyInjury", "Initial Hospital Physician Name Required", evalTreat()),
    stateId: yup.number().test("bodilyInjury", "Hospital State Required", evalTreat(isNumber))
    // hospitalId: yup.number().test("bodilyInjury", "Hospital Required", evalTreat(isNumber))
  });
};

const damagedProperty = single => {
  const evalDP = evalChild(single, "Property Damage");
  const evalProp = (fnValidate, isId) => (val, context) => {
    const { coverageTypes } = rootParent(context);
    const isPL = coverageTypes.some(lob => lob.id === 3 && lob.lossTypeGroups.some(ltg => ltg.checked));
    if (!isPL && isId) return true;
    if (!isPL) return evalDP(fnValidate)(val, context);
    const {
      insProperties: { data: properties }
    } = rootParent(context);
    return (isId ? properties.length === 0 : properties.length > 0) || (typeof fnValidate === "function" ? fnValidate : v => !!v)(val);
  };
  const evalMort = (level, fnValidate) => (val, context) => {
    const { hasMortgage } = context.from[level].value;
    const {
      damaged: { isClaimantMortgageCompany }
    } = context.from[level + 1].value;
    return !hasMortgage || isClaimantMortgageCompany || evalProp(fnValidate)(val, context);
  };
  return yup.object().shape({
    damaged: yup.object().shape({
      isClaimantMortgageCompany: yup.boolean(),
      propertyDamagedLossTypeId: yup.number().test("damaged", "Kind of Loss Required", evalDP(isNumber)),
      departamentTypeId: yup.number().test("damaged", "Reported to Required", evalDP(isNumber)),
      propertyDamagedTypeId: yup.number().test("damaged", "Damage Type Reported Required", evalDP(isNumber))
    }),
    property: yup.object().shape({
      id: number().test("property", "Property Required", evalProp(isNumber, true)),
      propertyTypeId: number().test("property", "Property Type Required", evalProp(isNumber)),
      hasMortgage: yup.boolean(),
      location: yup.object().shape({
        address1: string().test("property", "Address 1 Required", evalProp()),
        city: string().test("property", "City Required", evalProp()),
        stateId: number().test("property", "State Required", evalProp(isNumber)),
        zipCode: string().test("property", "Zip Required", evalProp())
      }),
      mortgageCompany: yup.object().shape({
        name: string().test("mortgageCompany", "Mortgage Company Name", evalMort(1)),
        location: yup.object().shape({
          address1: string().test("mortgageCompany", "Address 1 Required", evalMort(2)),
          city: string().test("mortgageCompany", "City Required", evalMort(2)),
          stateId: number().test("mortgageCompany", "State Required", evalMort(2, isNumber)),
          zipCode: string().test("mortgageCompany", "Zip Required", evalMort(2))
        })
      })
    })
  });
};

const claimant = single => {
  const evalClaimant = (context, val, fnValidate, validated) => {
    const { multipleClaimants } = rootParent(context);
    const { sameAsInsured } = context?.parent || {};
    if (sameAsInsured || validated || single === multipleClaimants) return true;
    return typeof fnValidate === "function" ? fnValidate(val) : Boolean(val);
  };
  const evalLocation = (context, val, isMailing, fnValidate) => {
    const { sameAsInsured, phisicalAsMailing } = context.from[1].value;
    return evalClaimant(context, val, fnValidate, sameAsInsured || (isMailing && phisicalAsMailing));
  };
  return yup.object().shape({
    isPerson: yup.boolean(),
    sameAsInsured: yup.boolean(),
    phisicalAsMailing: yup.boolean(),
    firstName: yup.string().test("isPerson", "First Name Required", (v, c) => evalClaimant(c, !c?.parent?.isPerson || v)),
    lastName: yup.string().test("isPerson", "Last Name Required", (v, c) => evalClaimant(c, !c?.parent?.isPerson || v)),
    dateofBirth: yup
      .string()
      .nullable()
      .test("isPerson", "Valid Date of Birth Required", (v, c) => evalClaimant(c, !c?.parent?.isPerson || isBeforeToday(v))),
    commercialName: yup.string().test("isNotPerson", "Commercial Name Required", (v, c) => evalClaimant(c, c?.parent?.isPerson || v)),
    contact: yup.string().test("isNotPerson", "Contact Name Required", (v, c) => evalClaimant(c, c?.parent?.isPerson || v)),
    taxIDTypeId: yup.number().test("default", "Tax ID Type Required", (o, c) => evalClaimant(c, o, isNumber)),
    taxID: yup.string().test("default", "Tax ID Required", (o, c) => evalClaimant(c, o)),
    workEmail: yup.string().test("", "Invalid Email", v => !v || isEmail(v)),
    email: yup.string().test("", "Invalid Email", v => !v || isEmail(v)),
    location: yup.object().shape({
      address1: yup.string().test("default", "Address 1 Required", (o, c) => evalLocation(c, o)),
      city: yup.string().test("default", "City Required", (o, c) => evalLocation(c, o)),
      stateId: yup.number().test("default", "State Required", (o, c) => evalLocation(c, o, undefined, isNumber)),
      zipCode: yup.string().test("default", "Zip Code Required", (o, c) => evalLocation(c, o))
    }),
    mailAddress: yup.object().shape({
      address1: yup.string().test("default", "Address 1 Required", (o, c) => evalLocation(c, o, true)),
      city: yup.string().test("default", "City Required", (o, c) => evalLocation(c, o, true)),
      stateId: yup.number().test("default", "State Required", (o, c) => evalLocation(c, o, true, isNumber)),
      zipCode: yup.string().test("default", "Zip Code Required", (o, c) => evalLocation(c, o, true))
    }),
    damagedVehicle: single ? undefined : damagedVehicle(false),
    bodilyInjury: single ? undefined : bodilyInjury(false),
    damagedProperty: single ? undefined : damagedProperty(false)
  });
};

export default yup.object().shape({
  multipleClaimants: yup.boolean(),
  incident: yup.object().shape({
    dateofLoss: date("Date of Loss Required").test("isDate", "Date of Loss has to be before today.", v => isBeforeToday(v)),
    timeofInjury: date("Time of Injury Required"),
    dateReported: date("Date Reported Required")
      .test("isDate", "Date reported has to be before today.", v => isBeforeToday(v))
      .test("isDateBeforeDate", "Date reported has to be after Date of Loss.", (v, c) => isAfterDate(v, c?.parent?.dateofLoss)),
    address1: yup.string().required("Address Required"),
    city: yup.string().required("City Required"),
    stateId: yup.number().required("State Required"),
    zipCode: yup.string().required("City Required")
  }),
  claimant: claimant(true),
  claimants: yup
    .array()
    .of(claimant(false))
    .test(
      "claimants",
      "Add at least one Claimant",
      (val, context) => Boolean(!context.parent.multipleClaimants || val.length > 0) || context.createError({ path: "toast" })
    ),
  damagedVehicle: damagedVehicle(true),
  bodilyInjury: bodilyInjury(true),
  damagedProperty: damagedProperty(true)
});
