import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Button, Collapse, Divider, Grid, IconButton, makeStyles, MenuItem, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import LabelValue from "../LabelValue";
import { CustomSelect } from "../inputs";
import ActionsAllowedContext from "../contexts/ActionsAllowed";

const useStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(1.25) },
  divider: { marginBottom: theme.spacing(2) },
  title: { color: "#428302", marginBottom: theme.spacing(2) },
  search: {
    padding: theme.spacing(1, 0),
    "& > div:not(:last-of-type)": {
      marginBottom: theme.spacing(1),
      position: "relative",
      "&:after": {
        position: "absolute",
        content: '""',
        width: "calc(100% - 32px)",
        bottom: theme.spacing(-0.5),
        right: theme.spacing(2),
        borderBottom: "solid 0.5px #898F91"
      }
    }
  },
  arrow: {
    minWidth: 24,
    marginRight: theme.spacing(1),
    "& > button > span > svg": {
      transform: "rotate(-180deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    }
  }
}));

const InsuredInformation = ({ insured: ins, editMode, onClose, onSave, isLoading }) => {
  const classes = useStyles();
  const insured = ins || {};
  const { location, mailingLocation } = insured;
  const [{ childId, children }, setState] = useState({ childId: insured.childId, children: insured.children });
  useEffect(() => {
    setState({ childId: insured.childId, children: insured.children });
  }, [insured, editMode]);
  const findChild = (chdrn, text) => {
    if (!Array.isArray(chdrn)) return null;
    for (let i = 0; i < chdrn.length; i += 1) {
      if (chdrn[i].id === childId) return { ...chdrn[i], name: `${text ? `${text} - ` : ""}${chdrn[i].name}` };
      const chd = findChild(chdrn[i].children, chdrn[i].name);
      if (chd) return chd;
    }
    return undefined;
  };
  const child = findChild(children) || {};
  const formatOption = (option, parent = null, level = 0) => {
    const chdrn = Array.isArray(option.children) ? option.children : [];
    const any = chdrn.some(() => true);
    const onClick = () => setState(ps => ({ ...ps, childId: option.id }));
    const onToggle = event => {
      event.preventDefault();
      event.stopPropagation();
      const map = p => (p.id === option.id ? { ...p, close: !p.close } : { ...p, children: p.children.map(map) });
      return setState(ps => ({ ...ps, children: ps.children.map(map) }));
    };
    return (
      <div key={option.id}>
        <MenuItem key={option.id} selected={childId === option.id} {...{ onClick }}>
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item className={clsx(classes.arrow, { [classes.open]: !option.close })} style={{ marginLeft: 24 * level }}>
              {any && (
                <IconButton size="small" onClick={onToggle}>
                  <ExpandMore />
                </IconButton>
              )}
            </Grid>
            <Grid item>{`${option.level}: ${option.name}`}</Grid>
          </Grid>
        </MenuItem>
        {any && <Collapse in={!option.close}>{chdrn.map(c => formatOption(c, parent || option, level + 1))}</Collapse>}
      </div>
    );
  };
  const formatValue = childId ? () => child.name : undefined;
  const container = p => <div className={classes.search} {...p} />;
  const onClear = () => setState({ ...insured, childId: undefined });
  const onSubmit =
    editMode && typeof onSave === "function"
      ? event => {
          event.preventDefault();
          onSave(childId);
        }
      : undefined;

  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info")?.update;
  return (
    <React.Fragment key="insuredinfo">
      <form className={classes.root} {...{ onSubmit }}>
        <Grid container spacing={4}>
          <Grid item xs>
            <LabelValue label="Insured #" value={insured.number} />
            <LabelValue label="Insured Name" value={insured.name} />
            <LabelValue label="FEIN #" value={insured.taxId} />
          </Grid>
          <Grid item xs>
            <LabelValue label="Type" value={insured.type} />
            <LabelValue label="Size" value={insured.sizeTypeName} />
            <LabelValue label="Insured Phone" value={insured.phone1} />
            <LabelValue label="Insured Email" value={insured.email} />
          </Grid>
          <Grid item xs>
            <LabelValue label="Address" value={location} isAddress isShortAddress />
            <LabelValue label="Mailing Address" value={mailingLocation} isAddress />
          </Grid>
        </Grid>
        <Collapse in={Boolean((editMode && access) || childId)}>
          <Divider className={classes.divider} />
          <Typography variant="subtitle2" className={classes.title}>
            Insured Structure
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              {(editMode && access && (
                <CustomSelect label="Level" name="level" value={childId} options={children} {...{ container, formatOption, formatValue, onClear }} />
              )) || <LabelValue label="Level" value={child.name} />}
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={4}>
                <Grid item xs>
                  <LabelValue label="Physical Address 1" value={child.location} isShortAddress />
                </Grid>
                <Grid item xs>
                  <LabelValue label="Phone 1" value={child.phone1} />
                </Grid>
              </Grid>
              {child.levelId === 7 && <LabelValue label="Description" value={child.description} />}
            </Grid>
          </Grid>
        </Collapse>
        {editMode && access && (
          <Grid container spacing={2} justify="flex-end">
            {typeof onClose === "function" && (
              <Grid item>
                <Button color="primary" variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
              </Grid>
            )}
            {typeof onSave === "function" && (
              <Grid item>
                <Button type="submit" color="primary" variant="contained" disabled={isLoading}>
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </form>
    </React.Fragment>
  );
};

InsuredInformation.propTypes = {
  insured: PropTypes.objectOf(PropTypes.any).isRequired,
  editMode: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool
};

InsuredInformation.defaultProps = {
  editMode: false,
  onClose: undefined,
  onSave: undefined,
  isLoading: false
};

export default InsuredInformation;
