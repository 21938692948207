import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import ViewMode from "../../../common/claims/bodilyInjury/Info";
import EditView from "../../../common/claims/bodilyInjury/Form";
import { set, get } from "../../../../redux/actions/claim/info/bodilyInjury";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import { useRouteParam } from "../../../../common";

export default props => {
  const claimId = useRouteParam("claimId");
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info") || {};
  const {
    props: { boxProps, editMode, loading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  const bodilyInjury = useSelector(({ claim: { bodilyInjury: b } }) => b, shallowEqual);
  const dispatch = useDispatch();
  const handleSave = data => {
    showLoading();
    return dispatch(set({ ...data, claimId }, onSuccessEdit, hideLoading));
  };
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  return (
    <CollapsibleBox title="Bodily Injury" name="bodily" {...boxProps} {...props}>
      {Loading}
      {(editMode && access.update && <EditView {...{ bodilyInjury, onClose: onToggleEdit, onSave: handleSave, isLoading: loading }} />) || (
        <ViewMode {...{ bodilyInjury }} />
      )}
    </CollapsibleBox>
  );
};
