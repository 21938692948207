import { CLAIM } from "../../actionTypes";

export const getAttorneyByFirmId = (id, onSuccess, onError) => ({ type: CLAIM.LEGAL.ATTORNEYBYFIRM.GET, id, onSuccess, onError });
export const getAttorneyByFirmIdSuccess = attorneyByFirm => ({ type: CLAIM.LEGAL.ATTORNEYBYFIRM.GET_SUCCESS, attorneyByFirm });

export const getClaimantAttorney = (claimId, onSuccess, onError) => ({ type: CLAIM.LEGAL.CLAIMANTATTORNEY.GET, claimId, onSuccess, onError });
export const getClaimantAttorneySuccess = claimantAttorneys => ({ type: CLAIM.LEGAL.CLAIMANTATTORNEY.GET_SUCCESS, claimantAttorneys });
export const setClaimantAttorney = (claimantAttorney, onSuccess, onError) => ({
  type: CLAIM.LEGAL.CLAIMANTATTORNEY.SET,
  claimantAttorney,
  onSuccess,
  onError
});
export const setClaimantAttorneySuccess = claimantAttorneys => ({ type: CLAIM.LEGAL.CLAIMANTATTORNEY.SET_SUCCESS, claimantAttorneys });
export const postClaimantAttorney = (claimantAttorney, onSuccess, onError) => ({
  type: CLAIM.LEGAL.CLAIMANTATTORNEY.POST,
  claimantAttorney,
  onSuccess,
  onError
});
export const postClaimantAttorneySuccess = claimantAttorneys => ({ type: CLAIM.LEGAL.CLAIMANTATTORNEY.POST_SUCCESS, claimantAttorneys });
export const delClaimantAttorney = (claimantAttorney, onSuccess, onError) => ({
  type: CLAIM.LEGAL.CLAIMANTATTORNEY.DEL,
  claimantAttorney,
  onSuccess,
  onError
});
export const delClaimantAttorneySuccess = claimantAttorneys => ({ type: CLAIM.LEGAL.CLAIMANTATTORNEY.DEL_SUCCESS, claimantAttorneys });

export const getDefendantAttorney = (claimId, onSuccess, onError) => ({ type: CLAIM.LEGAL.DEFENDANTATTORNEY.GET, claimId, onSuccess, onError });
export const getDefendantAttorneySuccess = defendantAttorneys => ({ type: CLAIM.LEGAL.DEFENDANTATTORNEY.GET_SUCCESS, defendantAttorneys });
export const setDefendantAttorney = (defendantAttorney, onSuccess, onError) => ({
  type: CLAIM.LEGAL.DEFENDANTATTORNEY.SET,
  defendantAttorney,
  onSuccess,
  onError
});
export const setDefendantAttorneySuccess = defendantAttorneys => ({ type: CLAIM.LEGAL.DEFENDANTATTORNEY.SET_SUCCESS, defendantAttorneys });
export const postDefendantAttorney = (defendantAttorney, onSuccess, onError) => ({
  type: CLAIM.LEGAL.DEFENDANTATTORNEY.POST,
  defendantAttorney,
  onSuccess,
  onError
});
export const postDefendantAttorneySuccess = defendantAttorneys => ({ type: CLAIM.LEGAL.DEFENDANTATTORNEY.POST_SUCCESS, defendantAttorneys });
export const delDefendantAttorney = (defendantAttorney, onSuccess, onError) => ({
  type: CLAIM.LEGAL.DEFENDANTATTORNEY.DEL,
  defendantAttorney,
  onSuccess,
  onError
});
export const delDefendantAttorneySuccess = defendantAttorneys => ({ type: CLAIM.LEGAL.DEFENDANTATTORNEY.DEL_SUCCESS, defendantAttorneys });
