import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Redirect, useRouteMatch, useLocation, Link } from "react-router-dom";
import { Tab } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import useStyles from "./index.styles";
import Sidebar from "../../components/claims/claim/sidebar";
import { getOverview, reset, getSecProfFilter, get } from "../../redux/actions/claim";
import { getReserveTypes } from "../../redux/actions/admin/claimsSetting";
import { useRouteParam, getUserAccess } from "../../common";
import ClaimRoutes, { routes } from "./Routes";
import SimpleTabs from "../../components/common/tabs/SimpleTabs";
import ActionsAllowed from "../../components/common/contexts/ActionsAllowed";
import Loading, { LoadingContextProvider } from "../../components/common/Loading";

export default () => {
  const [state, setState] = useState({ showSideBar: true, padding: true, breadcrumb: true });
  const claimId = useRouteParam("claimId");
  const { isExact, url } = useRouteMatch();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const store = useSelector(
    ({
      profile,
      claim: {
        filterfields,
        claim: { number }
      },
      admin: {
        claimSettings: {
          documents: { active }
        }
      }
    }) => ({ userProfile: profile, filters: filterfields, isDocumentTabActive: active, number }),
    shallowEqual
  );
  const { userProfile, filters, isDocumentTabActive, number } = store;
  useEffect(() => {
    dispatch(getReserveTypes());
    dispatch(getSecProfFilter(claimId));
    return () => dispatch(reset());
  }, []);
  const routesAllowed = routes.reduce((acc, [route]) => {
    if (!userProfile.securityProfilesDetails?.length || !filters?.lineOfBusinessId) return acc;
    const iClaimId = userProfile.claimIds.indexOf(claimId);
    const hasPermissionByClaim = userProfile.claimRestriction === true ? iClaimId === -1 : iClaimId > -1;
    const routeAccess = {
      ...getUserAccess(
        userProfile.securityProfilesDetails,
        `CLAIM.${route.toUpperCase()}`,
        filters.clientId,
        filters.stateId,
        filters.lineOfBusinessId
      ),
      route
    };
    routeAccess.read = ((route === "documents" && isDocumentTabActive) || route !== "documents") && routeAccess.read && hasPermissionByClaim;
    if (route === "notes") {
      const accessNoteConf = getUserAccess(
        userProfile.securityProfilesDetails,
        "CLAIM.NOTES.CONFIDENTIAL",
        filters.clientId,
        filters.stateId,
        filters.lineOfBusinessId
      );
      return [...acc, routeAccess, { ...accessNoteConf, route: "notes-confidential" }];
    }
    return [...acc, routeAccess];
  }, []);
  const isRouteRead = r => routesAllowed.some(({ route, read }) => route === r && read);
  const tabsToShow = routes.reduce((acc, [route, label]) => (isRouteRead(route) ? [...acc, [route, label]] : acc), []);
  const handleSideBar = useCallback(show => setState(ps => ({ ...ps, showSideBar: show })), [routesAllowed]);
  const handlePadding = useCallback(p => setState(ps => ({ ...ps, padding: p })), []);
  const handleBreadcrumb = useCallback(b => setState(ps => ({ ...ps, breadcrumb: b })), []);
  useEffect(() => {
    if (isRouteRead("overview")) dispatch(getOverview(claimId));
    else if (isRouteRead("info")) dispatch(get(claimId));
  }, [routesAllowed.length]);

  const classes = useStyles();
  const container = useRef(null);
  const { showSideBar, padding, breadcrumb } = state;
  const breadcrumbs = {
    breadData: {
      linkTitle: `CLAIMS`,
      to: `/claims/claims`
    },
    activeTitle: (() => {
      const claim = `${number || `Claim ${claimId}`}`;
      const label = (tabsToShow.find(([route]) => pathname === `${url}/${route}`) || ["", ""])[1];
      if (!label) return claim;
      return `${claim}: ${label}`;
    })()
  };
  return (
    <div className={classes.root}>
      <div ref={container} className={`${classes.container} tc-page-container`}>
        {(!tabsToShow.length && <Loading float />) || (
          <Fragment key="claim-page">
            {isExact && <Redirect to={`${url}/${tabsToShow[0][0]}`} />}
            <SimpleTabs value={url === pathname ? `${url}/overview` : pathname}>
              {tabsToShow.map(([route, label]) => (
                <Tab key={route} label={label} component={Link} to={`${url}/${route}`} value={`${url}/${route}`} />
              ))}
            </SimpleTabs>
            <LoadingContextProvider float actions={{ handlePadding, handleSideBar, handleBreadcrumb }} values={{ padding, breadcrumb, breadcrumbs }}>
              <ActionsAllowed.Provider value={routesAllowed}>
                <ClaimRoutes containerRef={container} handleSideBar={handleSideBar} />
              </ActionsAllowed.Provider>
            </LoadingContextProvider>
          </Fragment>
        )}
      </div>
      {showSideBar && isRouteRead("info") && <Sidebar />}
    </div>
  );
};
