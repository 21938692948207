import { makeStyles } from "@material-ui/core/styles";

const drawerPaper = { backgroundColor: "#FFFFFF", color: "#004763", minWidth: 84 };

export default makeStyles(theme => {
  return {
    drawer: {
      [theme.breakpoints.up("sm")]: {
        flexShrink: 0
      }
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#003144",
      background: "transparent linear-gradient(90deg, #A7CE05 0%, #1087A9 46%, #004763 100%) 0% 0% no-repeat padding-box;",
      backgroundPosition: "center bottom",
      backgroundSize: "100% 4px"
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    title: {
      flexGrow: 1
    },
    logo: {
      marginRight: "5px"
    },
    searchBar: {
      paddingLeft: "5px",
      display: "inline"
    },
    drawerPaper,
    sideMenu: { ...drawerPaper, overflow: "hidden", overflowY: "auto" },
    content: { flexGrow: 1 }
  };
});
