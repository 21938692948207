import React, { Fragment, useState, useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { getUserAccess } from "../../common";
import { LoadingContextProvider } from "../../components/common/Loading";
import Routes, { routes } from "./Routes";

const mapRoute = ([route, Comp, action, exact], path, access) => ({ Comp, exact, path: `${path}/${route}`, allowed: !!access?.[action] });

const Automation = () => {
  const [state, setState] = useState({ padding: true, breadcrumb: true });
  const { isExact, url, path } = useRouteMatch();
  const { pathname } = useLocation();
  const userProfileDet = useSelector(({ profile: { securityProfilesDetails: d } }) => d, shallowEqual);
  const tabs = routes.reduce(
    (a, [name, label, title, troutes]) => {
      const access = userProfileDet.length ? getUserAccess(userProfileDet, `AUTOMATION.${name.toLocaleUpperCase()}`) : {};
      return {
        ...a,
        tabs: access.read ? [...a.tabs, { name, to: `${url}/${name}`, label, title }] : a.tabs,
        allRoutes: [...a.allRoutes, ...troutes.map(r => mapRoute(r, path, access))],
        routesAllowed: [...a.routesAllowed, { route: name, ...access }]
      };
    },
    { tabs: [], allRoutes: [], routesAllowed: [] }
  );
  const handlePadding = useCallback(p => setState(ps => ({ ...ps, padding: p })), []);
  const handleBreadcrumb = useCallback(b => setState(ps => ({ ...ps, breadcrumb: b })), []);
  const currentTab = url === pathname || !tabs.tabs.some(t => t.to === pathname) ? tabs.tabs[0]?.to || false : pathname;
  return (
    <Fragment key="automation-page">
      {isExact && <Redirect to={`${url}/diaries`} />}
      <LoadingContextProvider float actions={{ handlePadding, handleBreadcrumb }} values={{ ...state, ...tabs, currentTab, pathname }}>
        <Routes />
      </LoadingContextProvider>
    </Fragment>
  );
};

export default Automation;
