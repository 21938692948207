import { API } from "../../actions/actionTypes";
import diaries from "../../actions/actionTypes/dashboard/diaries";
import { getSuccess, getExpiredSuccess } from "../../actions/dashboard/diaries";
import { takeLatest } from "../common/sagaGenerator";

export default [
  takeLatest({
    type: diaries.GETALL,
    actionSuccess: getSuccess,
    params: ({ userId }) => ({ userId }),
    errorMessage: "Error loading User Diaries",
    url: `${API.URL}/report/diariesByUser`
  }),
  takeLatest({
    type: diaries.EXPIRED.GETALL,
    actionSuccess: getExpiredSuccess,
    errorMessage: "Error loading Expired Diaries",
    url: `${API.URL}/report/expireddiaries`
  })
];
