import { API, ADMIN } from "../../../actions/actionTypes";
import { getAllTimelineDatesSuccess, setTimelineDatesSuccess } from "../../../actions/admin/claimsSetting";
import { takeLatest, takeLatestSet } from "../../common/sagaGenerator";

const url = `${API.URL}/claimsetting`;

export default [
  takeLatest({
    type: ADMIN.CLAIMSETTING.TIMELINEDATE.GETALL,
    url: `${url}/gettimelinedate`,
    actionSuccess: getAllTimelineDatesSuccess,
    errorMessage: "Error getting timeline dates."
  }),
  takeLatestSet({
    type: ADMIN.CLAIMSETTING.TIMELINEDATE.SET,
    url: `${url}/timelinedate`,
    actionSuccess: setTimelineDatesSuccess,
    successMessage: "Widget successfully updated",
    errorMessage: "Unable to update the widget",
    params: ({ data }) => data
  })
];
