const { useSelector, shallowEqual } = require("react-redux");

export const useDiariesSelector = () =>
  useSelector(
    ({
      occurrence: { occurrence, diaryTemplates: occurrenceDiaryTemplates },
      claim: {
        claim: { number },
        diaryTemplates
      }
    }) => ({
      occurrence,
      claimNumber: number,
      claimDiaryTemplates: diaryTemplates,
      occurrenceDiaryTemplates
    }),
    shallowEqual
  );

export const useFormSelector = () =>
  useSelector(
    ({
      claim: { diaryTemplates },
      common: { taskStatus, taskPriority, taskType, users },
      occurrence: {
        occurrence: { associatedList },
        diaryTemplates: occurrenceDiaryTemplates
      }
    }) => ({
      associatedList,
      common: { taskStatus, taskPriority, taskType, users },
      claimDiaryTemplates: diaryTemplates,
      occurrenceDiaryTemplates
    }),
    shallowEqual
  );

export const useListSelector = entityType => useSelector(({ [entityType]: { diaries } }) => diaries, shallowEqual);
