import { AUTOMATION } from "../actions/actionTypes";
import initialState from "./initialState";

export default (state = initialState.automation, action) => {
  switch (action.type) {
    case AUTOMATION.DIARIES.GETALL_SUCCESS:
    case AUTOMATION.DIARIES.ACTIVATE_SUCCESS:
    case AUTOMATION.DIARIES.DEL_SUCCESS:
      // case AUTOMATION.DIARIES.SET_SUCCESS:
      return { ...state, diaries: action.diaries };
    default:
      return state;
  }
};
