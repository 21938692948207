import React, { Fragment, memo } from "react";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { Formik } from "formik";
import useOnToggleBoxes from "../../../common/customHooks/useHandleBox";
import CollapsibleBox from "../../../common/CollapsibleBox";
import { DateTimeInput, SelectInput, InputText } from "../../../common/inputs";

const StatusForm = memo(({ clientStatus, handleChange, errors }) => {
  const { open, onToggle } = useOnToggleBoxes({ initialValue: { clientStatus: true } });
  const inputProps = name => {
    const prs = { name: `clientStatus.${name}`, onChange: handleChange, value: (clientStatus || {})[name] || undefined };
    if (name === "isActive") {
      const actProps = value => ({
        ...prs,
        options: [
          { id: 1, text: "Active" },
          { id: 2, text: "Inactive" }
        ],
        value: value || undefined,
        error: ((errors || {}).clientStatus || {}).isActive
      });
      switch (prs.value) {
        case true:
        case 1:
        case "1":
          return actProps(1);
        case false:
        case 2:
        case "2":
          return actProps(2);
        default:
          return actProps();
      }
    }
    return prs;
  };
  return (
    <CollapsibleBox title="Client Status" name="clientStatus" {...{ open: open.clientStatus, onToggle }}>
      <Formik>
        <Fragment key="client-status">
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <SelectInput label="Status" {...inputProps("isActive")} />
            </Grid>
            <Grid item xs={8}>
              <InputText label="Reason" {...inputProps("reason")} />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs>
              <InputText label="Status Description" {...inputProps("description")} rows={4} />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs>
              <DateTimeInput label="Migrate Date" {...inputProps("migratedDate")} keyboard />
            </Grid>
            <Grid item xs>
              <DateTimeInput label="Data Conversion Completed" {...inputProps("conversionCompletedDate")} keyboard />
            </Grid>
            <Grid item xs>
              <InputText label="Prior TPA" {...inputProps("priorTpa")} />
            </Grid>
          </Grid>
        </Fragment>
      </Formik>
    </CollapsibleBox>
  );
});

StatusForm.propTypes = {
  clientStatus: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any)
};

StatusForm.defaultProps = {
  errors: {}
};

export default StatusForm;
