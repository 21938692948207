import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../common/CollapsibleBox";
import { useRouteParam } from "../../../common";
import ClaimWitness from "../../common/claims/ClaimWitness";
import { getAll, post, set, del } from "../../../redux/actions/initialReport/witness";
import useCustomConfirm from "../../common/customHooks/useCustomConfirm";
import ActionsAllowedContext from "../../common/contexts/ActionsAllowed";

const WitnessInfoBox = props => {
  const store = useSelector(({ initialReport: { information } }) => information, shallowEqual);
  const { witnesses } = store || {};
  const claimId = useRouteParam("claimId");
  const dispatch = useDispatch();
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info");
  const {
    props: { boxProps, Loading },
    actions: { showLoading, hideLoading }
  } = useCollapsibleBox();
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(getAll(claimId, hideLoading, hideLoading));
    }
  }, []);
  const handleDelete = data => {
    if (access.delete) {
      showLoading();
      dispatch(del(data.id, hideLoading, hideLoading));
    }
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleDelete, "Cancel", "Yes, Delete");
  const witnessProps = {
    data: witnesses,
    add: data => {
      if (access.create) {
        showLoading();
        dispatch(post({ ...data, claimId }, hideLoading, hideLoading));
      }
    },
    update: data => {
      if (access.update) {
        showLoading();
        dispatch(set(data, hideLoading, hideLoading));
      }
    },
    remove: data =>
      handleCustomConfirm({
        oData: data,
        titleText: `Delete Witness`,
        messageText: `The following witness is going to be deleted: ${data.firstName} ${data.lastName}. Are you sure you want to proceed?`
      })
  };
  return (
    <CollapsibleBox title="Witness" name="witness" {...boxProps} {...props}>
      {Loading}
      <ClaimWitness {...witnessProps} hideExportButton />
      <Confirm />
    </CollapsibleBox>
  );
};

export default WitnessInfoBox;
