import { makeStyles } from "@material-ui/core/styles";

export const backgroundAvatar = {
  background: "transparent linear-gradient(294deg, #03A2DF 0%, #3398BF 49%, #A7CE05 100%) 0% 0% no-repeat padding-box",
  padding: 2,
  borderRadius: "50%"
};

export const useAvatarStyles = makeStyles(() => ({
  root: {
    display: "inline-block",
    ...backgroundAvatar
  },
  badge: { cursor: "pointer", "& > span[class*=badge]": { display: "none" }, "&:hover > span[class*=badge]": { display: "flex" } },
  avatar: { width: 86, height: 86, fontSize: 40, backgroundColor: "#FFFFFF", color: "#004763" }
}));

export default makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(2),
      "& *": { whiteSpace: "nowrap" },
      "& button": { padding: "3px 9px !important" },
      "& > div": { alignSelf: "center", flexWrap: "nowrap" }
    },
    buttons: { margin: theme.spacing(2, 0) },
    mb: { margin: theme.spacing(0, 0, 2, 0) },
    pr: { paddingRight: theme.spacing(1) },
    pl: { paddingLeft: theme.spacing(1) },
    userAvatar: { backgroundColor: "#003144" },
    backgroundAvatar,
    name: { color: "#004763", fontSize: 16, paddingTop: theme.spacing(1), fontWeight: 500 },
    profile: { color: "#898F91", fontSize: 12, paddingTop: theme.spacing(0.5) }
  }),
  { name: "info-menu" }
);
