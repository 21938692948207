import { CLAIM } from "../actionTypes";

export const get = claimId => ({ type: CLAIM.DIARY.GET, claimId });
export const getSuccess = diaries => ({ type: CLAIM.DIARY.GET_SUCCESS, diaries });
export const set = diary => ({ type: CLAIM.DIARY.SET, diary });
export const setSuccess = diaries => ({ type: CLAIM.DIARY.SET_SUCCESS, diaries });
export const del = (id, claimId) => ({ type: CLAIM.DIARY.DEL, id, claimId });
export const delSuccess = diaries => ({ type: CLAIM.DIARY.DEL_SUCCESS, diaries });
export const getTemplates = claimId => ({ type: CLAIM.DIARY.TEMPLATES.GET, claimId });
export const getTemplatesSuccess = templates => ({ type: CLAIM.DIARY.TEMPLATES.GET_SUCCESS, templates });
