import { createMuiTheme } from "@material-ui/core/styles";

export const colors = {
  blue: { primary: "#107A98", secondary: "#004763", contrast: "#ffcc00", light: "#DAEAEF", dark: '"#F3F3F3"' },
  green: "#428302",
  gray: "#898F91",
  red: "#E74C3C"
};

const { blue, green } = colors;

const title = { color: green, fontSize: "1rem", fontWeight: 500 };
const font14 = { fontSize: "0.875rem" };

const styles = {
  palette: {
    background: { default: "#E7EEF1" },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: blue.primary
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: blue.secondary,
      // dark: will be calculated from palette.secondary.main,
      contrastText: blue.contrast
    }
  },
  overrides: {
    MuiTypography: {
      root: { "&.tc-title": title, "&.tc-nodata": { color: "#898F91" } },
      body1: font14,
      gutterBottom: { "&.tc-title": { marginBottom: "1.375rem" }, "&.tc-nodata": { marginBottom: "1rem" } },
      subtitle2: { fontSize: "1rem" }
    },
    MuiTab: { root: { minHeight: "4rem !important" } },
    MuiFormLabel: { root: font14 },
    MuiInputBase: { root: font14 },
    MuiMenuItem: { root: font14 },
    MuiIconButton: { root: { padding: "0.5rem" } },
    MuiDialog: { paperWidthLg: { maxWidth: 1792 } }
  }
};

export const baseTableTheme = createMuiTheme({
  ...styles,
  overrides: {
    ...styles.overrides,
    MuiTypography: { ...styles.overrides.MuiTypography, h6: title },
    MuiToolbar: {
      gutters: { "&:not([class*=MuiPickersToolbar-toolbar])": { paddingLeft: "0 !important", paddingRight: "0 !important" } },
      highlight: { backgroundColor: "inherit !important" }
    },
    MuiTableCell: {
      head: {
        backgroundColor: "#F0F0F0 !important",
        color: "#000000 !important",
        whiteSpace: "nowrap"
      },
      body: { paddingTop: "2px", paddingBottom: "2px", height: "48px !important" }
    }
  }
});

const theme = createMuiTheme(styles);

export default theme;
