export default {
  employeeAccident: {
    safetyEquipmentProvided: false,
    safetyEquipmentProvidedUsed: false,
    onEmployerPremises: false,
    equipmentInvolved: false,
    lossTimeMore: false
  },
  dateReported: undefined,
  claimTypeId: undefined,
  claimLossTypeId: undefined,
  coverageTypeCodeId: undefined,
  ediClaimTypeId: undefined,
  stateNumber: undefined,
  reportedBy: undefined,
  ContactMethodId: undefined,
  lateReason: undefined,
  dateofLoss: undefined,
  location: {},
  bodilyInjury: {},
  claimLossTypeGroupId: 2,
  hospital: {},
  lossTimeMore: false,
  equipmentInvolved: false
};
