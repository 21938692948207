import rootGenerator from "../common/rootSagaGenerator";
import information from "./information";
import claim from "./claim";
import reports from "./reports";
import activity from "./activity";

export default rootGenerator({
  name: "initialReport",
  sagas: [...information, ...claim, ...reports, ...activity]
});
