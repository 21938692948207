import { takeLatest as sagaTakeLatests } from "redux-saga/effects";
import { OCCURRENCE, API } from "../actions/actionTypes";
import { objectToFormData } from "../../api/apiUtils";
import * as docs from "./common/documents";
import rootSagaGenerator from "./common/rootSagaGenerator";
import { takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "./common/sagaGenerator";
import {
  getSuccess,
  getAllSuccess,
  postSuccess,
  setSuccess,
  delSuccess,
  assocciateClaimSuccess,
  disassocciateClaimSuccess,
  getReservesSuccess,
  uploadFilesSuccess,
  deleteFileSuccess,
  getFilterFieldsSuccess
} from "../actions/occurrence";

export default rootSagaGenerator({
  name: "occurrence",
  sagas: [
    takeLatest({
      type: OCCURRENCE.GETALL,
      actionSuccess: getAllSuccess,
      errorMessage: "Error loading occurrences.",
      url: `${API.URL}/occurrence/getAll`
    }),
    takeLatest({
      type: OCCURRENCE.GET,
      actionSuccess: getSuccess,
      errorMessage: "Error loading occurrence.",
      url: `${API.URL}/occurrence`,
      params: ({ id }) => ({ id })
    }),
    takeLatestPost({
      type: OCCURRENCE.POST,
      actionSuccess: postSuccess,
      successMessage: "Occurrence successfully created",
      errorMessage: "Unable to create the occurrence",
      url: `${API.URL}/occurrence`,
      params: ({ occurrence }) => occurrence
    }),
    takeLatestSet({
      type: OCCURRENCE.SET,
      actionSuccess: setSuccess,
      successMessage: "Information successfully updated.",
      errorMessage: "Unable to update the information.",
      url: `${API.URL}/occurrence`,
      params: ({ occurrence }) => occurrence
    }),
    takeLatestDel({
      type: OCCURRENCE.DEL,
      actionSuccess: delSuccess,
      successMessage: "Occurrence successfully deleted.",
      errorMessage: "Unable to delete the occurrence.",
      url: `${API.URL}/occurrence`,
      params: ({ id }) => ({ id })
    }),
    takeLatestSet({
      type: OCCURRENCE.ASSOCIATECLAIM,
      actionSuccess: assocciateClaimSuccess,
      successMessage: "Claim successfully associated.",
      errorMessage: "Unable to associate the claim to this occurrence.",
      url: `${API.URL}/occurrence/associateclaim`,
      params: ({ claimAssociatedToOcc }) => claimAssociatedToOcc
    }),
    takeLatestSet({
      type: OCCURRENCE.DISASSOCIATECLAIM,
      actionSuccess: disassocciateClaimSuccess,
      successMessage: "Claim successfully removed.",
      errorMessage: "Unable to remove the claim from this occurrence.",
      url: `${API.URL}/occurrence/disassociateclaim`,
      params: ({ claimAssociatedToOcc }) => claimAssociatedToOcc
    }),
    takeLatest({
      type: OCCURRENCE.GET_RESERVES,
      actionSuccess: getReservesSuccess,
      errorMessage: "Error loading get reserves.",
      url: `${API.URL}/reserve/occurrenceReserves`,
      params: ({ occurrenceId }) => ({ occurrenceId })
    }),
    takeLatestPost({
      type: OCCURRENCE.UPLOAD_FILES,
      url: `${API.URL}/catastrophe/files`,
      params: ({ documents }) => objectToFormData(documents),
      actionSuccess: uploadFilesSuccess,
      successMessage: "Document successfully added.",
      errorMessage: "Unable to add the document."
    }),
    takeLatestDel({
      type: OCCURRENCE.DELETE_FILE,
      actionSuccess: deleteFileSuccess,
      successMessage: "Document successfully deleted. ",
      errorMessage: "Unable to delete the document. ",
      url: `${API.URL}/catastrophe/file`,
      isData: true,
      params: ({ data }) => data
    }),
    sagaTakeLatests(OCCURRENCE.OPEN_FILE, docs.openFile(OCCURRENCE.OPEN_FILE_SUCCESS)),
    takeLatest({
      type: OCCURRENCE.GET_FILTER_FIELD,
      actionSuccess: getFilterFieldsSuccess,
      params: ({ occurrenceId }) => ({ occurrenceId }),
      errorMessage: "Error loading Profile Filters Fields",
      url: `${API.URL}/occurrence/filterfields`
    })
  ]
});
