import * as yup from "yup";
import { isNumber, rootParent } from "../../../../../common/yup";

const testInsVehicle = fn => (val, ctx) => {
  const {
    coverageTypes,
    insVehicles,
    insured: { id: insuredId }
  } = rootParent(ctx);
  const lob = coverageTypes.some(x => x.description === "Automobile Liability" && x.lossTypeGroups.some(ltg => ltg.checked));
  if (!lob || insVehicles?.insuredId !== insuredId || !Array.isArray(insVehicles?.data) || insVehicles.data.length === 0) return true;
  const fnValidate = typeof fn === "function" ? fn : v => Boolean(v);
  return fnValidate(val);
};

const testInsProperty = fn => (val, ctx) => {
  const {
    coverageTypes,
    insProperties,
    insured: { id: insuredId }
  } = rootParent(ctx);
  const lob = coverageTypes.some(x => x.description === "Property Liability" && x.lossTypeGroups.some(ltg => ltg.checked));
  if (!lob || insProperties?.insuredId !== insuredId || !Array.isArray(insProperties?.data) || insProperties.data.length === 0) return true;
  const fnValidate = typeof fn === "function" ? fn : v => Boolean(v);
  return fnValidate(val);
};

export default yup.object().shape({
  multipleClaimants: yup.boolean(),
  insProperties: yup.object().shape({
    insuredId: yup.number().nullable(),
    data: yup.array().of(yup.object())
  }),
  insProperty: yup.object().shape({
    id: yup
      .number()
      .nullable()
      .test("insProperty", "Property Required", testInsProperty(isNumber))
  }),
  insVehicles: yup.object().shape({
    insuredId: yup.number().nullable(),
    data: yup.array().of(yup.object())
  }),
  insVehicle: yup.object().shape({
    id: yup
      .number()
      .nullable()
      .test("insVehicle", "Vehicle Required", testInsVehicle(isNumber)),
    driver: yup.object().shape({
      location: yup.object().shape({
        address1: yup.string().test("insVehicleDriver", "Driver's Address Required", testInsVehicle()),
        city: yup.string().test("insVehicleDriver", "Driver's City Required", testInsVehicle()),
        stateId: yup
          .number()
          .nullable()
          .test("insVehicleDriver", "Driver's State Required", testInsVehicle(isNumber)),
        zipCode: yup.string().test("insVehicleDriver", "Driver's Zip Required", testInsVehicle())
      }),
      firstName: yup.string().test("insVehicleDriver", "Driver's First Name Required", testInsVehicle()),
      lastName: yup.string().test("insVehicleDriver", "Driver's Last Name Required", testInsVehicle())
    })
  }),
  insured: yup.object().shape({
    id: yup.number().required("Insured Required")
  }),
  coverageTypes: yup
    .array()
    .of(
      yup.object().shape({
        lossTypeGroups: yup.array().of(
          yup.object().shape({
            checked: yup.boolean().nullable(),
            lossTypeId: yup
              .number()
              .nullable()
              .test("isChecked", "Loss Type Required", (o, c) => !c?.parent?.checked || isNumber(o)),
            causeCodeId: yup
              .number()
              .nullable()
              .test("isChecked", "Cause Code Required", (o, c) => !c?.parent?.checked || isNumber(o))
          })
        )
      })
    )
    .test("anyChecked", "Select at least one Coverage Type", (o, c) =>
      c?.parent?.multipleClaimants || o.some(ct => ct.lossTypeGroups.some(ltg => ltg.checked)) ? true : c.createError({ path: "toast" })
    )
    .test("onlyOneChecked", "Select a Coverage Type", (o, c) =>
      !c?.parent?.multipleClaimants || o.filter(ct => ct.lossTypeGroups.some(ltg => ltg.checked)).length === 1
        ? true
        : c.createError({ path: "toast" })
    )
});
