import { Grid, IconButton, withStyles } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import React from "react";
import { useWcClaimAddContext, WcClaimAddContextProvider } from "./context";
import CreateAsTeam from "./CreateAsTeam";
import WcAddDocument from "./Document";
import Employee from "./Employee";
import Employer from "./Employer";
import Employment from "./Employment";
import WcAddFooter from "./Footer";
import Incident from "./Incident";
import { useClaimAddHeaderStyles } from "./index.styles";

const WhiteButton = withStyles({ root: { backgroundColor: "#FFFFFF", "&:hover": { backgroundColor: "#F5F5F5" } } })(IconButton);

const WcClaimAdd = () => {
  const headerClasses = useClaimAddHeaderStyles();
  const {
    state: { insured, employee, employment, incident, createAsTeam, duplicates, blob, file },
    actions: { toggleShowDocs, setState, handleCancel, handleSave, setShowDuplicates },
    status: { showDocs, showDuplicates },
    error
  } = useWcClaimAddContext();
  return (
    <div className="tc-page-container">
      <Grid container className={headerClasses.container} alignItems="center">
        <Grid item className={headerClasses.title}>
          Add FROI / Claim - WC
        </Grid>
      </Grid>
      <Grid container className="tc-page-content tc-no-padding" style={{ height: "100%" }}>
        {showDocs && <WcAddDocument {...{ blob, file, setState }} />}
        <Grid item xs={showDocs ? 8 : 12} style={{ height: "100%" }}>
          <Grid container wrap="nowrap" style={{ height: "100%" }}>
            <Grid item className="tc-p2 tc-pr0">
              <WhiteButton size="small" onClick={toggleShowDocs}>
                {(showDocs && <ChevronRight />) || <ChevronLeft />}
              </WhiteButton>
            </Grid>
            <Grid item xs className="tc-page-container">
              <div className="tc-page-content">
                <Employer {...{ insured, error, setState }} />
                <Employee {...{ employee, error, setState }} />
                <Employment {...{ employment, error, setState }} />
                <Incident {...{ incident, insuredLocation: insured?.location || {}, error, setState }} />
                <CreateAsTeam {...{ createAsTeam, error, setState }} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <WcAddFooter {...{ duplicates, showDocs, showDuplicates, handleCancel, handleSave, setShowDuplicates }} />
    </div>
  );
};

export default () => (
  <WcClaimAddContextProvider>
    <WcClaimAdd />
  </WcClaimAddContextProvider>
);
