import React, { useContext } from "react";
import DocumentsTab from "../../components/common/documents/documentsTab";
import { useRouteParam } from "../../common";
import ActionsAllowed from "../../components/common/contexts/ActionsAllowed";

const Documents = () => {
  const occurrenceId = useRouteParam("occurrenceId");
  const routesAllowed = useContext(ActionsAllowed);
  const access = routesAllowed.find(elem => elem.route === "documents");
  return <DocumentsTab occurrenceId={occurrenceId} actionsAllowed={access} />;
};

export default Documents;
