import * as yup from "yup";
import { dateSchema, isAfterDate, isBeforeDate } from "../../../../../common/yup";

const common = (fn, msg) =>
  yup[fn]()
    .nullable()
    .required(msg);

const number = msg => common("number", msg);

const string = msg => common("string", msg);

const array = path => yup.array().test("", `At least one ${path} is required`, (v, c) => v.length > 0 || c.createError({ path: "toast" }));
const formShape = {
  number: string("Number Required"),
  legalNumber: string("Legal Number is required"),
  effectiveDate: dateSchema("Effective Date is required"),
  expirationDate: dateSchema("Expiration Date is required").test("isDateAfterDate", "Expiration Date has to be after Effective date.", (v, c) =>
    isAfterDate(v, c?.parent?.effectiveDate)
  ),
  retroactiveDate: yup
    .string()
    .nullable()
    .test("isDateBeforeDate", "Retroactive Date has to be before Effective date.", (v, c) => isBeforeDate(v, c?.parent?.effectiveDate)),
  producerId: number("Issuing Carrier is required"),
  insurerId: number("Primary Carrier is required")
};

const aggregateSchema = yup.object().shape({
  attachmentBasisId: number("Attachment Basis is Required"),
  attachmentPoint: number("Attachment Point is Required"),
  retentionDeductible: number("Attachment Point is Required"),
  maximumRetention: number("Attachment Point is Required")
});

export const formSchema = (typeId, isAdd) =>
  yup.object().shape({
    ...formShape,
    ...(isAdd
      ? {
          ...(typeId === 2 ? { vehicles: array("Vehicle") } : {}),
          ...(typeId === 4 ? { properties: array("Property") } : {}),
          ...(typeId === 1 ? { aggregateDetails: aggregateSchema } : { coverageList: array("Coverage") })
        }
      : {})
  });

export const coverageSchema = yup.object().shape({
  coverageTypeId: number("Coverage Type is Required"),
  limit: number("Limit is Required"),
  reportingThreshold: yup
    .string()
    .nullable()
    .test("", "Reporting Threshold is Required", (o, c) => !c.parent.reportingThresholdField || !!o)
});

export const vehicleSchema = yup.object().shape({
  number: string("Number is Required"),
  vin: string("Vin is Required"),
  model: string("Model is Required"),
  vehicleBodyTypeId: number("Vehicle Type is Required"),
  plateNumber: string("Plate Number is Required"),
  vehicleYear: string("Vehicle Year is Required"),
  vehicleMakeId: number("Vehicle Make is Required"),
  vehicleStateId: number("Vehicle State is Required")
});

export const propertySchema = yup.object().shape({
  propertyTypeId: string("Property Type is required"),
  location: yup.object().shape({
    stateId: string("Property location State is required")
  }),
  mortgageCompany: yup.object().when("hasMortgage", {
    is: true,
    then: yup.object().shape({
      name: string("Property location State is required"),
      email: yup.string().email("Invalid Email"),
      location: yup.object().shape({
        stateId: string("Mortgage company location State is required")
      })
    })
  })
});
