import { v4 as uuid } from "uuid";
import initState from "./initState";
import actionTypes from "./actionTypes";
import { filterByParentId } from "../../../../common";

const users = { adjusterUserId: "", supervisorUserId: "", assistantUserId: "" };

const lossTypeGroup = (ltg, ev, fromInitialReport) => ({
  ...ltg,
  ...users,
  ...(ev
    ? { isReportOnly: fromInitialReport || ltg.isReportOnly }
    : { isReportOnly: fromInitialReport || null, checked: false, lossTypeId: undefined, causeCodeId: undefined })
});

const popProperty = (state, p) => {
  const iProp = initState.damagedProperty.property;
  const property = { ...p, location: p.location || iProp.location, mortgageCompany: p.mortgageCompany || iProp.mortgageCompany };
  if (!property.mortgageCompany.location) property.mortgageCompany.location = iProp.mortgageCompany.location;
  return { ...state, damaged: state.damaged ? { ...state.damaged, isClaimantMortgageCompany: false } : {}, property };
};

export default (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.START_OVER: {
      const mapProps = props => props.reduce((acc, p) => ({ ...acc, [p]: initState[p] }), {});
      switch (state.step) {
        case 0: {
          return {
            ...state,
            coverageTypes: state.coverageTypes.map(ct => ({
              ...ct,
              checked: false,
              lossTypeGroups: ct.lossTypeGroups.map(ltg => lossTypeGroup(ltg, undefined, state.fromInitialReport))
            })),
            ...mapProps([
              "multipleClaimants",
              "duplicates",
              "claimant",
              "claimants",
              "insured",
              "insProperties",
              "insVehicles",
              "insVehicle",
              "insProperty"
            ])
          };
        }
        case 1:
          return {
            ...state,
            ...mapProps(["incident", "claimant", "claimants", "damagedVehicle", "damagedProperty", "bodilyInjury"])
          };
        case 2:
          return { ...state, ...mapProps(["witness", "documents", "attorneys"]) };
        case 3: {
          if (!state.multipleClaimants)
            return {
              ...state,
              coverageTypes: state.coverageTypes.map(ct => ({
                ...ct,
                lossTypeGroups: ct.lossTypeGroups.map(ltg => ({ ...ltg, ...users, isReportOnly: state.fromInitialReport || null }))
              }))
            };
          return { ...state, claimants: state.claimants.map(c => ({ ...c, ...users, isReportOnly: state.fromInitialReport || null })) };
        }
        default:
          return state;
      }
    }
    case actionTypes.PREV_STEP:
      if (state.step > 0) return { ...state, step: state.step - 1 };
      return state;
    case actionTypes.NEXT_STEP:
      if (state.step < 4) return { ...state, step: state.step + 1 };
      return state;
    case actionTypes.TOGGLE_DUPLICATE:
      return { ...state, duplicates: state.duplicates.map(d => ({ ...d, open: String(d.id) === String(payload) ? !d.open : d.open })) };
    case actionTypes.SET_MULTIPLE_CLAIMANTS: {
      const { name, value } = payload;
      return {
        ...state,
        [name]: value === "1",
        claimant: initState.claimant,
        claimants: initState.claimants,
        coverageTypes: state.coverageTypes.map(ct => ({
          ...ct,
          checked: false,
          lossTypeGroups: ct.lossTypeGroups.map(ltg => lossTypeGroup(ltg, undefined, state.fromInitialReport))
        }))
      };
    }
    case actionTypes.SET_DUPLICATES:
      return { ...state, duplicates: payload.map(d => ({ ...d, open: state.duplicates.some(_d => d.id === _d.id && _d.open) })) };
    case actionTypes.ADD_CLAIMANT: {
      const { bodilyInjury, claimant, damagedVehicle, damagedProperty } = initState;
      const { coverageTypes, insProperty } = state;
      const id = (state.claimants.sort((a, b) => b.id - a.id)[0] || { id: 0 }).id + 1;
      let claimants = [
        {
          id,
          ...claimant,
          damagedVehicle,
          damagedProperty:
            coverageTypes.some(lob => lob.id === 3 && lob.lossTypeGroups.some(ltg => ltg.checked)) && insProperty
              ? popProperty(damagedProperty, insProperty)
              : damagedProperty,
          bodilyInjury,
          ...users,
          ...payload
        },
        ...state.claimants.map(c => ({ ...c, closed: true }))
      ];
      claimants = claimants.map((x, i) => ({ ...x, order: claimants.length - i }));
      return { ...state, claimants };
    }
    case actionTypes.DELETE_CLAIMANT: {
      const { id, handleValidation } = action;
      let claimants = state.claimants.filter(c => c.id !== id);
      claimants = claimants.map((x, i) => ({ ...x, order: claimants.length - i }));
      handleValidation({ claimants });
      return { ...state, claimants };
    }
    case actionTypes.SET_INIT_COVERAGE_TYPES: {
      if (state.lobLoaded) return state;
      const { coverageTypes, claimLossTypeGroups } = payload;
      return {
        ...state,
        lobLoaded: true,
        coverageTypes: coverageTypes
          .filter(({ id }) => [1, 2, 3].some(i => i === id))
          .map(ct => {
            const existing = state.coverageTypes.find(x => x.id === ct.id);
            if (typeof existing !== "undefined") return { ...existing, checked: false };
            return {
              ...ct,
              checked: false,
              lossTypeGroups: filterByParentId(claimLossTypeGroups, ct.id).map(ltg => lossTypeGroup(ltg, true, state.fromInitialReport))
            };
          })
      };
    }
    case actionTypes.SET_INSURED:
      return { ...state, insured: { ...payload } };
    case actionTypes.SET_INS_VEHICLES: {
      let insVehicle = {};
      if (!payload.data.some(({ id }) => id === state.insVehicle.id)) insVehicle = { id: undefined, vehicleState: "" };
      return { ...state, insVehicles: { ...payload }, insVehicle: { ...state.insVehicle, ...insVehicle } };
    }
    case actionTypes.SELECT_INS_VEHICLE: {
      const v = state.insVehicles.data.filter(({ id }) => id === payload.value);
      if (!v.length) return { ...state, insVehicle: { ...state.insVehicle, id: undefined, vehicleState: "" } };
      return { ...state, insVehicle: { ...state.insVehicle, id: payload.value, vehicleState: v[0].vehicleState } };
    }
    case actionTypes.SET_INS_PROPERTIES: {
      const resetDmgProp =
        state.coverageTypes.some(lob => lob.id === 3 && lob.lossTypeGroups.some(ltg => ltg.checked)) &&
        (payload.data.length
          ? !state.damagedProperty.property.id || !payload.data.find(({ id }) => id === state.damagedProperty.property.id)
          : !!state.damagedProperty.property.id);

      return {
        ...state,
        insProperties: { ...payload },
        insProperty: (payload.data.find(({ id }) => id === state.insProperty.id) ? state : initState).insProperty,
        damagedProperty: { ...state.damagedProperty, property: { ...(resetDmgProp ? initState : state).damagedProperty.property } }
      };
    }
    case actionTypes.SET_INS_PROPERTY: {
      const p = state.insProperties.data.find(({ id }) => id === payload.value);
      if (!p) return { ...state, insProperty: initState.insProperty };
      return {
        ...state,
        insProperty: p,
        damagedProperty: popProperty(state.damagedProperty, p),
        claimants: state.claimants.map(c => ({ ...c, damagedProperty: popProperty(c.damagedProperty, p) }))
      };
    }
    case actionTypes.SET_INCIDENT: {
      const { name, value } = payload;
      return { ...state, incident: { ...state.incident, [name]: name === "occurrenceId" ? parseInt(value, 0) : value } };
    }
    case actionTypes.SET_OCCURRENCES:
      return { ...state, occurrences: payload };
    case actionTypes.SET_LINE_OF_BUSINESS: {
      const [n, id, ltgId] = payload.name.split("-");
      const isMultiCheck = (multi = true) => n === "checked" && state.multipleClaimants === multi;
      const isNotMatch = ltg => (isMultiCheck() && payload.checked ? lossTypeGroup(ltg, undefined, state.fromInitialReport) : ltg);
      const ns =
        !state.multipleClaimants && n === "lob"
          ? {
              insProperty: initState.insProperty,
              insVehicle: initState.insVehicle,
              coverageTypes: state.coverageTypes.map(ct => ({
                ...ct,
                checked: String(ct.id) === id,
                lossTypeGroups: ct.lossTypeGroups.map(ltg => ({
                  ...lossTypeGroup(ltg, undefined, state.fromInitialReport),
                  checked: String(ct.id) === id && ct.lossTypeGroups.length === 1
                }))
              }))
            }
          : {
              insProperty: n === "checked" ? initState.insProperty : state.insProperty,
              insVehicle: n === "checked" ? initState.insVehicle : state.insVehicle,
              coverageTypes: state.coverageTypes.map(ct => ({
                ...ct,
                lossTypeGroups: ct.lossTypeGroups.map(ltg =>
                  String(ct.id) === id && String(ltg.id) === ltgId
                    ? lossTypeGroup(
                        { ...ltg, [n]: payload[isMultiCheck() ? "checked" : "value"] },
                        !isMultiCheck(false) || payload.value,
                        state.fromInitialReport
                      )
                    : isNotMatch(ltg)
                )
              }))
            };
      if (typeof action.onSetState === "function") action.onSetState(ns);
      return { ...state, ...ns };
    }
    case actionTypes.SET_CLAIMANT: {
      const [multi, id, name] = payload.name.split("-");
      const mapTypeId = c => ({ ...c, ...initState.claimant, claimantTypeId: payload.value, isPerson: payload.value === 1 });
      const mapLocation = (name1, name2) => c => ({ ...c, [name1]: { ...c[name1], [name2]: payload.value } });
      if (multi === "multi") {
        const [name1, name2] = name.split(".");
        const isMatch = fn => ({ ...state, claimants: state.claimants.map(c => (String(c.id) === id ? fn(c) : c)) });
        if (name1 === "location" || name1 === "mailAddress") return isMatch(mapLocation(name1, name2));
        if (name1 === "claimantTypeId") return isMatch(mapTypeId);
        return isMatch(c => ({ ...c, [name]: payload.value }));
      }
      const [name1, name2] = payload.name.split(".");
      if (name1 === "location" || name1 === "mailAddress") return { ...state, claimant: mapLocation(name1, name2)(state.claimant) };
      if (payload.name === "claimantTypeId") return { ...state, claimant: mapTypeId(state.claimant) };
      return { ...state, claimant: { ...state.claimant, [payload.name]: payload.value } };
    }
    case actionTypes.SET_DAMAGED_VEHICLE: {
      const { name, value } = payload;
      const [path, name1, name2, name3] = name.split(".");
      const claimantId = path === "dv" ? undefined : parseInt(path.split("-")[1], 0);
      const buildDamagedVehicle = ps => {
        if (name3)
          return {
            ...ps,
            [name1]: { ...ps[name1], [name2]: { ...ps[name1][name2], [name3]: value } }
          };
        if (name2) return { ...ps, [name1]: { ...ps[name1], [name2]: value } };
        if (name1 === "isDriverOwner") return { ...ps, [name1]: value === 1 };
        return { ...ps, [name1]: value };
      };
      if (claimantId)
        return {
          ...state,
          claimants: state.claimants.map(c => (c.id === claimantId ? { ...c, damagedVehicle: buildDamagedVehicle(c.damagedVehicle) } : c))
        };
      return { ...state, damagedVehicle: buildDamagedVehicle(state.damagedVehicle) };
    }
    case actionTypes.SET_DAMAGED_PROPERTY: {
      const { name, value } = payload;
      const [multi, name1, name2, name3, name4] = name.split(".");
      if (name1 === "property" && name2 === "id") {
        const insProperty = state.insProperties.data.find(({ id }) => id === value) || {};
        return { ...state, insProperty, damagedProperty: popProperty(state.damagedProperty, insProperty) };
      }
      const claimantId = multi === "dp" ? undefined : parseInt(multi.split("-")[1], 0);
      const buildDamagedProperty = ps => {
        if (name4)
          return {
            ...ps,
            [name1]: { ...ps[name1], [name2]: { ...ps[name1][name2], [name3]: { ...ps[name1][name2][name3], [name4]: value } } }
          };
        if (name3)
          return {
            ...ps,
            [name1]: { ...ps[name1], [name2]: { ...ps[name1][name2], [name3]: value } }
          };
        if (name2) return { ...ps, [name1]: { ...ps[name1], [name2]: value } };
        return { ...ps, [name1]: value };
      };
      if (claimantId)
        return {
          ...state,
          claimants: state.claimants.map(c => (c.id === claimantId ? { ...c, damagedProperty: buildDamagedProperty(c.damagedProperty) } : c))
        };
      return { ...state, damagedProperty: buildDamagedProperty(state.damagedProperty) };
    }
    case actionTypes.SET_BODILY_INJURY: {
      const [multi, id, fName] = payload.name.split("-");
      const dod = (n, st) => ({ claimantDateofDeath: n === "isDeath" && !payload.value ? undefined : st.bodilyInjury?.claimantDateofDeath });
      if (multi === "multi") {
        const [, name] = fName.split(".");
        return {
          ...state,
          claimants: state.claimants.map(c =>
            String(c.id) === id ? { ...c, bodilyInjury: { ...c.bodilyInjury, ...dod(name, c), [name]: payload.value } } : c
          )
        };
      }
      const [, name] = payload.name.split(".");
      return { ...state, bodilyInjury: { ...state.bodilyInjury, ...dod(name, state), [name]: payload.value } };
    }
    case actionTypes.SET_INS_VEHICLE:
      return { ...state, insVehicle: { ...state.insVehicle, [payload.name]: payload.value } };
    case actionTypes.SET_INS_VEHICLE_DRIVER:
      return { ...state, insVehicle: { ...state.insVehicle, driver: { ...state.insVehicle.driver, [payload.name]: payload.value } } };
    case actionTypes.SET_INS_VEHICLE_DRIVER_LOCATION:
      return {
        ...state,
        insVehicle: {
          ...state.insVehicle,
          driver: { ...state.insVehicle.driver, location: { ...state.insVehicle.driver.location, [payload.name]: payload.value } }
        }
      };
    case actionTypes.SET_WITNESS:
      if (payload.id) return { ...state, witness: state.witness.map(w => (w.id === payload.id ? payload : w)) };
      return { ...state, witness: [...state.witness, { ...payload, id: uuid() }] };
    case actionTypes.DELETE_WITNESS:
      return { ...state, witness: state.witness.filter(w => w.id !== payload) };
    case actionTypes.SET_DOCUMENTS: {
      const [id, path] = payload.name.split(".");
      return {
        ...state,
        documents: state.documents.map(d => (d.id === id ? { ...d, [path]: payload.value } : d))
      };
    }
    case actionTypes.SET_DOCUMENTS_UPLOAD:
      return {
        ...state,
        documents: [...state.documents, ...[...payload].map((_f, i) => ({ id: uuid(), file: payload[i] }))]
      };
    case actionTypes.DEL_DOCUMENT:
      return { ...state, documents: state.documents.filter(d => d.id !== action.id) };
    case actionTypes.SET_ATTORNEY: {
      const mapAttorney = att => {
        const { lawFirmId, attorneyId } = att;
        let firm = state.firms.find(f => f.id === lawFirmId);
        if (!firm?.address1) firm = state.lawyers.find(f => f.id === attorneyId) || {};
        const { address1, city, state: s, zipCode } = firm;
        return { ...att, address1, city, state: s, zipCode };
      };
      if (payload.id) return { ...state, attorneys: state.attorneys.map(a => (a.id === payload.id ? mapAttorney(payload) : a)) };
      return { ...state, attorneys: [...state.attorneys, mapAttorney({ ...payload, id: uuid() })] };
    }
    case actionTypes.DELETE_ATTORNEY:
      return { ...state, attorneys: state.attorneys.filter(({ id }) => id !== payload) };
    case actionTypes.SET_CREATE_AS: {
      const [, id, ltgId] = payload.name.split("-");
      const setTeam = d => {
        const isReportOnly = payload.value === "1";
        const paths = [
          ["assistantUserId", "assistantId"],
          ["adjusterUserId", "adjusterId"],
          ["supervisorUserId", "supervisorId"]
        ];
        return {
          ...d,
          isReportOnly,
          ...paths.reduce((acc, [s, t]) => ({ ...acc, [s]: isReportOnly ? users[s] : state.insured[t] || users[s] }), {})
        };
      };
      if (!state.multipleClaimants)
        return {
          ...state,
          coverageTypes: state.coverageTypes.map(ct =>
            String(ct.id) === id
              ? {
                  ...ct,
                  lossTypeGroups: ct.lossTypeGroups.map(ltg => (String(ltg.id) === ltgId ? setTeam(ltg) : ltg))
                }
              : ct
          )
        };
      return { ...state, claimants: state.claimants.map(c => (String(c.id) === id ? setTeam(c) : c)) };
    }
    case actionTypes.SET_USER: {
      const [, id, ltgId, path] = payload.name.split("-");
      if (!state.multipleClaimants)
        return {
          ...state,
          coverageTypes: state.coverageTypes.map(ct =>
            String(ct.id) === id
              ? {
                  ...ct,
                  lossTypeGroups: ct.lossTypeGroups.map(ltg => (String(ltg.id) === ltgId ? { ...ltg, [path]: payload.value } : ltg))
                }
              : ct
          )
        };
      return { ...state, claimants: state.claimants.map(c => (String(c.id) === id ? { ...c, [path]: payload.value } : c)) };
    }
    case actionTypes.SET_FOLDER_SETTINGS:
      return { ...state, folderSettings: action.settings };
    case actionTypes.SET_LAWYERS_FIRMS: {
      const { lawyers, firms } = action;
      return { ...state, lawyers, firms };
    }
    case actionTypes.SET_SOURCE_INIT_REPORT: {
      const { fromInitialReport } = action;
      return { ...state, fromInitialReport };
    }
    default:
      return state;
  }
};
