import React, { createContext, useContext as rUseContext, useReducer, useEffect } from "react";
import initState from "./initState";
import actionTypes from "./actionTypes";
import reducer from "./reducer";
import useYupValidationSchema from "../../../common/customHooks/useYupValidationSchema";
import { schemaStep1, schemaStep2, schemaStep3, schemaStep4 } from "./yup";
import { useDebounce } from "../../../../common";
import useAxios from "../../../common/customHooks/useAxios";
import useQueryString from "../../../common/customHooks/useQueryString";

const ClaimAddContext = createContext();

const ClaimAddContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initState);
  const handleChange = (e, action, onSetState) => {
    const { name, value, checked } = e.target;
    dispatch({ type: action, payload: { name, value, checked }, onSetState });
  };
  const { ir } = useQueryString();
  const step1 = useYupValidationSchema(schemaStep1);
  const step2 = useYupValidationSchema(schemaStep2);
  const step3 = useYupValidationSchema(schemaStep3);
  const step4 = useYupValidationSchema(schemaStep4);
  const validation = {
    step1,
    step2,
    step3,
    step4,
    clearMessages: () => validation[`step${state.step + 1}`].handleValidationMessages(),
    validate: ext => {
      const { step } = state;
      const validateData = { ...state, ...(typeof ext === "object" ? { ...ext } : {}) };
      const v = validation[`step${step + 1}`].handleValidateSchema(validateData);
      return { ...v, validateData };
    }
  };
  const handleValidation = useDebounce(
    (...p) =>
      new Promise((res, rej) => {
        try {
          const result = validation.validate(...p);
          res(result);
        } catch (err) {
          rej(err);
        }
      }),
    1000
  );
  const { sendRequest } = useAxios();
  useEffect(() => {
    sendRequest({
      request: [{ url: `/attorney` }, { url: `/lawfirm/getAll` }],
      onSucces: response => {
        if (response.length > 1)
          dispatch({
            type: actionTypes.SET_LAWYERS_FIRMS,
            lawyers: response[0].data,
            firms: response[1].data
          });
      },
      multiple: true
    });
  }, []);
  useEffect(() => {
    dispatch({ type: "SET_SOURCE_INIT_REPORT", fromInitialReport: ir === "1" });
  }, [ir]);
  return <ClaimAddContext.Provider value={[state, dispatch, handleChange, { ...validation, handleValidation }]} {...props} />;
};

const getFolders = state => {
  const loadFolders = (acc, f) => {
    const lacc = [...acc, ...(acc.find(a => a.id === f.id) ? [] : [{ id: f.id, text: f.name }])];
    if (Array.isArray(f.folders) && f.folders.length > 0) return [...lacc, ...f.folders.reduce(loadFolders, [])];
    return lacc;
  };
  const lobSelected = state.coverageTypes.reduce(
    (acc, { id: claimLineOfBusinessId, lossTypeGroups }) => [
      ...acc,
      ...lossTypeGroups.reduce((lacc, { id: claimLossTypeGroupId, checked }) => {
        if (!checked) return lacc;
        return [...lacc, { claimLineOfBusinessId, claimLossTypeGroupId }];
      }, [])
    ],
    []
  );
  const folderSettings = state.folderSettings.filter(
    ({ stateId, claimLineOfBusinessId, claimLossTypeGroupId }) =>
      (!stateId || stateId === (state.incident || {}).stateId) &&
      lobSelected.some(l => l.claimLineOfBusinessId === claimLineOfBusinessId && l.claimLossTypeGroupId === claimLossTypeGroupId)
  );
  return folderSettings.reduce((acc, setting) => {
    const other = folderSettings.filter(
      s =>
        s.id !== setting.id &&
        s.stateId &&
        s.claimLineOfBusinessId === setting.claimLineOfBusinessId &&
        s.claimLossTypeGroupId === setting.claimLossTypeGroupId &&
        s.stateId === (state.incident || {}).stateId
    );
    if (other.length > 0 || !Array.isArray(setting.folders) || setting.folders.length === 0) return acc;
    return [...acc, ...setting.folders.reduce(loadFolders, [])];
  }, []);
};

const useContext = () => rUseContext(ClaimAddContext);

export { actionTypes, ClaimAddContextProvider, getFolders, useContext };
