import { CLIENTR as CLIENT, API } from "../../actions/actionTypes";
import { getInsuredsSuccess, deleteInsuredSuccess, addInsuredSuccess } from "../../actions/client/insureds";
import { takeLatest, takeLatestDel, takeLatestPost } from "../common/sagaGenerator";

export default [
  takeLatest({
    type: CLIENT.INSUREDS.GET,
    actionSuccess: getInsuredsSuccess,
    errorMessage: "Error loading client insureds",
    url: `${API.URL}/client/insureds`,
    params: ({ id }) => ({ id })
  }),
  takeLatestDel({
    type: CLIENT.INSUREDS.DEL,
    actionSuccess: deleteInsuredSuccess,
    successMessage: "Insured was removed successfully",
    errorMessage: "Error Removing Insured",
    url: `${API.URL}/insured`,
    params: ({ id }) => ({ id })
  }),
  takeLatestPost({
    type: CLIENT.INSUREDS.POST,
    actionSuccess: addInsuredSuccess,
    successMessage: "Insured was added successfully",
    errorMessage: "Error Adding Insured",
    url: `${API.URL}/client/addinsured`,
    params: ({ data }) => data
  })
];
