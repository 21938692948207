import { Grid, SvgIcon } from "@material-ui/core";
import { Gavel } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Coverage from "../../../../assets/coverage.svg";
import NoCoverage from "../../../../assets/no_coverage.svg";
import { useLitigationStyles } from "./index.styles";

const some = l => Array.isArray(l) && l.length > 0;

const LitigationPanelInfo = () => {
  const classes = useLitigationStyles();
  const claim = useSelector(({ claim: c }) => c, shallowEqual);
  const {
    claim: { claimLineOfBusinessId: lobId },
    claimantAttorneys,
    defendantAttorneys,
    policy: { coverageList, containsDamagedProperty }
  } = claim;
  const isCovered = (lobId !== 3 || !!containsDamagedProperty) && some(coverageList);
  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          {((some(claimantAttorneys) || some(defendantAttorneys)) && (
            <Grid container spacing={1} alignItems="center">
              <Grid item className={classes.icon}>
                <Gavel className={classes.gavel} />
              </Grid>
              <Grid item className={classes.red}>
                Litigation
              </Grid>
            </Grid>
          )) ||
            "No Litigation"}
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1} alignItems="center">
            <Grid item className={classes.icon}>
              <SvgIcon>{(isCovered && <Coverage />) || <NoCoverage />}</SvgIcon>
            </Grid>
            <Grid item className={clsx(isCovered ? classes.coverage : classes.red)}>
              {(isCovered && "Coverage") || "No coverage"}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LitigationPanelInfo;
