/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import { createBrowserHistory } from "history";
import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import rootReducer from "./reducers";
import rootSaga from "./sagas/index";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const configureStore = (initialState = {}) => {
  const store = createStore(rootReducer(history), initialState, applyMiddleware(sagaMiddleware, routerMiddleware(history)));

  sagaMiddleware.run(rootSaga);

  return store;
};

const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "cb9304d2-12c8-4497-9b90-dd8fb073ddbb",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history }
    }
  }
});
appInsights.loadAppInsights();

export const withInsights = Component => withAITracking(reactPlugin, Component);

export default configureStore;
