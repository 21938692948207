import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Paper, RadioGroup, FormControlLabel, Radio, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import { SelectInput, TextInput, DateTimeInput } from "../../components/common/inputs";
import { post as create } from "../../redux/actions/catastropheAction";
import CatastropheDuplicates from "../../components/catastrophe/CatastropheDuplicates";
import useStyles from "../../components/catastrophe/CatastropheAddPage.styles";
import ActionsFooter, { footerAction } from "../../components/common/ActionsFooter";
import useCustomConfirm from "../../components/common/customHooks/useCustomConfirm";
import useYupValidationSchema from "../../components/common/customHooks/useYupValidationSchema";
import { useLoading } from "../../components/common/Loading";
import MaterialTable from "../../components/common/table/MaterialTable";
import { addSchema } from "../../components/catastrophe/yup";

const CatastropheAddPage = () => {
  const classes = useStyles();
  const [state, setState] = useState({ location: {}, locationList: [] });
  const {
    Loading,
    loading,
    actions: { showLoading, hideLoading }
  } = useLoading(false, true);
  const dispatch = useDispatch();
  const states = useSelector(({ common: { states: s } }) => s, shallowEqual);
  const {
    name,
    dateSince,
    dateTo,
    location: { address1, address2, city, county, stateId, zipCode, stateReportingNumber },
    description,
    multipleLocation = 0,
    locationList
  } = state;
  const { handleValidateSchema, validationMessages } = useYupValidationSchema(addSchema);

  const onChange = ({ target: { name: n, value } }) => {
    if (n.indexOf(".") >= 0) {
      const [a, b] = n.split(".");
      setState(ps => {
        handleValidateSchema({ ...ps, [a]: { ...ps[a], [b]: value } });
        return { ...ps, [a]: { ...ps[a], [b]: value } };
      });
    } else {
      setState(ps => {
        handleValidateSchema({ ...ps, [n]: value });
        return { ...ps, [n]: value };
      });
    }
  };
  const AddLocation = location => {
    const maxId = state.locationList.reduce((max, loc) => (loc.id > max ? loc.id : max), 0);
    const newLocation = { ...location, stateId: parseInt(location.newStateid, 0), id: location.id ? location.id : maxId + 1 };
    setState({
      ...state,
      locationList: location.id ? locationList.filter(x => x.id !== location.id).concat(newLocation) : locationList.concat(newLocation)
    });
  };
  const DelLocation = id => setState({ ...state, locationList: locationList.filter(x => x.id !== id) });
  const history = useHistory();
  const onGoList = () => {
    hideLoading();
    history.replace("/claims/catastrophes");
  };
  const handleSave = ({ data }) => {
    const isMultiple = data.multipleLocation ? parseInt(data.multipleLocation, 0) : 0;
    showLoading();
    dispatch(
      create(
        { ...data, multipleLocation: isMultiple === 1, ...(isMultiple === 0 && data.location != null ? { locationList: [data.location] } : {}) },
        onGoList,
        hideLoading
      )
    );
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleSave, "Cancel", "Yes, Create");
  const handleConfirmSave = () =>
    handleCustomConfirm({
      oData: { data: state },
      titleText: `Add Catastrophe`,
      messageText: `The following Catastrophe will be created: ${name}. Are you sure you want to proceed?`
    });
  const onSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const { isValid } = handleValidateSchema(state);
    if (!isValid) {
      if (validationMessages.toast) toast.warn(validationMessages.toast);
      return false;
    }
    return handleConfirmSave();
  };
  const tableProps = {
    columns: [
      { title: "City", field: "city" },
      {
        title: "State",
        field: "newStateid",
        validate: rowData => Boolean(rowData.newStateid) || `State is Required `,
        lookup: { ...states.reduce((acc, item) => [...acc, item.text], []) }
      },
      { title: "State Reporting Number", field: "stateReportingNumber" }
    ],
    data: locationList || [],
    onDelete: ({ id }) => DelLocation(id),
    onActionSave: AddLocation
  };
  const aLocation = address1 && address2 && city && stateId && zipCode && zipCode.length > 3 ? [{ id: 1, ...state.location }] : [];
  const locationListToFind = multipleLocation > 0 ? locationList : aLocation;

  return (
    <div className="tc-page-container">
      <Grid container wrap="nowrap" className="tc-page-content tc-no-padding">
        <Grid item xs={7} className="tc-page-container">
          <div className={classes.containerh}>
            <div className={classes.titleh}>Add Catastrophe</div>
          </div>
          <div className="tc-page-content">
            {Loading}
            <Paper className={classes.paper}>
              <Typography className="tc-title" gutterBottom>
                New Catastrophe
              </Typography>
              <Grid container spacing={8}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextInput name="name" label="Name" value={name} onChange={onChange} error={validationMessages.name} />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <DateTimeInput
                    name="dateSince"
                    label="Date Since"
                    value={dateSince}
                    onChange={onChange}
                    keyboard
                    error={validationMessages.dateSince}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <DateTimeInput name="dateTo" label="Date To" value={dateTo} onChange={onChange} keyboard error={validationMessages.dateTo} />
                </Grid>
              </Grid>
              <div className="tc-pb2">
                <RadioGroup value={parseInt(multipleLocation, 0)} name="multipleLocation" onChange={onChange} row defaultValue="top">
                  <FormControlLabel control={<Radio />} value={0} label="One Location" />
                  <FormControlLabel control={<Radio />} value={1} label="Multiple Location" />
                </RadioGroup>
              </div>
              {multipleLocation < 1 ? (
                <Grid container spacing={8}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextInput name="location.address1" label="Physical Address 1" value={address1} onChange={onChange} />
                    <TextInput name="location.city" label="City" value={city} onChange={onChange} />
                    <TextInput name="location.county" label="County" value={county} onChange={onChange} />
                    <TextInput name="location.stateReportingNumber" label="State Reporting Number" value={stateReportingNumber} onChange={onChange} />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextInput name="location.address2" label="Physical Address 2" value={address2} onChange={onChange} />
                    <SelectInput
                      name="location.stateId"
                      label="State"
                      value={stateId}
                      options={states}
                      onChange={onChange}
                      error={validationMessages["location.stateId"]}
                    />
                    <TextInput name="location.zipCode" label="Zip" value={zipCode} onChange={onChange} />
                  </Grid>
                </Grid>
              ) : (
                <MaterialTable {...tableProps} hidePaging />
              )}
              <TextInput name="description" label="Description" value={description} onChange={onChange} rows={4} />
            </Paper>
          </div>
          <ActionsFooter
            shadow
            actions={[
              footerAction({ id: "cancel", text: "Cancel", onClick: onGoList, outlined: true, primary: true }),
              footerAction({ id: "save", text: "Add", onClick: onSubmit, primary: true, disabled: loading })
            ]}
          />
        </Grid>
        <Grid item xs={5} className="tc-page-container tc-bg-white tc-shadow-l tc-z-index-1">
          <div className="tc-page-content">
            <Typography className="tc-title" gutterBottom>
              Possible Duplicates
            </Typography>
            <div>
              <CatastropheDuplicates filter={{ locationList: locationListToFind, date: state.dateSince }} />
            </div>
          </div>
        </Grid>
      </Grid>
      <Confirm />
    </div>
  );
};

export default CatastropheAddPage;
