import { API } from "../actions/actionTypes";
import initialState from "./initialState";

function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - 8) === "_SUCCESS";
}

export default function apiCallStatusReducer(state = initialState.apiCallsInProgress, action) {
  if (action.type === API.CALL) {
    return state + 1;
  }
  if (action.type === API.ERROR || actionTypeEndsInSuccess(action.type)) {
    return state - 1;
  }

  return state;
}
