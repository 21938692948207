import rootSagaGenerator from "../common/rootSagaGenerator";
import info from "./info";
import coverage from "./coverage";
import documents from "./documents";
import legal from "./legal";
import reserves from "./reserves";
import financial from "./financial";
import communications from "./communications";
import activity from "./activity";

export default rootSagaGenerator({
  name: "Claim",
  sagas: [...info, ...coverage, ...documents, ...legal, ...reserves, ...financial, ...communications, ...activity]
});
