import { Grid } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useLobStyles } from "./index.styles";
import LossTypeGroupIcon from "../../LossTypeGroupIcon";

const LobPanelInfo = () => {
  const classes = useLobStyles();
  const claim = useSelector(({ claim: { claim: c } }) => c, shallowEqual);
  const { claimLossTypeGroup, claimLossType, claimLineOfBusinessCode, claimLineOfBusinessId, injuryType, type: claimType } = claim;
  const isWC = claimLineOfBusinessId === 4;
  return (
    <div className={classes.root}>
      <Grid container spacing={4} wrap="nowrap">
        <Grid item>
          <small>LOB</small>
          <div className={clsx(classes.rect, classes.value)}>{claimLineOfBusinessCode}</div>
        </Grid>
        {!isWC && (
          <>
            <Grid item className={classes.itemNoWrap}>
              <small>LTG</small>
              <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item className={classes.icon}>
                  <LossTypeGroupIcon type={claimLossTypeGroup} />
                </Grid>
                <Grid item className={classes.textNoWrap}>
                  {claimLossTypeGroup}
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.itemNoWrap}>
              <small>Loss Type</small>
              <div className={clsx(classes.value, classes.textNoWrap)}>{claimLossType}</div>
            </Grid>
          </>
        )}
        {isWC && (
          <>
            <Grid item className={classes.itemNoWrap}>
              <small>Nature Of Injury</small>
              <div className={clsx(classes.value, classes.textNoWrap)}>{injuryType || "-"}</div>
            </Grid>
            <Grid item className={classes.itemNoWrap}>
              <small>Claim Type</small>
              <div className={clsx(classes.value, classes.textNoWrap)}>{claimType || "-"}</div>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default LobPanelInfo;
