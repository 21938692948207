import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import * as yup from "yup";
import moment from "moment";
import { DateTimeInput, SelectInput, InputText, EditorInput } from "../inputs";
import FormFooter from "../FormFooter";
import AssociatedList from "../AssociatedList";
import { box } from "../index.styles";
import { useFormSelector } from "./selectors";
import useYupValidationSchema from "../customHooks/useYupValidationSchema";

const any = arr => Array.isArray(arr) && arr.some(() => true);

const Form = ({ diary, onCancel, entityId, entityType, onSave, isLoading }) => {
  const {
    associatedList,
    common: { taskStatus, taskPriority, taskType, users },
    claimDiaryTemplates: { claimId, templates: claimTemplates },
    occurrenceDiaryTemplates: { occurrenceId, templates: occurrenceTemplates }
  } = useFormSelector();
  const [state, setState] = useState(diary || {});
  useEffect(() => {
    setState(diary);
  }, [diary]);
  const { id, typeId, priorityId, statusId, dueDate, ownerId, name, description, claimIds, templateId } = state;

  const isDate = text =>
    yup
      .string()
      .nullable()
      .test(text, text, (val, context) => {
        if (!val) return false;

        if (moment("1900-01-01").isAfter(moment(val)) || moment("2100-01-01").isBefore(moment(val)))
          return context.createError({ message: "Date out of range" });

        return true;
      });
  const { handleValidateSchema, validationMessages } = useYupValidationSchema(
    yup.object().shape({
      typeId: yup.number().required("Type is Required"),
      statusId: yup.number().required("Status is Required"),
      dueDate: isDate("Due Date is Required"),
      ownerId: yup.string().test("Owner is Required", "Owner is Required", val => val && val.length > 0),
      name: yup
        .string()
        .nullable()
        .required("Diary Name is Required"),
      description: yup
        .string()
        .nullable()
        .required("Description is Required")
    })
  );

  const templates =
    entityId === (entityType === "claim" ? claimId : occurrenceId)
      ? (entityType === "claim" ? claimTemplates : occurrenceTemplates)
          .filter(x => !any(x.taskTypes) || x.taskTypes.some(t => t.id === typeId))
          .map(t => ({ ...t, text: t.name }))
      : [];
  const onChange = ({ target: { name: tName, value } }) =>
    setState(ps => {
      if (tName === "templateId") {
        const temps = templates.filter(t => t.id === value);
        if (any(temps)) {
          handleValidateSchema({ ...ps, [tName]: value, name: temps[0].name, description: temps[0].body });
          return { ...ps, [tName]: value, name: temps[0].name, description: temps[0].body };
        }
      }
      handleValidateSchema({ ...ps, [tName]: value });
      return { ...ps, [tName]: value };
    });
  const anyTemplate = any(templates);
  const onEditAssoClaims = useCallback(vclaimIds => {
    setState(ps => ({ ...ps, claimIds: vclaimIds }));
  });
  const [clearChecks] = useState(false);
  const doubleColumn = entityType !== "claim";
  const classesBox = box();
  const onSaveForm = data => {
    if (!handleValidateSchema(data).isValid) return;
    onSave(data);
  };
  return (
    <>
      {doubleColumn && <div className={classesBox.title}>{id > 0 ? "Edit Diary" : "Add Diary"}</div>}
      <Grid container spacing={5}>
        <Grid item xs>
          <SelectInput name="typeId" label="Type" value={typeId} options={taskType} onChange={onChange} error={validationMessages?.typeId} />
        </Grid>
        <Grid item xs>
          <SelectInput name="priorityId" label="Priority" value={priorityId} options={taskPriority} onChange={onChange} />
        </Grid>
        <Grid item xs>
          <SelectInput
            name="statusId"
            label="Status"
            value={statusId}
            options={taskStatus}
            onChange={onChange}
            error={validationMessages?.statusId}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={4}>
          <DateTimeInput name="dueDate" label="Due Date" value={dueDate} onChange={onChange} keyboard error={validationMessages?.dueDate} />
        </Grid>
        <Grid item xs={anyTemplate ? 4 : 8}>
          <SelectInput name="ownerId" label="Owner" value={ownerId} options={users} onChange={onChange} error={validationMessages?.ownerId} />
        </Grid>
        {anyTemplate && (
          <Grid item xs={4}>
            <SelectInput name="templateId" label="Template" value={templateId} options={templates} onChange={onChange} />
          </Grid>
        )}
      </Grid>
      <InputText name="name" label="Diary Name" value={name} onChange={onChange} error={validationMessages?.name} />
      <EditorInput name="description" value={description || undefined} height={150} {...{ onChange }} error={validationMessages?.description} />
      {doubleColumn && (
        <AssociatedList
          onEdit={onEditAssoClaims}
          entityType={entityType}
          entityId={entityId}
          clearChecks={clearChecks}
          associatedList={associatedList}
          claimIds={claimIds || []}
          relatedToText="Diary"
        />
      )}
      <FormFooter
        isAdd={!diary?.id}
        editMode
        onCancel={onCancel}
        onClick={() => onSaveForm(state)}
        cancelText={doubleColumn && !(id > 0) ? "Clear All" : "Cancel"}
        saveBtnDisabled={isLoading}
      />
    </>
  );
};

Form.propTypes = {
  diary: PropTypes.shape({ id: PropTypes.number }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default Form;
