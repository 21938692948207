import React, { useContext } from "react";
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import MaterialTable from "../table/MaterialTable";
import { PhoneInput } from "../inputs";
import ActionsAllowedContext from "../contexts/ActionsAllowed";
import { isEmail } from "../../../common/yup";

const ClaimWitness = ({ tableTitle, data: src, add, update, remove, routesAllowed, hideExportButton, exportFileName }) => {
  const data = src.map(d => Object.keys(d).reduce((a, p) => ({ ...a, [p]: d[p] === null ? undefined : d[p] }), {}));
  const access = routesAllowed.find(elem => elem.route === "info");
  const validateColumn = (title, field) => ({ title, field, validate: rowData => Boolean(rowData[field]) || `${title} Required` });
  const params = {
    title: tableTitle,
    columns: [
      validateColumn("First Name", "firstName"),
      validateColumn("Last Name", "lastName"),
      { title: "Email", field: "email", validate: ({ email }) => !email || isEmail(email) || "Invalid Email" },
      {
        title: "Phone 1",
        field: "phone",
        editComponent: row => {
          const value = row.rowData?.value ? row.rowData?.value : row.rowData?.phone;
          const phone = row.rowData?.phone?.target ? row.rowData?.phone?.target?.value : value;
          return <PhoneInput name="phone" value={phone} onChange={row.onChange} />;
        }
      },
      {
        title: "Phone 2",
        field: "phone2",
        editComponent: row => {
          const value = row.rowData?.value ? row.rowData?.value : row.rowData?.phone2;
          const phone2 = row.rowData?.phone2?.target ? row.rowData?.phone2?.target?.value : value;
          return <PhoneInput name="phone2" value={phone2} onChange={row.onChange} />;
        }
      },
      { title: "Note", field: "note" }
    ],
    data,
    hideExportButton,
    editable: {
      ...(!access.create
        ? {}
        : {
            onRowAdd: dataRow => {
              return new Promise(resolve => {
                add({
                  ...dataRow,
                  phone: dataRow?.phone?.target ? dataRow?.phone?.target?.value : dataRow?.phone,
                  phone2: dataRow?.phone2?.target ? dataRow?.phone2?.target?.value : dataRow?.phone2
                });
                resolve();
              });
            }
          }),
      ...(!access.update
        ? {}
        : {
            onRowUpdate: dataRow => {
              return new Promise(resolve => {
                update({
                  ...dataRow,
                  phone: dataRow?.phone?.target ? dataRow?.phone?.target?.value : dataRow?.phone,
                  phone2: dataRow?.phone2?.target ? dataRow?.phone2?.target?.value : dataRow?.phone2
                });
                resolve();
              });
            }
          })
    },
    actions: access.delete
      ? [
          {
            icon: () => <DeleteIcon />,
            tooltip: "Delete",
            onClick: (_e, rowData) => {
              _e.stopPropagation();
              remove(rowData);
            }
          }
        ]
      : [],
    options: {
      exportFileName
    }
  };

  return <MaterialTable {...params} />;
};

ClaimWitness.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  add: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  routesAllowed: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableTitle: PropTypes.string,
  hideExportButton: PropTypes.bool,
  exportFileName: PropTypes.string
};

ClaimWitness.defaultProps = { tableTitle: undefined, hideExportButton: false, exportFileName: "export" };

export const BaseClaimWitness = props => {
  const routesAllowed = [{ route: "info", create: true, update: true, delete: true }];
  return <ClaimWitness {...{ routesAllowed }} {...props} />;
};

export default props => {
  const routesAllowed = useContext(ActionsAllowedContext);
  return <ClaimWitness {...{ routesAllowed }} {...props} />;
};
