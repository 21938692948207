import { INITIALREPORT as IREPORT, API } from "../../actions/actionTypes";
import { getSuccess } from "../../actions/initialReport/activity";
import { takeLatest } from "../common/sagaGenerator";

export default [
  takeLatest({
    type: IREPORT.ACTIVITY.GET,
    actionSuccess: getSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading Initial Report Activity",
    url: `${API.URL}/claimactivity`
  })
];
