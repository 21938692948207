import { INITIALREPORT as REPORT } from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.initialReport, action) => {
  switch (action.type) {
    case REPORT.GETALL_SUCCESS:
      return { ...state, initialReports: action.reports };
    case REPORT.CLAIM.GET_SUCCESS:
    case REPORT.CLAIM.SET_SUCCESS:
      return { ...state, claim: action.data ? action.data : action.claim };
    case REPORT.INFORMATION.CLAIMANT.GET_SUCCESS:
    case REPORT.INFORMATION.CLAIMANT.SET_SUCCESS:
      return { ...state, information: { ...state.information, claimant: action.data ? action.data : action.claimant } };
    case REPORT.INFORMATION.DAMAGEDVEHICLE.GET_SUCCESS:
    case REPORT.INFORMATION.DAMAGEDVEHICLE.SET_SUCCESS:
      return {
        ...state,
        information: { ...state.information, damagedVehicle: action.damagedVehicle || initialState.initialReport.information.damagedVehicle }
      };
    case REPORT.INFORMATION.WITNESS.GET_SUCCESS:
    case REPORT.INFORMATION.WITNESS.GETALL_SUCCESS:
    case REPORT.INFORMATION.WITNESS.SET_SUCCESS:
    case REPORT.INFORMATION.WITNESS.POST_SUCCESS:
    case REPORT.INFORMATION.WITNESS.DEL_SUCCESS:
      return { ...state, information: { ...state.information, witnesses: action.data } };
    case REPORT.INFORMATION.INSURED.GET_SUCCESS:
    case REPORT.INFORMATION.INSURED.SET_SUCCESS:
      return { ...state, information: { ...state.information, insured: action.insured } };
    case REPORT.INFORMATION.INSUREDVEHICLE.GET_SUCCESS:
    case REPORT.INFORMATION.INSUREDVEHICLE.SET_SUCCESS:
      return {
        ...state,
        information: { ...state.information, insuredVehicle: action.insuredVehicle || initialState.initialReport.information.insuredVehicle }
      };
    case REPORT.INFORMATION.BODILYINJURY.GET_SUCCESS:
    case REPORT.INFORMATION.BODILYINJURY.SET_SUCCESS:
      return {
        ...state,
        information: { ...state.information, bodilyInjury: action.bodilyInjury || initialState.initialReport.information.bodilyInjury }
      };
    case REPORT.INFORMATION.PROPERTY.GET_SUCCESS:
    case REPORT.INFORMATION.PROPERTY.SET_SUCCESS:
      return {
        ...state,
        information: { ...state.information, propertyDamaged: action.property || initialState.initialReport.information.propertyDamaged }
      };

    case REPORT.CLEAR:
      return {
        ...state,
        claim: initialState.initialReport.claim,
        information: initialState.initialReport.information
      };
    case REPORT.ACTIVITY.GET_SUCCESS:
      return { ...state, activity: action.activity };
    case REPORT.GET_SECPROF_FILTER_SUCCESS:
      return { ...state, filterfields: action.filterfields };
    default:
      return state;
  }
};
