import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    // paddingTop: "10px"
  },
  formView: {
    paddingBottom: "30px"
  },
  title: {
    color: "#898F91",
    fontSize: "11px",
    paddingTop: "10px"
  },
  pr1: {
    paddingRight: theme.spacing(1)
  },
  pl1: {
    paddingLeft: theme.spacing(1)
  },
  marginDevider: {
    marginBottom: theme.spacing(2)
  },
  buttonWrapper: {
    "& > *": {
      margin: theme.spacing(1)
    }
  }
}));
