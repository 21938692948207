import React, { useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { reducer, initialState } from "../../components/vendors/add_vendor/reducer";
import styles from "./index.styles";
import Basic from "../../components/vendors/add_vendor/Basic";
import Industry from "../../components/vendors/add_vendor/Industry";
import { addVendor } from "../../redux/actions/vendor";
import useCustomConfirm from "../../components/common/customHooks/useCustomConfirm";
import { useLoading } from "../../components/common/Loading";
import ActionsFooter, { footerAction } from "../../components/common/ActionsFooter";

const VendorAdd = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fireValidation, setFireValidation] = useState(false);
  const {
    loading,
    Loading,
    actions: { showLoading, hideLoading }
  } = useLoading(true);
  const dispatchStore = useDispatch();
  const { basicInformation, industry } = state;
  const classes = styles();

  const history = useHistory();
  const handleRedirect = () => {
    hideLoading();
    return history.push("/vendors");
  };
  const handleSave = ({ data }) => {
    showLoading();
    dispatchStore(addVendor(data, handleRedirect, hideLoading));
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleSave, "Cancel", "Yes, Create");
  const handleConfirmCreate = nType => {
    if (state?.validSchema) {
      handleCustomConfirm({
        oData: { ...nType, data: state },
        titleText: `New Vendor`,
        messageText: (
          <>
            <p>The following vendor will be created:</p>
            <p>
              <b>{basicInformation.isPerson ? `${basicInformation.firstName} ${basicInformation.lastName}` : basicInformation.name}</b>
            </p>
            <p>Are you sure you want to proceed?</p>
          </>
        )
      });
    } else {
      setFireValidation(prev => !prev);
    }
  };
  return (
    <div className="tc-page-container">
      <AppBar position="static" color="inherit" className={classes.appNavBarHeader}>
        <Toolbar>
          <Typography variant="h6">Add Vendor</Typography>
        </Toolbar>
      </AppBar>
      {Loading}
      <div className="tc-page-content">
        <Basic data={basicInformation} setChange={dispatch} {...{ fireValidation }} />
        <Industry data={industry} setChange={dispatch} />
      </div>
      <ActionsFooter
        shadow
        actions={[
          footerAction({ id: "cancel", text: "Cancel", onClick: handleRedirect, outlined: true, primary: true }),
          footerAction({ id: "save", text: "Add Vendor", onClick: handleConfirmCreate, primary: true, disabled: loading })
        ]}
      />
      <Confirm />
    </div>
  );
};

export default VendorAdd;
