import React from "react";
import PropTypes from "prop-types";
import { Grid, Stepper, Step, StepLabel, StepIcon } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { useClaimAddHeaderStyles, withStepStyles } from "./index.styles";
import { useContext } from "../context";
import { colors } from "../../../App.styles";

const StyleStepIcon = withStepStyles(StepIcon);

const CompleteIcon = ({ completed, ...other }) => (completed ? <CheckCircle style={{ color: colors.green }} /> : <StyleStepIcon {...other} />);

CompleteIcon.propTypes = {
  completed: PropTypes.bool.isRequired
};

const ClaimAddHeader = () => {
  const classes = useClaimAddHeaderStyles();
  const { label, active, completed } = classes;
  const [{ step }] = useContext();
  return (
    <Grid container className={classes.container} alignItems="center">
      <Grid item className={classes.title}>
        Add Claim / Initial Report
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <Stepper alternativeLabel activeStep={step} className={classes.root}>
          {[0, 1, 2, 3].map(s => (
            <Step key={s}>
              <StepLabel StepIconComponent={CompleteIcon} classes={{ label, active, completed }}>{`Step ${s + 1}`}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default ClaimAddHeader;
