import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import { Grid, RadioGroup, FormControlLabel, Radio, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useContext, actionTypes } from "../context";
import { SelectInput } from "../../../common/inputs";
import { customRadioStyles } from "./index.styles";

const CustomRadio = props => <FormControlLabel control={<Radio size="small" />} {...props} classes={customRadioStyles()} />;

const CreateAsTeam = ({ classes, claim }) => {
  const [, , handleChange, { handleValidation }] = useContext();
  const { adjusterUsers, supervisorUsers, assistantUsers } = useSelector(({ common }) => common, shallowEqual);
  const isReportOnly = [true, false].indexOf(claim.isReportOnly) >= 0 ? (claim.isReportOnly === true && 1) || 0 : null;
  const disabled = isReportOnly !== 0;
  const onChange = type => e => {
    handleChange(e, type);
    return handleValidation();
  };
  const handleCreateAs = onChange(actionTypes.SET_CREATE_AS);
  const handleUsers = onChange(actionTypes.SET_USER);
  const inputProps = path => ({
    value: claim[path],
    name: `${claim.divId}-${path}`,
    onChange: handleUsers,
    disabled,
    error: claim.errors[path]
  });
  return (
    <Grid item style={{ width: 300 }}>
      <div className={clsx(classes.createAs, isReportOnly === null ? classes.error : null)}>
        <Typography className="tc-title">Create As</Typography>
        <RadioGroup value={isReportOnly} name={claim.divId} className={classes.radio} onChange={handleCreateAs}>
          <CustomRadio value={1} label="Report Only" />
          <CustomRadio value={0} label="Claim" />
        </RadioGroup>
      </div>
      <div className={clsx(classes.users, disabled ? classes.usersDisabled : null)}>
        <Typography className="tc-title" style={{ marginBottom: 11 }}>
          Assign User
        </Typography>
        <SelectInput options={adjusterUsers} {...inputProps("adjusterUserId")} label="Adjuster *" />
        <SelectInput options={supervisorUsers} {...inputProps("supervisorUserId")} label="Supervisor *" />
        <SelectInput options={assistantUsers} {...inputProps("assistantUserId")} label="Assistant" />
      </div>
    </Grid>
  );
};

CreateAsTeam.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  claim: PropTypes.objectOf(PropTypes.any).isRequired
};

export default CreateAsTeam;
