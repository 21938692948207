import { CircularProgress, Grid } from "@material-ui/core";
import { CloudOff, DomainDisabled } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { api } from "../../api/apiUtils";
import authentication from "../../auth";
import LinkButton from "../common/LinkButton";

const callUserStatus = fnSetState =>
  api({ url: `${process.env.API_URL}/apistatus/db` })
    .then(d => {
      const isComplete = d?.dbActive || (d?.tenantChecked && !d?.tenantExists);
      return fnSetState(ps => ({
        ...ps,
        ...d,
        retry: isComplete ? 0 : ps.retry + 1,
        maxRetries: isComplete ? 0 : ps.maxRetries
      }));
    })
    .catch(() => fnSetState(ps => ({ ...ps, retry: ps.retry + 1 })));

const dbError = ({ maxRetries }) => {
  if (maxRetries > 1) return `Client's DB was not available after ${maxRetries} retries`;
  return "Client's DB is not available";
};

export const useInitialLoading = () => {
  const [userStatus, setUserStatus] = useState({ retry: 0, maxRetries: 5 });
  useEffect(() => {
    callUserStatus(setUserStatus);
  }, []);
  useEffect(() => {
    if (userStatus.retry > 0 && userStatus.retry <= userStatus.maxRetries) setTimeout(() => callUserStatus(setUserStatus), 1500);
  }, [userStatus]);
  const onRetry = useCallback(() => setUserStatus(ps => ({ ...ps, retry: 1, maxRetries: 1 })), []);
  return { userStatus, onRetry };
};

const InitialLoading = ({ userStatus, onRetry }) => {
  return (
    <Grid container alignItems="center" justify="center" className="tc-full-height" direction="column">
      {(userStatus.maxRetries && userStatus.retry <= userStatus.maxRetries && <CircularProgress />) || (
        <>
          {(userStatus.tenantChecked && !userStatus.tenantExists && (
            <Grid item className="tc-cloud-off">
              <DomainDisabled />
              <p>Client does not exist in this environment</p>
            </Grid>
          )) || (
            <Grid item className="tc-cloud-off">
              <CloudOff />
              <p>{dbError(userStatus)}</p>
            </Grid>
          )}
          <Grid item>
            <Grid container spacing={2}>
              {(!userStatus.tenantChecked || userStatus.tenantExists) && (
                <Grid item>
                  <LinkButton onClick={onRetry} blue>
                    Retry
                  </LinkButton>
                </Grid>
              )}
              <Grid item>
                <LinkButton onClick={authentication.signOut} blue>
                  Logout
                </LinkButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

InitialLoading.propTypes = {
  userStatus: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.number])).isRequired,
  onRetry: PropTypes.func.isRequired
};

export default InitialLoading;
