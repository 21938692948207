import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Grid, Button, Paper, makeStyles } from "@material-ui/core";
import Location from "./Location";
import Form from "../profile/basic/Form";
import { getLocations, delLocations, loadVendor, cleanLocation, setLocations, postLocations } from "../../../redux/actions/vendor";
import { useRouteParam, getUserAccess } from "../../../common";
import { useLoadingContext } from "../../common/Loading";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "1rem 1.5rem",
    marginBottom: theme.spacing(2)
  }
}));

const List = () => {
  const vendorId = useRouteParam("id");
  const [show, setShow] = useState(false);
  const store = useSelector(
    ({
      vendor: {
        profile: { basicInformation: vendorInfo },
        location: { basicInformation },
        locations
      },
      profile: { securityProfilesDetails }
    }) => ({ vendorInfo, basicInformation, locations, securityProfilesDetails }),
    shallowEqual
  );
  const { vendorInfo, basicInformation, locations, securityProfilesDetails } = store;
  const actionsAllowed = getUserAccess(securityProfilesDetails, "VENDORS.LIST", null, vendorInfo.location.stateId, null);
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    dispatch(getLocations(vendorId));
  }, []);

  useEffect(() => {
    if (show) {
      setShow(false);
    }
  }, [locations]);

  const openAddLocation = () => {
    setShow(true);
    dispatch(cleanLocation());
  };

  const onClose = () => setShow(false);

  const {
    loading,
    actions: { showLoading, hideLoading }
  } = useLoadingContext();
  const onComplete = s => () => {
    hideLoading();
    return setShow(!!s);
  };
  const onSave = (data, vendorParentId) => {
    showLoading();
    if (data?.id) return dispatch(setLocations(data, onComplete(), hideLoading));
    return dispatch(postLocations({ basicInformation: { ...data, parentId: vendorParentId }, industry: [] }, onComplete(), hideLoading));
  };

  const onEdit = data => {
    showLoading();
    dispatch(loadVendor(data.id, onComplete(true), hideLoading));
  };

  const onRemove = id => {
    showLoading();
    dispatch(delLocations({ id, parentId: vendorId }, hideLoading, hideLoading));
  };

  return (
    <Fragment key="vendor-locations">
      {actionsAllowed.create && (
        <Paper className={classes.root}>
          <Grid container alignItems="flex-start" justify="flex-end" direction="row">
            <Button variant="contained" color="primary" onClick={openAddLocation} disabled={show}>
              ADD LOCATION
            </Button>
            {show && <Form {...{ onClose, onSave, loading }} basicInformation={{ ...basicInformation, isPerson: false }} parentId={vendorId} />}
          </Grid>
        </Paper>
      )}
      {locations.map((v, index) => (
        <Location
          key={v.id}
          data={{
            ...v,
            location: {
              city: v.city,
              zipCode: v.zipCode,
              state: v.state,
              address1: v.address1,
              address2: v.address2
            },
            mailingLocation: {
              address1: v.mailingAddress,
              city: v.mailingCity,
              state: v.mailingState,
              zipCode: v.mailingZipCode
            }
          }}
          index={index + 1}
          {...{ onEdit, onRemove, actionsAllowed }}
        />
      ))}
    </Fragment>
  );
};

List.propTypes = {};

export default List;
