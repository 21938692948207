import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const styles = makeStyles(theme => ({
  circle: { fill: "none", stroke: "#C1C0C0", strokeWidth: theme.spacing(0.5) },
  progress: {
    fill: "none",
    strokeWidth: theme.spacing(0.5),
    strokeDashoffset: 0,
    strokeLinecap: "round",
    transition: theme.transitions.create(["stroke-dashoffset", "stroke"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex
    })
  }
}));

const CircleProgress = ({ progress, stroke, children, width }) => {
  const classes = styles();
  const [state, setState] = useState({ progress: 0, stroke: "#707070" });
  useEffect(() => {
    setTimeout(() => setState({ progress, stroke }), 100);
  }, [progress, stroke]);
  const wholeTime = 0.5 * 60;
  const percent = wholeTime * (state.progress >= 1 ? 1 : state.progress);
  const strokeDasharray = Math.PI * 2 * 35;
  const strokeDashoffset = -strokeDasharray * (1 + percent / wholeTime);
  return (
    <svg width={width} viewBox="0 0 74 74" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(38,38)">
        <circle r="34" className={classes.circle} />
        <g transform="rotate(-180)">
          <circle
            r="34"
            {...{
              className: classes.progress,
              style: {
                strokeDasharray,
                strokeDashoffset,
                stroke: state.stroke
              }
            }}
          />
        </g>
        {children}
      </g>
    </svg>
  );
};

CircleProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  stroke: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  width: PropTypes.number
};

CircleProgress.defaultProps = {
  stroke: "#707070",
  children: null,
  width: 130
};

export default CircleProgress;
