import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import { BaseFormControlStyleRight, HocInput } from "./HocInput";

const PercentInput = ({ error, name, onChange, ...other }) => {
  const props = {
    ...other,
    name,
    id: `int-${name}`,
    onKeyPress: e => typeof [...Array(10).keys(), "."].find(x => String(x) === e.key) === "undefined" && e.preventDefault(),
    onValueChange: values => {
      if (typeof onChange !== "function") return;
      onChange({ target: { value: values.floatValue, name } });
    },
    error: Boolean(error),
    prefix: "%  ",
    customInput: TextField,
    decimalScale: other.value >= 100 ? 0 : 2,
    maxLength: other.value >= 100 ? 3 : null
  };
  if (error) props.helperText = error;

  return (
    <NumberFormat
      fullWidth
      {...props}
      isAllowed={values => {
        const { formattedValue, floatValue } = values;
        return formattedValue === "" || floatValue <= 100;
      }}
    />
  );
};
PercentInput.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.string
};

PercentInput.defaultProps = {
  value: null,
  label: undefined,
  error: null,
  onChange: undefined
};

export default HocInput(PercentInput, BaseFormControlStyleRight);
