import * as yup from "yup";
import { rootParent } from "../../../../../common/yup";

const validate = multi => {
  const test = (val, ctx) => {
    const { multipleClaimants } = rootParent(ctx);
    if (multipleClaimants !== multi) return true;
    const { isReportOnly, checked } = ctx.parent;
    if (!multipleClaimants && !checked) return true;
    return typeof isReportOnly === "undefined" || isReportOnly || Boolean(val);
  };
  return yup.object().shape({
    checked: yup.boolean().nullable(),
    isReportOnly: yup.boolean().nullable(),
    adjusterUserId: yup.string().test("step4", "Adjuster Required", test),
    supervisorUserId: yup.string().test("step4", "Supervisor Required", test)
  });
};

export default yup.object().shape({
  claimants: yup.array().of(validate(true)),
  coverageTypes: yup.array().of(
    yup.object().shape({
      lossTypeGroups: yup.array().of(validate(false))
    })
  ),
  multipleClaimants: yup.boolean()
});
