import React, { useEffect, useState, Fragment, useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { makeStyles, Paper, Grid, Checkbox, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { loadFiles, uploadFiles, updateFile, deleteFile, downloadFiles, openFile } from "../../../redux/actions/vendor";
import MaterialTable from "../../common/table/MaterialTable";
import { API } from "../../../redux/actions/actionTypes";
import { api } from "../../../api/apiUtils";
import { fileSize, getUserAccess, useRouteParam } from "../../../common";
import Header from "./Header";
import RegexFilter from "../../common/RegexFilter";
import { useProgress } from "../../common/documents/hooks";
import { SelectInput } from "../../common/inputs";
import useCustomConfirm from "../../common/customHooks/useCustomConfirm";
import FileIcon from "../../common/documents/FileIcon";
import LinkButton from "../../common/LinkButton";
import { useLoadingContext } from "../../common/Loading";
import useViewer from "../../common/viewer/useViewer";

const useStyles = makeStyles(theme => ({ root: { padding: theme.spacing(1, 2) } }));

const Files = () => {
  const classes = useStyles();
  const dataSelector = useSelector(store => store, shallowEqual);

  const {
    vendor: {
      files,
      profile: { basicInformation }
    },
    profile: { securityProfilesDetails }
  } = dataSelector || {};

  const actionsAllowed = getUserAccess(securityProfilesDetails, "VENDORS.FILES", null, basicInformation?.location?.stateId, null);
  const [{ data, allChecked }, setState] = useState({ data: [], allChecked: false });
  const [filter, setFilter] = useState("");
  const [downloadState, dispatchDownload] = useProgress();
  const [uploadState, dispatchUpload] = useProgress(true);
  const {
    actions: { showLoading, hideLoading }
  } = useLoadingContext();

  const vendorId = useRouteParam("id");
  const dispatch = useDispatch();
  useEffect(() => {
    showLoading();
    dispatch(loadFiles(vendorId, hideLoading, hideLoading));
  }, []);
  const [locations, setLocations] = useState([]);
  const callLocations = async () => {
    await api({
      method: "GET",
      url: `${API.URL}/select/vendorlocations`,
      data: { parentId: vendorId }
    })
      .then(vdata => {
        setLocations(vdata);
      })
      .catch();
  };
  useEffect(() => {
    setState(ps => ({
      ...ps,
      data: files.map(f => ({ ...f, checked: false, editable: !f.claims.some(() => true) })),
      allChecked: false
    }));
    setFilter("");
    if (basicInformation?.hasMultipleLocations === true) {
      callLocations();
    }
  }, [files]);

  useEffect(() => {
    if (basicInformation?.hasMultipleLocations === true) {
      callLocations();
    }
  }, [basicInformation]);
  const onAllChechChange = ({ target: { checked } }) => setState(ps => ({ ...ps, allChecked: checked, data: ps.data.map(f => ({ ...f, checked })) }));
  const onCheckChange = _f => ({ target: { checked } }) =>
    setState(ps => {
      const fs = ps.data.map(f => (f.id === _f.id ? { ...f, checked } : f));
      return { ...ps, data: fs, allChecked: !fs.some(f => !f.checked) };
    });
  const onDownload = () =>
    dispatchDownload({
      action: downloadFiles,
      params: {
        vendorId,
        ...data
          .filter(({ checked }) => checked)
          .reduce(
            (a, { id, claims }) => ({
              data: [...a.data, { id, file: true }],
              billIds: [...a.billIds, ...claims.filter(({ billId }) => !a.billIds.some(i => i === billId)).map(({ billId }) => billId)]
            }),
            { data: [], billIds: [] }
          )
      },
      onComplete: () => onAllChechChange({ target: { checked: false } })
    });
  const onUpload = fs => dispatchUpload({ action: uploadFiles, params: { vendorId, data: { files: fs } } });
  const showData = data.filter(x => !filter || RegexFilter(filter, x.fileName) || RegexFilter(filter, x.description));
  const onFilterChange = ({ target: { value } }) => {
    setFilter(value);
    setState(ps => ({ data: ps.data.map(d => ({ ...d, checked: false })), allChecked: false }));
  };
  const CustomSelect = props => <SelectInput label="" className={classes.select} {...props} />;

  const onActionSave = rowData => {
    const relatedVendorId = rowData?.relatedVendorId?.target ? rowData?.relatedVendorId?.target?.value : rowData?.relatedVendorId;
    showLoading();
    dispatch(
      updateFile(
        {
          vendorId,
          data: { id: rowData.id, description: rowData.description, relatedVendorId: relatedVendorId || vendorId }
        },
        hideLoading,
        hideLoading
      )
    );
  };
  const onDelete = ({ id }) => {
    showLoading();
    dispatch(deleteFile({ vendorId, data: id }, hideLoading, hideLoading));
  };

  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(onDelete, "Cancel", "Yes, Delete");

  const handleConfirmDelete = rowData => {
    handleCustomConfirm({
      oData: rowData,
      titleText: `Delete Document`,
      messageText: `The following document is going to be deleted: ${rowData.fileName}. Are you sure you want to proceed?`
    });
  };
  const actionsFiles = () => {
    switch (true) {
      case actionsAllowed.update && actionsAllowed.delete:
        return {
          onActionSave,
          actions: [
            row => {
              return {
                icon: () => (
                  <IconButton size="small" disabled={row.claims.some(() => true)}>
                    <DeleteIcon />
                  </IconButton>
                ),
                tooltip: "Delete",
                onClick: (_e, rowData) => {
                  _e.stopPropagation();
                  if (!rowData.claims.some(() => true)) handleConfirmDelete(rowData);
                }
              };
            }
          ]
        };
      case actionsAllowed.update:
        return { onActionSave };
      case actionsAllowed.delete:
        return {
          actions: [
            row => {
              return {
                icon: () => (
                  <IconButton size="small" disabled={row.claims.some(() => true)}>
                    <DeleteIcon />
                  </IconButton>
                ),
                tooltip: "Delete",
                onClick: (_e, rowData) => {
                  _e.stopPropagation();
                  if (!rowData.claims.some(() => true)) handleConfirmDelete(rowData);
                }
              };
            }
          ]
        };
      default:
        return {};
    }
  };
  const callbackDispatch = (action, del, onSuccess) => (params, dataResponse) => {
    showLoading();
    const onComplete = {
      onSuccess: (...success) => {
        if (typeof onSuccess === "function") onSuccess(...success);
        return hideLoading();
      },
      onError: hideLoading
    };
    if (del) return dispatch(action(params, dataResponse, onComplete));
    return dispatch(action({ ...params, ...onComplete }));
  };
  const openFileFire = useCallback(callbackDispatch(openFile), []);
  const { onViewFile, fileViewer } = useViewer({ dispatchAction: openFileFire });
  const actions = actionsFiles();

  const tableProps = {
    data: showData.map(c => ({
      ...c,
      relatedVendor: locations.find(elem => elem?.id === c.relatedVendorId)?.text
    })),
    columns: [
      {
        title: <Checkbox checked={allChecked} onChange={onAllChechChange} color="primary" disabled={!showData.some(() => true)} />,
        render: r => <Checkbox {...{ checked: r.checked, onChange: onCheckChange(r) }} color="primary" />,
        sorting: false
      },
      {
        title: "Name",
        render: r => (
          <LinkButton onClick={() => onViewFile(r.id, { vendorId, billIds: r.claims.map(({ billId }) => billId) })} style={{ textAlign: "initial" }}>
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
              <Grid item>
                <FileIcon file type={r.fileType} />
              </Grid>
              <Grid item style={{ minWidth: 250 }}>
                {r.fileName}
              </Grid>
            </Grid>
          </LinkButton>
        ),
        customSort: (a, b) => a.fileName.localeCompare(b.fileName)
      },
      { title: "Description", field: "description" },
      { title: "Date", field: "uploadedOn", type: "date", editable: "never" },
      {
        title: "Size",
        render: ({ size }) => fileSize(size),
        customSort: (a, b) => a.size - b.size
      },
      { title: "Uploaded By", field: "uploadedByName", editable: "never" },
      {
        title: "Claim Related",
        render: f =>
          f.claims.map((c, i) => (
            <Fragment key={c.claimId}>
              {i !== 0 && ", "}
              <Link to={`/claim/${c.claimId}`}>{c.data}</Link>
            </Fragment>
          ))
      },
      {
        title: "Location",
        hidden: !basicInformation?.hasMultipleLocations,
        field: "relatedVendor",
        editComponent: row => {
          return (
            <CustomSelect
              name="relatedVendorId"
              className={classes.inputWidth}
              value={row.rowData.relatedVendorId}
              onChange={({ target: { value } }) => {
                return row.onRowDataChange({ ...row.rowData, relatedVendorId: value });
              }}
              allowClear={locations.some(({ categoryId }) => !categoryId)}
              options={locations}
            />
          );
        }
      }
    ],
    options: {
      sorting: true
    },
    ...actions
  };
  return (
    <Fragment key="vendor-files-page">
      <Paper className={classes.root}>
        <Header {...{ data, filter, onFilterChange, onDownload, onUpload, downloadState, uploadState, isUploadAllowed: actionsAllowed?.create }} />
        <MaterialTable {...tableProps} hideToolbar hidePaging />
        <Confirm />
        {fileViewer}
      </Paper>
    </Fragment>
  );
};

export default Files;
