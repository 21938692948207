import React, { useContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { set } from "../../../../redux/actions/claim/wcClaim";
import WcClaimForm from "../../../common/claims/wcClaim/Form";
import WcClaimViewMode from "../../../common/claims/wcClaim/ViewMode";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";

export default props => {
  const claim = useSelector(({ claim: { wcClaim } }) => wcClaim, shallowEqual);
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info") || {};
  const {
    props: { boxProps, editMode, Loading, loading: isLoading },
    actions: { hideLoading, showLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  const dispatch = useDispatch();
  const handleSave = data => {
    showLoading();
    dispatch(set(data, onSuccessEdit, hideLoading));
  };
  return (
    <CollapsibleBox title="Claim" name="wcClaim" {...props} {...boxProps}>
      {Loading}
      {(editMode && access.update && <WcClaimForm {...{ claim, onClose: onToggleEdit, onSave: handleSave, isLoading }} />) || (
        <WcClaimViewMode {...{ claim }} />
      )}
    </CollapsibleBox>
  );
};
