import React from "react";
import { AppBar, Tabs, Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const SimpleTabs = ({ title, ...other }) => (
  <AppBar {...{ position: "static", color: "inherit", className: "tc-nav" }}>
    <Grid container wrap="nowrap">
      {title && (
        <Grid item className="tc-nav-title">
          {title}
        </Grid>
      )}
      {!!other.children && (
        <Grid item xs>
          <Tabs variant="scrollable" scrollButtons="auto" {...other} />
        </Grid>
      )}
    </Grid>
  </AppBar>
);

SimpleTabs.propTypes = { title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]) };

SimpleTabs.defaultProps = { title: undefined };

export default SimpleTabs;
