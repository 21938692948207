import { objectToFormData } from "../../../api/apiUtils";
import { CLAIM, API } from "../../actions/actionTypes";
import * as actions from "../../actions/claim/wcClaim";
import { takeLatest, takeLatestPost, takeLatestSet } from "../common/sagaGenerator";

const url = `${API.URL}/wcClaim`;

export default [
  takeLatest({
    type: CLAIM.WCCLAIM.GET,
    actionSuccess: actions.loadByClaimIdSuccess,
    params: ({ id }) => id,
    errorMessage: "Error loading WC claim",
    url: ({ id }) => `${url}/${id}`
  }),
  takeLatestPost({
    type: CLAIM.WCCLAIM.POST,
    actionSuccess: actions.postSuccess,
    url,
    params: ({ wcClaim }) => objectToFormData(wcClaim),
    successMessage: "WC Claim successfully added",
    errorMessage: "Unable to add the Claim"
  }),
  takeLatestSet({
    type: CLAIM.WCCLAIM.SET,
    actionSuccess: actions.setSuccess,
    url,
    params: ({ wcClaim }) => objectToFormData(wcClaim),
    successMessage: "WC Claim information successfully saved",
    errorMessage: "Unable to save the information"
  })
];
