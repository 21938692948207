import React from "react";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";
import { Paper, Grid, Button } from "@material-ui/core";
import moment from "moment";
import useClaimInfoStyles from "./index.styles";
import useConfirm from "../../common/customHooks/useConfirm";
import { getUserAccess } from "../../../common";
import { useLoadingContext } from "../../common/Loading";

const ClaimInfo = ({ data, onSave }) => {
  const { dateEntry, dateReported, claimLineOfBusinessCode, initialReport } = data || {};
  const classes = useClaimInfoStyles();
  const { openConfirm, handle, confirmComponent } = useConfirm(
    onSave,
    "Conver Report to a Claim",
    `The following Initial report will be converted to a claim: ${initialReport || ""}. Are you sure you want to proceed?`,
    "Cancel",
    "Yes, Convert to a Claim"
  );
  const store = useSelector(({ profile, initialReport: { filterfields } }) => ({ userProfile: profile, filters: filterfields }), shallowEqual);
  const { loading } = useLoadingContext();
  const { userProfile, filters } = store || {};
  const convertToaClaim = getUserAccess(
    userProfile.securityProfilesDetails,
    "INITIALREPORT.CONVERTTOACLAIM",
    filters.clientId,
    filters.stateId,
    filters.lobId
  );

  return (
    <Paper className={classes.root}>
      <Grid container spacing={8} alignItems="center">
        <Grid item xs={12} md={6}>
          <Grid container spacing={8}>
            <Grid item xs={12} md={3}>
              <div className={classes.title}>Initial Report</div>
              <div className={classes.claimNo}>{initialReport}</div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.title}>Reported Date</div>
              <div className={classes.claimNo}>{moment(dateReported).format("M/D/YYYY")}</div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.title}>Entry Date</div>
              <div className={classes.claimNo}>{moment(dateEntry).format("M/D/YYYY")}</div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.title}>Line of Business</div>
              <div className={classes.claimNo}>{claimLineOfBusinessCode}</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container alignItems="flex-start" justify="flex-end" direction="row">
            {convertToaClaim.update && (
              <Button variant="contained" color="primary" onClick={handle} disabled={loading}>
                CONVERT TO A CLAIM
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      {openConfirm && confirmComponent()}
    </Paper>
  );
};

ClaimInfo.propTypes = {
  data: PropTypes.shape({}).isRequired,
  onSave: PropTypes.func.isRequired
};

export default ClaimInfo;
