import { INITIALREPORT as REPORT } from "../actionTypes";

const get = (claimId, onSuccess, onError) => ({ type: REPORT.INFORMATION.CLAIMANT.GET, claimId, onSuccess, onError });

const getSuccess = data => ({ type: REPORT.INFORMATION.CLAIMANT.GET_SUCCESS, data });

const set = (claimant, claimId, onSuccess, onError) => ({ type: REPORT.INFORMATION.CLAIMANT.SET, claimant, claimId, onSuccess, onError });

const setSuccess = claimant => ({ type: REPORT.INFORMATION.CLAIMANT.SET_SUCCESS, claimant });

const setData = claimant => {
  const { isPerson, phisicalAsMailing, phAddress, mailAddress } = claimant;
  let data = {
    isPerson,
    id: claimant.id,
    taxID: claimant.taxID,
    taxIDTypeId: claimant.taxIDTypeId,
    mobile: claimant.mobile,
    workPhone: claimant.workPhone,
    workEmail: claimant.workEmail,
    email: claimant.email,
    location: phAddress,
    phisicalAsMailing,
    mailingLocation: phisicalAsMailing ? phAddress : mailAddress
  };
  if (isPerson) {
    const { firstName, middleName, lastName, dateofBirth, genderId, maritalStatusId, dependents, phone } = claimant;
    data = { ...data, firstName, middleName, lastName, dateofBirth, genderId, maritalStatusId, dependents, phone };
  } else {
    const { commercialName, contact } = claimant;
    data = { ...data, commercialName, contact };
  }
  return data;
};

export { get, getSuccess, set, setSuccess, setData };
