import React, { useContext, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Collapse } from "@material-ui/core";
import Property from "./Property";
import Mortgage from "./Mortgage";
import Damaged from "./Damaged";
import { PropertyContext } from "./PropertyContext";
import useYupValidationSchema from "../customHooks/useYupValidationSchema";
import ActionsFooter, { footerAction } from "../ActionsFooter";
import schema from "./yup";

const PropertyForm = ({ isEdit, onSubmit, onCancel, isLoading }) => {
  const { handleValidateSchema, validationMessages: error } = useYupValidationSchema(schema);
  const { state } = useContext(PropertyContext);
  useEffect(() => {
    handleValidateSchema(state);
  }, [state]);
  const { hasMortgage } = state.property || {};
  const { isClaimantMortgageCompany } = state.damaged || {};
  const handleSubmit = event => {
    event.preventDefault();
    const { isValid } = handleValidateSchema(state);
    if (!isValid) return false;
    return onSubmit(state);
  };
  return (
    <Fragment key="property-form">
      <form onSubmit={handleSubmit}>
        {state.isClaimMode && <Damaged />}
        <Property
          {...{
            isEdit,
            validationMessages: {
              id: error["property.id"],
              propertyTypeId: error["property.propertyTypeId"],
              stateId: error["property.location.stateId"]
            }
          }}
        />
        <Collapse in={hasMortgage && (!state.isClaimMode || !isClaimantMortgageCompany)} timeout="auto">
          <Mortgage
            {...{
              isEdit,
              validationMessages: {
                name: error["property.mortgageCompany.name"],
                stateId: error["property.mortgageCompany.location.stateId"],
                email: error["property.mortgageCompany.email"]
              }
            }}
          />
        </Collapse>
        {[onCancel, onSubmit].some(x => typeof x === "function") && (
          <ActionsFooter
            nopadding
            actions={[
              footerAction({ id: "cancel", onClick: onCancel, text: "Cancel", outlined: true, primary: true }),
              footerAction({ id: "save", type: "submit", text: (isEdit && "Save") || "Add", disabled: isLoading, primary: true })
            ]}
          />
        )}
      </form>
    </Fragment>
  );
};

PropertyForm.propTypes = { isEdit: PropTypes.bool.isRequired, onCancel: PropTypes.func, onSubmit: PropTypes.func, isLoading: PropTypes.bool };

PropertyForm.defaultProps = { onCancel: null, onSubmit: null, isLoading: false };

export default PropertyForm;
