import { CLAIM } from "../../actionTypes";

export const get = (claimId, onSuccess, onError) => ({ type: CLAIM.LEGAL.HEARING.GET, claimId, onSuccess, onError });
export const getSuccess = hearings => ({ type: CLAIM.LEGAL.HEARING.GET_SUCCESS, hearings });

export const del = (hearing, onSuccess, onError) => ({ type: CLAIM.LEGAL.HEARING.DEL, hearing, onSuccess, onError });
export const delSuccess = hearings => ({ type: CLAIM.LEGAL.HEARING.DEL_SUCCESS, hearings });

export const set = (hearing, onSuccess, onError) => ({ type: CLAIM.LEGAL.HEARING.SET, hearing, onSuccess, onError });
export const setSuccess = hearings => ({ type: CLAIM.LEGAL.HEARING.SET_SUCCESS, hearings });

export const post = (hearing, onSuccess, onError) => ({ type: CLAIM.LEGAL.HEARING.POST, hearing, onSuccess, onError });
export const postSuccess = hearings => ({ type: CLAIM.LEGAL.HEARING.POST_SUCCESS, hearings });
