import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import BarChart from "../../components/common/financial/BarChart";
import PieChart from "../../components/common/financial/PieChart";
import { getReserves, get } from "../../redux/actions/catastropheAction";
import { useRouteParam } from "../../common";
import { useLoadingContext } from "../../components/common/Loading";

const CatastropheFinancial = () => {
  const catastropheId = useRouteParam("slug");
  const storeData = useSelector(store => store.catastrophe, shallowEqual);
  const { catastropheReserves, catastrophe } = storeData || {};
  const { amountReserved, totalPaid, outstandingReserve, claimReserves, occurrenceReserves, reserveTypes } = catastropheReserves;
  const pieData = { amountReserved: amountReserved || 0, totalPaid: totalPaid || 0, outstandingReserve: outstandingReserve || 0 };
  const dispatch = useDispatch();
  const {
    actions: { hideLoading, showLoading }
  } = useLoadingContext();
  useEffect(() => {
    showLoading();
    dispatch(getReserves({ catastropheId }, hideLoading, hideLoading));
    if (!catastrophe?.id) {
      dispatch(get(catastropheId));
    }
  }, []);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <PieChart reserves={pieData} by="catastrophe" />
      </Grid>
      <Grid item xs={12} sm={8} md={9} lg={10}>
        <BarChart reserves={claimReserves || []} type="claim" />
        <BarChart reserves={occurrenceReserves || []} type="occurrence" />
        <BarChart reserves={reserveTypes || []} type="type" />
      </Grid>
    </Grid>
  );
};

export default CatastropheFinancial;
