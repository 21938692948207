import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import { CheckCircleOutline, HighlightOff, Group } from "@material-ui/icons";
import Client from "../../../../assets/client.svg";

const useStyles = makeStyles(() => ({
  levelName: { fontSize: 14 },
  label: { color: "#898F91", fontSize: 11 },
  icon: { fontSize: 24, paddingRight: 10, lineHeight: 1, "& svg": { width: "1em", height: "1em" } },
  number: { fontWeight: 500, fontSize: 24, lineHeight: 1 },
  name: { fontWeight: 500, fontSize: 24, color: "#73B25D", lineHeight: 1 },
  active: { fontSize: 20, color: "#73B25D", backgroundColor: "#FFFFFF", borderRadius: "25%", verticalAlign: "middle" },
  inactive: { fontSize: 20, color: "#E74C3C", backgroundColor: "#FFFFFF", borderRadius: "25%", verticalAlign: "middle" }
}));

const InsuredInfo = ({ isActive, name, number, level, levelId }) => {
  const classes = useStyles();
  const levelName = levelId === 1 ? level.split("(")[0] : level;
  return (
    <Grid container spacing={1}>
      <Grid item alignItems="center" xs={12}>
        <Grid item className={classes.icon}>
          {levelId === 4 ? <Group /> : <Client />}
        </Grid>
        <Grid item>
          <span className={classes.levelName}>{levelName}</span>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.number}>{number}</div>
        <div className={classes.name}>
          <span style={{ marginRight: 16 }}>{name}</span>
          {isActive && <CheckCircleOutline className={classes.active} />}
          {isActive === false && <HighlightOff className={classes.inactive} />}
        </div>
      </Grid>
    </Grid>
  );
};

InsuredInfo.propTypes = {
  isActive: PropTypes.bool,
  name: PropTypes.string,
  number: PropTypes.string,
  level: PropTypes.string,
  levelId: PropTypes.number
};

InsuredInfo.defaultProps = {
  isActive: false,
  name: "",
  number: "",
  level: "",
  levelId: null
};

export default InsuredInfo;
