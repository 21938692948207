import React, { Fragment } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import LabelValue from "../../../../common/LabelValue";
import { formatMoney } from "../../../../../api/apiUtils";
import { CheckboxLabel } from "../../../../common/inputs";
// import InteractiveList from "../../../../common/list/InteractiveList";
import Benefits from "./Benefits";

export default () => {
  const store = useSelector(
    ({
      claim: {
        claim: { id },
        settled: s
      }
    }) => ({ claimId: id, settled: s }),
    shallowEqual
  );

  const { claimId, settled } = store || {};
  // const payeeList = (settled.benefits || []).map((el, i) => ({
  //   id: i + 1,
  //   ...el,
  //   primary: (
  //     <Grid container spacing={2}>
  //       <Grid item xs={12} md={4} className="tc-no-padding-tb">
  //         <LabelValue label="Payee Type" value={el.claimantId && !el.vendorId ? "Claimant" : "Vendor"} style={{ marginBottom: 0 }} />
  //       </Grid>
  //       <Grid item xs={12} md={4} className="tc-no-padding-tb">
  //         <LabelValue label="Payee Name" value={el.payeeName} style={{ marginBottom: 0 }} />
  //       </Grid>
  //       <Grid item xs={12} md={4} className="tc-no-padding-tb">
  //         <LabelValue label="Physical Address 1" value={el.fullAddress1} style={{ marginBottom: 0 }} />
  //       </Grid>
  //     </Grid>
  //   )
  // }));
  return (
    (claimId === settled.claimId && (
      <Fragment key="settledRead">
        <CheckboxLabel label="Case Settled" checked={settled.isCaseSettled} disabled />

        <Grid container spacing={2}>
          <Grid item xs={12} md={3} className="tc-no-padding-tb">
            <LabelValue label="From Reserve Type" value={settled.reserveType} />
          </Grid>
          <Grid item xs={12} md={3} className="tc-no-padding-tb">
            <LabelValue label="Demand Amount" value={formatMoney(settled.demandAmount)} />
          </Grid>
          <Grid item xs={12} md={3} className="tc-no-padding-tb">
            <LabelValue label="Authorized Amount" value={formatMoney(settled.authorizedAmount)} />
          </Grid>
          <Grid item xs={12} md={3} className="tc-no-padding-tb">
            <LabelValue label="Authorized Date" value={settled.authorizedDate} isDate />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} className="tc-no-padding-tb">
            <LabelValue label="Settlement Amount" value={formatMoney(settled.settlementAmount)} />
          </Grid>
          <Grid item xs={12} md={3} className="tc-no-padding-tb">
            <LabelValue label="Settlement Date" value={settled.settlementDate} isDate />
          </Grid>
          {!!(settled.benefits || []).length && (
            <Grid item xs={12} md={12}>
              {/* <InteractiveList items={payeeList} title="Payees" style={{ width: "100%", maxHeight: 150 }} /> */}
              <Benefits benefits={settled.benefits} />
            </Grid>
          )}
        </Grid>
        {!!settled.paymentId && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography style={{ color: "#428302", display: "inline-block", fontWeight: 500 }}>Payment</Typography>
            </Grid>
            <Grid item xs={12} md={3} className="tc-no-padding-tb">
              <LabelValue label="Payment Date" value={settled.paymentDate} isDate />
            </Grid>
            <Grid item xs={12} md={3} className="tc-no-padding-tb">
              <LabelValue label="Payment Method" value={settled.paymentMethod} />
            </Grid>
            {!!(settled.checkTransactionNumber === "Check" || settled.checkTransactionNumber === "Bank Transfer") && (
              <Grid item xs={12} md={3}>
                {!!(settled.checkTransactionNumber === "Check") && <LabelValue label="Check Number" value={settled.checkNumber} />}
                {!!(settled.checkTransactionNumber === "Bank Transfer") && (
                  <LabelValue label="Transaction Number" value={settled.transactionNumber} />
                )}
              </Grid>
            )}
            <Grid item xs={12} md={3} className="tc-no-padding-tb">
              <LabelValue label="Description" value={settled.description} />
            </Grid>
          </Grid>
        )}
      </Fragment>
    )) || (
      <Typography className="tc-nodata" gutterBottom>
        No Settled Information
      </Typography>
    )
  );
};
