import { Grid, Button } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { v4 as uuid } from "uuid";
import BtnMenues from "./menu/ButtonMenu";

export const footerAction = ({ id, outlined, primary, isBtnMenu, ...props }) => ({
  component: isBtnMenu ? BtnMenues : Button,
  id: id || uuid(),
  color: primary ? "primary" : undefined,
  variant: outlined ? "outlined" : "contained",
  ...props
});

export const mapAction = footerId => ({ component: Comp, id, text, gridCss, ...other }, ix) => {
  const comp = <Comp {...{ id, ...other }}>{text}</Comp>;
  if (typeof ix === "undefined") return comp;
  return (
    <Grid item key={`footer-action-${footerId}-${id || ix}`} className={gridCss}>
      {comp}
    </Grid>
  );
};

const ActionsFooterWrapper = ({ actions, className, nopadding, shadow, ...props }) => {
  const [footerId] = useState(uuid());
  return (
    <div className={clsx("tc-footer", { "tc-shadow": shadow, "tc-no-padding": nopadding, [className]: !!className })} {...props}>
      <Grid container justify="flex-end" spacing={2}>
        {actions.map(mapAction(footerId))}
      </Grid>
    </div>
  );
};

ActionsFooterWrapper.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  nopadding: PropTypes.bool,
  shadow: PropTypes.bool
};

ActionsFooterWrapper.defaultProps = { className: undefined, nopadding: false, shadow: false };

const ActionsFooter = ({ elementId, ...props }) => {
  if (elementId) {
    const portal = document.getElementById(elementId);
    return portal && ReactDOM.createPortal(<ActionsFooterWrapper {...props} />, portal);
  }
  return <ActionsFooterWrapper {...props} />;
};

ActionsFooter.propTypes = { elementId: PropTypes.string };
ActionsFooter.defaultProps = { elementId: undefined };

export default ActionsFooter;
