import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Grid } from "@material-ui/core";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import Money from "../../../common/MoneyLabel";
import { useReservesFinancialStyles } from "./index.styles";
import { ReserveBreakdown } from "../overview/ReservesFinancialInfo";

const ReservesPanelInfo = () => {
  const classes = useReservesFinancialStyles();
  const { id, amountReserved, totalPaid, outstandingReserve, currentDetails } = useSelector(({ claim: { reserves } }) => reserves, shallowEqual);
  const details = currentDetails.filter(x => x.isActive && x.amountReserved > 0 && x.status === "Accepted");
  const any = !!id && details.length > 0;
  return (
    <div className={classes.root}>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item className={classes.icon}>
              <LocalAtmIcon />
            </Grid>
            <Grid item>Reserve</Grid>
          </Grid>
        </Grid>
        {!any && (
          <Grid item className={classes.red}>
            (No Reserve Set)
          </Grid>
        )}
      </Grid>
      {any && (
        <>
          <Grid container spacing={3}>
            <Grid item>
              <small>Total Reserves</small>
              <div className={classes.amount}>
                <Money value={amountReserved} />
              </div>
            </Grid>
            <Grid item>
              <small>Total Paid</small>
              <div className={classes.total}>
                <Money value={totalPaid} />
              </div>
            </Grid>
            <Grid item>
              <small>Outstanding Reserve</small>
              <div className={classes.outstanding}>
                <Money value={outstandingReserve} />
              </div>
            </Grid>
          </Grid>
          <ReserveBreakdown {...{ details, amountReserved }} />
        </>
      )}
    </div>
  );
};

export default ReservesPanelInfo;
