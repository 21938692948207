import { Avatar, Grid, Paper, Typography, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import Claim from "../../../assets/claim.svg";
import MaterialTable from "../../common/table/MaterialTable";
import { useClaimDuplicatesStyles } from "../claimAdd/layout/index.styles";
import ClaimProcessingStatus from "../ClaimProcessingStatus";
import ClaimStatus from "../ClaimStatus";

const any = arr => arr.some(() => true);

const WcClaimAddDuplicates = memo(({ duplicates, setShowDuplicates }) => {
  const classes = useClaimDuplicatesStyles();
  const anyDuplicates = any(duplicates);
  const tableProps = {
    id: "claims-table",
    columns: [
      {
        title: "Claim Number",
        width: 280,
        render: rowData => (
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: 5 }}>
              <Link to={`/claim/${rowData.id}`}>{rowData.number}</Link>
            </span>
            <span style={{ marginRight: 5 }}>
              <ClaimStatus status={rowData.status} />
            </span>
            <ClaimProcessingStatus processingStatus={rowData.processingStatus} />
          </div>
        )
      },
      { title: "Employee", field: "claimantFullName", width: 200 },
      { title: "Nature of Injury", field: "injuryType" },
      { title: "Date of Injury", field: "dateofLoss", render: rowData => moment(rowData.dateofLoss).format("M/D/YYYY") },
      { title: "SSN", field: "taxID" },
      { title: "Insured", field: "insured" },
      { title: "Jurisdiction State", field: "stateCode", width: 80 }
    ],
    data: duplicates,
    hidePaging: duplicates.length <= 5,
    options: { search: false },
    hideExportButton: true
  };
  return (
    <Paper className="tc-p2">
      <Grid container className={classes.root}>
        <Grid item style={{ zIndex: 1 }}>
          <Grid container>
            <Grid item xs>
              <Typography className="tc-title " gutterBottom>
                Possible Duplicates
              </Typography>
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={() => setShowDuplicates(false)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs className={classes[anyDuplicates ? "duplicates" : "noDuplicates"]}>
          {(anyDuplicates && (
            <div style={{ marginTop: -65 }}>
              <MaterialTable {...tableProps} />
            </div>
          )) || (
            <div>
              <Avatar className={classes.avatar}>
                <Claim />
              </Avatar>
              No duplicates found
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
});

WcClaimAddDuplicates.propTypes = { duplicates: PropTypes.arrayOf(PropTypes.object).isRequired, setShowDuplicates: PropTypes.func.isRequired };

export default WcClaimAddDuplicates;
