import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Radio } from "@material-ui/core";

const RadioLabel = ({ label, classes, ...props }) => {
  return <FormControlLabel control={<Radio {...props} color="primary" />} {...{ label, classes }} />;
};

RadioLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  classes: PropTypes.objectOf(PropTypes.string)
};

RadioLabel.defaultProps = {
  label: undefined,
  classes: undefined
};

export default RadioLabel;
