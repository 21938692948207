import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import { Paper, Grid } from "@material-ui/core";
import {} from "@material-ui/icons";
import Money from "../../../common/MoneyLabel";
import { usePolicyInfoStyles } from "./index.styles";

import NoPoliciAssociateIcon from "../../../../assets/no_policy_associate.svg";

export default () => {
  const classes = usePolicyInfoStyles();
  const store = useSelector(({ claim: { policy } }) => policy, shallowEqual);

  const { coverageList, policyType, number, effectiveDate, expirationDate, holder, producer, insurer, stateList } = store || {};
  const coveredStates = stateList ? stateList.map(({ name }) => name).join() : "";
  return (
    <Paper className={classes.root}>
      <div className={classes.title}>Policy Info</div>
      {number ? (
        <Grid container spacing={5}>
          <Grid item xs>
            {number && (
              <div className={classes.div}>
                <span className={classes.label}>Policy:</span>
                {number}
              </div>
            )}
            {policyType && (
              <div className={classes.div}>
                <span className={classes.label}>Policy Type:</span>
                {policyType}
              </div>
            )}
            {effectiveDate && expirationDate && (
              <div className={classes.div}>
                <span className={classes.label}>From:</span>
                {moment(effectiveDate).format("M/D/YYYY")}
                <span className={classes.label} style={{ paddingLeft: 15 }}>
                  To:
                </span>
                {moment(expirationDate).format("M/D/YYYY")}
              </div>
            )}
            {holder && (
              <div className={classes.div}>
                <span className={classes.label}>Policy Holder:</span>
                {holder}
              </div>
            )}
          </Grid>
          <Grid item xs>
            {insurer && (
              <div className={classes.div}>
                <span className={classes.label}>Primary Carrier:</span>
                {insurer}
              </div>
            )}
            {producer && (
              <div className={classes.div}>
                <span className={classes.label}>Issuing Carrier:</span>
                {producer}
              </div>
            )}
            {coveredStates && (
              <div className={classes.div}>
                <span className={classes.label}>Covered States:</span>
                {coveredStates}
              </div>
            )}
          </Grid>
          <Grid item xs>
            {coverageList && coverageList.some(() => true) && (
              <div className={classes.limits}>
                <table className={classes.table}>
                  <tbody>
                    {coverageList.map(({ id: i, coverageName, limit }) => (
                      <tr key={i}>
                        <td>{coverageName}</td>
                        <td>
                          <Money value={limit} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={5}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <NoPoliciAssociateIcon />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            No policy associate with the Claim
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};
