import { put, takeLatest, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { BANKING, API } from "../actions/actionTypes";
import { api } from "../../api/apiUtils";
import { apiCallError, beginApiCall } from "../actions/apiStatusActions";
import { capitalizeFirstLetter } from "../../common";
import * as actions from "../actions/banking";
import { takeLatestSet, takeLatestPost, getErrorMessage } from "./common/sagaGenerator";

export default function* root() {
  try {
    yield all([
      takeLatest(BANKING.GET, function* get({ data, ...rest }) {
        try {
          const entityname = data.entityType.concat("Id");
          const accounts = yield call(api, {
            method: "GET",
            url: `${API.URL}/banking/getall`,
            data: { [entityname]: data.id }
          });
          const fName = "get".concat(capitalizeFirstLetter(data.entityType)).concat("BankingSuccess");
          const actionSuccess = actions[fName];
          if (typeof rest?.onSuccess === "function") yield call(rest.onSuccess, accounts);
          yield put(actionSuccess(accounts));
        } catch (err) {
          yield apiCallError(err);
          if (typeof rest?.onError === "function") yield call(rest.onError, err);
          yield call(toast.error, getErrorMessage(err.response.status, "Unable to load Bank Accounts."));
        }
      }),
      takeLatestPost({
        type: BANKING.POST,
        url: `${API.URL}/banking`,
        params: ({ data }) => data,
        onSuccess: function* onBankingSetSuccess({ payload, entityType }) {
          yield put(actions[`post${capitalizeFirstLetter(entityType)}BankingSuccess`](payload));
        },
        successMessage: "Bank Account successfully added",
        errorMessage: "Unable to add the Bank Account"
      }),
      takeLatestSet({
        type: BANKING.SET,
        url: `${API.URL}/banking`,
        params: ({ data }) => data,
        onSuccess: function* onBankingSetSuccess({ payload, entityType }) {
          yield put(actions[`set${capitalizeFirstLetter(entityType)}BankingSuccess`](payload));
        },
        successMessage: "Bank Account successfully updated",
        errorMessage: "Unable to update the Bank Account"
      }),
      takeLatest(BANKING.DEL, function* del({ id, entityType, entityId }) {
        try {
          yield put(beginApiCall());
          const entityname = entityType.concat("Id");
          const values = { [entityname]: entityId, id };
          const accounts = yield call(api, { method: "DELETE", url: `${API.URL}/banking`, data: values });
          const fName = "del".concat(capitalizeFirstLetter(entityType)).concat("BankingSuccess");
          const actionSuccess = actions[fName];
          yield put(actionSuccess(accounts));
          yield call(toast.success, `Bank Account successfully deleted.`);
        } catch (err) {
          yield apiCallError(err);
          yield call(toast.error, getErrorMessage(err.response.status, "Unable to delete the Bank Account."));
        }
      })
    ]);
  } catch (error) {
    if (process.env.NODE_ENV !== "production") yield call(toast.error, "(saga/Banking)Something was wrong in Banking saga file");
    yield call(toast.error, "Error Banking");
  }
}
