import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { DIARY, API } from "../actions/actionTypes";
import { api } from "../../api/apiUtils";
import { apiCallError, beginApiCall } from "../actions/apiStatusActions";
import { capitalizeFirstLetter } from "../../common";
import * as actions from "../actions/diary";
import rootSagaGenerator from "./common/rootSagaGenerator";

export default rootSagaGenerator({
  name: "Diary",
  sagas: [
    takeLatest(DIARY.GET, function* get({ id, entityType, onSuccess, onError }) {
      try {
        yield put(beginApiCall());
        const diaries = yield call(api, {
          url: `${API.URL}/diary`,
          data: { [`${entityType}Id`]: id }
        });
        yield put(actions[`get${capitalizeFirstLetter(entityType)}DiariesSuccess`](diaries));
        if (typeof onSuccess === "function") yield call(onSuccess);
      } catch (err) {
        yield put(apiCallError(err));
        yield call(toast.error, "Error loading diaries");
        if (typeof onError === "function") yield call(onError);
      }
    }),
    takeLatest(DIARY.POST, function* post({ diary, entityType, onSuccess, onError }) {
      try {
        yield put(beginApiCall());
        const diaries = yield call(api, { method: "POST", url: `${API.URL}/diary`, data: { ...diary } });
        yield put(actions[`post${capitalizeFirstLetter(entityType)}DiarySuccess`](diaries));
        yield call(toast.success, "Diary successfully added");
        if (typeof onSuccess === "function") yield call(onSuccess, diaries);
      } catch (err) {
        yield put(apiCallError(err));
        yield call(toast.error, `Unable to add the diary`);
        if (typeof onError === "function") yield call(onError);
      }
    }),
    takeLatest(DIARY.SET, function* set({ diary, entityType, onSuccess, onError }) {
      try {
        yield put(beginApiCall());
        const diaries = yield call(api, { method: "PUT", url: `${API.URL}/diary`, data: { ...diary } });
        yield put(actions[`set${capitalizeFirstLetter(entityType)}DiarySuccess`](diaries));
        yield call(toast.success, `Diary successfully updated`);
        if (typeof onSuccess === "function") yield call(onSuccess, diaries);
      } catch (err) {
        yield put(apiCallError(err));
        yield call(toast.error, `Unable to update the diary`);
        if (typeof onError === "function") yield call(onError);
      }
    }),
    takeLatest(DIARY.DEL, function* del({ diary, entityType, onSuccess, onError }) {
      try {
        const entityname = `${entityType}Id`;
        const values = { id: diary.id, [entityname]: diary[entityname] };
        yield put(beginApiCall());
        const diaries = yield call(api, { method: "DELETE", url: `${API.URL}/diary`, data: values });
        yield put(actions[`del${capitalizeFirstLetter(entityType)}DiarySuccess`](diaries));
        yield call(toast.success, `Diary successfully deleted`);
        if (typeof onSuccess === "function") yield call(onSuccess);
      } catch (err) {
        yield put(apiCallError(err));
        yield call(toast.error, `Unable to delete the diary`);
        if (typeof onError === "function") yield call(onError);
      }
    })
  ]
});
