import React from "react";
import Witness from "./Witness";
import Documents from "./documents";
import Attorney from "./Attorney";

export default () => (
  <>
    <Witness />
    <Documents />
    <Attorney />
  </>
);
