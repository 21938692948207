import { Divider, Grid, Collapse, Paper, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { memo, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { filterByParentId } from "../../../common";
import { DateTimeInput, SelectInput, TextInput, TimeInput, CheckboxLabel } from "../../common/inputs";
import { BaseRadioButtonGroup } from "../../common/inputs/RadioButtonGroup";
import LabelValue from "../../common/LabelValue";
import { useEmployeeStyles } from "./index.styles";

const newStateIncident = (payload, state) => {
  if (payload.name.indexOf(".") < 0) return { ...state.incident, [payload.name]: payload.value };
  const [name01, name02] = payload.name.split(".");
  return { ...state.incident, [name01]: { ...state.incident[name01], [name02]: payload.value } };
};

const WcClaimForm = memo(({ incident, insuredLocation, error, setState }) => {
  const classes = useEmployeeStyles();
  const {
    injuryType,
    bodyPart: bodyPartFull,
    bodyPartGroup,
    hospitalLocation,
    treatmentType,
    claimType,
    contactMethods,
    lossTimeStatus,
    ediClaimTypes,
    ncciInjuryCodes,
    typeOfCoverageCodes
  } = useSelector(({ common }) => common, shallowEqual);
  const { st: states, claimLossTypes: lossTypesFull, claimCauseCodes: codes } = useSelector(
    ({ common: { states: st, claimLossTypes, claimCauseCodes } }) => ({
      st,
      claimLossTypes,
      claimCauseCodes
    }),
    shallowEqual
  );
  const { claimLossTypeGroupId, bodilyInjury } = incident;
  const isDeath = injuryType.some(t => t.id === incident?.bodilyInjury?.injuryTypeId && t.isDeath);
  useEffect(() => {
    if (isDeath !== incident?.bodilyInjury?.isDeath)
      setState(ps => ({
        ...ps,
        incident: {
          ...ps.incident,
          bodilyInjury: { ...ps.incident.bodilyInjury, isDeath, claimantDateofDeath: isDeath ? ps.incident.bodilyInjury.claimantDateofDeath : null }
        }
      }));
  }, [isDeath]);
  const lossTypes = filterByParentId(lossTypesFull, claimLossTypeGroupId);
  const causeCodes = filterByParentId(codes, claimLossTypeGroupId);
  const handleChange = ({ target: { name: path, value } }) => {
    const val = value === "true" || value === "false" ? value === "true" : value;
    if (insuredLocation && path === "employeeAccident.onEmployerPremises" && value === "true")
      setState(ps => ({ ...ps, incident: { ...incident, location: insuredLocation } }));
    return setState(ps => ({ ...ps, incident: newStateIncident({ name: path, value: val }, ps), validation: new Date().getTime() }));
  };
  const inputProps = path => {
    const [ent, prop] = path.split(".");
    return {
      name: path,
      [prop === "treatmentReceived" ? "checked" : "value"]: (prop ? incident[ent][prop] : incident[ent]) || undefined,
      error: error[`incident.${path}`],
      onChange: handleChange
    };
  };
  const options = [
    { id: true, text: "YES" },
    { id: false, text: "NO" }
  ];

  const arr = a => (Array.isArray(a) ? a : []);
  const hospitalFilter = arr(hospitalLocation)
    .filter(h => !incident.hospitalStateId || h.stateId === incident.hospitalStateId)
    .map(({ id, name }) => ({ id, text: name }));
  const { hPhone, address1, address2, city, zipCode } = hospitalLocation.find(({ id }) => id === bodilyInjury.hospitalId) || {};
  const hLocation = { address1, address2, city, zipCode };
  return (
    <React.Fragment key="claimedit">
      <Paper className={classes.root}>
        <Grid container className={classes.head} spacing={2}>
          <Grid item xs>
            <Typography className="tc-title">Incident</Typography>
          </Grid>
        </Grid>
        <React.Fragment key="claim-update">
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <DateTimeInput {...inputProps("employeeAccident.froiReceivedDate")} label="FROI Received Date *" keyboard />
              <DateTimeInput {...inputProps("dateReported")} label="Date Reported to the Employer *" keyboard />
            </Grid>
            <Grid item xs={3}>
              <SelectInput {...inputProps("claimTypeId")} label="Claim Type" options={claimType} />
              <TextInput {...inputProps("reportedBy")} label="Claim Reported By" />
            </Grid>
            <Grid item xs={3}>
              <SelectInput {...inputProps("contactMethodId")} label="Method of Contact/Report" options={contactMethods} />
              <TimeInput {...inputProps("employeeAccident.beganWorkTime")} label="Time employee began to work" keyboard />
            </Grid>
            <Grid item xs={3}>
              <DateTimeInput {...inputProps("employeeAccident.lastWorkDate")} label="Last Work Date" keyboard />
              <DateTimeInput {...inputProps("employeeAccident.beganDisabilityDate")} label="Date Disability Began" keyboard />
            </Grid>
          </Grid>
          <TextInput {...inputProps("lateReason")} label="Late Reason" />
          <Divider style={{ margin: "15px 0px 15px 0px" }} />
        </React.Fragment>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Grid container>
              <Grid item xs className={classes.pr1}>
                <DateTimeInput {...inputProps("dateofLoss")} label="Date of Injury *" keyboard />
              </Grid>
              <Grid item xs className={classes.pl1}>
                <TimeInput {...inputProps("dateofLoss")} label="Time of Injury *" keyboard />
              </Grid>
            </Grid>
            <TextInput {...inputProps("location.address1")} label="Accident Address 1  *" />
            <TextInput {...inputProps("location.address2")} label="Accident Address 2" />
            <TextInput {...inputProps("location.city")} label="City *" />
            <Grid container>
              <Grid item xs className={classes.pr1}>
                <SelectInput {...inputProps("location.stateId")} label="State *" options={states} />
              </Grid>
              <Grid item xs className={classes.pl1}>
                <TextInput {...inputProps("location.zipCode")} label="Zip *" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <BaseRadioButtonGroup
              name="employeeAccident.onEmployerPremises"
              options={options}
              value={incident.employeeAccident?.onEmployerPremises}
              label="Accident on Employer’s Premises"
              row
              onChange={handleChange}
            />
            <SelectInput {...inputProps("employeeAccident.lossTimeStatusId")} label="Loss Time Status" options={lossTimeStatus} />
            <BaseRadioButtonGroup
              name="employeeAccident.lossTimeMore"
              options={options}
              value={incident.employeeAccident.lossTimeMore}
              label="Loss time more than 7 days"
              row
              onChange={handleChange}
            />
            <DateTimeInput {...inputProps("employeeAccident.returnDate")} label="Date Return (ed) to Work" keyboard />
          </Grid>
          <Grid item xs={6}>
            <BaseRadioButtonGroup
              name="employeeAccident.equipmentInvolved"
              options={options}
              value={incident.employeeAccident.equipmentInvolved}
              label="Equipment, material or chemicals involved"
              row
              onChange={handleChange}
            />
            {incident.employeeAccident.equipmentInvolved && (
              <TextInput {...inputProps("employeeAccident.equipmentInvolvedDescription")} label="Details" rows={1} />
            )}
            <TextInput
              {...inputProps("employeeAccident.activityAtTheMoment")}
              label="Employee Specific Activity at the moment of the accident"
              rows={1}
            />
            <TextInput
              {...inputProps("employeeAccident.WorkProcessAtTheMoment")}
              label="Employee Work Process at the moment of the accident"
              rows={1}
            />
            <BaseRadioButtonGroup
              name="employeeAccident.safetyEquipmentProvided"
              options={options}
              value={incident.employeeAccident?.safetyEquipmentProvided}
              label="Safeguards or Safety Equipment provided? "
              row
              onChange={handleChange}
            />
            {incident.employeeAccident?.safetyEquipmentProvided && (
              <BaseRadioButtonGroup
                name="employeeAccident.safetyEquipmentProvidedUsed"
                options={options}
                value={incident.employeeAccident?.safetyEquipmentProvidedUsed}
                label="Were they used?"
                row
                onChange={handleChange}
              />
            )}
          </Grid>
        </Grid>
        <TextInput {...inputProps("employeeAccident.description")} label="Accident Description" rows={1} />
        <Divider style={{ margin: "15px 0px 15px 0px" }} />
        <Grid container spacing={4}>
          <Grid item xs>
            <SelectInput {...inputProps("bodilyInjury.injuryTypeId")} label="Nature of Injury *" options={injuryType} />
            {isDeath && <DateTimeInput {...inputProps("bodilyInjury.claimantDateofDeath")} label="Date Of Death *" keyboard />}
            <SelectInput {...inputProps("bodilyInjury.parentBodyPartId")} label="Body Part Group *" options={bodyPartGroup} />
            <SelectInput
              {...inputProps("bodilyInjury.bodyPartId")}
              label="Body Part *"
              disabled={!bodilyInjury.parentBodyPartId ? true : undefined}
              options={filterByParentId(bodyPartFull, bodilyInjury.parentBodyPartId)}
            />
          </Grid>
          <Grid item xs>
            <SelectInput {...inputProps("claimCauseCodeId")} label="Cause Code" options={causeCodes} />
            <SelectInput {...inputProps("bodilyInjury.ncciInjuryCodeId")} label="NCCI Injury Code" options={ncciInjuryCodes} />
            <SelectInput {...inputProps("claimLossTypeId")} label="Loss Type *" options={lossTypes} />
          </Grid>

          <Grid item xs>
            <SelectInput {...inputProps("typeOfCoverageCodeId")} label="Type of Coverage Code" options={typeOfCoverageCodes} />
            <SelectInput {...inputProps("ediClaimTypeId")} label="EDI Claim Type" options={ediClaimTypes} />
            <TextInput {...inputProps("stateNumber")} label="State Claim Number" />
          </Grid>
          <Grid item xs>
            <CheckboxLabel {...inputProps("bodilyInjury.treatmentReceived")} label="Initial Treatment Received" />
          </Grid>
        </Grid>
        <TextInput {...inputProps("bodilyInjury.description")} label="Bodily Injury Description" rows={3} />
        <Collapse in={bodilyInjury?.treatmentReceived} timeout="auto">
          <Divider className={classes.marginDivider} />
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <SelectInput {...inputProps("bodilyInjury.treatmentTypeId")} label="Initial Treatment Type *" options={treatmentType} />
            </Grid>
            <Grid item xs={3}>
              <SelectInput {...inputProps("bodilyInjury.hospitalId")} label="Hospital Name" options={hospitalFilter} allowClear />
            </Grid>
            <Grid item xs={3}>
              <LabelValue value={hPhone} label="Hospital Phone Number" />
            </Grid>
            <Grid item xs={3}>
              <LabelValue value={hLocation} label="Hospital Address" isAddress />
            </Grid>
            <Grid item xs={3}>
              <TextInput {...inputProps("bodilyInjury.physicianName")} label="Initial Hospital Physician Name" />
            </Grid>
            <Grid item xs={9}>
              <TextInput {...inputProps("bodilyInjury.treatmentDescription")} label="Treatment Description" rows={1} />
            </Grid>
          </Grid>
        </Collapse>
      </Paper>
    </React.Fragment>
  );
});

WcClaimForm.propTypes = {
  incident: PropTypes.objectOf(PropTypes.any).isRequired,
  insuredLocation: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.string).isRequired,
  setState: PropTypes.func.isRequired
};

export default WcClaimForm;
