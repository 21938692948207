import React from "react";
import { useFormikContext } from "formik";
import AgentInformationForm from "./forms/AgentInformationForm";

const AgentInformation = () => {
  const {
    values: { agentInformation },
    handleChange
  } = useFormikContext();

  return <AgentInformationForm {...{ agentInformation, handleChange }} />;
};

export default AgentInformation;
