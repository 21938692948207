import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useRouteParam } from "../../common";
import EditForm from "../../components/catastrophe/EditForm";
import ReadOnlyForm from "../../components/catastrophe/ReadOnlyForm";
import CollapsibleBox, { useCollapsibleBox } from "../../components/common/CollapsibleBox";
import ActionsAllowed from "../../components/common/contexts/ActionsAllowed";
import { useLoadingContext } from "../../components/common/Loading";
import { get as load, set as update } from "../../redux/actions/catastropheAction";

const CatastrophePage = () => {
  const routesAllowed = useContext(ActionsAllowed);
  const actionsAllowed = routesAllowed.find(elem => elem.route === "info") || {};
  const {
    actions: { hideLoading, showLoading }
  } = useLoadingContext();
  const {
    props: { boxProps, editMode },
    actions: { onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(actionsAllowed.update, false, hideLoading);

  const dispatch = useDispatch();
  const catastrophe = useSelector(({ catastrophe: { catastrophe: c } }) => c, shallowEqual);
  const catastropheId = useRouteParam("slug");
  const number = catastrophe.id === catastropheId ? catastrophe.number : undefined;
  useEffect(() => {
    if (catastrophe.id !== catastropheId) dispatch(load(catastropheId));
  }, [catastropheId, catastrophe]);
  const onSave = data => {
    showLoading();
    return dispatch(update(data, onSuccessEdit, hideLoading));
  };
  return (
    <>
      {(editMode && <EditForm {...{ onToggleEdit, onSave }} />) || (
        <CollapsibleBox cssTitle="tc-pl2" isCollapse={false} title={`Catastrophe # ${number || catastropheId}`} {...boxProps}>
          <ReadOnlyForm {...{ catastrophe }} />
        </CollapsibleBox>
      )}
    </>
  );
};

export default CatastrophePage;
