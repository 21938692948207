import React from "react";
import useOnToggleBoxes from "./useHandleBox";

const useClaimBoxes = ({ claimLossTypeGroupId, claimLineOfBusiness, claimLossTypeGroups, components }) => {
  const {
    ClaimantBox,
    EmploymentBox,
    ClaimBox,
    WcClaimBox,
    DamagedVehicleBox,
    WitnessBox,
    InsuredBox,
    BodilyInjuryBox,
    DamagedPropertyBox,
    InsuredVehicleBox
  } = components;
  const match = text => claimLossTypeGroups.some(c => c.text === text && c.id === claimLossTypeGroupId);
  const propertyDamage = match("Property Damage");
  const vehicleDamage = match("Vehicle Damage");
  const isWorkersCompensation = claimLineOfBusiness === "Workers Compensation";
  const bodilyInjury = match("Bodily Injury") && !isWorkersCompensation;
  const isAutoLiability = claimLineOfBusiness === "Automobile Liability";

  const boxes = [
    ["claimantBox", ClaimantBox, "claimant", true],
    ["employmentBox", EmploymentBox, "employment", isWorkersCompensation],
    ["claimBox", ClaimBox, "claim", !isWorkersCompensation],
    ["claimBox", WcClaimBox, "wcClaim", isWorkersCompensation],
    ["damagedVehicleBox", DamagedVehicleBox, "damagedV", isAutoLiability && vehicleDamage],
    ["damagedPropertyBox", DamagedPropertyBox, "damagedP", propertyDamage],
    ["bodilyInjuryBox", BodilyInjuryBox, "bodily", bodilyInjury],
    ["witnessBox", WitnessBox, "witness", true],
    ["insuredBox", InsuredBox, "insured", true],
    ["insuredVehicleBox", InsuredVehicleBox, "insuredV", isAutoLiability]
  ];
  const initOpen = boxes.reduce((a, [, , path, show]) => (show ? { ...a, [path]: true } : a), {});
  const { open, onToggle, expandProps } = useOnToggleBoxes({ initialValue: initOpen });
  const showBoxes = boxes.reduce(
    (a, [name, Comp, path, show]) => [...a, { name, path, show: !!show, component: key => <Comp {...{ key, open: open[path], onToggle }} /> }],
    []
  );
  return { expandProps, showBoxes };
};

export default useClaimBoxes;
