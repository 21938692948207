import React, { useEffect, useContext, useState, useMemo } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import ClaimantInfo from "../../../common/claims/claimant/ViewMode";
import ClaimantForm from "../../../common/claims/claimant/Form";
import { set, loadByClaimId } from "../../../../redux/actions/claim/info/claimant";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import { useRouteParam } from "../../../../common";
import Confirm from "../../../common/Confirm";

export default props => {
  const claimId = useRouteParam("claimId");
  const store = useSelector(
    ({ claim }) => ({ claimant: claim.claimant, claim, lastClaimId: claim.claim.id, claimLineOfBusinessId: claim.claim.claimLineOfBusinessId }),
    shallowEqual
  );
  const { claimant, lastClaimId, claimLineOfBusinessId } = store;
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info");
  const {
    props: { boxProps, editMode, loading: isLoading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);

  const dispatch = useDispatch();
  const [state, setState] = useState({ openConfirm: false, dataToSave: {} });
  const { openConfirm, dataToSave } = state;
  const confirmProps = useMemo(
    () => ({
      messageText: (
        <div>
          <p>The claimant is associated to another claims</p>
          <ul>
            {claimant.claimsAssociated
              .filter(el => el.id !== claimId)
              .map(el => (
                <li key={`cl-${el.id}`}>{el.number}</li>
              ))}
          </ul>
          <p>Are you sure you want to proceed?</p>
        </div>
      ),
      title: "Claimant Update",
      onAction: () => {
        showLoading();
        dispatch(
          set(
            dataToSave,
            claimId,
            () => {
              onSuccessEdit();
              setState({ openConfirm: false, dataToSave: {} });
            },
            hideLoading
          )
        );
      },
      openDialog: openConfirm,
      onToggleConfirm: () => setState({ openConfirm: false, dataToSave: {} }),
      okText: `Yes, Update`
    }),
    [openConfirm, dataToSave, claimant.claimsAssociated, claimId]
  );

  const handleSubmit = data => {
    if (claimant.claimsAssociated.filter(el => el.id !== claimId).length > 0) setState({ openConfirm: true, dataToSave: data });
    else {
      showLoading();
      dispatch(set(data, claimId, onSuccessEdit, hideLoading));
    }
  };
  useEffect(() => {
    const accessToOverview = routesAllowed.find(elem => elem.route === "overview");
    if (access.read && ((!claimant?.id && !accessToOverview.read) || (lastClaimId && lastClaimId !== claimId))) {
      showLoading();
      dispatch(loadByClaimId(claimId, hideLoading, hideLoading));
    }
  }, [claimant]);
  return (
    <CollapsibleBox title={claimLineOfBusinessId !== 4 ? "Claimant" : "Employee"} name="claimant" {...boxProps} {...props}>
      {Loading}
      {(editMode && access.update && (
        <>
          <ClaimantForm {...{ claimant, claimLineOfBusinessId, onClose: onToggleEdit, onSave: handleSubmit, isLoading }} />
          <Confirm {...confirmProps} />
        </>
      )) || <ClaimantInfo {...{ claimant, claimLineOfBusinessId }} />}
    </CollapsibleBox>
  );
};
