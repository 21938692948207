import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Grid, IconButton } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import { HorizontalBar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import Card from "../../common/overview/Card";
import { useLoading } from "../../common/Loading";
import { get } from "../../../redux/actions/dashboard/claimsByAdjuster";
import useStyles from "../Dashboard.styles";

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions, Loading } = useLoading(true);
  const { showLoading, hideLoading } = actions;

  useEffect(() => {
    showLoading();
    dispatch(get(hideLoading, hideLoading));
  }, []);
  const claimsByAdjuster = useSelector(({ dashboard: { openClaimsByAdjuster } }) => openClaimsByAdjuster, shallowEqual);

  const adjusters = [...new Set(claimsByAdjuster.map(item => item.adjuster))];

  const lob = ["AL", "GL", "PL", "WC"];

  const data = lob.reduce(
    (acc, curr) => [
      ...acc,
      { lob: curr, quantities: adjusters.map(a => claimsByAdjuster.filter(c => c.adjuster === a && c.lineOfBussiness === curr).length) }
    ],
    []
  );
  const dataBar = {
    labels: adjusters,
    datasets: [
      {
        label: "AL",
        backgroundColor: "#FE9A32",
        data: data.find(d => d.lob === "AL").quantities
      },
      {
        label: "GL",
        backgroundColor: "#F7CB4D",
        data: data.find(d => d.lob === "GL").quantities
      },
      {
        label: "PL",
        backgroundColor: "#7BAAF7",
        data: data.find(d => d.lob === "PL").quantities
      },
      {
        label: "WC",
        backgroundColor: "#BA68C8",
        data: data.find(d => d.lob === "WC").quantities
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { position: "bottom", labels: { usePointStyle: true, padding: 10, boxWidth: 6 } },
    scales: {
      yAxes: [{ ticks: { beginAtZero: true, maxRotation: 45, minRotation: 45 } }],
      xAxes: [{ ticks: { autoSkip: false, precision: 0, beginAtZero: true } }]
    }
  };

  const showDetail = () => {
    history.push("/dashboard/open-claims-count-by-adjuster");
  };
  const widgetActions = () => {
    return (
      <>
        <IconButton size="small" className={classes.icon} onClick={showDetail}>
          <OpenInNew />
        </IconButton>
      </>
    );
  };

  return (
    <>
      {Loading}
      <Card title="Open Claims Count by Adjuster" cardHeaderAction={widgetActions}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs>
            <HorizontalBar height={275} data={dataBar} options={options} />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
