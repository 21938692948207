import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import useOnToggleBoxes from "../../common/customHooks/useHandleBox";
import ClientStructure from "../common/settings/ClientStructure";

const ClientStructureWrapper = ({ setClientLevelSetting }) => {
  const { clientTypes } = useSelector(store => store.common);
  const {
    values: { basicInformation }
  } = useFormikContext();
  const clientTypeInfo = (basicInformation.typeId && clientTypes.filter(item => item.id === basicInformation.typeId)[0]?.text) || "PEO";
  const {
    open: { clientStructure: open },
    onToggle
  } = useOnToggleBoxes({
    initialValue: { clientStructure: true }
  });
  return <ClientStructure isAdd onChange={setClientLevelSetting} clientType={clientTypeInfo} {...{ open, onToggle }} />;
};

ClientStructureWrapper.propTypes = {
  setClientLevelSetting: PropTypes.func.isRequired
};

export default ClientStructureWrapper;
