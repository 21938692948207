import claimant from "./claimant";
import claim from "./claim";
import insured from "./insured";
import wcClaim from "../wcClaim";
import employment from "./employment";
import damagedProperty from "./damagedProperty";
import bodilyInjury from "./bodilyInjury";
import insuredVehicle from "./insuredVehicle";
import damagedVehicle from "./damagedVehicle";
import witness from "./witness";

export default [
  ...claimant,
  ...claim,
  ...wcClaim,
  ...insured,
  ...employment,
  ...damagedProperty,
  ...bodilyInjury,
  ...insuredVehicle,
  ...damagedVehicle,
  ...witness
];
