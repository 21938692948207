import { Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import ClaimStatus from "../../claims/ClaimStatus";
import ClaimProcessingStatus from "../../claims/ClaimProcessingStatus";
import { setExportTitleFileName } from "../../../common";
import { getDuplicates } from "../../../redux/actions/dashboard/payments";
import { useLoading } from "../../common/Loading";
import MaterialTable from "../../common/table/MaterialTable";

export default () => {
  const dispatch = useDispatch();
  const duplicatePayments = useSelector(store => store.dashboard.duplicatePayments, shallowEqual);
  const {
    actions: { showLoading, hideLoading },
    Loading
  } = useLoading(true);
  useEffect(() => {
    showLoading();
    dispatch(getDuplicates(hideLoading, hideLoading));
  }, []);
  const tableProps = {
    columns: [
      { title: "Adjuster Name", field: "adjusterName" },
      {
        title: "Claim #",
        field: "claimNumber",
        render: rowData => (
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: 5 }}>
              {rowData.allowed ? <Link to={`/claim/${rowData.id}`}>{rowData.claimNumber}</Link> : rowData.claimNumber}
            </span>
            <span style={{ marginRight: 5 }}>
              <ClaimStatus status={rowData.claimStatus} />
            </span>
            <ClaimProcessingStatus processingStatus={rowData.claimProcessingStatus} />
          </div>
        )
      },
      { title: "Claimant Name", field: "claimantName" },
      { title: "Reserve Type", field: "reserveTypeName" },
      { title: "Description", field: "description" },
      { title: "Status", field: "status" },
      { title: "Payment Amount", field: "paymentAmount" },
      {
        title: "Payment Date",
        field: "paymentDate",
        render: rowData => (rowData.paymentDate ? moment(rowData.paymentDate).format("M/D/YYYY") : "")
      },
      { title: "Payee Name", field: "payeeName" },
      { title: "Transaction / Check Number", field: "transactionCheckNumber" }
    ],
    data: duplicatePayments,
    options: {
      pageSize: 50,
      filtering: true,
      exportFileName: setExportTitleFileName("PossibleDuplicatePayments")
    },
    hidePaging: (duplicatePayments || []).length <= 50
  };

  return (
    <>
      {Loading}
      <div className="tc-page-container">
        <div className="tc-page-content">
          <Paper className="tc-relative tc-o-hidden tc-box-content">
            <Typography className="tc-title">Duplicate payments report by reserve type by adjuster</Typography>
            <MaterialTable {...tableProps} />
          </Paper>
        </div>
      </div>
    </>
  );
};
