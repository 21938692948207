import { CLAIM, API } from "../../../actions/actionTypes";
import * as actions from "../../../actions/claim/info/insuredVehicle";
import { takeLatest, takeLatestPost } from "../../common/sagaGenerator";

const url = `${API.URL}/claim/insuredVehicle`;

export default [
  takeLatest({
    type: CLAIM.INFO.INSVEHICLE.GET,
    actionSuccess: actions.getSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading insured vehicle",
    url
  }),
  takeLatestPost({
    type: CLAIM.INFO.INSVEHICLE.SET,
    actionSuccess: actions.setSuccess,
    params: ({ insVehicle }) => insVehicle,
    successMessage: "Insured Vehicle information successfully saved",
    errorMessage: "Unable to save the information",
    url
  })
];
