import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import VendorNotes from "../../common/notes";
import { useRouteParam, getUserAccess } from "../../../common";

const Notes = () => {
  const vendorId = useRouteParam("id");
  const dataSelector = useSelector(({ vendor, profile }) => ({ vendor, profile }), shallowEqual);
  const {
    vendor: {
      profile: { basicInformation }
    },
    profile: { securityProfilesDetails }
  } = dataSelector || {};
  const actionsAllowed = getUserAccess(securityProfilesDetails, "VENDORS.NOTES", null, basicInformation?.location?.stateId, null);
  return <VendorNotes vendorId={vendorId} entityNumber={basicInformation?.id.toString()} actionsAllowed={actionsAllowed} />;
};

export default Notes;
