import React, { useEffect, useContext } from "react";
import { Button, Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Link as RouterLink } from "react-router-dom";
import AppContext from "./common/contexts/Container";
import useStyles from "./PageNotFound.styles";

const PageNotfound = () => {
  const { setNotFoundActive } = useContext(AppContext);
  useEffect(() => {
    setNotFoundActive(prev => !prev);
    return () => setNotFoundActive(prev => !prev);
  }, []);
  const classes = useStyles();
  return (
    <Container maxWidth={false} className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="center" spacing={3} className={classes.container}>
        <Grid item xs={1} />
        <Grid item xs={6}>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} className={classes.title}>
              Oops, something broke
            </Grid>
            <Grid item xs={12} className={classes.subtitle}>
              Error 404: We are not sure what went wrong, we couldn&apos;t find the page.
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" className={classes.btnHomePage} component={RouterLink} to="/">
                BACK TO MAIN SECTION
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <div className={classes.logo} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageNotfound;
