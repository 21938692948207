import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import { Divider, Grid, Collapse, Button } from "@material-ui/core";
import { DateTimeInput, SelectInput, TextInput, CheckboxLabel, PhoneInput } from "../../inputs";
import useStyles from "./Style";
import validationSchema from "./yup";
import { filterByParentId } from "../../../../common";
import useYupValidationSchema from "../../customHooks/useYupValidationSchema";

const arr = a => (Array.isArray(a) ? a : []);

const BodilyInjuryInfo = ({ bodilyInjury, onClose, onSave, isLoading }) => {
  const classes = useStyles();
  const { injuryType, bodyPart: bodyPartFull, bodyPartGroup, hospitalLocation, states, treatmentType } = useSelector(
    ({ common }) => common,
    shallowEqual
  );
  const [state, setState] = useState(
    {
      ...bodilyInjury,
      hospitalStateId: bodilyInjury.hospital?.location?.stateId,
      hospitalCity: bodilyInjury.hospital?.location?.city,
      hospitalPhone: bodilyInjury.hospital?.phone,
      hospitalAddress1: bodilyInjury.hospital?.location?.address1,
      hospitalAddress2: bodilyInjury.hospital?.location?.address2,
      hospitalZipCode: bodilyInjury.hospital?.location?.zipCode
    } || {}
  );
  const [loaded, setLoaded] = useState(false);
  const { validationMessages, handleValidateSchema } = useYupValidationSchema(validationSchema);
  const handleValidation = () => handleValidateSchema({ ...state, injuryType });
  useEffect(() => {
    if (loaded) handleValidation();
    else setLoaded(true);
  }, [state]);
  const isDeath = injuryType.some(x => x.id === state.injuryTypeId && x.isDeath);
  useEffect(() => {
    if (!isDeath && state.claimantDateofDeath) setState(ps => ({ ...ps, claimantDateofDeath: undefined }));
  }, [isDeath]);
  const statesFiler = states.filter(({ id }) => arr(hospitalLocation).some(({ stateId }) => stateId === id));
  const hospitalFilter = arr(hospitalLocation)
    .filter(h => !state.hospitalStateId || h.stateId === state.hospitalStateId)
    .map(({ id, name }) => ({ id, text: name }));
  const inputProps = (name, disabled) => ({
    name,
    disabled: disabled || undefined,
    [name === "treatmentReceived" ? "checked" : "value"]: state[name] || undefined,
    error: (validationMessages || {})[name],
    onChange: disabled
      ? undefined
      : ({ target: { value } }) =>
          setState(ps => {
            if (!["hospitalId", "hospitalStateId", "treatmentReceived"].some(x => x === name)) return { ...ps, [name]: value };
            const hosp = hospitalLocation.find(e => name === "hospitalId" && e.id === value);
            return {
              ...ps,
              hospitalStateId: hosp?.stateId,
              hospitalCity: hosp?.city,
              hospitalPhone: hosp?.phone,
              hospitalAddress1: hosp?.address1,
              hospitalAddress2: hosp?.address2,
              hospitalZipCode: hosp?.zipCode,
              hospitalId: ["hospitalStateId", "treatmentReceived"].some(x => x === name) ? undefined : ps.hospitalId,
              [name]: value
            };
          })
  });
  const handleSubmit = event => {
    event.preventDefault();
    const { isValid } = handleValidation();
    if (isValid) onSave(state);
  };
  return (
    <React.Fragment key="bodilyedit">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs>
            <SelectInput {...inputProps("injuryTypeId")} label="Injury Type *" options={injuryType} />
            {isDeath && <DateTimeInput {...inputProps("claimantDateofDeath")} label="Claimant Date Of Death" keyboard />}
          </Grid>
          <Grid item xs>
            <SelectInput {...inputProps("parentBodyPartId")} label="Body Part Group *" options={bodyPartGroup} />
            <SelectInput {...inputProps("bodyPartId")} label="Body Part *" options={filterByParentId(bodyPartFull, state.parentBodyPartId)} />
          </Grid>
          <Grid item xs>
            <CheckboxLabel {...inputProps("treatmentReceived")} label="Initial Treatment Received" />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <TextInput {...inputProps("description")} label="Bodily Injury Description" rows={4} />
          </Grid>
        </Grid>
        <Divider className={classes.marginDevider} />
        <Collapse in={state.treatmentReceived} timeout="auto">
          <Grid container spacing={4}>
            <Grid item xs>
              <SelectInput {...inputProps("treatmentTypeId")} label="Initial Treatment Type *" options={treatmentType} />
              <SelectInput {...inputProps("hospitalStateId")} label="Hospital State *" options={statesFiler} />
              <PhoneInput {...inputProps("phone", true)} label="Hospital Phone Number" />
            </Grid>
            <Grid item xs>
              <TextInput {...inputProps("physicianName")} label="Initial Hospital Physician Name" />
              <TextInput {...inputProps("hospitalCity", true)} label="Hospital City" />
              <TextInput {...inputProps("hospitalAddress2", true)} label="Hospital Address" />
            </Grid>
            <Grid item xs>
              <SelectInput {...inputProps("hospitalId")} label="Hospital Name *" options={hospitalFilter} />
              <TextInput {...inputProps("hospitalAddress1", true)} label="Hospital Address 1" />
              <TextInput {...inputProps("hospitalZipCode", true)} label="Hospital Zip" />
            </Grid>
          </Grid>
        </Collapse>
        <Grid container alignItems="flex-start" justify="flex-end" direction="row">
          <div className={classes.buttonWrapper}>
            <Button variant="outlined" color="primary" onClick={onClose}>
              CANCEL
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
              SAVE
            </Button>
          </div>
        </Grid>
      </form>
    </React.Fragment>
  );
};

BodilyInjuryInfo.propTypes = {
  bodilyInjury: PropTypes.objectOf(PropTypes.any).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default BodilyInjuryInfo;
