import React, { useState, useEffect, Fragment, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Paper } from "@material-ui/core";
import { toast } from "react-toastify";
import FileInput from "./FileInput";
import FileDropzone from "./FileDropzone";
import Header from "./DocumentsHeader";
import FolderListView from "./FolderListView";
import DeleteFolderDialog from "./DeleteFolderDialog";
import ListView from "./ListView";
import AddFolderDialog from "./AddFolderDialog";
import RootListView from "./RootListView";
import RootView from "./RootView";
import { useDocumentsBoxStyles, useProgress, validateFile } from "./hooks";
import { useLoading, useLoadingContext } from "../Loading";
import useViewer from "../viewer/useViewer";
import MoveFilesDialog from "./MoveFilesDialog";

const DocumentsBox = ({
  parentId,
  toggleViews,
  path,
  documents,
  actions: { get, deleteFile, updateFile, addFolder, updateFolder, deleteFolder, uploadFiles, downloadFiles, openFile, onIndex, moveFiles },
  actionsAllowed
}) => {
  const classes = useDocumentsBoxStyles();
  const [state, setState] = useState({
    filter: "",
    folder: { fileList: [], folderList: [] },
    viewType: 0, // 0 folder - 1 files - 3 list
    breadcrumbs: [],
    level: 0,
    delFolder: null,
    downloadArray: [],
    addFolderOpen: false,
    moveFilesOpen: false,
    errorFolder: "",
    hover: false,
    errorDrag: false,
    filesToUpload: "",
    folderSelected: null
  });
  const { filesToUpload, filter, folderSelected, hover, level, viewType } = state;
  const { tree, allFiles } = documents;
  const [downloadState, dispatchDownload] = useProgress(false, 500, 1, () => setState(ps => ({ ...ps, downloadArray: [] })));
  const [uploadState, dispatchUpload] = useProgress(true);
  const dispatch = useDispatch();
  const loadingCtx = useLoadingContext();
  const loadingHook = !loadingCtx ? useLoading(true) : undefined;
  const {
    Loading,
    actions: { showLoading, hideLoading }
  } = loadingCtx ? { ...loadingCtx, Loading: null } : loadingHook;
  useEffect(() => {
    if (documents[path] !== parentId) {
      showLoading();
      dispatch(get(parentId, hideLoading, hideLoading));
    } else {
      setState(ps => ({ ...ps, addFolderOpen: false, moveFilesOpen: false, delFolder: null }));
      hideLoading();
    }
  }, [documents]);
  const findFolder = (data, id) => {
    if (!id) return data;
    let f = data.folderList.find(x => x.id === id);
    for (let i = 0; i < data.folderList.length && !f; i += 1) {
      f = findFolder(data.folderList[i], id);
    }
    return f;
  };
  const folder = !toggleViews || level ? findFolder(tree, level) : tree;
  const foldersNotRoot = !toggleViews || (viewType !== 1 && level !== 0);
  const openFolder = useCallback(({ id, name }, ixBc) => {
    setState(ps => ({
      ...ps,
      breadcrumbs: typeof ixBc === "undefined" ? [...ps.breadcrumbs, { id, name }] : ps.breadcrumbs.filter((_b, i) => i <= ixBc),
      level: id,
      downloadArray: []
    }));
  });
  const withParams = (fn, data, other) => fn({ [path]: documents[path], ...(other?.isQueue ? { indexingStatusId: 3 } : {}) }, data, other);
  const onDeleteFile =
    typeof deleteFile === "function"
      ? useCallback(({ id, isQueue }) => dispatch(withParams(deleteFile, id, { isQueue })), [dispatch, withParams])
      : undefined;
  const onDeleteFolder = typeof deleteFolder === "function" ? useCallback(f => dispatch(deleteFolder(f)), [dispatch, deleteFolder]) : undefined;
  const setDeleteFolder = f => setState(ps => ({ ...ps, delFolder: f }));
  const onCheckItem = useCallback(
    elements =>
      setState(ps => {
        let downloadArray = ps.downloadArray.filter(({ id, file }) => !elements.some(e => e.file === file && e.id === id && !e.checked));
        downloadArray = [
          ...downloadArray,
          ...elements.filter(e => e.checked && !downloadArray.some(a => a.id === e.id && a.file === e.file)).map(({ id, file }) => ({ id, file }))
        ];
        return { ...ps, downloadArray };
      }),
    []
  );
  const { addFolderOpen, breadcrumbs, delFolder, downloadArray, errorDrag, errorFolder, moveFilesOpen } = state;
  const onDeleteFolderConfirm = () =>
    setState(ps => {
      if (ps.delFolder) dispatch(withParams(deleteFolder, ps.delFolder.id));
      return { ...ps, delFolder: null };
    });
  const onOpen = name => open => () => setState(ps => ({ ...ps, [name]: open, downloadArray: [] }));
  const onAddFile = event => {
    event.preventDefault();
    document.querySelector("#document-upload-file").click();
  };
  const onAddFolder = ({ name, description }) => event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (folder.folderList.some(f => f.name.toLowerCase().trim() === name.toLowerCase().trim())) {
      setState(ps => ({ ...ps, errorFolder: "Folder already exists" }));
      return;
    }
    dispatch(withParams(addFolder, { name, description, folderId: folder.id }));
  };
  const onDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
    setState(ps => ({ ...ps, hover: true, errorDrag: [...event.dataTransfer.items].some(i => !validateFile(i)) }));
  };
  const onDragLeave = () => setState(ps => ({ ...ps, hover: false, errorDrag: false }));
  const onFilesCleanup = () => setState(ps => ({ ...ps, filesToUpload: "", folderSelected: null }));
  const onUploadFiles = (event, files, fldr) => {
    event.preventDefault();
    event.stopPropagation();
    if (!(fldr || foldersNotRoot || folderSelected)) return onFilesCleanup();
    if ([...files].map(f => ({ type: f.type, kind: "file" })).some(i => !validateFile(i))) {
      toast.error("Invalid files");
      return onFilesCleanup();
    }
    dispatchUpload({
      action: uploadFiles,
      params: {
        [path]: documents[path],
        data: { files, folderId: (fldr || { id: folderSelected || level }).id || undefined }
      }
    });
    return onFilesCleanup();
  };
  const onDrop = fldr => event => {
    const { files } = event.dataTransfer;
    onDragLeave();
    onUploadFiles(event, files, fldr);
  };
  const onUploadChange = event => {
    const { files } = event.target;
    setState(ps => ({ ...ps, filesToUpload: files }));
    onUploadFiles(event, files);
  };

  const onDownload = items =>
    dispatchDownload({
      action: downloadFiles,
      params: {
        [path]: parentId,
        data: items.map(({ id, file }) => ({ id, file }))
      }
    });

  const headerProps = {
    breadcrumbs,
    classes,
    downloadArray,
    filter,
    level,
    openFolder,
    toggleViews,
    viewType,
    downloadState,
    uploadState,
    actions: {
      goRoot: () => setState(ps => ({ ...ps, breadcrumbs: [], level: 0, downloadArray: [] })),
      onDownload: () => onDownload(downloadArray),
      onFilterChange: ({ target: { value } }) => setState(ps => ({ ...ps, filter: value, downloadArray: [] })),
      setViewType: vf => setState(ps => ({ ...ps, viewType: vf, downloadArray: [] })),
      onAddFile,
      onAddFolder: addFolder ? () => setState(ps => ({ ...ps, errorFolder: "", addFolderOpen: true })) : null,
      onMoveFiles: moveFiles ? () => setState(ps => ({ ...ps, moveFilesOpen: true })) : null
    },
    actionsAllowed
  };
  const onMoveFiles = data => {
    showLoading();
    dispatch(moveFiles({ data, [path]: parentId, onSuccess: hideLoading, onError: hideLoading }));
    return setState(ps => ({ ...ps, moveFilesOpen: false, downloadArray: [] }));
  };
  const { onViewFile, fileViewer } = useViewer({
    dispatchAction: params => {
      showLoading();
      const onComplete = {
        onSuccess: hideLoading,
        onError: hideLoading
      };
      return dispatch(openFile({ ...params, ...onComplete }));
    }
  });

  const onOpenFile = useCallback(f => onViewFile(f.id, { [path]: parentId, ...(f.folder?.isQueue ? { indexingStatusId: 3 } : {}) }), []);

  const onUpdateFolder =
    typeof updateFolder === "function" ? useCallback(fldr => dispatch(updateFolder({ [path]: documents[path] }, fldr)), []) : undefined;
  const rootProps = {
    filter,
    folder,
    openFolder,
    validateFile,
    onDrop,
    downloadState,
    uploadState,
    path,
    onRename: updateFolder ? onUpdateFolder : null,
    onDelete: deleteFolder ? setDeleteFolder : null,
    onAddFileToFolder: id => event => {
      setState(ps => ({ ...ps, folderSelected: id }));
      onAddFile(event);
    },
    onDownload,
    actionsAllowed
  };
  const onUpdateFile =
    typeof updateFile === "function" ? useCallback(file => dispatch(updateFile({ [path]: documents[path] }, file)), []) : undefined;
  const paramsDagAndDrop = actionsAllowed.create ? { onDragOver, onDragLeave, onDrop: onDrop() } : {};
  return (
    <Fragment key="documentWrapper">
      <Paper className={classes.root} {...paramsDagAndDrop}>
        {Loading}
        {actionsAllowed.create && <FileInput value={filesToUpload} onChange={onUploadChange} />}
        {foldersNotRoot && actionsAllowed.create && <FileDropzone {...{ hover, errorDrag, onDragOver, onDragLeave, onDrop: onDrop(folder) }} />}
        <div style={{ zIndex: 2, position: "relative" }}>
          <Header {...headerProps} />
          {toggleViews && viewType === 0 && level === 0 && <RootView {...rootProps} />}
          {toggleViews && viewType === 2 && level === 0 && <RootListView {...rootProps} />}
          {foldersNotRoot && (
            <FolderListView
              {...{ downloadArray, filter, folder, path }}
              actions={{ onUpdateFile, onUpdateFolder, openFolder, onCheckItem, onDeleteFile, onDeleteFolder, onOpenFile, onIndex }}
              actionsAllowed={actionsAllowed}
            />
          )}
          {toggleViews && viewType === 1 && (
            <ListView
              {...{ filter, allFiles, actions: { onDeleteFile, updateFile: onUpdateFile, onCheckItem, onOpenFile }, downloadState, actionsAllowed }}
            />
          )}
        </div>
        {actionsAllowed.delete && (
          <DeleteFolderDialog {...{ open: Boolean(delFolder), onClose: () => setDeleteFolder(null), onSubmit: onDeleteFolderConfirm }} />
        )}
        {actionsAllowed.create && (
          <AddFolderDialog open={addFolderOpen} onClose={onOpen("addFolderOpen")(false)} onSubmit={onAddFolder} error={errorFolder} />
        )}
        {foldersNotRoot && actionsAllowed.create && (
          <MoveFilesDialog open={moveFilesOpen} onClose={onOpen("moveFilesOpen")(false)} {...{ documents, downloadArray, onMoveFiles, folder }} />
        )}
      </Paper>
      {fileViewer}
    </Fragment>
  );
};

DocumentsBox.propTypes = {
  parentId: PropTypes.number.isRequired,
  toggleViews: PropTypes.bool,
  path: PropTypes.string.isRequired,
  documents: PropTypes.shape({ tree: PropTypes.object, allFiles: PropTypes.arrayOf(PropTypes.object) }).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  actionsAllowed: PropTypes.objectOf(PropTypes.any)
};

DocumentsBox.defaultProps = {
  toggleViews: false,
  actionsAllowed: { read: true, update: true, create: true }
};

export default DocumentsBox;
