import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { HorizontalBar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import Card from "../../common/overview/Card";
import { getExpired } from "../../../redux/actions/dashboard/diaries";
import { useLoading } from "../../common/Loading";

const useExpiredStyles = makeStyles(theme => ({
  icon: { color: "#107A98", padding: theme.spacing(1) }
}));

const ExpiredBarChart = () => {
  const dispatch = useDispatch();
  const {
    actions: { showLoading, hideLoading },
    Loading
  } = useLoading(true);
  useEffect(() => {
    showLoading();
    dispatch(getExpired(hideLoading, hideLoading));
  }, []);
  const expiredDiaries = useSelector(store => store.dashboard.expiredDiaries, shallowEqual);
  const distinct = (value, index, self) => self.indexOf(value) === index;
  const ownerIds = expiredDiaries.map(c => c.ownerId && c.ownerId).filter(distinct);
  const data = {
    labels: ownerIds.map(aId => expiredDiaries.find(c => c.ownerId === aId)?.owner),
    datasets: [
      {
        label: "Number of expired",
        backgroundColor: "#7BAAF7",
        hoverBackgroundColor: "#7BAAF7",
        borderColor: "#7BAAF7",
        borderWidth: 1,
        data: ownerIds.map(x => expiredDiaries.filter(c => c.ownerId === x).length)
      }
    ]
  };
  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { position: "bottom", labels: { usePointStyle: true, padding: 10, boxWidth: 6 } },
    scales: {
      yAxes: [{ ticks: { beginAtZero: true, maxRotation: 45, minRotation: 45 } }],
      xAxes: [{ ticks: { autoSkip: false, precision: 0, beginAtZero: true } }]
    }
  };
  const history = useHistory();
  const showDetail = () => {
    history.push("/dashboard/expireddiaries");
  };
  const classes = useExpiredStyles();
  const widgetActions = () => {
    return (
      <>
        <IconButton size="small" className={classes.icon} onClick={showDetail}>
          <OpenInNew />
        </IconButton>
      </>
    );
  };
  return (
    <>
      {Loading}
      <Card title="Expired Diaries by Adjuster" cardHeaderAction={widgetActions}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs>
            <div>
              <HorizontalBar data={data} height={275} options={barOptions} />
            </div>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ExpiredBarChart;
