import React, { shallowEqual } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import ClaimStatus from "../claims/ClaimStatus";
import ClaimProcessingStatus from "../claims/ClaimProcessingStatus";
import MaterialTable from "../common/table/MaterialTable";
import { colors } from "../common/index.styles";
import { disassocciateClaim } from "../../redux/actions/occurrence";
import useCustomConfirm from "../common/customHooks/useCustomConfirm";

const AssociatedList = ({ editMode, search }) => {
  const store = useSelector(({ occurrence }) => occurrence, shallowEqual);

  const {
    occurrence: { id, associatedList, number }
  } = store || {};
  const dispatch = useDispatch();
  const handleDelete = rowData => {
    dispatch(disassocciateClaim({ claimId: rowData.id, occurrenceId: id }, search));
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleDelete, "Cancel", "Yes, Remove");
  const handleConfirmDisassociate = claim => {
    handleCustomConfirm({
      oData: claim,
      titleText: `Remove Claim`,
      messageText: `The following Claim will be removed from this Occurrence: ${claim.number}. Are you sure you want to proceed? `
    });
  };
  const exportFileName = `TerraClaim_${number}_ClaimsRelated`;
  const claimsColumns = [
    {
      title: "Claim Number",
      width: 280,
      field: "number",
      customSort: (a, b) => (a.number || "").localeCompare(b.number || ""),
      render: rowData => (
        <div style={{ display: "flex" }}>
          <span style={{ marginRight: 5 }}>
            <Link to={`/claim/${rowData.id}`}>{rowData.number}</Link>
          </span>
          <span style={{ marginRight: 5 }}>
            <ClaimStatus status={rowData.status} />
          </span>
          <ClaimProcessingStatus processingStatus={rowData.processingStatus} />
        </div>
      )
    },
    { title: "Claimant", field: "claimantFullName", width: 200 },
    {
      title: "DOL",
      field: "dateofLoss",
      type: "date",
      render: rowData => moment(rowData.dateofLoss).format("M/D/YYYY")
    },
    {
      title: "Line of Business",
      field: "claimLineOfBusinessCode"
    },
    { title: "Loss Type", field: "claimLossTypeGroup" }
  ];
  const tableProps = {
    id: "table-associated-claims",
    columns: claimsColumns,
    data: associatedList,
    hidePaging: associatedList.length <= 5,
    options: {
      headerStyle: {
        backgroundColor: colors.gray,
        color: "#000000"
      },
      search: false,
      exportFileName
    },
    actions: editMode
      ? [
          {
            icon: "delete",
            tooltip: "Remove Claim",
            onClick: (_event, rowData) => handleConfirmDisassociate(rowData)
          }
        ]
      : []
  };
  return (
    <div style={{ marginBottom: 20, minHeight: 350 }}>
      <MaterialTable {...tableProps} />
      <Confirm />
    </div>
  );
};

AssociatedList.propTypes = { editMode: PropTypes.bool, search: PropTypes.func.isRequired };

AssociatedList.defaultProps = { editMode: true };
export default AssociatedList;
