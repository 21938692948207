import WIDGET from "../actions/actionTypes/dashboard/widget";
import diaries from "../actions/actionTypes/dashboard/diaries";
import payments from "../actions/actionTypes/dashboard/payments";
import claimsByAdjuster from "../actions/actionTypes/dashboard/claimsByAdjuster";
import claimsLossesByAdjuster from "../actions/actionTypes/dashboard/claimsLossesByAdjuster";
import claimsLossesByAdjusterDetail from "../actions/actionTypes/dashboard/claimsLossesByAdjusterDetail";
import initialState from "./initialState";

export default (state = initialState.dashboard, action) => {
  switch (action.type) {
    case diaries.GETALL_SUCCESS:
      return { ...state, diaries: action.mydiaries };
    case WIDGET.CLAIM_WITHOUT_ACTIVITY.GETALL_SUCCESS:
      return { ...state, claimWithoutActivity: action.data };
    case diaries.EXPIRED.GETALL_SUCCESS:
      return { ...state, expiredDiaries: action.expiredDiaries };
    case payments.DUPLICATES.GETALL_SUCCESS:
      return { ...state, duplicatePayments: action.duplicatePayments };
    case payments.DUPLICATES_COUNT.GETALL_SUCCESS:
      return { ...state, duplicatePaymentsChart: action.duplicatePaymentsChart };
    case claimsByAdjuster.GETALL_SUCCESS:
      return { ...state, openClaimsByAdjuster: action.listClaimsByAdjuster };
    case claimsLossesByAdjuster.GETALL_SUCCESS:
      return { ...state, claimsLossesByAdjuster: action.listClaimsLossesByAdjuster };
    case claimsLossesByAdjusterDetail.GETALL_SUCCESS:
      return { ...state, claimsLossesByAdjusterDetail: action.listClaimsLossesByAdjusterDetail };
    default:
      return state;
  }
};
