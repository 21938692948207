import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  footerButton: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  changeParentBUtton: {
    float: "right",
    textDecoration: "underline"
  },
  root: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(4)
  },
  icons: {
    color: "#898F91"
  },
  clientListPaper: {
    padding: "22px 22px 100px",
    "& th": {
      background: "#F0F0F0"
    }
  },
  appNavBarHeader: {
    backgroundColor: "#F4F8F9",
    [theme.breakpoints.up("sm")]: {
      minWidth: "initial !important"
    },
    "& h6": {
      color: "#004763"
    }
  },
  active: { color: "#73B25D", backgroundColor: "#FFFFFF", borderRadius: "25%", verticalAlign: "middle", marginLeft: "auto" },
  inactive: { color: "#E74C3C", backgroundColor: "#FFFFFF", borderRadius: "25%", verticalAlign: "middle", marginLeft: "auto" }
}));
