import React from "react";
import PropTypes from "prop-types";
import { FormControl, FormLabel, RadioGroup, FormHelperText } from "@material-ui/core";
import RadioLabel from "./RadioLabel";
import { HocInput } from "./HocInput";

export const BaseRadioButtonGroup = ({ label, options, error, classes, disabled, onRender, ...other }) => {
  const fnRender =
    typeof onRender === "function"
      ? onRender
      : ({ id, text }) => <RadioLabel key={typeof id === "boolean" ? `val-${id}` : id} value={id} label={text} {...{ classes, disabled }} />;
  return (
    <FormControl component="fieldset" error={Boolean(error)}>
      {label && (
        <FormLabel component="label" className="MuiInputLabel-shrink">
          {label}
        </FormLabel>
      )}
      <RadioGroup {...other}>{options.map(fnRender)}</RadioGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

BaseRadioButtonGroup.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  onRender: PropTypes.func
};

BaseRadioButtonGroup.defaultProps = {
  label: undefined,
  error: undefined,
  classes: undefined,
  disabled: false,
  onRender: undefined
};

const radioButtonGroupStyle = { root: { marginBottom: "1rem" } };

export default HocInput(BaseRadioButtonGroup, radioButtonGroupStyle);
