import { toast } from "react-toastify";
import { API, ADMIN, CLAIM, OCCURRENCE } from "../../../actions/actionTypes";
import { getAllSuccess, setSuccess, delSuccess } from "../../../actions/admin/templates/diaries";
import { getTemplatesSuccess as getClaimTemplatesSuccess } from "../../../actions/claim/diary";
import { getTemplatesSuccess as getOccurrenceTemplatesSuccess } from "../../../actions/occurrence";
import { takeLatest, takeLatestDel } from "../../common/sagaGenerator";

const url = `${API.URL}/diary/template`;

export default [
  takeLatest({
    type: ADMIN.TEMPLATE_DIARY.GETALL,
    url,
    actionSuccess: getAllSuccess,
    errorMessage: "Unable to get Diary Templates"
  }),
  takeLatest({
    type: ADMIN.TEMPLATE_DIARY.SET,
    url,
    method: ({ data: { id } }) => (id ? "PUT" : "POST"),
    params: ({ data }) => data,
    actionSuccess: setSuccess,
    onSuccess: ({ data: { id } }) => toast.success(`Diary Template successfully ${id ? "upd" : "cre"}ated`),
    onError: ({ data: { id } }) => toast.error(`Unable to ${id ? "upd" : "cre"}ate the Diary Template`)
  }),
  takeLatestDel({
    type: ADMIN.TEMPLATE_DIARY.DEL,
    url,
    params: ({ id }) => ({ id }),
    actionSuccess: delSuccess,
    successMessage: "Diary Template successfully deleted",
    errorMessage: "Unable to delete the Diary Template"
  }),
  takeLatest({
    type: CLAIM.DIARY.TEMPLATES.GET,
    url: ({ claimId }) => `${url}/claim/${claimId}`,
    actionSuccess: getClaimTemplatesSuccess,
    errorMessage: "Unable to get Diary Templates for Claim"
  }),
  takeLatest({
    type: OCCURRENCE.DIARY.TEMPLATES.GET,
    url: ({ occurrenceId }) => `${url}/occurrence/${occurrenceId}`,
    actionSuccess: getOccurrenceTemplatesSuccess,
    errorMessage: "Unable to get Diary Templates for Occurrence"
  })
];
