import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import LabelValue from "../common/LabelValue";
import useStyles from "./CatastropheDuplicates.styles";
import MaterialTable from "../common/table/MaterialTable";
import AssociatedList from "./AssociatedList";

const ReadOnlyForm = ({ catastrophe }) => {
  const classes = useStyles();
  const c = catastrophe || {};
  const { name, dateSince, dateTo, description, multipleLocation } = {
    ...c,
    location:
      !!c.locationList?.length && !c.multipleLocation
        ? c.locationList[0] || {}
        : { address1: "", address2: "", city: "", county: "", stateId: null, zipCode: "", stateReportingNumber: "" },
    locationList:
      !!c.locationList?.length && !c.multipleLocation
        ? []
        : (c.locationList || []).reduce((acc, item) => [...acc, { ...item, newStateid: item.stateId.toString() }], [])
  };
  const tablePropsLocation = {
    title: "Multiple Location",
    columns: [
      { title: "City", field: "city" },
      { title: "State", field: "state" },
      { title: "State Reporting Number", field: "stateReportingNumber" }
    ],
    data: catastrophe?.locationList,
    options: { search: false },
    hidePaging: catastrophe?.locationList?.length <= 5
  };
  const locations = catastrophe?.locationList?.map(elem => (
    <>
      <Grid item xs={8}>
        <LabelValue label="Physical Address" value={elem} isAddress showAddress2 />
      </Grid>
      <Grid item xs={4}>
        <LabelValue label="State Reporting Number" value={elem.stateReportingNumber} />
      </Grid>
    </>
  ));
  const desc = <LabelValue name="description" label="Description" value={description} classes={{ root: "tc-mb0" }} />;
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container spacing={8}>
          <Grid item xs={6} md={4}>
            <LabelValue label="Name" value={name} />
          </Grid>
          <Grid item xs={6} md={4}>
            <LabelValue label="Date Since" value={dateSince} isDate />
          </Grid>
          <Grid item xs={6} md={4}>
            <LabelValue label="Date To" value={dateTo} isDate />
          </Grid>
        </Grid>
        {(multipleLocation && (
          <>
            <Grid container>
              <Grid item xs={12}>
                {desc}
              </Grid>
            </Grid>
            <MaterialTable {...tablePropsLocation} hidePaging />
          </>
        )) || (
          <>
            <Grid container>{locations}</Grid>
            <Grid container>
              <Grid item xs={12}>
                {desc}
              </Grid>
            </Grid>
          </>
        )}
        <AssociatedList editMode={false} />
      </div>
    </div>
  );
};
ReadOnlyForm.propTypes = {
  catastrophe: PropTypes.shape({ location: PropTypes.shape({}) }).isRequired
};
export default ReadOnlyForm;
