import { makeStyles } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";

const colors = {
  green: "#428302",
  blue: "#107A98",
  gray: "#F3F3F3",
  black: "#000000",
  lightBlue: "#1599BC",
  color898F91: "#898F91"
};

const title = theme => ({
  color: colors.green,
  fontSize: 16,
  paddingBottom: theme.spacing(2.75),
  fontWeight: 500
});

const box = makeStyles(theme => ({
  title: title(theme),
  content: {
    padding: theme.spacing(2)
  }
}));

const read = makeStyles(() => ({
  form: {
    paddingBottom: "30px",
    "& hr": {
      margin: "10px 0px 10px 0px"
    }
  }
}));

const tableTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.gray
    },
    secondary: {
      main: colors.blue
    }
  }
});

export const table = makeStyles(() => ({
  header: {
    backgroundColor: "#FFFFFF",
    color: colors.black
  },
  fullHeader: {
    "& a": {
      color: "#1599BC"
    },
    "& th": {
      background: colors.gray
    }
  }
}));

export const icon = makeStyles(() => ({
  link: {
    color: "#107A98",
    marginBottom: -7,
    marginTop: 2,
    marginLeft: 5,
    cursor: "pointer"
  },
  table: {
    color: colors.color898F91
  }
}));

export { colors, box, tableTheme, read, title };
