import { getActions, setActions, postActions, getAllActions, delActions } from "./common";

export default {
  USER: {
    ...getAllActions("ADMIN_USERS"),
    ...postActions("ADMIN_USER"),
    ...setActions("ADMIN_USER"),
    ...delActions("ADMIN_USER"),
    CLAIMS: "GET_ADMIN_USER_CLAIMS",
    CLAIMS_SUCCESS: "GET_ADMIN_USER_CLAIMS_SUCCESS"
  },
  SECURITYPROFILE: {
    RESET: "ADMIN_SECURITY_PROFILE_RESET",
    ...setActions("ADMIN_SECURITY_PROFILE"),
    ...postActions("ADMIN_SECURITY_PROFILE"),
    ...getActions("ADMIN_SECURITY_PROFILE"),
    ...getAllActions("ADMIN_SECURITY_PROFILE"),
    ...delActions("ADMIN_SECURITY_PROFILE")
  },
  CLAIMSETTING: {
    JURISDICTIONSTATE: {
      ...getAllActions("ADMIN_CLAIMSETTING_JURISDICTIONSTATE"),
      ...postActions("ADMIN_CLAIMSETTING_JURISDICTIONSTATE"),
      ...setActions("ADMIN_CLAIMSETTING_JURISDICTIONSTATE"),
      ...delActions("ADMIN_CLAIMSETTING_JURISDICTIONSTATE")
    },
    LINEOFBUSINESS: {
      ...getAllActions("ADMIN_CLAIMSETTING_LINEOFBUSINESS"),
      ...postActions("ADMIN_CLAIMSETTING_LINEOFBUSINESS"),
      ...setActions("ADMIN_CLAIMSETTING_LINEOFBUSINESS"),
      ...delActions("ADMIN_CLAIMSETTING_LINEOFBUSINESS")
    },
    TIMELINEDATE: {
      ...getAllActions("ADMIN_CLAIMSETTING_TIMELINEDATE"),
      ...setActions("ADMIN_CLAIMSETTING_TIMELINEDATE")
    },
    RESERVES: {
      ...getAllActions("ADMIN_CLAIMSETTING_RESERVES"),
      ...postActions("ADMIN_CLAIMSETTING_RESERVES"),
      ...delActions("ADMIN_CLAIMSETTING_RESERVES"),
      TYPES: {
        ...getAllActions("ADMIN_CLAIMSETTING_RESERVES_TYPES"),
        ...postActions("ADMIN_CLAIMSETTING_RESERVES_TYPES")
      }
    },
    WIDGET: {
      ...getAllActions("ADMIN_CLAIMSETTING_WIDGET"),
      ...setActions("ADMIN_CLAIMSETTING_WIDGET")
    },
    DOCUMENTS: {
      ...getActions("ADMIN_CLAIMDOCUMENTS"),
      ...getAllActions("ADMIN_CLAIMDOCUMENTS"),
      ...setActions("ADMIN_CLAIMDOCUMENTS"),
      ...delActions("ADMIN_CLAIMDOCUMENTS"),
      GETACTIVE: "GET_ADMIN_CLAIMDOCUMENTS_ACTIVE",
      GETACTIVE_SUCCESS: "GET_ADMIN_CLAIMDOCUMENTS_ACTIVE_SUCCESS",
      SETACTIVE: "SET_ADMIN_CLAIMDOCUMENTS_ACTIVE",
      SETACTIVE_SUCCESS: "SET_ADMIN_CLAIMDOCUMENTS_ACTIVE_SUCCESS"
    }
  },
  NOTE_TYPE: {
    ...getAllActions("ADMIN_NOTETYPES"),
    ...postActions("ADMIN_NOTETYPE"),
    ...setActions("ADMIN_NOTETYPE"),
    ...delActions("ADMIN_NOTETYPE")
  },
  TEMPLATE_COMMUNICATION: {
    ...getAllActions("TEMPLATE_COMMUNICATION"),
    ...setActions("TEMPLATE_COMMUNICATION"),
    ...delActions("TEMPLATE_COMMUNICATION")
  },
  TEMPLATE_DIARY: {
    ...getAllActions("TEMPLATE_DIARY"),
    ...setActions("TEMPLATE_DIARY"),
    ...delActions("TEMPLATE_DIARY")
  }
};
