import React, { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuList, MenuItem } from "@material-ui/core";
import FinancialIcon from "@material-ui/icons/AttachMoney";
import DashboardSvg from "../../assets/dashboard_left_menu.svg";
import ClaimsSvg from "../../assets/claims_left_menu.svg";
import DocumentsSvg from "../../assets/document_left_menu.svg";
import AutomationSvg from "../../assets/miscellaneous_services.svg";
import TerraMapSvg from "../../assets/terra_map_left_menu.svg";
import IntelligenceSvg from "../../assets/intelligence.svg";
import VendorSvg from "../../assets/vendor_left_menu.svg";
import ClientSvg from "../../assets/client_menu.svg";
import AdminSvg from "../../assets/admin_icon.svg";
import usePermissionForMenu from "../common/customHooks/usePermissionForMenu";

const SignedInLinks = () => {
  const { pathname } = useLocation();
  const { permissions } = usePermissionForMenu();
  const isDisabled = useCallback(routeName => !permissions.find(e => e.routeName === routeName)?.read, [permissions]);
  const isSelected = menuName => {
    const indexOf = p => pathname.indexOf(`/${p || ""}`);
    if (menuName === "claims") return ["claim", "catastrophe", "occurrence", "initialReport"].some(p => indexOf(p) === 0);
    return indexOf(menuName) === 0;
  };
  return (
    <MenuList disablePadding>
      {[
        ["Dashboard", "/", pathname === "/", DashboardSvg],
        ["Claims", "/claims", isSelected("claims"), ClaimsSvg, isDisabled("claims")],
        ["Documents", "/documents", isSelected("documents"), DocumentsSvg, isDisabled("documents")],
        ["Automation", "/automation", isSelected("automation"), AutomationSvg, isDisabled("automation")],
        ["Intelligence", "/intelligence", isSelected("intelligence"), IntelligenceSvg, isDisabled("intelligence")],
        ["TerraMaps", "/terramaps", isSelected("terramaps"), TerraMapSvg, isDisabled("terramaps")],
        ["Financial", "/financial", isSelected("financial"), FinancialIcon, isDisabled("financial")],
        ["Clients", "/clients", isSelected("client"), ClientSvg, isDisabled("clients")],
        ["Vendors", "/vendors", isSelected("vendor"), VendorSvg, isDisabled("vendors")],
        ["Admin", "/admin", isSelected("admin"), AdminSvg, isDisabled("admin")]
      ].map(([title, to, selected, Icon, disabled]) => (
        <MenuItem key={title} className="tc-main-menu-item" component={Link} {...{ to, selected, disabled }}>
          <Icon />
          {title}
        </MenuItem>
      ))}
    </MenuList>
  );
};
export default SignedInLinks;
