import { CLIENTR as CLIENT, API } from "../../actions/actionTypes";
import { addClientSuccess } from "../../actions/client/add";
import { takeLatestPost } from "../common/sagaGenerator";

export default [
  takeLatestPost({
    type: CLIENT.ADD.POST,
    actionSuccess: addClientSuccess,
    successMessage: "Client successfully created",
    errorMessage: "Unable to create the Client",
    url: `${API.URL}/client`,
    params: ({ data }) => data
  })
];
