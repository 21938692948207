import { makeStyles } from "@material-ui/core";
import { colors } from "../../../App.styles";

export default makeStyles({
  bar: {
    flexGrow: 0,
    flexShrink: 0,
    width: 450,
    maxWidth: 450,
    backgroundColor: "white",
    height: "100%",
    overflow: "hidden",
    overflowY: "auto",
    zIndex: 1,
    display: "flex",
    flexDirection: "column"
  }
});

const { blue, red, green } = colors;
const icon = { lineHeight: 1 };
const textNoWrap = { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" };

export const useClaimPanelStyles = makeStyles(t => ({
  root: { padding: t.spacing(4, 3, 2, 3) },
  claimNo: { fontSize: 24, fontWeight: 500 },
  icon,
  claimant: { flexGrow: 1, fontSize: 16, fontWeight: 500, ...textNoWrap }
}));

const rootPadding = t => ({ padding: t.spacing(2, 3, 2, 3) });

export const useLobStyles = makeStyles(t => ({
  root: rootPadding(t),
  icon,
  value: { marginTop: t.spacing(0.5) },
  textNoWrap,
  itemNoWrap: { flexGrow: 1, overflow: "hidden", flexBasis: "40%" },
  rect: { background: blue.light, borderRadius: 4, padding: t.spacing(0, 1.25) }
}));

export const useClaimDayStyles = makeStyles(t => ({
  root: rootPadding(t),
  icon,
  barwrapper: { paddingTop: t.spacing(2) },
  progress: {
    width: "100%",
    height: 11,
    backgroundColor: blue.dark,
    borderRadius: 4,
    overflow: "hidden",
    "& > div": {
      cursor: "default",
      height: 11,
      transition: t.transitions.create("width", {
        easing: t.transitions.easing.easeInOut,
        duration: t.transitions.duration.complex
      })
    }
  }
}));

export const useCatOccStyles = makeStyles(t => ({ root: rootPadding(t) }));

export const useLitigationStyles = makeStyles(t => ({
  root: rootPadding(t),
  icon,
  red,
  coverage: { color: green, fontWeight: 500 }
}));

export const useReservesFinancialStyles = makeStyles(theme => ({
  root: { ...rootPadding(theme), flexGrow: 1 },
  icon,
  red,
  amount: { marginBottom: theme.spacing(1) },
  total: { color: "#1599BC" },
  outstanding: { color: "#A7CE05" }
}));

export const useLastActivityStyles = makeStyles(t => ({ root: rootPadding(t) }));
