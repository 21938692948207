import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import MaterialTable from "../../components/common/table/MaterialTable";
import { getAll } from "../../redux/actions/initialReport/reports";
import { getUserAccess, setExportTitleFileName } from "../../common";
import useLoading from "../../components/common/customHooks/useIsSubmitting";
import Loading from "../../components/common/Loading";
import BtnMenues from "../../components/common/menu/ButtonMenu";
import DateFilter from "../../components/common/table/DateFilter";

const InitialReports = () => {
  const store = useSelector(
    ({ initialReport: { initialReports: ireports }, profile: { securityProfilesDetails } }) => ({
      initialReports: ireports,
      secProfs: securityProfilesDetails
    }),
    shallowEqual
  );
  const { initialReports, secProfs } = store || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useLoading();
  const hideLoading = () => {
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(getAll(hideLoading, hideLoading));
  }, []);
  const convertToaClaim = getUserAccess(secProfs, "INITIALREPORT.CONVERTTOACLAIM", null, null, null);
  const getNextTab = (clientId, stateId, lobId) => {
    if (getUserAccess(secProfs, "CLAIM.INFO", clientId, stateId, lobId).read) return "info";
    if (getUserAccess(secProfs, "CLAIM.DOCUMENTS", clientId, stateId, lobId).read) return "documents";
    if (getUserAccess(secProfs, "CLIENT.NOTES", clientId, stateId, lobId).read) return "notes";
    if (getUserAccess(secProfs, "CLAIM.ACTIVITY", clientId, stateId, lobId).read) return "activity";
    return "";
  };
  const types = [
    { id: 1, text: "Property & Casualty" },
    { id: 2, text: "Workers Compensation" }
  ];
  const handleNewClaim = type => {
    if (type === 1) history.push("/claim?ir=1");
    else history.push("/wcClaim?ir=1");
  };
  const getButtonToolBar = () => {
    if (!convertToaClaim) return null;
    return (
      <BtnMenues
        menuName="Add Initial Report"
        subMenues={types}
        menuId="addInitialReport"
        onClick={option => {
          handleNewClaim(option.id);
        }}
      />
    );
  };

  const exportFileName = setExportTitleFileName(`InitialReportsList`);

  const tableProps = {
    columns: [
      {
        title: "Report Number",
        field: "number",
        customSort: (a, b) => (a.number || "").localeCompare(b.number || ""),
        render: rowData => (
          <Link to={`/initialReport/${rowData.id}/${getNextTab(rowData.rootClientId, rowData.stateId, rowData.claimLineOfBusinessId)}`}>
            {rowData.number}
          </Link>
        )
      },
      { title: "Reported By", field: "claimant" },
      { title: "Date Of Loss", type: "date", field: "dateofLoss", filterComponent: DateFilter },
      { title: "Date Reported", type: "date", field: "dateReported", filterComponent: DateFilter },
      { title: "Insured", field: "insured" },
      { title: "Line Of Business", field: "claimLineOfBusinessCode" },
      { title: "Claim Type", field: "type" }
    ],
    data: initialReports,
    hidePaging: initialReports.length <= 50,
    options: {
      pageSize: 50,
      filtering: true,
      exportFileName
    },
    ButtonToolBar: getButtonToolBar
  };

  return (
    <Fragment key="initial-reports">
      {isLoading && <Loading float />}
      <div className="tc-page-content">
        <Paper className="tc-p2">
          <MaterialTable {...tableProps} />
        </Paper>
      </div>
    </Fragment>
  );
};

export default InitialReports;
