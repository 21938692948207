import { CLAIM, API } from "../../../actions/actionTypes";
import { getSuccess, setSuccess } from "../../../actions/claim/info/bodilyInjury";
import { takeLatest, takeLatestSet } from "../../common/sagaGenerator";

const url = `${API.URL}/bodilyInjury/`;

export default [
  takeLatest({
    type: CLAIM.INFO.BODILYINJURY.GET,
    actionSuccess: getSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading Bodily Injury",
    url: ({ id }) => `${url}${id}`
  }),
  takeLatestSet({
    type: CLAIM.INFO.BODILYINJURY.SET,
    actionSuccess: setSuccess,
    params: ({ bodilyInjury }) => bodilyInjury,
    successMessage: "Bodily Information Updated",
    errorMessage: "Error saving Bodily Information",
    url
  })
];
