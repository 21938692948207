import { setActions, getActions } from "./common";

export default {
  SETTING: {
    GET: "CLIENT_GET_LEVEL_TABS_SETTINGS",
    GET_SUCCESS: "CLIENT_GET_SUCCESS_LEVEL_TABS_SETTINGS",
    CLIENT_STRUCTURE: {
      ...setActions("CLIENT_STRUCTURE_SETTING")
    },
    RESERVE_PAYMENT: {
      ...setActions("CLIENT_RESERVEPAYMENT_SETTING")
    },
    BILL_REVIEW: {
      ...setActions("CLIENT_BILL_REVIEW_SETTING")
    },
    TEAM: setActions("CLIENT_TEAM_SETTING")
  },
  LEVELS: {
    ...getActions("CLIENT_LEVELS")
  },
  ROUTES: {
    ...getActions("CLIENT_ROUTES")
  },
  LIST: {
    GET: "CLIENT_GET_ALL",
    GET_SUCCESS: "CLIENT_GET_ALL_SUCCESS",
    GET_ALL_BY_PARENT: "CLIENT_BY_PARENT_GET_ALL",
    GET_ALL_BY_PARENT_SUCCESS: "CLIENT_BY_PARENT_GET_ALL_SUCCESS",
    DEL: "DEL_CLIENT",
    DEL_SUCCESS: "DEL_CLIENT_SUCCESS",
    MOVE_ENTITY_TO_NEW_PARENT: "MOVE_ENTITY_TO_NEW_PARENT",
    MOVE_ENTITY_TO_NEW_PARENT_SUCCESS: "MOVE_ENTITY_TO_NEW_PARENT_SUCCESS"
  },
  ADD: {
    POST: "POST_CLIENT",
    POST_SUCCESS: "POST_CLIENT_SUCCESS",
    POST_AND_MIGRATE: "POST_CLIENT_AND_MIGRATE_LEVEL",
    POST_AND_MIGRATE_SUCCESS: "POST_CLIENT_SUCCESS"
  },
  ADDCLIENT: {
    POST: "POST_NEW_CLIENT",
    POST_SUCCESS: "POST_NEW_CLIENT_SUCCESS"
  },
  PROFILE: {
    GET: "GET_PROFILE",
    GET_SUCCESS: "GET_PROFILE_SUCCESS",
    BASIC: {
      SET: "SET_CLIENT_BASIC",
      SET_SUCCESS: "SET_CLIENT_BASIC_SUCCESS"
    },
    AGENT: {
      SET: "SET_CLIENT_AGENT",
      SET_SUCCESS: "SET_CLIENT_AGENT_SUCCESS"
    },
    STATUS: {
      SET: "SET_CLIENT_STATUS",
      SET_SUCCESS: "SET_CLIENT_STATUS_SUCCESS"
    },
    CODE_PROFILE: {
      POST: "POST_CLIENT_CODE",
      POST_SUCCESS: "POST_CLIENT_CODE_SUCCESS",
      DEL: "DEL_CLIENT_CODE",
      DEL_SUCCESS: "DEL_CLIENT_CODE_SUCCESS"
    },
    AREA: {
      POST: "POST_CLIENT_AREA",
      POST_SUCCESS: "POST_CLIENT_AREA_SUCCESS",
      SET: "SET_CLIENT_AREA",
      SET_SUCCESS: "SET_CLIENT_AREA_SUCCESS",
      DEL: "DEL_CLIENT_AREA",
      DEL_SUCCESS: "DEL_CLIENT_AREA_SUCCESS"
    },
    OPERATING_STATE: {
      POST: "POST_CLIENT_OPERATING_STATE",
      POST_SUCCESS: "POST_CLIENT_OPERATING_STATE_SUCCESS",
      SET: "SET_CLIENT_OPERATING_STATE",
      SET_SUCCESS: "SET_CLIENT_OPERATING_STATE_SUCCESS",
      DEL: "DEL_CLIENT_OPERATING_STATE",
      DEL_SUCCESS: "DEL_CLIENT_OPERATING_STATE_SUCCESS"
    }
  },
  POLICY: {
    GET: "GET_CLIENT_POLICIES",
    GET_SUCCESS: "GET_CLIENT_POLICIES_SUCCESS"
  },
  BANKING: {
    POST_SUCCESS: "POST_CLIENTBANKING_SUCCESS",
    SET_SUCCESS: "SET_CLIENTBANKING_SUCCESS",
    DEL_SUCCESS: "DEL_CLIENTBANKING_SUCCESS",
    GET_SUCCESS: "GET_CLIENTBANKING_SUCCESS"
  },
  INSUREDS: {
    GET: "GET_CLIENT_INSUREDS",
    GET_SUCCESS: "GET_CLIENT_INSUREDS_SUCCESS",
    DEL: "DEL_CLIENT_INSURED",
    DEL_SUCCESS: "DEL_CLIENT_INSURED_SUCCESS",
    POST: "POST_CLIENT_INSURED",
    POST_SUCCESS: "POST_CLIENT_INSURED_SUCCESS"
  },
  CONTACTS: {
    GET: "GET_CLIENT_CONTACTS",
    GET_SUCCESS: "GET_CLIENT_CONTACTS_SUCCESS",
    POST: "POST_CLIENT_CONTACTS",
    POST_SUCCESS: "POST_CLIENT_CONTACTS_SUCCESS",
    SET: "SET_CLIENT_CONTACTS",
    SET_SUCCESS: "SET_CLIENT_CONTACTS_SUCCESS",
    DEL: "DEL_CLIENT_CONTACTS",
    DEL_SUCCESS: "DEL_CLIENT_CONTACTS_SUCCESS"
  },
  INSURED: {
    PROFILE: {
      GET: "GET_INSURED_PROFILE",
      GET_SUCCESS: "GET_INSURED_PROFILE_SUCCESS",
      BASIC: {
        SET: "SET_INSURED_BASIC",
        SET_SUCCESS: "SET_INSURED_BASIC_SUCCESS"
      },
      STATUS: {
        SET: "SET_INSURED_STATUS",
        SET_SUCCESS: "SET_INSURED_STATUS_SUCCESS"
      },
      CODE_PROFILE: {
        POST: "POST_INSURED_CODE",
        POST_SUCCESS: "POST_INSURED_CODE_SUCCESS",
        DEL: "DEL_INSURED_CODE",
        DEL_SUCCESS: "DEL_INSURED_CODE_SUCCESS"
      }
    },
    CONTACTS: {
      GET: "GET_CLIENT_INSURED_CONTACTS",
      GET_SUCCESS: "GET_CLIENT_INSURED_CONTACTS_SUCCESS"
    },
    EMPLOYEE: {
      POST: "POST_CLIENT_INSURED_EMPLOYEE",
      POST_SUCCESS: "POST_CLIENT_INSURED_EMPLOYEE_SUCCESS",
      PUT: "PUT_CLIENT_INSURED_EMPLOYEE",
      PUT_SUCCESS: "PUT_CLIENT_INSURED_EMPLOYEE_SUCCESS",
      DELETE: "DELETE_CLIENT_INSURED_EMPLOYEE",
      DELETE_SUCCESS: "DELETE_CLIENT_INSURED_EMPLOYEE_SUCCESS"
    },
    EMPLOYEES: {
      GET: "GET_CLIENT_INSURED_EMPLOYEE",
      GET_SUCCESS: "GET_CLIENT_INSURED_EMPLOYEE_SUCCESS"
    },
    BANKING: {
      GET: "GET_CLIENT_INSURED_ACCOUNTS",
      GET_SUCCESS: "GET_CLIENT_INSURED_ACCOUNTS_SUCCESS",
      POST: "POST_CLIENT_INSURED_BANKING",
      POST_SUCCESS: "POST_CLIENT_INSURED_BANKING_SUCCESS",
      SET: "SET_CLIENT_INSURED_BANKING",
      SET_SUCCESS: "SET_CLIENT_INSURED_BANKING_SUCCESS",
      DEL: "DEL_CLIENT_INSURED_BANKING",
      DEL_SUCCESS: "DEL_CLIENT_INSURED_BANKING_SUCCESS"
    },
    POLICY: {
      GET: "GET_CLIENT_INSURED_POLICY",
      GET_SUCCESS: "GET_CLIENT_INSURED_POLICY_SUCCESS",
      GET_POLICIES: "GET_CLIENT_INSURED_POLICIES",
      GET_POLICIES_SUCCESS: "GET_CLIENT_INSURED_POLICIES_SUCCESS",
      SET: "SET_CLIENT_INSURED_POLICY",
      SET_SUCCESS: "SET_CLIENT_INSURED_POLICY_SUCCESS",
      POST: "POST_CLIENT_INSURED_POLICY",
      POST_SUCCESS: "POST_CLIENT_INSURED_POLICY_SUCCESS",
      DEL: "DEL_CLIENT_INSURED_POLICY",
      DEL_SUCCESS: "DEL_CLIENT_INSURED_POLICY_SUCCESS",
      RELATED: {
        GET: "GET_CLIENT_INSURED_POLICY_RELATED",
        GET_SUCCESS: "GET_CLIENT_INSURED_POLICY_RELATED_SUCCESS",
        POST: "POST_CLIENT_INSURED_POLICY_RELATED",
        POST_SUCCESS: "POST_CLIENT_INSURED_POLICY_RELATED_SUCCESS",
        DEL: "DEL_CLIENT_INSURED_POLICY_RELATED",
        DEL_SUCCESS: "DEL_CLIENT_INSURED_POLICY_RELATED_SUCCESS"
      },
      COVERAGE: {
        GET: "GET_CLIENT_INSURED_POLICY_COVERAGE",
        GET_SUCCESS: "GET_CLIENT_INSURED_POLICY_COVERAGE_SUCCESS",
        SET: "SET_CLIENT_INSURED_POLICY_COVERAGE",
        SET_SUCCESS: "SET_CLIENT_INSURED_POLICY_COVERAGE_SUCCESS",
        DEL: "DEL_INSURED_POLICY_COVERAGE",
        DEL_SUCCESS: "DEL_INSURED_POLICY_COVERAGE_SUCCESS"
      },
      AGGREGATE: {
        GET: "GET_CLIENT_INSURED_POLICY_AGGREGATE",
        GET_SUCCESS: "GET_CLIENT_INSURED_POLICY_AGGREGATE_SUCCESS",
        SET: "SET_CLIENT_INSURED_POLICY_AGGREGATE",
        SET_SUCCESS: "SET_CLIENT_INSURED_POLICY_AGGREGATE_SUCCESS"
      },
      PROPERTY: {
        GET: "GET_CLIENT_INSURED_POLICY_PROPERTY_POLICY",
        GET_SUCCESS: "GET_CLIENT_INSURED_POLICY_PROPERTY_SUCCESS",
        GET_PROPERTIES: "GET_CLIENT_INSURED_POLICY_PROPERTIES",
        GET_PROPERTIES_SUCCESS: "GET_CLIENT_INSURED_POLICY_PROPERTIES_SUCCESS",
        POST: "POST_CLIENT_INSURED_POLICY_PROPERTY",
        POST_SUCCESS: "POST_CLIENT_INSURED_POLICY_PROPERTY_SUCCESS",
        SET: "SET_CLIENT_INSURED_POLICY_PROPERTY",
        SET_SUCCESS: "SET_CLIENT_INSURED_POLICY_PROPERTY_SUCCESS",
        DEL: "DEL_CLIENT_INSURED_POLICY_PROPERTY",
        DEL_SUCCESS: "DEL_CLIENT_INSURED_POLICY_PROPERTY_SUCCESS"
      },
      CODE: {
        GET: "GET_CLIENT_INSURED_POLICY_CODE",
        GET_SUCCESS: "GET_CLIENT_INSURED_POLICY_CODE_SUCCESS",
        POST: "POST_CLIENT_INSURED_POLICY_CODE",
        POST_SUCCESS: "POST_CLIENT_INSURED_POLICY_CODE_SUCCESS",
        DEL: "DEL_CLIENT_INSURED_POLICY_CODE",
        DEL_SUCCESS: "DEL_CLIENT_INSURED_POLICY_CODE_SUCCESS"
      },
      VEHICLE: {
        GET: "GET_CLIENT_INSURED_POLICY_VEHICLE",
        GET_SUCCESS: "GET_CLIENT_INSURED_POLICY_VEHICLE_SUCCESS",
        GET_VEHICLES: "GET_CLIENT_INSURED_POLICY_VEHICLES",
        GET_VEHICLES_SUCCESS: "GET_CLIENT_INSURED_POLICY_VEHICLES_SUCCESS",
        POST: "POST_CLIENT_INSURED_POLICY_VEHICLE",
        POST_SUCCESS: "POST_CLIENT_INSURED_POLICY_VEHICLE_SUCCESS",
        SET: "SET_CLIENT_INSURED_POLICY_VEHICLE",
        SET_SUCCESS: "SET_CLIENT_INSURED_POLICY_VEHICLE_SUCCESS",
        DEL: "DEL_CLIENT_INSURED_POLICY_VEHICLE",
        DEL_SUCCESS: "DEL_CLIENT_INSURED_POLICY_VEHICLE_SUCCESS"
      },
      FINANCIAL: setActions("CLIENT_POLICY_FINANCIAL")
    },
    LOCATION: {
      GET_ALL: "GET_CLIENT_INSURED_LOCATIONS",
      GET_ALL_SUCCESS: "GET_CLIENT_INSURED_LOCATIONS_SUCCESS",
      GET: "GET_CLIENT_INSURED_LOCATION",
      GET_SUCCESS: "GET_CLIENT_INSURED_LOCATION_SUCCESS",
      POST: "POST_CLIENT_INSURED_LOCATION",
      POST_SUCCESS: "POST_CLIENT_INSURED_LOCATION_SUCCESS",
      SET: "SET_CLIENT_INSURED_LOCATION",
      SET_SUCCESS: "SET_CLIENT_INSURED_LOCATION_SUCCESS",
      CLEAR: "CLEAR_INSURED_LOCATION_ID"
    }
  }
};
