import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Paper, Typography, Grid, Button } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { toast } from "react-toastify";
import { useContext, actionTypes, getFolders } from "../../context";
import { FileInput, FileDropzone } from "../../../../common/documents";
import { validateFile } from "../../../../common/documents/hooks";
// import { getUserAccess } from "../../../../../common";
import { useDocumentsStyles } from "../index.styles";
import Row from "./Row";

export default () => {
  const classes = useDocumentsStyles();
  const [
    { documents, ...store },
    dispatch,
    handleChange,
    {
      step3: { validationMessages },
      handleValidation
    }
  ] = useContext();
  const isDocumentActive = useSelector(
    ({
      admin: {
        claimSettings: {
          documents: { active }
        }
      }
    }) => active,
    shallowEqual
  );
  // const secProfs = useSelector(({ profile: { securityProfilesDetails } }) => securityProfilesDetails, shallowEqual);
  // const showDocuments = getUserAccess(secProfs, "CLAIM.DOCUMENTS", insured?.clientId, incident.stateId, coverageTypes)?.create;
  const folders = getFolders(store);
  const [state, setState] = useState({ hover: false, errorDrag: false });
  const onUploadChange = ({ target: { files } }) => dispatch({ type: actionTypes.SET_DOCUMENTS_UPLOAD, payload: files });
  const onClick = event => {
    event.preventDefault();
    document.querySelector("#document-upload-file").click();
  };
  const onDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
    setState({ ...state, hover: true, errorDrag: [...event.dataTransfer.items].some(i => !validateFile(i)) });
  };
  const onDragLeave = () => setState({ hover: false, errorDrag: false });
  const onDrop = event => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;
    onDragLeave();
    if ([...files].map(f => ({ type: f.type, kind: "file" })).some(i => !validateFile(i))) return toast.error("Invalid files");
    return onUploadChange({ target: { files } });
  };
  const actions = {
    onChange: e => handleChange(e, actionTypes.SET_DOCUMENTS),
    onDelete: id => () => dispatch({ type: actionTypes.DEL_DOCUMENT, id }),
    handleValidation
  };
  return (
    folders.length > 0 &&
    isDocumentActive && (
      <Paper className={classes.root} {...{ onDragOver, onDragLeave, onDrop }}>
        <Grid container spacing={8}>
          <Grid item xs={9}>
            <Typography className="tc-title" gutterBottom>
              Documents
            </Typography>
            <table className={classes.table}>
              <tbody>
                {documents.map((doc, index) => (
                  <Row key={`claim-add-doc-${doc.id}`} {...{ doc, index, actions, folders, validationMessages }} />
                ))}
              </tbody>
            </table>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.upload}>
              <FileDropzone {...state} />
              <div style={{ zIndex: 2 }}>
                <CloudUpload />
                <div>Please upload your file</div>
                <FileInput value="" onChange={onUploadChange} />
                <Button variant="contained" color="primary" onClick={onClick}>
                  Select Files
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    )
  );
};
