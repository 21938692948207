import React, { Fragment } from "react";
import { useSelector, shallowEqual } from "react-redux";
import ClaimantBox from "./Claimant";
import ClaimBox from "./Claim";
import DamagedVehicleBox from "./DamagedVehicle";
import WitnessInfo from "./WitnessInfo";
import InsuredBox from "./Insured";
import BodilyInjuryBox from "./BodilyInjury";
import DamagedPropertyBox from "./DamagedProperty";
import InsuredVehicleBox from "./InsuredVehicle";
import WcClaimBox from "./WcClaim";
import EmploymentBox from "./Employment";
import useClaimBoxes from "../../../common/customHooks/useClaimBoxes";
import ExpandAll from "../../../common/ExpandAll";

const Info = () => {
  const claim = useSelector(
    ({
      claim: {
        claim: { claimLossTypeGroupId, claimLineOfBusiness }
      }
    }) => ({ claimLossTypeGroupId, claimLineOfBusiness }),
    shallowEqual
  );
  const claimLossTypeGroups = useSelector(
    ({
      common: {
        claimLossTypeGroups: { data }
      }
    }) => data,
    shallowEqual
  );
  const { showBoxes, expandProps } = useClaimBoxes({
    ...claim,
    claimLossTypeGroups,
    components: {
      ClaimantBox,
      EmploymentBox,
      ClaimBox,
      WcClaimBox,
      DamagedVehicleBox,
      WitnessBox: WitnessInfo,
      InsuredBox,
      BodilyInjuryBox,
      DamagedPropertyBox,
      InsuredVehicleBox
    }
  });
  return (
    <Fragment key="claim-info-page">
      <ExpandAll elementId="claimTopContainer" className="tc-ml1" {...expandProps} />
      {showBoxes.map(({ show, component, name }, index) => show && component(name + index))}
    </Fragment>
  );
};

export default Info;
