import { Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fileProps } from "../../../api/apiUtils";
import CustomModal from "../CustomModal";
import { FileNameIcon, parseFiletype } from "../documents/FileIcon";
import Viewer from "./Viewer";

export default ({ modal = true, dispatchAction, action, headerActions }) => {
  const [{ blob, open, fileSelected, newBlob }, setState] = useState({ open: false });
  const dispose = clear => {
    const url = blob?.url;
    if (clear) setState(s => ({ ...s, blob: undefined, fileSelected: undefined, open: false, newBlob: undefined }));
    if (url) URL.revokeObjectURL(url);
  };
  useEffect(() => dispose, [blob]);
  const onToggle = () => dispose(true);
  const dispatch = useDispatch();
  const onViewFile = useCallback(
    (id, params = {}) => {
      const f = { ...params, data: [{ id, file: true }] };
      const data = {
        ...f,
        onDownloadSuccess: d => {
          dispose();
          const fProps = fileProps(d);
          setState(s => ({ ...s, blob: { ...fProps, ...parseFiletype(fProps.type) }, fileSelected: fProps, open: true }));
        }
      };
      if (typeof dispatchAction === "function") return dispatchAction(data);
      if (typeof action === "function") return dispatch(action(data));
      return false;
    },
    [dispatchAction, action, blob]
  );
  const setNewBlob = newFileUploaded => {
    dispose();
    if (newFileUploaded) {
      setState(s => ({ ...s, newBlob: { ...newFileUploaded, ...parseFiletype(newFileUploaded.type) }, fileSelected: newFileUploaded, open: true }));
    }
  };
  const openModal = Boolean(open && fileSelected);
  const filename = fileSelected?.filename || "";
  const title = (
    <Typography className="tc-title" component="div">
      <FileNameIcon type={(blob || {}).type} name={filename.substr(0, filename.lastIndexOf("."))} />
    </Typography>
  );
  const fileViewer = modal ? (
    <CustomModal cancelBtnText="Close" cssDialog="tc-modal-viewer" {...{ openModal, title, onToggle, maxWidth: "md" }}>
      <Viewer blob={newBlob ?? blob} actions={headerActions} />
    </CustomModal>
  ) : (
    <Viewer blob={newBlob ?? blob} actions={headerActions} />
  );
  return { onViewFile, fileViewer, blob, setNewBlob, newBlob };
};
