import { CLAIM } from "../actions/actionTypes";
import initialState from "./initialState/claim";

export default function claimReducer(state = initialState, action) {
  switch (action.type) {
    case CLAIM.RESET:
      return initialState;
    case CLAIM.GETALL_SUCCESS:
      return { ...state, claims: action.claims };
    case CLAIM.OVERVIEW.GET_SUCCESS: {
      const { overview } = action;
      const {
        claim,
        wcClaim,
        claimant,
        insured,
        diaries,
        policy,
        coverageDocuments,
        claimantAttorneys,
        defendantAttorneys,
        hearings,
        trial,
        reserves,
        isActivityWidgetActive
      } = overview;
      return {
        ...state,
        claim,
        wcClaim,
        claimant,
        insured,
        diaries,
        coverageDocuments,
        overviewPolicyBox: { ...initialState.overviewPolicyBox },
        policy: policy != null ? policy : { ...initialState.policy },
        claimantAttorneys,
        defendantAttorneys,
        hearings,
        trial,
        reserves,
        isActivityWidgetActive
      };
    }
    case CLAIM.GET_SUCCESS:
    case CLAIM.SET_SUCCESS:
    case CLAIM.USER.SET_SUCCESS:
      return { ...state, claim: action.claim };
    case CLAIM.INFO.CLAIMANT.GET_SUCCESS:
    case CLAIM.INFO.CLAIMANT.SET_SUCCESS:
      return { ...state, claimant: action.claimant };
    case CLAIM.WCCLAIM.GET_SUCCESS:
    case CLAIM.WCCLAIM.SET_SUCCESS:
      return { ...state, wcClaim: action.wcClaim, claim: action.wcClaim };
    case CLAIM.INFO.EMPLOYMENT.GET_SUCCESS:
    case CLAIM.INFO.EMPLOYMENT.SET_SUCCESS:
      return { ...state, wcClaim: { ...state.wcClaim, employment: action.employment } };
    case CLAIM.INFO.INSURED.GET_SUCCESS:
    case CLAIM.INFO.INSURED.SET_SUCCESS:
      return { ...state, insured: action.insured };
    case CLAIM.INFO.BODILYINJURY.GET_SUCCESS:
    case CLAIM.INFO.BODILYINJURY.SET_SUCCESS:
      return { ...state, bodilyInjury: action.bodilyInjury || initialState.bodilyInjury };
    case CLAIM.INFO.PROPERTY.GET_SUCCESS:
      return { ...state, propertyDamaged: action.property || initialState.property };
    case CLAIM.INFO.PROPERTY.SET_SUCCESS: {
      const propertyDamaged = action.property || initialState.property;
      return {
        ...state,
        propertyDamaged,
        policy: state.claim?.claimLineOfBusinessId === 3 ? { ...state.policy, containsDamagedProperty: true } : state.policy
      };
    }
    case CLAIM.INFO.INSVEHICLE.GET_SUCCESS:
    case CLAIM.INFO.INSVEHICLE.SET_SUCCESS:
      return { ...state, insVehicle: action.insVehicle || initialState.insVehicle };
    case CLAIM.COVERAGE.GET_SUCCESS:
      return { ...state, policy: action.policy };
    case CLAIM.DOCUMENTS.GET_SUCCESS:
    case CLAIM.DOCUMENTS.DEL_FILE_SUCCESS:
    case CLAIM.DOCUMENTS.SET_FILE_SUCCESS:
    case CLAIM.DOCUMENTS.POST_FOLDER_SUCCESS:
    case CLAIM.DOCUMENTS.SET_FOLDER_SUCCESS:
    case CLAIM.DOCUMENTS.DEL_FOLDER_SUCCESS:
    case CLAIM.DOCUMENTS.POST_FILES_SUCCESS:
    case CLAIM.DOCUMENTS.APPROVE_SUCCESS:
    case CLAIM.DOCUMENTS.REINDEX_SUCCESS:
    case CLAIM.DOCUMENTS.MOVE_FILE_SUCCESS:
      return { ...state, documents: action.documents };
    case CLAIM.INFO.DMVEHICLE.GET_SUCCESS:
    case CLAIM.INFO.DMVEHICLE.SET_SUCCESS:
      return { ...state, damagedVehicle: action.damagedVehicle || initialState.damagedVehicle };
    case CLAIM.FINANCIAL.LIST_INFO.GETALL_SUCCESS:
    case CLAIM.FINANCIAL.BILL.DEL_SUCCESS:
    case CLAIM.FINANCIAL.SCHEDULEPAY.DEL_SUCCESS:
    case CLAIM.FINANCIAL.SENDTOPAY.SET_SUCCESS:
    case CLAIM.FINANCIAL.BILLDUPLICATE.SET_SUCCESS:
    case CLAIM.FINANCIAL.VOIDED.SET_SUCCESS:
    case CLAIM.FINANCIAL.BILLREINDEX.SET_SUCCESS:
      return { ...state, financial: { ...state.financial, financialList: action.data } };
    case CLAIM.FINANCIAL.RECOVERY.GETALL_SUCCESS:
    case CLAIM.FINANCIAL.RECOVERY.POST_SUCCESS:
    case CLAIM.FINANCIAL.RECOVERY.DEL_SUCCESS:
    case CLAIM.FINANCIAL.RECOVERY.SET_SUCCESS:
      return { ...state, financial: { ...state.financial, recoveryList: action.data } };
    case CLAIM.FINANCIAL.BILL.GET_SUCCESS:
    case CLAIM.FINANCIAL.BILL.POST_SUCCESS:
    case CLAIM.FINANCIAL.BILL.SET_SUCCESS:
      return { ...state, bill: action.bill };
    case CLAIM.RESERVES.GET_SUCCESS:
    case CLAIM.RESERVES.SET_SUCCESS:
      return { ...state, reserves: action.reserves || state.reserves };
    case CLAIM.LEGAL.TRIAL.GET_SUCCESS:
    case CLAIM.LEGAL.TRIAL.SET_SUCCESS:
    case CLAIM.LEGAL.TRIAL.DEL_SUCCESS:
      return { ...state, trial: action.trial ? action.trial : initialState.trial };
    case CLAIM.LEGAL.MEDITATION_ARBITRATION.GET_SUCCESS:
    case CLAIM.LEGAL.MEDITATION_ARBITRATION.SET_SUCCESS:
    case CLAIM.LEGAL.MEDITATION_ARBITRATION.POST_SUCCESS:
      return { ...state, mediationArbitration: action.mediationArbitration || state.mediationArbitration };
    case CLAIM.LEGAL.CLAIMANTATTORNEY.GET_SUCCESS:
    case CLAIM.LEGAL.CLAIMANTATTORNEY.SET_SUCCESS:
    case CLAIM.LEGAL.CLAIMANTATTORNEY.POST_SUCCESS:
    case CLAIM.LEGAL.CLAIMANTATTORNEY.DEL_SUCCESS:
      return { ...state, claimantAttorneys: action.claimantAttorneys || state.claimantAttorneys };
    case CLAIM.LEGAL.DEFENDANTATTORNEY.GET_SUCCESS:
    case CLAIM.LEGAL.DEFENDANTATTORNEY.SET_SUCCESS:
    case CLAIM.LEGAL.DEFENDANTATTORNEY.POST_SUCCESS:
    case CLAIM.LEGAL.DEFENDANTATTORNEY.DEL_SUCCESS:
      return { ...state, defendantAttorneys: action.defendantAttorneys || state.defendantAttorneys };
    case CLAIM.LEGAL.HEARING.GET_SUCCESS:
    case CLAIM.LEGAL.HEARING.SET_SUCCESS:
    case CLAIM.LEGAL.HEARING.DEL_SUCCESS:
    case CLAIM.LEGAL.HEARING.POST_SUCCESS:
      return { ...state, hearings: action.hearings || state.hearings };
    case CLAIM.LEGAL.SETTLED.GET_SUCCESS:
    case CLAIM.LEGAL.SETTLED.SET_SUCCESS:
      return { ...state, settled: action.settled || state.settled };
    case CLAIM.LEGAL.ATTORNEYBYFIRM.GET_SUCCESS:
      return { ...state, attorneyByFirm: action.attorneyByFirm || state.attorneyByFirm };
    case CLAIM.DIARY.GET_SUCCESS:
    case CLAIM.DIARY.POST_SUCCESS:
    case CLAIM.DIARY.SET_SUCCESS:
    case CLAIM.DIARY.DEL_SUCCESS:
      return { ...state, diaries: action.diaries };
    case CLAIM.NOTES.GET_SUCCESS:
    case CLAIM.NOTES.POST_SUCCESS:
    case CLAIM.NOTES.SET_SUCCESS:
    case CLAIM.NOTES.DEL_SUCCESS:
      return { ...state, notes: action.notes };
    case CLAIM.NOTES.GET_DRAFT_SUCCESS:
    case CLAIM.NOTES.POST_DRAFT_SUCCESS:
      return { ...state, draftNote: action.draft };
    case CLAIM.COMMUNICATION.GET_SUCCESS:
    case CLAIM.COMMUNICATION.POST_SUCCESS:
    case CLAIM.COMMUNICATION.SET_SUCCESS:
      return { ...state, communication: { ...state.communication, communications: action.communications } };
    case CLAIM.COMMUNICATION.CLAIMEMAILS.GETALL_SUCCESS:
      return { ...state, communication: { ...state.communication, claimEmails: action.data } };
    case CLAIM.ACTIVITY.GET_SUCCESS:
      return { ...state, activity: action.activity };
    case CLAIM.INFO.WITNESS.GET_SUCCESS:
    case CLAIM.INFO.WITNESS.GETALL_SUCCESS:
    case CLAIM.INFO.WITNESS.SET_SUCCESS:
    case CLAIM.INFO.WITNESS.POST_SUCCESS:
    case CLAIM.INFO.WITNESS.DEL_SUCCESS:
      return { ...state, witnesses: action.data };
    case CLAIM.DIARY.TEMPLATES.GET_SUCCESS:
      return { ...state, diaryTemplates: action.templates };
    case CLAIM.COMMUNICATION.TEMPLATES.GET_SUCCESS:
      return { ...state, communicationTemplates: action.templates };
    case CLAIM.GET_SECPROF_FILTER_SUCCESS:
      return { ...state, filterfields: action.filterfields };
    case CLAIM.COVERAGE.POLICIES_BY_LOB_INSURED.GETALL_SUCCESS:
      return { ...state, coveragePoliciesByLob: action.data };
    case CLAIM.COVERAGE.POLICIES_BY_LOB_INSURED.SET_SUCCESS:
      return { ...state, policy: action.data };
    default:
      return state;
  }
}
