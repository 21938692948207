import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Paper, Typography, makeStyles, Collapse, Grid, Divider } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { childrenPropType } from "../../common";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 0),
    margin: theme.spacing(0, 0, 0, 0)
  },
  title: {
    color: "#898F91",
    marginBottom: 5,
    cursor: "pointer"
  },
  expand: {
    transform: "rotate(180deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    position: "relative",
    top: 6,
    color: "#428302"
  },
  expanded: { transform: "rotate(0deg)", color: "#428302" },
  editIcon: {
    textAlign: "end"
  },
  divider: {
    margin: theme.spacing(1)
  }
}));

const HeaderCollapsibleBox = ({ header, children, title, open, name, onToggle, edit: { setEdit, handleToggle } }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={setEdit ? 11 : 12} onClick={() => onToggle(name)}>
          <Typography variant="subtitle2" className={classes.title}>
            <KeyboardArrowDown className={clsx(classes.expand, { [classes.expanded]: open })} />
            {title}
          </Typography>
          {header}
        </Grid>
        {setEdit && open && (
          <Grid item xs={1}>
            <Typography variant="elevation" className={classes.editIcon} onClick={() => handleToggle()}>
              <EditIcon />
            </Typography>
          </Grid>
        )}
      </Grid>
      <Collapse in={open} timeout="auto">
        {children}
      </Collapse>
      <Divider className={classes.divider} />
    </Paper>
  );
};

HeaderCollapsibleBox.propTypes = {
  title: PropTypes.string.isRequired,
  header: childrenPropType().isRequired,
  children: childrenPropType().isRequired,
  open: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  edit: PropTypes.shape({
    setEdit: PropTypes.bool,
    handleToggle: PropTypes.func
  })
};

HeaderCollapsibleBox.defaultProps = {
  edit: {
    setEdit: false,
    handleToggle: null
  }
};

export default HeaderCollapsibleBox;
