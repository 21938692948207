import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Badge, Grid, IconButton, Popover, withStyles } from "@material-ui/core";
import { Notifications } from "@material-ui/icons";
import useNotificationStyles from "./index.styles";
import Notification from "./List";

const NotifBadge = withStyles({ badge: { backgroundColor: "#e74c3c" } })(Badge);

const NotificationMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useNotificationStyles();
  const onClose = () => setAnchorEl(null);
  const handleMenu = event => setAnchorEl(event.currentTarget);
  const open = Boolean(anchorEl);
  const id = open ? "menu-appbar" : undefined;
  const notifications = useSelector(store => store.notification.notifications, shallowEqual);
  const notificationsCount = notifications.filter(x => !x.read).length;
  return (
    <>
      <IconButton onClick={handleMenu} color="inherit" className="tc-mr1">
        <NotifBadge badgeContent={notificationsCount}>
          <Notifications />
        </NotifBadge>
      </IconButton>
      <Popover
        {...{ id, open, anchorEl, onClose }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Grid container direction="column" justify="center" className={classes.root}>
          <Grid item>
            <Notification {...{ onClose }} />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default NotificationMenu;
