import { withStyles } from "@material-ui/core/styles";
import React from "react";

const formcontrolProps = ({ ...other }, css = "root") => ({ className: (other.classes || {})[css], ...other });

export const FormControlStyle = theme => ({ root: { marginBottom: theme.spacing(2) } });

export const BaseFormControlStyleRight = { root: { "& input": { textAlign: "right" } } };

export const FormControlStyleRight = theme => ({ root: { marginBottom: theme.spacing(2), ...BaseFormControlStyleRight.root } });

export const HocInput = (Comp, styles = FormControlStyle) => withStyles(styles)(props => <Comp {...formcontrolProps(props)} />);
