import claim from "./claim";
import client from "./client";
import admin from "./admin";
import common from "./common";
import initialReport from "./initialReport";

export default {
  claim,
  client,
  common,
  admin,
  initialReport,
  catastrophe: {
    catastrophe: {
      location: {},
      associatedList: []
    },
    catastrophes: [],
    catastropheDuplicates: [],
    catastropheReserves: {},
    notes: [],
    draftNote: {},
    filterFields: {}
  },
  vendor: {
    profile: {
      basicInformation: { location: {}, mailingLocation: {} },
      industry: []
    },
    claimsInvolved: {},
    contacts: [],
    vendors: [],
    pending: [],
    notes: [],
    draftNote: {},
    financialInfo: { barChart: [], total: {} },
    files: [],
    accounts: [],
    locations: [],
    payments: [],
    location: {
      basicInformation: { location: {}, mailingLocation: {} }
    }
  },
  claimants: [],
  communications: {
    communications: []
  },
  occurrence: {
    occurrence: { associatedList: [] },
    occurrences: [],
    notes: [],
    draftNote: {},
    diaries: [],
    reserves: {},
    diaryTemplates: {
      templates: []
    },
    filterFields: {}
  },
  profile: {
    securityProfiles: [],
    securityProfilesDetails: []
  },
  apiCallsInProgress: 0,
  automation: {
    diaries: []
  },
  dashboard: {
    diaries: [],
    claimWithoutActivity: [],
    expiredDiaries: [],
    openClaimsByAdjuster: [],
    claimsLossesByAdjuster: [],
    claimsLossesByAdjusterDetail: [],
    duplicatePayments: [],
    duplicatePaymentsChart: []
  },
  documents: {
    indexing: [],
    reindexing: [],
    review: []
  },
  financial: {
    processingQueue: [],
    scheduled: [],
    history: [],
    settlements: []
  },
  notification: { notifications: [] }
};
