import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Paper, Grid, Collapse, Typography } from "@material-ui/core";
import { useContext, actionTypes } from "../context";
import { CheckboxLabel, SelectInput, RadioLabel } from "../../../common/inputs";
import { useLineOfBusinessStyles } from "./index.styles";
import Count from "../../../common/Count";
import { CoverageIcon } from "../layout";
import { filterByParentId } from "../../../../common";

export default () => {
  const classes = useLineOfBusinessStyles();
  const [
    { multipleClaimants, lobLoaded, coverageTypes },
    ,
    handleChange,
    {
      step1: { validationMessages },
      handleValidation
    }
  ] = useContext();
  const { claimLossTypes, claimCauseCodes } = useSelector(({ common }) => common, shallowEqual);
  const [, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(ld => {
      if (ld) handleValidation();
      return !!lobLoaded;
    });
  }, [lobLoaded, coverageTypes]);
  const onChange = e => handleChange(e, actionTypes.SET_LINE_OF_BUSINESS);
  const countClaims = coverageTypes.reduce((a, b) => a + b.lossTypeGroups.reduce((c, d) => c + (d.checked ? 1 : 0), 0), 0);
  return (
    <Paper className={classes.root}>
      <Grid container spacing={4} wrap="nowrap">
        <Grid item xs>
          <Typography className="tc-title" gutterBottom>
            Line Of Business / Coverage
          </Typography>
        </Grid>
        {!multipleClaimants && (
          <Grid item>
            Number of Claims to be created
            <Count>{countClaims}</Count>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={8}>
        {coverageTypes.map(({ id: lobId, text, description, lossTypeGroups, ...lob }, lobIx) => (
          <Grid key={lobId} item xs>
            <div className={classes.coverageTypeGroup}>
              {(multipleClaimants && `${description} (${text})`) || (
                <RadioLabel
                  name={`lob-${lobId}`}
                  label={`${description} (${text})`}
                  checked={lob.checked}
                  {...{ onChange }}
                  className={classes.chk}
                />
              )}
            </div>
            {lossTypeGroups.map(({ id: ltgId, text: label, checked, lossTypeId, causeCodeId }, ltgIx) => {
              const lossTypes = filterByParentId(claimLossTypes, ltgId);
              const causeCodes = filterByParentId(claimCauseCodes, ltgId);
              const inputProps = (path, val, lbl) => ({
                name: `${path}-${lobId}-${ltgId}`,
                label: lbl || label,
                onChange,
                [path === "checked" ? "checked" : "value"]: val,
                disabled: multipleClaimants ? lobId === 3 : !lob.checked,
                error: (validationMessages || {})[`coverageTypes[${lobIx}].lossTypeGroups[${ltgIx}].${path}`]
              });
              return (
                <div key={ltgId}>
                  <Grid container alignItems="center">
                    <Grid item style={{ width: 186 }}>
                      {!multipleClaimants && <CheckboxLabel {...inputProps("checked", checked)} className={classes.chk} />}
                      {multipleClaimants && <RadioLabel {...inputProps("checked", checked)} className={classes.chk} />}
                    </Grid>
                    <Grid item>
                      <CoverageIcon ltg={ltgId} lob={lobId} />
                    </Grid>
                  </Grid>
                  <Collapse in={checked} style={{ marginLeft: 15 }}>
                    <div className={classes.selects}>
                      <SelectInput {...inputProps("lossTypeId", lossTypeId, "Loss Type *")} options={lossTypes} />
                      <SelectInput {...inputProps("causeCodeId", causeCodeId, "Cause Code *")} options={causeCodes} />
                    </div>
                  </Collapse>
                </div>
              );
            })}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};
