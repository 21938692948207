import React, { useEffect, useState } from "react";
import { Divider, Paper, Grid, Collapse, Typography } from "@material-ui/core";
import { useContext, actionTypes } from "../context";
import { useInsuredPropertyStyles } from "./index.styles";
import { InputText, SelectInput, PhoneInput } from "../../../common/inputs";

export default () => {
  const classes = useInsuredPropertyStyles();
  const [
    {
      insProperty,
      insProperties: { data }
    },
    ,
    handleChange,
    {
      step1: { validationMessages },
      handleValidation
    }
  ] = useContext();
  const { id, propertyType, location, mortgageCompany } = insProperty;
  const [, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(loaded => {
      if (loaded) handleValidation();
      return true;
    });
  }, [id]);
  const { city, address2, state, zipCode } = location || {};
  const { name, phone, email, mainContactName, location: mlocation } = mortgageCompany || {};
  const { address1: maddress1, address2: maddress2, city: mcity, state: mstate, zipCode: mzipCode } = mlocation || {};
  const options = data.map(d => ({ id: d.id, text: (d.location || {}).address1 || "-" }));
  const onChange = e => handleChange(e, actionTypes.SET_INS_PROPERTY);
  return (
    <Paper className={classes.root}>
      <Typography className="tc-title" gutterBottom>
        Insured Property
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <InputText label="Property Type" value={propertyType} disabled />
          <SelectInput name="address1" label="Address 1 *" value={id} {...{ options, onChange }} error={validationMessages["insProperty.id"]} />
        </Grid>
        <Grid item xs={4}>
          <InputText label="City" value={city} disabled />
          <InputText label="Address 2" value={address2} disabled />
        </Grid>
        <Grid item xs={4}>
          <InputText label="State" value={state} disabled />
          <InputText label="Zip" value={zipCode} disabled />
        </Grid>
      </Grid>
      <Collapse in={Boolean(mortgageCompany)}>
        <Divider className={classes.marginDivider} />
        <Grid container spacing={4}>
          <Grid item xs>
            <InputText label="Mortgage Company Name" value={name} disabled />
            <InputText label="Address 1" value={maddress1} disabled />
            <InputText label="Address 2" value={maddress2} disabled />
          </Grid>
          <Grid item xs>
            <InputText label="City" value={mcity} disabled />
            <InputText label="State" value={mstate} disabled />
            <InputText label="Zip" value={mzipCode} disabled />
          </Grid>
          <Grid item xs>
            <PhoneInput label="Mortgage Company Phone Number" value={phone} disabled />
            <InputText label="Mortgage Company Email" value={email} disabled />
            <InputText label="Mortgage Company Main Contact" value={mainContactName} disabled />
          </Grid>
        </Grid>
      </Collapse>
    </Paper>
  );
};
