import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Grid, Box } from "@material-ui/core";
import { LockOpen, LockOutlined } from "@material-ui/icons";
import VendorSvg from "../../../assets/vendor_left_menu.svg";
import Style from "./index.styles";

const VendorInformation = ({ basicInformation, ...rest }) => {
  const { fullName = "", isActive, id = 0 } = basicInformation || {};

  const classes = Style();
  const status = (icon, css) => <span className={clsx(classes.status, css)}>{icon}</span>;
  return (
    <div {...rest}>
      <Grid container alignItems="center">
        <Grid item className={classes.icon}>
          <VendorSvg />
        </Grid>
        <Grid item>Vendor</Grid>
      </Grid>
      <div className={classes.number}>{`VD ${id}`}</div>
      <div className={classes.name}>
        <div className={classes.vendorNameEllipsis}>
          <Box component="div" my={2} textOverflow="ellipsis" overflow="hidden">
            {fullName}
          </Box>
        </div>
        {isActive && status(<LockOpen />, classes.open)}
        {isActive === false && status(<LockOutlined />, classes.close)}
      </div>
    </div>
  );
};

VendorInformation.propTypes = {
  basicInformation: PropTypes.shape({}).isRequired
};

export default VendorInformation;
