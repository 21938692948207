import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { LockOpen, LockOutlined, Replay, NotInterested, HourglassEmpty } from "@material-ui/icons";
import Claim from "../../assets/claim.svg";

const styles = makeStyles(() => ({
  status: { borderRadius: "50%", padding: 5, width: 24, height: 24, "& svg": { fontSize: 14 } },
  claim: { backgroundColor: "#A3B3B9", "& svg": { color: "#003145" } },
  open: { backgroundColor: "#D2EEB6", "& svg": { color: "#428302" } },
  close: { backgroundColor: "#FCB7B0", "& svg": { color: "#E74C3C" } },
  reopen: { backgroundColor: "#7C9DAA", "& svg": { color: "#004763", transform: "scaleX(-1)" } },
  voided: { backgroundColor: "#F6C096", "& svg": { color: "#FE7F1E" } },
  pending: { backgroundColor: "#FCF3BC", "& svg": { color: "#EFD10F" } }
}));

const ClaimStatus = ({ status, divMode, propStyles }) => {
  const classes = styles();
  const iconStatus = (css, icon) => {
    if (divMode) {
      return (
        <div className={clsx(classes.status, css)} style={{ ...propStyles }}>
          {icon}
        </div>
      );
    }
    return (
      <span className={clsx(classes.status, css)} style={{ marginRight: 7 }}>
        {icon}
      </span>
    );
  };
  switch (status) {
    case "Claim":
      return iconStatus(classes.claim, <Claim />);
    case "Open":
      return iconStatus(classes.open, <LockOpen />);
    case "Closed":
      return iconStatus(classes.close, <LockOutlined />);
    case "Reopened":
      return iconStatus(classes.reopen, <Replay />);
    case "Voided":
      return iconStatus(classes.voided, <NotInterested />);
    case "Pending":
      return iconStatus(classes.pending, <HourglassEmpty />);
    default:
      return null;
  }
};

ClaimStatus.propTypes = {
  status: PropTypes.string.isRequired,
  divMode: PropTypes.bool,
  propStyles: PropTypes.shape({})
};

ClaimStatus.defaultProps = {
  divMode: true,
  propStyles: {}
};

export default ClaimStatus;
