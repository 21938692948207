import React, { useContext, shallowEqual } from "react";
import { useSelector } from "react-redux";
import CatastropheNote from "../../components/common/notes";
import { useRouteParam } from "../../common";
import ActionsAllowed from "../../components/common/contexts/ActionsAllowed";

const Notes = () => {
  const entityNumber = useSelector(({ catastrophe: { catastrophe } }) => catastrophe.number, shallowEqual);
  const catastropheId = useRouteParam("slug");
  const routesAllowed = useContext(ActionsAllowed);
  const access = routesAllowed.find(elem => elem.route === "notes");
  const confidentialAccess = routesAllowed.find(elem => elem.route === "notes-confidential");
  const confAccess = {
    confidentialCreate: confidentialAccess.create,
    confidentialUpdate: confidentialAccess.update,
    confidentialDelete: confidentialAccess.delete
  };
  return <CatastropheNote catastropheId={catastropheId} entityNumber={entityNumber} actionsAllowed={{ ...access, ...confAccess }} />;
};

export default Notes;
