import { CLAIM, API } from "../../../actions/actionTypes";
import { getSuccess, setSuccess, delSuccess } from "../../../actions/claim/legal/trial";
import { takeLatest, takeLatestSet, takeLatestDel } from "../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLAIM.LEGAL.TRIAL.GET,
    url: `${API.URL}/trial`,
    params: ({ claimId }) => ({ claimId }),
    actionSuccess: getSuccess,
    errorMessage: "Error loading trial"
  }),
  takeLatestSet({
    type: CLAIM.LEGAL.TRIAL.SET,
    url: `${API.URL}/trial`,
    params: ({ trial }) => trial,
    actionSuccess: setSuccess,
    successMessage: "Information successfully saved",
    errorMessage: "Unable to save the information"
  }),
  takeLatestDel({
    type: CLAIM.LEGAL.TRIAL.DEL,
    url: `${API.URL}/trial`,
    params: ({ claimId }) => ({ claimId }),
    actionSuccess: delSuccess,
    successMessage: "Information successfully saved",
    errorMessage: "Unable to save the information"
  })
];
