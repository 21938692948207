import { Button, Chip, Grid, InputLabel } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { array } from "../../../common";
import { HocInput } from "./HocInput";
import { BaseTextInput } from "./TextInput";

export const BaseTagInput = memo(
  ({ disabled, error, label, name, value: val, onChange, onValidate, validationMessage, ...other }) => {
    const [state, setState] = useState({ value: "" });
    const onTextChange = ({ target: { value } }) => setState(ps => ({ ...ps, value, error: undefined }));
    const values = (typeof val === "string" ? val.split(",") : array(val)).reduce(
      (a, txt, ix) => (typeof txt === "string" ? [...a, { ix, txt }] : a),
      []
    );
    const onSubmit =
      typeof onChange === "function" &&
      (event => {
        event.preventDefault();
        if (disabled) return false;
        if (!state.value || (typeof onValidate === "function" && !onValidate(state.value)))
          return setState(ps => ({ ...ps, error: validationMessage || "Invalid Value" }));
        onChange({ target: { name, value: [...array(val), state.value] } });
        return setState({ value: "" });
      });
    const onDelete = txt =>
      (typeof onChange === "function" && (() => onChange({ target: { name, value: array(val).filter(v => v !== txt) } }))) || undefined;
    useEffect(() => {
      setState(ps => ({ ...ps, error }));
    }, [error]);
    return (
      <div {...other}>
        {(onSubmit && (
          <form {...{ onSubmit }}>
            <Grid container spacing={2} wrap="nowrap" alignItems="flex-end">
              <Grid item xs>
                <BaseTextInput autoComplete="off" name={`${name || "tag-input"}-text`} onChange={onTextChange} {...{ label, disabled, ...state }} />
              </Grid>
              {!disabled && (
                <Grid item style={state.error ? { alignSelf: "center" } : undefined}>
                  <Button type="submit" size="small" variant="contained" color="primary">
                    Add
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        )) ||
          (label && (
            <InputLabel shrink disabled className="tc-mb05">
              {label}
            </InputLabel>
          ))}
        {!!values.length && (
          <Grid item xs container spacing={1}>
            {values.map(({ ix, txt }) => (
              <Grid item key={ix}>
                <Chip label={txt} onDelete={onDelete(txt)} />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    );
  },
  (p, n) => !["value", "error", "disabled"].some(k => p[k] !== n[k])
);

BaseTagInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onValidate: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  validationMessage: PropTypes.string
};

BaseTagInput.defaultProps = ["disabled", "error", "name", "label", "onChange", "onValidate", "value", "validationMessage"].reduce(
  (a, k) => ({ ...a, [k]: undefined }),
  {}
);

export default HocInput(BaseTagInput);
