import * as yup from "yup";
import { isNumber } from "../../../common/yup";

const yupNoteSchema = yup.object().shape({
  title: yup
    .string()
    .trim("Title cannot include leading and trailing spaces")
    .required("Title Required"),
  note: yup
    .string()
    .trim("Description cannot include leading and trailing spaces")
    .required("Description Required"),
  typeId: yup
    .number()
    .nullable()
    .test("default", "Note type is Required", (val, ctx) => typeof ctx?.parent?.claimId !== "number" || isNumber(val))
});

export default yupNoteSchema;
