import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";

const Bar = ({ classes, color, percent, tooltip }) => {
  const [state, setState] = useState(0);
  useEffect(() => {
    setTimeout(() => setState(percent > 1 ? 1 : percent), 100);
  }, [percent]);
  const dProgress = (
    <div className={classes.progress}>
      <div style={{ width: `${100 * state}%`, backgroundColor: color }}>&nbsp;</div>
    </div>
  );
  return (
    (tooltip && (
      <Tooltip title={tooltip} arrow placement="top">
        {dProgress}
      </Tooltip>
    )) ||
    dProgress
  );
};

Bar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  color: PropTypes.string,
  percent: PropTypes.number.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string])
};

Bar.defaultProps = {
  color: "#707070",
  tooltip: null
};

export default Bar;
