import { CLAIM, API } from "../../../actions/actionTypes";
import {
  getDefendantAttorneySuccess,
  postDefendantAttorneySuccess,
  setDefendantAttorneySuccess,
  delDefendantAttorneySuccess
} from "../../../actions/claim/legal/attorney";
import { takeLatest } from "../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLAIM.LEGAL.DEFENDANTATTORNEY.GET,

    url: `${API.URL}/attorney/attorneysByInsured`,
    params: ({ claimId }) => ({ claimId }),
    actionSuccess: getDefendantAttorneySuccess,
    errorMessage: "Error loading claimant attorney"
  }),
  takeLatest({
    type: CLAIM.LEGAL.DEFENDANTATTORNEY.POST,
    method: "POST",
    url: `${API.URL}/attorney/insuredAttorney`,
    params: ({ defendantAttorney }) => defendantAttorney,
    actionSuccess: postDefendantAttorneySuccess,
    successMessage: "Defendant attorney successfully saved",
    errorMessage: "Unable to delete the information"
  }),
  // TODO: ver si se usa porque faltaria un PUT
  takeLatest({
    type: CLAIM.LEGAL.DEFENDANTATTORNEY.SET,
    method: "PUT",
    url: `${API.URL}/attorney/updateClaimAttorney`,
    params: ({ defendantAttorney }) => defendantAttorney,
    actionSuccess: setDefendantAttorneySuccess,
    successMessage: "Defendant attorney successfully updated",
    errorMessage: "Unable to delete the information"
  }),
  takeLatest({
    type: CLAIM.LEGAL.DEFENDANTATTORNEY.DEL,
    method: "DELETE",
    url: `${API.URL}/attorney/claimAttorney`,
    params: ({ defendantAttorney: { claimAttorneyId, claimId, insuredId } }) => ({ claimAttorneyId, claimId, insuredId }),
    actionSuccess: delDefendantAttorneySuccess,
    successMessage: "Defendant attorney successfully deleted",
    errorMessage: "Unable to delete the information"
  })
];
