import React, { useContext } from "react";
import OccurrenceDiary from "../../components/common/diary";
import { useRouteParam } from "../../common";
import ActionsAllowed from "../../components/common/contexts/ActionsAllowed";

const Diary = () => {
  const occurrenceId = useRouteParam("occurrenceId");

  const routesAllowed = useContext(ActionsAllowed);
  const access = routesAllowed.find(elem => elem.route === "diaries");

  return <OccurrenceDiary occurrenceId={occurrenceId} access={access} />;
};

export default Diary;
