import { Tab } from "@material-ui/core";
import clsx from "clsx";
import React, { lazy, useRef, Suspense } from "react";
import { Link, Switch, Route } from "react-router-dom";
import BreadCrumbsNavigation from "../../components/common/breadCrumbs/BreadCrumbsNavigation";
import ActionsAllowed from "../../components/common/contexts/ActionsAllowed";
import LoadingComp, { useLoadingContext } from "../../components/common/Loading";
import SimpleTabs from "../../components/common/tabs/SimpleTabs";

const PageNotAllowed = lazy(() => import("../../components/PageNotAllowed"));
const Diaries = lazy(() => import("../../components/automation/diaries"));
const Diary = lazy(() => import("../../components/automation/diaries/Form"));

const AutomationRoutes = () => {
  const { breadcrumb, tabs, allRoutes, routesAllowed, pathname, currentTab, Loading, padding } = useLoadingContext();
  const container = useRef(null);
  const getTitle = () => {
    const paths = pathname.split("/");
    const rootBc = { to: "/automation/diaries", title: "Diaries Workflows" };
    if (paths.slice(-1)[0] === "diary") return [rootBc, { title: "New Workflow" }];
    if (paths.slice(-2)[0] === "diary") return [rootBc, { title: "Edit Workflow" }];
    return tabs.reduce((acc, { name, title }) => (name === paths.slice(-1)[0] ? title : acc), "");
  };
  return (
    <div ref={container} className="tc-page-container">
      <SimpleTabs value={currentTab} title="Automation">
        {tabs.map(({ to, label }) => (
          <Tab key={to} label={label} component={Link} to={to} value={to} />
        ))}
      </SimpleTabs>
      {Loading}
      {!!allRoutes.length && (
        <div className={clsx("tc-page-content", { "tc-no-padding": !padding })}>
          {breadcrumb && <BreadCrumbsNavigation min gutterBottom activeTitle={getTitle()} breadData="AUTOMATION" />}
          <ActionsAllowed.Provider value={routesAllowed}>
            <Switch>
              <Suspense fallback={<LoadingComp absolute />}>
                {allRoutes.map(({ Comp, allowed, path, exact }) => (
                  <Route key={path} {...{ path, exact }} render={() => (allowed && <Comp {...{ container }} />) || <PageNotAllowed />} />
                ))}
              </Suspense>
            </Switch>
          </ActionsAllowed.Provider>
        </div>
      )}
    </div>
  );
};

export const routes = [
  [
    "diaries",
    "Diaries",
    "Diaries Workflows",
    [
      ["diaries", Diaries, "read"],
      ["diary", Diary, "create", true],
      ["diary/:autoDiaryId", Diary, "read"]
    ]
  ]
];

export default AutomationRoutes;
