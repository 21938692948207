import React, { createContext, useReducer, useEffect, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { childrenPropType } from "../../../common";

export const FormContext = createContext();
const initialState = {};
const reducer = (state, action) => {
  switch (action.type) {
    case "PUT_OBJ":
      return action.obj;
    case "PUT_FIELD":
      return { ...state, [action.payload.name]: action.payload.value };
    case "PUT_INITIAL_STATE":
      return initialState;
    default:
      return state;
  }
};
export const FormContextProvider = React.forwardRef(({ children, initialData }, ref) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useImperativeHandle(
    ref,
    () => {
      return {
        getState: () => state
      };
    },
    [state]
  );
  useEffect(() => {
    if (initialData?.id) {
      dispatch({ type: "PUT_OBJ", obj: initialData });
    } else {
      dispatch({ type: "PUT_INITIAL_STATE" });
    }
    return () => {
      dispatch({ type: "SET_INITIAL_STATE" });
    };
  }, [initialData]);

  const values = {
    state,
    dispatch
  };

  return <FormContext.Provider value={values}>{children}</FormContext.Provider>;
});

FormContextProvider.propTypes = {
  children: childrenPropType().isRequired,
  initialData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.arrayOf(PropTypes.number)
    ])
  ).isRequired
};

FormContextProvider.defaultProps = {};
