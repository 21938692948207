import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import { Divider, Grid, Collapse, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useContext, actionTypes } from "../context";
import { CheckboxLabel, DateTimeInput, PhoneInput, SelectInput, InputText, IntegerInput } from "../../../common/inputs";
import { useDamagedVehicleStyles } from "./index.styles";
// import { CoverageIcon } from "../layout";

const DamagedVehicle = ({ claimant, index }) => {
  const classes = useDamagedVehicleStyles();
  const [
    { damagedVehicle },
    ,
    handleChange,
    {
      step2: { validationMessages },
      handleValidation
    }
  ] = useContext();
  const { states, vehicleBodyType, vehicleMake, vehicleDamageType: damageType, codeType } = useSelector(({ common }) => common, shallowEqual);
  const [loaded, setLoaded] = useState(false);
  const state = (claimant || { damagedVehicle }).damagedVehicle;
  useEffect(() => {
    if (loaded) handleValidation();
    else setLoaded(true);
  }, [state]);
  const {
    isLienholderClaimant,
    hasLienholder,
    isDriverOwner,
    vehicle,
    driver,
    vehicleOwner,
    insuranceCarrier,
    estimateAmount,
    nAICCodeId,
    policyNumber,
    damageDescription,
    whereIsDamage,
    vehicleDamageTypeId,
    vehicleOwner: { location: voLocation },
    lienHolder,
    lienHolder: { location: lhLocation }
  } = state;
  const Container = claimant ? React.Fragment : Paper;
  const inputProps = path => ({
    name: `${claimant ? `claimant-1-` : ""}dv.${path}`,
    onChange: event => handleChange(event, actionTypes.SET_DAMAGED_VEHICLE),
    error: (validationMessages || {})[`${claimant ? `claimants[${index}].` : ""}damagedVehicle.${path}`]
  });
  return (
    <Container {...(claimant ? {} : { className: classes.root })}>
      <div className={clsx(classes.mb, claimant ? classes.mt : null)}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography className="tc-title">Damaged Vehicle</Typography>
          </Grid>
          {/* <Grid item>
            <CoverageIcon text="Vehicle Damage" label />
          </Grid> */}
        </Grid>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <CheckboxLabel label="Lienholder" checked={hasLienholder} {...inputProps("hasLienholder")} />
        </Grid>
        <Collapse in={hasLienholder} timeout="auto">
          <Grid item xs={4}>
            <CheckboxLabel label="Lienholder same as Claimant" checked={isLienholderClaimant} {...inputProps("isLienholderClaimant")} />
          </Grid>
        </Collapse>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <SelectInput
            {...inputProps("isDriverOwner")}
            label="Driver"
            value={isDriverOwner === true ? 1 : 2}
            options={[
              { id: 1, text: "Owner" },
              { id: 2, text: "Different than Owner" }
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <InputText {...inputProps("driver.licenseNumber")} label="Driver’s License Number" value={driver.licenseNumber} />
        </Grid>
      </Grid>
      <Divider className={classes.marginDivider} />
      <Collapse in={!isDriverOwner} timeout="auto">
        <Grid container spacing={4}>
          <Grid item xs>
            <InputText {...inputProps("driver.firstName")} label="Driver’s First Name *" value={driver.firstName} />
            <InputText {...inputProps("driver.lastName")} label="Driver’s Last Name *" value={driver.lastName} />
            <DateTimeInput {...inputProps("driver.dateofBirth")} label="Driver’s Date of Birth" value={driver.dateofBirth} keyboard />
          </Grid>
          <Grid item xs>
            <InputText {...inputProps("driver.location.address1")} label="Driver’s Address *" value={driver.location.address1} />
            <InputText {...inputProps("driver.location.city")} label="Driver’s City *" value={driver.location.city} />
            <Grid container spacing={4}>
              <Grid item xs>
                <SelectInput {...inputProps("driver.location.stateId")} label="Driver’s State *" value={driver.location.stateId} options={states} />
              </Grid>
              <Grid item xs>
                <InputText {...inputProps("driver.location.zipCode")} label="Driver’s Zip *" value={driver.location.zipCode} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <PhoneInput {...inputProps("driver.phone")} label="Driver’s Phone Number" value={driver.phone} />
            <InputText {...inputProps("driver.email")} label="Driver’s Email" value={driver.email} />
          </Grid>
        </Grid>
        <Divider className={classes.marginDivider} />
      </Collapse>
      <Collapse in={hasLienholder && !isDriverOwner && isLienholderClaimant} timeout="auto">
        <Grid container spacing={4}>
          <Grid item xs>
            <InputText {...inputProps("vehicleOwner.firstName")} label="Owner’s First Name *" value={vehicleOwner.firstName} />
            <InputText {...inputProps("vehicleOwner.lastName")} label="Owner’s Last Name *" value={vehicleOwner.lastName} />
            <DateTimeInput {...inputProps("vehicleOwner.dateofBirth")} label="Owner’s Date of Birth" value={vehicleOwner.dateofBirth} keyboard />
          </Grid>
          <Grid item xs>
            <InputText {...inputProps("vehicleOwner.location.address1")} label="Owner’s Address 1 *" value={voLocation.address1} />
            <InputText {...inputProps("vehicleOwner.location.city")} label="Owner’s City *" value={voLocation.city} />
            <Grid container spacing={4}>
              <Grid item xs>
                <SelectInput {...inputProps("vehicleOwner.location.stateId")} label="Owner’s State *" value={voLocation.stateId} options={states} />
              </Grid>
              <Grid item xs>
                <InputText {...inputProps("vehicleOwner.location.zipCode")} label="Owner’s Zip *" value={voLocation.zipCode} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <InputText {...inputProps("vehicleOwner.location.address2")} label="Owner’s Address 2" value={voLocation.address2} />
            <PhoneInput {...inputProps("vehicleOwner.phone")} label="Owner’s Phone Number" value={vehicleOwner.phone} />
            <InputText {...inputProps("vehicleOwner.email")} label="Owner’s Email" value={vehicleOwner.email} />
          </Grid>
        </Grid>
        <Divider className={classes.marginDivider} />
      </Collapse>
      <Grid container spacing={5}>
        <Grid item xs>
          <IntegerInput {...inputProps("vehicle.number")} label="Vehicle *" value={vehicle.number} />
          <IntegerInput {...inputProps("vehicle.vehicleYear")} label="Vehicle Year *" value={vehicle.vehicleYear} />
          <SelectInput {...inputProps("vehicle.vehicleMakeId")} label="Vehicle Make *" value={vehicle.vehicleMakeId} options={vehicleMake} />
        </Grid>
        <Grid item xs>
          <InputText {...inputProps("vehicle.model")} label="Vehicle Model *" value={vehicle.model} />
          <SelectInput
            {...inputProps("vehicle.vehicleBodyTypeId")}
            label="Vehicle Body Type"
            value={vehicle.vehicleBodyTypeId}
            options={vehicleBodyType}
          />
          <InputText {...inputProps("vehicle.vin")} label="V.I.N # *" value={vehicle.vin} />
        </Grid>
        <Grid item xs>
          <InputText {...inputProps("vehicle.plateNumber")} label="Vehicle Plate Number *" value={vehicle.plateNumber} />
          <SelectInput {...inputProps("vehicle.vehicleStateId")} label="Vehicle State *" value={vehicle.vehicleStateId} options={states} />
        </Grid>
      </Grid>
      <Divider className={classes.marginDivider} />
      <Grid container spacing={4}>
        <Grid item xs>
          <InputText {...inputProps("insuranceCarrier")} label="Carrier or Agency Name" value={insuranceCarrier} />
        </Grid>
        <Grid item xs>
          <SelectInput {...inputProps("nAICCodeId")} label="NAIC Code" value={nAICCodeId} options={codeType} />
        </Grid>
        <Grid item xs>
          <InputText {...inputProps("policyNumber")} label="Policy Number" value={policyNumber} />
        </Grid>
      </Grid>
      <Divider className={classes.marginDivider} />
      <Grid container spacing={4}>
        <Grid item xs>
          <InputText {...inputProps("damageDescription")} label="Damage Description" value={damageDescription} rows={4} />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs>
          <InputText {...inputProps("whereIsDamage")} label="Where can Damage be seen?" value={whereIsDamage} />
        </Grid>
        <Grid item xs>
          <SelectInput {...inputProps("vehicleDamageTypeId")} label="Car Damage Type Reported" value={vehicleDamageTypeId} options={damageType} />
        </Grid>
        <Grid item xs>
          <IntegerInput {...inputProps("estimateAmount")} label="Estimate Amount Reported" value={estimateAmount} />
        </Grid>
      </Grid>
      <Collapse in={hasLienholder && !isLienholderClaimant} timeout="auto">
        <Divider className={classes.marginDivider} />
        <Grid container spacing={4}>
          <Grid item xs>
            <InputText {...inputProps("lienHolder.name")} label="LienHolder Name *" value={lienHolder.name} />
            <InputText {...inputProps("lienHolder.location.address1")} label="LienHolder Address 1 *" value={lhLocation.address1} />
            <InputText {...inputProps("lienHolder.location.address2")} label="LienHolder Address 2" value={lhLocation.address2} />
          </Grid>
          <Grid item xs>
            <InputText {...inputProps("lienHolder.location.city")} label="LienHolder City *" value={lhLocation.city} />
            <SelectInput {...inputProps("lienHolder.location.stateId")} label="LienHolder State *" value={lhLocation.stateId} options={states} />
            <InputText {...inputProps("lienHolder.location.zipCode")} label="LienHolder Zip *" value={lhLocation.zipCode} />
          </Grid>
          <Grid item xs>
            <InputText {...inputProps("lienHolder.phone")} label="LienHolder Phone Number" value={lienHolder.phone} />
            <InputText {...inputProps("lienHolder.email")} label="LienHolder Email" value={lienHolder.email} />
            <InputText {...inputProps("lienHolder.mainContactName")} label="LienHolder Main Contact" value={lienHolder.mainContactName} />
          </Grid>
        </Grid>
      </Collapse>
    </Container>
  );
};

DamagedVehicle.propTypes = {
  claimant: PropTypes.objectOf(PropTypes.any),
  index: PropTypes.number
};

DamagedVehicle.defaultProps = {
  claimant: undefined,
  index: undefined
};

export default DamagedVehicle;
