import { API } from "../../actions/actionTypes";
import claimsLossesByAdjusterDetail from "../../actions/actionTypes/dashboard/claimsLossesByAdjusterDetail";
import { getSuccess } from "../../actions/dashboard/claimsLossesByAdjusterDetail";
import { takeLatest } from "../common/sagaGenerator";

export default [
  takeLatest({
    type: claimsLossesByAdjusterDetail.GETALL,
    actionSuccess: getSuccess,
    params: {},
    errorMessage: "Error loading Open/Closed Claims Losses",
    url: `${API.URL}/report/claimslossesbyadjusterdetail`
  })
];
