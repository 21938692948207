/* eslint-disable no-case-declarations */
import { CLAIM, SELECTS } from "../actions/actionTypes";
import initialState from "./initialState";

export default function commonReducer(state = initialState.common, action) {
  switch (action.type) {
    case SELECTS.LOAD_SUCCESS:
      const {
        selects: { users, ...selects }
      } = action;
      return { ...state, ...selects, adjusterUsers: users, supervisorUsers: users, assistantUsers: users, users };
    case SELECTS.GET_ALL_VENDOR_CARRIERS_SUCCESS:
      return { ...state, vendorCarriers: action.data };
    case CLAIM.OVERVIEW.GET_SUCCESS:
      const {
        overview: { adjusterUsers, supervisorUsers, assistantUsers }
      } = action;
      return { ...state, adjusterUsers, supervisorUsers, assistantUsers };
    default:
      return state;
  }
}
