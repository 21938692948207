import { Grid, Divider } from "@material-ui/core";
import { CheckCircleOutline, Room } from "@material-ui/icons";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import Card from "../../common/overview/Card";
import { useVendorInfoStyles } from "./index.styles";

const exists = s => s && s.trim();

const setLocationAddress = data => {
  const d = data || {};
  const comma = (exists(d.address1) || exists(d.address2)) && exists(d.city) && exists(d.state) ? "," : "";
  const dash = (exists(d.address1) || exists(d.address2) || exists(d.city) || exists(d.state)) && exists(d.zipCode) ? "-" : "";
  return `${d.address1 || d.address2 || ""}${comma} ${d.city || ""} ${d.state || ""} ${dash} ${d.zipCode || ""}`.trim();
};

const VendorInfo = props => {
  const vendorClasses = useVendorInfoStyles();
  const { fullName, isActive, id, category, phone1, location, w9RequestedOn, w9ReceivedOn, industry, locations } = props;
  return (
    <Card title="Vendor Info">
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs>
          <div className={vendorClasses.label}>Name</div>
          <div className={vendorClasses.title}>{fullName}</div>
        </Grid>
        <Grid item xs>
          {isActive && <CheckCircleOutline className={vendorClasses.checkCircle} />}
        </Grid>
      </Grid>
      <Grid container alignItems="stretch" className={vendorClasses.paddingTop}>
        <Grid item xs>
          <Room />
        </Grid>
        <Grid item xs={11}>
          <span className={vendorClasses.locationText}>{`Locations (${locations.length + 1})`}</span>
        </Grid>
      </Grid>
      <Grid container alignItems="stretch" className={vendorClasses.paddingTop}>
        <Grid item xs>
          <Room className={vendorClasses.locationIcon} />
        </Grid>
        <Grid item xs={11}>
          <span className={vendorClasses.locationText}>{`Headquarters: ${fullName}`}</span>
        </Grid>
      </Grid>
      <Grid container alignItems="stretch">
        <Grid item xs />
        <Grid item xs={11} className={vendorClasses.locationText}>
          {setLocationAddress(location)}
        </Grid>
      </Grid>
      <Grid container alignItems="stretch">
        <Grid item xs />
        <Grid item xs={5}>
          <div className={vendorClasses.label}>Category</div>
          {category}
        </Grid>
        <Grid item xs={5}>
          <div className={vendorClasses.label}>Phone</div>
          {phone1}
        </Grid>
        <Grid item xs />
      </Grid>
      <Grid container alignItems="stretch" className={vendorClasses.paddingBottom}>
        <Grid item xs />
        <Grid item xs={7}>
          <div className={vendorClasses.label}>Industry</div>
          {industry && industry.filter(value => value.vendorId === id).map(element => element.codeDivisionName)}
        </Grid>
        <Grid item xs={4}>
          <div className={vendorClasses.label}>W9 Requested</div>
          {w9RequestedOn ? moment(w9RequestedOn).format("M/D/YYYY") : <span>PENDING</span>}
          <div className={vendorClasses.label}>W9 Submission</div>
          {w9ReceivedOn ? moment(w9ReceivedOn).format("M/D/YYYY") : <span>PENDING</span>}
        </Grid>
      </Grid>
      {locations &&
        locations
          .filter(l => l.isActive)
          .map(item => (
            <Fragment key={item.id}>
              <Divider />

              <Grid container alignItems="stretch" className={vendorClasses.paddingTop}>
                <Grid item xs>
                  <Room className={vendorClasses.locationIcon} />
                </Grid>
                <Grid item xs={11}>
                  <span className={vendorClasses.locationText}>{item.fullName}</span>
                </Grid>
              </Grid>
              <Grid container alignItems="stretch">
                <Grid item xs />
                <Grid item xs={11} className={vendorClasses.locationText}>
                  {setLocationAddress(item)}
                </Grid>
              </Grid>
              <Grid container alignItems="stretch">
                <Grid item xs />
                <Grid item xs={5}>
                  <div className={vendorClasses.label}>Category</div>
                  {item.category}
                </Grid>
                <Grid item xs={5}>
                  <div className={vendorClasses.label}>Phone</div>
                  {item.phone1}
                </Grid>
                <Grid item xs />
              </Grid>
              <Grid container alignItems="stretch" className={vendorClasses.paddingBottom}>
                <Grid item xs />
                <Grid item xs={7}>
                  <div className={vendorClasses.label}>Industry</div>
                  {industry && industry.filter(value => value.vendorId === item.id).map(element => element.codeDivisionName)}
                </Grid>
                <Grid item xs={4}>
                  <div className={vendorClasses.label}>W9 Requested</div>
                  {item.w9RequestedOn ? moment(item.w9RequestedOn).format("M/D/YYYY") : <span className={vendorClasses.pending}>PENDING</span>}
                  <div className={vendorClasses.label}>W9 Submission</div>
                  {item.w9ReceivedOn ? moment(item.w9ReceivedOn).format("M/D/YYYY") : <span className={vendorClasses.pending}>PENDING</span>}
                </Grid>
              </Grid>
            </Fragment>
          ))}
    </Card>
  );
};

VendorInfo.propTypes = {
  fullName: PropTypes.string,
  isActive: PropTypes.bool,
  id: PropTypes.number,
  category: PropTypes.string,
  phone1: PropTypes.string,
  location: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string
  }),
  w9RequestedOn: PropTypes.string,
  w9ReceivedOn: PropTypes.string,
  industry: PropTypes.arrayOf(PropTypes.object),
  locations: PropTypes.arrayOf(PropTypes.object)
};

VendorInfo.defaultProps = {
  fullName: "",
  isActive: false,
  id: 0,
  category: "",
  phone1: "",
  location: {},
  w9RequestedOn: "",
  w9ReceivedOn: "",
  industry: [],
  locations: []
};

export default VendorInfo;
