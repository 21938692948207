import { makeStyles } from "@material-ui/core";

const CatastropheDuplicatesStyles = makeStyles(theme => ({
  //   root: { height: "100%", flexDirection: "column" },
  title: {
    color: "#428302",
    fontSize: 16,
    paddingBottom: theme.spacing(2.75),
    fontWeight: 500
  },
  title1: {
    color: "#898F91",
    fontSize: 16
  },
  noDuplicates: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    color: "#898F91",
    fontSize: 16,
    paddingBottom: theme.spacing(2.75),
    paddingTop: 45
  },
  noDuplicatesText: { textAlign: "center", color: "#898F91" },
  avatar: {
    width: 130,
    height: 130,
    color: "#898F91",
    fontSize: 118,
    backgroundColor: "#F3F3F3",
    "& > svg": { height: "1em", width: "1 em" },
    margin: "0 auto",
    marginBottom: theme.spacing(-2.25)
  },
  root: {
    padding: theme.spacing(0, 0, 0, 0),
    height: "100%"
  },
  container: {
    padding: theme.spacing(2, 2, 2, 2)
  },
  claimContainer: { margin: theme.spacing(0, 0, 0, 0) },
  //   claimNo: { color: "#000", fontSize: 19, fontWeight: 500, padding: theme.spacing(0.4, 1, 1, 2) },
  //   claimant: { color: "#000", fontSize: 15, fontWeight: 500 },
  //   spnIcon: { "& svg": { fontSize: 20 } },
  label: { fontSize: 11, color: "#898F91", padding: "5px" },
  info: { fontSize: 14, color: "#000", padding: "5px" },
  item: { paddingTop: "30px", paddingBottom: "30px", borderBottom: "solid 1px #898F91" }
  //   link: { marginBottom: -3, color: "#107A98" },
  //   status: { borderRadius: "50%", padding: 5, width: 24, height: 24, "& svg": { fontSize: 14 } },
  //   open: { backgroundColor: "#D2EEB6", "& svg": { color: "#428302" } },
  //   close: { backgroundColor: "#FCB7B0", "& svg": { color: "#E74C3C" } },
  //   reopen: { backgroundColor: "#7C9DAA", "& svg": { color: "#004763", transform: "scaleX(-1)" } },
  //   voided: { backgroundColor: "#F6C096", "& svg": { color: "#FE7F1E" } },
  //   pending: { backgroundColor: "#FCF3BC", "& svg": { color: "#EFD10F" } },
  //   pStatus: { fontSize: 20, marginBottom: -6 },
  //   accepted: { color: "#428302" },
  //   denied: { color: "#E74C3C" },
  //   pPending: { borderRadius: "50%", padding: 3, width: 24, height: 24, border: "solid 2px #efd10f", "& svg": { fontSize: 14, color: "#efd10f" } },
  //   rectangle: { backgroundColor: "#DAEAEF", borderRadius: 4, padding: theme.spacing(0.5, 1) },
  //   associateButton: { fontSize: 14 },
  //   exitToApp: { color: "#707070", padding: theme.spacing(0, 0, 0, 0), margin: theme.spacing(0, 0, 0, 0), width: 24, height: 24 },
  //   padding: { padding: theme.spacing(1, 1, 1, 2.6) },
  //   item: { padding: theme.spacing(0, 0, 2.5, 0) },
  //   claimantSection: { padding: theme.spacing(0, 2) },
  //   containerAssoc: { alignSelf: "flex-end", marginRight: 0, textAlign: "right" }
}));

export default CatastropheDuplicatesStyles;
