import { makeStyles } from "@material-ui/core";

const useVendorInfoStyles = makeStyles(() => ({
  title: { color: "#428302", fontSize: 16, fontWeight: 500 },
  locationText: { fontWeight: 500 },
  label: { fontSize: 11, color: "#898F91" },
  checkCircle: { color: "#73B25D" },
  locationIcon: { color: "#107A98" },
  pending: { color: "#E74C3C" },
  paddingTop: { paddingTop: 20 },
  paddingBottom: { paddingBottom: 20 }
}));

const useClaimsInvolvedStyles = makeStyles(() => ({
  box: {
    width: "100%",
    height: "144px",
    backgroundColor: "#F3F3F3",
    padding: "18px",
    borderRadius: 4
  },
  titleBox: { height: "40px" },
  label: { font: "Regular 11px/20px Roboto", paddingLeft: "5px", paddingRight: "5px" },
  count: { fontWeight: 500, fontSize: "48px" }
}));

const useFinancialInfoStyles = makeStyles(theme => ({
  reserved: { backgroundColor: "#1599BC" },
  total: { backgroundColor: "#898F91" },
  sumItem: { backgroundColor: "#F3F3F3", borderRadius: 4, padding: theme.spacing(2, 1.5), margin: "10px" },
  sumTitle: { color: "#898F91", fontWeight: 500 },
  sumAmount: { color: "#000000", fontSize: 24, fontWeight: 500, marginTop: theme.spacing(1.5) },
  sumGrid: { padding: "10px" },
  center: { textAlign: "center", marginTop: "20px" },
  icon: { height: "20px" },
  text: { borderRadius: "10px", border: "solid 1px #107A98", color: "#107A98", margin: "10px 0", fontSize: "12px" },
  triangle: {
    width: "0",
    height: "0",
    borderLeft: "3px solid transparent",
    borderRight: "3px solid transparent",
    borderBottom: "3px solid #707070",
    margin: "auto"
  },
  amount: { borderRadius: "15px", backgroundColor: "#707070", color: "#FFFFFF", fontSize: "20px", width: "40%", margin: "auto" }
}));

export { useVendorInfoStyles, useClaimsInvolvedStyles, useFinancialInfoStyles };
