import React, { memo } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Delete as DeleteIcon, Edit } from "@material-ui/icons";
import { FileNameIcon } from "../FileIcon";
import MaterialTable from "../../table/MaterialTable";
import LinkButton from "../../LinkButton";
import { colors } from "../../index.styles";
import LinkCount from "../../LinkCount";

const DocumentsTable = memo(({ actions: { onClick: editAction, onDelete, onOpen }, documents, actionsAllowed, exportFileName }) => {
  const countByPage = 10;
  const tablePropsDocuments = {
    columns: [
      {
        title: "Name",
        field: "fileName",
        render: r => (
          <LinkButton
            onClick={e => {
              onOpen(e, r);
            }}
          >
            <FileNameIcon file type={r.fileType} name={r.fileName} wrap="nowrap" cssName="tc-nowrap" />
          </LinkButton>
        )
      },
      { title: "Date", type: "date", field: "uploadedOn" },
      { title: "Folder", field: "folderName" },
      {
        title: "Uploaded By",
        field: "uploadedByName",
        width: null,
        cellStyle: {
          whiteSpace: "nowrap",
          paddingRight: 0,
          width: 200
        }
      },
      {
        title: "Claim",
        render: r => <LinkCount count={r.claimIds.length} />
      }
    ],
    data: documents || [],
    options: {
      headerStyle: { backgroundColor: colors.gray },
      exportFileName,
      pageSize: countByPage
    },
    hidePaging: (documents || []).length <= countByPage,
    actions: [
      actionsAllowed.update && {
        icon: Edit,
        tooltip: "Edit Document",
        onClick: (_e, r) => {
          editAction(r)(_e);
        }
      },
      actionsAllowed.delete && {
        icon: DeleteIcon,
        tooltip: "Delete Document",
        onClick: (_e, r) => {
          onDelete(r)(_e);
        }
      }
    ]
  };
  return (
    <>
      <Typography className="tc-title">Documents</Typography>
      <MaterialTable {...tablePropsDocuments} />
    </>
  );
});

DocumentsTable.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  actionsAllowed: PropTypes.objectOf(PropTypes.any),
  exportFileName: PropTypes.string
};

DocumentsTable.defaultProps = {
  documents: undefined,
  actionsAllowed: { read: false, create: false, update: false, delete: false },
  exportFileName: ""
};

export default DocumentsTable;
