import React, { useEffect, useState, useContext, useCallback, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Bar } from "react-chartjs-2";
import { Grid, Paper } from "@material-ui/core";
import { getFinancialInfo, post, set, delBill, setBillReindex, setBillAsDuplicate } from "../../../../redux/actions/claim/financial";
import { useRouteParam } from "../../../../common";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import { useLoadingContext } from "../../../common/Loading";
import Edit from "./BillEdit";
import Add from "./BillAdd";
// import Financial from "./Financial";
import Recovery from "./Recovery";
import { useFormStyles } from "./styles";
import FinancialList from "./FinancialList";

export default () => {
  const claimId = useRouteParam("claimId");
  const selectorData = useSelector(
    ({ common: { coverageTypes, schedulePaymentTypes }, claim, admin }) => ({
      claimLineOfBusinessList: coverageTypes,
      claim,
      admin,
      schedulePaymentTypes
    }),
    shallowEqual
  );

  const {
    claim: {
      reserves,
      financial: { financialList, recoveryList }
    }
  } = selectorData || {};
  const { outstandingReserve } = reserves;
  const [{ typeBill }, setState] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [rowEdit, setRowEdit] = useState({});
  const [addMode, setAddMode] = useState(false);
  const dispatch = useDispatch();
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "financial");
  const {
    actions: { hideLoading, showLoading, handleBreadcrumb, handlePadding, handleSideBar }
  } = useLoadingContext();
  useEffect(() => {
    handleSideBar(false);
    if (access.read) {
      showLoading();
      dispatch(getFinancialInfo(claimId, hideLoading, hideLoading));
    }
    return () => handleSideBar(true);
  }, []);

  useEffect(() => {
    if (typeBill) {
      setState({ billType: null });
    }
  }, []);

  const classes = useFormStyles();

  const onChange = useCallback(value => {
    setState(prevState => ({ ...prevState, typeBill: value || null }));
  }, []);

  const viewBillList = () => {
    setEditMode(false);
    setAddMode(false);
  };
  const onCancel = () => {
    setState({ billType: null });
    viewBillList();
  };

  const onSuccess = () => {
    hideLoading();
    viewBillList();
  };

  const onError = () => hideLoading();

  const onEdit = billInfo => {
    showLoading();
    if (access.update)
      dispatch(
        set(
          {
            ...billInfo,
            claimId
          },
          onSuccess,
          onError
        )
      );
  };
  const onAdd = billInfo => {
    showLoading();
    if (access.create)
      dispatch(
        post(
          {
            ...billInfo,
            claimId
          },
          onSuccess,
          onError
        )
      );
  };

  const onBillReindex = billInfo => {
    showLoading();
    if (access.update) dispatch(setBillReindex(billInfo, onSuccess, onError));
  };

  const onBillDuplicate = billInfo => {
    showLoading();
    if (access.update) dispatch(setBillAsDuplicate(billInfo, onSuccess, onError));
  };

  const onBillDelete = billInfo => {
    showLoading();
    if (access.delete) dispatch(delBill(billInfo.id, onSuccess, onError));
  };

  const totalPaid = useMemo(
    () =>
      financialList
        .filter(item => item.status === "Paid" || item.status === "Partial Paid")
        .reduce((acc, elem) => (!elem.scheduledPaymentId ? acc + (elem?.totalPaid || 0) : acc), 0),
    [financialList]
  );
  const totalPendingPaid = useMemo(
    () =>
      financialList.filter(item => item.status === "Pending").reduce((acc, elem) => (!elem.scheduledPaymentId ? acc + (elem?.amount || 0) : acc), 0),
    [financialList]
  );
  const totalActualRecovery = useMemo(() => recoveryList.reduce((acc, recovery) => acc + recovery.actual, 0), [recoveryList]);
  const dataValues = useMemo(
    () => ({
      labels: ["Outstanding Reserve", "Total Paid", "Pending Payments", "Actual Recoveries"],
      datasets: [
        {
          label: "",
          backgroundColor: ["#BED600", "#58A618", "#EADF00"],
          borderWidth: [1, 1],
          data: [outstandingReserve, totalPaid, totalPendingPaid, totalActualRecovery]
        }
      ]
    }),
    [outstandingReserve, totalPaid, totalPendingPaid, totalActualRecovery]
  );

  const barOptions = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };
  useEffect(() => {
    handlePadding(!editMode && !addMode);
    handleBreadcrumb(!editMode && !addMode);
    return () => {
      handlePadding(true);
      handleBreadcrumb(true);
    };
  }, [editMode, addMode]);
  return (
    <>
      {(!editMode && !addMode && (
        <Grid container spacing={2}>
          <Grid item sm={12} md={4}>
            <Paper className={classes.bar}>
              <div style={{ maxHeight: 300, padding: "10px 20px 20px 0" }}>
                <Bar data={dataValues} options={barOptions} height={300} width={234} />
              </div>
            </Paper>
            <Recovery />
          </Grid>
          <Grid item sm={12} md={8}>
            <FinancialList {...{ onChange, setAddMode, setEditMode, setRowEdit }} />
          </Grid>
        </Grid>
      )) || (
        <>
          {editMode && (
            <Edit
              billId={rowEdit.id}
              onSave={onEdit}
              onCancel={onCancel}
              onReindex={onBillReindex}
              onDuplicate={onBillDuplicate}
              onDelete={onBillDelete}
            />
          )}
          {addMode && <Add billType={typeBill} onSave={onAdd} onCancel={onCancel} />}
        </>
      )}
    </>
  );
};
