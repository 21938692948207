import * as yup from "yup";
import { isNumber, rootParent, isEmail } from "../../../../common/yup";

const string = () => yup.string().nullable();
const number = () => yup.number().nullable();

const evalDm = fnValidate => val => (typeof fnValidate === "function" ? fnValidate : Boolean)(val);
const evalDriver = (type, msg, fnValidate) =>
  type().test("driver", msg, (val, context) => {
    const { isDriverOwner } = rootParent(context);
    return isDriverOwner || evalDm(fnValidate)(val, context);
  });
const evalOwner = (type, msg, fnValidate) =>
  type().test("vehicleOwner", msg, (val, context) => {
    const { hasLienholder, isDriverOwner, isLienholderClaimant } = rootParent(context);
    return !hasLienholder || isDriverOwner || !isLienholderClaimant || evalDm(fnValidate)(val);
  });
const evalLienh = (type, msg, fnValidate) =>
  type().test("lienHolder", msg, (val, context) => {
    const { hasLienholder, isLienholderClaimant } = rootParent(context);
    return !hasLienholder || isLienholderClaimant || evalDm(fnValidate)(val);
  });

export default yup.object().shape({
  isLienholderClaimant: yup.boolean(),
  hasLienholder: yup.boolean(),
  isDriverOwner: yup.boolean(),
  vehicle: yup.object().shape({
    number: number().required("Vehicle # Required"),
    vehicleYear: number().required("Vehicle Year Required"),
    vehicleMakeId: number().required("Vehicle Make Required"),
    model: string().required("Vehicle Model Required"),
    vehicleBodyTypeId: number().required("Vehicle Body Type Required"),
    vin: string().required("V.I.N. # Required"),
    plateNumber: string().required("Vehicle Plate Number Required"),
    vehicleStateId: number().required("Vehicle State Required")
  }),
  driver: yup.object().shape({
    licenseNumber: string().required("Driver’s License Number Required"),
    firstName: evalDriver(string, "Driver's First Name Required"),
    lastName: evalDriver(string, "Driver's Last Name Required"),
    email: yup.string().test("", "Invalid Email", v => !v || isEmail(v)),
    location: yup.object().shape({
      address1: evalDriver(string, "Driver's Address Required"),
      city: evalDriver(string, "Driver's City Required"),
      stateId: evalDriver(number, "Driver's State Required", isNumber),
      zipCode: evalDriver(string, "Driver's Zip Code Required")
    })
  }),
  vehicleOwner: yup.object().shape({
    firstName: evalOwner(string, "Owner's First Name Required"),
    lastName: evalOwner(string, "Owner's Last Name Required"),
    location: yup.object().shape({
      address1: evalOwner(string, "Driver's Address Required"),
      city: evalOwner(string, "Driver's City Required"),
      stateId: evalOwner(number, "Driver's State Required", isNumber),
      zipCode: evalOwner(string, "Driver's Zip Code Required")
    })
  }),
  lienHolder: yup.object().shape({
    name: evalLienh(string, "LienHolder Name Required"),
    location: yup.object().shape({
      address1: evalLienh(string, "LienHolder Address 1 Required"),
      city: evalLienh(string, "LienHolder City Required"),
      stateId: evalLienh(number, "LienHolder State Required", isNumber),
      zipCode: evalLienh(string, "LienHolder Zip Code Required")
    })
  })
});
