import { INITIALREPORT, API } from "../../../actions/actionTypes";
import { getSuccess, setChildSuccess } from "../../../actions/initialReport/insuredInformation";
import { takeLatest, takeLatestSet } from "../../common/sagaGenerator";

const url = `${API.URL}/claim/insured`;

export default [
  takeLatest({
    type: INITIALREPORT.INFORMATION.INSURED.GET,
    actionSuccess: getSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading insured",
    url
  }),
  takeLatestSet({
    type: INITIALREPORT.INFORMATION.INSURED.SET,
    actionSuccess: setChildSuccess,
    params: ({ id, parentId }) => ({ id, parentId }),
    errorMessage: "Error Saving Insured Structure",
    successMessage: "Insured Structure Saved",
    url
  })
];
