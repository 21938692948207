import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import React, { Fragment } from "react";
import { Redirect, useRouteMatch, Route } from "react-router-dom";
import DinamicTabs from "../../components/clients/client/DinamicTabs";
import Loading, { LoadingContextProvider } from "../../components/common/Loading";
import { useClient, useClientWrapper } from "./hooks";
import useStyles from "./index.styles";

const ClientWrapper = () => {
  const { isExact } = useRouteMatch();
  const classes = useStyles();
  const { anyLevel, clientLevels, confTabs, currentPath, hideSidebar, pathname, routesAllowed } = useClientWrapper();
  const redirectToProfile = () => {
    const pathNameLength = pathname.split("/").length;
    if (pathNameLength >= 5) {
      const lastElement = pathname.split("/")[pathNameLength - 1];
      // eslint-disable-next-line no-restricted-globals
      const isNumeric = !isNaN(lastElement) && !isNaN(parseFloat(lastElement));
      if (isNumeric) {
        return <Redirect to={`${pathname}/profile`} />;
      }
    }
    return <></>;
  };
  return (
    <Fragment key="client-page">
      {(clientLevels.length > 0 && confTabs.length > 0 && routesAllowed.length && (
        <>
          {isExact && <Redirect to={`${pathname}/overview`} />}
          {redirectToProfile()}
          <Grid container className={classes.root} wrap="nowrap">
            <Grid item className={clsx(classes.container, "tc-page-container")}>
              {(anyLevel && (
                <Route path={currentPath}>
                  <DinamicTabs confTabs={confTabs} clientStructure={clientLevels} routesAllowed={routesAllowed} />
                </Route>
              )) || <DinamicTabs confTabs={confTabs} clientStructure={clientLevels} routesAllowed={routesAllowed} />}
            </Grid>
            <Grid item xs={3} hidden={hideSidebar} id="clientSidebar" />
          </Grid>
        </>
      )) || <Loading />}
    </Fragment>
  );
};

const ClientPage = () => {
  const clientHandles = useClient();
  return (
    <LoadingContextProvider float {...clientHandles}>
      <ClientWrapper />
    </LoadingContextProvider>
  );
};

export default ClientPage;
