import React, { useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import * as yup from "yup";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { SelectInput, TextInput, IntegerInput } from "../../../common/inputs";
import { useFormStyles } from "./styles";
import ValidationContext from "./ValidationContext";
import useYupValidationSchema from "../../../common/customHooks/useYupValidationSchema";

const schema = yup.object().shape({
  statusId: yup.string().required("Status is required")
});

const RecoveryForm = ({ recovery, onChange }) => {
  const selectorData = useSelector(
    ({ common: { recoveryStatus } }) => ({
      recoveryStatus
    }),
    shallowEqual
  );

  const { handleValidateSchema, validationMessages } = useYupValidationSchema(schema);

  const { fireValidation } = useContext(ValidationContext);

  useEffect(() => {
    if (fireValidation) handleValidateSchema(recovery);
  }, [fireValidation]);

  const classes = useFormStyles();

  const { statusId, estimated, actual, comment } = recovery || {};

  const { recoveryStatus } = selectorData;

  const handleChange = ({ target: { name, value } }) => {
    onChange(prev => {
      handleValidateSchema({ ...prev, [name]: value });

      return { ...prev, [name]: value };
    });
  };
  return (
    <div className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <form>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <SelectInput
                    name="statusId"
                    label="Recovery Status"
                    value={statusId}
                    options={recoveryStatus}
                    onChange={handleChange}
                    error={validationMessages?.statusId}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <IntegerInput name="estimated" label="Estimated Recovery" value={estimated} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <IntegerInput name="actual" label="Actual Recovery" value={actual} onChange={handleChange} />
                </Grid>
              </Grid>
            </div>
          </form>
        </Grid>
        <Grid item xs={12}>
          <TextInput name="comment" label="Comment" row={3} value={comment} onChange={handleChange} />
        </Grid>
      </Grid>
    </div>
  );
};

RecoveryForm.propTypes = {
  recovery: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired
};

export default RecoveryForm;
