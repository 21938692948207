import * as yup from "yup";
import { beforeTodaySchema } from "../../common/yup";

export default yup.object().shape({
  name: yup.string().required("Name is Required"),
  date: beforeTodaySchema().required("Date Since is Required"),
  // address1: yup.string().required("Address  is Required"),
  // city: yup.string().required("City is Required"),
  stateId: yup
    .number()
    .nullable()
    .required("State is Required")
  // zipCode: yup.string().required("Zip Code is Required"),
});
