import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

export const useDocumentFolderStyles = makeStyles(
  theme => ({
    root: { width: 92, margin: theme.spacing(3, 5, 2, 0) },
    hover: {
      "& [class*=DocumentFolder-folder]": {
        color: "rgba(0, 0, 0, 0.8) !important"
      }
    },
    error: {
      "& [class*=DocumentFolder-folder]": {
        color: "rgba(221,75,57, 0.5) !important"
      }
    },
    container: { position: "relative", height: 86 },
    folder: { fontSize: 80, color: "rgba(0, 0, 0, 0.5)", cursor: "pointer" },
    actions: { fontSize: 16, position: "absolute", top: 0, right: 0, color: "rgba(0, 0, 0, 0.5)", cursor: "pointer" },
    nameContainer: { cursor: "pointer", color: "#000000" },
    name: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", paddingRight: 3 }
  }),
  { name: "DocumentFolder" }
);

export const useDocumentsBoxStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(1, 2), overflow: "hidden", position: "relative" },
  title: { color: "#428302", flexGrow: 1 },
  iconActive: { color: "#000000" },
  iconInactive: { color: "#7F7F7F" },
  pl: { paddingLeft: 5 },
  pr: { paddingRight: 5 },
  br: { borderRight: "solid 1px rgba(112, 112, 112, 0.5)" },
  pointer: { cursor: "pointer" }
}));

export const useProgressButtonStyles = makeStyles(theme => ({
  wrapper: { position: "relative" },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1
  },
  button: {
    transition: theme.transitions.create(["background-color", "color"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard
    })
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: "white !important",
    "&:hover": {
      backgroundColor: green[700]
    }
  }
}));

export const useFileDropzoneStyles = makeStyles({
  root: {
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0)",
    color: "rgba(0,0,0,0)",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    opacity: 0,
    zIndex: 1
  },
  hover: {
    backgroundColor: "rgba(0,0,0,0.3)",
    color: "rgba(0,0,0,0.3)",
    opacity: 1,
    zIndex: "3 !important"
  },
  error: {
    backgroundColor: "rgba(221,75,57,0.3) !important"
  }
});

export const useRootStyles = makeStyles({
  wrapper: { position: "relative" },
  root: { width: "calc(100% + 40px) !important" },
  feedback: {
    position: "absolute",
    display: "flex",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255,255,255, 0.5)",
    transition: "visibility 0s, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    visibility: "hidden",
    opacity: 0
  },
  loading: {
    visibility: "visible !important",
    opacity: "1 !important"
  }
});

export const useProgress = (upload = false, delay = 500, initProgress = 1, onSuccess) => {
  const [state, setState] = useState({ loading: false, progress: undefined, timestamp: undefined });
  const dispatch = useDispatch();
  const dispatchProgress = ({ action, params, onComplete }) => {
    if (typeof action !== "function") return;
    setState(ps => ({ ...ps, loading: true, progress: initProgress }));
    const onFinish = success => () => {
      setState(ps => ({ loading: false, progress: undefined, timestamp: success ? new Date().getTime() : ps.timestamp }));
      if (success) setTimeout(() => setState(ps => ({ ...ps, timestamp: undefined })), 100);
      if (typeof onComplete === "function") onComplete();
    };
    dispatch(
      action({
        ...(params || {}),
        onSuccess: () =>
          setTimeout(() => {
            if (typeof onSuccess === "function") onSuccess();
            return onFinish(true)();
          }, delay),
        onError: onFinish(false),
        [upload ? "onUploadProgress" : "onDownloadProgress"]: ({ loaded, total }) => setState(ps => ({ ...ps, progress: (loaded / total) * 100 }))
      })
    );
  };
  const setTimestamp = useCallback(() => setState(ps => ({ ...ps, timestamp: new Date().getTime() })), []);
  return [state, dispatchProgress, setTimestamp];
};

export const validateFile = ({ kind, type }) =>
  kind === "file" &&
  ["application/pdf", "image/png", "image/jpg", "image/jpeg", "video/quicktime", "video/avi"].indexOf(String(type || "").toLowerCase()) >= 0;
