import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { Room } from "@material-ui/icons";
import { useClaimDayStyles } from "./index.styles";
import Bar from "../../../common/BarProgress";

const ClaimDayInfo = () => {
  const { dateofLoss, dateReported, location, status, openDaysQuantity: days } = useSelector(({ claim: { claim } }) => claim, shallowEqual);
  const { stateCode = "" } = location || {};
  const classes = useClaimDayStyles();
  const percent = days / 365;
  return (
    <div className={classes.root}>
      <Grid container alignItems="center" spacing={4}>
        {dateofLoss && (
          <Grid item>
            <small>Day of Loss</small>
            <div>{moment(dateofLoss).format("M/D/YYYY")}</div>
          </Grid>
        )}
        {dateReported && (
          <Grid item>
            <small>Date Reported</small>
            <div>{moment(dateReported).format("M/D/YYYY")}</div>
          </Grid>
        )}
        {stateCode && (
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item className={classes.icon}>
                <Room />
              </Grid>
              <Grid item>{stateCode}</Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container alignItems="center" className={classes.barwrapper}>
        <Grid item xs={12}>
          <Bar {...{ classes, color: "#004763", percent, tooltip: `${days} days ${status}` }} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ClaimDayInfo;
