import React from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, Input, InputAdornment, IconButton, Tooltip, FormHelperText } from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import { HocInput } from "./HocInput";

const SearchLabelInput = ({ error, name, label, tooltip, onClick, value, forwardRef, ...other }) => {
  const id = `search-${name}`;
  const btn = (typeof onClick === "function" && (
    <IconButton onClick={onClick}>
      <Search />
    </IconButton>
  )) || <Search />;
  const props = {
    name,
    id,
    ref: forwardRef,
    error: Boolean(error),
    endAdornment: <InputAdornment position="start">{(tooltip && <Tooltip title={tooltip}>{btn}</Tooltip>) || btn}</InputAdornment>
  };
  return (
    <FormControl fullWidth {...other}>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <Input {...{ ...props, value }} />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

SearchLabelInput.propTypes = {
  name: PropTypes.string.isRequired,
  forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })]),
  error: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string
};

SearchLabelInput.defaultProps = {
  forwardRef: null,
  error: undefined,
  label: "",
  tooltip: "",
  onClick: null,
  value: ""
};

export default HocInput(SearchLabelInput);
