import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import LabelValue from "../../LabelValue";
import MultipleLabels from "../../Labels";
import useCommonSelects from "../../customHooks/useCommonSelect";

const ClaimantViewMode = ({ claimant, isReportOnly, claimLineOfBusinessId }) => {
  const {
    firstName,
    middleName,
    lastName,
    dateofBirth,
    gender,
    maritalStatus,
    dependents,
    isPerson = false,
    taxID,
    phone,
    mobile,
    email,
    workPhone,
    workEmail,
    commercialName,
    taxIDTypeId,
    contact
  } = claimant || {};

  const [taxType] = useCommonSelects(["taxType"]);

  const { address1: pAddress1 = "" } = claimant?.location || {};
  const { address1: mAddress1 = "" } = claimant?.mailingLocation || {};

  const phisicalAsMailing = pAddress1 === mAddress1;
  const taxIdTypeLab = {
    label: "Tax ID Type",
    value: taxType.length > 0 ? (taxType.find(t => t.id === taxIDTypeId) || {}).text : "",
    key: "claimant_taxidtype"
  };
  const taxIdLab = {
    label: "Tax ID #",
    value: taxID || "",
    key: "claimant_taxid"
  };
  const physicalAdd1Lab = {
    label: "Physical Address",
    value: claimant?.location || {},
    key: "claimant_address1_physical",
    isAddress: true
  };

  const mailingAddLab = {
    label: "Mailing Address",
    value: claimant?.mailingLocation || {},
    key: "claimant_mailingAddress1_physical",
    isAddress: true
  };
  const emptyMailingAddLab = {
    label: "Mailing Address",
    value: "Same as Physical address",
    key: "claimant_mailingAddress1_physical"
  };
  const locationData = !phisicalAsMailing ? [physicalAdd1Lab, mailingAddLab] : [physicalAdd1Lab, emptyMailingAddLab];

  const personFullName = `${firstName ?? ""} ${middleName ?? ""} ${lastName ?? ""}`;
  return (
    <form style={{ padding: "10px" }}>
      <Grid container spacing={4}>
        <Grid item xs>
          {claimLineOfBusinessId !== 4 && <LabelValue label={!isReportOnly ? "Claimant Type" : "Type"} value={isPerson ? "Person" : "Entity"} />}
          {isPerson && (
            <Fragment key="person-information">
              <LabelValue label="Name" value={personFullName} />
              <Grid container>
                <Grid item xs>
                  <LabelValue label="Date of Birth" value={dateofBirth} isDate />
                </Grid>
                <Grid item xs>
                  <LabelValue label="Gender" value={gender} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs>
                  <LabelValue label="Marital Status" value={maritalStatus} />
                </Grid>
                <Grid item xs>
                  <LabelValue label="Dependents" value={dependents} />
                </Grid>
              </Grid>
            </Fragment>
          )}
          {!isPerson && (
            <Fragment key="entity-information">
              <LabelValue label="Commercial Name" value={commercialName} />
              <LabelValue label="Main Contact" value={contact} />
            </Fragment>
          )}
        </Grid>
        <Grid item xs>
          <Grid container spacing={4}>
            <Grid item xs>
              <LabelValue {...taxIdTypeLab} />
            </Grid>
            <Grid item xs>
              <LabelValue {...taxIdLab} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <MultipleLabels data={locationData} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          {isPerson && <LabelValue label="Home Phone" value={phone} />}
          <MultipleLabels
            data={[
              {
                label: "Cell Phone",
                value: mobile,
                key: "claimant_cell_phone"
              },
              {
                label: "Work Phone",
                value: workPhone,
                key: "claimant_workp_hone"
              },
              {
                label: "Work Email",
                value: workEmail,
                key: "claimant_work_email"
              },
              {
                label: "Personal Email",
                value: email,
                key: "claimant_personal_email"
              }
            ]}
          />
        </Grid>
      </Grid>
    </form>
  );
};

ClaimantViewMode.propTypes = {
  claimant: PropTypes.objectOf(PropTypes.any).isRequired,
  isReportOnly: PropTypes.bool,
  claimLineOfBusinessId: PropTypes.number.isRequired
};

ClaimantViewMode.defaultProps = {
  isReportOnly: false
};

export default ClaimantViewMode;
