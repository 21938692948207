import { ClickAwayListener, Grow, Paper, Popper as MuiPopper } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { childrenPropType } from "../../common";

const Popper = ({ children, onClickAway, transformOrigin, ...props }) => (
  <MuiPopper {...props} role={undefined} transition disablePortal>
    {({ TransitionProps }) => (
      <Grow {...TransitionProps} style={{ transformOrigin }}>
        <Paper elevation={2}>
          <ClickAwayListener {...{ onClickAway }}>{children}</ClickAwayListener>
        </Paper>
      </Grow>
    )}
  </MuiPopper>
);

Popper.propTypes = { children: childrenPropType().isRequired, onClickAway: PropTypes.func, transformOrigin: PropTypes.string };
Popper.defaultProps = { onClickAway: undefined, transformOrigin: "center bottom" };

export default Popper;
