import rootSagaGenerator from "../common/rootSagaGenerator";
import sagaInsured from "./level_insured";
import sagaProfile from "./profile";
import sagaInsureds from "./insureds";
import sagaContact from "./contact";
import sagaPolicy from "./policy";
import sagaAdd from "./add";
import sagaList from "./list";
import sagaSettings from "./setting";
import sagaLevels from "./getLevels";
import sagaEmployees from "./employee";

export default rootSagaGenerator({
  name: "Client",
  sagas: [
    ...sagaInsured,
    ...sagaProfile,
    ...sagaInsureds,
    ...sagaContact,
    ...sagaPolicy,
    ...sagaAdd,
    ...sagaList,
    ...sagaSettings,
    ...sagaLevels,
    ...sagaEmployees
  ]
});
