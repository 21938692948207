import { Badge } from "@material-ui/core";
import { GetApp, NoteAdd } from "@material-ui/icons";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import LinkButton from "../LinkButton";
import RegexFilter from "../RegexFilter";
import Table from "../table/MaterialTable";
import { FileNameIcon } from "./FileIcon";
import { useRootStyles } from "./hooks";
import ProgressButton, { progressProps } from "./ProgressButton";

const folderWithCount = folder => (
  <>
    {folder.name}
    <small>{` (${folder.filesCount})`}</small>
  </>
);

const RootListView = ({
  actionsAllowed,
  filter,
  folder: { folderList },
  downloadState,
  onAddFileToFolder,
  onDownload,
  openFolder,
  path,
  uploadState
}) => {
  const classes = useRootStyles();
  const countFiles = fldr => {
    let count = fldr.fileList.length;
    fldr.folderList.forEach(f => {
      count += countFiles(f);
    });
    return count;
  };
  const data = folderList.filter(x => !filter || RegexFilter(filter, x.name)).map(f => ({ ...f, filesCount: countFiles(f) }));
  const isClaim = path === "claimId";
  const tableProps = {
    columns: [
      {
        title: "Folder",
        field: "name",
        render: r => (
          <>
            {(r.isQueue && (
              <Badge badgeContent={r.filesCount} color="primary">
                <LinkButton className="tc-pr05" onClick={() => openFolder(r)}>
                  <FileNameIcon file={false} name={r.name} wrap="nowrap" />
                </LinkButton>
              </Badge>
            )) || (
              <LinkButton onClick={() => openFolder(r)}>
                <FileNameIcon file={false} name={folderWithCount(r)} wrap="nowrap" />
              </LinkButton>
            )}
          </>
        )
      },
      {
        title: "Last Activity",
        render: r => !r.isQueue && (!isClaim || r.filesCount > 0) && moment(r.lastActivity).format("M/D/YYYY")
      }
    ],
    data,
    actions: [
      ...(actionsAllowed?.create && typeof onAddFileToFolder === "function"
        ? [f => (f.isQueue ? undefined : { icon: NoteAdd, tooltip: "Add File", onClick: (e, { id }) => onAddFileToFolder(id)(e) })]
        : []),
      f =>
        f.isQueue || !f.filesCount ? undefined : { icon: GetApp, tooltip: "Download", onClick: (_e, { id }) => onDownload([{ id, file: false }]) }
    ]
  };
  return (
    <div className={classes.wrapper}>
      <Table {...tableProps} hideToolbar hidePaging />
      <div className={clsx(classes.feedback, { [classes.loading]: downloadState.loading || uploadState.loading })}>
        {downloadState.loading && <ProgressButton {...downloadState} />}
        {uploadState.loading && <ProgressButton {...uploadState} upload />}
      </div>
    </div>
  );
};

RootListView.propTypes = {
  actionsAllowed: PropTypes.objectOf(PropTypes.any).isRequired,
  filter: PropTypes.string.isRequired,
  folder: PropTypes.objectOf(PropTypes.any).isRequired,
  downloadState: PropTypes.shape(progressProps).isRequired,
  onAddFileToFolder: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  openFolder: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  uploadState: PropTypes.shape(progressProps).isRequired
};

export default RootListView;
