const hasChildren = (elemets, id) => elemets.some(rt => rt.parentId === id);

const shouldBeReadOnly = (elemets, row) => !row.isMoney || (row.isMoney && hasChildren(elemets, row.typeId));

const stringToXML = str => {
  if (window.ActiveXObject) {
    // IE
    // eslint-disable-next-line no-undef
    const miXML = new ActiveXObject("Microsoft.XMLDOM");
    miXML.loadXML(str);
    return miXML;
  }
  // eslint-disable-next-line no-undef
  return new DOMParser().parseFromString(str, "text/xml"); // Chrome, Safari, Firefox, Opera, etc.
};

const appendPropertyFromXml = xml => {
  const o = Array.from(stringToXML(xml).getElementsByTagName("Field")).reduce((acc, item) => {
    return item.getAttribute("type") !== "LABEL" ? { ...acc, [item.getAttribute("name")]: parseFloat(item.getAttribute("value")) } : { ...acc };
  }, {});
  return o;
};

const sumPropertyTotal = (elemets, name) => elemets.reduce((a, b) => a + b[name], 0);

const sumTotalAmount = (elements, pId, name) => elements.filter(rt => rt.parentId === pId).reduce((acc, rt) => acc + rt[name], 0.0);

const getRow = (elements, id) => elements.filter(rt => rt.typeId === id)[0];

const makeReserveDetail = (elements, name, value, typeId) => {
  let newElements = [];
  if (typeof value === "object") {
    newElements = elements.map(rt => (rt.typeId === typeId ? { ...rt, ...value } : rt));
  } else {
    newElements = elements.map(rt => (rt.typeId === typeId ? { ...rt, [name]: value } : rt));
  }
  const parentId = getRow(newElements, typeId)?.parentId;

  const a = newElements.map(rt =>
    rt.typeId === parentId ? { ...rt, totalAmountReserved: sumTotalAmount(newElements, rt.typeId, "amountReserved") } : rt
  );
  return a;
};

const isNullOrUndefined = o => o === null || o === undefined;

const applySchemaXml = row => !isNullOrUndefined(row.xml);

const mapXmlFromJson = row => {
  if (isNullOrUndefined(row.xml) || row.xml === "") return null;

  const xml = Array.from(stringToXML(row.xml).getElementsByTagName("Field")).reduce((acc, item) => {
    const name = item.getAttribute("name");
    if (item.getAttribute("type") === "INPUT") item.setAttribute("value", row[name]);
    return `${acc}${item.outerHTML}`;
  }, "");
  return `<Formula>${xml || ""}</Formula>`;
};

export {
  shouldBeReadOnly,
  stringToXML,
  appendPropertyFromXml,
  sumTotalAmount,
  sumPropertyTotal,
  applySchemaXml,
  makeReserveDetail,
  isNullOrUndefined,
  mapXmlFromJson
};
