import { Grid, Collapse, Typography, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Fragment, memo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  CheckboxLabel,
  SelectInput,
  InputText,
  DateTimeInput,
  IntegerInput,
  PhoneInput,
  TaxSocialSecurityNumber,
  TaxIdNumber
} from "../../common/inputs";
import { useEmployeeStyles } from "./index.styles";

const mapEmployee = ({ target: { name, value } }, state) => {
  const [name1, name2] = name.split(".");
  if (name1 === "location" || name1 === "mailAddress") return { ...state.employee, [name1]: { ...state.employee[name1], [name2]: value } };
  return { ...state.employee, [name]: value };
};

const EmployeeForm = memo(({ employee, error, setState }) => {
  const classes = useEmployeeStyles();
  const { phisicalAsMailing } = employee;
  const { gender, maritalStatus, states, taxType } = useSelector(({ common }) => common, shallowEqual);
  const handleChange = e => setState(state => ({ ...state, employee: mapEmployee(e, state), validation: new Date().getTime() }));
  const inputProps = path => {
    const val = () => {
      const [name1, name2] = path.split(".");
      if (name2) return ["value", employee[name1][name2]];
      return [name1 === "phisicalAsMailing" ? "checked" : "value", employee[name1]];
    };
    const [name, value] = val();
    return {
      onChange: handleChange,
      name: path,
      error: error[`employee.${path}`],
      [name]: value
    };
  };
  return (
    <Fragment key="employee-form-box">
      <form>
        <Paper className={classes.root}>
          <Grid container className={classes.head} spacing={2}>
            <Grid item xs>
              <Typography className="tc-title">Employee</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <InputText {...inputProps("firstName")} label="First Name *" />
              <InputText {...inputProps("middleName")} label="Middle Name" />
              <InputText {...inputProps("lastName")} label="Last Name *" />
            </Grid>
            <Grid item xs={3}>
              <InputText {...inputProps("location.address1")} label="Address 1 *" />
              <InputText {...inputProps("location.city")} label="City *" />
              <Grid container spacing={4}>
                <Grid item xs>
                  <SelectInput {...inputProps("location.stateId")} label="State *" options={states} />
                </Grid>
                <Grid item xs>
                  <InputText {...inputProps("location.zipCode")} label="Zip *" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container>
                <Grid item xs className={classes.pr1}>
                  <DateTimeInput {...inputProps("dateofBirth")} label="Date of Birth *" keyboard />
                </Grid>
                <Grid item xs className={classes.pl1}>
                  <SelectInput {...inputProps("genderId")} label="Gender" options={gender} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs className={classes.pr1}>
                  <SelectInput {...inputProps("maritalStatusId")} label="Marital Status" options={maritalStatus} />
                </Grid>
                <Grid item xs className={classes.pl1}>
                  <IntegerInput {...inputProps("dependents")} label="Dependents" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs className={classes.pl1}>
                  <InputText {...inputProps("personalEmail")} label="Personal Email" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container>
                <Grid item xs className={classes.pr1}>
                  <SelectInput {...inputProps("taxIDTypeId")} label="Tax ID Type *" options={taxType} />
                </Grid>
                <Grid item xs className={classes.pl1}>
                  {employee.taxIDTypeId === 1 ? (
                    <TaxSocialSecurityNumber {...inputProps("taxID")} label="Tax ID # *" />
                  ) : (
                    <TaxIdNumber {...inputProps("taxID")} label="Tax ID # *" />
                  )}
                </Grid>
              </Grid>
              <PhoneInput {...inputProps("mobile")} label="Cell Phone" />
            </Grid>
          </Grid>
          <CheckboxLabel {...inputProps("phisicalAsMailing")} label="Use Physical Address as Mailing Address" />
          <Collapse in={!phisicalAsMailing} timeout="auto">
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <InputText {...inputProps("mailAddress.address1")} label="Mailing Address *" />
              </Grid>
              <Grid item xs={3}>
                <InputText {...inputProps("mailAddress.city")} label="City *" />
              </Grid>
              <Grid item xs={3}>
                <SelectInput {...inputProps("mailAddress.stateId")} label="State *" options={states} />
              </Grid>
              <Grid item xs={3}>
                <InputText {...inputProps("mailAddress.zipCode")} label="Zip *" />
              </Grid>
            </Grid>
          </Collapse>
        </Paper>
      </form>
    </Fragment>
  );
});

EmployeeForm.propTypes = {
  employee: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.string).isRequired,
  setState: PropTypes.func.isRequired
};

export default EmployeeForm;
