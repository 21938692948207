export default {
  id: undefined,
  number: undefined,
  name: undefined,
  // policyNo: undefined,
  location: undefined,
  // mailingLocation: undefined,
  phone1: undefined,
  type: undefined,
  sizeTypeName: undefined,
  coverageTypes: [],
  children: [],
  child: undefined
};
