import { CLIENTR as CLIENT, API } from "../../actions/actionTypes";
import { getEmployeesSuccess, addEmployeeSuccess, editEmployeeSuccess, deleteEmployeeSuccess } from "../../actions/client/insured/employee";
import { takeLatest, takeLatestPost, takeLatestSet, takeLatestDel } from "../common/sagaGenerator";

export default [
  takeLatest({
    type: CLIENT.INSURED.EMPLOYEES.GET,
    url: `${API.URL}/client/getAllEmployeesByClientId`,
    params: ({ id }) => ({ id }),
    actionSuccess: getEmployeesSuccess,
    errorMessage: "Unable to get Employees"
  }),
  takeLatestPost({
    type: CLIENT.INSURED.EMPLOYEE.POST,
    url: `${API.URL}/client/addClientEmployee`,
    params: ({ employee }) => employee,
    actionSuccess: addEmployeeSuccess,
    successMessage: "Employee successfully added",
    errorMessage: "Unable to add the employee"
  }),
  takeLatestSet({
    type: CLIENT.INSURED.EMPLOYEE.PUT,
    url: `${API.URL}/client/setClientEmployee`,
    params: ({ employee }) => employee,
    actionSuccess: editEmployeeSuccess,
    successMessage: "Employee successfully updated",
    errorMessage: "Unable to updated the employee"
  }),
  takeLatestDel({
    type: CLIENT.INSURED.EMPLOYEE.DELETE,
    url: `${API.URL}/client/delClientEmployee`,
    params: ({ employeeId, clientId }) => ({ employeeId, clientId }),
    actionSuccess: deleteEmployeeSuccess,
    successMessage: "Employee successfully deleted",
    errorMessage: "Unable to delete the employee"
  })
];
