import { toast } from "react-toastify";
import { objectToFormData } from "../../../../../api/apiUtils";
import { CLIENTR as CLIENT, API } from "../../../../actions/actionTypes";
import {
  getPolicySuccess,
  getPoliciesSuccess,
  setPolicySuccess,
  deletePolicySuccess,
  getPoliciesByPolicyIdSuccess,
  addRelatedPolicySuccess,
  deleteRelatedPolicySuccess,
  postPolicySuccess,
  setFinancialSuccess
} from "../../../../actions/client/insured/policy/policy";
import { takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "../../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLIENT.INSURED.POLICY.GET_POLICIES,
    actionSuccess: getPoliciesSuccess,
    errorMessage: "Error loading Insured policies",
    url: `${API.URL}/client/policiesbyinsured`,
    params: ({ insuredId }) => ({ insuredId })
  }),
  takeLatest({
    type: CLIENT.INSURED.POLICY.GET,
    actionSuccess: getPolicySuccess,
    errorMessage: "Error loading Insured policy",
    url: `${API.URL}/client/insuredpolicy`,
    params: ({ id }) => ({ id })
  }),
  takeLatestPost({
    type: CLIENT.INSURED.POLICY.POST,
    actionSuccess: postPolicySuccess,
    successMessage: "Policy successfully added",
    errorMessage: "Unable to add the policy",
    url: `${API.URL}/client/policy`,
    params: ({ policy }) => objectToFormData(policy)
  }),
  takeLatestSet({
    type: CLIENT.INSURED.POLICY.SET,
    actionSuccess: setPolicySuccess,
    onSuccess: ({ policy: { boxName } }) => toast.success(`${boxName || "Policy Details"} successfully saved`),
    errorMessage: "Unable to save Policy Details",
    url: `${API.URL}/client/policy`,
    params: ({ policy }) => policy
  }),
  takeLatestDel({
    type: CLIENT.INSURED.POLICY.DEL,
    actionSuccess: deletePolicySuccess,
    errorMessage: "Unable to delete the Policy",
    successMessage: "Policy successfully deleted.",
    url: `${API.URL}/client/delpolicy`,
    params: ({ id, insuredId }) => ({ id, insuredId })
  }),
  takeLatest({
    type: CLIENT.INSURED.POLICY.RELATED.GET,
    actionSuccess: getPoliciesByPolicyIdSuccess,
    errorMessage: "Error loading policies",
    url: `${API.URL}/policy/policiesbypolicy`,
    params: ({ policyId }) => ({ policyId })
  }),
  takeLatestPost({
    type: CLIENT.INSURED.POLICY.RELATED.POST,
    actionSuccess: addRelatedPolicySuccess,
    errorMessage: "Error adding related policy",
    successMessage: "Policy added",
    url: `${API.URL}/policy/associatepolicy`,
    params: ({ relatedPolicyId, policyId }) => ({ relatedPolicyId, policyId })
  }),
  takeLatestDel({
    type: CLIENT.INSURED.POLICY.RELATED.DEL,
    actionSuccess: deleteRelatedPolicySuccess,
    errorMessage: "Error deleting related policy",
    successMessage: "Policy removed",
    url: `${API.URL}/policy/disassociatepolicy`,
    params: ({ relatedPolicyId, policyId }) => ({ relatedPolicyId, policyId })
  }),
  takeLatestSet({
    type: CLIENT.INSURED.POLICY.FINANCIAL.SET,
    actionSuccess: setFinancialSuccess,
    errorMessage: "Unable to save Policy Financial Information",
    successMessage: "Policy Financial Information successfully saved",
    url: `${API.URL}/policy/financial`,
    params: ({ data }) => data
  })
];
