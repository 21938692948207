import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import BasicInformationForm from "./forms/BasicInformationForm";

const BasicInformation = ({ nextLevelId }) => {
  const {
    values: { basicInformation },
    handleChange,
    errors
  } = useFormikContext();

  return <BasicInformationForm {...{ basicInformation, handleChange, errors, nextLevelId }} />;
};

BasicInformation.propTypes = {
  nextLevelId: PropTypes.number
};

BasicInformation.defaultProps = {
  nextLevelId: null
};

export default BasicInformation;
