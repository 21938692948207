import { Tab } from "@material-ui/core";
import React, { Fragment, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Link, Switch, Redirect, Route, useRouteMatch, useLocation } from "react-router-dom";
import SimpleTabs from "../../components/common/tabs/SimpleTabs";
import Vendors from "./List";
import Pending from "./ListPending";
import { getUserAccess } from "../../common";
import PageNotAllowed from "../../components/PageNotAllowed";

export default () => {
  const { isExact, url, path } = useRouteMatch();
  const { pathname } = useLocation();
  const secProfs = useSelector(({ profile: { securityProfilesDetails } }) => securityProfilesDetails, shallowEqual);
  const tabsConfig = [
    ["vendors", "Vendor List", Vendors, getUserAccess(secProfs, "VENDORS.LIST", null, null, null)?.read || false],
    ["pending", "Pending Investigation", Pending, getUserAccess(secProfs, "VENDORS.PENDING", null, null, null)?.read || false]
  ];
  const container = useRef(null);
  return (
    <Fragment key="vendors-page">
      {isExact && <Redirect to={`${url}/vendors`} />}
      <div ref={container} className="tc-page-container">
        <SimpleTabs value={url === pathname ? `${url}/vendors` : pathname}>
          {tabsConfig.map(
            ([route, label, , allowed]) =>
              allowed && <Tab key={route} label={label} component={Link} to={`${url}/${route}`} value={`${url}/${route}`} />
          )}
        </SimpleTabs>
        <Switch>
          {tabsConfig.map(([route, , Component, allowed]) => (
            <Route key={route} exact path={`${path}/${route}`} render={() => (allowed ? <Component /> : <PageNotAllowed />)} />
          ))}
        </Switch>
      </div>
    </Fragment>
  );
};
