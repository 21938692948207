import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import { Paper, Grid } from "@material-ui/core";
import { useClaimantInfoStyles } from "./index.styles";

export default () => {
  const classes = useClaimantInfoStyles();
  const store = useSelector(({ claim: { claimant, insured } }) => ({ claimant, insured }), shallowEqual);

  const {
    claimant: { isPerson, commercialName, fullName, dateofBirth, age, gender },
    insured: { name }
  } = store || {};
  return (
    <Paper className={classes.root}>
      <Grid container spacing={4} alignItems="center">
        <Grid item>
          <div className={classes.title}>Claimant</div>
          {((isPerson && fullName) || commercialName) && (
            <div className={classes.claimant}>
              {isPerson && fullName}
              {!isPerson && commercialName}
            </div>
          )}
        </Grid>
        {(commercialName || fullName) && (
          <Grid item>
            <div className={classes.label}>Claimant Type</div>
            {isPerson && "Person"}
            {!isPerson && "Entity"}
          </Grid>
        )}
        {isPerson && (
          <>
            {dateofBirth && (
              <Grid item>
                <div className={classes.label}>DOB</div>
                {moment(dateofBirth).format("M/D/YYYY")}
              </Grid>
            )}
            {age && (
              <Grid item>
                <div className={classes.label}>Age</div>
                {age}
              </Grid>
            )}
            {gender && (
              <Grid item style={{ flexGrow: 1 }}>
                <div className={classes.label}>Gender</div>
                {gender}
              </Grid>
            )}
          </>
        )}
        {name && (
          <Grid item>
            <div className={classes.label}>Insured</div>
            {name}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
