import { API } from "../../actions/actionTypes";
import claimsByAdjuster from "../../actions/actionTypes/dashboard/claimsByAdjuster";
import { getSuccess } from "../../actions/dashboard/claimsByAdjuster";
import { takeLatest } from "../common/sagaGenerator";

export default [
  takeLatest({
    type: claimsByAdjuster.GETALL,
    actionSuccess: getSuccess,
    params: {},
    errorMessage: "Error loading Open Claims by Adjuster",
    url: `${API.URL}/report/claimsbyadjuster`
  })
];
