// import employer from "./insured";
import insured from "./insured";
import incident from "./incident";
import employee from "./employee";
import employment from "./employment";
import createAsTeam from "./createAsTeam";

export default {
  duplicates: [],
  employment,
  insured,
  incident,
  employee,
  createAsTeam,
  fromInitialReport: false
};
