import { CLIENTR as CLIENT } from "../../../actionTypes";

const getCoverage = (policyId, coverageTypeId, onSuccess) => ({
  type: CLIENT.INSURED.POLICY.COVERAGE.GET,
  policyId,
  coverageTypeId,
  onSuccess
});
const getCoverageSuccess = coverage => ({ type: CLIENT.INSURED.POLICY.COVERAGE.GET_SUCCESS, coverage });

const setCoverage = (coverage, onSuccess, onError) => ({ type: CLIENT.INSURED.POLICY.COVERAGE.SET, coverage, onSuccess, onError });
const setCoverageSuccess = insuredPolicy => ({ type: CLIENT.INSURED.POLICY.COVERAGE.SET_SUCCESS, insuredPolicy });

const delCoverage = (policyId, coverageTypeId, onSuccess) => ({
  type: CLIENT.INSURED.POLICY.COVERAGE.DEL,
  policyId,
  coverageTypeId,
  onSuccess
});
const delCoverageSuccess = policy => ({ type: CLIENT.INSURED.POLICY.COVERAGE.DEL_SUCCESS, policy });

export { getCoverage, getCoverageSuccess, setCoverage, setCoverageSuccess, delCoverage, delCoverageSuccess };
