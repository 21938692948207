import React, { Fragment, memo } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { Formik } from "formik";
import useOnToggleBoxes from "../../../common/customHooks/useHandleBox";
import CollapsibleBox from "../../../common/CollapsibleBox";
import { DateTimeInput, SelectInput, InputText, FeinInput, PhoneInput } from "../../../common/inputs";

const BasicInformationForm = memo(({ basicInformation, handleChange, errors, nextLevelId }) => {
  const { open, onToggle } = useOnToggleBoxes({ initialValue: { information: true } });
  const { sizeTypes, clientTypes, states } = useSelector(store => store.common);
  const {
    name,
    typeId,
    sizeTypeId,
    clientSinceDate,
    taxId,
    description,
    address1,
    city,
    stateId,
    zip,
    mailingaddress1,
    mailingcity,
    mailingstateId,
    mailingzip,
    email,
    phone1
  } = basicInformation || {};

  const clientTypesByLevel =
    nextLevelId && nextLevelId === 2
      ? clientTypes.filter(c => c.levelId === 1 && c.text !== "Peo")
      : clientTypes.filter(c => c.levelId === nextLevelId) || [];

  return (
    <CollapsibleBox
      title="Basic Information"
      name="information"
      {...{
        open: open.information,
        onToggle
      }}
    >
      <Formik>
        <Fragment key="Client-Add-Basic-Information">
          <Grid container spacing={4}>
            <Grid item xs>
              <InputText name="basicInformation.name" label="Name" value={name} onChange={handleChange} error={errors?.basicInformation?.name} />

              <SelectInput
                label="Type"
                name="basicInformation.typeId"
                value={typeId}
                options={!nextLevelId ? clientTypes.filter(c => c.levelId === 1) : clientTypesByLevel}
                onChange={handleChange}
                error={errors?.basicInformation?.typeId}
              />
              <InputText name="basicInformation.address1" label="Address" value={address1} onChange={handleChange} />
              <InputText name="basicInformation.mailingaddress1" label="Mailing Address" value={mailingaddress1} onChange={handleChange} />
              <InputText
                name="basicInformation.email"
                label="Company Email"
                value={email}
                onChange={handleChange}
                error={errors?.basicInformation?.email}
              />
            </Grid>
            <Grid item xs>
              <SelectInput
                label="Size"
                name="basicInformation.sizeTypeId"
                value={sizeTypeId}
                options={sizeTypes}
                onChange={handleChange}
                error={errors?.basicInformation?.sizeTypeId}
              />
              <FeinInput name="basicInformation.taxId" label="FEIN Number" value={taxId} onChange={handleChange} />
              <InputText name="basicInformation.city" label="City" value={city} onChange={handleChange} />
              <InputText name="basicInformation.mailingcity" label="Mailing City" value={mailingcity} onChange={handleChange} />
              <PhoneInput name="basicInformation.phone1" label="Company Phone" value={phone1} onChange={handleChange} />
            </Grid>
            <Grid item xs>
              <DateTimeInput
                name="basicInformation.clientSinceDate"
                label="Client Since Date"
                value={clientSinceDate}
                keyboard
                onChange={handleChange}
              />
              <Grid container spacing={4}>
                <Grid item xs>
                  <InputText name="basicInformation.zip" label="Zip" value={zip} onChange={handleChange} />
                  <InputText name="basicInformation.mailingzip" label="Mailing Zip" value={mailingzip} onChange={handleChange} />
                </Grid>
                <Grid item xs>
                  <SelectInput name="basicInformation.stateId" label="State" value={stateId} options={states} onChange={handleChange} />
                  <SelectInput
                    name="basicInformation.mailingstateId"
                    label="Mailing State"
                    value={mailingstateId}
                    options={states}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs>
              <InputText name="basicInformation.description" label="Description" value={description} rows={4} onChange={handleChange} />
            </Grid>
          </Grid>
        </Fragment>
      </Formik>
    </CollapsibleBox>
  );
});

BasicInformationForm.propTypes = {
  basicInformation: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  nextLevelId: PropTypes.number
};

BasicInformationForm.defaultProps = {
  nextLevelId: null
};

export default BasicInformationForm;
