import { makeStyles } from "@material-ui/core";
import { useSelector, shallowEqual } from "react-redux";
import * as yup from "yup";
import { isNumber, rootParent } from "../../../../common/yup";
import { colors } from "../../index.styles";

export const useDocuments = (occurrenceId, catastropheId) => {
  const { entityId, entityType } = (() => {
    if (occurrenceId) return { entityId: occurrenceId, entityType: "occurrence" };
    if (catastropheId) return { entityId: catastropheId, entityType: "catastrophe" };
    return { entityId: undefined, entityType: "" };
  })();
  const isOccurrence = entityType === "occurrence";
  const isCatastrophe = entityType === "catastrophe";
  if (!isOccurrence && !isCatastrophe) return { entityId, entityType, isCatastrophe, associatedList: [], occurrence: {}, catastrophe: {} };
  return useSelector(
    ({
      [entityType]: {
        [entityType]: { id, associatedList, documents, number },
        [entityType]: entity
      }
    }) => ({
      storeId: id,
      entityId,
      entityType,
      isCatastrophe,
      associatedList,
      occurrence: (isOccurrence && entity) || {},
      catastrophe: (isCatastrophe && entity) || {},
      documents,
      number
    }),
    shallowEqual
  );
};

export const useAddDocumentsStyles = makeStyles(theme => ({
  root: { marginBottom: theme.spacing(4) },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    verticalAlign: "middle",
    lineHeight: "1em",
    margin: theme.spacing(2, 0),
    "& > thead > tr > th": { backgroundColor: colors.gray },
    "& > tbody > tr > td:first-of-type": {
      width: 36,
      padding: theme.spacing(0.5, 0),
      color: colors.color898F91
    },
    "& > tbody > tr > td:not(:first-of-type)": { padding: theme.spacing(0.5, 1.5) }
  },
  folder: { minWidth: 160, whiteSpace: "nowrap" },
  del: { width: 60, paddingRight: 0 }
}));

export const useFolders = (associatedList, claimIds) =>
  (associatedList || [])
    .filter(({ id }) => claimIds.some(cId => cId === id))
    .reduce(
      (ac, c) => [...ac, ...(c.folders || []).reduce((fc, f) => [...fc, ...(ac.some(({ id }) => f.id === id) ? [] : [{ ...f, text: f.name }])], [])],
      []
    )
    .sort((a, b) => a.name.localeCompare(b.name));

export const yupSchema = yup.object().shape({
  newFiles: yup.array().of(
    yup.object().shape({
      folderId: yup
        .number()
        .nullable()
        .test("default", "Folder required", (val, ctx) => {
          const { claimIds, editFile } = rootParent(ctx);
          return claimIds.length === 0 || Boolean(editFile) || isNumber(val);
        })
    })
  ),
  editFile: yup.object().nullable(),
  claimIds: yup.array().of(yup.number())
});
