import { CLIENTR as CLIENT } from "../actionTypes";

// Get Actions

const getProfile = (id, onSuccess, onError) => ({ type: CLIENT.PROFILE.GET, id, onSuccess, onError });

const getProfileSuccess = profile => ({ type: CLIENT.PROFILE.GET_SUCCESS, profile });

const getInsuredProfile = (id, onSuccess, onError) => ({ type: CLIENT.INSURED.PROFILE.GET, id, onSuccess, onError });

const getInsuredProfileSuccess = profile => ({ type: CLIENT.INSURED.PROFILE.GET_SUCCESS, profile });

// Update Actions

const updateBasic = ({ data, onSuccess, onError }) => ({ type: CLIENT.PROFILE.BASIC.SET, data, onSuccess, onError });

const setAgent = (data, onSuccess, onError) => ({ type: CLIENT.PROFILE.AGENT.SET, data, onSuccess, onError });

const updateStatus = ({ data, onSuccess, onError }) => ({ type: CLIENT.PROFILE.STATUS.SET, data, onSuccess, onError });

const addCode = (clientId, codeId, onSuccess, onError) => ({ type: CLIENT.PROFILE.CODE_PROFILE.POST, clientId, codeId, onSuccess, onError });

const removeCode = (clientId, codeId, onSuccess, onError) => ({ type: CLIENT.PROFILE.CODE_PROFILE.DEL, clientId, codeId, onSuccess, onError });

// Update Actions Success

const updateBasicSuccess = profile => ({ type: CLIENT.PROFILE.BASIC.SET_SUCCESS, profile });

const setAgentSuccess = profile => ({ type: CLIENT.PROFILE.AGENT.SET_SUCCESS, profile });

const updateStatusSuccess = profile => ({ type: CLIENT.PROFILE.STATUS.SET_SUCCESS, profile });

const addCodeSuccess = codeInsured => ({ type: CLIENT.PROFILE.CODE_PROFILE.POST_SUCCESS, codeInsured });

const removeCodeSuccess = codeInsured => ({ type: CLIENT.PROFILE.CODE_PROFILE.DEL_SUCCESS, codeInsured });

const addArea = (data, onSuccess, onError) => ({ type: CLIENT.PROFILE.AREA.POST, data, onSuccess, onError });

const addAreaSuccess = profile => ({ type: CLIENT.PROFILE.AREA.POST_SUCCESS, profile });

const addOperatingState = (data, onSuccess, onError) => ({ type: CLIENT.PROFILE.OPERATING_STATE.POST, data, onSuccess, onError });

const addOperatingStateSuccess = profile => ({ type: CLIENT.PROFILE.OPERATING_STATE.POST_SUCCESS, profile });

const setOperatingState = (data, onSuccess, onError) => ({ type: CLIENT.PROFILE.OPERATING_STATE.SET, data, onSuccess, onError });

const setOperatingStateSuccess = profile => ({ type: CLIENT.PROFILE.OPERATING_STATE.SET_SUCCESS, profile });

const deleteOperatingState = (data, onSuccess, onError) => ({ type: CLIENT.PROFILE.OPERATING_STATE.DEL, data, onSuccess, onError });

const deleteOperatingStateSuccess = profile => ({ type: CLIENT.PROFILE.OPERATING_STATE.DEL_SUCCESS, profile });

const setArea = (data, onSuccess, onError) => ({ type: CLIENT.PROFILE.AREA.SET, data, onSuccess, onError });

const setAreaSuccess = profile => ({ type: CLIENT.PROFILE.AREA.SET_SUCCESS, profile });

const deleteArea = (data, onSuccess, onError) => ({ type: CLIENT.PROFILE.AREA.DEL, data, onSuccess, onError });

const deleteAreaSuccess = profile => ({ type: CLIENT.PROFILE.AREA.DEL_SUCCESS, profile });

export {
  getProfile,
  getInsuredProfile,
  updateBasic,
  setAgent,
  updateStatus,
  addCode,
  removeCode,
  getProfileSuccess,
  getInsuredProfileSuccess,
  updateBasicSuccess,
  addCodeSuccess,
  removeCodeSuccess,
  updateStatusSuccess,
  setAgentSuccess,
  addArea,
  addAreaSuccess,
  setArea,
  setAreaSuccess,
  deleteArea,
  deleteAreaSuccess,
  addOperatingState,
  addOperatingStateSuccess,
  setOperatingState,
  setOperatingStateSuccess,
  deleteOperatingState,
  deleteOperatingStateSuccess
};
