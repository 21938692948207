import { Grid, makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { openFile, setStatus } from "../../../redux/actions/vendor";
import { FileNameIcon } from "../../common/documents/FileIcon";
import { BaseSelectInput } from "../../common/inputs";
import LabelValue from "../../common/LabelValue";
import LinkButton from "../../common/LinkButton";
import useViewer from "../../common/viewer/useViewer";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  item: { paddingRight: 64 }
}));
const lblCss = { root: "tc-mb0", value: "tc-pt12px" };

export default () => {
  const css = useStyles();
  const dataSelector = useSelector(
    ({
      vendor: {
        profile: { basicInformation }
      }
    }) => basicInformation,
    shallowEqual
  );

  const { id, fullName, fileId, fileName, fileType, ...basic } = dataSelector || {};

  const vendorStatus = useSelector(({ common: { vendorStatus: s } }) => s, shallowEqual).filter(({ id: i }) => i !== 1);
  const dispatch = useDispatch();
  const [statusId, setStatusId] = useState(undefined);
  useEffect(() => {
    setStatusId(basic.statusId);
  }, [basic.statusId]);
  const { onViewFile, fileViewer } = useViewer({ action: openFile });
  const fileLink = (
    <LinkButton onClick={() => onViewFile(fileId, { indexingStatusId: 6 })}>
      <FileNameIcon name={fileName} type={fileType} cssName="tc-link" />
    </LinkButton>
  );
  const onChange = ({ target: { value } }) => {
    setStatusId(value);
    return dispatch(setStatus({ id, statusId: value }, () => setTimeout(() => setStatusId(basic.statusId), 0)));
  };

  return (
    <Paper className={css.root}>
      <Grid container>
        <Grid item className={css.item}>
          <LabelValue label="Vendor Name" value={fullName} classes={lblCss} />
        </Grid>
        <Grid item className={css.item}>
          <LabelValue label="Incoming Bill" value={fileLink} classes={{ root: "tc-mb0" }} />
        </Grid>
        <Grid item>
          <BaseSelectInput label="Status" name="statusId" value={statusId} options={vendorStatus} {...{ onChange }} />
        </Grid>
      </Grid>
      {fileViewer}
    </Paper>
  );
};
