import { CLIENTR as CLIENT, API } from "../../../actions/actionTypes";
import { getSuccess, addSuccess, getByIdSuccess, setSuccess } from "../../../actions/client/insured/location";
import { takeLatest, takeLatestPost, takeLatestSet } from "../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLIENT.INSURED.LOCATION.GET,
    actionSuccess: getByIdSuccess,
    errorMessage: "Error loading location",
    url: `${API.URL}/insuredLocation/insuredLocationById`,
    params: ({ id }) => ({ id })
  }),
  takeLatest({
    type: CLIENT.INSURED.LOCATION.GET_ALL,
    actionSuccess: getSuccess,
    errorMessage: "Error loading location by InsuredId",
    url: `${API.URL}/insuredLocation/getByInsured`,
    params: ({ id }) => ({ id })
  }),
  takeLatestSet({
    type: CLIENT.INSURED.LOCATION.SET,
    actionSuccess: setSuccess,
    successMessage: "Location updated successfully",
    errorMessage: "Error Updating location",
    url: `${API.URL}/insuredLocation`,
    params: ({ data }) => data
  }),
  takeLatestPost({
    type: CLIENT.INSURED.LOCATION.POST,
    actionSuccess: addSuccess,
    successMessage: "Location was added successfully",
    errorMessage: "Error Adding location",
    url: `${API.URL}/insuredLocation`,
    params: ({ data }) => data
  })
];
