import { Grid, CardContent, Typography } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { Suspense } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Link, useRouteMatch, Route } from "react-router-dom";
import { getUserAccess } from "../../common";
import ActionsAllowed from "../../components/common/contexts/ActionsAllowed";
import Loading from "../../components/common/Loading";
import baseCards from "./cards";
import useStyles from "./index.style";
import routes from "./Routes";

const PageNotAllowed = React.lazy(() => import("../../components/PageNotAllowed"));

const CardBox = ({ card }) => (
  <CardContent style={card.style} disabled={!!card.disabled}>
    {!!card.Icon && (
      <Typography color="textSecondary" gutterBottom>
        <card.Icon />
      </Typography>
    )}
    <Typography variant="h5" component="h2" gutterBottom>
      {card.title}
    </Typography>
    <Typography variant="body2" component="p">
      {card.text}
    </Typography>
  </CardContent>
);

CardBox.propTypes = { card: PropTypes.objectOf(PropTypes.any).isRequired };

const upper = txt => txt.toLocaleUpperCase();

const AdminPage = () => {
  const classes = useStyles();
  const { isExact, path } = useRouteMatch();
  const profile = useSelector(({ profile: { securityProfilesDetails } }) => securityProfilesDetails, shallowEqual);
  const { routesAllowed, cards } = baseCards.reduce(
    (a, c) => {
      if (!profile?.length) return a;
      const section = upper(c.section);
      if (c.section === "users") {
        const access = getUserAccess(profile, `ADMIN.${section}`);
        return { ...a, routesAllowed: [...a.routesAllowed, { ...access, route: c.section }], cards: [...a.cards, { ...c, disabled: !access?.read }] };
      }
      const subRoutes = c.subRoutes.reduce((sa, r) => [...sa, { ...getUserAccess(profile, `ADMIN.${section}.${upper(r)}`), route: r }], []);
      return { ...a, routesAllowed: [...a.routesAllowed, ...subRoutes], cards: [...a.cards, { ...c, disabled: !subRoutes.some(s => s.read) }] };
    },
    { routesAllowed: [], cards: [] }
  );
  const isAllowed = sectionName => !cards.find(e => e.section === sectionName)?.disabled;
  return (
    <>
      {isExact && (
        <div className={classes.root}>
          <Grid container alignItems="stretch" justify="center">
            {cards.map(item => (
              <Grid key={`${item.order}_grid`} item xs={12} sm={3} className={clsx(classes.card, { [classes.hover]: !item.disabled })}>
                {(item.disabled && <CardBox card={item} />) || (
                  <Link to={`${path}${item.link}`}>
                    <CardBox card={item} />
                  </Link>
                )}
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      <ActionsAllowed.Provider value={routesAllowed}>
        {routes(path).map(item => (
          <Route
            key={item.path}
            path={item.path}
            render={() => <Suspense fallback={<Loading />}>{isAllowed(item.section) ? <item.component /> : <PageNotAllowed />}</Suspense>}
          />
        ))}
      </ActionsAllowed.Provider>
    </>
  );
};

export default AdminPage;
