import { takeLatest } from "redux-saga/effects";
import { CLAIM, API } from "../../actions/actionTypes";
import { getSuccess, setSuccess } from "../../actions/claim/reserves";
import sagaGenerator from "../common/sagaGenerator";

const url = `${API.URL}/reserve`;

export default [
  takeLatest(
    CLAIM.RESERVES.GET,
    sagaGenerator({
      url,
      params: ({ claimId }) => ({ claimId }),
      actionSuccess: getSuccess,
      errorMessage: "Error loading reserves"
    })
  ),
  takeLatest(
    CLAIM.RESERVES.SET,
    sagaGenerator({
      method: "PUT",
      url,
      params: ({ reserves }) => reserves,
      actionSuccess: setSuccess,
      successMessage: "Reserve successfully saved",
      errorMessage: "Unable to save the reserve"
    })
  )
];
