export default [
  { text: "Claim", isTitle: true, identification: 1 },
  { text: "##ClaimNumber##", parentId: 1 },
  { text: "##ClaimStatus##", parentId: 1 },
  { text: "##ClaimProcessingStatus##", parentId: 1 },
  { text: "##ClaimDteReported##", parentId: 1 },
  { text: "##ClaimLineOfBusiness##", parentId: 1 },
  { text: "##ClaimLossTypeGroup##", parentId: 1 },
  { text: "##ClaimLossType##", parentId: 1 },
  { text: "##ClaimCauseCode##", parentId: 1 },
  { text: "##ClaimDateOfLoss##", parentId: 1 },
  { text: "##ClaimAddress##", parentId: 1 },
  { text: "##ClaimJurisdictionState##", parentId: 1 },
  { text: "##ClaimFROIReceived##", parentId: 1 },
  { text: "##ClaimStateNumber##", parentId: 1 },
  { text: "##ClaimantAttorneyName##", parentId: 1 },
  { text: "##ClaimantAttorneyAddress##", parentId: 1 },
  { text: "##DefendantAttorneyName##", parentId: 1 },
  { text: "##DefendantAttorneyAddress##", parentId: 1 },
  { text: "##ClaimAdjusterName##", parentId: 1 },
  { text: "##ClaimAdjusterEmail##", parentId: 1 },
  { text: "##ClaimSupervisorName##", parentId: 1 },
  { text: "##ClaimSupervisorEmail##", parentId: 1 },
  { text: "##ClaimAssistantName##", parentId: 1 },
  { text: "##ClaimAssistantEmail##", parentId: 1 },
  { text: "##AdjusterBussinessPhone##", parentId: 1 },
  { text: "##AdjusterMobilePhone##", parentId: 1 },
  { text: "##SupervisorBussinessPhone##", parentId: 1 },
  { text: "##SupervisorMobilePhone##", parentId: 1 },
  { text: "##AdjusterJobTitle##", parentId: 1 },
  { text: "##SupervisorJobTitle##", parentId: 1 },
  { text: "##AssistantJobTitle##", parentId: 1 },
  { text: "Claimant", isTitle: true, identification: 2 },
  { text: "##ClaimantFirstName##", parentId: 2 },
  { text: "##ClaimantLastName##", parentId: 2 },
  { text: "##ClaimanName##", parentId: 2 },
  { text: "##ClaimantAddress##", parentId: 2 },
  { text: "##ClaimantMailingAddress##", parentId: 2 },
  { text: "##ClaimantTaxID##", parentId: 2 },
  { text: "##ClaimantDateOfBirth##", parentId: 2 },
  { text: "##ClaimantEmail##", parentId: 2 },
  { text: "##ClaimantPhone##", parentId: 2 },
  { text: "Client", isTitle: true, identification: 3 },
  { text: "##ClientNumber##", parentId: 3 },
  { text: "##ClientName##", parentId: 3 },
  { text: "##ClientAddress##", parentId: 3 },
  { text: "##ClientPhone## ", parentId: 3 },
  { text: "Employee", isTitle: true, identification: 4 },
  { text: "##EmployeeName##", parentId: 4 },
  { text: "##EmployeeAddress##", parentId: 4 },
  { text: "##EmployeeMailingAddress##", parentId: 4 },
  { text: "##EmployeeTaxID##", parentId: 4 },
  { text: "##EmployeeDateOfBirth##", parentId: 4 },
  { text: "##EmployeeEmail##", parentId: 4 },
  { text: "##EmployeePhone##", parentId: 4 },
  { text: "##EmployeeFirstName##", parentId: 4 },
  { text: "##EmployeeLastName## ", parentId: 4 },
  { text: "Insured", isTitle: true, identification: 5 },
  { text: "##InsuredNumber##", parentId: 5 },
  { text: "##InsuredName##", parentId: 5 },
  { text: "##InsuredTaxID##", parentId: 5 },
  { text: "##InsuredAddress##", parentId: 5 },
  { text: "##InsuredMailingAddress##", parentId: 5 },
  { text: "##InsuredPhone##", parentId: 5 },
  { text: "##InsuredVehicleNumber##", parentId: 5 },
  { text: "##InsuredVINNumber##", parentId: 5 },
  { text: "##InsuredPropertyNumber##", parentId: 5 },
  { text: "##InsuredPropertyLocation##", parentId: 5 }
];
