import React, { useState, useContext, useEffect, memo, createRef } from "react";
import { MTableBody } from "material-table";
import { Grid, Paper, Typography, TableRow, TableCell, TableFooter, IconButton } from "@material-ui/core";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import recoveryActions, { getRecovery } from "../../../../redux/actions/claim/financial";
import { useLoading } from "../../../common/Loading";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import { setExportTitleFileName, useRouteParam } from "../../../../common";
import LinkButton from "../../../common/LinkButton";
import { useListStyles } from "./styles";
import useCustomConfirm from "../../../common/customHooks/useCustomConfirm";
import BtnMenues from "../../../common/menu/ButtonMenu";
import MaterialTable from "../../../common/table/MaterialTable";

const RecoveryList = memo(({ editRecovery, handleTypeSelected }) => {
  const claimId = useRouteParam("claimId");
  const selectorData = useSelector(
    ({ common: { recoveryTypes }, claim }) => ({
      recoveryTypes,
      claim
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [confirm, setConfirm] = useState({ openConfirm: false, toRemove: {} });
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm();

  const { actions, Loading } = useLoading(true);
  const { hideLoading } = actions;
  const {
    claim: {
      financial: { recoveryList },
      claim: { number: claimNumber }
    },
    recoveryTypes
  } = selectorData || {};

  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "financial");
  const classes = useListStyles();
  const { create, update } = access || {};
  useEffect(() => {
    if (access.read) {
      dispatch(getRecovery(claimId, hideLoading, hideLoading));
    }
  }, []);

  const getButtonToolBar = () =>
    create && (
      <Grid item>
        <BtnMenues menuName=" ADD RECOVERY" subMenues={recoveryTypes} menuId="recovery" onClick={option => handleTypeSelected(option)} />
      </Grid>
    );

  const tableRef = createRef();

  const getTotal = list => (list || recoveryList).reduce((acc, b) => acc + (b?.actual || 0), 0).toFixed(2);

  const handleConfirmDelete = data => {
    handleCustomConfirm({
      titleText: `Delete Recovery`,
      messageText: `The following Recovery is going to be deleted. Are you sure you want to proceed?`,
      onAction: () => {
        const { deleteRecovery } = recoveryActions;
        dispatch(deleteRecovery({ id: data.id }));
      }
    });
  };

  const tableProps = {
    title: "Recoveries",
    columns: [
      {
        title: "Type",
        field: "type",
        render: rowData => {
          return (
            <LinkButton blue onClick={() => (update ? editRecovery(rowData) : {})}>
              {rowData.type}
            </LinkButton>
          );
        },
        width: null,
        cellStyle: { whiteSpace: "nowrap", paddingRight: 0, width: 200 }
      },
      {
        title: "Status",
        field: "status",
        width: null,
        cellStyle: { whiteSpace: "nowrap", paddingRight: 0, width: 200 }
      },
      {
        title: "Actual",
        field: "actual",
        render: rowData => <span>{`$ ${(rowData?.actual || 0).toFixed(2)}`}</span>,
        width: null,
        cellStyle: { whiteSpace: "nowrap", paddingRight: 0, width: 200 }
      },
      {
        title: "",
        render: rowData => (
          <>
            <Grid container wrap="nowrap">
              <Grid item>
                <IconButton
                  // className={classIcon.table}
                  onClick={() => {
                    handleConfirmDelete(rowData);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </>
        )
      }
    ],
    data: recoveryList,
    options: { actionsColumnIndex: -1, exportButton: true, exportFileName: setExportTitleFileName(`${claimNumber}_Recoveries`) },
    tableRef,
    componentsAttr: {
      Body: tProps => (
        <>
          <MTableBody {...tProps} />
          <TableFooter>
            <TableRow style={{ backgroundColor: "#DAEAEF" }}>
              <TableCell />
              <TableCell colSpan={1} align="right">
                <Typography className="tc-bold tc-label tc-blue tc-nowrap">Subtotal</Typography>
              </TableCell>
              <TableCell align="left" colSpan={2}>
                <Typography className="tc-bold tc-label tc-blue tc-nowrap">{`$ ${getTotal(recoveryList)}`}</Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </>
      )
    },
    ButtonToolBar: getButtonToolBar
  };

  const confirmConfig = {
    title: "Recovery",
    messageText: "The Recovery will be deleted. Are you sure you want to proceed?",
    onToggleConfirm: () => setConfirm({ ...confirm, openConfirm: !confirm.openConfirm }),
    onAction: () => {
      setConfirm({ openConfirm: !confirm.openConfirm, toRemove: {} });
    },

    openDialog: confirm.openConfirm
  };

  return (
    <div>
      {Loading}
      <Paper className={classes.paperContainer}>
        <MaterialTable {...tableProps} />
      </Paper>
      <Confirm {...confirmConfig} />
    </div>
  );
});

RecoveryList.propTypes = {
  editRecovery: PropTypes.func.isRequired,
  handleTypeSelected: PropTypes.func.isRequired
};

export default RecoveryList;
