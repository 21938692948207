import { Paper, Grid, Avatar } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setUser } from "../../../../redux/actions/claim";
import { useActionsAllowedContext } from "../../../common/contexts/ActionsAllowed";
import useCustomConfirm from "../../../common/customHooks/useCustomConfirm";
import { CustomSelect } from "../../../common/inputs";
import LabelValue from "../../../common/LabelValue";
import { useTeamInfoStyles } from "./index.styles";

export default () => {
  const classes = useTeamInfoStyles();
  const store = useSelector(
    ({
      claim: { claim },
      common,
      admin: {
        claimSettings: { widgets }
      }
    }) => ({ claim, common, widgets }),
    shallowEqual
  );
  const {
    claim: { id, adjusterId, supervisorId, assistantId },
    common: { adjusterUsers, supervisorUsers, assistantUsers },
    widgets
  } = store;
  const activity = Array.isArray(widgets) && widgets.some(w => w.name.toLowerCase() === "activity" && w.isActive);
  const [state, setState] = useState({ adjusterId, supervisorId, assistantId });
  const dispatch = useDispatch();
  const onSuccess = typeUser => () => {
    toast.success(`${typeUser} successfully replaced.`);
  };
  const onError = typeUser => () => {
    toast.error(`Unable to replace the ${typeUser}.`);
  };
  const handleSave = ({ name, value, typeUser }) => {
    setState({ ...state, [name]: value });
    dispatch(setUser({ ...state, [name]: value }, onSuccess(typeUser), onError(typeUser)));
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleSave, "Cancel", "Yes, Replace");

  const handleConfirm = (e, typeUser) => {
    handleCustomConfirm({
      oData: { ...e.target, typeUser },
      titleText: `${typeUser} replacement`,
      messageText: `Are you sure you want to replace the ${typeUser}?`
    });
  };
  useEffect(() => {
    setState({ id, adjusterId, supervisorId, assistantId });
  }, [id, adjusterId, supervisorId, assistantId]);

  const some = l => l.some(() => true);
  const Item = prs => (
    <Grid item xs>
      <Grid container spacing={2} alignItems="center" className={classes.container}>
        <Grid item>
          <Avatar>
            <Person />
          </Avatar>
        </Grid>
        <Grid item className={classes.item} {...prs} />
      </Grid>
    </Grid>
  );
  const handleClear = (name, typeUser) => {
    handleCustomConfirm({
      oData: { name, value: null, typeUser },
      titleText: `${typeUser} clear`,
      messageText: `Are you sure you want to clear the ${typeUser}?`
    });
  };
  const actionsAllowed = useActionsAllowedContext();
  const update = actionsAllowed.some(a => a.route === "overview" && a.update);
  return (
    <Paper className={classes.root}>
      <Grid container spacing={activity ? 0 : 4} direction={activity ? "column" : "row"}>
        {[
          [adjusterUsers, "Adjuster", "adjusterId"],
          [supervisorUsers, "Supervisor", "supervisorId"],
          [assistantUsers, "Assistant", "assistantId"]
        ].map(([options, label, name]) => (
          <Item key={name}>
            {update && some(options) && (
              <CustomSelect
                {...{ label, name }}
                value={state[name] || ""}
                onChange={e => handleConfirm(e, label)}
                options={adjusterUsers}
                onClear={() => handleClear(name, label)}
              />
            )}
            {!update && <LabelValue {...{ label }} className="" value={options.find(o => o.id === state[name])?.text} />}
          </Item>
        ))}
      </Grid>
      <Confirm />
    </Paper>
  );
};
