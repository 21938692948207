import { Grid, Typography } from "@material-ui/core";
import { Business, Person, Room } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import ClaimProcessingStatus from "../../../claims/ClaimProcessingStatus";
import ClaimStatus from "../../../claims/ClaimStatus";
import LossTypeGroupIcon from "../../../claims/LossTypeGroupIcon";
import LabelValue from "../../../common/LabelValue";

const iconText = (icon, text) => (
  <Grid container spacing={1} alignItems="flex-start" wrap="nowrap">
    {icon && <Grid item>{icon}</Grid>}
    <Grid item className="tc-pt10px">
      {text}
    </Grid>
  </Grid>
);

const ClaimInfo = ({ claim }) => {
  const lbClasses = val => ({ classes: { root: "tc-mb0", ...(val ? { value: "tc-pt12px" } : {}) } });
  return (
    <>
      <Typography className="tc-title">Claim</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item style={{ fontSize: 24, fontWeight: 500 }}>
              {claim.number}
            </Grid>
            <Grid item>
              <ClaimStatus status={claim.status} />
            </Grid>
            <Grid item>
              <ClaimProcessingStatus processingStatus={claim.processingStatus} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <LabelValue
            label="Claimant"
            value={iconText((claim.isClaimantPerson && <Person />) || <Business />, claim.claimantName)}
            {...lbClasses()}
          />
        </Grid>
        <Grid item xs={3}>
          <LabelValue label="Insured" value={claim.insuredName} {...lbClasses(true)} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <LabelValue label="Line Of Business" value={claim.claimLineOfBusinessCode} {...lbClasses(true)} />
        </Grid>
        <Grid item xs={8}>
          <LabelValue
            label="Loss Type Group"
            value={iconText(<LossTypeGroupIcon type={claim.claimLossTypeGroup} />, claim.claimLossTypeGroup)}
            {...lbClasses()}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <LabelValue label="Date of Loss" value={claim.dateOfLoss} isDate {...lbClasses(true)} />
        </Grid>
        <Grid item xs={5}>
          <LabelValue label="Date Reported" value={claim.dateReported} isDate {...lbClasses(true)} />
        </Grid>
        <Grid item xs={3}>
          <LabelValue label="Jurisdiction State" value={iconText(<Room />, claim.stateCode)} {...lbClasses()} />
        </Grid>
      </Grid>
    </>
  );
};

ClaimInfo.propTypes = { claim: PropTypes.objectOf(PropTypes.any).isRequired };

export default ClaimInfo;
