const RegexFilter = (q, item) => {
  const escapeRegExp = s => s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  const words = q
    .split(/\s+/g)
    .map(s => s.trim())
    .filter(s => !!s);
  const hasTrailingSpace = q.endsWith(" ");
  const buildRegex = (word, i) => {
    if (i + 1 === words.length && !hasTrailingSpace)
      // The last word - ok with the word being "startswith"-like
      return `(?=.*\\b${escapeRegExp(word)})`;
    // Not the last word - expect the whole word exactly
    return `(?=.*\\b${escapeRegExp(word)}\\b)`;
  };
  const searchRegex = new RegExp(`${words.map(buildRegex).join("")}.+`, "gi");
  return searchRegex.test(item);
};

export default RegexFilter;
