import { CLIENTR as CLIENT } from "../actionTypes";

export const get = (onSuccess, onError) => ({ type: CLIENT.LIST.GET, onSuccess, onError });

export const getSuccess = clients => ({ type: CLIENT.LIST.GET_SUCCESS, clients });

export const getByParentId = (parentId, onSuccess, onError) => ({ type: CLIENT.LIST.GET_ALL_BY_PARENT, parentId, onSuccess, onError });

export const getByParentIdSuccess = data => ({ type: CLIENT.LIST.GET_ALL_BY_PARENT_SUCCESS, data });

export const add = ({ data, onSuccess, onError }) => ({ type: CLIENT.ADDCLIENT.POST, data, onSuccess, onError });

export const addClientSuccess = data => ({ type: CLIENT.ADDCLIENT.POST_SUCCESS, data });

export const changeEntitysParent = (data, onSuccess, onError) => ({ type: CLIENT.LIST.MOVE_ENTITY_TO_NEW_PARENT, data, onSuccess, onError });

export const changeEntitysParentSuccess = clients => ({
  type: CLIENT.LIST.MOVE_ENTITY_TO_NEW_PARENT_SUCCESS,
  clients
});

export const del = ({ client, onSuccess, onError }) => ({ type: CLIENT.LIST.DEL, client, onSuccess, onError });

export const delSuccess = clients => ({ type: CLIENT.LIST.DEL_SUCCESS, clients });
