export default {
  START_OVER: "START_OVER",
  PREV_STEP: "PREV_STEP",
  NEXT_STEP: "NEXT_STEP",
  TOGGLE_DUPLICATE: "TOGGLE_DUPLICATE",
  SET_MULTIPLE_CLAIMANTS: "SET_MULTIPLE_CLAIMANTS",
  SET_DUPLICATES: "SET_DUPLICATES",
  ADD_CLAIMANT: "ADD_CLAIMANT",
  DELETE_CLAIMANT: "DELETE_CLAIMANT",
  SET_INIT_COVERAGE_TYPES: "SET_INIT_COVERAGE_TYPES",
  SET_CLAIMANT: "SET_CLAIMANT",
  SET_INSURED: "SET_INSURED",
  SET_INS_VEHICLES: "SET_INS_VEHICLES",
  SELECT_INS_VEHICLE: "SELECT_INS_VEHICLE",
  SET_INS_PROPERTIES: "SET_INS_PROPERTIES",
  SET_INS_PROPERTY: "SET_INS_PROPERTY",
  SET_INCIDENT: "SET_INCIDENT",
  SET_OCCURRENCES: "SET_OCCURRENCES",
  SET_LINE_OF_BUSINESS: "SET_LINE_OF_BUSINESS",
  SET_DAMAGED_PROPERTY: "SET_DAMAGED_PROPERTY",
  SET_BODILY_INJURY: "SET_BODILY_INJURY",
  SET_DAMAGED_VEHICLE: "SET_DAMAGED_VEHICLE",
  SET_INS_VEHICLE: "SET_INS_VEHICLE",
  SET_INS_VEHICLE_DRIVER: "SET_INS_VEHICLE_DRIVER",
  SET_INS_VEHICLE_DRIVER_LOCATION: "SET_INS_VEHICLE_DRIVER_LOCATION",
  SET_WITNESS: "SET_WITNESS",
  DELETE_WITNESS: "DELETE_WITNESS",
  SET_DOCUMENTS: "SET_DOCUMENTS",
  DEL_DOCUMENT: "SET_DOCUMENT",
  SET_DOCUMENTS_UPLOAD: "SET_DOCUMENTS_UPLOAD",
  SET_ATTORNEY: "SET_ATTORNEY",
  DELETE_ATTORNEY: "DELETE_ATTORNEY",
  SET_CREATE_AS: "SET_CREATE_AS",
  SET_USER: "SET_USER",
  SET_FOLDER_SETTINGS: "SET_FOLDER_SETTINGS",
  SET_LAWYERS_FIRMS: "SET_LAWYERS_FIRMS",
  SET_SOURCE_INIT_REPORT: "SET_SOURCE_INIT_REPORT"
};
