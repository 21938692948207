import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  IconButton,
  Divider,
  ListItemText,
  Grid,
  Typography,
  Button
} from "@material-ui/core";
import { Delete as DeleteIcon, AddBoxRounded as AddBtn } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper
  }
}));
const InteractiveList = ({ items, title, Header, addAction, deleteAction, hideDivider, addBtnText, ...others }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {(!!title || !!addAction) && (
        <Grid container style={{ paddingLeft: 0, paddingRight: 16 }} alignItems="center">
          <Grid item xs={10}>
            <Typography className="tc-title">{title}</Typography>
            {!!Header && <Header />}
          </Grid>
          {!!addAction && (
            <Grid item xs={2}>
              <Grid container justify="flex-end" spacing={1}>
                <Grid item>
                  {!addBtnText ? (
                    <IconButton edge="end" aria-label="Add" onClick={addAction}>
                      <AddBtn />
                    </IconButton>
                  ) : (
                    <Button variant={others.btnAddContained ? "contained" : "outlined"} color="primary" onClick={addAction}>
                      {addBtnText}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      <List>
        {items.map(elem => (
          <div key={`${elem.key}`}>
            <ListItem style={{ paddingLeft: 0 }}>
              {!!elem.icon && (
                <ListItemAvatar>
                  <Avatar style={{ color: "#014b65" }}>{elem.icon}</Avatar>
                </ListItemAvatar>
              )}
              <ListItemText primary={elem.primary} secondary={elem.secondary} />
              {!!deleteAction && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      deleteAction(elem);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            {hideDivider && <Divider component="li" />}
          </div>
        ))}
      </List>
    </div>
  );
};

InteractiveList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  others: PropTypes.shape({}),
  addAction: PropTypes.func,
  deleteAction: PropTypes.func,
  hideDivider: PropTypes.bool,
  Header: PropTypes.func,
  addBtnText: PropTypes.string
};

InteractiveList.defaultProps = {
  title: "",
  others: {},
  addAction: undefined,
  deleteAction: undefined,
  hideDivider: false,
  Header: undefined,
  addBtnText: ""
};

export default InteractiveList;
