import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, FormHelperText } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { HocInput } from "./HocInput";

export const BaseMultipleSelectInput = props => {
  const { id, label, name, onChange, options, value, renderOptionDesc, renderOptions, renderValue, renderValueDesc, error, ...other } = props;
  const inputId = id || `select-multiple-${name}`;
  return (
    <FormControl fullWidth {...other} error={Boolean(error)}>
      {label && <InputLabel htmlFor={inputId}>{label}</InputLabel>}
      <Select
        id={inputId}
        multiple
        MenuProps={{ PaperProps: { style: { maxHeight: 232, width: 250 } } }}
        {...{
          value,
          name,
          options,
          onChange,
          error: Boolean(error),
          renderValue: selected => {
            if (typeof renderValue !== "undefined") return renderValue(selected, options);
            return options
              .reduce((acc, op) => {
                if (selected.some(item => item === op.id)) return [...acc, renderValueDesc ? op.description : op.text];
                return acc;
              }, [])
              .join(", ");
          }
        }}
      >
        {(typeof renderOptions !== "undefined" && renderOptions(options, value)) ||
          options.map(op => (
            <MenuItem key={`${inputId}-item-${op.id}`} value={op.id}>
              <Checkbox checked={value.includes(op.id)} />
              <ListItemText primary={renderOptionDesc ? op.description : op.text} />
            </MenuItem>
          ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

BaseMultipleSelectInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  renderOptionDesc: PropTypes.bool,
  renderOptions: PropTypes.func,
  renderValue: PropTypes.func,
  renderValueDesc: PropTypes.bool,
  error: PropTypes.string
};

BaseMultipleSelectInput.defaultProps = {
  id: undefined,
  label: undefined,
  onChange: undefined,
  renderOptionDesc: false,
  renderOptions: undefined,
  renderValue: undefined,
  renderValueDesc: false,
  error: undefined
};

export default HocInput(BaseMultipleSelectInput);
