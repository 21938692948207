import diary from "./diary";
import claimWithoutActivity from "./claimWithoutActivity";
import claimsByAdjuster from "./claimsByAdjuster";
import claimsLossesByAdjuster from "./claimsLossesByAdjuster";
import claimsLossesByAdjusterDetail from "./claimsLossesByAdjusterDetail";
import payments from "./payments";
import rootGenerator from "../common/rootSagaGenerator";

export default rootGenerator({
  name: "dashboard",
  sagas: [...diary, ...claimWithoutActivity, ...claimsByAdjuster, ...claimsLossesByAdjuster, ...claimsLossesByAdjusterDetail, ...payments]
});
