import React, { useContext, useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { DateTimeInput, TextInput, SelectInput } from "../common/inputs";
import InputSuggestions from "../common/SearchSuggestions/InputSuggestions";
import { FormContext } from "../common/contexts/FormContext";
import { API } from "../../redux/actions/actionTypes";
import { api } from "../../api/apiUtils";
import AssociatedList from "./AssociatedList";
import Footer from "../common/FormFooter";
import { useDebounce } from "../../common";
import useYupValidationSchema from "../common/customHooks/useYupValidationSchema";
import schema from "./yup";
import ActionsFooter, { footerAction } from "../common/ActionsFooter";

const Form = ({ actionCancel, actionSave, isAdd, search, searchBy, loading }) => {
  const { state, dispatch } = useContext(FormContext);
  const [isLoad, setIsLoad] = useState(false);
  const { handleValidateSchema, validationMessages } = useYupValidationSchema(schema);
  const onChange = e => {
    const { name, value } = e.target;
    dispatch({ type: "PUT_FIELD", payload: { name, value } });
    if (value) {
      if (searchBy.indexOf(name) > -1) {
        search();
      }
    }
  };
  const { states } = useSelector(store => store.common, shallowEqual);
  const [insured, setInsured] = useState({
    id: null,
    name: "",
    results: [],
    loading: false
  });

  const searchInsured = useDebounce((field, query) => {
    api({ methhod: "GET", url: `${API.URL}/insured/find`, data: { field, query } })
      .then(data => setInsured(ps => ({ ...ps, results: data, loading: false })))
      .catch(() => setInsured(ps => ({ ...ps, loading: false })));
  }, 500);

  const onChangeInsured = ({ target: { name: field, value: query } }) => {
    let load = false;
    if ((query || "").length >= 3) {
      load = true;
      searchInsured(field, query);
    }
    dispatch({ type: "PUT_FIELD", payload: { name: "insuredName", value: query } });
    setInsured(prevInsu => ({ ...prevInsu, [field]: query, loading: load }));
  };
  const onSelect = ({ id }) => {
    if (!insured.results.some(x => x.id === id)) return setInsured(ps => ({ ...ps }));
    const op = insured.results.filter(x => x.id === id)[0];
    dispatch({ type: "PUT_FIELD", payload: { name: "insuredName", value: op.name } });
    dispatch({ type: "PUT_FIELD", payload: { name: "insuredId", value: op.id } });
    return {};
  };
  useEffect(() => {
    handleValidateSchema(state);
    if (state.id && !isLoad) {
      search();
      setIsLoad(true);
    }
  }, [state]);
  const handleSave = () => {
    const { isValid } = handleValidateSchema(state);
    if (isValid) actionSave();
  };
  return (
    <>
      {isAdd && (
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className="tc-title">New Occurrence</Typography>
          </Grid>
        </Grid>
      )}
      {!isAdd && (
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography className="tc-title">{`Occurrence #${state.number}`}</Typography>
          </Grid>
          {state.catastropheNumber && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography className="tc-title">
                <Link to={`/catastrophe/${state.catastropheId}`}>{`Catastrophe: #${state.catastropheNumber}`}</Link>
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
      <Grid container spacing={8}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <TextInput name="name" label="Name" value={state.name} onChange={onChange} error={validationMessages.name} />
          <TextInput name="address1" label="Address1" value={state.address1} onChange={onChange} />
          <TextInput name="city" label="City" value={state.city} onChange={onChange} />
          <TextInput name="county" label="County" value={state.county} onChange={onChange} />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <DateTimeInput name="date" label="Date" value={state.date} onChange={onChange} keyboard error={validationMessages.date} disableFuture />
          <TextInput name="address2" label="Address2" value={state.address2} onChange={onChange} />
          <SelectInput name="stateId" label="State" value={state.stateId} options={states} onChange={onChange} error={validationMessages.stateId} />
          <TextInput name="zipCode" label="Zip Code" value={state.zipCode} onChange={onChange} />
        </Grid>
      </Grid>
      <InputSuggestions
        name="name"
        value={state.insuredName || ""}
        label="Insured Name"
        onChange={onChangeInsured}
        loading={insured.loading}
        {...{ onSelect, results: insured.results }}
      />
      <TextInput name="description" label="Description" value={state.description} onChange={onChange} rows={4} />
      {!isAdd && <AssociatedList search={search} />}
      {(isAdd && (
        <ActionsFooter
          elementId="occurrenceFooter"
          shadow
          actions={[
            footerAction({ id: "cancel", text: "Cancel", outlined: true, primary: true, onClick: actionCancel }),
            footerAction({ id: "save", text: "Add", primary: true, onClick: handleSave, disabled: loading })
          ]}
        />
      )) || <Footer type="button" editMode {...{ onCancel: actionCancel, onClick: handleSave }} />}
    </>
  );
};

Form.propTypes = {
  actionSave: PropTypes.func.isRequired,
  actionCancel: PropTypes.func.isRequired,
  search: PropTypes.func,
  isAdd: PropTypes.bool,
  searchBy: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool
};

Form.defaultProps = {
  search: () => {},
  searchBy: [],
  isAdd: false,
  loading: false
};
export default Form;
