import { GetApp, ZoomIn, ZoomOut, ZoomOutMap } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { Fragment, memo } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ViewerTools from "./Tools";

const ImageViewer = ({ actions, onDownload, hideDownload, filename, url }) => {
  const prevent = e => e.preventDefault();
  return (
    <TransformWrapper options={{ wrapperClass: "tc-page-content" }}>
      {({ zoomIn, zoomOut, resetTransform }) => (
        <Fragment key="fTrasformContainer">
          <ViewerTools
            actions={[
              ...actions,
              ...(hideDownload ? [] : [{ key: "btn-download", icon: <GetApp />, onClick: onDownload, tooltip: "Download File" }]),
              { key: "btn-zoom-in", icon: <ZoomIn />, onClick: zoomIn, tooltip: "Zoom In" },
              { key: "btn-zoom-out", icon: <ZoomOut />, onClick: zoomOut, tooltip: "Zoom Out" },
              { key: "btn-zoom-reset", icon: <ZoomOutMap />, onClick: resetTransform, tooltip: "Fit to container" }
            ]}
          />
          <TransformComponent>
            <img onContextMenu={prevent} onDragStart={prevent} src={url} alt={filename} className="tc-viewer-image" />
          </TransformComponent>
        </Fragment>
      )}
    </TransformWrapper>
  );
};

ImageViewer.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  hideDownload: PropTypes.bool,
  onDownload: PropTypes.func.isRequired,
  filename: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

ImageViewer.defaultProps = { actions: [], hideDownload: false };

export default memo(ImageViewer);
