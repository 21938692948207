import React, { useContext, Fragment } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { SelectInput, InputText, MoneyInput } from "../inputs";
import { PropertyContext } from "./PropertyContext";

const Damaged = () => {
  const { state, dispatch } = useContext(PropertyContext);
  const { propertyDamagedLossTypes, departamentTypes, propertyDamagedTypes } = useSelector(store => store.common);

  const { propertyDamagedLossTypeId, departamentTypeId, reportNumber, propertyDamagedTypeId, amountEntireLoss, description } = state.damaged || {};
  // const { name, email, phone, mainContactName } = state.property.mortgageCompany || {};

  const handleChangeDamaged = e => {
    dispatch({ type: "SET_DAMAGED", payload: { name: e.target.name, value: e.target.value } });
  };

  return (
    <Fragment key="damaged-form-component">
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <SelectInput
            name="propertyDamagedLossTypeId"
            label="Kind of Loss"
            value={propertyDamagedLossTypeId}
            options={propertyDamagedLossTypes}
            onChange={handleChangeDamaged}
          />
          <SelectInput
            name="propertyDamagedTypeId"
            label="Damage Type Reported"
            value={propertyDamagedTypeId}
            onChange={handleChangeDamaged}
            options={propertyDamagedTypes}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            name="departamentTypeId"
            label="Reported to"
            value={departamentTypeId}
            options={departamentTypes}
            onChange={handleChangeDamaged}
          />
          <MoneyInput name="amountEntireLoss" label="Probable Amount Entire Loss" value={amountEntireLoss} onChange={handleChangeDamaged} />
        </Grid>
        <Grid item xs={4}>
          <InputText name="reportNumber" label="Report Number" value={reportNumber} onChange={handleChangeDamaged} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <InputText name="description" label="Property Damage description" value={description} onChange={handleChangeDamaged} rows={4} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Damaged;
