import React, { Fragment, useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import HearingInfo from "./HearingInfo";
import LegalTrialInfo from "./LegalTrialInfo";
import SettledInfo from "./settled/SettledForm";
import MediationArbitration from "./MediationArbitration";
import useOnToggleBoxes from "../../../common/customHooks/useHandleBox";
import useAxios from "../../../common/customHooks/useAxios";
import {
  getClaimantAttorney,
  postClaimantAttorney,
  setClaimantAttorney,
  delClaimantAttorney,
  getDefendantAttorney,
  postDefendantAttorney,
  setDefendantAttorney,
  delDefendantAttorney
} from "../../../../redux/actions/claim/legal/attorney";
import ClaimAttorney from "./ClaimAttorney";
import ExpandAll from "../../../common/ExpandAll";

export default () => {
  const [{ firms, lawyers }, setAttorneyInformation] = useState({ firms: [], lawyers: [] });
  const store = useSelector(({ claim: { claimantAttorneys: c, defendantAttorneys: d } }) => ({ c, d }), shallowEqual);

  const { c: claimantAttorneys, d: defendantAttorneys } = store || {};
  const { open, onToggle, expandProps } = useOnToggleBoxes({
    initialValue: {
      attorney: true,
      defendant: true,
      hearing: true,
      trial: true,
      settled: true,
      mediationArbitration: true
    }
  });
  const { sendRequest } = useAxios();

  useEffect(() => {
    sendRequest({
      request: [
        { method: "GET", url: `/attorney` },
        { method: "GET", url: `/lawfirm/getAll` }
      ],
      onSucces: response => {
        if (response.length <= 1) return;
        setAttorneyInformation({ lawyers: response[0].data.map(item => ({ ...item, hasFirm: Boolean(item.lawFirmId) })), firms: response[1].data });
      },
      multiple: true
    });
  }, []);

  return (
    <Fragment key="claim-legal-page">
      <ExpandAll elementId="claimTopContainer" className="tc-ml1" {...expandProps} />
      <ClaimAttorney
        {...{ firms, lawyers, onToggle }}
        data={claimantAttorneys}
        actions={{ get: getClaimantAttorney, post: postClaimantAttorney, set: setClaimantAttorney, del: delClaimantAttorney }}
        open={open.attorney}
        title="Claimant Attorney Information"
        name="attorney"
      />
      <ClaimAttorney
        {...{ firms, lawyers, onToggle }}
        data={defendantAttorneys}
        actions={{ get: getDefendantAttorney, post: postDefendantAttorney, set: setDefendantAttorney, del: delDefendantAttorney }}
        open={open.defendant}
        title="Defendant Attorney Information"
        name="defendant"
      />
      <MediationArbitration {...{ open: open.mediationArbitration, onToggle }} />
      <HearingInfo {...{ open: open.hearing, onToggle }} />
      <LegalTrialInfo {...{ open: open.trial, onToggle }} />
      <SettledInfo claimantAttorneys={claimantAttorneys} {...{ open: open.settled, onToggle }} />
    </Fragment>
  );
};
