import React, { Fragment, memo, useContext } from "react";
import { Grid, Divider } from "@material-ui/core";
import LabelValue from "../common/LabelValue";
import { FormContext } from "../common/contexts/FormContext";
import AssociatedList from "./AssociatedList";

const Read = memo(() => {
  const { state } = useContext(FormContext);
  return (
    <Fragment key="occurrence-read">
      <Grid container spacing={4}>
        <Grid item xs>
          <LabelValue label="Name" value={state.name} />
          <LabelValue label="Address" value={state} isAddress />
        </Grid>
        <Grid item xs>
          <LabelValue label="Date" value={state.date} isDate />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs>
          <LabelValue label="Description" value={state.description} />
        </Grid>
      </Grid>
      <Divider />
      <AssociatedList editMode={false} />
    </Fragment>
  );
});

export default Read;
