export const getActions = path => ({
  GET: `GET_${path}`,
  GET_SUCCESS: `GET_${path}_SUCCESS`
});

export const getAllActions = path => ({
  GETALL: `GETALL_${path}`,
  GETALL_SUCCESS: `GETALL_${path}_SUCCESS`
});

export const setActions = path => ({
  SET: `SET_${path}`,
  SET_SUCCESS: `SET_${path}_SUCCESS`
});

export const postActions = path => ({
  POST: `POST_${path}`,
  POST_SUCCESS: `POST_${path}_SUCCESS`
});

export const delActions = path => ({
  DEL: `DEL_${path}`,
  DEL_SUCCESS: `DEL_${path}_SUCCESS`
});

export const baseActions = (path, get = true, set = true, post = true, del = true) => ({
  ...(get ? getActions(path) : {}),
  ...(set ? setActions(path) : {}),
  ...(post ? postActions(path) : {}),
  ...(del ? delActions(path) : {})
});

export const documents = path => ({
  GET: `GET_${path}DOCUMENTS`,
  GET_SUCCESS: `GET_${path}DOCUMENTS_SUCCESS`,
  DEL_FILE: `DEL_${path}FILE`,
  DEL_FILE_SUCCESS: `DEL_${path}FILE_SUCCESS`,
  SET_FILE: `SET_${path}FILE`,
  SET_FILE_SUCCESS: `SET_${path}FILE_SUCCESS`,
  POST_FOLDER: `POST_${path}FOLDER`,
  POST_FOLDER_SUCCESS: `POST_${path}FOLDER_SUCCESS`,
  SET_FOLDER: `SET_${path}FOLDER`,
  SET_FOLDER_SUCCESS: `SET_${path}FOLDER_SUCCESS`,
  DEL_FOLDER: `DEL_${path}FOLDER`,
  DEL_FOLDER_SUCCESS: `DEL_${path}FOLDER_SUCCESS`,
  POST_FILES: `POST_${path}FILES`,
  POST_FILES_SUCCESS: `POST_${path}FILES_SUCCESS`,
  DOWNLOAD_FILES: `DOWNLOAD_${path}FILES`,
  DOWNLOAD_FILES_SUCCESS: `DOWNLOAD_${path}FILES_SUCCESS`,
  OPEN_FILE: `OPEN_${path}FILE`,
  OPEN_FILE_SUCCESS: `OPEN_${path}FILE_SUCCESS`
});
