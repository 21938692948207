import { Grid, IconButton, Paper } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import moment from "moment";
import PropTypes from "prop-types";
import React, { memo, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import Dropzone from "./Dropzone";
import Confirm from "../../../common/Confirm";
import FileIcon from "../../../common/documents/FileIcon";
import LinkButton from "../../../common/LinkButton";
import MaterialTable from "../../../common/table/MaterialTable";
import useViewer from "../../../common/viewer/useViewer";

const List = memo(({ actions: { deleteFile, uploadFiles, openFile, onIndex }, isReindex, access, exportFileName }) => {
  const documents = useSelector(({ documents: { [`${isReindex ? "re" : ""}indexing`]: d } }) => d, shallowEqual);
  const [{ delFileConf, openDialog }, setState] = useState({ openDialog: false });
  const { onViewFile, fileViewer } = useViewer({ dispatchAction: openFile });
  const tableProps = {
    title: "Processing Queue",
    columns: [
      {
        title: "Name",
        field: "fileName",
        render: row => (
          <LinkButton onClick={() => onViewFile(row.id, { indexingStatusId: row.statusId })}>
            <Grid container spacing={1} alignItems="center" wrap="nowrap">
              <Grid item>
                <FileIcon file type={row.fileType} />
              </Grid>
              <Grid item>{row.fileName}</Grid>
            </Grid>
          </LinkButton>
        )
      },
      ...(isReindex
        ? [
            { title: "Date", field: "statusOn", render: ({ statusOn }) => moment(statusOn).format("M/D/YYYY - h:mm a") },
            {
              title: "Previous Claim",
              field: "claimNumber",
              render: row => {
                if (row?.claimAllowed) return <Link to={`/claim/${row.claimId}`}>{row.claimNumber}</Link>;
                return row.claimNumber;
              }
            },
            { title: "Reason to Re-Index", field: "reindexReason" }
          ]
        : [{ title: "Date", field: "uploadedOn", render: ({ uploadedOn }) => moment(uploadedOn).format("M/D/YYYY - h:mm a") }])
    ],
    data: documents,
    actions: ["update", "delete"].reduce((acc, elem) => {
      if (elem === "update" && access?.update)
        return [
          ...acc,
          {
            icon: p => (
              <LinkButton blue underline className="tc-bold tc-nowrap" {...p}>
                {`${isReindex ? "RE-" : ""}INDEX`}
              </LinkButton>
            ),
            ...{ custom: true, onClick: (_e, row) => onIndex(row) }
          }
        ];

      if (elem === "delete" && access?.delete)
        return [
          ...acc,
          {
            icon: p => (
              <IconButton {...p} className="tc-ml1">
                <Delete />
              </IconButton>
            ),
            ...{ custom: true, onClick: (_e, r) => setState(ps => ({ ...ps, delFileConf: r, openDialog: true })) }
          }
        ];
      return acc;
    }, []),
    hidePaging: documents.length <= 50,
    options: { pageSize: 50, exportFileName }
  };
  const confirmProps = {
    messageText: delFileConf && (
      <>
        <p>The following file will be deleted:</p>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item>
            <FileIcon file type={delFileConf.fileType} />
          </Grid>
          <Grid item>
            <b>{delFileConf.fileName}</b>
          </Grid>
        </Grid>
        <p>Are you sure to proceed?</p>
      </>
    ),
    title: "Delete File",
    onAction: () => {
      confirmProps.onToggleConfirm();
      if (typeof delFileConf === "undefined") return false;
      return deleteFile({ indexingStatusId: delFileConf.statusId }, delFileConf.id);
    },
    openDialog,
    onToggleConfirm: () => setState(ps => ({ ...ps, openDialog: false })),
    okText: "Yes - Delete",
    noText: "No - Cancel"
  };
  return (
    <div className="tc-page-content">
      <Paper className="tc-p2">
        {!isReindex && access?.create && <Dropzone onUpload={files => uploadFiles({ data: { files } })} />}
        <MaterialTable {...tableProps} />
        <Confirm {...confirmProps} />
      </Paper>
      {fileViewer}
    </div>
  );
});

List.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  isReindex: PropTypes.bool.isRequired,
  access: PropTypes.shape({}).isRequired,
  exportFileName: PropTypes.string
};

List.defaultProps = {
  exportFileName: ""
};
export default List;
