import * as yup from "yup";
import { dateSchema as date, isNumber, isBeforeToday, isAfterDate, beforeTodaySchema, isDate, isEmail } from "../../../common/yup";

const evalClaimant = (context, val, fnValidate, validated) => {
  const { sameAsInsured } = context?.parent || {};
  if (sameAsInsured || validated) return true;
  return typeof fnValidate === "function" ? fnValidate(val) : Boolean(val);
};
const evalLocation = (context, val, isMailing, fnValidate) => {
  const { sameAsInsured, phisicalAsMailing } = context.from[1].value;
  return evalClaimant(context, val, fnValidate, sameAsInsured || (isMailing && phisicalAsMailing));
};
const number = () => yup.number().nullable();
const testTreatment = (val, ctx) => !ctx?.parent?.treatmentReceived || isNumber(val);

export default yup.object().shape({
  insured: yup.object().shape({
    id: yup.number().required("Insured Required")
  }),
  employee: yup.object().shape({
    sameAsInsured: yup.boolean(),
    phisicalAsMailing: yup.boolean(),
    firstName: yup.string().test("isPerson", "First Name Required", (v, c) => evalClaimant(c, !c?.parent?.isPerson || v)),
    lastName: yup.string().test("isPerson", "Last Name Required", (v, c) => evalClaimant(c, !c?.parent?.isPerson || v)),
    dateofBirth: date("Date of Birth Required").test("isDate", "Date of Birth has to be before today.", v => isBeforeToday(v)),
    contact: yup.string().test("isNotPerson", "Contact Name Required", (v, c) => evalClaimant(c, c?.parent?.isPerson || v)),
    taxIDTypeId: yup.number().test("default", "Tax ID Type Required", (o, c) => evalClaimant(c, o, isNumber)),
    taxID: yup.string().test("default", "Tax ID Required", (o, c) => evalClaimant(c, o)),
    location: yup.object().shape({
      address1: yup.string().test("default", "Address 1 Required", (o, c) => evalLocation(c, o)),
      city: yup.string().test("default", "City Required", (o, c) => evalLocation(c, o)),
      stateId: yup.number().test("default", "State Required", (o, c) => evalLocation(c, o, undefined, isNumber)),
      zipCode: yup.string().test("default", "Zip Code Required", (o, c) => evalLocation(c, o))
    }),
    mailAddress: yup.object().shape({
      address1: yup.string().test("default", "Address 1 Required", (o, c) => evalLocation(c, o, true)),
      city: yup.string().test("default", "City Required", (o, c) => evalLocation(c, o, true)),
      stateId: yup.number().test("default", "State Required", (o, c) => evalLocation(c, o, true, isNumber)),
      zipCode: yup.string().test("default", "Zip Code Required", (o, c) => evalLocation(c, o, true))
    }),
    personalEmail: yup.string().test("", "Invalid Email", v => !v || isEmail(v))
  }),
  employment: yup.object().shape({
    jurisdictionStateId: yup.number().required("Jurisdiction State is Required"),
    payrollClassCodeTypeId: yup.number().required("Payroll Class Code is Required"),
    employmentStatusId: yup.number().required("Employment Status is Required")
  }),
  incident: yup.object().shape({
    employeeAccident: yup.object().shape({
      froiReceivedDate: beforeTodaySchema("FROI Received Date to be before today.")
    }),
    dateReported: date("Date Reported Required")
      .test("isDate", "Date reported has to be before today.", v => isBeforeToday(v))
      .test("isDateBeforeDate", "Date reported has to be after Date of Injury.", (v, c) => isAfterDate(v, c?.parent?.dateofLoss)),
    claimLossTypeId: yup.number().required("Loss Type Required"),
    claimCauseCodeId: yup.number().required("Cause Code Required"),
    dateofLoss: date("Date of Injury Required"),
    location: yup.object().shape({
      address1: yup
        .string()
        .nullable()
        .required("Address Required"),
      city: yup
        .string()
        .nullable()
        .required("City Required"),
      stateId: yup
        .number()
        .nullable()
        .required("State Required"),
      zipCode: yup
        .string()
        .nullable()
        .required("Zip Required")
    }),
    bodilyInjury: yup.object().shape({
      isDeath: yup.boolean().nullable(),
      claimantDateofDeath: date("Date of Death Required", (v, c) => !c?.parent?.isDeath || isDate(v)),
      injuryTypeId: yup.number().required("Injury Type Required"),
      parentBodyPartId: yup.number().required("Body Part Group Required"),
      bodyPartId: yup.number().required("Body Part Required"),
      treatmentReceived: yup.boolean(),
      treatmentTypeId: number().test("treatmentReceived", "Initial Treatment Type Required", testTreatment)
      // hospitalId: number().test("treatmentReceived", "Hospital Name Required", testTreatment)
    })
  }),
  createAsTeam: yup.object().shape({
    isReportOnly: yup.boolean().nullable(),
    adjusterUserId: yup
      .string()
      .nullable()
      .test("", "Adjuster Required", (val, ctx) => !!ctx.parent.isReportOnly || !!val),
    supervisorUserId: yup
      .string()
      .nullable()
      .test("", "Supervisor is Required", (val, ctx) => !!ctx.parent.isReportOnly || !!val)
  })
});
