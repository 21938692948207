import { CLIENTR as CLIENT, API } from "../../actions/actionTypes";
import { getLevelsSuccess, getBreadCrumbRoutesSuccess } from "../../actions/client/getLevels";
import { takeLatest } from "../common/sagaGenerator";

export default [
  takeLatest({
    type: CLIENT.LEVELS.GET,
    actionSuccess: getLevelsSuccess,
    errorMessage: "Error loading Client Structure",
    url: `${API.URL}/client/getLevelTab`,
    params: ({ clientId }) => ({ clientId })
  }),
  takeLatest({
    type: CLIENT.ROUTES.GET,
    actionSuccess: getBreadCrumbRoutesSuccess,
    errorMessage: "Error loading Client Routes",
    url: `${API.URL}/breadcrumb/routes`,
    params: ({ clientId }) => ({ clientId })
  })
];
