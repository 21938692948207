import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { Divider, Grid, Button, Collapse } from "@material-ui/core";
import useStyles from "../claim/claim.style";
import { DateTimeInput, SelectInput, TextInput, TimeInput, CheckboxLabel } from "../../inputs";
import LabelValue from "../../LabelValue";
import { BaseRadioButtonGroup } from "../../inputs/RadioButtonGroup";
import { filterByParentId, anyById } from "../../../../common";
import validationSchema from "./yup";
import useYupValidationSchema from "../../customHooks/useYupValidationSchema";

const WcClaimForm = ({ claim, onSave, onClose, isLoading, isReportOnly }) => {
  const classes = useStyles();
  const store = useSelector(
    ({
      common: {
        claimStatus,
        processingStatus,
        states,
        claimLossTypeGroups,
        claimLossTypes,
        claimCauseCodes,
        injuryType,
        bodyPart: bodyPartFull,
        bodyPartGroup,
        hospitalLocation,
        treatmentType,
        claimType,
        contactMethods,
        lossTimeStatus,
        ediClaimTypes,
        ncciInjuryCodes,
        typeOfCoverageCodes
      },
      claim: {
        insured: { location }
      }
    }) => ({
      status: claimStatus,
      processingStatus,
      states,
      typeGroups: claimLossTypeGroups,
      lossTypesFull: claimLossTypes,
      codes: claimCauseCodes,
      injuryType,
      bodyPart: bodyPartFull,
      bodyPartGroup,
      hospitalLocation,
      treatmentType,
      claimType,
      contactMethods,
      lossTimeStatus,
      ediClaimTypes,
      ncciInjuryCodes,
      typeOfCoverageCodes,
      insuredLocation: location
    }),
    shallowEqual
  );
  const {
    status,
    processingStatus,
    states,
    typeGroups,
    lossTypesFull,
    codes,
    injuryType,
    bodyPart: bodyPartFull,
    bodyPartGroup,
    hospitalLocation,
    treatmentType,
    claimType,
    contactMethods,
    lossTimeStatus,
    ediClaimTypes,
    ncciInjuryCodes,
    typeOfCoverageCodes,
    insuredLocation
  } = store;
  const [state, setState] = useState(claim || {});
  const [location, setLocation] = useState(claim?.location || {});
  const [employeeAccident, setEmployeeAccident] = useState(claim?.employeeAccident || {});
  const [bodilyInjury, setBodilyInjury] = useState(claim?.bodilyInjury || {});
  const [hospital, setHospital] = useState(claim?.bodilyInjury?.hospital || {});
  const [loaded, setLoaded] = useState(false);
  const { validationMessages, handleValidateSchema } = useYupValidationSchema(validationSchema);
  const handleValidation = () => handleValidateSchema({ ...state, employeeAccident, bodilyInjury, location });
  const { processingStatusId, claimLossTypeGroupId, claimLineOfBusinessId } = state;
  const lossTypeGroups = filterByParentId(typeGroups, claimLineOfBusinessId);
  const lossTypes = filterByParentId(lossTypesFull, claimLossTypeGroupId);
  const causeCodes = filterByParentId(codes, claimLossTypeGroupId);
  const isDenied = processingStatus.find(x => x.id === processingStatusId)?.text === "Denied";
  useEffect(() => {
    let newState = {};
    if (!anyById(lossTypeGroups, claimLossTypeGroupId)) {
      newState = { claimLossTypeGroupId: undefined, claimLossTypeId: undefined, claimCauseCodeId: undefined };
    } else {
      const { claimLossTypeId, claimCauseCodeId } = state;
      if (!anyById(lossTypes, claimLossTypeId)) newState = { ...newState, claimLossTypeId: undefined };
      if (!anyById(causeCodes, claimCauseCodeId)) newState = { ...newState, claimCauseCodeId: undefined };
    }
    if (!isDenied) newState = { ...newState, deniedDescription: "" };
    setState(prevState => ({ ...prevState, ...newState }));
  }, [claimLineOfBusinessId, claimLossTypeGroupId, processingStatusId]);
  useEffect(() => {
    if (loaded) handleValidation();
    else setLoaded(true);
  }, [state, location, employeeAccident, bodilyInjury]);
  const isDeath = injuryType.some(t => t.id === bodilyInjury?.injuryTypeId && t.isDeath);
  useEffect(() => {
    setBodilyInjury(ps => ({ ...ps, isDeath, claimantDateofDeath: isDeath ? ps.claimantDateofDeath : null }));
  }, [isDeath]);
  const handleSubmit = event => {
    event.preventDefault();
    const { isValid } = handleValidation();
    if (isValid) onSave({ ...state, location, employeeAccident, bodilyInjury });
  };
  const inputProps = path => {
    const [ent, name] = path.split(".");
    const { st, error, action } = (() => {
      switch (ent) {
        case "location":
          return { st: location, error: "location.", action: setLocation };
        case "employeeAccident":
          return { st: employeeAccident, error: "employeeAccident.", action: setEmployeeAccident };
        case "bodilyInjury":
          return { st: bodilyInjury, error: "bodilyInjury.", action: setBodilyInjury };
        case "hospital":
          return { st: hospital, error: "hospital.", action: setHospital };
        default:
          return { st: state, error: "", action: setState };
      }
    })();
    return {
      name: path,
      [name === "treatmentReceived" ? "checked" : "value"]: (name ? st[name] : st[ent]) || undefined,
      error: (validationMessages || {})[`${error || ""}${name || ent}`],
      onChange: ({ target: { value } }) => {
        return action(ps => ({ ...ps, [name || ent]: value }));
      }
    };
  };
  const handleChange = ({ target: { name: path, value } }) => {
    const [, name] = path.split(".");
    if (name === "onEmployerPremises" && value === "true") setLocation(insuredLocation);
    return setEmployeeAccident(ps => ({ ...ps, [name]: value === "true" }));
  };
  const options = [
    { id: true, text: "YES" },
    { id: false, text: "NO" }
  ];
  const arr = a => (Array.isArray(a) ? a : []);
  const hospitalFilter = arr(hospitalLocation)
    .filter(h => !state.hospitalStateId || h.stateId === state.hospitalStateId)
    .map(({ id, name }) => ({ id, text: name }));
  const { hPhone, address1, address2, city, zipCode } = hospitalLocation.find(({ id }) => id === bodilyInjury.hospitalId) || {};
  const hLocation = { address1, address2, city, zipCode };
  return (
    <React.Fragment key="claimedit">
      <form onSubmit={handleSubmit}>
        <React.Fragment key="claim-update">
          {!isReportOnly ? (
            <Grid container spacing={4}>
              <Grid item xs>
                <TextInput {...inputProps("number")} label="Claim #" disabled />
                <LabelValue label="Line Of Business " value="WC" />
                <DateTimeInput {...inputProps("employeeAccident.froiReceivedDate")} label="FROI Received Date *" keyboard />
                <DateTimeInput {...inputProps("dateReported")} label="Date Reported *" keyboard />
              </Grid>
              <Grid item xs>
                <SelectInput {...inputProps("statusId")} label="Claim Status *" options={status} />
                <DateTimeInput {...inputProps("statusDate")} label="Claim Status Date" disabled keyboard />
                <DateTimeInput {...inputProps("dateEntry")} label="Claim Entry" disabled keyboard />
                <TextInput {...inputProps("lateReason")} label="Late Reason" />
              </Grid>
              <Grid item xs>
                <SelectInput {...inputProps("processingStatusId")} label="Processing Status *" options={processingStatus} />
                <DateTimeInput {...inputProps("processingStatusDate")} label="Processing Status Date" disabled keyboard />
                <SelectInput {...inputProps("typeId")} label="Claim Type *" options={claimType} />
                <TextInput {...inputProps("reportedBy")} label="Claim Reported By" />
              </Grid>
              <Grid item xs>
                <SelectInput {...inputProps("contactMethodId")} label="Method of Contact/Report *" options={contactMethods} />
                <TimeInput {...inputProps("employeeAccident.beganWorkTime")} label="Time employee began to work" keyboard />
                <DateTimeInput {...inputProps("employeeAccident.lastWorkDate")} label="Last Work Date" keyboard />
                <DateTimeInput {...inputProps("employeeAccident.beganDisabilityDate")} label="Date Disability Began" keyboard />
              </Grid>
              <Grid item xs={12}>
                {isDenied && <TextInput {...inputProps("deniedDescription")} label="Claim Denied Description *" rows={4} />}
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={4}>
              <Grid item xs>
                <DateTimeInput {...inputProps("employeeAccident.froiReceivedDate")} label="FROI Received Date *" keyboard />
                <DateTimeInput {...inputProps("dateReported")} label="Date Reported *" keyboard />
              </Grid>
              <Grid item xs>
                <SelectInput {...inputProps("typeId")} label="Claim Type *" options={claimType} />
                <TextInput {...inputProps("reportedBy")} label="Claim Reported By" />
              </Grid>
              <Grid item xs>
                <SelectInput {...inputProps("contactMethodId")} label="Method of Contact/Report *" options={contactMethods} />
                <DateTimeInput {...inputProps("employeeAccident.lastWorkDate")} label="Last Work Date" keyboard />
              </Grid>
              <Grid item xs>
                <DateTimeInput {...inputProps("employeeAccident.beganDisabilityDate")} label="Date Disability Began" keyboard />
                <TimeInput {...inputProps("employeeAccident.beganWorkTime")} label="Time employee began to work" keyboard />
              </Grid>
              <Grid item xs={12}>
                <TextInput {...inputProps("lateReason")} label="Late Reason" />
              </Grid>
            </Grid>
          )}
        </React.Fragment>
        <Divider style={{ margin: "15px 0px 15px 0px" }} />
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Grid container>
              <Grid item xs className={classes.pr1}>
                <DateTimeInput {...inputProps("dateofLoss")} label="Date of Injury *" keyboard />
              </Grid>
              <Grid item xs className={classes.pl1}>
                <TimeInput {...inputProps("dateofLoss")} label="Time of Injury *" keyboard />
              </Grid>
            </Grid>
            <TextInput {...inputProps("location.address1")} label="Accident Address 1  *" />
            <TextInput {...inputProps("location.address2")} label="Accident Address 2" />
            <TextInput {...inputProps("location.city")} label="City *" />
            <Grid container>
              <Grid item xs className={classes.pr1}>
                <SelectInput {...inputProps("location.stateId")} label="State *" options={states} />
              </Grid>
              <Grid item xs className={classes.pl1}>
                <TextInput {...inputProps("location.zipCode")} label="Zip *" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <BaseRadioButtonGroup
              name="employeeAccident.onEmployerPremises"
              options={options}
              value={employeeAccident.onEmployerPremises}
              label="Accident on Employer’s Premises"
              row
              onChange={handleChange}
            />
            <SelectInput {...inputProps("employeeAccident.lossTimeStatusId")} label="Loss Time Status *" options={lossTimeStatus} />
            <BaseRadioButtonGroup
              name="employeeAccident.lossTimeMore"
              options={options}
              value={employeeAccident.lossTimeMore}
              label="Loss time more than 7 days"
              row
              onChange={handleChange}
            />
            <DateTimeInput {...inputProps("employeeAccident.returnDate")} label="Date Return (ed) to Work" keyboard />
          </Grid>
          <Grid item xs={6}>
            <BaseRadioButtonGroup
              name="employeeAccident.equipmentInvolved"
              options={options}
              value={employeeAccident.equipmentInvolved}
              label="Equipment, material or chemicals involved"
              row
              onChange={handleChange}
            />
            {employeeAccident.equipmentInvolved && (
              <TextInput {...inputProps("employeeAccident.equipmentInvolvedDescription")} label="Details" rows={1} />
            )}
            <TextInput
              {...inputProps("employeeAccident.activityAtTheMoment")}
              label="Employee Specific Activity at the moment of the accident"
              rows={1}
            />
            <TextInput
              {...inputProps("employeeAccident.workProcessAtTheMoment")}
              label="Employee Work Process at the moment of the accident"
              rows={1}
            />
            <BaseRadioButtonGroup
              name="employeeAccident.safetyEquipmentProvided"
              options={options}
              value={employeeAccident.safetyEquipmentProvided}
              label="Safeguards or Safety Equipment provided? "
              row
              onChange={handleChange}
            />
            {employeeAccident.safetyEquipmentProvided && (
              <BaseRadioButtonGroup
                name="employeeAccident.safetyEquipmentProvidedUsed"
                options={options}
                value={employeeAccident.safetyEquipmentProvidedUsed}
                label="Were they used?"
                row
                onChange={handleChange}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <TextInput {...inputProps("employeeAccident.description")} label="Accident Description" rows={2} />
          </Grid>
        </Grid>
        <Divider style={{ margin: "15px 0px 15px 0px" }} />
        <Grid container spacing={4}>
          <Grid item xs>
            <SelectInput {...inputProps("bodilyInjury.injuryTypeId")} label="Nature of Injury *" options={injuryType} />
            {isDeath && <DateTimeInput {...inputProps("bodilyInjury.claimantDateofDeath")} label="Date Of Death *" keyboard />}
            <SelectInput {...inputProps("claimCauseCodeId")} label="Cause Code" options={causeCodes} />
            <SelectInput {...inputProps("bodilyInjury.parentBodyPartId")} label="Body Part Group *" options={bodyPartGroup} />
          </Grid>
          <Grid item xs>
            <SelectInput
              {...inputProps("bodilyInjury.bodyPartId")}
              label="Body Part *"
              disabled={!bodilyInjury.parentBodyPartId ? true : undefined}
              options={filterByParentId(bodyPartFull, bodilyInjury.parentBodyPartId)}
            />
            <SelectInput {...inputProps("bodilyInjury.ncciInjuryCodeId")} label="NCCI Injury Code" options={ncciInjuryCodes} />
            <SelectInput {...inputProps("claimLossTypeId")} label="Loss Type *" options={lossTypes} />
          </Grid>

          <Grid item xs>
            <SelectInput {...inputProps("bodilyInjury.typeOfCoverageCodeId")} label="Type of Coverage Code" options={typeOfCoverageCodes} />
            <SelectInput {...inputProps("ediClaimTypeId")} label="EDI Claim Type" options={ediClaimTypes} />
            <TextInput {...inputProps("stateNumber")} label="State Claim Number" />
          </Grid>
          <Grid item xs>
            <CheckboxLabel {...inputProps("bodilyInjury.treatmentReceived")} label="Initial Treatment Received" />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <TextInput {...inputProps("bodilyInjury.description")} label="Bodily Injury Description" rows={3} />
          </Grid>
        </Grid>
        <Collapse in={bodilyInjury?.treatmentReceived} timeout="auto">
          <Divider className={classes.marginDevider} />
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <SelectInput {...inputProps("bodilyInjury.treatmentTypeId")} label="Initial Treatment Type *" options={treatmentType} />
              <TextInput {...inputProps("bodilyInjury.physicianName")} label="Initial Hospital Physician Name" />
            </Grid>
            <Grid item xs={3}>
              <SelectInput {...inputProps("bodilyInjury.hospitalId")} label="Hospital Name" options={hospitalFilter} allowClear />
              <LabelValue value={hPhone} label="Hospital Phone Number" />
            </Grid>
            <Grid item xs={3}>
              <LabelValue value={hLocation} label="Hospital Address" isAddress />
            </Grid>
            <Grid item xs={3}>
              <TextInput {...inputProps("bodilyInjury.treatmentDescription")} label="Treatment Description" rows={3} />
            </Grid>
          </Grid>
        </Collapse>
        <Grid container alignItems="flex-start" justify="flex-end" direction="row">
          <div className={classes.buttonWrapper}>
            <Button variant="outlined" color="primary" onClick={onClose}>
              CANCEL
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
              SAVE
            </Button>
          </div>
        </Grid>
      </form>
    </React.Fragment>
  );
};

WcClaimForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  claim: PropTypes.shape({
    location: PropTypes.shape({})
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  isReportOnly: PropTypes.bool,
  isLoading: PropTypes.bool
};

WcClaimForm.defaultProps = {
  isReportOnly: false,
  isLoading: false
};

export default WcClaimForm;
