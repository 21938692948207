import { Paper } from "@material-ui/core";
import React, { useEffect, useContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllHistory } from "../../redux/actions/financial";
import { useLoading } from "../common/Loading";
import MaterialTable from "../common/table/MaterialTable";
import ActionsAllowed from "../common/contexts/ActionsAllowed";
import { setExportTitleFileName } from "../../common";

export default () => {
  const routesAllowed = useContext(ActionsAllowed);
  const dispatch = useDispatch();
  const data = useSelector(({ financial: { history } }) => history, shallowEqual);
  const {
    Loading,
    actions: { showLoading, hideLoading }
  } = useLoading(true);
  const access = routesAllowed.find(elem => elem.route === "history");
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(getAllHistory(hideLoading, hideLoading));
    }
  }, []);
  const tableProps = {
    title: "Payment History",
    data,
    columns: [
      {
        title: "Client Name",
        field: "clientName"
      },
      {
        title: "Claim",
        field: "claimNumber",
        render: row => {
          if (row?.claimAllowed) return <Link to={`/claim/${row.claimId}`}>{row.claimNumber}</Link>;
          return row.claimNumber;
        }
      },
      { title: "Payment Type", field: "type", width: null, cellStyle: { whiteSpace: "nowrap", paddingRight: 0, width: 200 } },
      { title: "Type", field: "reserveType" },
      { title: "Description", field: "description" },
      { title: "Status", field: "status" },
      { title: "Payee Name", field: "payeeName" },
      { title: "Bill/Payment Amount", field: "amount" },
      { title: "Total Paid", field: "totalPaid" },
      { title: "Payment Date", field: "paymentDate", type: "date" }
    ],
    hidePaging: data.length <= 50,
    options: { pageSize: 50, exportFileName: setExportTitleFileName("FinancialPaymentsHistory") }
  };
  return (
    <>
      {Loading}
      <div className="tc-page-content">
        <Paper className="tc-p2">
          <MaterialTable {...tableProps} />
        </Paper>
      </div>
    </>
  );
};
