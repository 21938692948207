import { put, call, takeLatest as tl } from "redux-saga/effects";
import { toast } from "react-toastify";
import { api } from "../../../api/apiUtils";
import { apiCallError, beginApiCall } from "../../actions/apiStatusActions";

export const getErrorMessage = (status, errorMessage) => {
  switch (status) {
    case 403:
      return "Access to the requested resource is forbidden.";
    case 401:
      return "The request sent by the client could not be authenticated.";
    default:
      return errorMessage;
  }
};

const sagaGenerator = ({
  typeSuccess,
  actionSuccess,
  onSuccess,
  successMessage,
  typeError,
  actionError,
  onError,
  errorMessage,
  method = "GET",
  url,
  params,
  apiStatus = true,
  isData = false,
  payloadPath = "payload",
  apiConfig
}) =>
  function* gSagaGen(action) {
    try {
      if (apiStatus) yield put(beginApiCall());
      const data = typeof params === "function" ? params(action) : null;
      let cMethod = method;
      if (typeof method === "function") cMethod = method(action);
      let cUrl = url;
      if (typeof url === "function") cUrl = url(action);
      const headers = { "Content-Type": "application/json" };

      let config = {};
      if (typeof apiConfig === "function") config = apiConfig(action);
      else if (typeof apiConfig === "object") config = { ...apiConfig };
      else if (typeof apiConfig !== "undefined")
        throw new Error(`Error on sagaGenerator: parameter 'apiConfig' of type '${typeof apiConfig}' not valid`);

      const payload = yield call(api, { method: cMethod, url: cUrl, data, headers, ...(isData ? { dataOrParams: "data" } : {}), ...config });

      if (payload?.message && payload.message !== "" && typeof action.onError === "function") yield call(action.onError, payload);
      else {
        if (typeof actionSuccess === "function") yield put(actionSuccess(payload));
        else if (typeof typeSuccess === "string") yield put({ type: typeSuccess, [payloadPath]: payload });
        if (typeof onSuccess === "function") yield call(onSuccess, { ...action, [payloadPath]: payload });
        if (typeof successMessage === "string" && successMessage) yield call(toast.success, successMessage);
        if (typeof action.onSuccess === "function") yield call(action.onSuccess, payload);
      }
    } catch (err) {
      if (apiStatus) yield put(apiCallError(err));
      if (typeof actionError === "function") yield put(actionError(err));
      else if (typeof typeError === "string") yield put({ type: typeError, err });
      if (typeof onError === "function") yield call(onError, { ...action, err });
      if (typeof action.onError === "function") yield call(action.onError, err);
      if (typeof errorMessage === "string" && errorMessage) yield call(toast.error, getErrorMessage(err?.response?.status, errorMessage));
    }
  };

export const takeLatest = ({ type, ...other }) => tl(type, sagaGenerator(other));

export const takeLatestSet = ({ type, ...other }) => tl(type, sagaGenerator({ method: "PUT", ...other }));

export const takeLatestPost = ({ type, ...other }) => tl(type, sagaGenerator({ method: "POST", ...other }));

export const takeLatestDel = ({ type, ...other }) => tl(type, sagaGenerator({ method: "DELETE", ...other }));

export default sagaGenerator;
