import CLIENTR from "./client";
import OCCURRENCE from "./occurrence";
import CLAIM from "./claim";
import INITIALREPORT from "./initialReport";
import ADMIN from "./admin";
import PROFILE from "./profile";
import AUTOMATION from "./automation";
import DOCUMENTS from "./documents";
import FINANCIAL from "./financial";
import { documents } from "./common";

const API = {
  URL: `${process.env.API_URL}`,
  CALL: "BEGIN_API_CALL",
  ERROR: "API_CALL_ERROR"
};

const SELECTS = {
  LOAD: "FETCH_SELECTS",
  LOAD_SUCCESS: "LOAD_SELECTS_SUCCESS",
  GET_PROFILES: "GET_SELECT_PROFILES",
  GET_PROFILES_SUCCESS: "GET_SELECT_PROFILES_SUCCESS",
  GET_ALL_VENDOR_CARRIERS: "GET_ALL_VENDOR_CARRIERS",
  GET_ALL_VENDOR_CARRIERS_SUCCESS: "GET_ALL_VENDOR_CARRIERS_SUCCESS"
};

const INSURED = {
  LOAD_INSURED_PROFILE: "LOAD_INSURED_PROFILE",
  LOAD_INSURED_PROFILE_SUCCESS: "LOAD_INSURED_PROFILE_SUCCESS",
  UPDATE_INSURED_INFO: "UPDATE_INSURED_INFO",
  UPDATE_INSURED_INFO_SUCCESS: "UPDATE_INSURED_INFO_SUCCESS",
  DELETE_INSURED: "DELETE_INSURED",
  DELETE_INSURED_SUCCESS: "DELETE_INSURED_SUCCESS",
  ADD_CODE: "ADD_INSURED_CODE",
  ADD_CODE_SUCCESS: "ADD_INSURED_CODE_SUCCESS",
  DELETE_CODE: "DELETE_INSURED_CODE",
  DELETE_CODE_SUCCESS: "DELETE_INSURED_CODE_SUCCESS",
  NOTES: {
    POST_SUCCESS: "POST_INSUREDNOTE_SUCCESS",
    SET_SUCCESS: "SET_INSUREDNOTE_SUCCESS",
    DEL_SUCCESS: "DEL_INSUREDNOTE_SUCCESS",
    GET_SUCCESS: "GET_INSUREDNOTES_SUCCESS",
    GET_DRAFT_SUCCESS: "GET_INSUREDNOTE_DRAFT_SUCCESS",
    POST_DRAFT_SUCCESS: "POST_INSUREDNOTE_DRAFT_SUCCESS"
  }
};

const CONTACT = {
  LOAD_CLIENT_CONTACT: "LOAD_CLIENT_CONTACT",
  LOAD_CLIENT_CONTACT_SUCCESS: "LOAD_CLIENT_CONTACT_SUCCESS",
  LOAD_INSURED_CONTACT: "LOAD_INSURED_CONTACT",
  LOAD_INSURED_CONTACT_SUCCESS: "LOAD_INSURED_CONTACT_SUCCESS"
};

const LOCATION = {
  LOAD_INSURED_LOCATION: "LOAD_INSURED_LOCATION",
  LOAD_INSURED_LOCATION_SUCCESS: "LOAD_INSURED_LOCATION_SUCCESS",
  LOAD_INSURED_LOCATION_ADD: "LOAD_INSURED_LOCATION_ADD",
  LOAD_INSURED_LOCATION_ADD_SUCCESS: "LOAD_INSURED_LOCATION_ADD_SUCCESS",
  LOAD_INSURED_LOCATION_ID: "LOAD_INSURED_LOCATION_ID",
  LOAD_INSURED_LOCATION_ID_SUCCESS: "LOAD_INSURED_LOCATION_ID_SUCCESS",
  CLEAR_INSURED_LOCATION_ID: "CLEAR_INSURED_LOCATION_ID",
  LOAD_INSURED_LOCATION_UPDATE: "LOAD_INSURED_LOCATION_UPDATE",
  LOAD_INSURED_LOCATION_UPDATE_SUCCESS: "LOAD_INSURED_LOCATION_UPDATE_SUCCESS"
};

const CLIENT_DOCUMENTS = documents("CLIENT");

const INSURED_DOCUMENTS = documents("INSURED");

const CATASTROPHE = {
  GET: "GET_CATASTROPHE",
  GET_SUCCESS: "GET_CATASTROPHE_SUCCESS",
  GETALL: "GET_CATASTROPHES",
  GETALL_SUCCESS: "GET_CATASTROPHES_SUCCESS",
  POST: "POST_CATASTROPHE",
  POST_SUCCESS: "POST_CATASTROPHE_SUCCESS",
  SET: "SET_CATASTROPHE",
  SET_SUCCESS: "SET_CATASTROPHE_SUCCESS",
  DEL: "DEL_CATASTROPHE",
  DEL_SUCCESS: "DEL_CATASTROPHE_SUCCESS",
  FIND: "CATASTROPHE_FIND",
  FIND_SUCCESS: "CATASTROPHE_FIND_SUCCESS",
  ASSOCIATE_CATASTROPHE: "ASSOCIATE_CATASTROPHE",
  ASSOCIATE_CATASTROPHE_SUCCESS: "ASSOCIATE_CATASTROPHE_SUCCESS",
  GET_RESERVES: "GET_CATASTROPHE_RESERVES",
  GET_RESERVES_SUCCESS: "GET_CATASTROPHE_RESERVES_SUCCESS",
  NOTES: {
    POST_SUCCESS: "POST_CATASTROPHENOTE_SUCCESS",
    SET_SUCCESS: "SET_CATASTROPHENOTE_SUCCESS",
    DEL_SUCCESS: "DEL_CATASTROPHENOTE_SUCCESS",
    GET_SUCCESS: "GET_CATASTROPHENOTES_SUCCESS",
    GET_DRAFT_SUCCESS: "GET_CATASTROPHENOTE_DRAFT_SUCCESS",
    POST_DRAFT_SUCCESS: "POST_CATASTROPHENOTE_DRAFT_SUCCESS"
  },
  LOAD_FILES: "LOAD_CATASTROPHE_FILES",
  LOAD_FILES_SUCCESS: "LOAD_CATASTROPHE_FILES_SUCCESS",
  UPLOAD_FILES: "UPLOAD_CATASTROPHE_FILES",
  UPLOAD_FILES_SUCCESS: "UPLOAD_CATASTROPHE_FILES_SUCCESS",
  UPDATE_FILE: "UPDATE_CATASTROPHE_FILE",
  UPDATE_FILE_SUCCESS: "UPDATE_CATASTROPHE_FILE_SUCCESS",
  DELETE_FILE: "DELETE_CATASTROPHE_FILE",
  DELETE_FILE_SUCCESS: "DELETE_CATASTROPHE_FILE_SUCCESS",
  OPEN_FILE: "OPEN_CATASTROPHE_FILE",
  OPEN_FILE_SUCCESS: "OPEN_CATASTROPHE_FILE_SUCCESS",
  GET_FILTER_FIELD: "GET_CATASTROPHE_PROFILE_FILTER_FIELD",
  GET_FILTER_FIELD_SUCCESS: "GET_CATASTROPHE_PROFILE_FILTER_FIELD_SUCCESS",
  RESET: "RESET_CATASTROPHE"
};

const CLIENT = {
  OPEN_POLICY_DOCUMENT: "OPEN_INSURED_POLICY_DOCUMENT",
  OPEN_POLICY_DOCUMENT_SUCCESS: "OPEN_INSURED_POLICY_DOCUMENT_SUCCESS",
  UPLOAD_POLICY_DOCUMENT: "UPLOAD_INSURED_POLICY_DOCUMENT",
  UPLOAD_POLICY_DOCUMENT_SUCCESS: "UPLOAD_INSURED_POLICY_DOCUMENT_SUCCESS",
  DELETE_POLICY_DOCUMENT: "DELETE_INSURED_POLICY_DOCUMENT",
  DELETE_POLICY_DOCUMENT_SUCCESS: "DELETE_INSURED_POLICY_DOCUMENT_SUCCESS",
  NOTES: {
    POST_SUCCESS: "POST_CLIENTNOTE_SUCCESS",
    SET_SUCCESS: "SET_CLIENTNOTE_SUCCESS",
    DEL_SUCCESS: "DEL_CLIENTNOTE_SUCCESS",
    GET_SUCCESS: "GET_CLIENTNOTES_SUCCESS",
    GET_DRAFT_SUCCESS: "GET_CLIENTNOTE_DRAFT_SUCCESS",
    POST_DRAFT_SUCCESS: "POST_CLIENTNOTE_DRAFT_SUCCESS"
  }
};

const VENDOR = {
  LOAD: "LOAD_VENDOR",
  LOAD_SUCCESS: "LOAD_VENDOR_SUCCESS",
  LOAD_VENDORS: "LOAD_VENDORS",
  LOAD_VENDORS_SUCCESS: "LOAD_VENDORS_SUCCESS",
  GET_PENDING: "GET_VENDORS_PENDING",
  GET_PENDING_SUCCESS: "GET_VENDORS_PENDING_SUCCESS",
  SET: "SET_VENDOR",
  SET_SUCCESS: "SET_VENDOR_SUCCESS",
  ADD_CODE: "ADD_VENDOR_CODE",
  ADD_CODE_SUCCESS: "ADD_VENDOR_CODE_SUCCESS",
  DELETE_CODE: "DELETE_VENDOR_CODE",
  DELETE_CODE_SUCCESS: "DELETE_VENDOR_CODE_SUCCESS",
  LOAD_CONTACTS: "LOAD_VENDOR_CONTACTS",
  LOAD_CONTACTS_SUCCESS: "LOAD_VENDOR_CONTACTS_SUCCESS",
  POST_CONTACT: "VENDOR_POST_CONTACT",
  POST_CONTACT_SUCCESS: "VENDOR_POST_CONTACT_SUCCESS",
  SET_CONTACT: "VENDOR_SET_CONTACT",
  SET_CONTACT_SUCCESS: "VENDOR_SET_CONTACT_SUCCESS",
  DEL_CONTACT: "VENDOR_DEL_CONTACT",
  DEL_CONTACT_SUCCESS: "VENDOR_DEL_CONTACT_SUCCESS",
  DEL: "DELETE_VENDOR",
  DEL_SUCCESS: "DELETE_VENDOR_SUCCESS",
  ADD: "ADD_VENDOR",
  ADD_SUCCESS: "ADD_VENDOR_SUCCESS",
  LOAD_PAYMENTS: "LOAD_PAYMENTS",
  LOAD_PAYMENTS_SUCCESS: "LOAD_PAYMENTS_SUCCESS",
  GET_CLAIMSINVOLVED: "GET_CLAIMSINVOLVED",
  GET_CLAIMSINVOLVED_SUCCESS: "GET_CLAIMSINVOLVED_SUCCESS",
  GET_FINANCIALINFO: "GET_FINANCIALINFO",
  GET_FINANCIALINFO_SUCCESS: "GET_FINANCIALINFO_SUCCESS",
  LOAD_FILES: "LOAD_VENDOR_FILES",
  LOAD_FILES_SUCCESS: "LOAD_VENDOR_FILES_SUCCESS",
  UPLOAD_FILES: "UPLOAD_VENDOR_FILES",
  UPLOAD_FILES_SUCCESS: "UPLOAD_VENDOR_FILES_SUCCESS",
  UPDATE_FILE: "UPDATE_VENDOR_FILE",
  UPDATE_FILE_SUCCESS: "UPDATE_VENDOR_FILE_SUCCESS",
  DELETE_FILE: "DELETE_VENDOR_FILE",
  DELETE_FILE_SUCCESS: "DELETE_VENDOR_FILE_SUCCESS",
  DOWNLOAD_FILES: "DOWNLOAD_VENDOR_FILES",
  DOWNLOAD_FILES_SUCCESS: "DOWNLOAD_VENDOR_FILES_SUCCESS",
  OPEN_FILE: "OPEN_VENDOR_FILE",
  OPEN_FILE_SUCCESS: "OPEN_VENDOR_FILE_SUCCESS",
  SET_STATUS: "SET_VENDOR_STATUS",
  SET_STATUS_SUCCESS: "SET_VENDOR_STATUS_SUCCESS",
  APPROVE: "APPROVE_VENDOR",
  APPROVE_SUCCESS: "APPROVE_VENDOR_SUCCESS",
  REJECT: "REJECT_VENDOR",
  REJECT_SUCCESS: "REJECT_VENDOR_SUCCESS",
  CLEAN: "CLEAN_VENDOR",
  NOTES: {
    POST_SUCCESS: "POST_VENDORNOTE_SUCCESS",
    SET_SUCCESS: "SET_VENDORNOTE_SUCCESS",
    DEL_SUCCESS: "DEL_VENDORNOTE_SUCCESS",
    GET_SUCCESS: "GET_VENDORNOTES_SUCCESS",
    GET_DRAFT_SUCCESS: "GET_VENDORNOTE_DRAFT_SUCCESS",
    POST_DRAFT_SUCCESS: "POST_VENDORNOTE_DRAFT_SUCCESS"
  },
  BANKING: {
    POST_SUCCESS: "POST_VENDORBANKING_SUCCESS",
    SET_SUCCESS: "SET_VENDORBANKING_SUCCESS",
    DEL_SUCCESS: "DEL_VENDORBANKING_SUCCESS",
    GET_SUCCESS: "GET_VENDORBANKING_SUCCESS"
  },
  LOCATIONS: {
    GET: "GET_VENDOR_LOCATIONS",
    GET_SUCCESS: "GET_VENDOR_LOCATIONS_SUCCESS",
    POST: "POST_VENDOR_LOCATION",
    POST_SUCCESS: "POST_VENDOR_LOCATION_SUCCESS",
    SET: "SET_VENDOR_LOCATION",
    SET_SUCCESS: "SET_VENDOR_LOCATION_SUCCESS",
    DEL: "DEL_VENDOR_LOCATION",
    DEL_SUCCESS: "DEL_VENDOR_LOCATION_SUCCESS",
    LOCATION_GET_SUCCESS: "GET_VENDOR_LOCATION_SUCCESS",
    CLEAN: "CLEAN_VENDOR_LOCATION",
    CLEAN_SUCCESS: "CLEAN_VENDOR_LOCATION_SUCCESS"
  }
};

const NOTES = {
  GET: "GET_NOTES",
  POST: "POST_NOTE",
  SET: "SET_NOTE",
  DEL: "DEL_NOTE",
  GET_DRAFT: "GET_NOTE_DRAFT",
  POST_DRAFT: "POST_NOTE_DRAFT",
  OPEN_FILE: `OPEN_NOTE_FILE`,
  OPEN_FILE_SUCCESS: `OPEN_NOTE_FILE_SUCCESS`
};

const BANKING = {
  GET: "GET_ACCOUNTS",
  POST: "POST_ACCOUNT",
  SET: "SET_ACCOUNT",
  DEL: "DEL_ACCOUNT"
};

const DIARY = {
  GET: "GET_DIARIES",
  POST: "POST_DIARY",
  SET: "SET_DIARY",
  DEL: "DEL_DIARY"
};

const NOTIFICATION = {
  GET: "GET_NOTIFICATIONS",
  GET_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  SET_READ: "SET_READ_NOTIFICATIONS",
  SET_READ_SUCCESS: "SET_READ_NOTIFICATION_SUCCESS"
};

export {
  API,
  CLAIM,
  INSURED,
  INSURED_DOCUMENTS,
  SELECTS,
  CATASTROPHE,
  CLIENT,
  CLIENT_DOCUMENTS,
  OCCURRENCE,
  CONTACT,
  LOCATION,
  BANKING,
  VENDOR,
  CLIENTR,
  NOTES,
  INITIALREPORT,
  ADMIN,
  DIARY,
  PROFILE,
  AUTOMATION,
  DOCUMENTS,
  FINANCIAL,
  NOTIFICATION
};
