import { CLAIM, API } from "../../../actions/actionTypes";
import { getSuccess, setSuccess, postSuccess } from "../../../actions/claim/legal/mediationArbitration";
import { takeLatest, takeLatestSet, takeLatestPost } from "../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLAIM.LEGAL.MEDITATION_ARBITRATION.GET,
    url: `${API.URL}/mediationArbitration`,
    params: ({ claimId }) => ({ claimId }),
    actionSuccess: getSuccess,
    errorMessage: "Error loading Mediation Arbitration"
  }),
  takeLatestSet({
    type: CLAIM.LEGAL.MEDITATION_ARBITRATION.SET,
    url: `${API.URL}/mediationArbitration`,
    params: ({ mediationArbitration }) => mediationArbitration,
    successMessage: "Mediation Arbitration information successfully saved",
    actionSuccess: setSuccess,
    errorMessage: "Unable to save the information"
  }),
  takeLatestPost({
    type: CLAIM.LEGAL.MEDITATION_ARBITRATION.POST,
    url: `${API.URL}/mediationArbitration`,
    params: ({ mediationArbitration }) => mediationArbitration,
    successMessage: "Mediation Arbitration information successfully saved",
    actionSuccess: postSuccess,
    errorMessage: "Unable to save the information"
  })
];
