import { useContext, useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { getUserAccess } from "../../../common";
import AppContext from "../contexts/Container";

const usePermissionForMenu = () => {
  const routeNames = [
    "myinfo",
    "claims",
    "claim",
    "catastrophe",
    "reports",
    "documents",
    "intelligence",
    "terramaps",
    "automation",
    "clients",
    "client",
    "vendors",
    "vendor",
    "admin",
    "occurrence",
    "initialReport",
    "claims-claims",
    "dashboard",
    "financial"
  ];
  const exceptedRoutesForCheck = useContext(AppContext)?.exceptedRoutesForCheck;
  const secProfs = useSelector(({ profile: { securityProfilesDetails } }) => securityProfilesDetails, shallowEqual);
  const isDocumentActive = useSelector(
    ({
      admin: {
        claimSettings: {
          documents: { active }
        }
      }
    }) => active,
    shallowEqual
  );
  const isLoading = !secProfs || secProfs.length === 0;

  const isAllowed = (routeName, codeName, action) => {
    if (exceptedRoutesForCheck.indexOf(routeName) > -1) return true;
    if (secProfs.length > 0) return getUserAccess(secProfs, codeName, null, null, null)?.[action];
    return true;
  };

  const isRouteAllowed = useCallback(
    (routeName, action) => {
      switch (routeName) {
        case "documents":
          return (
            isDocumentActive &&
            (isAllowed(routeName, "DOCUMENT.INDEXING", action) ||
              isAllowed(routeName, "DOCUMENT.REINDEXING", action) ||
              isAllowed(routeName, "DOCUMENT.REVIEW", action))
          );
        case "admin":
          return (
            isAllowed(routeName, "ADMIN.CLAIM.OVERVIEW", action) ||
            isAllowed(routeName, "ADMIN.CLAIM.DOCUMENTS", action) ||
            isAllowed(routeName, "ADMIN.CLAIM.NOTES", action) ||
            isAllowed(routeName, "ADMIN.CLAIM.RESERVES", action) ||
            isAllowed(routeName, "ADMIN.TEMPLATES.COMMUNICATIONS", action) ||
            isAllowed(routeName, "ADMIN.TEMPLATES.DIARIES", action) ||
            isAllowed(routeName, "ADMIN.USERS", action)
          );
        case "claims":
          return (
            isAllowed("claims", "CLAIM.LIST", action) ||
            isAllowed("initialReport", "INITIALREPORT.LIST", action) ||
            isAllowed("occurrence", "OCCURRENCE.LIST", action) ||
            isAllowed("catastrophe", "CATASTROPHE.LIST", action)
          );
        case "financial":
          return (
            isAllowed(routeName, "FINANCIAL.PAYMENTSPROCESSINGQUEUE", action) ||
            isAllowed(routeName, "FINANCIAL.SCHEDULEDPAYMENTS", action) ||
            isAllowed(routeName, "FINANCIAL.PAYMENTSHISTORY", action) ||
            isAllowed(routeName, "FINANCIAL.SETTLEMENTS", action)
          );
        case "claims-claims":
          return isAllowed(routeName, "CLAIM.LIST", action);
        case "initialReport":
          return isAllowed(routeName, "INITIALREPORT.LIST", action);
        case "occurrence":
          return isAllowed(routeName, "OCCURRENCE.LIST", action);
        case "catastrophe":
          return isAllowed(routeName, "CATASTROPHE.LIST", action);
        case "clients":
          return isAllowed(routeName, "CLIENT.LIST", action);
        case "vendor":
          return isAllowed(routeName, "VENDORS.LIST", action);
        case "automation":
          return isAllowed(routeName, "AUTOMATION.DIARIES", action);
        default:
          return isAllowed(routeName, `${routeName.toLocaleUpperCase()}.LIST`, action);
      }
    },
    [isDocumentActive, secProfs]
  );
  return {
    permissions: routeNames.map(route => ({ routeName: route, read: isRouteAllowed(route, "read"), create: isRouteAllowed(route, "create") })),
    isLoading
  };
};

export default usePermissionForMenu;
