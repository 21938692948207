import { VENDOR, API } from "./actionTypes";
import * as docActions from "./common/documents";

export const loadVendors = (onSuccess, onError) => ({ type: VENDOR.LOAD_VENDORS, onSuccess, onError });
export const loadVendorsSuccess = vendors => ({ type: VENDOR.LOAD_VENDORS_SUCCESS, vendors });
export const loadPendingVendors = (onSuccess, onError) => ({ type: VENDOR.GET_PENDING, onSuccess, onError });
export const loadPendingVendorsSuccess = vendors => ({ type: VENDOR.GET_PENDING_SUCCESS, vendors });
export const loadVendor = (id, onSuccess, onError) => ({ type: VENDOR.LOAD, id, onSuccess, onError });
export const loadVendorSuccess = profile => ({ type: VENDOR.LOAD_SUCCESS, profile });
export const getLocationSuccess = location => ({ type: VENDOR.LOCATIONS.LOCATION_GET_SUCCESS, location });
export const setVendor = (data, onSuccess, onError) => ({ type: VENDOR.SET, data, onSuccess, onError });
export const setVendorSuccess = profile => ({ type: VENDOR.SET_SUCCESS, profile });
export const addVendor = (data, onSuccess, onError) => ({ type: VENDOR.ADD, data, onSuccess, onError });
export const addVendorSuccess = vendors => ({ type: VENDOR.ADD_SUCCESS, vendors });
export const delVendor = id => ({ type: VENDOR.DEL, id });
export const delVendorSuccess = vendors => ({ type: VENDOR.DEL_SUCCESS, vendors });
export const addCode = (vendorId, codeId, onSuccess, onError) => ({ type: VENDOR.ADD_CODE, vendorId, codeId, onSuccess, onError });
export const addCodeSuccess = industry => ({ type: VENDOR.ADD_CODE_SUCCESS, industry });
export const deleteCode = (vendorId, codeId, onSuccess, onError) => ({ type: VENDOR.DELETE_CODE, vendorId, codeId, onSuccess, onError });
export const deleteCodeSuccess = industry => ({ type: VENDOR.DELETE_CODE_SUCCESS, industry });
export const loadContacts = (parentId, onSuccess, onError) => ({ type: VENDOR.LOAD_CONTACTS, parentId, onSuccess, onError });
export const loadContactsSuccess = contacts => ({ type: VENDOR.LOAD_CONTACTS_SUCCESS, contacts });
export const postContact = (data, onSuccess, onError) => ({ type: VENDOR.POST_CONTACT, data, onSuccess, onError });
export const postContactSuccess = contacts => ({ type: VENDOR.POST_CONTACT_SUCCESS, contacts });
export const setContact = (data, onSuccess, onError) => ({ type: VENDOR.SET_CONTACT, data, onSuccess, onError });
export const setContactSuccess = contacts => ({ type: VENDOR.SET_CONTACT_SUCCESS, contacts });
export const delContact = (data, onSuccess, onError) => ({ type: VENDOR.DEL_CONTACT, data, onSuccess, onError });
export const delContactSuccess = contacts => ({ type: VENDOR.DEL_CONTACT_SUCCESS, contacts });
export const loadPayments = (vendorId, onSuccess, onError) => ({ type: VENDOR.LOAD_PAYMENTS, vendorId, onSuccess, onError });
export const loadPaymentsSuccess = payments => ({ type: VENDOR.LOAD_PAYMENTS_SUCCESS, payments });
export const getClaimsInvolved = vendorId => ({ type: VENDOR.GET_CLAIMSINVOLVED, vendorId });
export const getClaimsInvolvedSuccess = claimsInvolved => ({ type: VENDOR.GET_CLAIMSINVOLVED_SUCCESS, claimsInvolved });
export const getFinancialInfo = vendorId => ({ type: VENDOR.GET_FINANCIALINFO, vendorId });
export const getFinancialInfoSuccess = financialInfo => ({ type: VENDOR.GET_FINANCIALINFO_SUCCESS, financialInfo });
export const loadFiles = (id, onSuccess, onError) => ({ type: VENDOR.LOAD_FILES, id, onSuccess, onError });
export const loadFilesSuccess = files => ({ type: VENDOR.LOAD_FILES_SUCCESS, files });
export const uploadFiles = docActions.uploadFiles(VENDOR.UPLOAD_FILES, `${API.URL}/vendor/files`);
export const updateFile = (data, onSuccess, onError) => ({ type: VENDOR.UPDATE_FILE, data, url: `${API.URL}/vendor/file`, onSuccess, onError });
export const deleteFile = (data, onSuccess, onError) => ({ type: VENDOR.DELETE_FILE, data, url: `${API.URL}/vendor/file`, onSuccess, onError });
export const downloadFiles = docActions.downloadFiles(VENDOR.DOWNLOAD_FILES);
export const openFile = docActions.openFile(VENDOR.OPEN_FILE);
export const getLocations = parentId => ({ type: VENDOR.LOCATIONS.GET, parentId });
export const getLocationsSuccess = locations => ({ type: VENDOR.LOCATIONS.GET_SUCCESS, locations });
export const setLocations = (location, onSuccess, onError) => ({ type: VENDOR.LOCATIONS.SET, location, onSuccess, onError });
export const setLocationsSuccess = locations => ({ type: VENDOR.LOCATIONS.SET_SUCCESS, locations });
export const postLocations = (location, onSuccess, onError) => ({ type: VENDOR.LOCATIONS.POST, location, onSuccess, onError });
export const postLocationsSuccess = locations => ({ type: VENDOR.LOCATIONS.POST_SUCCESS, locations });
export const delLocations = (location, onSuccess, onError) => ({ type: VENDOR.LOCATIONS.DEL, location, onSuccess, onError });
export const delLocationsSuccess = locations => ({ type: VENDOR.LOCATIONS.DEL_SUCCESS, locations });
export const cleanLocation = () => ({ type: VENDOR.LOCATIONS.CLEAN });
export const cleanLocationSuccess = location => ({ type: VENDOR.LOCATIONS.CLEAN_SUCCESS, location });
export const setStatus = (data, onError) => ({ type: VENDOR.SET_STATUS, data, onError });
export const setStatusSuccess = basicInformation => ({ type: VENDOR.SET_STATUS_SUCCESS, basicInformation });
export const approve = (id, onSuccess, onError) => ({ type: VENDOR.APPROVE, id, onSuccess, onError });
export const approveSuccess = basicInformation => ({ type: VENDOR.APPROVE_SUCCESS, basicInformation });
export const reject = (id, onSuccess, onError) => ({ type: VENDOR.REJECT, id, onSuccess, onError });
export const rejectSuccess = () => ({ type: VENDOR.REJECT_SUCCESS });
export const clear = { type: VENDOR.CLEAN };
