import { CLAIM } from "../actionTypes";

export const get = (id, onSuccess, onError) => ({ type: CLAIM.GET, id, onSuccess, onError });
export const getSuccess = claim => ({ type: CLAIM.GET_SUCCESS, claim });
export const getSecProfFilter = (claimId, onSuccess) => ({ type: CLAIM.GET_SECPROF_FILTER, claimId, onSuccess });
export const getSecProfFilterSuccess = filterfields => ({ type: CLAIM.GET_SECPROF_FILTER_SUCCESS, filterfields });
export const getAll = (onSuccess, onError) => ({ type: CLAIM.GETALL, onSuccess, onError });
export const getAllSuccess = claims => ({ type: CLAIM.GETALL_SUCCESS, claims });
export const post = params => ({ type: CLAIM.POST, ...params });
export const postSuccess = () => ({ type: CLAIM.POST_SUCCESS });
export const set = (claim, onSuccess, onError) => ({ type: CLAIM.SET, claim, onSuccess, onError });
export const setSuccess = claim => ({ type: CLAIM.SET_SUCCESS, claim });
export const getOverview = claimId => ({ type: CLAIM.OVERVIEW.GET, claimId });
export const getOverviewSuccess = overview => ({ type: CLAIM.OVERVIEW.GET_SUCCESS, overview });
export const setUser = (claim, onSuccess, onError) => ({ type: CLAIM.USER.SET, claim, onSuccess, onError });
export const setUserSuccess = claim => ({ type: CLAIM.USER.SET_SUCCESS, claim });
export const reset = () => ({ type: CLAIM.RESET });
