import React from "react";
import PropTypes from "prop-types";
import { Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  noRecords: {
    textAlign: "center",
    padding: 15,
    height: 50
  }
}));

const NoRecords = ({ isPaper }) => {
  const classes = useStyles();
  return isPaper ? (
    <Paper className={classes.noRecords}>No records to display</Paper>
  ) : (
    <div className={classes.noRecords}>No records to display</div>
  );
};

NoRecords.propTypes = {
  isPaper: PropTypes.bool
};

NoRecords.defaultProps = {
  isPaper: true
};

export default NoRecords;
