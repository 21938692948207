import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import { Checkbox } from "@material-ui/core";
import { progressProps } from "./ProgressButton";
import RegexFilter from "../RegexFilter";
import Table from "../table/MaterialTable";
import useCustomConfirm from "../customHooks/useCustomConfirm";
import LinkButton from "../LinkButton";
import { FileNameIcon } from "./FileIcon";

const ListView = ({ filter, allFiles, actions: { onDeleteFile, updateFile, onCheckItem, onOpenFile }, downloadState, actionsAllowed }) => {
  const [state, setState] = useState({ files: [], allChecked: false });
  const { files, allChecked } = state;
  const sort = (a, b) => a.fileName.localeCompare(b.fileName);
  useEffect(() => {
    setState({
      ...state,
      files: allFiles
        .map(f => ({ ...f, description: f.description || "", checked: (files.find(_f => _f.id === f.id) || {}).checked === true }))
        .sort(sort)
    });
  }, [allFiles]);

  const showFiles = files.filter(
    x => !filter || RegexFilter(filter, x.fileName) || RegexFilter(filter, x.folder.name) || RegexFilter(filter, x.description)
  );

  const onAllChechChange = ({ target: { checked } }) => {
    setState({ ...state, files: files.map(file => ({ ...file, checked })), allChecked: checked });
    onCheckItem(files.map(({ id }) => ({ id, file: true, checked })));
  };
  const onCheckChange = ({ id }) => ({ target: { checked } }) => {
    const newFiles = files.map(file => (file.id === id ? { ...file, checked } : file));
    setState({ ...state, files: newFiles, allChecked: !newFiles.some(file => !file.checked) });
    onCheckItem([{ id, file: true, checked }]);
  };

  useEffect(() => {
    if (downloadState.timestamp) onAllChechChange({ target: { checked: false } });
  }, [downloadState.timestamp]);

  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(onDeleteFile, "Cancel", "Yes, Delete");

  const handleConfirm = file => {
    handleCustomConfirm({
      oData: { isQueue: file.folder.isQueue, ...file },
      titleText: `Delete Document`,
      messageText: `The following document is going to be deleted: ${file.fileName}. Are you sure you want to proceed?`
    });
  };
  const tableProps = {
    columns: [
      {
        title: <Checkbox checked={allChecked} onChange={onAllChechChange} color="primary" />,
        render: r => <Checkbox {...{ checked: r.checked, onChange: onCheckChange(r) }} color="primary" />,
        sorting: false,
        width: null,
        cellStyle: { width: 1, padding: 0 },
        headerStyle: { padding: 0 }
      },
      {
        title: "Name",
        customSort: sort,
        render: r => {
          return (
            <LinkButton onClick={() => onOpenFile(r)} style={{ textAlign: "initial" }}>
              <FileNameIcon file type={r.fileType} name={r.fileName} wrap="nowrap" />
            </LinkButton>
          );
        }
      },
      { title: "Date", field: "uploadedOn", type: "date", editable: "never" },
      { title: "Folder", field: "folder.name", editable: "never" },
      { title: "Description", field: "description" }
    ],
    data: showFiles,
    onActionSave: file => updateFile(file),
    editableAttr: {
      isEditHidden: r => r.folder?.isQueue || typeof updateFile !== "function"
    },
    actions: [
      () =>
        actionsAllowed?.delete && typeof onDeleteFile === "function"
          ? {
              icon: () => <DeleteIcon />,
              tooltip: "Delete",
              onClick: (_e, rowData) => {
                _e.stopPropagation();
                handleConfirm(rowData);
              }
            }
          : undefined
    ]
  };
  return (
    <>
      <Table {...tableProps} hideToolbar hidePaging />
      <Confirm />
    </>
  );
};

ListView.propTypes = {
  filter: PropTypes.string.isRequired,
  allFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  downloadState: PropTypes.shape(progressProps).isRequired,
  actionsAllowed: PropTypes.shape({}).isRequired
};

export default ListView;
