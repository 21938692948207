import { PROFILE, API } from "../actions/actionTypes";
import { takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "./common/sagaGenerator";
import rootSagaGenerator from "./common/rootSagaGenerator";
import { getSuccess, uploadPhotoSuccess, setSuccess, delPhotoSuccess, getPhotoSuccess } from "../actions/profile";
import { objectToFormData } from "../../api/apiUtils";

const url = `${API.URL}/me`;

export default rootSagaGenerator({
  name: "profile",
  sagas: [
    takeLatest({ type: PROFILE.GET, actionSuccess: getSuccess, errorMessage: "Error loading user's profile", url }),
    takeLatest({
      type: PROFILE.PHOTO.GET,
      actionSuccess: getPhotoSuccess,
      errorMessage: "Error loading user's photo",
      url: `${url}/photo`
    }),
    takeLatestSet({
      type: PROFILE.SET,
      actionSuccess: setSuccess,
      successMessage: "Profile updated",
      errorMessage: "Error updating user's profile",
      url,
      params: ({ data }) => data
    }),
    takeLatestPost({
      type: PROFILE.POST,
      actionSuccess: uploadPhotoSuccess,
      errorMessage: "Error uploading photo",
      url,
      params: ({ file }) => objectToFormData({ file })
    }),
    takeLatestDel({ type: PROFILE.DEL, actionSuccess: delPhotoSuccess, url, errorMessage: "Error deleting photo" })
  ]
});
