import React, { useRef } from "react";
import { Grid, MuiThemeProvider, TablePagination } from "@material-ui/core";
import { CsvBuilder } from "filefy";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { v4 as uuid } from "uuid";
import MaterialTable, { MTableAction, MTableGroupbar, MTableGroupRow, MTableToolbar } from "material-table";
import PropTypes from "prop-types";
import { childrenPropType } from "../../../common";
import { baseTableTheme } from "../../App.styles";
import CustomGroupRow from "./CustomGroupRow";

const customExport = fnExport => ({ exportFileName, filterColumn, mapData }) => (columnList, initialData) => {
  const fnFilter =
    typeof filterColumn === "function" ? filterColumn : columnDef => !columnDef.hidden && columnDef.field && columnDef.export !== false;
  const columns = columnList.filter(fnFilter);
  const fnMap = typeof mapData === "function" ? mapData : rowData => columns.map(columnDef => rowData[columnDef.field]);
  const data = initialData.map(fnMap);
  return fnExport(columns, data, exportFileName);
};

export const customExportCsv = customExport((columns, data, exportFileName) => {
  const builder = new CsvBuilder(`${exportFileName}.csv`);
  builder
    .setDelimeter(",")
    .setColumns(columns.map(columnDef => columnDef.exportTitle || columnDef.title))
    .addRows(data)
    .exportFile();
});

export const customExportPdf = customExport((columns, data, exportFileName) => {
  const content = {
    startY: 50,
    head: [columns.map(columnDef => columnDef.exportTitle || columnDef.title)],
    body: data
  };

  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";

  const doc = new JsPDF(orientation, unit, size);
  doc.setFontSize(15);
  doc.text(exportFileName, 40, 40);
  doc.autoTable(content);
  doc.save(`${exportFileName}.pdf`);
});

const intl = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

const DataTable = ({
  actions,
  columns,
  data,
  onDelete,
  onActionSave,
  hideToolbar,
  hidePaging,
  hideGroupbar,
  hideExportButton,
  paginationToolBar,
  ButtonToolBar,
  ButtonToolBarPreviousSearch,
  options: opts,
  customGroupConfig,
  onGroupingCheckChanged,
  editableAttr,
  componentsAttr,
  ...other
}) => {
  const options = { ...(opts || {}), actionsColumnIndex: -1 };
  if (hidePaging) options.paging = false;
  if (hideToolbar) {
    options.toolbar = false;
    options.exportButton = false;
  } else if (!hideExportButton) {
    options.exportButton = true;
  }

  options.exportPdf =
    options.exportPdf ||
    customExportPdf({
      exportFileName: options.exportFileName || uuid(),
      mapData: rowData =>
        columns.map(
          columnDef =>
            (columnDef.type === "date" && rowData[columnDef.field] && moment(rowData[columnDef.field]).format("M/D/YYYY")) ||
            (columnDef.type === "currency" && intl.format(rowData[columnDef.field])) ||
            rowData[columnDef.field]
        )
    });

  options.exportCsv =
    options.exportCsv ||
    customExportCsv({
      exportFileName: options.exportFileName || uuid(),
      mapData: rowData =>
        columns.map(
          columnDef =>
            (columnDef.type === "date" && rowData[columnDef.field] && moment(rowData[columnDef.field]).format("M/D/YYYY")) ||
            (columnDef.type === "currency" && intl.format(rowData[columnDef.field])) ||
            rowData[columnDef.field]
        )
    });

  const tableRef = useRef(null);
  const fnPromise = action => (typeof action === "function" ? row => new Promise(r => r(action(row))) : undefined);
  return (
    <MuiThemeProvider theme={baseTableTheme}>
      <MaterialTable
        components={{
          Pagination: props => !paginationToolBar && !hidePaging && <TablePagination {...props} />,
          Toolbar: props => (
            <Grid container justify="flex-end" alignItems="center" spacing={1}>
              {!!ButtonToolBarPreviousSearch && (
                <Grid item xs>
                  <ButtonToolBarPreviousSearch />
                </Grid>
              )}
              <Grid item xs>
                <MTableToolbar {...props} />
              </Grid>
              {paginationToolBar && !hidePaging && !!tableRef.current && (
                <Grid item>
                  <TablePagination
                    labelRowsPerPage=""
                    rowsPerPageOptions={[]}
                    component="div"
                    labelDisplayedRows={row => `Total records: ${row.from}-${row.to} of ${row.count}`}
                    count={tableRef.current.state.data.length}
                    rowsPerPage={tableRef.current.state.pageSize}
                    page={tableRef.current.state.currentPage}
                    onChangePage={tableRef.current.onChangePage}
                    onChangeRowsPerPage={tableRef.current.onChangeRowsPerPage}
                  />
                </Grid>
              )}
              {ButtonToolBar && <ButtonToolBar />}
            </Grid>
          ),
          Container: ({ children }) => children,
          Action: props => {
            const {
              // eslint-disable-next-line react/prop-types
              action: { custom, icon: Icon, onClick },
              data: d
            } = props;
            if (custom) return <Icon onClick={event => typeof onClick === "function" && onClick(event, d)} data={d} />;
            return <MTableAction {...props} />;
          },
          Groupbar: props => <div>{!hideGroupbar && <MTableGroupbar {...props} />}</div>,
          GroupRow: props =>
            (customGroupConfig !== null && (
              <CustomGroupRow {...props} onGroupingCheckChanged={onGroupingCheckChanged} customGroupConfig={customGroupConfig} />
            )) || <MTableGroupRow {...props} />,
          ...(componentsAttr || {})
        }}
        editable={{
          isEditable: r => typeof onActionSave === "function" && r.editable !== false,
          isDeletable: r => typeof onDelete === "function" && r.editable !== false,
          onRowAdd: fnPromise(onActionSave),
          onRowUpdate: fnPromise(onActionSave),
          onRowDelete: fnPromise(onDelete),
          ...(editableAttr || {})
        }}
        {...{ columns, data, options, actions, tableRef, ...other }}
      />
    </MuiThemeProvider>
  );
};

DataTable.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, childrenPropType()]),
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.func])),
  onDelete: PropTypes.func,
  onActionSave: PropTypes.func,
  hideToolbar: PropTypes.bool,
  hidePaging: PropTypes.bool,
  hideGroupbar: PropTypes.bool,
  hideExportButton: PropTypes.bool,
  paginationToolBar: PropTypes.bool,
  ButtonToolBar: PropTypes.func,
  ButtonToolBarPreviousSearch: PropTypes.func,
  options: PropTypes.objectOf(PropTypes.any),
  customGroupConfig: PropTypes.objectOf(PropTypes.any),
  onGroupingCheckChanged: PropTypes.func,
  editableAttr: PropTypes.objectOf(PropTypes.any),
  componentsAttr: PropTypes.objectOf(PropTypes.func)
};

DataTable.defaultProps = {
  title: "",
  hideToolbar: false,
  hidePaging: false,
  hideGroupbar: true,
  hideExportButton: false,
  actions: [],
  ButtonToolBar: null,
  ButtonToolBarPreviousSearch: null,
  paginationToolBar: true,
  onDelete: null,
  onActionSave: null,
  options: null,
  customGroupConfig: null,
  onGroupingCheckChanged: () => {},
  editableAttr: undefined,
  componentsAttr: undefined
};

export default DataTable;
