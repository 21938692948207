import { CLAIM } from "../actionTypes";
import * as docActions from "../common/documents";

const success = type => documents => ({ type, documents });

export const loadByClaimId = (claimId, onSuccess, onError) => docActions.getByParentId(CLAIM.DOCUMENTS.GET)({ claimId }, { onSuccess, onError });
export const deleteFile = docActions.withParams(CLAIM.DOCUMENTS.DEL_FILE);
export const updateFile = docActions.withParams(CLAIM.DOCUMENTS.SET_FILE);
export const addFolder = docActions.withParams(CLAIM.DOCUMENTS.POST_FOLDER);
export const updateFolder = docActions.withParams(CLAIM.DOCUMENTS.SET_FOLDER);
export const deleteFolder = docActions.withParams(CLAIM.DOCUMENTS.DEL_FOLDER);
export const uploadFiles = docActions.uploadFiles(CLAIM.DOCUMENTS.POST_FILES);
export const downloadFiles = docActions.downloadFiles(CLAIM.DOCUMENTS.DOWNLOAD_FILES);
export const openFile = docActions.openFile(CLAIM.DOCUMENTS.OPEN_FILE);
export const approve = (data, onSuccess, onError) => ({ type: CLAIM.DOCUMENTS.APPROVE, data, onSuccess, onError });
export const approveSuccess = success(CLAIM.DOCUMENTS.APPROVE_SUCCESS);
export const reindex = (data, onSuccess, onError) => ({ type: CLAIM.DOCUMENTS.REINDEX, data, onSuccess, onError });
export const reindexSuccess = success(CLAIM.DOCUMENTS.REINDEX_SUCCESS);
export const moveFiles = docActions.moveFiles(CLAIM.DOCUMENTS.MOVE_FILE);
