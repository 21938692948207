import { ADMIN, CLAIM } from "../../actionTypes";
import * as docActions from "../../common/documents";

const success = type => communications => ({ type, communications });

export const getAll = (onSuccess, onError) => ({ type: ADMIN.TEMPLATE_COMMUNICATION.GETALL, onSuccess, onError });
export const getAllSuccess = success(ADMIN.TEMPLATE_COMMUNICATION.GETALL_SUCCESS);
export const set = (data, onSuccess, onError) => ({ type: ADMIN.TEMPLATE_COMMUNICATION.SET, data, onSuccess, onError });
export const setSuccess = success(ADMIN.TEMPLATE_COMMUNICATION.SET_SUCCESS);
export const del = (id, onSuccess, onError) => ({ type: ADMIN.TEMPLATE_COMMUNICATION.DEL, id, onSuccess, onError });
export const delSuccess = success(ADMIN.TEMPLATE_COMMUNICATION.DEL_SUCCESS);
export const openFile = docActions.openFile(CLAIM.DOCUMENTS.OPEN_FILE);
