import { FINANCIAL } from "./actionTypes";

export const getAllProcessingQueue = (onSuccess, onError) => ({ type: FINANCIAL.PROCESSINGQUEUE.GETALL, onSuccess, onError });
export const getAllProcessingQueueSuccess = processingQueue => ({ type: FINANCIAL.PROCESSINGQUEUE.GETALL_SUCCESS, processingQueue });

export const getAllScheduled = (onSuccess, onError) => ({ type: FINANCIAL.SCHEDULED.GETALL, onSuccess, onError });
export const getAllScheduledSuccess = scheduled => ({ type: FINANCIAL.SCHEDULED.GETALL_SUCCESS, scheduled });

export const getAllHistory = (onSuccess, onError) => ({ type: FINANCIAL.HISTORY.GETALL, onSuccess, onError });
export const getAllHistorySuccess = history => ({ type: FINANCIAL.HISTORY.GETALL_SUCCESS, history });

export const getAllSettlements = (onSuccess, onError) => ({ type: FINANCIAL.SETTLEMENTS.GETALL, onSuccess, onError });
export const getAllSettlementsSuccess = settlements => ({ type: FINANCIAL.SETTLEMENTS.GETALL_SUCCESS, settlements });
