import { useReducer } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { API } from "../../../redux/actions/actionTypes";

const initialState = {
  isLoading: false,
  error: "",
  response: null
};

const useAxios = () => {
  const [state, dispatch] = useReducer((reducerState, action) => {
    switch (action.type) {
      case "IN_PROCCESS":
        return { ...reducerState, isLoading: true };
      case "SET_SUCCESS":
        return { ...reducerState, isLoading: false, response: action.payload };
      case "SET_ERROR": {
        return { ...reducerState, isLoading: false, response: null, error: action.payload };
      }
      default:
        return reducerState;
    }
  }, initialState);

  const { isLoading: requestInProcess, error, response } = state;

  const sendRequest = async ({ request, onSucces, multiple, onError }) => {
    dispatch({ type: "IN_PROCCESS" });
    const headers = {
      // eslint-disable-next-line
      Authorization: sessionStorage.getItem("Authorization")
    };
    try {
      if (multiple) {
        const res = await Promise.all(request.map(r => axios({ ...r, url: `${API.URL}${r.url}`, headers })));
        if (res) {
          onSucces(res);
        }
      } else {
        const resp = await axios({ ...request, url: `${API.URL}${request.url}`, headers });
        if (resp) {
          dispatch({ type: "SET_SUCCESS", payload: resp.data });
          onSucces(resp.data);
        }
      }
    } catch (e) {
      if (onError) onError();
      else toast.error("Request error");
      dispatch({ type: "SET_ERROR", payload: "Error" });
    }
  };

  return {
    requestInProcess,
    error,
    response,
    sendRequest
  };
};

export default useAxios;
