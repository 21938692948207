import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../common/CollapsibleBox";
import WcClaimForm from "../../common/claims/wcClaim/Form";
import WcClaimViewMode from "../../common/claims/wcClaim/ViewMode";
import { loadByClaimId, set } from "../../../redux/actions/claim/wcClaim";
import ActionsAllowedContext from "../../common/contexts/ActionsAllowed";

const ClaimBox = props => {
  const irStore = useSelector(({ initialReport }) => initialReport, shallowEqual);
  const { claim } = irStore || {};
  const id = claim?.id;
  const wcClaim = useSelector(store => store.claim.wcClaim, shallowEqual);
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info");
  const {
    props: { boxProps, editMode, loading: isLoading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  const dispatch = useDispatch();
  useEffect(() => {
    if (id && access.read && !wcClaim?.id) {
      showLoading();
      dispatch(loadByClaimId(id, hideLoading, hideLoading));
    }
  }, [id]);
  const onSave = data => {
    if (access.update) {
      showLoading();
      dispatch(set(data, onSuccessEdit, hideLoading));
    }
  };
  return (
    <CollapsibleBox title="Incident" name="wcClaim" {...boxProps} {...props}>
      {Loading}
      {(editMode && <WcClaimForm isReportOnly {...{ claim: wcClaim, onClose: onToggleEdit, onSave, isLoading }} />) || (
        <WcClaimViewMode isReportOnly {...{ claim: wcClaim }} />
      )}
    </CollapsibleBox>
  );
};

export default ClaimBox;
