import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Divider } from "@material-ui/core";
import LabelValue from "../../LabelValue";
import MultipleLabels from "../../Labels";

const WcClaimViewMode = ({ claim, isReportOnly }) => {
  const store = useSelector(({ common: { processingStatus: ps, injuryType: it } }) => ({ processingStatusList: ps, injuryTypes: it }), shallowEqual);
  const { processingStatusList, injuryTypes } = store;
  const {
    number,
    dateReported,
    processingStatusId,
    statusDate,
    dateEntry,
    processingStatusDate,
    type,
    status,
    processingStatus,
    claimLossType,
    deniedDescription,
    lateReason,
    reportedBy,
    contactMethod,
    claimCauseCode,
    ediClaimType,
    stateNumber,
    dateofLoss
  } = claim || {};
  const {
    froiReceivedDate,
    beganWorkTime,
    lastWorkDate,
    beganDisabilityDate,
    onEmployerPremises,
    lossTimeStatus,
    lossTimeMore,
    description,
    equipmentInvolved,
    equipmentInvolvedDescription,
    activityAtTheMoment,
    workProcessAtTheMoment,
    returnDate,
    safetyEquipmentProvided,
    safetyEquipmentProvidedUsed
  } = claim?.employeeAccident || {};
  const isDenied = processingStatusList.find(x => x.id === processingStatusId)?.text === "Denied";
  const {
    injuryType,
    injuryTypeId,
    claimantDateofDeath,
    description: injuryDescription,
    treatmentDescription,
    bodyPart,
    parentBodyPart,
    treatmentReceived,
    treatmentType,
    physicianName,
    hospital,
    typeOfCoverageCodeName,
    ncciInjuryCodeName
  } = claim?.bodilyInjury || {};
  const isDeath = injuryTypes.some(t => t.id === injuryTypeId && t.isDeath);
  return (
    <React.Fragment key="claimedit">
      <form style={{ padding: "10px" }}>
        {!isReportOnly ? (
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <MultipleLabels
                data={[
                  { label: "Claim #", value: number, key: "claim_number" },
                  { label: "Line Of Business", value: "WC", key: "claim_line_of_business" },
                  { label: "FROI Received Date", value: froiReceivedDate, isDate: true, key: "employeeAccident_froi_recived_date" },
                  { label: "Date Reported", value: dateReported, isDate: true, key: "claim_date_reported" }
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <MultipleLabels
                data={[
                  { label: "Claim Status", value: status, key: "claim_status" },
                  { label: "Claim Status Date", value: statusDate, isDate: true, key: "claim_status_date" },
                  { label: "Claim Entry", value: dateEntry, isDate: true, key: "claim_entry" },
                  { label: "Late Reason", value: lateReason, key: "claim_lateReason" }
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <MultipleLabels
                data={[
                  { label: "Processing Status", value: processingStatus, key: "claim_proccessing_status" },
                  { label: "Processing Status Date", value: processingStatusDate, isDate: true, key: "claim_status_date" },
                  { label: "Claim Type", value: type, key: "claim_type" },
                  { label: "Claim Reported By", value: reportedBy, key: "claim_reportedBy" }
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <MultipleLabels
                data={[
                  { label: "Method of Contact/Report", value: contactMethod, key: "claim_contactMethod" },
                  { label: "Last Work Date", value: lastWorkDate, isDate: true, key: "claim_lastWorkDate" },
                  { label: "Date Disability Began", value: beganDisabilityDate, isDate: true, key: "claim_beganDisabilityDate" }
                ]}
              />
              <LabelValue label="Time employee began to work" value={beganWorkTime} isDate formatHH />
            </Grid>
            <Grid item xs={12}>
              {isDenied === true && <LabelValue label="Claim Denied Description" value={deniedDescription || ""} />}
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <MultipleLabels
                data={[
                  { label: "FROI Received Date", value: froiReceivedDate, isDate: true, key: "employeeAccident_froi_recived_date" },
                  { label: "Date Reported", value: dateReported, isDate: true, key: "claim_date_reported" },
                  { label: "Late Reason", value: lateReason, key: "claim_lateReason" }
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <MultipleLabels
                data={[
                  { label: "Claim Type", value: type, key: "claim_type" },
                  { label: "Reported By", value: reportedBy, key: "claim_reportedBy" }
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <MultipleLabels
                data={[
                  { label: "Method of Contact/Report", value: contactMethod, key: "claim_contactMethod" },
                  { label: "Last Work Date", value: lastWorkDate, isDate: true, key: "claim_lastWorkDate" }
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <LabelValue label="Date Disability Began" value={beganDisabilityDate} isDate />
              <LabelValue label="Time employee began to work" value={beganWorkTime} isDate formatHH />
            </Grid>
          </Grid>
        )}

        <Divider style={{ margin: "15px 0px 15px 0px" }} />
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <LabelValue label="Date of Injury" value={dateofLoss} isDate />
            <LabelValue label="Time of Injury" value={dateofLoss} isDate formatHH />
            <LabelValue label="Accident Address" value={claim?.location} isAddress />
          </Grid>
          <Grid item xs={3}>
            <LabelValue label="Accident on Employer’s Premises" value={onEmployerPremises ? "YES" : "NO"} />
            <LabelValue label="Loss Time Status" value={lossTimeStatus} />
            <LabelValue label="Loss time more than 7 days" value={lossTimeMore ? "YES" : "NO"} />
            <LabelValue label="Accident Description" value={description} />
          </Grid>
          <Grid item xs={3}>
            <LabelValue label="Equipment, material or chemicals involved" value={equipmentInvolved ? "YES" : "NO"} />
            <LabelValue label="Description" value={equipmentInvolvedDescription} />
            <LabelValue label="Employee Specific Activity at the moment of the accident" value={activityAtTheMoment} />
            <LabelValue label="Employee Work Process at the moment of the accident" value={workProcessAtTheMoment} />
          </Grid>
          <Grid item xs={3}>
            <LabelValue label="Date Return (ed) to Work " value={returnDate} isDate />
            <LabelValue label="Safeguards or Safety Equipment provided? " value={safetyEquipmentProvided ? "YES" : "NO"} />
            <LabelValue label="Were they used?" value={safetyEquipmentProvidedUsed ? "YES" : "NO"} />
          </Grid>
        </Grid>
        <Divider style={{ margin: "15px 0px 15px 0px" }} />
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <LabelValue label="Nature of Injury" value={injuryType} />
            {isDeath && <LabelValue label="Date of Death" value={claimantDateofDeath} isDate />}
            <LabelValue label="Body Part Group" value={parentBodyPart} />
            <LabelValue label="Body Part" value={bodyPart} />
          </Grid>
          <Grid item xs={3}>
            <LabelValue label="Cause Code" value={claimCauseCode} />
            <MultipleLabels
              data={[
                { label: "NCCI Injury Code", value: ncciInjuryCodeName, key: "bodily_injury_ncci_code " },
                { label: "Loss Type", value: claimLossType, key: "claim_loss_type" }
              ]}
            />
          </Grid>
          <Grid item xs={3}>
            <MultipleLabels
              data={[
                { label: "Type of Coverage Code", value: typeOfCoverageCodeName, key: "claim_coverage_code" },
                { label: "EDI Claim Type", value: ediClaimType, key: "claim_edi_claim_type" },
                { label: "State Claim Number", value: stateNumber, key: "claim_state_number" }
              ]}
            />
          </Grid>
          <Grid item xs={3}>
            <LabelValue label="Initial Treatment Received" value={treatmentReceived ? "YES" : "NO"} />
          </Grid>
        </Grid>
        {injuryDescription && <LabelValue label="Bodily Injury Description" value={injuryDescription} />}
        {treatmentReceived && (
          <>
            <Divider style={{ margin: "15px 0px 15px 0px" }} />
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <MultipleLabels
                  data={[
                    { label: "Initial Treatment Type", value: treatmentType, key: "treatment_received" },
                    { label: "Initial Hospital Physician Name ", value: physicianName, key: "physician_name" }
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <MultipleLabels
                  data={[
                    { label: "Hospital Name", value: hospital?.name, key: "bodily_hospital_name" },
                    { label: "Phone number", value: hospital?.phone, key: "bodily_hospital_phone_number" }
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <MultipleLabels data={[{ label: "Hospital Address", value: hospital?.location, isAddress: true, key: "bodily_hospital_address" }]} />
              </Grid>
              <Grid item xs={3}>
                <MultipleLabels data={[{ label: "Treatment Description", value: treatmentDescription, key: "bodily_treatment_description" }]} />
              </Grid>
            </Grid>
          </>
        )}
      </form>
    </React.Fragment>
  );
};

WcClaimViewMode.propTypes = {
  claim: PropTypes.objectOf(PropTypes.any).isRequired,
  isReportOnly: PropTypes.bool
};

WcClaimViewMode.defaultProps = {
  isReportOnly: false
};

export default WcClaimViewMode;
