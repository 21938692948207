import { makeStyles } from "@material-ui/core";

const sel = ix => `tr > & > span > div[data-rbd-draggable-id="${ix}"]:before`;

const reservesClasses = (reserved, total, outstanding) => ({
  [`${sel(reserved)}, ${sel(total)}, ${sel(outstanding)}`]: {
    content: '""',
    width: 8,
    height: 8,
    borderRadius: "50%",
    display: "inline-block",
    marginRight: 8
  },
  [sel(reserved)]: { backgroundColor: "#1599BC" },
  [sel(total)]: { backgroundColor: "#898F91" },
  [sel(outstanding)]: { backgroundColor: "#A7CE05" }
});

const CurrentReserveStyles = makeStyles(theme => ({
  tableClaim: reservesClasses(3, 4, 5),
  tableOcurrence: reservesClasses(3, 4, 5),
  tableType: reservesClasses(1, 2, 3),
  root: { padding: theme.spacing(1, 2), marginBottom: theme.spacing(2) },
  title: { color: "#428302", flexGrow: 1 },
  reserved: { backgroundColor: "#1599BC" },
  total: { backgroundColor: "#898F91" },
  oustanding: { backgroundColor: "#A7CE05" },
  sumGrid: { margin: theme.spacing(2, 0, 2.25, 0) },
  bar: { paddingTop: "40px" }
}));

export default CurrentReserveStyles;
