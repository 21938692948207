import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "./BillForm";
import useAxios from "../../../common/customHooks/useAxios";
import { useLoadingContext } from "../../../common/Loading";

const Edit = ({ billId, onSave, onCancel, onReindex, onDuplicate, onDelete }) => {
  const [bill, setBill] = useState({});
  const { sendRequest } = useAxios();
  const {
    actions: { hideLoading, showLoading }
  } = useLoadingContext();
  useEffect(() => {
    showLoading();
    sendRequest({
      request: {
        method: "GET",
        url: `/bill?id=${billId}`
      },
      onSucces: data => {
        setBill({ ...data, query: data?.vendorName, vendorTypeId: data?.vendorCategoryId });
        return hideLoading();
      }
    });
  }, []);
  return <Form editMode data={bill} onSave={onSave} onCancel={onCancel} onReindex={onReindex} onDuplicate={onDuplicate} onDelete={onDelete} />;
};
Edit.propTypes = {
  billId: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReindex: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default Edit;
