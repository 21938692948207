import { put, takeLatest, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { SELECTS, API } from "../actions/actionTypes";
import { api } from "../../api/apiUtils";
import { beginApiCall, apiCallError } from "../actions/apiStatusActions";
import { getSuccess, getAllCarriersSuccess } from "../actions/commonActions";
import { takeLatest as takeLatestGenerator } from "./common/sagaGenerator";
import rootSagaGenerator from "./common/rootSagaGenerator";

const select = path => ({ url: `${API.URL}/select/${path}` });

export function* get() {
  try {
    yield put(beginApiCall());
    const selects = yield all({
      claimType: call(api, select("claimType")),
      claimStatus: call(api, select("claimStatus")),
      gender: call(api, select("genders")),
      states: call(api, select("states")),
      maritalStatus: call(api, select("maritalStatus")),
      processingStatus: call(api, select("processingStatus")),
      taxType: call(api, select("taxIdType")),
      coverageTypes: call(api, select("coverageTypes")),
      claimLossTypeGroups: call(api, select("claimlosstypegroups")),
      claimLossTypes: call(api, select("claimlosstypes")),
      claimCauseCodes: call(api, select("claimcausecodes")),
      departamentTypes: call(api, select("departamentTypes")),
      propertyTypes: call(api, select("propertyTypes")),
      propertyDamagedLossTypes: call(api, select("propertyDamagedLossTypes")),
      propertyDamagedTypes: call(api, select("propertyDamagedTypes")),
      bodyPart: call(api, select("bodyPart")),
      bodyPartGroup: call(api, select("bodyPartGroup")),
      injuryType: call(api, select("injuryType")),
      treatmentType: call(api, select("treatmentType")),
      hospitalLocation: call(api, { url: `${API.URL}/hospital` }),
      vehicleBodyType: call(api, select("vehicleBodyType")),
      vehicleMake: call(api, select("vehicleMake")),
      vehicleDamageType: call(api, select("vehicleDamageType")),
      codeType: call(api, select("codeType")),
      codeDivision: call(api, select("codedivision")),
      codes: call(api, select("codes")),
      paymentMethods: call(api, select("paymentMethods")),
      vendorTypes: call(api, select("vendorTypes")),
      lawFirms: call(api, select("lawFirm")),
      veredictType: call(api, select("veredictType")),
      appealStatus: call(api, select("appealStatus")),
      veredictStatus: call(api, select("veredictStatus")),
      taskStatus: call(api, select("taskStatus")),
      taskPriority: call(api, select("taskPriority")),
      taskType: call(api, select("taskType")),
      sizeTypes: call(api, select("sizeTypes")),
      clientTypes: call(api, select("clientTypes")),
      users: call(api, select("users")),
      codetypes: call(api, select("codeType")),
      accountTypes: call(api, select("accountTypes")),
      insuredLocationTypes: call(api, select("insuredLocationTypes")),
      policyTypes: call(api, select("policyTypes")),
      policyCoverageTypes: call(api, select("policyCoverageTypes")),
      producers: call(api, select("producers")),
      insurers: call(api, select("insurers")),
      purposeTypes: call(api, select("purposeTypes")),
      rreSources: call(api, select("rreSources")),
      corridorErosions: call(api, select("corridorErosions")),
      coverageTriggers: call(api, select("coverageTriggers")),
      riskExposureTypes: call(api, select("riskExposureTypes")),
      vendorCategories: call(api, select("vendorcategories")),
      contactCategories: call(api, select("contactCategories")),
      memberCategories: call(api, select("membercategories")),
      insuredCategories: call(api, select("insuredcategories")),
      attachmentBasis: call(api, select("attachmentbasis")),
      reserveStatus: call(api, select("reservestatus")),
      communicationType: call(api, select("communicationType")),
      timeZone: call(api, select("timeZone")),
      autoDayAfterTypes: call(api, select("autoDayAfterTypes")),
      claimUserTypes: call(api, select("claimUserTypes")),
      basicPeriodTypes: call(api, select("basicPeriodTypes")),
      reindexReasons: call(api, select("reindexReasons")),
      schedulePaymentTypes: call(api, select("schedulePaymentTypes")),
      recurrenceTypes: call(api, select("recurrenceTypes")),
      vendorStatus: call(api, select("vendorStatus")),
      recoveryTypes: call(api, select("recoveryTypes")),
      recoveryStatus: call(api, select("recoveryStatus")),
      employmentStatus: call(api, select("employmentStatus")),
      lossTimeStatus: call(api, select("lossTimeStatus")),
      payrollClassCodeTypes: call(api, select("payrollClassCodeTypes")),
      ratePerTypes: call(api, select("ratePerTypes")),
      ediClaimTypes: call(api, select("ediClaimTypes")),
      contactMethods: call(api, select("contactMethods")),
      ncciInjuryCodes: call(api, select("ncciInjuryCode")),
      typeOfCoverageCodes: call(api, select("typeOfCoverageCode"))
    });

    yield put(getSuccess(selects));
  } catch (e) {
    yield put(apiCallError());
    yield call(toast.error, "Selects error.");
  }
}

export default rootSagaGenerator({
  name: "Common",
  sagas: [
    takeLatest(SELECTS.LOAD, get),
    takeLatestGenerator({
      type: SELECTS.GET_ALL_VENDOR_CARRIERS,
      actionSuccess: getAllCarriersSuccess,
      errorMessage: "Error loading Vendor Carriers",
      ...select("vendorcarriers")
    })
  ]
});
