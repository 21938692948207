import React from "react";
import { useFormikContext } from "formik";
import StatusForm from "./forms/StatusForm";

const Status = () => {
  const {
    values: { clientStatus },
    handleChange,
    errors
  } = useFormikContext();
  return <StatusForm {...{ clientStatus, handleChange, errors }} />;
};

export default Status;
