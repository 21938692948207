import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../common/CollapsibleBox";
import InsuredInformation from "../../common/claims/InsuredInformation";
import { useRouteParam } from "../../../common";
import { get, setChild } from "../../../redux/actions/initialReport/insuredInformation";
import ActionsAllowedContext from "../../common/contexts/ActionsAllowed";

const InsuredBox = props => {
  const store = useSelector(({ initialReport: { information } }) => information, shallowEqual);
  const { insured } = store || {};
  const { claim } = useSelector(({ initialReport }) => initialReport, shallowEqual);
  const dispatch = useDispatch();
  const claimId = useRouteParam("claimId");
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info") || {};
  const {
    props: { boxProps, editMode, loading: isLoading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  const onSave = childId => {
    if (access.update) {
      showLoading();
      dispatch(setChild(childId, claimId, onSuccessEdit, hideLoading));
    }
  };
  return (
    <CollapsibleBox title={claim.claimLineOfBusinessId !== 4 ? "Insured" : "Employer/Insured"} name="insured" {...boxProps} {...props}>
      {Loading}
      <InsuredInformation {...{ insured, editMode, onClose: onToggleEdit, onSave, isLoading }} />
    </CollapsibleBox>
  );
};

export default InsuredBox;
