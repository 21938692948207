import { combineDateTime } from "../../../common";

const mapObject = (obj, keys) =>
  Object.assign(
    {},
    ...Object.keys(obj)
      .filter(k => keys.some(_k => k === _k))
      .map(k => ({ [k]: obj[k] }))
  );

export const mapLineOfBusiness = (coverageTypes, allData = false) => {
  const ltgs = ct =>
    ct.lossTypeGroups
      .filter(_ltg => _ltg.checked && _ltg.lossTypeId && _ltg.causeCodeId)
      .reduce(
        (acc, { id, lossTypeId, causeCodeId, isReportOnly, adjusterUserId, supervisorUserId, assistantUserId }) => [
          ...acc,
          {
            coverageTypeId: ct.id,
            claimLineOfBusinessId: ct.id,
            lossTypeGroupId: id,
            lossTypeId,
            causeCodeId,
            ...(allData ? { isReportOnly, adjusterUserId, supervisorUserId, assistantUserId } : {})
          }
        ],
        []
      );
  return coverageTypes.reduce((a, ct) => [...a, ...ltgs(ct)], []);
};

const mapClaimant = c => Object.keys(c).reduce((a, p) => (p === "closed" ? a : { ...a, [p]: c[p] }), {});

export const mapPostData = ({
  multipleClaimants,
  claimant,
  claimants,
  insured: { id: insuredId, child, policyId },
  insVehicle: insuredVehicle,
  insProperty: { id: insPropertyId },
  incident,
  incident: { dateofLoss, timeofInjury },
  coverageTypes,
  damagedProperty,
  damagedVehicle,
  bodilyInjury,
  witness,
  documents,
  attorneys
}) => {
  return {
    multipleClaimants,
    claimant: multipleClaimants ? null : claimant,
    claimants: multipleClaimants ? claimants.map(mapClaimant) : null,
    insuredId,
    insuredLevelId: (child || {}).id,
    policyId,
    insuredVehicle,
    insPropertyId,
    incident: { ...incident, dateofLoss: combineDateTime(dateofLoss, timeofInjury) },
    coverageTypes: mapLineOfBusiness(coverageTypes, !multipleClaimants),
    claimLinesOfBusiness: mapLineOfBusiness(coverageTypes, !multipleClaimants),
    damagedProperty,
    damagedVehicle,
    bodilyInjury,
    witness: witness.map(w => mapObject(w, ["email", "firstName", "lastName", "note", "phone", "phone2"])),
    documents: documents.map(d => mapObject(d, ["id", "description", "folderId", "file"])),
    attorneys: attorneys.map(a => mapObject(a, ["effectiveDate", "expirationDate", "lawFirmId", "attorneyId"]))
  };
};
