import { CLIENTR as CLIENT, API } from "../../../../actions/actionTypes";
import { loadVehiclesSuccess, addVehicleSuccess, setVehicleSuccess, deleteVehicleSuccess } from "../../../../actions/client/insured/policy/vehicle";
import { takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "../../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLIENT.INSURED.POLICY.VEHICLE.GET_VEHICLES,
    actionSuccess: loadVehiclesSuccess,
    errorMessage: "Error loading vehicles",
    url: `${API.URL}/vehicle/vehiclepolicy`,
    params: ({ policyId }) => ({ policyId })
  }),
  takeLatestPost({
    type: CLIENT.INSURED.POLICY.VEHICLE.POST,
    actionSuccess: addVehicleSuccess,
    successMessage: "Covered Vehicles information successfully saved",
    url: `${API.URL}/vehicle/vehiclepolicy`,
    params: ({ data }) => data
  }),
  takeLatestSet({
    type: CLIENT.INSURED.POLICY.VEHICLE.SET,
    actionSuccess: setVehicleSuccess,
    errorMessage: "Unable to save the Covered Vehicle",
    successMessage: "Covered Vehicles information successfully saved",
    url: `${API.URL}/vehicle`,
    params: ({ data }) => data
  }),
  takeLatestDel({
    type: CLIENT.INSURED.POLICY.VEHICLE.DEL,
    actionSuccess: deleteVehicleSuccess,
    errorMessage: "Error deleting vehicle from policy",
    successMessage: "Vehicle deleted from policy",
    url: `${API.URL}/vehicle/vehiclepolicy`,
    params: ({ policyId, vehicleId }) => ({ policyId, vehicleId })
  })
];
