import React, { useEffect, useCallback } from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { ConnectedRouter } from "connected-react-router";
import { useDispatch } from "react-redux";
import Loading from "./common/Loading";
import Layout from "./layout/Index";
import theme from "./App.styles";
import { get as getSelects } from "../redux/actions/commonActions";
import { getDocumentsActive } from "../redux/actions/admin/claimsSetting";
import StyledToastContainer from "./common/ToastContainer";
import usePermissionForMenu from "./common/customHooks/usePermissionForMenu";

import Dashboard from "../containers/dashboard";
import PageNotFound from "./PageNotFound";
import MyProfilePage from "./myinfo/MyProfilePage";
import ClaimsPage from "../containers/claim/ListRoutes";
import ClaimPage from "../containers/claim";
import ClaimAddPage from "../containers/claim/ClaimAddPage";
import WcClaimAddPage from "../containers/claim/WcClaimAddPage";
import ClientList from "./clients/common/list";
import ClientPage from "../containers/client";
import ClientRedirect from "../containers/client/redirector";
import ClientAdd from "./clients/client_add";
import ReportsPage from "./reports/ReportsPage";
import VendorsPage from "../containers/vendor/ListRoutes";
import VendorPage from "../containers/vendor";
import VendorAdd from "../containers/vendor/Add";
import Documents from "../containers/documents";
import Automation from "../containers/automation";
import Intelligence from "../containers/intelligence";
import TerraMaps from "../containers/terraMaps";
import AdminPage from "../containers/admin";
import OccurrencePage from "../containers/occurrence";
import OccurrenceAddPage from "../containers/occurrence/Add";
import CatastrophePage from "../containers/catastrophe";
import CatastropheAddPage from "../containers/catastrophe/Add";
import InitialReport from "../containers/initialReport";
import Financial from "../containers/financial";
import PageNotAllowed from "./PageNotAllowed";
import { DashboardRedirect } from "./dashboard/Dashboard";

export default props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDocumentsActive());
    dispatch(getSelects());
  }, []);

  const { isLoading, permissions } = usePermissionForMenu();
  const getAllowed = useCallback((route, action) => permissions.find(e => e.routeName === route)?.[action], [permissions]);

  const routesConfig = [
    { key: "myinfo", path: "/myinfo", Component: MyProfilePage, isAllowed: getAllowed("myinfo", "read") },
    { key: "claims", path: "/claims", Component: ClaimsPage, isAllowed: getAllowed("claims", "read"), params: { actionsAllowed: permissions } },
    { key: "claim-add", path: "/claim", exact: true, Component: ClaimAddPage, isAllowed: getAllowed("claim", "create") },
    { key: "wcclaim-add", path: "/wcClaim", exact: true, Component: WcClaimAddPage, isAllowed: getAllowed("claim", "create") },
    { key: "claim", path: "/claim/:claimId", Component: ClaimPage, isAllowed: getAllowed("claim", "read") },
    { key: "catastrophe", path: "/catastrophe/:slug", Component: CatastrophePage, isAllowed: getAllowed("catastrophe", "read") },
    { key: "catastrophe-add", path: "/catastrophe", exact: true, Component: CatastropheAddPage, isAllowed: getAllowed("catastrophe", "create") },
    { key: "reports", path: "/reports", Component: ReportsPage, isAllowed: getAllowed("reports", "read") },
    { key: "documents", path: "/documents", Component: Documents, isAllowed: getAllowed("documents", "read") },
    { key: "intelligence", path: "/intelligence", Component: Intelligence, isAllowed: getAllowed("intelligence", "read") },
    { key: "terramaps", path: "/terramaps", Component: TerraMaps, isAllowed: getAllowed("terramaps", "read") },
    { key: "automation", path: "/automation", Component: Automation, isAllowed: getAllowed("automation", "read") },
    { key: "clients", path: "/clients", Component: ClientList, isAllowed: getAllowed("clients", "read") },
    { key: "client-add", path: "/client", exact: true, Component: ClientAdd, isAllowed: getAllowed("client", "create") },
    { key: "client", path: "/client/:clientId", Component: ClientPage, isAllowed: getAllowed("client", "read") },
    { key: "client-redirect", path: "/clientredirect", Component: ClientRedirect, isAllowed: getAllowed("client", "read") },
    { key: "vendors", path: "/vendors", Component: VendorsPage, isAllowed: getAllowed("vendors", "read") },
    { key: "vendor", path: "/vendor/:id", Component: VendorPage, isAllowed: getAllowed("vendor", "read") },
    { key: "vendor-add", path: "/vendor", exact: true, Component: VendorAdd, isAllowed: getAllowed("vendor", "create") },
    { key: "admin", path: "/admin", Component: AdminPage, isAllowed: getAllowed("admin", "read") },
    { key: "occurrence", path: "/occurrence/:occurrenceId", Component: OccurrencePage, isAllowed: getAllowed("occurrence", "read") },
    { key: "occurrence-add", path: "/occurrence", exact: true, Component: OccurrenceAddPage, isAllowed: getAllowed("occurrence", "create") },
    { key: "initialReport", path: "/initialReport/:claimId", Component: InitialReport, isAllowed: getAllowed("initialReport", "read") },
    { key: "dashboard", path: "/dashboard", Component: Dashboard, isAllowed: getAllowed("dashboard", "read") },
    { key: "dashboardRedirect", exact: true, path: "/", Component: DashboardRedirect, isAllowed: getAllowed("dashboard", "read") },
    { key: "financial", path: "/financial", Component: Financial, isAllowed: getAllowed("financial", "read") }
  ];
  return (
    <ConnectedRouter {...props}>
      <ThemeProvider theme={theme}>
        <Layout>
          {isLoading ? (
            <Loading />
          ) : (
            <Switch>
              {routesConfig.map(({ path, key, exact, Component, params, isAllowed }) => {
                return (
                  <Route key={key} path={path} exact={exact || false} render={() => (isAllowed ? <Component {...params} /> : <PageNotAllowed />)} />
                );
              })}
              <Route render={() => <PageNotFound />} />
            </Switch>
          )}
          <StyledToastContainer />
          <div id="buttonFooter" style={{ position: "fixed", zIndex: 10, bottom: 0, width: "calc(100% - 80px)", background: "#fff" }} />
        </Layout>
      </ThemeProvider>
    </ConnectedRouter>
  );
};
