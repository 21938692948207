import React from "react";
import PropTypes from "prop-types";
import { Pie } from "react-chartjs-2";
import { Paper, Grid, Typography } from "@material-ui/core";
import Total from "../../claims/claim/reserves/CurrentReserveTotal";
import styles from "../../claims/claim/reserves/CurrentReserve.styles";

const PieChart = ({ reserves, by }) => {
  const classes = styles();
  const total = {
    amountReserved: reserves.amountReserved,
    totalPaid: reserves.totalPaid,
    outstandingReserve: reserves.outstandingReserve
  };
  const any = true;
  const totalPercent = (total.totalPaid / (total.totalPaid + total.outstandingReserve)) * 100;
  const outstandingPercent = (total.outstandingReserve / (total.totalPaid + total.outstandingReserve)) * 100;
  const label = p => {
    let perc = p.toFixed(2);
    if (perc.substr(perc.length - 3) === ".00") perc = perc.substr(0, perc.length - 3);
    return `${perc}%`;
  };
  const data = {
    labels: [label(totalPercent), label(outstandingPercent)],
    datasets: [
      {
        data: [totalPercent, outstandingPercent],
        backgroundColor: ["#CECECE", "#1599BC"],
        hoverBackgroundColor: ["#CECECE", "#1599BC"],
        borderColor: ["#CECECE", "#1599BC"],
        borderWidth: [1, 1]
      }
    ]
  };
  const options = {
    legend: { display: false },
    tooltips: {
      callbacks: {
        label: (i, d) => d.labels[i.index]
      },
      displayColors: false
    }
  };
  const title = by === "occurrence" ? "Occurrence Reserve" : "Catastrophe Reserve";
  return (
    <Paper className={classes.root}>
      <Grid container alignItems="center">
        <Grid item className={classes.title}>
          <Typography variant="subtitle2">{title}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item className={classes.sumGrid} xs={12}>
              <Total {...{ css: classes.reserved, classes, title: "Amount Reserved", value: total.amountReserved }} />
            </Grid>
            <Grid item className={classes.sumGrid} xs={12}>
              <Total {...{ css: classes.total, classes, title: "Total Paid", value: total.totalPaid }} />
            </Grid>
            <Grid item className={classes.sumGrid} xs={12}>
              <Total {...{ css: classes.oustanding, classes, title: "Outstanding Reserve", value: total.outstandingReserve }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {any && (
            <Grid item style={{ paddingTop: 16 }}>
              <Pie data={data} options={options} width={150} />
            </Grid>
          )}
          {!any && (
            <svg width={150} viewBox="0 0 74 74" xmlns="http://www.w3.org/2000/svg">
              <g transform="translate(38,38)">
                <circle r="34" style={{ fill: "#C1C0C0" }} />
              </g>
            </svg>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

PieChart.propTypes = {
  reserves: PropTypes.objectOf(PropTypes.number),
  by: PropTypes.string.isRequired
};

PieChart.defaultProps = {
  reserves: {}
};

export default PieChart;
