import clsx from "clsx";
import React, { Fragment } from "react";
import { DocumentsBox } from "../../../common/documents";
import { useDocuments } from "./hooks";
import Indexing from "./Indexing";

const Documents = () => {
  const { claimId, documents, actions, actionsAllowed, indexItem, error } = useDocuments();
  return (
    <Fragment key="claim-documents">
      <div className={clsx({ "tc-none": Boolean(indexItem) })}>
        <DocumentsBox {...{ parentId: claimId, path: "claimId", documents, actions, actionsAllowed }} toggleViews />
      </div>
      {indexItem && <Indexing {...{ actions, indexItem, error }} />}
    </Fragment>
  );
};

export default Documents;
