import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../common/CollapsibleBox";
import ViewMode from "../../common/claims/bodilyInjury/Info";
import EditView from "../../common/claims/bodilyInjury/Form";
import { get, set } from "../../../redux/actions/initialReport/bodilyInjury";
import { useRouteParam } from "../../../common";
import ActionsAllowedContext from "../../common/contexts/ActionsAllowed";

const BodilyInjuryBox = props => {
  const store = useSelector(({ initialReport: { information } }) => information, shallowEqual);
  const { bodilyInjury } = store || {};
  const claimId = useRouteParam("claimId");
  const dispatch = useDispatch();
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info");
  const {
    props: { boxProps, editMode, loading: isLoading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  const handleSave = data => {
    if (access.update) {
      showLoading();
      dispatch(set(data, onSuccessEdit, hideLoading));
    }
  };
  return (
    <CollapsibleBox title="Bodily Injury" name="bodily" {...boxProps} {...props}>
      {Loading}
      {editMode ? <EditView {...{ bodilyInjury, onClose: onToggleEdit, onSave: handleSave, isLoading }} /> : <ViewMode {...{ bodilyInjury }} />}
    </CollapsibleBox>
  );
};

export default BodilyInjuryBox;
