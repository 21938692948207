import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import authentication from "./auth";
import App from "./components/App";
import configureStore, { history, withInsights } from "./redux/configureStore";
import AppContextProvider from "./components/common/AppContextProvider";
import "./index.css";

authentication.initialize();

const store = configureStore();

const Index = withInsights(() => (
  <ReduxProvider store={store}>
    <BrowserRouter>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AppContextProvider>
          <App {...{ history }} />
        </AppContextProvider>
      </MuiPickersUtilsProvider>
    </BrowserRouter>
  </ReduxProvider>
));

authentication.run(() => render(<Index />, document.getElementById("root")));
