import React, { useState, useEffect, Fragment } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Collapse, Grid } from "@material-ui/core";
import LabelValue from "../../../../common/LabelValue";

export default () => {
  const [state, setState] = useState({ location: {} });

  const store = useSelector(({ common, claim }) => ({ common, claim }), shallowEqual);

  const {
    claim: { mediationArbitration }
  } = store || {};
  useEffect(() => {
    setState(mediationArbitration);
  }, [mediationArbitration]);

  const { date, description, arbitration, arbitrationFirstName, arbitrationLastName, location } = state || {};

  return (
    <Fragment key="readMode">
      <Grid container spacing={4}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <LabelValue label="Date" value={date} isDate />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <LabelValue label="Address" value={location} isAddress />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs>
          <LabelValue label="Description" value={description} />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Collapse in={arbitration} timeout="auto">
            <LabelValue label="Arbitration First Name" value={arbitrationFirstName} />
          </Collapse>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Collapse in={arbitration} timeout="auto">
            <LabelValue label="Arbitration Last Name" value={arbitrationLastName} />
          </Collapse>
        </Grid>
      </Grid>
    </Fragment>
  );
};
