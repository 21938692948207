import { useState } from "react";

const useOnToggleBoxes = ({ initialValue }) => {
  const [open, setOpen] = useState(initialValue);
  const onToggle = name => setOpen(ps => ({ ...ps, [name]: !ps[name] }));
  const allExpanded = !Object.keys(initialValue).some(p => open[p] === false);
  const expandProps = {
    allExpanded,
    onClick: () => setOpen(ps => ({ ...ps, ...Object.keys(initialValue).reduce((acc, p) => ({ ...acc, [p]: !allExpanded }), {}) }))
  };
  return { open, onToggle, setOpen, expandProps };
};

export default useOnToggleBoxes;
