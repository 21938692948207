import { Grid } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { memo } from "react";
import ReactDOM from "react-dom";
import LinkButton from "./LinkButton";

const ExpandAllWrapper = memo(({ allExpanded, ...props }) => (
  <LinkButton blue {...props}>
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item className="tc-mtb-4px">
        <KeyboardArrowDown className={clsx("tc-arrow", { "tc-expand": !allExpanded })} />
      </Grid>
      <Grid item className="tc-bold">{`${allExpanded ? "COLLAPSE" : "EXPAND"} ALL`}</Grid>
    </Grid>
  </LinkButton>
));

ExpandAllWrapper.propTypes = { allExpanded: PropTypes.bool.isRequired };

const ExpandAll = ({ elementId, ...props }) => {
  if (elementId) {
    const portal = document.getElementById(elementId);
    return portal && ReactDOM.createPortal(<ExpandAllWrapper {...props} />, portal);
  }
  return <ExpandAllWrapper {...props} />;
};

ExpandAll.propTypes = { elementId: PropTypes.string };
ExpandAll.defaultProps = { elementId: undefined };

export default ExpandAll;
