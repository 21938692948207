import * as yup from "yup";
import { isEmail, rootParent } from "../../../common/yup";

const number = () => yup.number().nullable();
const string = () => yup.string().nullable();

const testProp = (id, fnValidate) => (val, ctx) => {
  const { isPropertyLiability, insProperties } = rootParent(ctx);
  if (id) {
    if (!isPropertyLiability) return true;
    return !!val && Array.isArray(insProperties) && insProperties.some(x => x.id === val && x.hasCoverage);
  }
  return (typeof fnValidate === "function" ? fnValidate : Boolean)(val);
};

const schema = yup.object().shape({
  property: yup.object().shape({
    id: number().test("", "Property covered by Policy Required", testProp(true)),
    propertyTypeId: string().test("", "Property Type is required", testProp()),
    location: yup.object().shape({ stateId: string().test("", "Property location State is required", testProp()) }),
    mortgageCompany: yup.object().when("hasMortgage", {
      is: true,
      then: yup.object().shape({
        name: string().test("", "Property location State is required", testProp()),
        email: string().test(
          "",
          "Invalid Email",
          testProp(undefined, v => !v || isEmail(v))
        ),
        location: yup.object().shape({ stateId: string().test("", "Mortgage company location State is required", testProp()) })
      })
    })
  })
});

export default schema;
