import { CLIENTR as CLIENT } from "../actionTypes";

export const get = (id, onSuccess, onError) => ({ type: CLIENT.CONTACTS.GET, id, onSuccess, onError });

export const getSuccess = contacts => ({ type: CLIENT.CONTACTS.GET_SUCCESS, contacts });

export const set = (contact, onSuccess, onError) => ({ type: CLIENT.CONTACTS.SET, contact, onSuccess, onError });

export const setSuccess = contacts => ({ type: CLIENT.CONTACTS.SET_SUCCESS, contacts });

export const post = (contact, onSuccess, onError) => ({ type: CLIENT.CONTACTS.POST, contact, onSuccess, onError });

export const postSuccess = contacts => ({ type: CLIENT.CONTACTS.POST_SUCCESS, contacts });

export const del = (contact, onSuccess, onError) => ({ type: CLIENT.CONTACTS.DEL, contact, onSuccess, onError });

export const delSuccess = contacts => ({ type: CLIENT.CONTACTS.DEL_SUCCESS, contacts });
