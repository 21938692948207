import * as yup from "yup";

const string = () => yup.string().nullable();
const number = () => yup.number().nullable();
const date = () => yup.date().nullable();

export default yup.object().shape({
  claimLineOfBusinessId: number().required("Policy Type Required"),
  dateReported: date().required("Date Reported Required"),
  statusId: number().required("Claim Status Required"),
  processingStatus: yup.array().of(yup.object().shape({ id: number(), text: string() })),
  processingStatusId: number().required("Processing Status Required"),
  deniedDescription: string().test("isDenied", "Claim Denied Description Required", (val, ctx) => {
    const { processingStatus, processingStatusId } = ctx?.parent;
    if (!Array.isArray(processingStatus)) return true;
    const status = processingStatus.find(x => x.id === processingStatusId)?.text;
    return status !== "Denied" || Boolean(val);
  }),
  location: yup.object().shape({
    address1: string().required("Address of Loss 1 Required"),
    city: string().required("City Required"),
    stateId: number().required("State Required"),
    zipCode: string().required("Zip Code Required")
  }),
  claimLossTypeGroupId: number().required("Loss Type Group Required"),
  claimLossTypeId: number().required("Loss Type Required"),
  claimCauseCodeId: number().required("Cause Code Required")
});
