import { NOTES, CLIENT, INSURED, VENDOR, CLAIM, OCCURRENCE, CATASTROPHE } from "./actionTypes";
import * as docActions from "./common/documents";

export const getAll = ({ id, entityType, onSuccess, onError }) => ({ type: NOTES.GET, id, entityType, onSuccess, onError });
export const getClientNotesSuccess = notes => ({ type: CLIENT.NOTES.GET_SUCCESS, notes });
export const getInsuredNotesSuccess = notes => ({ type: INSURED.NOTES.GET_SUCCESS, notes });
export const getVendorNotesSuccess = notes => ({ type: VENDOR.NOTES.GET_SUCCESS, notes });
export const getClaimNotesSuccess = notes => ({ type: CLAIM.NOTES.GET_SUCCESS, notes });
export const getOccurrenceNotesSuccess = notes => ({ type: OCCURRENCE.NOTES.GET_SUCCESS, notes });
export const getCatastropheNotesSuccess = notes => ({ type: CATASTROPHE.NOTES.GET_SUCCESS, notes });

export const post = ({ note, entityType, onSuccess, onError }) => ({ type: NOTES.POST, note, entityType, onSuccess, onError });
export const postClientNoteSuccess = notes => ({ type: CLIENT.NOTES.POST_SUCCESS, notes });
export const postInsuredNoteSuccess = notes => ({ type: INSURED.NOTES.POST_SUCCESS, notes });
export const postVendorNoteSuccess = notes => ({ type: VENDOR.NOTES.POST_SUCCESS, notes });
export const postClaimNoteSuccess = notes => ({ type: CLAIM.NOTES.POST_SUCCESS, notes });
export const postOccurrenceNoteSuccess = notes => ({ type: OCCURRENCE.NOTES.POST_SUCCESS, notes });
export const postCatastropheNoteSuccess = notes => ({ type: CATASTROPHE.NOTES.POST_SUCCESS, notes });

export const set = ({ note, entityType, onSuccess, onError }) => ({ type: NOTES.SET, note, entityType, onSuccess, onError });
export const setClientNoteSuccess = notes => ({ type: CLIENT.NOTES.SET_SUCCESS, notes });
export const setInsuredNoteSuccess = notes => ({ type: INSURED.NOTES.SET_SUCCESS, notes });
export const setVendorNoteSuccess = notes => ({ type: VENDOR.NOTES.SET_SUCCESS, notes });
export const setClaimNoteSuccess = notes => ({ type: CLAIM.NOTES.SET_SUCCESS, notes });
export const setOccurrenceNoteSuccess = notes => ({ type: OCCURRENCE.NOTES.SET_SUCCESS, notes });
export const setCatastropheNoteSuccess = notes => ({ type: CATASTROPHE.NOTES.SET_SUCCESS, notes });

export const del = ({ note, entityType, onSuccess, onError }) => ({ type: NOTES.DEL, note, entityType, onSuccess, onError });
export const delClientNoteSuccess = notes => ({ type: CLIENT.NOTES.DEL_SUCCESS, notes });
export const delInsuredNoteSuccess = notes => ({ type: INSURED.NOTES.DEL_SUCCESS, notes });
export const delVendorNoteSuccess = notes => ({ type: VENDOR.NOTES.DEL_SUCCESS, notes });
export const delClaimNoteSuccess = notes => ({ type: CLAIM.NOTES.DEL_SUCCESS, notes });
export const delOccurrenceNoteSuccess = notes => ({ type: OCCURRENCE.NOTES.DEL_SUCCESS, notes });
export const delCatastropheNoteSuccess = notes => ({ type: CATASTROPHE.NOTES.DEL_SUCCESS, notes });

export const getDraft = ({ id, entityType, onSuccess, onError }) => ({ type: NOTES.GET_DRAFT, id, entityType, onSuccess, onError });
export const getClientDraftSuccess = draft => ({ type: CLIENT.NOTES.GET_DRAFT_SUCCESS, draft });
export const getInsuredDraftSuccess = draft => ({ type: INSURED.NOTES.GET_DRAFT_SUCCESS, draft });
export const getVendorDraftSuccess = draft => ({ type: VENDOR.NOTES.GET_DRAFT_SUCCESS, draft });
export const getClaimDraftSuccess = draft => ({ type: CLAIM.NOTES.GET_DRAFT_SUCCESS, draft });
export const getOccurrenceDraftSuccess = draft => ({ type: OCCURRENCE.NOTES.GET_DRAFT_SUCCESS, draft });
export const getCatastropheDraftSuccess = draft => ({ type: CATASTROPHE.NOTES.GET_DRAFT_SUCCESS, draft });

export const postDraft = ({ note, entityType, onSuccess, onError }) => ({ type: NOTES.POST_DRAFT, note, entityType, onSuccess, onError });
export const postClientDraftSuccess = draft => ({ type: CLIENT.NOTES.POST_DRAFT_SUCCESS, draft });
export const postInsuredDraftSuccess = draft => ({ type: INSURED.NOTES.POST_DRAFT_SUCCESS, draft });
export const postVendorDraftSuccess = draft => ({ type: VENDOR.NOTES.POST_DRAFT_SUCCESS, draft });
export const postClaimDraftSuccess = draft => ({ type: CLAIM.NOTES.POST_DRAFT_SUCCESS, draft });
export const postOccurrenceDraftSuccess = draft => ({ type: OCCURRENCE.NOTES.POST_DRAFT_SUCCESS, draft });
export const postCatastropheDraftSuccess = draft => ({ type: CATASTROPHE.NOTES.POST_DRAFT_SUCCESS, draft });

export const openFile = docActions.openFile(CLAIM.DOCUMENTS.OPEN_FILE);
