import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import { Avatar, Grid } from "@material-ui/core";
import moment from "moment";
import Claim from "../../assets/not_duplicates_found.svg";
import styles from "./CatastropheDuplicates.styles";
import LabelValue from "../common/LabelValue";
import { useDebounce } from "../../common";
import { API } from "../../redux/actions/actionTypes";

const CatastropheDuplicates = ({ filter }) => {
  const classes = styles();
  const apiCallsInProgress = useSelector(store => store.apiCallsInProgress, shallowEqual);

  const [catastropheDuplicates, setDuplicates] = useState([]);
  const loading = apiCallsInProgress > 0;
  const anyDuplicate = catastropheDuplicates && catastropheDuplicates.length > 0;
  const { date, locationList } = filter || {};
  const duplicatesDebounced = useDebounce(data => axios.post(`${API.URL}/catastrophe/find`, data), 1500);
  useEffect(() => {
    if (!loading && date && locationList.length > 0) {
      duplicatesDebounced(filter).then(({ data }) => setDuplicates(data));
    }
  }, [filter]);
  const duplicates = catastropheDuplicates.map(item => (
    <div className={classes.item}>
      <Grid container spacing={2}>
        <Grid item>
          <LabelValue className={classes.label} value="Catastrophe" />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item style={{ flexGrow: 1 }}>
              <LabelValue className={classes.info} value={item.number} />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <LabelValue className={classes.info} value={item.name} />
            </Grid>
            <Grid item>
              <LabelValue className={classes.info} value={`${moment(item.date).format("M/D/YYYY")}`} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item style={{ flexGrow: 1 }}>
              <LabelValue className={classes.info} value={item.fullAddress} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item>
          <LabelValue className={classes.label} value="Description" />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <LabelValue className={classes.info} value={item.description} />
        </Grid>
      </Grid>
    </div>
  ));
  if (anyDuplicate)
    return (
      <Grid container className={classes.claimContainer}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {duplicates}
        </Grid>
      </Grid>
    );
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.noDuplicates}>
        <Avatar className={classes.avatar}>
          <Claim />
        </Avatar>
      </Grid>
      <Grid item xs={12} className={classes.noDuplicatesText}>
        No duplicates found
      </Grid>
    </Grid>
  );
};

CatastropheDuplicates.propTypes = {
  filter: PropTypes.shape({}).isRequired
};

export default CatastropheDuplicates;
