import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, IconButton, Paper, Grid } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import Loading from "../../components/common/Loading";
import MaterialTable from "../../components/common/table/MaterialTable";
import { getAll as loadCatastrophes, del } from "../../redux/actions/catastropheAction";
import useCustomConfirm from "../../components/common/customHooks/useCustomConfirm";
import useLoading from "../../components/common/customHooks/useIsSubmitting";
import { getUserAccess } from "../../common";
import DateFilter from "../../components/common/table/DateFilter";

const TabCatastrophes = () => {
  const secProfs = useSelector(({ profile: { securityProfilesDetails } }) => securityProfilesDetails, shallowEqual);
  const catastrophes = useSelector(({ catastrophe: { catastrophes: d } }) => d, shallowEqual);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useLoading();
  const hideLoading = () => {
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(loadCatastrophes(hideLoading, hideLoading));
  }, []);
  const [hideCancel, setHideCancel] = useState(false);

  const access = getUserAccess(secProfs, "CATASTROPHE.LIST", null, null, null);
  const handleDelete = row => {
    if (!hideCancel) dispatch(del(row.id));
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(
    handleDelete,
    "Cancel",
    hideCancel ? "Accept" : "Yes, Delete",
    hideCancel
  );
  const handleConfirmDelete = item => {
    setHideCancel(item.claimsCount + item.occurrenceCount > 0);
    const message =
      item.claimsCount + item.occurrenceCount === 0
        ? `The following Catastrophe will be deleted: ${item.number}. Are you sure you want to proceed? `
        : `Unable to delete this Catastrophe since there are claims/occurrence associated. In order to delete the Catastrophe, please remove the claims/occurrences associated.`;
    handleCustomConfirm({ oData: item, titleText: `Delete Catastrophe`, messageText: message });
  };
  const history = useHistory();
  const getButtonToolBar = () => {
    if (!access.create) return null;
    return (
      <Grid item className="tc-pl1">
        <Button variant="contained" color="primary" onClick={() => history.push("/catastrophe")}>
          Add Catastrophe
        </Button>
      </Grid>
    );
  };
  const tableProps = {
    columns: [
      { title: "Number", field: "number", render: rowData => <Link to={`/catastrophe/${rowData.id}`}>{rowData.number}</Link> },
      { title: "Date Since", field: "dateSince", type: "date", filterComponent: DateFilter },
      { title: "Name", field: "name" },
      { title: "Location", field: "fullAddress" },
      { title: "Claims Associated", field: "claimsCount" },
      { title: "Occurrence Associated", field: "occurrenceCount" }
    ],
    data: catastrophes ?? [],
    hidePaging: catastrophes && catastrophes.length <= 50,
    options: { pageSize: 50, filtering: true, exportFileName: "TerraClaim_CatastropheList" },
    actions: [
      ...(access.delete
        ? [
            {
              icon: p => (
                <IconButton {...p} className="tc-ml1">
                  <DeleteIcon />
                </IconButton>
              ),
              custom: true,
              onClick: (_e, r) => handleConfirmDelete(r)
            }
          ]
        : [])
    ],
    ButtonToolBar: getButtonToolBar
  };
  return (
    <>
      {isLoading && <Loading float />}
      <div className="tc-page-content">
        <Paper className="tc-p2">
          sarasa
          <MaterialTable {...tableProps} />
          <Confirm />
        </Paper>
      </div>
    </>
  );
};

export default TabCatastrophes;
