import { Collapse, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { CheckboxLabel, SelectInput, InputText, MoneyInput, PhoneInput } from "../../../../common/inputs";
import { useContext, actionTypes } from "../../context";

const Damaged = memo(({ state, inputProps: iProps }) => {
  const [
    {
      coverageTypes,
      insProperties: { data: insProperties }
    },
    ,
    handleChange,
    {
      step2: { validationMessages }
    }
  ] = useContext();
  const isPL = coverageTypes.some(lob => lob.id === 3 && lob.lossTypeGroups.some(ltg => ltg.checked));
  const anyInsProp = isPL && insProperties.length > 0;
  const opsProps = anyInsProp ? insProperties.map(d => ({ id: d.id, text: (d.location || {}).address1 || "-" })) : [];
  const store = useSelector(({ common }) => common, shallowEqual);
  const { propertyDamagedLossTypes: lossTypes, departamentTypes, propertyDamagedTypes: types, states, propertyTypes } = store;
  const {
    damaged: {
      propertyDamagedLossTypeId,
      departamentTypeId,
      reportNumber,
      propertyDamagedTypeId,
      amountEntireLoss,
      description,
      isClaimantMortgageCompany
    },
    property: {
      id,
      propertyTypeId,
      hasMortgage,
      mortgageCompany: { name, email, phone, mainContactName, location: mgLoc },
      location: { address1, address2, city, stateId, zipCode }
    }
  } = state;
  const inputProps = path => {
    const prs = iProps(path);
    return { ...prs, onChange: e => handleChange(e, actionTypes.SET_DAMAGED_PROPERTY), error: validationMessages[prs.error] };
  };
  const dProps = path => inputProps(`damaged.${path}`);
  const pProps = path => inputProps(`property.${path}`);
  const mgProps = path => inputProps(`property.mortgageCompany.${path}`);
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <SelectInput {...dProps("propertyDamagedLossTypeId")} label="Kind of Loss *" value={propertyDamagedLossTypeId} options={lossTypes} />
          <SelectInput {...dProps("propertyDamagedTypeId")} label="Damage Type Reported *" value={propertyDamagedTypeId} options={types} />
        </Grid>
        <Grid item xs={4}>
          <SelectInput {...dProps("departamentTypeId")} label="Reported to *" value={departamentTypeId} options={departamentTypes} />
          <MoneyInput {...dProps("amountEntireLoss")} label="Probable Amount Entire Loss" value={amountEntireLoss} />
        </Grid>
        <Grid item xs={4}>
          <InputText {...dProps("reportNumber")} label="Report Number" value={reportNumber} />
        </Grid>
      </Grid>
      <InputText {...dProps("description")} label="Property Damage description" value={description} rows={4} />
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <SelectInput {...pProps("propertyTypeId")} label="Property Type *" value={propertyTypeId} options={propertyTypes} disabled={anyInsProp} />
          {(anyInsProp && <SelectInput {...pProps("id")} label="Address 1 *" value={id} options={opsProps} />) || (
            <InputText {...pProps("location.address1")} label="Address 1 *" value={address1} />
          )}
        </Grid>
        <Grid item xs={4}>
          <InputText {...pProps("location.city")} label="City" value={city} disabled={anyInsProp} />
          <InputText {...pProps("location.address2")} label="Address 2" value={address2} disabled={anyInsProp} />
        </Grid>
        <Grid item xs={4}>
          <SelectInput {...pProps("location.stateId")} label="State *" value={stateId} options={states} disabled={anyInsProp} />
          <InputText {...pProps("location.zipCode")} label="Zip *" value={zipCode} disabled={anyInsProp} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          <CheckboxLabel {...pProps("hasMortgage")} label="Mortgage Property" checked={hasMortgage} disabled={anyInsProp} />
        </Grid>
        <Grid item xs={8}>
          <Collapse in={hasMortgage} timeout="auto">
            <CheckboxLabel {...dProps("isClaimantMortgageCompany")} label="Mortgage Company same as Claimant" checked={isClaimantMortgageCompany} />
          </Collapse>
        </Grid>
      </Grid>
      <Collapse in={hasMortgage && !isClaimantMortgageCompany} timeout="auto">
        <Grid container spacing={4}>
          <Grid item xs>
            <InputText {...mgProps("name")} label="Mortgage Company Name *" value={name} disabled={anyInsProp} />
            <InputText {...mgProps("location.address1")} label="Address 1 *" value={mgLoc.address1} disabled={anyInsProp} />
            <InputText {...mgProps("location.address2")} label="Address 2" value={mgLoc.address2} disabled={anyInsProp} />
          </Grid>
          <Grid item xs>
            <InputText {...mgProps("location.city")} label="City *" value={mgLoc.city} disabled={anyInsProp} />
            <SelectInput {...mgProps("location.stateId")} label="State *" value={mgLoc.stateId} options={states} disabled={anyInsProp} />
            <InputText {...mgProps("location.zipCode")} label="Zip *" value={mgLoc.zipCode} disabled={anyInsProp} />
          </Grid>
          <Grid item xs>
            <PhoneInput {...mgProps("phone")} label="Mortgage Company Phone Number" value={phone} disabled={anyInsProp} />
            <InputText {...mgProps("email")} label="Mortgage Company Email" value={email} disabled={anyInsProp} />
            <InputText {...mgProps("mainContactName")} label="Mortgage Company Main Contact" value={mainContactName} disabled={anyInsProp} />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
});

Damaged.propTypes = {
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  inputProps: PropTypes.func.isRequired
};

export default Damaged;
