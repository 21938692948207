import React, { memo } from "react";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import { FormControl, Input, InputLabel, FormHelperText } from "@material-ui/core";
import { HocInput } from "./HocInput";

const bMask = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

const PhoneMaskedInput = ({ inputRef, noextension, ...other }) => {
  const mask = (rawValue = "") => {
    if (noextension || rawValue.replace(/\D+/g, "").length <= 10) return bMask;
    return [...bMask, " ", "x", /\d/, /\d/, /\d/, /\d/, /\d/];
  };
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      guide={false}
    />
  );
};

PhoneMaskedInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  noextension: PropTypes.bool.isRequired
};

const PhoneInput = memo(
  ({ classes, className, error, label, name, noextension, ...other }) => {
    const id = `phone-${name || uuid()}`;
    return (
      <FormControl fullWidth error={Boolean(error)} {...{ classes, className }}>
        <InputLabel htmlFor={id} shrink>
          {label}
        </InputLabel>
        <Input
          {...{
            ...other,
            name,
            id,
            inputComponent: PhoneMaskedInput,
            inputProps: { noextension },
            placeholder: "(   )    -    "
          }}
        />
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  },
  (p, n) => !["value", "error", "label"].some(k => p[k] !== n[k])
);

PhoneInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  noextension: PropTypes.bool
};

PhoneInput.defaultProps = {
  name: null,
  value: "",
  error: "",
  noextension: false,
  onChange: () => {}
};

export default HocInput(PhoneInput);
