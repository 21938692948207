import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Grid, IconButton } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import { HorizontalBar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import Card from "../../common/overview/Card";
import { useLoading } from "../../common/Loading";
import { get } from "../../../redux/actions/dashboard/claimsLossesByAdjuster";
import useStyles from "../Dashboard.styles";

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions, Loading } = useLoading(true);
  const { showLoading, hideLoading } = actions;

  useEffect(() => {
    showLoading();
    dispatch(get(hideLoading, hideLoading));
  }, []);
  const claimLossesByAdjuster = useSelector(({ dashboard: { claimsLossesByAdjuster } }) => claimsLossesByAdjuster, shallowEqual);

  const adjusters = [...new Set(claimLossesByAdjuster.map(item => item.adjuster))];

  const lob = ["OPEN/CLOSED CLAIMS"];

  const data = lob.reduce(
    (acc, curr) => [...acc, { lob: curr, quantities: adjusters.map(a => claimLossesByAdjuster.find(c => c.adjuster === a).cantClaims) }],
    []
  );
  const dataBar = {
    labels: adjusters,
    options: {
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              min: 0,
              suggestedMin: 0
            }
          }
        ]
      }
    },
    datasets: [
      {
        label: "OPEN/CLOSED CLAIMS",
        backgroundColor: "#7BAAF7",
        hoverBackgroundColor: "#7BAAF7",
        borderColor: "#7BAAF7",
        borderWidth: 1,
        data: data.find(d => d.lob === "OPEN/CLOSED CLAIMS").quantities
      }
    ]
  };
  const showDetail = () => {
    history.push("/dashboard/claims-losses-by-adjuster");
  };
  const widgetActions = () => {
    return (
      <>
        <IconButton size="small" className={classes.icon} onClick={showDetail}>
          <OpenInNew />
        </IconButton>
      </>
    );
  };

  return (
    <>
      {Loading}
      <Card title="Large Loss Report by adjuster" cardHeaderAction={widgetActions}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs>
            <HorizontalBar data={dataBar} />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
