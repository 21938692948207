import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, Divider, Typography } from "@material-ui/core";
import LabelValue from "../../../common/LabelValue";
import ReadOnlyCoverage from "../../../clients/insured/policies/policy/coverage/Read";
import PolicyAggregate from "../../../clients/insured/policies/policy/aggregate/Read";
import PolicyFinancial from "../../../clients/insured/policies/policy/financial/Read";
import CodeList from "../../../common/code/List";

const DocumentInformation = ({ policy, rateColumn, classes, showFinancial }) => {
  const {
    number,
    policyType,
    policyTypeId,
    effectiveDate,
    expirationDate,
    producer,
    insurer,
    coverageTrigger,
    riskExposureType,
    purposeType,
    primaryPolicyValue,
    corridorAmount,
    corridorErosion,
    rolloverValue,
    codeList,
    financial
  } = policy;
  return (
    <Fragment key="policy-read-mode">
      <Grid container spacing={4}>
        <Grid item xs>
          <LabelValue label="Number" value={number} />
          <LabelValue label="Primary Carrier" value={insurer} />
          <LabelValue label="Purpose Type" value={purposeType} />
        </Grid>
        <Grid item xs>
          <LabelValue label="Policy Type" value={policyType} />
          <LabelValue label="Issuing Carrier" value={producer} />
          <LabelValue label="Primary Policy" value={primaryPolicyValue} />
        </Grid>
        <Grid item xs>
          <LabelValue label="Effective Date" value={effectiveDate} isDate />
          <LabelValue label="Coverage Trigger" value={coverageTrigger} />
        </Grid>
        <Grid item xs>
          <LabelValue label="Expiration Date" value={expirationDate} isDate />
          <LabelValue label="Risk Exposure Types " value={riskExposureType} />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Typography className="tc-title" gutterBottom>
        Corridor Deductible
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <LabelValue label="Corridor Amount" value={corridorAmount} isMoney />
        </Grid>
        <Grid item xs={3}>
          <LabelValue label="Corridor Erosion" value={corridorErosion} />
        </Grid>
        <Grid item xs={3}>
          <LabelValue label="Rollover?" value={rolloverValue} />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      {(policyTypeId !== 1 && <ReadOnlyCoverage insuredPolicy={policy} title="Coverage" />) || (
        <>
          <Typography className="tc-title" gutterBottom>
            Aggregate Details
          </Typography>
          <PolicyAggregate />
        </>
      )}
      <Divider className={classes.divider} />
      <CodeList rateColumn={rateColumn && policyTypeId === 6} title="Codes" codes={codeList || []} viewMode />
      {showFinancial && policyTypeId === 6 && (
        <>
          <Divider className={classes.divider} />
          <Typography className="tc-title">Policy Financial</Typography>
          <PolicyFinancial {...{ financial }} />
        </>
      )}
    </Fragment>
  );
};

DocumentInformation.propTypes = {
  policy: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  rateColumn: PropTypes.bool,
  showFinancial: PropTypes.bool
};

DocumentInformation.defaultProps = { rateColumn: false, showFinancial: false };

export default DocumentInformation;
