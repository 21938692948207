import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Grid, Button, IconButton } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import clsx from "clsx";
import useStyles from "../claims/claim_list/CollapsibleClaimList.styles";
import HeaderCollapsibleBox from "../common/HeaderCollapsibleBox";

const CollapsibleOccurrenceList = ({ occurrences, onToggle, associate, openOccurrenceDetail }) => {
  const classes = useStyles();
  const OccurrenceHeader = ({ occurrence }) => {
    const viewOccurrenceDetail = (c, event) => {
      event.stopPropagation();
      openOccurrenceDetail(c);
    };
    return (
      <Grid container className={classes.claimContainer}>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>{occurrence.number && <div className={classes.claimNo}>{occurrence.number}</div>}</Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6} className={classes.claimantSection}>
          <div className={classes.label}>Location</div>
          <Grid container alignItems="center">
            <Grid item xs={11}>
              {occurrence.fullAddress && <span className={classes.claimantName}>{occurrence.fullAddress}</span>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Grid container alignItems="flex-start" justify="flex-end" direction="row">
            <Grid item>
              <IconButton
                aria-label="delete"
                size="small"
                className={clsx(classes.exitToApp)}
                onClick={event => viewOccurrenceDetail(occurrence, event)}
              >
                <ExitToApp />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  OccurrenceHeader.propTypes = {
    occurrence: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])).isRequired
  };

  const actionAssociate = (c, event) => {
    event.stopPropagation();
    associate(c);
  };

  const occurrenceItems = occurrences.map(occurrence => (
    <HeaderCollapsibleBox
      header={<OccurrenceHeader {...{ occurrence }} />}
      title="occurrence #"
      name={occurrence.number}
      key={occurrence.number}
      {...{ open: occurrence.open, onToggle }}
    >
      <Grid container className={classes.padding}>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <div className={classes.label}>Date of Loss</div>
          <Grid container alignItems="center">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {occurrence.date && <div className={classes.claiminfo}>{moment(occurrence.date).format("M/D/YYYY")}</div>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <div className={classes.label}>Claims Associated</div>
          <Grid container alignItems="center">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {occurrence.claimsCount && <div className={classes.claiminfo}>{occurrence.claimsCount}</div>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4} className={classes.containerAssoc}>
          <Button variant="contained" color="primary" onClick={event => actionAssociate(occurrence, event)} className={classes.associateButton}>
            ASSOCIATE
          </Button>
        </Grid>
      </Grid>
    </HeaderCollapsibleBox>
  ));

  return <>{occurrenceItems}</>;
};

CollapsibleOccurrenceList.propTypes = {
  occurrences: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  onToggle: PropTypes.func.isRequired,
  associate: PropTypes.func.isRequired,
  openOccurrenceDetail: PropTypes.func.isRequired
};

export default CollapsibleOccurrenceList;
