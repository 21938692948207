import React, { Fragment } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Dashboard from "../../components/dashboard/Dashboard";
import ExtendedDiariesList from "../../components/dashboard/diaries/ExtendedList";
import ExpiredReport from "../../components/dashboard/diaries/ExpiredReport";
import ClaimWithoutActivityDetail from "../../components/dashboard/claims/ClaimWithoutActivityDetail";
import OpenClaimsByAdjusterDetail from "../../components/dashboard/openClaimsByAdjuster/detail";
import ClaimLossesByAdjusterDetail from "../../components/dashboard/ClaimLossesByAdjuster/ClaimLossesByAdjusterDetail";
import DuplicateReport from "../../components/dashboard/payments/DuplicateReport";

const Routes = () => {
  const { path } = useRouteMatch();
  return (
    <Fragment key="route-fragment">
      <Switch>
        <Route exact path={`${path}`} component={Dashboard} />
        <Route path={`${path}/diaries`} component={ExtendedDiariesList} />
        <Route path={`${path}/expireddiaries`} component={ExpiredReport} />
        <Route path={`${path}/duplicatepayments`} component={DuplicateReport} />
        <Route path={`${path}/claim-without-activity`} component={ClaimWithoutActivityDetail} />
        <Route path={`${path}/open-claims-count-by-adjuster`} component={OpenClaimsByAdjusterDetail} />
        <Route path={`${path}/claims-losses-by-adjuster`} component={ClaimLossesByAdjusterDetail} />
      </Switch>
    </Fragment>
  );
};
export default Routes;
