import React, { useState, useEffect, Fragment, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Grid, Button, Collapse, Paper, Typography, IconButton } from "@material-ui/core";
import SaveAltSharp from "@material-ui/icons/SaveAltSharp";
import { SearchInput, SelectBase } from "../inputs";
import Note from "./Note";
import NoteForm from "./NoteForm";
import RegexFilter from "../RegexFilter";
import AssociateNoteContext from "./AssociateNoteContext";
import Confirm from "../Confirm";
import { getAll, post, set, del, getDraft, postDraft, openFile } from "../../../redux/actions/notes";
import * as actionOccurrence from "../../../redux/actions/occurrence";
import * as actionCatastrophe from "../../../redux/actions/catastropheAction";
import useStyles from "./Note.styles";
import AddFooter from "./AddFooter";
import EditFooter from "../FooterSave";
import { getAll as getNoteTypes } from "../../../redux/actions/admin/noteTypeSetting";
import NoRecords from "../NoRecords";
import useYupValidationSchema from "../customHooks/useYupValidationSchema";
import useCleanup from "../customHooks/useCleanup";
import useNote from "./useNote";
import schemaValidation from "./yupSchema";
import { useLoadingOrContext } from "../Loading";
import useViewer from "../viewer/useViewer";
import exportPDF from "./exportPDF";

const Notes = ({ actionsAllowed, ...props }) => {
  const { handleValidateSchema, validationMessages, handleValidationMessages } = useYupValidationSchema(schemaValidation);
  const {
    Loading,
    loading,
    actions: { showLoading, hideLoading }
  } = useLoadingOrContext(true);
  const claimEntityProps = ["claimId", "occurrenceId", "catastropheId"];
  const entityProps = [...claimEntityProps, "vendorId", "insuredId", "clientId"];
  const { entityNumber } = props;
  const { id, entityType } = (() =>
    entityProps.reduce(
      (acc, p) => [...acc, ...(!props[p] || acc.length > 0 ? [] : [{ id: props[p], entityType: p.substr(0, p.length - 2) }])],
      []
    )[0] || { entityType: "" })();
  const allEntityIds = entityProps.reduce((acc, p) => ({ ...acc, [p]: parseInt(props[p], 10) || undefined }), {});
  const handleValidation = state => handleValidateSchema({ ...state, claimId: allEntityIds.claimId });
  const dispatch = useDispatch();
  const heightFormRef = React.useRef();
  // eslint-disable-next-line
  const [heightForm, setHeightForm] = useState(0);
  const { notes, draftNote, associatedList, noteType, occurrence, catastrophe } = useNote(entityType);
  const modeFull = claimEntityProps.some(p => Boolean(allEntityIds[p]));
  const doubleColumn = claimEntityProps.some(p => p !== "claimId" && Boolean(allEntityIds[p]));
  const [clearChecks, setClearChecks] = useState(false);
  const onEdit = useCallback(({ note, onSuccess }) => {
    showLoading();
    return dispatch(
      set({
        note,
        entityType,
        onSuccess: () => {
          onSuccess();
          return hideLoading();
        },
        onError: hideLoading
      })
    );
  }, []);
  const onAdd = useCallback(({ note, onSuccess }) => {
    showLoading();
    dispatch(
      post({
        note,
        entityType,
        onSuccess: () => {
          onSuccess();
          hideLoading();
        },
        onError: hideLoading
      })
    );
  }, []);
  const onDelete = useCallback(({ note }) => {
    showLoading();
    const entityNote = { ...note, [`${entityType}Id`]: id };
    return dispatch(del({ note: entityNote, entityType, onSuccess: hideLoading, onError: hideLoading }));
  }, []);
  const defaultNote = {
    typeId: (() => {
      switch (entityType) {
        case "occurrence":
          return 2;
        case "catastrophe":
          return 3;
        default:
          return undefined;
      }
    })(),
    isConfidential: false,
    open: doubleColumn,
    claimIds: [],
    occurrenceIds: [],
    ...allEntityIds
  };

  const [note, setNote] = useState(defaultNote);

  const handleChange = ({ target: { name, value } }) =>
    setNote(ps => {
      const ns = typeof value === "function" ? value(ps) : { ...ps, [name]: value };
      handleValidation(ns);
      return ns;
    });

  const onSaveDraft = () => {
    if ((!note.id || note.isDraft) && (note.note || note.title) && actionsAllowed.create)
      dispatch(postDraft({ note: { ...note, ...allEntityIds }, entityType }));
  };
  const classes = useStyles();
  const [filter, setFilter] = useState({ type: 0, term: "" });
  const onChangeFilter = ({ target: { name, value } }) => setFilter(ps => ({ ...ps, [name]: value }));

  const [openConfirm, setOpenConfirm] = useState(false);
  const noteOpen = (open = doubleColumn) => () => setNote({ ...defaultNote, open });

  const filteredNotes = (notes || []).filter(
    n =>
      (filter.type === 0 || n.typeId === filter.type) &&
      (!filter.term ||
        RegexFilter(filter.term, n.title) ||
        RegexFilter(filter.term, n.note) ||
        n.responses.filter(r => RegexFilter(filter.term, r.title) || RegexFilter(filter.term, r.note)).length > 0)
  );
  const addNewNote = () => {
    if (!handleValidation(note).isValid) return false;
    showLoading();
    return onAdd({
      note: { ...note, ...allEntityIds },
      onSuccess: () => {
        noteOpen()();
        setNote(defaultNote);
        setClearChecks(true);
        setTimeout(() => setClearChecks(false), 100);
        if (note.isDraft) {
          dispatch(getDraft({ id, entityType }));
          setNote({ ...defaultNote, open: true });
        }
        return hideLoading();
      },
      onError: hideLoading
    });
  };
  const cancelNewNote = () => {
    if ((!note.id || note.isDraft) && note.note && note.title) {
      setOpenConfirm(true);
    } else {
      setNote(defaultNote);
      handleValidationMessages();
      setClearChecks(true);
      setTimeout(() => setClearChecks(false), 100);
    }
  };
  const confirmConfig = {
    title: "Note",
    messageText: "Do you want to save this draft?",
    onToggleConfirm: () => {
      if (note.id)
        dispatch(
          del({
            note,
            entityType,
            onSuccess: () => {
              setOpenConfirm(!openConfirm);
              setClearChecks(true);
              setTimeout(() => setClearChecks(false), 100);
            }
          })
        );
      else setOpenConfirm(false);
      setNote(defaultNote);
    },
    onAction: () => {
      onSaveDraft();
      setNote(ps => ({ ...ps, open: doubleColumn }));
      setOpenConfirm(false);
    },
    openDialog: openConfirm,
    okText: "Save draft",
    noText: "Discard draft"
  };
  useEffect(() => {
    setNote(draftNote?.id ? { ...draftNote, open: true } : defaultNote);
  }, [draftNote]);
  useEffect(() => {
    dispatch(getNoteTypes());
    showLoading();
    dispatch(getAll({ id, entityType, onSuccess: hideLoading, onError: hideLoading }));
    dispatch(getDraft({ id, entityType }));
    if (entityType === "occurrence" && !occurrence?.id) dispatch(actionOccurrence.get(allEntityIds.occurrenceId));
    if (entityType === "catastrophe" && !catastrophe?.id) dispatch(actionCatastrophe.get(allEntityIds.catastropheId));
  }, []);

  useCleanup(() => {
    if (actionsAllowed.update) onSaveDraft();
  }, [note]);

  const updateHeightForm = () => {
    setHeightForm(heightFormRef.current.clientHeight);
  };
  const [addModeForm, setAddModeForm] = useState(true);
  const onEditDoubleColumnView = data => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setNote({ ...defaultNote, ...data });
    setAddModeForm(false);
  };
  const onCancelEditDoubleColumnView = () => {
    setClearChecks(true);
    setTimeout(() => setClearChecks(false), 100);
    if (draftNote?.id) setNote({ ...draftNote, open: doubleColumn });
    else setNote({ ...defaultNote, open: doubleColumn });
    setAddModeForm(true);
  };
  const { onViewFile, fileViewer } = useViewer({
    dispatchAction: p => {
      showLoading();
      dispatch(openFile({ ...p, onSuccess: hideLoading, onError: hideLoading }));
    }
  });
  const noteProps = {
    actions: {
      add: onAdd,
      edit: doubleColumn ? onEditDoubleColumnView : onEdit,
      del: onDelete,
      onOpen: (fileId, noteId) => onViewFile(fileId, { noteId })
    },
    actionsAllowed,
    doubleColumn
  };

  return (
    <Fragment key="notes">
      {Loading}
      <AssociateNoteContext.Provider value={{ entityType, allEntityIds, associatedList, clearChecks }}>
        <Grid container spacing={doubleColumn ? 2 : 0}>
          {actionsAllowed.create && (
            <Grid ref={heightFormRef} item xs={12} sm={12} lg={doubleColumn ? 6 : 12} md={doubleColumn ? 6 : 12}>
              <Collapse in={note.open} onEntered={updateHeightForm} onExited={updateHeightForm}>
                <Paper className={classes.paper}>
                  {doubleColumn && <Typography className="tc-title" gutterBottom>{`${addModeForm ? "Add" : "Edit"} Note`}</Typography>}
                  <NoteForm
                    hideFirstRow={!claimEntityProps.some(p => Boolean(allEntityIds[p]))}
                    onChange={handleChange}
                    data={note}
                    {...{ validationMessages, actionsAllowed }}
                  />
                  {addModeForm ? (
                    <AddFooter {...{ doubleColumn }} cancel={cancelNewNote} saveDraft={onSaveDraft} addNote={addNewNote} isSaveDisabled={loading} />
                  ) : (
                    <EditFooter
                      onSave={() => onEdit({ note, onSuccess: onCancelEditDoubleColumnView })}
                      onCancel={onCancelEditDoubleColumnView}
                      isDisable={loading}
                    />
                  )}
                </Paper>
              </Collapse>
            </Grid>
          )}
          <Grid item xs={12} sm={12} lg={doubleColumn ? 6 : 12} md={doubleColumn ? 6 : 12}>
            <Paper className={classes.paper}>
              <div style={{ marginBottom: 10 }}>
                {(actionsAllowed.create || actionsAllowed.update) && (
                  <Grid container spacing={3} justify="flex-end" alignItems="center">
                    <Grid item>
                      <SearchInput name="term" value={filter.term} onChange={onChangeFilter} />
                    </Grid>
                    {entityNumber && (
                      <Grid item className="tc-p0">
                        <IconButton
                          onClick={() => exportPDF({ data: filteredNotes, number: entityNumber, loading: { show: showLoading, hide: hideLoading } })}
                        >
                          <SaveAltSharp />
                        </IconButton>
                      </Grid>
                    )}
                    {modeFull && (
                      <Grid item>
                        <SelectBase name="type" value={filter.type} options={[{ id: 0, text: "All" }, ...noteType]} onChange={onChangeFilter} />
                      </Grid>
                    )}
                    {actionsAllowed.create && !doubleColumn && (
                      <Grid item>
                        <Button variant="contained" color="primary" disabled={note.open} onClick={noteOpen(true)}>
                          New Note
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </div>
              <div>
                {modeFull &&
                  filteredNotes
                    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
                    .map(n => <Note key={n.id} note={n} hideReply={!n.canResponse || doubleColumn} {...noteProps} />)}
                {!modeFull &&
                  filteredNotes
                    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
                    .map(n => <Note key={n.id} note={{ ...n, isConfidential: false }} hideReply editHideFirstRow {...noteProps} />)}
              </div>
            </Paper>
            {filteredNotes.length === 0 && <NoRecords />}
          </Grid>
        </Grid>
        <Confirm {...confirmConfig} />
        {fileViewer}
      </AssociateNoteContext.Provider>
    </Fragment>
  );
};

Notes.propTypes = { actionsAllowed: PropTypes.objectOf(PropTypes.any), entityNumber: PropTypes.string };
Notes.defaultProps = {
  actionsAllowed: { create: true, update: true, delete: true, confidentialCreate: true, confidentialUpdate: true, confidentialDelete: true },
  entityNumber: ""
};

export default Notes;
