import { makeStyles } from "@material-ui/core/styles";

const wrapper = {
  "& a": { color: "#648C9B" },
  "& p": { color: "#004763" }
};

export default makeStyles(theme => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  wrapper: {
    paddingTop: "17px",
    ...wrapper
  },
  minimum: wrapper,
  gutterBottom: { paddingBottom: theme.spacing(2) },
  container: {
    padding: theme.spacing(1, 3)
  }
}));
