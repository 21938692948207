import { API, ADMIN } from "../../actions/actionTypes";
import { getSuccess, getAllSuccess, deleteSecurityProfileSuccess, setSuccess, postSuccess } from "../../actions/admin/securityProfile";
import { takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "../common/sagaGenerator";

const url = `${API.URL}/securityprofile`;

export default [
  takeLatest({
    type: ADMIN.SECURITYPROFILE.GET,
    url,
    params: ({ id }) => ({ id }),
    actionSuccess: getSuccess,
    errorMessage: "Unable to get Security Profile"
  }),
  takeLatest({
    type: ADMIN.SECURITYPROFILE.GETALL,
    url: `${url}/getAll`,
    actionSuccess: getAllSuccess,
    errorMessage: "Unable to get Security Profiles"
  }),
  takeLatestDel({
    type: ADMIN.SECURITYPROFILE.DEL,
    url,
    actionSuccess: deleteSecurityProfileSuccess,
    successMessage: "Security Profile successfully removed",
    errorMessage: "Unable to remove the Security Profile",
    params: ({ id }) => ({ id })
  }),
  takeLatestSet({
    type: ADMIN.SECURITYPROFILE.SET,
    url,
    actionSuccess: setSuccess,
    successMessage: "Security Profile successfully updated",
    errorMessage: "Unable to update the Security Profile",
    params: ({ data }) => data
  }),
  takeLatestPost({
    type: ADMIN.SECURITYPROFILE.POST,
    url,
    params: ({ data }) => data,
    actionSuccess: postSuccess,
    successMessage: "Security Profile successfully created",
    errorMessage: "Unable to create the Security Profile"
  })
];
