export default {
  claims: [],
  claim: { location: {} },
  claimant: { location: {}, mailingLocation: {}, claimsAssociated: [] },
  wcClaim: { location: {} },
  employment: {},
  employeeAccident: {},
  insured: { location: {}, contact: {} },
  bodilyInjury: {},
  diaries: [],
  coverageDocuments: [],
  policy: { coverageList: [], stateList: [], codeList: [] },
  overviewPolicyBox: { coverageDetailList: [], limitList: [] },
  claimantAttorneys: [],
  defendantAttorneys: [],
  hearings: [],
  witnesses: [],
  trial: { trialNoticeReceived: false, isCaseSettled: false },
  mediationArbitration: {},
  settled: {},
  attorneyByFirm: [],
  reserves: {
    currentDetails: [],
    historyDetails: []
  },
  propertyDamaged: { damaged: {}, property: {}, insProperties: [] },
  insVehicle: { vehicle: {}, driver: { location: {} } },
  damagedVehicle: { vehicle: {}, driver: { location: {} }, vehicleOwner: { location: {} }, lienHolder: { location: {} } },
  documents: {
    tree: { fileList: [], folderList: [] },
    allFiles: []
  },
  bill: [],
  financial: {
    financialList: [],
    recoveryList: [],
    summaryBox: {}
  },
  notes: [],
  draftNote: {},
  communication: {
    communications: [],
    claimEmails: []
  },
  activity: [],
  diaryTemplates: {
    templates: []
  },
  communicationTemplates: {
    templates: []
  },
  filterfields: {},
  coveragePoliciesByLob: [],
  isActivityWidgetActive: true
};
