import { Chip, Grid, IconButton } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useContext, useState, memo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { SelectInput, CheckboxLabel, InputText } from "../inputs";
import AssociateNoteContext from "./AssociateNoteContext";
import AssociatedList from "../AssociatedList";
import FileInput, { onIdClick } from "../documents/FileInput";
import { validateFile } from "../documents/hooks";
import FileIcon from "../documents/FileIcon";
import { array } from "../../../common";

const fileName = file => {
  const name = file?.name || file?.file?.name;
  return name ? name.substr(0, name.lastIndexOf(".")) : file?.fileName || file?.file?.fileName;
};

const fileType = file => file?.type || file?.file?.type || file?.fileType || file?.file?.fileType;

const NoteForm = memo(({ hideFirstRow, onChange, data, validationMessages, actionsAllowed }) => {
  const [formId] = useState(uuid());
  const fileInputId = `note-file-input-${formId}`;
  const associateNote = useContext(AssociateNoteContext);
  const { entityType, associatedList, clearChecks } = associateNote;
  const { claimIds, id: entityId, occurrenceIds, isConfidential, files: fs } = data;
  const showList = !data?.parentNoteId && ["occurrence", "catastrophe"].some(x => x === entityType);
  const claimInfo =
    entityType === "claim" ? useSelector(({ claim: { claim: c }, initialReport: { claim: irc } }) => (c?.id ? c : irc), shallowEqual) : {};
  const allNoteTypes = useSelector(
    ({
      admin: {
        claimSettings: { noteTypes }
      }
    }) => noteTypes,
    shallowEqual
  );
  const noteType = allNoteTypes.filter(
    item =>
      (entityType === "claim" &&
        item.parentId === 1 &&
        item.claimLineOfBusinessId === claimInfo.claimLineOfBusinessId &&
        item.claimLossTypeGroupId === claimInfo.claimLossTypeGroupId) ||
      (entityType === "occurrence" && item.id === 2) ||
      (entityType === "catastrophe" && item.id === 3)
  );

  const onEdit = (vclaimIds, voccurrenceIds) => {
    onChange({ target: { name: "claimIds", value: vclaimIds } });
    if (entityType === "catastrophe" && voccurrenceIds) {
      onChange({ target: { name: "occurrenceIds", value: voccurrenceIds } });
    }
  };
  const iProps = (name, label) => ({ id: `${name}-${formId}`, name, label, value: data[name], onChange, error: validationMessages[name] });
  const onUploadChange = ({ target: { files } }) => {
    if ([...files].some(file => !validateFile({ type: file.type, kind: "file" }))) return toast.warn("Invalid file type");
    return onChange({
      target: { value: ps => ({ ...ps, files: [...array(ps.files), ...[...files].map((_f, i) => ({ id: uuid(), file: files[i] }))] }) }
    });
  };
  const onDeleteFile = file => () => onChange({ target: { name: "files", value: fs.filter(f => f.id !== file.id) } });
  return (
    <>
      {!hideFirstRow && (
        <Grid container spacing={3}>
          <Grid item style={{ minWidth: 200 }}>
            <SelectInput {...iProps("typeId", "Note Type")} options={noteType || []} disabled={entityType !== "claim"} />
          </Grid>
          <Grid item style={{ paddingTop: 25, marginBottom: -10 }}>
            {(!!entityId || actionsAllowed.confidentialCreate) && (
              <CheckboxLabel
                name="isConfidential"
                label="Confidential"
                checked={isConfidential}
                disabled={!actionsAllowed[`confidential${entityId ? "Upd" : "Cre"}ate`]}
                {...{ onChange }}
              />
            )}
          </Grid>
        </Grid>
      )}
      <InputText {...iProps("title", "Title")} />
      <InputText {...iProps("note", "Description")} rows={3} />
      <div className="tc-pb2">
        <Grid container spacing={1} direction="row-reverse">
          <Grid item>
            <IconButton onClick={onIdClick(fileInputId)}>
              <AttachFile fontSize="small" />
            </IconButton>
            <FileInput onChange={onUploadChange} id={fileInputId} />
          </Grid>
          {array(fs).map(file => (
            <Grid key={file.id} item>
              <Chip icon={<FileIcon file type={fileType(file)} />} label={fileName(file)} onDelete={onDeleteFile(file)} />
            </Grid>
          ))}
        </Grid>
      </div>
      {showList && (
        <AssociatedList
          onEdit={onEdit}
          entityType={entityType}
          entityId={entityId}
          clearChecks={clearChecks}
          associatedList={associatedList}
          claimIds={claimIds}
          occurrenceIds={occurrenceIds}
        />
      )}
    </>
  );
});

NoteForm.propTypes = {
  hideFirstRow: PropTypes.bool,
  validations: PropTypes.shape({
    title: PropTypes.string,
    note: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  validationMessages: PropTypes.shape({}),
  actionsAllowed: PropTypes.objectOf(PropTypes.any)
};

NoteForm.defaultProps = {
  hideFirstRow: false,
  validations: {},
  data: {},
  validationMessages: {},
  actionsAllowed: { create: true, confidentialCreate: true, confidentialUpdate: true }
};

export default NoteForm;
