import { ADMIN } from "../actionTypes";

export const getAllJurisdictionStates = () => ({ type: ADMIN.CLAIMSETTING.JURISDICTIONSTATE.GETALL });
export const getAllJurisdictionStatesSuccess = jurisdictionStates => ({
  type: ADMIN.CLAIMSETTING.JURISDICTIONSTATE.GETALL_SUCCESS,
  jurisdictionStates
});
export const postJurisdictionState = data => ({ type: ADMIN.CLAIMSETTING.JURISDICTIONSTATE.POST, data });
export const postJurisdictionStateSuccess = jurisdictionStates => ({ type: ADMIN.CLAIMSETTING.JURISDICTIONSTATE.POST_SUCCESS, jurisdictionStates });
export const setJurisdictionState = data => ({ type: ADMIN.CLAIMSETTING.JURISDICTIONSTATE.SET, data });
export const setJurisdictionStateSuccess = jurisdictionStates => ({ type: ADMIN.CLAIMSETTING.JURISDICTIONSTATE.SET_SUCCESS, jurisdictionStates });
export const delJurisdictionState = data => ({ type: ADMIN.CLAIMSETTING.JURISDICTIONSTATE.DEL, data });
export const delJurisdictionStateSuccess = jurisdictionStates => ({ type: ADMIN.CLAIMSETTING.JURISDICTIONSTATE.DEL_SUCCESS, jurisdictionStates });
export const getAllLineOfBusiness = () => ({ type: ADMIN.CLAIMSETTING.LINEOFBUSINESS.GETALL });
export const getAllLineOfBusinessSuccess = lineOfBusiness => ({
  type: ADMIN.CLAIMSETTING.LINEOFBUSINESS.GETALL_SUCCESS,
  lineOfBusiness
});
export const postLineOfBusiness = data => ({ type: ADMIN.CLAIMSETTING.LINEOFBUSINESS.POST, data });
export const postLineOfBusinessSuccess = lineOfBusiness => ({ type: ADMIN.CLAIMSETTING.LINEOFBUSINESS.POST_SUCCESS, lineOfBusiness });
export const setLineOfBusiness = data => ({ type: ADMIN.CLAIMSETTING.LINEOFBUSINESS.SET, data });
export const setLineOfBusinessSuccess = lineOfBusiness => ({ type: ADMIN.CLAIMSETTING.LINEOFBUSINESS.SET_SUCCESS, lineOfBusiness });
export const delLineOfBusiness = data => ({ type: ADMIN.CLAIMSETTING.LINEOFBUSINESS.DEL, data });
export const delLineOfBusinessSuccess = lineOfBusiness => ({ type: ADMIN.CLAIMSETTING.LINEOFBUSINESS.DEL_SUCCESS, lineOfBusiness });
export const getAllTimelineDates = (onSuccess, onError) => ({ type: ADMIN.CLAIMSETTING.TIMELINEDATE.GETALL, onSuccess, onError });
export const getAllTimelineDatesSuccess = timelineDates => ({
  type: ADMIN.CLAIMSETTING.TIMELINEDATE.GETALL_SUCCESS,
  timelineDates
});
export const setTimelineDates = (data, onSuccess, onError) => ({ type: ADMIN.CLAIMSETTING.TIMELINEDATE.SET, data, onSuccess, onError });
export const setTimelineDatesSuccess = timelineDates => ({ type: ADMIN.CLAIMSETTING.TIMELINEDATE.SET_SUCCESS, timelineDates });
export const getAllWidget = (widgetName, onSuccess, onError) => ({ type: ADMIN.CLAIMSETTING.WIDGET.GETALL, widgetName, onSuccess, onError });
export const getAllWidgetSuccess = widgets => ({
  type: ADMIN.CLAIMSETTING.WIDGET.GETALL_SUCCESS,
  widgets
});
export const setWidget = (data, onSuccess, onError) => ({ type: ADMIN.CLAIMSETTING.WIDGET.SET, data, onSuccess, onError });
export const setWidgetSuccess = widgets => ({ type: ADMIN.CLAIMSETTING.WIDGET.SET_SUCCESS, widgets });

export const getReserves = () => ({ type: ADMIN.CLAIMSETTING.RESERVES.GETALL });
export const getReservesSuccess = reserves => ({ type: ADMIN.CLAIMSETTING.RESERVES.GETALL_SUCCESS, reserves });
export const postReserves = (reserves, onSuccess, onError) => ({ type: ADMIN.CLAIMSETTING.RESERVES.POST, reserves, onSuccess, onError });
export const postReservesSuccess = reserves => ({ type: ADMIN.CLAIMSETTING.RESERVES.POST_SUCCESS, reserves });
export const delReserves = (reserves, onSuccess, onError) => ({ type: ADMIN.CLAIMSETTING.RESERVES.DEL, reserves, onSuccess, onError });
export const delReservesSuccess = reserves => ({ type: ADMIN.CLAIMSETTING.RESERVES.DEL_SUCCESS, reserves });
export const postReserveTypes = (reservetypes, onSuccess, onError) => ({
  type: ADMIN.CLAIMSETTING.RESERVES.TYPES.POST,
  reservetypes,
  onSuccess,
  onError
});
export const postReserveTypesSuccess = reservetypes => ({ type: ADMIN.CLAIMSETTING.RESERVES.TYPES.POST_SUCCESS, reservetypes });
export const getReserveTypes = () => ({ type: ADMIN.CLAIMSETTING.RESERVES.TYPES.GETALL });
export const getReserveTypesSuccess = reservetypes => ({ type: ADMIN.CLAIMSETTING.RESERVES.TYPES.GETALL_SUCCESS, reservetypes });

export const getDocument = (id, onSuccess) => ({ type: ADMIN.CLAIMSETTING.DOCUMENTS.GET, id, onSuccess });
export const getDocumentSuccess = () => ({ type: ADMIN.CLAIMSETTING.DOCUMENTS.GET_SUCCESS });

export const getDocuments = (getStructure, onSuccess, onError) => ({ type: ADMIN.CLAIMSETTING.DOCUMENTS.GETALL, getStructure, onSuccess, onError });
export const getDocumentsSuccess = documents => ({ type: ADMIN.CLAIMSETTING.DOCUMENTS.GETALL_SUCCESS, documents });

export const setDocuments = (data, onSuccess, onError) => ({ type: ADMIN.CLAIMSETTING.DOCUMENTS.SET, data, onSuccess, onError });
export const setDocumentsSuccess = documents => ({ type: ADMIN.CLAIMSETTING.DOCUMENTS.SET_SUCCESS, documents });

export const activateDocuments = (active, onSuccess, onError) => ({ type: ADMIN.CLAIMSETTING.DOCUMENTS.SETACTIVE, active, onSuccess, onError });
export const activateDocumentsSuccess = documents => ({ type: ADMIN.CLAIMSETTING.DOCUMENTS.SETACTIVE_SUCCESS, documents });

export const getDocumentsActive = () => ({ type: ADMIN.CLAIMSETTING.DOCUMENTS.GETACTIVE });
export const getDocumentsActiveSuccess = active => ({ type: ADMIN.CLAIMSETTING.DOCUMENTS.GETACTIVE_SUCCESS, active });

export const delDocument = (id, onSuccess) => ({ type: ADMIN.CLAIMSETTING.DOCUMENTS.DEL, id, onSuccess });
export const delDocumentSuccess = documents => ({ type: ADMIN.CLAIMSETTING.DOCUMENTS.DEL_SUCCESS, documents });
