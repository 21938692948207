export default {
  clientLevels: [],
  breadCrumbRoutes: [],
  isLoading: false,
  settings: {
    reservePaymentSetting: {},
    billReviewSettings: [],
    teamSetting: {}
  },
  notes: [],
  profile: {
    basicInformation: {},
    industryOperatingStates: {
      codes: [],
      stateInsured: []
    },
    clientStatus: {},
    financial: { barChart: [], total: {} }
  },
  insuredProfile: {
    basicInformation: {},
    industryOperatingStates: {
      codes: [],
      stateInsured: []
    }
  },
  clients: [],
  policies: [],
  areas: [],
  entityList: [],
  contacts: [],
  employees: [],
  accounts: [],
  policy: {
    insuredPolicy: {},
    vehicles: [],
    properties: [],
    property: {},
    policyCodes: [],
    policies: [],
    aggregateDetails: {}
  },
  draftNote: {},
  documents: {
    tree: { fileList: [], folderList: [] },
    allFiles: []
  }
};
