import React from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "@material-ui/core";
import noteStyle from "./Note.styles";

const AddFooter = ({ doubleColumn, cancel, saveDraft, addNote, isSaveDisabled }) => {
  const cancelBtnText = doubleColumn ? "Clear All" : "Cancel";
  const saveBtnText = doubleColumn ? "Add Note" : "Save";
  const classes = noteStyle();
  return (
    <Grid container spacing={3} justify="flex-end">
      <Grid item>
        <Button variant="outlined" color="primary" onClick={cancel}>
          {cancelBtnText}
        </Button>
      </Grid>
      <Grid item>
        <Button variant="outlined" color="primary" onClick={saveDraft} className={classes.saveDraft}>
          Save Draft
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" onClick={addNote} disabled={isSaveDisabled}>
          {saveBtnText}
        </Button>
      </Grid>
    </Grid>
  );
};
AddFooter.propTypes = {
  doubleColumn: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  saveDraft: PropTypes.func.isRequired,
  addNote: PropTypes.func.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired
};

export default AddFooter;
