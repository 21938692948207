import axios from "axios";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDebounce } from "../../../../common";
import { API } from "../../../../redux/actions/actionTypes";
import useYupValidationSchema from "../../../common/customHooks/useYupValidationSchema";
import useViewer from "../../../common/viewer/useViewer";
import { indexSchema, searchSchema } from "./yup";

export const useIndex = (actions, indexItem) => {
  const [state, setState] = useState({ step: 0, focused: false });
  const { openFile, save } = actions;
  const { handleValidateSchema, validationMessages: error } = useYupValidationSchema(indexSchema);
  const setClaim = fn =>
    setState(ps => {
      const claim = fn(ps.claim);
      handleValidateSchema(claim);
      return { ...ps, claim };
    });
  const postParams = isReviewCompany => {
    const reduce = source => (acc, p) => ({ ...acc, [p]: (source || {})[p] || undefined });
    const payee = ["id", "name", "taxID", "amount", "serviceFrom", "serviceTo"].reduce(reduce(state?.claim?.payee), {});
    const claim = ["indexTypeId", "folderId", "billTypeId", "userIds"].reduce(reduce(state?.claim), { payee });
    const { id, statusId } = indexItem;
    return { data: { id, statusId, claimId: state.claim.id, ...claim, reviewCompany: isReviewCompany || false } };
  };
  const onValidate = (fn, isVendorReview, isReviewCompany) => () => {
    const { isValid, validationMessages } = handleValidateSchema(state.claim);
    const valid = isValid || (isVendorReview && Object.keys(validationMessages).filter(k => k !== "payee.id").length === 0);
    if (!valid || typeof fn !== "function") return false;
    return fn(postParams(isReviewCompany));
  };
  const indexActions = {
    ...actions,
    onSelect: useCallback(claim => {
      actions.showLoading();
      return axios
        .get(`${API.URL}/folder/list`, { params: { getQueue: false, claimId: claim.id, indexingStatusId: 1 } })
        .then(({ data: folders }) => setState(ps => ({ ...ps, claim: { ...claim, userIds: [], folders, payee: {} }, step: 1 })))
        .catch(() => toast.error("Unable to get Claim Folders"))
        .finally(actions.hideLoading);
    }, []),
    onChange: useCallback(({ target: { name, value } }) => setClaim(ps => ({ ...ps, [name]: value })), []),
    onChangeType: useCallback(({ target: { value } }) => {
      const claim = { indexTypeId: value, folderId: undefined, billTypeId: undefined, payee: {} };
      return setClaim(ps => ({ ...ps, ...claim }));
    }, []),
    onChangePayee: useCallback(({ target: { name, value } }) => setClaim(ps => ({ ...ps, payee: { ...ps.payee, [name]: value } })), []),
    onSelectPayee: useCallback(payee => setClaim(ps => ({ ...ps, payee: { ...ps.payee, ...payee } })), []),
    onSave: onValidate(save),
    onReviewCompany: onValidate(save, false, true),
    onReviewVendor: onValidate(save, true),
    onFocus: useCallback(focused => () => setState(ps => ({ ...ps, focused })), [])
  };
  const { onViewFile, fileViewer, blob } = useViewer({ dispatchAction: openFile, modal: false });
  useEffect(() => {
    if (indexItem?.id && indexItem?.statusId) onViewFile(indexItem.id, { indexingStatusId: indexItem.statusId });
  }, [indexItem?.id, indexItem?.statusId]);
  const { step, claim, focused } = state;
  const warning = Boolean(step === 1 && claim?.indexTypeId === 1 && !claim?.payee?.id && error["payee.id"] && !focused);
  return { state, indexActions, error, warning, fileViewer, blob };
};

const paths = ["number", "dateOfLoss", "claimantName", "dateTo", "statusIds", "clientNumber"];

const buildParams = state =>
  paths.reduce((acc, p) => (state[p] && (!Array.isArray(state[p]) || state[p].length > 0) ? { ...acc, [p]: state[p] } : acc), {});

export const useSearch = ({ showLoading, hideLoading }, disabled) => {
  const [state, setState] = useState({ statusIds: [], data: [] });
  const store = useSelector(({ common: { claimStatus } }) => claimStatus, shallowEqual);
  const claimStatus = (store || []).filter(({ text }) => text !== "Reopened" && text !== "Voided");
  const findDebounced = useDebounce(params => {
    showLoading();
    return axios.get(`${API.URL}/claim/search`, { params, paramsSerializer: p => qs.stringify(p, { indices: false }) });
  }, 500);
  const { handleValidateSchema, validationMessages } = useYupValidationSchema(searchSchema);
  useEffect(
    () => {
      const params = buildParams(state);
      const { isValid } = handleValidateSchema(params);
      if (isValid && Object.keys(params).length > 0)
        findDebounced({ ...params, sameDol: true, rangeDate: Boolean(params.dateTo) })
          .then(({ data }) => setState(ps => ({ ...ps, data })))
          .catch(() => toast.error("Unable to get Claim results"))
          .finally(hideLoading);
    },
    paths.reduce((acc, p) => [...acc, state[p]], [])
  );

  const inputProps = (name, label, chkId) => ({
    name,
    label,
    error: (validationMessages || {})[name],
    disabled,
    onChange: ({ target: { value } }) =>
      setState(ps => {
        if (chkId) return { ...ps, statusIds: value ? [...ps.statusIds, chkId] : ps.statusIds.filter(x => x !== chkId) };
        return { ...ps, [name]: value };
      }),
    [chkId ? "checked" : "value"]: chkId ? state.statusIds.some(id => id === chkId) : state[name] || undefined
  });
  return { state, inputProps, claimStatus };
};
