import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useRouteParam } from "../../../common";
import { getAll } from "../../../redux/actions/notes";
import { getLocations, getClaimsInvolved, getFinancialInfo } from "../../../redux/actions/vendor";
import ClaimsInvolved from "./ClaimsInvolved";
import FinancialInfo from "./FinancialInfo";
import LastNotes from "./LastNotes";
import VendorInfo from "./VendorInfo";

export default () => {
  const dispatch = useDispatch();

  const vendorId = useRouteParam("id");

  const dataSelector = useSelector(({ vendor }) => vendor, shallowEqual);

  const {
    profile: { basicInformation, industry },
    locations,
    claimsInvolved,
    notes,
    financialInfo
  } = dataSelector || {};
  useEffect(() => {
    dispatch(getLocations(vendorId));
    dispatch(getClaimsInvolved(vendorId));
    dispatch(getAll({ id: vendorId, entityType: "vendor" }));
    dispatch(getFinancialInfo(vendorId));
  }, []);
  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={4}>
        <VendorInfo {...{ ...basicInformation, industry, locations }} />
      </Grid>
      <Grid item xs={8}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={6}>
            <ClaimsInvolved {...{ ...claimsInvolved }} />
          </Grid>
          <Grid item xs={6}>
            <LastNotes notes={notes} />
          </Grid>
          <Grid item xs={12}>
            <FinancialInfo financialInfo={financialInfo} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
