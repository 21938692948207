import { CLAIM, API } from "../../../actions/actionTypes";
import { getAllSuccess, postSuccess, setSuccess, delSuccess } from "../../../actions/claim/info/witness";
import { takeLatest, takeLatestPost, takeLatestSet, takeLatestDel } from "../../common/sagaGenerator";

const url = `${API.URL}/witness`;

export default [
  takeLatest({
    type: CLAIM.INFO.WITNESS.GETALL,
    actionSuccess: getAllSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading Witnesses",
    url: `${url}/witnesses`
  }),
  takeLatestPost({
    type: CLAIM.INFO.WITNESS.POST,
    actionSuccess: postSuccess,
    params: ({ witness }) => witness,
    url,
    errorMessage: "Unable to add witness",
    successMessage: "Witness successfully saved"
  }),
  takeLatestSet({
    type: CLAIM.INFO.WITNESS.SET,
    actionSuccess: setSuccess,
    params: ({ witness }) => witness,
    url,
    successMessage: "Witness successfully updated",
    errorMessage: "Unable to update witness"
  }),
  takeLatestDel({
    type: CLAIM.INFO.WITNESS.DEL,
    actionSuccess: delSuccess,
    params: ({ id }) => ({ id }),
    successMessage: "Witness deleted",
    errorMessage: "Unable to delete witness",
    url
  })
];
