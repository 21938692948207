import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    height: "calc(100vh - 64px)",
    backgroundImage: `url(../src/assets/terra_background_not_found.svg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "repeat"
  },
  container: {
    height: "calc(100vh - 64px)",
    margin: "auto"
  },
  title: {
    fontFamily: "'Exo 2'",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "normal",
    fontSize: "72px",
    textAlign: "left",
    lineHeight: "86px",
    letterSpacing: "0px",
    color: "#107A98",
    opacity: 1
  },
  subtitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    textAlign: "left",
    lineHeight: "29px",
    letterSpacing: "0px",
    color: "#707070",
    opacity: 1
  },
  logo: {
    backgroundImage: `url(../src/assets/terra_logo.svg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "578px",
    width: "550px"
  },
  btnHomePage: {
    width: "224px",
    height: "56px",
    background: "#107a98 0% 0% no-repeat padding-box",
    border: "1px solid #107A98",
    borderRadius: "4px",
    opacity: 1,
    padding: "0px",
    textAlign: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "22px",
    letterSpacing: "0px",
    color: "#E6EEF6",
    textTransform: "uppercase"
  },
  content: {
    padding: theme.spacing(2)
  }
}));
