import { CLIENTR as CLIENT, API } from "../../actions/actionTypes";
import { getSuccess, addClientSuccess, getByParentIdSuccess, delSuccess, changeEntitysParentSuccess } from "../../actions/client/list";
import { takeLatest, takeLatestDel, takeLatestPost } from "../common/sagaGenerator";

export default [
  takeLatest({ type: CLIENT.LIST.GET, actionSuccess: getSuccess, errorMessage: "Clients Fetch error.", url: `${API.URL}/client/getAll` }),
  takeLatest({
    type: CLIENT.LIST.GET_ALL_BY_PARENT,
    actionSuccess: getByParentIdSuccess,
    params: ({ parentId }) => ({ parentId }),
    errorMessage: "Clients Fetch error.",
    url: `${API.URL}/client/getAll`
  }),
  takeLatestPost({
    type: CLIENT.ADDCLIENT.POST,
    actionSuccess: addClientSuccess,
    url: `${API.URL}/client`,
    params: ({ data }) => data
  }),
  takeLatestDel({
    type: CLIENT.LIST.DEL,
    actionSuccess: delSuccess,
    url: `${API.URL}/client`,
    params: ({ client: { id, parentId } }) => ({ id, parentId })
  }),
  takeLatestPost({
    type: CLIENT.LIST.MOVE_ENTITY_TO_NEW_PARENT,
    actionSuccess: changeEntitysParentSuccess,
    url: `${API.URL}/client/setParent`,
    params: ({ data }) => data
  })
];
