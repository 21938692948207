import { Paper } from "@material-ui/core";
import React, { useEffect, useContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getReview } from "../../redux/actions/documents";
import { useLoading } from "../common/Loading";
import MaterialTable from "../common/table/MaterialTable";
import ActionsAllowed from "../common/contexts/ActionsAllowed";
import { setExportTitleFileName } from "../../common";

export default () => {
  const routesAllowed = useContext(ActionsAllowed);
  const dispatch = useDispatch();

  const data = useSelector(({ documents: { review } }) => review, shallowEqual);
  const {
    Loading,
    actions: { showLoading, hideLoading }
  } = useLoading(true);
  const callbackDispatch = (action, onSuccess) => params => {
    showLoading();
    const onComplete = {
      onSuccess: (...success) => {
        if (typeof onSuccess === "function") onSuccess(...success);
        return hideLoading();
      },
      onError: hideLoading
    };
    return dispatch(action({ indexingStatusId: -1, ...params, ...onComplete }));
  };
  const access = routesAllowed.find(elem => elem.route === "indexing");
  useEffect(() => {
    if (access.read) {
      callbackDispatch(getReview)();
    }
  }, []);
  const tableProps = {
    title: "Special Reviewe Queue",
    data,
    columns: [
      {
        title: "Claim",
        field: "claimNumber",
        render: row => {
          if (row?.claimAllowed) return <Link to={`/claim/${row.claimId}`}>{row.claimNumber}</Link>;
          return row.claimNumber;
        }
      },
      {
        title: "Name",
        field: "fileName"
      },
      { title: "Type", field: "indexType" },
      { title: "Date", field: "createdOn", type: "date" },
      { title: "Diary Related", field: "taskName" },
      { title: "Diary Assigned To", field: "taskUser" },
      { title: "Diary Status", field: "taskStatus" },
      { title: "Due Date", field: "dueDate", type: "date" }
    ],
    hidePaging: data.length <= 50,
    options: { pageSize: 50, exportFileName: setExportTitleFileName("DocumentsSpecialReviewQueue") }
  };
  return (
    <>
      {Loading}
      <div className="tc-page-content">
        <Paper className="tc-p2">
          <MaterialTable {...tableProps} />
        </Paper>
      </div>
    </>
  );
};
