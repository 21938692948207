import React, { useContext } from "react";
import { Grid, Divider } from "@material-ui/core";
import LabelValue from "../LabelValue";
import { PropertyContext } from "./PropertyContext";
import MultipleLabels from "../Labels";

const PropertyRead = () => {
  const { state } = useContext(PropertyContext);
  const { damaged, property, insProperties, isPropertyLiability } = state;
  const isNotCovered = isPropertyLiability && !!property?.id && !insProperties.some(x => x.id === property.id && x.hasCoverage);
  return (
    <React.Fragment key="damagedpropertyread">
      <form>
        {damaged?.claimId > -1 && (
          <>
            <Grid container spacing={4}>
              <Grid item xs>
                <LabelValue label="Kind of Loss" value={damaged.propertyDamagedLossType} />
              </Grid>
              <Grid item xs>
                <LabelValue label="Report To" value={damaged.departamentType} />
              </Grid>
              <Grid item xs>
                <LabelValue label="Report Number" value={damaged.reportNumber} />
              </Grid>
            </Grid>
            <Divider style={{ margin: "15px 0px 15px 0px" }} />
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <LabelValue label="Damage Type Reported" value={damaged.propertyDamagedType} />
              </Grid>
              <Grid item xs>
                <LabelValue label="Probable Amount Entire Loss" value={damaged.amountEntireLoss} />
              </Grid>
              <Grid item xs />
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs>
                <LabelValue label="Property Damage description" value={damaged.description} />
              </Grid>
            </Grid>
            <Divider style={{ margin: "15px 0px 15px 0px" }} />
            {property && (
              <>
                <Grid container spacing={4} wrap="nowrap" alignItems="center">
                  <Grid item>
                    <LabelValue label="Address" value={property.fullAddress} />
                  </Grid>
                  {isNotCovered && (
                    <Grid item className="tc-color-red">
                      Property not covered by selected Policy
                    </Grid>
                  )}
                </Grid>
                {property.hasMortgage && !damaged.isClaimantMortgageCompany && (
                  <>
                    <Divider />
                    <Grid container spacing={4}>
                      <Grid item xs={4}>
                        <LabelValue label="Mortgage Company Address" value={property.mortgageCompany.location} isAddress />
                      </Grid>
                      <Grid item xs>
                        <MultipleLabels
                          data={[
                            { label: "Mortgage Company Name", value: property.mortgageCompany.name, key: "mortgage_company_name" },
                            { label: "Mortgage Company Phone Number", value: property.mortgageCompany.phone, key: "mortgage_company_phone" },
                            {
                              label: "Mortgage Company Main Contact",
                              value: property.mortgageCompany.mainContactName,
                              key: "mortgage_company_contact"
                            }
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </>
        )}
      </form>
    </React.Fragment>
  );
};

export default PropertyRead;
