import { API, ADMIN } from "../../../actions/actionTypes";
import {
  getAllLineOfBusinessSuccess,
  postLineOfBusinessSuccess,
  setLineOfBusinessSuccess,
  delLineOfBusinessSuccess
} from "../../../actions/admin/claimsSetting";
import { takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "../../common/sagaGenerator";

const url = `${API.URL}/claimsetting`;

export default [
  takeLatest({
    type: ADMIN.CLAIMSETTING.LINEOFBUSINESS.GETALL,
    url: `${url}/getclaimlineofbusiness`,
    actionSuccess: getAllLineOfBusinessSuccess,
    errorMessage: "Error getting lines of business."
  }),
  takeLatestPost({
    type: ADMIN.CLAIMSETTING.LINEOFBUSINESS.POST,
    url: `${url}/claimlineofbusiness`,
    actionSuccess: postLineOfBusinessSuccess,
    successMessage: "Widget successfully added",
    errorMessage: "Unable to add the widget",
    params: ({ data }) => data
  }),
  takeLatestSet({
    type: ADMIN.CLAIMSETTING.LINEOFBUSINESS.SET,
    url: `${url}/claimlineofbusiness`,
    actionSuccess: setLineOfBusinessSuccess,
    successMessage: "Widget successfully updated",
    errorMessage: "Unable to update the widget",
    params: ({ data }) => data
  }),
  takeLatestDel({
    type: ADMIN.CLAIMSETTING.LINEOFBUSINESS.DEL,
    url: `${url}/claimlineofbusiness`,
    actionSuccess: delLineOfBusinessSuccess,
    successMessage: "Widget successfully deleted",
    errorMessage: "Unable to delete the widget",
    params: ({ data }) => data
  })
];
