import React, { memo } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import { Grid, Collapse } from "@material-ui/core";
import {
  CheckboxLabel,
  SelectInput,
  InputText,
  DateTimeInput,
  IntegerInput,
  PhoneInput,
  TaxSocialSecurityNumber,
  TaxIdNumber
} from "../../../../common/inputs";

const ClaimantForm = memo(({ classes, claimant, inputProps }) => {
  const { isPerson, phisicalAsMailing, sameAsInsured } = claimant;
  const { gender, maritalStatus, states, taxType } = useSelector(({ common }) => common, shallowEqual);
  return (
    !sameAsInsured && (
      <>
        <Grid container spacing={4}>
          <Grid item xs>
            <SelectInput
              {...inputProps("claimantTypeId")}
              options={[
                { id: 1, text: "Person" },
                { id: 2, text: "Entity" }
              ]}
              label="Claimant Type"
            />
            <Collapse in={isPerson} timeout="auto">
              <InputText {...inputProps("firstName")} label="First Name *" />
              <InputText {...inputProps("middleName")} label="Middle Name" />
              <InputText {...inputProps("lastName")} label="Last Name *" />
              <Grid container>
                <Grid item xs className={classes.pr1}>
                  <DateTimeInput {...inputProps("dateofBirth")} label="Date of Birth *" keyboard />
                </Grid>
                <Grid item xs className={classes.pl1}>
                  <SelectInput {...inputProps("genderId")} label="Gender" options={gender} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs className={classes.pr1}>
                  <SelectInput {...inputProps("maritalStatusId")} label="Marital Status" options={maritalStatus} />
                </Grid>
                <Grid item xs className={classes.pl1}>
                  <IntegerInput {...inputProps("dependents")} label="Dependents" />
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={!isPerson} timeout="auto">
              <InputText {...inputProps("commercialName")} label="Commercial Name *" />
              <InputText {...inputProps("contact")} label="Main Contact *" />
            </Collapse>
          </Grid>
          <Grid item xs>
            <Grid container>
              <Grid item xs className={classes.pr1}>
                <SelectInput {...inputProps("taxIDTypeId")} label="Tax ID Type *" options={taxType} />
              </Grid>
              <Grid item xs className={classes.pl1}>
                {claimant.taxIDTypeId === 1 ? (
                  <TaxSocialSecurityNumber {...inputProps("taxID")} label="Tax ID # *" />
                ) : (
                  <TaxIdNumber {...inputProps("taxID")} label="Tax ID # *" />
                )}
              </Grid>
            </Grid>
            <InputText {...inputProps("location.address1")} label="Physical Address 1 *" />
            <InputText {...inputProps("location.address2")} label="Physical Address 2" />
            <InputText {...inputProps("location.city")} label="City *" />
            <SelectInput {...inputProps("location.stateId")} label="State *" options={states} />
            <InputText {...inputProps("location.zipCode")} label="Zip *" />
          </Grid>
          <Grid item xs>
            <Collapse in={isPerson} timeout="auto">
              <PhoneInput {...inputProps("phone")} label="Home Phone" />
            </Collapse>
            <PhoneInput {...inputProps("mobile")} label="Cell Phone" />
            <PhoneInput {...inputProps("workPhone")} label="Work Phone" />
            <InputText {...inputProps("workEmail")} label="Work Email" />
            <InputText {...inputProps("email")} label="Personal Email" />
          </Grid>
        </Grid>
        <CheckboxLabel {...inputProps("phisicalAsMailing")} label="Use Physical Address as Mailing Address" />
        <Collapse in={!phisicalAsMailing} timeout="auto">
          <Grid container spacing={4}>
            <Grid item xs>
              <InputText {...inputProps("mailAddress.address1")} label="Mailing Address *" />
              <InputText {...inputProps("mailAddress.city")} label="City *" />
            </Grid>
            <Grid item xs>
              <SelectInput {...inputProps("mailAddress.stateId")} label="State *" options={states} />
            </Grid>
            <Grid item xs>
              <InputText {...inputProps("mailAddress.zipCode")} label="Zip *" />
            </Grid>
          </Grid>
        </Collapse>
      </>
    )
  );
});

ClaimantForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  claimant: PropTypes.objectOf(PropTypes.any).isRequired,
  inputProps: PropTypes.func.isRequired
};

export default ClaimantForm;
