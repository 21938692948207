import PROFILE from "./actionTypes/profile";

export const get = () => ({ type: PROFILE.GET });
export const getSuccess = profile => ({ type: PROFILE.GET_SUCCESS, profile });

export const set = data => ({ type: PROFILE.SET, data });
export const setSuccess = profile => ({ type: PROFILE.SET_SUCCESS, profile });

export const uploadPhoto = (file, onSuccess) => ({ type: PROFILE.POST, file, onSuccess });
export const uploadPhotoSuccess = photo => ({ type: PROFILE.POST_SUCCESS, photo });

export const delPhoto = onSuccess => ({ type: PROFILE.DEL, onSuccess });
export const delPhotoSuccess = () => ({ type: PROFILE.DEL_SUCCESS });

export const getPhoto = () => ({ type: PROFILE.PHOTO.GET });
export const getPhotoSuccess = photo => ({ type: PROFILE.PHOTO.GET_SUCCESS, photo });
