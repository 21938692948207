import PropTypes from "prop-types";
import React, { useCallback, useState, createRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { v4 as uuid } from "uuid";
import CustomToolbar from "./CustomToolbar";

const baseFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video"
];

const baseToolbar = [
  [{ header: "1" }, { header: "2" }, { font: [] }],
  [{ size: [] }],
  ["bold", "italic", "underline", "strike", "blockquote"],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  ["link", "image", "video"],
  ["clean"]
];

const baseModules = (customToolbar, container) => ({
  toolbar: customToolbar ? { container: `#${container || "toolbar"}` } : baseToolbar,
  clipboard: { matchVisual: false }
});

export const Editor = ({ editorClassName, readOnly, customToolbar, claimMergeTags, ...rest }) => {
  const { id, onChange, value, modules, formats } = rest;
  const [{ editorHtml, toolbarId }, setState] = useState({ editorHtml: "", toolbarId: `${id || `editor-${uuid()}`}-toolbar` });
  const ref = createRef();

  const handleChange = html => {
    if (typeof onChange === "function") return onChange(html);
    return setState(ps => ({ ...ps, editorHtml: html }));
  };
  const parseTag = tag => {
    const editor = ref.current.getEditor();
    editor.insertText(editor.getSelection()?.index || 0, tag);
    return ref.current.makeUnprivilegedEditor(editor).getHTML();
  };
  const onSelectTag = useCallback(
    tag => {
      if (typeof onChange === "function") return onChange(parseTag(tag));
      return setState(ps => ({ ...ps, editorHtml: parseTag(tag) }));
    },
    [onChange]
  );
  return (
    <div className={editorClassName}>
      {(customToolbar || claimMergeTags) && <CustomToolbar {...{ onSelectTag, readOnly, claimMergeTags, toolbarId }} />}
      <ReactQuill
        theme="snow"
        {...{
          bounds: ".app",
          readOnly: readOnly || false,
          ref,
          ...rest,
          onChange: handleChange,
          value: value || editorHtml,
          modules: modules || baseModules(customToolbar || claimMergeTags, toolbarId),
          format: formats || baseFormats
        }}
      />
    </div>
  );
};

Editor.propTypes = { editorClassName: PropTypes.string, readOnly: PropTypes.bool, customToolbar: PropTypes.bool, claimMergeTags: PropTypes.bool };
Editor.defaultProps = { editorClassName: undefined, readOnly: false, customToolbar: false, claimMergeTags: false };

export default Editor;
