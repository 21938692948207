import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { SearchInput } from "../../common/inputs";
import { FileInput } from "../../common/documents";
import { validateFile } from "../../common/documents/hooks";
import ProgressButton, { progressProps } from "../../common/documents/ProgressButton";

const VendorFilesHeader = ({ data, filter, onFilterChange, onDownload, onUpload, downloadState, uploadState, isUploadAllowed }) => {
  const [filesToUpload, setUploadFiles] = useState("");
  const onUploadChange = event => {
    event.preventDefault();
    event.stopPropagation();
    const {
      target: { files }
    } = event;
    if ([...files].map(f => ({ type: f.type, kind: "file" })).some(i => !validateFile(i))) toast.error("Invalid files");
    else onUpload(files);
    return setUploadFiles("");
  };
  const onAddFile = event => {
    event.preventDefault();
    document.querySelector("#document-upload-file").click();
  };
  return (
    <Grid container justify="flex-end" alignItems="center">
      <Grid item style={{ paddingRight: 12 }}>
        <SearchInput name="term" value={filter} onChange={onFilterChange} />
      </Grid>
      {data.some(f => f.checked) && (
        <Grid item>
          <ProgressButton {...downloadState} onClick={onDownload} />
        </Grid>
      )}
      {isUploadAllowed === true && (
        <Grid item>
          <FileInput value={filesToUpload} onChange={onUploadChange} />
          <ProgressButton {...uploadState} onClick={onAddFile} upload />
        </Grid>
      )}
    </Grid>
  );
};

VendorFilesHeader.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  filter: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  downloadState: PropTypes.shape(progressProps).isRequired,
  uploadState: PropTypes.shape(progressProps).isRequired,
  isUploadAllowed: PropTypes.bool.isRequired
};

export default VendorFilesHeader;
