import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, makeStyles } from "@material-ui/core";

const style = makeStyles(theme => ({
  buttonWrapper: {
    "& > *": {
      margin: theme.spacing(1)
    }
  }
}));

const EditModeFooter = ({ onCancel, onSave, isDisable }) => {
  const classes = style();
  return (
    <div>
      <Grid container alignItems="flex-start" justify="flex-end" direction="row">
        <div className={classes.buttonWrapper}>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            CANCEL
          </Button>
          <Button type="submit" variant="contained" color="primary" onClick={onSave} disabled={isDisable}>
            SAVE
          </Button>
        </div>
      </Grid>
    </div>
  );
};

EditModeFooter.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isDisable: PropTypes.bool.isRequired
};

export default EditModeFooter;
