import React, { useState, useEffect, Fragment } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import * as yup from "yup";
import { v4 as uuid } from "uuid";
import LinkButton from "../../../common/LinkButton";
import { TextInput, MoneyInput, SelectInput } from "../../../common/inputs";
import Money from "../../../common/MoneyLabel";
import { set } from "../../../../redux/actions/claim/reserves";
import styles from "./ReplaceReserve.styles";
import LabelValue from "../../../common/LabelValue";
import Confirm from "../../../common/Confirm";
import { useLoadingContext } from "../../../common/Loading";
import useYupValidationSchema from "../../../common/customHooks/useYupValidationSchema";
import {
  shouldBeReadOnly,
  stringToXML,
  appendPropertyFromXml,
  sumTotalAmount,
  isNullOrUndefined,
  sumPropertyTotal,
  makeReserveDetail,
  applySchemaXml,
  mapXmlFromJson
} from "./helperReserve";

const CurrentReserve = ({ onToggle, access }) => {
  const { isSup: isSupervisor, rs: reserveStatus, r: reserves } = useSelector(
    ({
      claim: {
        claim: { supervisorId: supId },
        reserves: r,
        filterfields: { supervisorId: ffsupId }
      },
      common: { reserveStatus: rs },
      profile: p
    }) => ({
      r,
      rs,
      isSup: (supId ?? ffsupId) === p?.id
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const classes = styles();
  const [reserve, setReserve] = useState({ currentDetails: [] });

  const inputField = ({ row, setting }) => {
    const type = setting.getAttribute("type");
    const label = setting.getAttribute("label");
    const name = setting.getAttribute("name") || uuid();
    // const value= setting.getAttribute("value");
    const alignLabel = setting.getAttribute("alignLabel");
    const isReadOnly = setting.getAttribute("readonly");
    const decimalScale = parseInt(setting.getAttribute("decimalScale") || 2, 10);
    const adornmentSymbol = setting.getAttribute("adornmentSymbol") || "";
    const valueLimit = parseFloat(setting.getAttribute("valueLimit") || 0);
    const isAllowed = ({ floatValue }) => floatValue <= valueLimit;
    const fn = setting.getAttribute("fn");

    switch (type) {
      case "INPUT":
        // eslint-disable-next-line no-case-declarations
        const onChange = !fn
          ? () => {}
          : (rowDetail, n, v) => {
              // eslint-disable-next-line no-new-func
              const resultFn = new Function("row", "name", "value", fn)(rowDetail, n, v);
              setReserve({ ...reserve, currentDetails: makeReserveDetail(reserve.currentDetails, "", resultFn, Number(row.typeId)) });
            };
        return (
          <Fragment key={`formula-${name}-${row.typeId}`}>
            {alignLabel === "L" && label && (
              <Grid item>
                <Typography variant="subtitle2">{label}</Typography>
              </Grid>
            )}
            <Grid item className={classes.money}>
              {!isReadOnly ? (
                <MoneyInput
                  value={row[name]}
                  name={`${row.typeId}-${name}`}
                  onChange={({ target: { name: nameInput, value } }) => {
                    onChange(row, nameInput, value);
                  }}
                  adornmentSymbol={adornmentSymbol}
                  decimalScale={decimalScale}
                  allowNegative={false}
                  {...(valueLimit > 0 && isAllowed)}
                />
              ) : (
                <Money value={row[name]} />
              )}
            </Grid>
            {alignLabel === "R" && label && (
              <Grid item>
                <Typography variant="subtitle2">{label}</Typography>
              </Grid>
            )}
          </Fragment>
        );
      case "LABEL":
        return (
          <Fragment key={`formula-${name}-${row.typeId}`}>
            <Grid item>{label}</Grid>
          </Fragment>
        );
      default:
        return undefined;
    }
  };

  const mapReserveTypes = data => [
    ...data.reduce(
      (acc, rt) => [
        ...acc,
        { ...rt, ...(rt.xml ? appendPropertyFromXml(rt.xml) : {}) },
        ...mapReserveTypes(reserves.currentDetails.filter(srt => srt.parentId === rt.typeId))
      ],
      []
    )
  ];

  useEffect(
    () =>
      setReserve({
        id: reserves.id,
        claimId: reserves.claimId,
        comment: reserves.comment,
        statusId: reserves.statusId,
        currentDetails: mapReserveTypes(
          reserves.currentDetails
            .filter(rt => rt.parentId === null)
            .map(x => ({
              ...x,
              previousAmount: x.amountReserved,
              previousTotalAmountReserved: !x.isMoney ? sumTotalAmount(reserves.currentDetails, x.typeId, "previousAmount") : 0.0,
              totalAmountReserved: !x.isMoney ? sumTotalAmount(reserves.currentDetails, x.typeId, "amountReserved") : 0.0
            }))
        )
      }),
    [reserves.currentDetails]
  );
  const {
    loading,
    actions: { showLoading, hideLoading }
  } = useLoadingContext();

  const schema = yup.object().shape({
    statusId: yup
      .number()
      .nullable()
      .test(
        "Status required",
        "Status required",
        (v, cx) => !isNullOrUndefined(v) || (!v && cx.parent.currentDetails.filter(rd => rd.amountReserved > 0).length <= 0)
      )
  });

  const { handleValidateSchema, validationMessages } = useYupValidationSchema(schema);

  const handleSave = () => {
    showLoading();
    const r = { ...reserve, currentDetails: reserve.currentDetails.map(rd => ({ ...rd, xml: mapXmlFromJson(rd) })) };
    dispatch(set(r, hideLoading, hideLoading));
    onToggle();
  };
  const [confirmProps, setConfirmProps] = useState({
    onAction: () => {},
    onToggleConfirm: () => {
      setConfirmProps(ps => ({ ...ps, openDialog: false }));
    }
  });

  const handleConfirm = () => {
    let message = "The reserve will be modified. Are you sure you want to proceed?";
    let title = "Reserve Modification";
    let onAction = "";
    let hideCancel = false;
    let okText = "Yes, Modify";
    const withOutChanges = reserve.currentDetails.filter(e => e.previousAmount === e.amountReserved);

    if (!handleValidateSchema(reserve).isValid) return;

    if (withOutChanges.length === reserve.currentDetails.length) {
      title = "Reserve validation";
      message = "You should change at least one reserve in order to save.";
      hideCancel = true;
      okText = "Close";
      onAction = () => {
        setConfirmProps(ps => ({ ...ps, openDialog: !ps.openDialog }));
      };
    } else {
      onAction = () => {
        setConfirmProps(ps => ({ ...ps, openDialog: !ps.openDialog }));
        handleSave();
      };
    }
    setConfirmProps(ps => ({ ...ps, messageText: message, title, openDialog: !ps.openDialog, onAction, hideCancel, okText }));
  };

  const inputProps = (col, name) => ({
    name,
    value: col[name.split("-")[1]],
    className: classes.inputWidth,
    onChange: ({ target: { value, name: nam } }) => {
      const [typeId, n] = nam.split("-");
      const state = { ...reserve, currentDetails: makeReserveDetail(reserve.currentDetails, n, value, Number(typeId)) };
      handleValidateSchema(state);
      setReserve(state);
    }
    // error: error && name.split("-")[1] === "statusId" && col.amountReserved > 0 && !isNumber(col[name.split("-")[1]]) ? error : undefined
  });

  const fieldProps = (name, label) => ({
    id: `field-${name}`,
    name,
    label,
    onChange: ({ target: { value } }) => {
      handleValidateSchema({ ...reserve, [name]: value });
      setReserve({ ...reserve, [name]: value });
    },
    error: (validationMessages || {})[name]
  });

  const containerSpacing = 1;
  const rowReserveType = rowData => {
    const isNotReadOnly = !shouldBeReadOnly(reserve.currentDetails, rowData);
    const isSchemaXml = applySchemaXml(rowData);

    return (
      <Fragment key={rowData.typeId}>
        {rowData.parentId === null && (
          <Grid container className={classes.gridContainer} spacing={containerSpacing}>
            <Grid item xs={8}>
              <Typography variant="subtitle2" className={classes.rowHeadLeft}>
                Type
              </Typography>
            </Grid>
            {!isSchemaXml && (
              <Grid item xs={2}>
                <Typography variant="subtitle2" className={classes.rowHead}>
                  Current Amount
                </Typography>
              </Grid>
            )}
            <Grid item xs={2}>
              <Typography variant="subtitle2" className={classes.rowHead}>
                New Amount
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.gridContainer} spacing={containerSpacing}>
          <Grid item className={rowData.parentId ? classes.paddingLeft40 : ""} style={{ flexGrow: 1 }}>
            {rowData.type}
          </Grid>
          {isSchemaXml && Array.from(stringToXML(rowData.xml).getElementsByTagName("Field")).map(item => inputField({ row: rowData, setting: item }))}
          {!isSchemaXml && (
            <Grid item xs={2} className={classes.money}>
              <Money value={isNotReadOnly ? rowData.previousAmount : rowData.previousTotalAmountReserved} />
            </Grid>
          )}
          <Grid item xs={2} className={classes.money}>
            {access.update && isNotReadOnly ? (
              <MoneyInput value={rowData.amountReserved} allowNegative={false} {...inputProps(rowData, `${rowData.typeId}-amountReserved`)} />
            ) : (
              <Money value={!isNullOrUndefined(rowData.parentId) ? rowData.amountReserved : rowData.totalAmountReserved} />
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  const previousAmountReservedTotal = sumPropertyTotal(reserve.currentDetails, "previousAmount");
  const newAmountReservedTotal = sumPropertyTotal(reserve.currentDetails, "amountReserved");

  return (
    <>
      <Paper className={classes.root}>
        <Grid container className={classes.gridContainer}>
          <Grid item className={classes.title}>
            <Typography variant="subtitle2">
              <LinkButton onClick={onToggle}>Current Reserve</LinkButton>
              {" > Replace Reserve"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.gridContainer} spacing={containerSpacing}>
          <Grid item xs>
            <Typography variant="subtitle2" className={classes.titleRight}>
              Reserve Status:
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {isSupervisor && access.update ? (
              <SelectInput value={reserve.statusId} options={reserveStatus} {...fieldProps("statusId", "Status")} />
            ) : (
              <LabelValue label="" value={reserves.status} />
            )}
          </Grid>
        </Grid>

        <Grid container className={classes.gridContainer} spacing={containerSpacing}>
          {reserve.currentDetails.map(rd => rowReserveType(rd))}
        </Grid>
        <Grid container className={classes.gridContainer}>
          <Grid item xs>
            <Typography variant="subtitle2">TOTAL</Typography>
          </Grid>
          <Grid item xs={2} className={classes.money}>
            <Money value={previousAmountReservedTotal} />
          </Grid>
          <Grid item xs={2} className={classes.money}>
            <Money value={newAmountReservedTotal} />
          </Grid>
        </Grid>

        <Grid container className={classes.gridContainer}>
          <Grid item xs>
            {access.update ? (
              <TextInput value={reserve.comment || undefined} rows={4} {...fieldProps("comment", "Comment")} />
            ) : (
              <LabelValue label="Comment" value={reserve.comment || undefined} />
            )}
          </Grid>
        </Grid>

        <div className={classes.actions}>
          <Button variant="outlined" color="primary" onClick={onToggle}>
            Cancel
          </Button>
          {access.update && (
            <Button variant="contained" color="primary" onClick={handleConfirm} disabled={loading}>
              Save
            </Button>
          )}
        </div>
      </Paper>
      <Confirm {...confirmProps} />
    </>
  );
};

CurrentReserve.propTypes = {
  onToggle: PropTypes.func.isRequired,
  access: PropTypes.objectOf(PropTypes.any).isRequired
};

export default CurrentReserve;
