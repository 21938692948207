import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    position: "absolute",
    border: "solid 1px #BBBBBB",
    backgroundColor: "white",
    bottom: -8,
    left: 13,
    width: 38,
    height: 38,
    padding: 7,
    zIndex: 1,
    "&:hover": { backgroundColor: "#F2F2F2" },
    transition: theme.transitions.create("bottom", {
      duration: theme.transitions.duration.shortest
    })
  },
  open: { bottom: -16 }
}));
