import React from "react";
import PropTypes from "prop-types";
import { Delete as DeleteIcon } from "@material-ui/icons";
import MaterialTable from "../../../../common/table/MaterialTable";
import { isNumber } from "../../../../../common/yup";
import { useSettledLogic } from "../customHooks";
import { SelectInput } from "../../../../common/inputs";

const BenefitList = ({ benefits, claimantAttorneys, delAction, addAction }) => {
  const { claimClaimant: claimant, payeeTypes, access } = useSettledLogic();
  const getAddress = loc => {
    return `${loc.address1 || ""} ${loc.address1 ? "," : ""} ${loc.city || ""}${loc.city && (loc.state || loc.zipCode) ? ", " : ""}${loc.state ||
      ""}${loc.state ? " " : ""}${loc.zipCode || ""}`.trim();
  };
  const getPayeeType = row => payeeTypes.find(e => e.id === row.payeeTypeId).text || "";
  const getPayeeName = row => {
    if (row.payeeTypeId === 2) return claimantAttorneys.find(a => a.attorneyId === row.vendorId)?.fullName;
    if (row.payeeTypeId === 1) return claimant?.fullName;
    return "";
  };
  const getFullAddress1 = row => {
    let payee = {};
    if (row.payeeTypeId === 2) payee = claimantAttorneys.find(a => a.attorneyId === row.vendorId);
    return getAddress(
      row.payeeTypeId === 1 ? claimant.location : { city: payee?.city, address1: payee?.address1, state: payee?.state, zipCode: payee?.zipCode }
    );
  };
  const addPayee = row => {
    addAction({ ...row, payeeType: getPayeeType(row), payeeName: getPayeeName(row), fullAddress1: getFullAddress1(row) });
  };
  const optionsAtt = claimantAttorneys
    .filter(e => !benefits.some(elem => elem.vendorId === e.attorneyId))
    .map(el => ({ id: el?.attorneyId, text: el?.fullName }));
  const inputProps = (col, name, error) => {
    const props = {
      name,
      value: col.rowData[name] || undefined,
      onChange: ({ target: { value } }) => col.onChange(value),
      error: error && !isNumber(col.rowData[name]) ? error : undefined
    };
    switch (name) {
      case "payeeTypeId": {
        return {
          ...props,
          allowClear: false,
          options: payeeTypes,
          onChange: ({ target: { value } }) => {
            const cSelected = value === 1 ? { claimantId: col.rowData.claimantId ?? claimant.id, vendorId: null } : {};
            col.onRowDataChange({ ...col.rowData, payeeTypeId: value, ...cSelected });
          }
        };
      }
      case "vendorId":
        return {
          ...props,
          allowClear: true,
          options: optionsAtt,
          onChange: ({ target: { value } }) => col.onRowDataChange({ ...col.rowData, vendorId: value, claimantId: null, payeeTypeId: 2 })
        };
      default:
        return { ...props };
    }
  };

  const handleDelete = rowData => {
    if (access.delete) delAction(rowData);
  };
  const readMode = !addAction && !delAction;
  const tableProps = {
    title: "Payees",
    columns: [
      {
        title: "Payee Type",
        field: "payeeType",
        width: null,
        headerStyle: { paddingRight: 0, paddingTop: 0, paddingBottom: 0, height: 48 },
        cellStyle: { width: 20 },
        editComponent: row => {
          if (!benefits.some(e => e.claimantId > 0)) return <SelectInput {...inputProps(row, "payeeTypeId", "Payee Type Required")} />;
          return "Vendor";
        }
      },
      {
        title: "Payee Name",
        field: "payeeName",
        width: null,
        cellStyle: { width: 100, whiteSpace: "nowrap" },
        headerStyle: { paddingRight: 0, paddingTop: 0, paddingBottom: 0, height: 48 },
        editComponent: col => {
          if (col.rowData.payeeTypeId === 1) return <span>{claimant?.fullName}</span>;
          return <SelectInput {...inputProps(col, "vendorId", "Payee Required")} />;
        }
      },
      {
        title: "Physical Address 1",
        field: "fullAddress1",
        headerStyle: { paddingRight: 0, paddingTop: 0, paddingBottom: 0, height: 48 },
        editComponent: col => {
          let payee = {};
          if (col.rowData.payeeTypeId === 2) payee = claimantAttorneys.find(a => a.attorneyId === col.rowData.vendorId);
          return getAddress(
            col.rowData.payeeTypeId === 1
              ? claimant.location
              : { city: payee?.city, address1: payee?.address1, state: payee?.state, zipCode: payee?.zipCode }
          );
        }
      }
    ],
    data: benefits,
    hideExportButton: true,
    options: { actionsColumnIndex: -1, search: false, paging: false, exportButton: false },
    components: { Container: ({ children }) => children },
    editable: {
      ...(access.create && !!addAction && (optionsAtt.length || !benefits.some(e => e.claimantId != null))
        ? {
            onRowAdd: newData => {
              return new Promise(resolve => {
                if ((!newData?.claimantId && isNumber(newData?.vendorId)) || (!newData?.vendorId && isNumber(newData?.claimantId))) {
                  addPayee(newData);
                  resolve();
                }
              });
            }
          }
        : {})
    },
    actions:
      access.delete && !!delAction
        ? [
            {
              icon: () => <DeleteIcon />,
              tooltip: "Delete",
              onClick: (_e, rowData) => {
                _e.stopPropagation();
                handleDelete(rowData);
              }
            }
          ]
        : []
  };
  return (
    <div style={{ marginTop: readMode ? -37 : -26 }}>
      <MaterialTable {...tableProps} />
    </div>
  );
};

BenefitList.propTypes = {
  delAction: PropTypes.func,
  addAction: PropTypes.func,
  benefits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  claimantAttorneys: PropTypes.arrayOf(PropTypes.shape({}))
};

BenefitList.defaultProps = {
  delAction: undefined,
  addAction: undefined,
  claimantAttorneys: []
};

export default BenefitList;
