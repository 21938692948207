import React, { useState, useEffect, useMemo } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import clsx from "clsx";
import { Paper, Grid } from "@material-ui/core";
import { Room } from "@material-ui/icons";
import CircleProgress from "../../../common/CircleProgress";
import Timeline from "../../../common/Timeline";
import { useActivityInfoStyles, useClaimInfoStyles } from "./index.styles";
import { getAllTimelineDates, getAllJurisdictionStates, getAllLineOfBusiness } from "../../../../redux/actions/admin/claimsSetting";
import LossTypeGroupIcon from "../../LossTypeGroupIcon";

export default () => {
  const dispatch = useDispatch();
  const classes = useActivityInfoStyles();
  const cClasses = useClaimInfoStyles();
  const useSelectorData = useSelector(
    ({
      claim: {
        claim,
        reserves: { historyDetails }
      },
      admin: { claimSettings }
    }) => ({ claim, historyDetails, claimSettings }),
    shallowEqual
  );
  const { historyDetails, claimSettings, claim } = useSelectorData;
  const { timelineDates, jurisdictionStates, lineOfBusiness, widgets } = claimSettings;
  const {
    id,
    dateEntry,
    dateofLoss,
    dateReported,
    openDaysQuantity: days,
    location,
    processingStatus,
    processingStatusDate,
    status,
    statusDate,
    location: { stateId },
    claimLineOfBusinessId,
    claimLossTypeGroupId,
    claimLineOfBusinessCode,
    claimLossTypeGroup
  } = claim;

  const dateReserved =
    historyDetails.length > 0
      ? historyDetails.reduce((prev, curr) => (prev.dateReserve < curr.dateReserve ? prev.dateReserve : curr.dateReserve))
      : null;

  const { stateCode } = location || {};

  useEffect(() => {
    if (!timelineDates.length) {
      dispatch(getAllTimelineDates());
      dispatch(getAllJurisdictionStates());
      dispatch(getAllLineOfBusiness());
    }
  }, []);
  const timeline = timelineDates.reduce((acc, item) => {
    if (item.checked)
      switch (item.id) {
        case 1:
          return [...acc, { ...item, date: dateofLoss }];
        case 2:
          return [...acc, { ...item, date: dateReported }];
        case 3:
          return [...acc, { ...item, date: dateEntry }];
        case 4:
          return [...acc, { ...item, date: processingStatusDate, label: `Claim ${processingStatus} Date` }];
        case 5:
          if (dateReserved) return [...acc, { ...item, date: dateReserved }];
          break;
        case 6:
          if (status === "Closed") return [...acc, { ...item, date: statusDate }];
          break;
        default:
          break;
      }
    return acc;
  }, []);

  const [pieChart, setState] = useState({ progress: 0, stroke: "#428302" });
  const activityWidget = widgets.find(e => e.name.toLowerCase() === "activity");
  const interval = useMemo(() => {
    const jstates = jurisdictionStates?.find(e => e.stateId === stateId);
    const lbusiness = lineOfBusiness?.find(e => e.claimLineOfBusinessId === claimLineOfBusinessId && e.lossTypeGroupId === claimLossTypeGroupId);
    return activityWidget?.isActiveJuridictionState
      ? [jstates?.daysRange1 ?? 15, jstates?.daysRange2 ?? 40, 100]
      : [lbusiness?.daysRange1 ?? 15, lbusiness?.daysRange2 ?? 40, 100];
  }, [activityWidget, jurisdictionStates, lineOfBusiness, activityWidget, stateId, claimLineOfBusinessId, claimLossTypeGroupId]);
  useEffect(() => {
    let s = "#E74C3C";
    if (days <= interval[0]) s = "#428302";
    else if (days > interval[0] && days <= interval[1]) s = "#F29120";
    setState({ progress: days / 100 || 0, stroke: s });
  }, [days, interval]);
  return (
    <Paper className={classes.root}>
      <Grid container spacing={4} style={{ height: "calc(100% + 32px)" }}>
        <Grid item xs={2}>
          <div className={classes.title}>Activity</div>
          <Grid container spacing={1} className={classes.location}>
            {activityWidget.isActiveJuridictionState && (
              <>
                <Grid item>
                  <Room />
                </Grid>
                <Grid item>{stateCode}</Grid>
              </>
            )}
            {!activityWidget.isActiveJuridictionState && (
              <>
                <Grid item>
                  <div className={cClasses.rectangle}>{claimLineOfBusinessCode}</div>
                </Grid>
                <Grid item>
                  <LossTypeGroupIcon type={claimLossTypeGroup} />
                </Grid>
              </>
            )}
          </Grid>
          {interval.map((d, i) => (
            <div key={d} className={classes.legend}>
              <span className={clsx(classes.mark, classes[`days${i + 1}`])} />
              {`${d} Days`}
            </div>
          ))}
        </Grid>
        <Grid item xs={5} style={{ alignSelf: "center" }}>
          {Boolean(id) && (
            <CircleProgress progress={pieChart.progress} stroke={pieChart.stroke} width={160}>
              <text x="0" y="-3" className={classes.progressDays}>
                {days}
              </text>
              <text x="0" y="16" className={classes.progressLabel}>
                Days Open
              </text>
            </CircleProgress>
          )}
        </Grid>
        <Grid item xs={5} style={{ alignSelf: "center" }}>
          <Timeline {...{ timeline }} />
        </Grid>
      </Grid>
    </Paper>
  );
};
