import { Button, Paper } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import FileInput, { onClick } from "../../../common/documents/FileInput";
import { validateFile } from "../../../common/documents/hooks";

const Dropzone = ({ onUpload }) => {
  const [{ hover, errorDrag }, setState] = useState({
    claimIds: [],
    occurrenceIds: [],
    hover: false,
    errorDrag: false,
    newFiles: [],
    editFile: undefined
  });
  const onUploadChange = ({ target: { files } }) => {
    if ([...files].map(f => ({ type: f.type, kind: "file" })).some(i => !validateFile(i))) return toast.error("Invalid files");
    return onUpload(files);
  };
  const handleEvent = useCallback(
    fn => event => {
      event.preventDefault();
      event.stopPropagation();
      if (typeof fn === "function") fn(event);
    },
    []
  );
  const onDragOver = handleEvent(({ dataTransfer: { items } }) =>
    setState(ps => ({ ...ps, hover: true, errorDrag: [...items].some(i => !validateFile(i)) }))
  );
  const onDragLeave = () => setState(ps => ({ ...ps, hover: false, errorDrag: false }));
  const onDrop = handleEvent(({ dataTransfer: { files } }) => {
    onDragLeave();
    return onUploadChange({ target: { files } });
  });
  return (
    <div {...{ onDragOver, onDragLeave, onDrop }} className="tc-upd-dropzone">
      <Paper className={clsx({ "tc-upd-hover": hover, "tc-upd-error": errorDrag })}>
        <CloudUpload />
        <div>Please upload your files</div>
        <FileInput value="" onChange={onUploadChange} />
        <Button variant="contained" color="primary" {...{ onClick }}>
          Select Files
        </Button>
      </Paper>
    </div>
  );
};

Dropzone.propTypes = { onUpload: PropTypes.func.isRequired };

export default Dropzone;
