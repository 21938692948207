import { Paper, Grid, Typography, Button } from "@material-ui/core";
import moment from "moment";
import { HourglassEmpty } from "@material-ui/icons";
import { MTableHeader } from "material-table";
import PropTypes from "prop-types";
import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { setExportTitleFileName } from "../../../../common";
import Table from "../../../common/table/MaterialTable";
import styles from "./CurrentReserve.styles";
import Total from "./CurrentReserveTotal";
import LabelValue from "../../../common/LabelValue";

const CurrentReserve = ({ onToggle, access }) => {
  const store = useSelector(
    ({
      claim: {
        claim: { number },
        reserves
      }
    }) => ({ reserves, number }),
    shallowEqual
  );

  const { reserves: reserve, number: claimNumber } = store || {};
  const { currentDetails, amountReserved, totalPaid, outstandingReserve } = reserve;

  const hasChildWithAmountReserved = parentId => currentDetails.filter(rt => rt.parentId === parentId).some(rt => rt.amountReserved > 0);

  const aggregateField = (parentId, name) => {
    return currentDetails
      .filter(rt => rt.parentId === parentId)
      .reduce((acc, rt) => {
        return acc + rt[name];
      }, 0.0);
  };

  const mapReserveTypes = data => {
    return [
      ...data.reduce(
        (acc, rt) => [
          ...acc,
          { ...rt },
          ...mapReserveTypes(
            currentDetails.filter(srt => srt.parentId === rt.typeId && srt.amountReserved > 0).map(srt => ({ ...srt, level: (rt.level || 0) + 1 }))
          )
        ],
        []
      )
    ];
  };

  const dataStore = mapReserveTypes(
    currentDetails
      .filter(rt => rt.parentId === null && (rt.amountReserved > 0 || hasChildWithAmountReserved(rt.typeId)))
      .map(rt => ({
        ...rt,
        level: 0,
        amountReserved: rt.amountReserved > 0 ? rt.amountReserved : aggregateField(rt.typeId, "amountReserved"),
        outstandingReserve: rt.amountReserved > 0 ? rt.outstandingReserve : aggregateField(rt.typeId, "outstandingReserve"),
        totalPaid: rt.amountReserved > 0 ? rt.totalPaid : aggregateField(rt.typeId, "totalPaid")
      }))
  );

  const classes = styles();
  const makeTitle = () => {
    return (
      <Grid container spacing={1}>
        <Grid item>
          <Typography variant="subtitle2">Reserve Status:</Typography>
        </Grid>
        {reserve.statusId === 1 && (
          <Grid item>
            <HourglassEmpty style={{ fill: "#EADF00" }} />
          </Grid>
        )}
        <Grid item style={{ paddingTop: `8px` }}>
          {reserve.status}
        </Grid>
      </Grid>
    );
  };
  const tableProps = {
    columns: [
      {
        title: "Type",
        field: "type",
        // eslint-disable-next-line
        cellStyle: (colData, rowData) => {
          return { whiteSpace: "nowrap", paddingLeft: rowData.level > 0 ? "2rem" : "" };
        }
      },
      { title: "Amount Reserved", field: "amountReserved", type: "currency" },
      { title: "Total Paid", field: "totalPaid", type: "currency" },
      { title: "Outstanding Reserve", field: "outstandingReserve", type: "currency" }
    ],
    data: dataStore,
    options: {
      rowStyle: rowData => ({
        backgroundColor: rowData.statusId === 1 ? "#FCFBE0" : "#FFF"
      }),
      sorting: false,
      exportFileName: setExportTitleFileName(`${claimNumber}_CurrentReserve`)
    },
    componentsAttr: {
      Header: props => <MTableHeader {...props} classes={{ header: classes.tableHead }} />
    },
    hidePaging: true,
    title: makeTitle()
  };

  return (
    <Paper className={classes.root}>
      <Grid container alignItems="center">
        <Grid item className={classes.title}>
          <Typography variant="subtitle2">Current Reserve</Typography>
        </Grid>
        <Grid item>
          {access?.update === true && (
            <Button variant="contained" color="primary" onClick={onToggle}>
              Modify Reserve
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.sumGrid}>
        <Total {...{ css: classes.reserved, classes, title: "Amount Reserved", value: amountReserved || 0 }} />
        <Total {...{ css: classes.total, classes, title: "Total Paid", value: totalPaid || 0 }} />
        <Total {...{ css: classes.oustanding, classes, title: "Outstanding Reserve", value: outstandingReserve || 0 }} />
      </Grid>
      <Table {...tableProps} />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LabelValue label="Comment" value={reserve.comment} />
        </Grid>
        <Grid item xs={12} className={classes.textAlignRight}>
          <small>{`${reserve.status} on ${moment(reserve.statusOn).format("M/D/YYYY")} by ${reserve.statusByName}`}</small>
        </Grid>
      </Grid>
    </Paper>
  );
};

CurrentReserve.propTypes = {
  onToggle: PropTypes.func.isRequired,
  access: PropTypes.objectOf(PropTypes.any).isRequired
};

export default CurrentReserve;
