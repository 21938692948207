import { ClickAwayListener, Grid, Grow, Paper, Popper, SvgIcon } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { memo, useRef } from "react";
import Claim from "../../../assets/claim.svg";
import { childrenPropType } from "../../../common";
import ActionsFooter, { footerAction } from "../../common/ActionsFooter";
import LinkButton from "../../common/LinkButton";
import ClaimDuplicates from "./ClaimDuplicates";
import { useClaimAddHeaderStyles } from "./index.styles";

const DuplicatesContainer = ({ children, onClickAway, ...props }) => (
  <Popper {...props} role={undefined} transition disablePortal placement="top-start" style={{ zIndex: 1, paddingBottom: 4 }}>
    {({ TransitionProps }) => (
      <Grow {...TransitionProps} style={{ transformOrigin: "center bottom" }}>
        <Paper>
          <ClickAwayListener {...{ onClickAway }}>
            <div id="duplicates-container" style={{ width: "58.333333vw" }}>
              {children}
            </div>
          </ClickAwayListener>
        </Paper>
      </Grow>
    )}
  </Popper>
);
DuplicatesContainer.propTypes = { children: childrenPropType().isRequired, onClickAway: PropTypes.func.isRequired };

const WcAddFooter = memo(({ duplicates, showDocs, showDuplicates, handleCancel, handleSave, setShowDuplicates }) => {
  const headerClasses = useClaimAddHeaderStyles();
  const ref = useRef(null);
  const onClickAway = event => {
    if (ref.current && ref.current.contains(event.target)) return false;
    return setShowDuplicates(false);
  };
  return (
    <>
      <DuplicatesContainer {...{ open: showDuplicates, onClickAway }} anchorEl={ref.current}>
        <ClaimDuplicates {...{ duplicates, setShowDuplicates }} />
      </DuplicatesContainer>
      <ActionsFooter
        shadow
        actions={[
          footerAction({
            id: "duplicates",
            text: (
              <Grid container alignItems="center" spacing={1} wrap="nowrap" style={!showDuplicates ? { color: "#1599bc" } : {}}>
                <Grid item>
                  <SvgIcon>
                    <Claim />
                  </SvgIcon>
                </Grid>
                <Grid item>Possible Duplicates Claims</Grid>
                <Grid item className={clsx({ [headerClasses.number]: !!duplicates.length })}>
                  {duplicates.length || 0}
                </Grid>
              </Grid>
            ),
            component: LinkButton,
            onClick: () => setShowDuplicates(prev => !prev),
            disabled: showDuplicates,
            gridCss: clsx(headerClasses.duplicateLink, { [headerClasses.duplicateLinkViewer]: showDocs }),
            ref,
            "aria-controls": showDuplicates ? "duplicates-container" : undefined,
            "aria-expanded": showDuplicates ? "true" : undefined,
            "aria-haspopup": "menu"
          }),
          footerAction({ id: "cancel", text: "Cancel", outlined: true, primary: true, onClick: handleCancel }),
          footerAction({ id: "save", text: "Save", primary: true, onClick: handleSave })
        ]}
      />
    </>
  );
});

WcAddFooter.propTypes = {
  duplicates: PropTypes.arrayOf(PropTypes.object).isRequired,
  showDocs: PropTypes.bool.isRequired,
  showDuplicates: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  setShowDuplicates: PropTypes.func.isRequired
};

export default WcAddFooter;
