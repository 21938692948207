import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Divider } from "@material-ui/core";
import MultipleLabels from "../../Labels";
import LabelValue from "../../LabelValue";

const BodilyInjuryRead = ({ bodilyInjury }) => {
  const injuryTypes = useSelector(({ common: { injuryType } }) => injuryType, shallowEqual);
  const state = bodilyInjury || {};
  const { hospital } = state;
  const isDeath = injuryTypes.some(x => x.id === state.injuryTypeId && x.isDeath);
  return (
    <React.Fragment key="bodilyinjuryread">
      <form>
        <Grid container spacing={4}>
          {[
            { label: "Injury Type", value: state.injuryType, key: "bodily_injury_type" },
            ...(isDeath ? [{ label: "Claimant Date Of Death", value: state.claimantDateofDeath, isDate: true, key: "bodily_injury_dateof" }] : []),
            { label: "Body Part Group", value: state.parentBodyPart, key: "bodily_injury_parent_body_part" },
            { label: "Body Part", value: state.bodyPart, key: "bodily_injury_body_part" }
          ].map(({ key, ...props }) => (
            <Grid item {...{ key }} xs={3}>
              <LabelValue {...props} />
            </Grid>
          ))}
        </Grid>
        {state.description && <LabelValue label="Bodily Injury Description" value={state.description} />}
        {state.treatmentReceived && (
          <>
            <Divider />
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <MultipleLabels
                  data={[
                    { label: "Physician Name", value: state.physicianName, key: "physician_name" },
                    { label: "Initial Treatment Type", value: state.treatmentType, key: "treatment_received" }
                  ]}
                />
              </Grid>
              <Grid item xs>
                <MultipleLabels
                  data={[
                    { label: "Hospital Name", value: hospital?.name, key: "bodily_hospital_name" },
                    { label: "Phone number", value: hospital?.phone, key: "bodily_hospital_phone_number" },
                    { label: "Hospital Address", value: hospital?.location, isAddress: true, key: "bodily_hospital_address" }
                  ]}
                />
              </Grid>
            </Grid>
          </>
        )}
      </form>
    </React.Fragment>
  );
};

BodilyInjuryRead.propTypes = {
  bodilyInjury: PropTypes.objectOf(PropTypes.any).isRequired
};

export default BodilyInjuryRead;
