import { CLAIM, API } from "../../actions/actionTypes";
import { getSuccess, postSuccess, setSuccess, getClaimEmailsSuccess, getTemplatesSuccess } from "../../actions/claim/communications";
import { takeLatest, takeLatestPost, takeLatestSet } from "../common/sagaGenerator";

const url = `${API.URL}/communication/`;

export default [
  takeLatest({
    type: CLAIM.COMMUNICATION.GET,
    params: ({ claimId }) => ({ claimId }),
    url,
    actionSuccess: getSuccess,
    errorMessage: "Communication Fetch error."
  }),
  takeLatest({
    type: CLAIM.COMMUNICATION.CLAIMEMAILS.GETALL,
    params: ({ claimId }) => ({ claimId }),
    url: `${url}getClaimEmails`,
    actionSuccess: getClaimEmailsSuccess,
    errorMessage: "Communication Claim Emails Fetch error."
  }),
  takeLatestPost({
    type: CLAIM.COMMUNICATION.POST,
    url: `${API.URL}/claim/addCommunication`,
    params: ({ communication }) => communication,
    actionSuccess: postSuccess
  }),
  takeLatestSet({
    type: CLAIM.COMMUNICATION.SET,
    url,
    params: ({ communication }) => communication,
    actionSuccess: setSuccess
  }),
  takeLatest({
    type: CLAIM.COMMUNICATION.TEMPLATES.GET,
    url: ({ claimId }) => `${url}template/${claimId}`,
    actionSuccess: getTemplatesSuccess,
    errorMessage: "Unable to get Communication Templates for Claim"
  })
];
