import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Paper, Typography } from "@material-ui/core";
import { post } from "../../redux/actions/occurrence";
import useStyles from "./index.styles";
import { API } from "../../redux/actions/actionTypes";
import { api } from "../../api/apiUtils";
import OccurrenceDuplicates from "../../components/occurrence/Duplicates";
import Form from "../../components/occurrence/Form";
import { FormContextProvider } from "../../components/common/contexts/FormContext";
import { useDebounce } from "../../common";
import useCustomConfirm from "../../components/common/customHooks/useCustomConfirm";
import { useLoading } from "../../components/common/Loading";

const initialData = {
  id: null,
  address1: "",
  address2: "",
  catastropheNumber: "",
  city: "",
  claimsAssociated: [],
  claimsAssociatedCount: null,
  county: "",
  date: null,
  description: "",
  fullAddress: "",
  insuredId: null,
  insuredName: "",
  name: "",
  number: "",
  stateId: null,
  zipCode: ""
};

const Occurrence = () => {
  const {
    Loading,
    loading,
    actions: { showLoading, hideLoading }
  } = useLoading(false, true);
  const history = useHistory();
  const contextRef = React.useRef();
  const dispatch = useDispatch();
  const [occurrences, setOccurrences] = useState([]);
  const searchOccurrences = useDebounce(() => {
    const occ = contextRef?.current?.getState();
    const values = {
      date: occ.date,
      insuredId: occ.insuredId,
      stateId: occ.stateId,
      city: occ.city,
      zipCode: occ.zipCode,
      address1: occ.address1,
      address2: occ.address2
    };
    api({ method: "GET", url: `${API.URL}/occurrence/search`, data: values }).then(data => setOccurrences(data));
  }, 500);
  const onGoList = () => {
    hideLoading();
    return history.replace("/claims/occurrences");
  };
  const handleSave = () => {
    showLoading();
    dispatch(post(contextRef?.current?.getState(), onGoList, hideLoading));
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleSave, "Cancel", "Yes, Create");
  const handleConfirmSave = () => {
    const { name } = contextRef?.current?.getState();
    handleCustomConfirm({
      titleText: `Add Occurrence`,
      messageText: `The following Occurrence will be created: ${name}. Are you sure you want to proceed?`
    });
  };
  const classes = useStyles();
  const searchBy = ["date", "insuredId", "stateId", "city", "county", "zipCode", "address1", "address2"];
  return (
    <div className="tc-page-container">
      <Grid container wrap="nowrap" className="tc-page-content tc-no-padding">
        <Grid item xs={7} className="tc-page-container">
          <div className={classes.containerh}>
            <div className={classes.titleh}>Add Occurrence</div>
          </div>
          <div className="tc-page-content">
            {Loading}
            <Paper className={classes.paper}>
              <FormContextProvider ref={contextRef} {...{ initialData }}>
                <Form actionCancel={onGoList} actionSave={handleConfirmSave} isAdd search={searchOccurrences} {...{ searchBy, loading }} />
              </FormContextProvider>
            </Paper>
          </div>
          <div id="occurrenceFooter" />
        </Grid>
        <Grid item xs={5} className="tc-page-container tc-bg-white tc-shadow-l tc-z-index-1">
          <div className="tc-page-content">
            <Typography className="tc-title" gutterBottom>
              Possible Duplicates
            </Typography>
            <div>
              <OccurrenceDuplicates {...{ occurrences }} />
            </div>
          </div>
        </Grid>
      </Grid>
      <Confirm />
    </div>
  );
};

export default Occurrence;
