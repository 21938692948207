import React from "react";
import PropTypes from "prop-types";
import { IconButton, Badge, withStyles } from "@material-ui/core";
import clsx from "clsx";
import { InsertComment } from "@material-ui/icons";
import styles from "./ResponseButton.styles";

const StyledBadge = withStyles({ badge: { top: -5, right: -5 } })(Badge);

const ResponseButton = ({ open, responses, ...props }) => {
  const classes = styles();
  return (
    <IconButton {...props} className={clsx(classes.root, { [classes.open]: open })}>
      <StyledBadge badgeContent={responses} color="primary">
        <InsertComment />
      </StyledBadge>
    </IconButton>
  );
};

ResponseButton.propTypes = {
  open: PropTypes.bool.isRequired,
  responses: PropTypes.number.isRequired
};

export default ResponseButton;
