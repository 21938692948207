import React, { useContext, Fragment } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Grid, Collapse } from "@material-ui/core";
import PropTypes from "prop-types";
import { CheckboxLabel, SelectInput, InputText } from "../inputs";
import { PropertyContext } from "./PropertyContext";

const Property = ({ validationMessages }) => {
  const { state, dispatch } = useContext(PropertyContext);
  const store = useSelector(({ common: { states, propertyTypes } }) => ({ states, propertyTypes }), shallowEqual);
  const { states, propertyTypes } = store;
  const { isClaimMode, isPropertyLiability, property, insProperties } = state || {};
  const { id, hasMortgage } = property || {};

  const insProps = isPropertyLiability
    ? insProperties.filter(x => x.hasCoverage || x.id === id).map(x => ({ id: x.id, text: (x.location || {}).address1 || "-" }))
    : [];

  const inputProps = (pre, name, label, src, type, chk) => ({
    id: `dmg-prop-${pre}-${name}`,
    name,
    label,
    disabled: !!isPropertyLiability && name !== "isClaimantMortgageCompany" && (name !== "id" || insProps.length < 2),
    [chk ? "checked" : "value"]: (src || {})[name],
    onChange: ({ target: { value } }) => dispatch({ type, payload: { name, value } }),
    error: (validationMessages || {})[name]
  });

  const propProps = (name, label, chk) => inputProps("prop", name, label, state.property, "SET_PROPERTY", chk);
  const locProps = (name, label) => inputProps("loc", name, label, state.property?.location, "SET_LOCATION");
  const dmgProps = (name, label, chk) => inputProps("dmg", name, label, state.damaged, "SET_DAMAGED", chk);

  return (
    <Fragment key="property-form-component">
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <SelectInput {...propProps("propertyTypeId", "Property Type")} options={propertyTypes} />
          {(isPropertyLiability && <SelectInput {...propProps("id", "Address 1")} options={insProps} />) || (
            <InputText {...locProps("address1", "Address 1")} />
          )}
        </Grid>
        <Grid item xs={4}>
          <InputText {...locProps("city", "City")} />
          <InputText {...locProps("address2", "Address 2")} />
        </Grid>
        <Grid item xs={4}>
          <SelectInput {...locProps("stateId", "State")} options={states} />
          <InputText {...locProps("zipCode", "Zip")} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          <CheckboxLabel {...propProps("hasMortgage", "Mortgage Property", true)} />
        </Grid>
        <Grid item xs={4}>
          <Collapse in={!!hasMortgage} timeout="auto">
            {isClaimMode && <CheckboxLabel {...dmgProps("isClaimantMortgageCompany", "Mortgage Company same as Claimant", true)} />}
          </Collapse>
        </Grid>
      </Grid>
    </Fragment>
  );
};

Property.propTypes = { validationMessages: PropTypes.shape({}).isRequired };

export default Property;
