import { takeLatest, all } from "redux-saga/effects";
import { CLIENT_DOCUMENTS } from "../../actions/actionTypes";
import * as docs from "../common/documents";

export default function* root() {
  // eslint-disable-next-line redux-saga/no-unhandled-errors
  yield all([
    takeLatest(CLIENT_DOCUMENTS.GET, docs.getByParentId(CLIENT_DOCUMENTS.GET_SUCCESS)),
    takeLatest(CLIENT_DOCUMENTS.DEL_FILE, docs.deleteFile(CLIENT_DOCUMENTS.DEL_FILE_SUCCESS)),
    takeLatest(CLIENT_DOCUMENTS.SET_FILE, docs.updateFile(CLIENT_DOCUMENTS.SET_FILE_SUCCESS)),
    takeLatest(CLIENT_DOCUMENTS.POST_FOLDER, docs.addFolder(CLIENT_DOCUMENTS.POST_FOLDER_SUCCESS)),
    takeLatest(CLIENT_DOCUMENTS.SET_FOLDER, docs.updateFolder(CLIENT_DOCUMENTS.SET_FOLDER_SUCCESS)),
    takeLatest(CLIENT_DOCUMENTS.DEL_FOLDER, docs.deleteFolder(CLIENT_DOCUMENTS.DEL_FOLDER_SUCCESS)),
    takeLatest(CLIENT_DOCUMENTS.POST_FILES, docs.uploadFiles(CLIENT_DOCUMENTS.POST_FILES_SUCCESS)),
    takeLatest(CLIENT_DOCUMENTS.DOWNLOAD_FILES, docs.downloadFiles(CLIENT_DOCUMENTS.DOWNLOAD_FILES_SUCCESS)),
    takeLatest(CLIENT_DOCUMENTS.OPEN_FILE, docs.openFile(CLIENT_DOCUMENTS.OPEN_FILE_SUCCESS))
  ]);
}
