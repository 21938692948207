import { CLAIM, API } from "../../../actions/actionTypes";
import { getSuccess, delSuccess, setSuccess, postSuccess } from "../../../actions/claim/legal/hearing";
import { takeLatest, takeLatestSet, takeLatestPost, takeLatestDel } from "../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLAIM.LEGAL.HEARING.GET,
    url: `${API.URL}/hearing/getAll`,
    params: ({ claimId }) => ({ claimId }),
    actionSuccess: getSuccess,
    errorMessage: "Error loading hearing"
  }),
  takeLatestSet({
    type: CLAIM.LEGAL.HEARING.SET,
    url: `${API.URL}/hearing`,
    params: ({ hearing }) => hearing,
    actionSuccess: setSuccess,
    successMessage: "Hearing information successfully updated",
    errorMessage: "Unable to save the information"
  }),
  takeLatestPost({
    type: CLAIM.LEGAL.HEARING.POST,
    url: `${API.URL}/hearing`,
    params: ({ hearing }) => hearing,
    actionSuccess: postSuccess,
    successMessage: "Hearing information successfully saved",
    errorMessage: "Unable to save the information"
  }),
  takeLatestDel({
    type: CLAIM.LEGAL.HEARING.DEL,
    url: `${API.URL}/hearing`,
    params: ({ hearing: { id, claimId } }) => ({ id, claimId }),
    actionSuccess: delSuccess,
    successMessage: "Hearing information successfully deleted",
    errorMessage: "Unable to delete the information"
  })
];
