import { CLAIM } from "../../actionTypes";

export const get = (claimId, onSuccess, onError) => ({ type: CLAIM.LEGAL.MEDITATION_ARBITRATION.GET, claimId, onSuccess, onError });
export const getSuccess = mediationArbitration => ({ type: CLAIM.LEGAL.MEDITATION_ARBITRATION.GET_SUCCESS, mediationArbitration });

export const set = (mediationArbitration, onSuccess, onError) => ({
  type: CLAIM.LEGAL.MEDITATION_ARBITRATION.SET,
  mediationArbitration,
  onSuccess,
  onError
});

export const setSuccess = mediationArbitration => ({ type: CLAIM.LEGAL.MEDITATION_ARBITRATION.SET_SUCCESS, mediationArbitration });

export const post = (mediationArbitration, onSuccess, onError) => ({
  type: CLAIM.LEGAL.MEDITATION_ARBITRATION.POST,
  mediationArbitration,
  onSuccess,
  onError
});

export const postSuccess = mediationArbitration => ({ type: CLAIM.LEGAL.MEDITATION_ARBITRATION.POST_SUCCESS, mediationArbitration });
