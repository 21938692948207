import { Grid } from "@material-ui/core";
import clsx from "clsx";
import React, { useContext, Fragment } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Overview from "../../components/claims/claim/overview";
import Info from "../../components/claims/claim/info";
import Coverage from "../../components/claims/claim/coverage";
import Documents from "../../components/claims/claim/documents";
import Notes from "../../components/claims/claim/Notes";
import Legal from "../../components/claims/claim/legal";
import Reserves from "../../components/claims/claim/reserves";
import Financial from "../../components/claims/claim/financial";
import Diary from "./Diary";
import Communications from "../../components/claims/claim/communications";
import Activity from "../../components/claims/claim/Activity";
import PageNotAllowed from "../../components/PageNotAllowed";
import ActionsAllowedContext from "../../components/common/contexts/ActionsAllowed";
import { useLoadingContext } from "../../components/common/Loading";
import BreadCrumbsNavigation from "../../components/common/breadCrumbs/BreadCrumbsNavigation";

export const routes = [
  ["overview", "Overview", Overview],
  ["info", "Claim Info", Info],
  ["coverage", "Coverage", Coverage],
  ["documents", "Documents", Documents],
  ["notes", "Notes", Notes],
  ["legal", "Legal", Legal],
  ["reserves", "Reserves", Reserves],
  ["financial", "Financial", Financial],
  ["diaries", "Diaries", Diary],
  ["communications", "Communications", Communications],
  ["activity", "Activity", Activity]
];

const Routes = props => {
  const { path } = useRouteMatch();
  const routesAllowed = useContext(ActionsAllowedContext);
  const parsedRoutes = routes.map(([key, , Component]) => ({
    key,
    path: `${path}/${key}`,
    Component,
    isAllowed: routesAllowed.some(({ route, read }) => route === key && read)
  }));
  const { breadcrumb, breadcrumbs, Loading, padding } = useLoadingContext();
  return (
    <Fragment key="route-fragment">
      {Loading}
      {routesAllowed.length > 0 && (
        <div className={clsx("tc-page-content", { "tc-no-padding": !padding })}>
          <Grid container wrap="nowrap">
            {breadcrumb && (
              <Grid item xs>
                <BreadCrumbsNavigation min gutterBottom {...breadcrumbs} />
              </Grid>
            )}
            <Grid item id="claimTopContainer" />
          </Grid>
          <Switch>
            {parsedRoutes.map(({ Component, isAllowed, ...other }) => (
              <Route {...other} render={() => (isAllowed && <Component {...props} />) || <PageNotAllowed />} />
            ))}
          </Switch>
        </div>
      )}
    </Fragment>
  );
};
export default Routes;
