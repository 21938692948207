import { CLAIM } from "../actionTypes";

export const get = (claimId, onSuccess, onError) => ({ type: CLAIM.COVERAGE.GET, claimId, onSuccess, onError });
export const getSuccess = policy => ({ type: CLAIM.COVERAGE.GET_SUCCESS, policy });

export const getPoliciesByLineOfBusiness = (claimId, onSuccess, onError) => ({
  type: CLAIM.COVERAGE.POLICIES_BY_LOB_INSURED.GETALL,
  claimId,
  onSuccess,
  onError
});
export const getPoliciesByLineOfBusinessSuccess = data => ({ type: CLAIM.COVERAGE.POLICIES_BY_LOB_INSURED.GETALL_SUCCESS, data });
export const setClaimPolicyAsActive = (data, onSuccess) => ({
  type: CLAIM.COVERAGE.POLICIES_BY_LOB_INSURED.SET,
  data,
  onSuccess
});
export const setClaimPolicyAsActiveSuccess = data => ({ type: CLAIM.COVERAGE.POLICIES_BY_LOB_INSURED.SET_SUCCESS, data });
