import moment from "moment";
import * as yup from "yup";
import { isNumber, rootParent } from "../../../../common/yup";

const isDate = date => moment("1900-01-01").isBefore(moment(date));

const string = () => yup.string().nullable();
const number = () => yup.number().nullable();

export const mediationArbitrationSchema = yup.object().shape({
  date: string().test("", "Date Required", o => o === undefined || isDate(o)),
  arbitration: yup.boolean(),
  arbitrationFirstName: string().test("", "Arbitration First Name Required", (o, c) => !c?.parent?.arbitration || !!o),
  arbitrationLastName: string().test("", "Arbitration Last Name Required", (o, c) => !c?.parent?.arbitration || !!o)
});

export const settledSchema = yup.object().shape({
  isCaseSettled: yup.boolean(),
  settlementAmount: number().test("", "Settlement Amount Required", (o, c) => !c?.isCaseSettled && isNumber(o)),
  reserveTypeId: number().test("", "Reserve Type Required", (o, c) => !c?.isCaseSettled && isNumber(o))
});

const isDateAndGreaterThanMin = date => date !== undefined && moment("1900-01-01").isBefore(moment(date));

const reusableTestVerification = (val, ctx) => {
  const { trialNoticeReceived, appealed } = rootParent(ctx);
  switch (ctx.path) {
    case "trialDate":
      return !trialNoticeReceived || isDateAndGreaterThanMin(val);
    case "appealDate":
      return !appealed || isDateAndGreaterThanMin(val);
    case "appealStatusId":
      return !appealed || isNumber(val);
    case "veredictStatusId":
      return !trialNoticeReceived || isNumber(val);
    default:
      return false;
  }
};

export const trialSchema = yup.object().shape({
  trialNoticeReceived: yup
    .bool()
    .nullable()
    .required("Trial Notice Received is required")
    .oneOf([true], null)
    .typeError("Trial Notice Received is required"),
  trialDate: string().test("", "Trial Date Required", reusableTestVerification),
  veredictStatusId: number().test("", "Status Required", reusableTestVerification),
  appealDate: string().test("", "Appealed Date Filed Required", reusableTestVerification),
  appealStatusId: number().test("", "Status Required", reusableTestVerification)
});
