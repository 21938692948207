import { API, ADMIN } from "../../../actions/actionTypes";
import {
  getAllJurisdictionStatesSuccess,
  postJurisdictionStateSuccess,
  setJurisdictionStateSuccess,
  delJurisdictionStateSuccess
} from "../../../actions/admin/claimsSetting";
import { takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "../../common/sagaGenerator";

const url = `${API.URL}/claimsetting`;

export default [
  takeLatest({
    type: ADMIN.CLAIMSETTING.JURISDICTIONSTATE.GETALL,
    url: `${url}/getjurisdictionstates`,
    actionSuccess: getAllJurisdictionStatesSuccess,
    errorMessage: "Error getting jurisdiction states."
  }),
  takeLatestPost({
    type: ADMIN.CLAIMSETTING.JURISDICTIONSTATE.POST,
    url: `${url}/jurisdictionstates`,
    actionSuccess: postJurisdictionStateSuccess,
    successMessage: "Widget successfully added",
    errorMessage: "Unable to add the widget",
    params: ({ data }) => data
  }),
  takeLatestSet({
    type: ADMIN.CLAIMSETTING.JURISDICTIONSTATE.SET,
    url: `${url}/jurisdictionstates`,
    actionSuccess: setJurisdictionStateSuccess,
    successMessage: "Widget successfully updated",
    errorMessage: "Unable to update the widget",
    params: ({ data }) => data
  }),
  takeLatestDel({
    type: ADMIN.CLAIMSETTING.JURISDICTIONSTATE.DEL,
    url: `${url}/jurisdictionstates`,
    actionSuccess: delJurisdictionStateSuccess,
    successMessage: "Widget successfully deleted",
    errorMessage: "Unable to delete the widget",
    params: ({ data }) => data
  })
];
