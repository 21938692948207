export default {
  user: {
    users: [],
    securityProfiles: [],
    securityProfile: { rules: [] }
  },
  claimSettings: {
    jurisdictionStates: [],
    lineOfBusiness: [],
    timelineDates: [],
    widgets: [],
    noteTypes: [],
    reserves: [],
    reserveTypes: [],
    documents: { active: false, settings: [], synced: false }
  },
  templates: { communications: [], diaries: [] }
};
