import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { InputAdornment, FormControl, Input, InputLabel, CircularProgress, MenuList, MenuItem, FormHelperText, Grid } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { HocInput } from "../inputs/HocInput";
import { useDebounce } from "../../../common";

const InputSuggestionsDebounced = ({
  classes,
  className,
  inputClassName,
  name,
  value,
  label,
  onChange,
  onSelect,
  url,
  queryParams,
  axiosConfig,
  disabled,
  formatOption,
  displayValue,
  container,
  error,
  onFocus,
  onLostFocus,
  endAdornment
}) => {
  const attName = name.split("-")[0];
  const [{ loading, anchorEl, attempted }, setState] = useState({ loading: false, attempted: false });
  const [results, setResults] = useState([]);
  const findDebounced = useDebounce(
    (field, query) => axios.get(url, { ...(axiosConfig || {}), params: { field, query, ...(queryParams || {}) } }),
    500
  );
  const effect = () => {
    setState(ps => ({ ...ps, loading: true }));
    findDebounced(name, value)
      .then(({ data }) => {
        setResults(data.filter(x => Boolean(x[attName])));
      })
      .finally(() => setState(ps => ({ ...ps, loading: false })));
  };
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (!open) return;
    if ((value || "").length >= 3) effect();
    else {
      setState(ps => ({ ...ps, loading: false }));
      setResults([]);
    }
  }, [name, value]);
  useEffect(() => {
    if (anchorEl && !attempted) {
      setState(ps => ({ ...ps, attempted: true }));
      if ((value || "").length >= 3) effect();
    }
    if (anchorEl && typeof onFocus === "function") onFocus();
    if (!anchorEl && attempted && typeof onLostFocus === "function") onLostFocus();
  }, [anchorEl]);
  const any = (value || "").length >= 3 && results.length > 0;
  const msgNoResults = loading ? "Loading..." : ((value || "").length >= 3 && "No results") || "Type at least 3 characters";
  const adornment = loading ? <CircularProgress color="inherit" size={20} /> : <Search />;
  const inputProps = {
    id: `search-${name}`,
    name,
    onChange,
    error: Boolean(error),
    onFocus: ({ currentTarget }) => {
      setState(ps => ({ ...ps, anchorEl: currentTarget }));
    },
    onBlur: () =>
      setTimeout(() => {
        if (anchorEl !== document.activeElement) setState(ps => ({ ...ps, anchorEl: null }));
      }, 300),
    endAdornment: (
      <InputAdornment position="end">
        {(endAdornment && (
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item>{endAdornment}</Grid>
            <Grid item style={{ width: 24, textAlign: "center" }}>
              {adornment}
            </Grid>
          </Grid>
        )) ||
          adornment}
      </InputAdornment>
    ),
    disabled,
    value: (!open && typeof displayValue === "string" && displayValue) || value || "",
    classes: { input: inputClassName }
  };
  const Container = container || MenuList;
  const fnOption = (option, index) => {
    if (typeof formatOption === "function") return formatOption({ option, setResults, anchorEl });
    return (
      <MenuItem key={`${name}-${option[attName] || index}`} onClick={() => onSelect(option, name)}>
        {option[attName]}
      </MenuItem>
    );
  };
  return (
    <FormControl fullWidth {...{ classes, className }}>
      {label && (
        <InputLabel htmlFor={inputProps.id} error={inputProps.error}>
          {label}
        </InputLabel>
      )}
      <Input autoComplete="off" {...inputProps} />
      {error && <FormHelperText error>{error}</FormHelperText>}
      {open && any && <Container>{results.map(fnOption)}</Container>}
      {open && !any && (
        <Container>
          <MenuItem>{msgNoResults}</MenuItem>
        </Container>
      )}
    </FormControl>
  );
};

InputSuggestionsDebounced.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  queryParams: PropTypes.objectOf(PropTypes.any),
  axiosConfig: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  formatOption: PropTypes.func,
  displayValue: PropTypes.string,
  container: PropTypes.elementType,
  error: PropTypes.string,
  onFocus: PropTypes.func,
  onLostFocus: PropTypes.func,
  endAdornment: PropTypes.node,
  inputClassName: PropTypes.string
};

InputSuggestionsDebounced.defaultProps = {
  label: null,
  value: "",
  queryParams: {},
  axiosConfig: {},
  disabled: false,
  formatOption: undefined,
  displayValue: undefined,
  container: undefined,
  error: undefined,
  onFocus: undefined,
  onLostFocus: undefined,
  endAdornment: undefined,
  inputClassName: undefined
};

export default HocInput(InputSuggestionsDebounced);
