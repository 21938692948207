import { Grid, Paper, Typography } from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment, memo } from "react";
import { useSelector } from "react-redux";
import { DateTimeInput, SelectInput, TextInput } from "../../common/inputs";
import { BaseRadioButtonGroup } from "../../common/inputs/RadioButtonGroup";
import { useEmployeeStyles } from "./index.styles";

const EmploymentForm = memo(({ employment, error, setState }) => {
  const classes = useEmployeeStyles();
  const selects = useSelector(store => store.common);
  const { states, employmentStatus, payrollClassCodeTypes, ratePerTypes } = selects;

  const onChange = ({ target: { name: path, value } }) => {
    const [, name] = path.split(".");
    const val = name === "fullPayInjuryDay" || name === "salaryContinued" ? value === "true" : value;
    return setState(ps => ({ ...ps, employment: { ...ps.employment, [name]: val }, validation: new Date().getTime() }));
  };
  const inputProps = path => {
    const [, name] = path.split(".");
    return {
      name: path,
      value: employment[name] || undefined,
      onChange,
      error: error[`employment.${name}`]
    };
  };
  const options = [
    { id: true, text: "YES" },
    { id: false, text: "NO" }
  ];
  const daysWorkedWeekOptions = [1, 2, 3, 4, 5, 6, 7].map(x => ({ id: x, text: x }));
  const yearsTenure =
    moment().diff(moment(employment.hireDate), "years") > 0 ? `${moment().diff(moment(employment.hireDate), "years")} year(s)` : undefined;
  const monthsTenure =
    moment().diff(moment(employment.hireDate), "months") > 0 ? `${moment().diff(moment(employment.hireDate), "months")} month(s)` : undefined;
  return (
    <Fragment key="employment-form-box">
      <form>
        <Paper className={classes.root}>
          <Grid container className={classes.head} spacing={2}>
            <Grid item xs>
              <Typography className="tc-title">Employment</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <SelectInput {...inputProps("employment.jurisdictionStateId")} options={states} label="Jurisdiction State *" />
              <DateTimeInput {...inputProps("employment.hireDate")} label="Hire Date" keyboard />
              <TextInput value={yearsTenure || monthsTenure || ""} label="Tenure" disabled />
            </Grid>
            <Grid item xs={3}>
              <SelectInput {...inputProps("employment.payrollClassCodeTypeId")} options={payrollClassCodeTypes} label="Payroll Class Code *" />
              <SelectInput {...inputProps("employment.ratePerTypeId")} label="Rate Per" options={ratePerTypes} />
              {employment.ratePerTypeId === 4 && <TextInput label="Rate Per Description" {...inputProps("employment.ratePerDescription")} />}
            </Grid>
            <Grid item xs={3}>
              <SelectInput {...inputProps("employment.daysWorkedWeek")} options={daysWorkedWeekOptions} label="Days Worked/Week" allowClear />
              <SelectInput {...inputProps("employment.employmentStatusId")} options={employmentStatus} label="Employment Status *" />
              <TextInput {...inputProps("employment.tpaOfficeNumber")} label="TPA Office #" />
            </Grid>
            <Grid item xs={3}>
              <BaseRadioButtonGroup
                name="employment.fullPayInjuryDay"
                options={options}
                onChange={onChange}
                value={employment.fullPayInjuryDay}
                label="Full Pay For Day of Injury?"
                row
              />
              <BaseRadioButtonGroup
                name="employment.salaryContinued"
                options={options}
                onChange={onChange}
                value={employment.salaryContinued}
                label="Did Salary Continued?"
                row
              />
            </Grid>
          </Grid>
          <TextInput {...inputProps("employment.occupationDescription")} label="Occupation Description" />
        </Paper>
      </form>
    </Fragment>
  );
});

EmploymentForm.propTypes = {
  employment: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.string).isRequired,
  setState: PropTypes.func.isRequired
};

export default EmploymentForm;
