import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Paper } from "@material-ui/core";
import moment from "moment";
import MaterialTable from "../../common/table/MaterialTable";
import { get } from "../../../redux/actions/dashboard/claimWithoutActivity";
import { useLoading } from "../../common/Loading";
import ClaimStatus from "../../claims/ClaimStatus";
import ClaimProcessingStatus from "../../claims/ClaimProcessingStatus";

const ClaimWithoutActivityDetail = () => {
  const dispatch = useDispatch();
  const { actions, Loading } = useLoading(true);
  const { showLoading, hideLoading } = actions;

  const store = useSelector(({ dashboard: { claimWithoutActivity } }) => ({ claimWithoutActivity }), shallowEqual);

  const { claimWithoutActivity } = store || {};

  useEffect(() => {
    showLoading();
    dispatch(get(hideLoading, hideLoading));
  }, []);

  const tableProps = {
    columns: [
      { title: "Adjuster", field: "adjuster" },
      {
        title: "Claim Number",
        field: "number",
        render: rowData => (
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: 5 }}>
              <Link to={`/claim/${rowData.id}/overview`} blue>
                {rowData.number}
              </Link>
            </span>
            <span style={{ marginRight: 5 }}>
              <ClaimStatus status={rowData.status} />
            </span>
            <ClaimProcessingStatus processingStatus={rowData.processingStatus} />
          </div>
        )
      },
      { title: "Claimant", field: "claimant" },
      { title: "City", field: "city" },
      {
        title: "Date of Loss",
        field: "dateofLoss",
        type: "date",
        render: rowData => (rowData.dateofLoss ? moment(rowData.dateofLoss).format("M/D/YYYY") : "")
      },
      { title: "Line of Business", field: "claimLineOfBusiness" },
      { title: "Loss Type Group", field: "claimLossTypeGroup" },
      {
        title: "Last Activity Date",
        field: "lastActivityOn",
        type: "date",
        render: rowData => (rowData.lastActivityOn ? moment(rowData.lastActivityOn).format("M/D/YYYY") : "")
      }
    ],
    data: claimWithoutActivity.sort((a, b) => a.adjuster.localeCompare(b.adjuster)),
    hidePaging: claimWithoutActivity.length <= 50,
    options: {
      filtering: true,
      pageSize: 50,
      exportFileName: "claims_with_no_activity"
    },
    title: "Claims with no activity in the last 30 days by Adjuster"
  };
  return (
    <div className="tc-page-container">
      <div className="tc-page-content">
        <Paper className="tc-relative tc-o-hidden tc-box-content">
          {Loading}
          <MaterialTable {...tableProps} />
        </Paper>
      </div>
    </div>
  );
};

ClaimWithoutActivityDetail.propTypes = {};

ClaimWithoutActivityDetail.defaultProps = {};

export default ClaimWithoutActivityDetail;
