import clsx from "clsx";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { InputLabel, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Grid, Button, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import * as yup from "yup";
import useYupValidationSchema from "../../../common/customHooks/useYupValidationSchema";
import { BaseInputText as InputText } from "../../../common/inputs/InputText";
import { useRouteParam } from "../../../../common";
import useAxios from "../../../common/customHooks/useAxios";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import Switch from "../../../common/Switch";
import { setClientStructure, addClientAndMigrateLevel } from "../../../../redux/actions/client/setting";
import { getBreadCrumbRoutes, getLevels } from "../../../../redux/actions/client/getLevels";
import CustomModal from "../../../common/CustomModal";
import { SelectInput, FeinInput } from "../../../common/inputs";
import useCustomConfirm from "../../../common/customHooks/useCustomConfirm";

const useStyles = makeStyles(theme => ({
  inactive: { color: "#898F91" },
  buttonWrapper: {
    "& > *": {
      margin: theme.spacing(1)
    }
  }
}));

const mapItem = item =>
  item.levelId === 7
    ? {
        ...item,
        tabs: [
          { id: 1, levelId: 7, name: "Name" },
          { id: 2, levelId: 7, name: "Description" },
          { id: 3, levelId: 7, name: "Status" }
        ]
      }
    : item;

const ClientStructure = ({ isAdd, clientType, onChange, actionsAllowed, open, onToggle }) => {
  const [state, setState] = useState([]);
  const [clientAllLevels, setClientAllLevels] = useState([]);
  const { sizeTypes } = useSelector(store => store.common);
  const store = useSelector(({ client: { clientLevels, breadCrumbRoutes } }) => ({ clientLevels, breadCrumbRoutes }), shallowEqual);
  const { clientLevels, breadCrumbRoutes } = store;
  const {
    props: { boxProps, editMode, loading, Loading },
    actions: { showLoading, hideLoading, onToggleEdit }
  } = useCollapsibleBox(!isAdd && (actionsAllowed.create || actionsAllowed.update));
  const [clientLevelSetting, setClientLevelSetting] = useState([]);
  const { sendRequest } = useAxios();
  const clientId = useRouteParam("clientId");
  const dispatch = useDispatch();

  const classes = useStyles();
  const fetchClientStructure = refreshProfile => {
    showLoading();
    const url = isAdd ? "getDefaultLevelTab" : `getLevelTab?clientId=${clientId}`;
    sendRequest({
      request: { url: `/client/${url}` },
      onSucces: cs => {
        setState(
          cs.map(mapItem).map((level, index) => ({
            ...level,
            levelName: isAdd && index === 0 ? `${level.levelName} (Client Type)` : level.levelName,
            disabledInput: index === 0 || !level.isActive
          }))
        );
        setClientLevelSetting(
          cs.reduce((a, l) => [...a, { levelId: l.levelId, isActive: l.isActive, disabledInput: !l.isActive, relatedId: l.relatedId }], [])
        );
        setClientAllLevels(cs.map(mapItem));

        if (refreshProfile) {
          dispatch(getLevels(clientId));
          dispatch(getBreadCrumbRoutes(clientId));
        }
        if (!isAdd && editMode) onToggleEdit();
        hideLoading();
      },
      onError: () => {
        if (!isAdd && editMode) onToggleEdit();
        hideLoading();
      }
    });
  };
  const [openNewEntityLevelActivated, setOpenNewEntityLevelActivated] = useState(false);
  const [newEntityLevelActivatedLevelName, setNewEntityLevelActivatedLevelName] = useState("");
  const [newEntityLevelActivatedLevelId, setNewEntityLevelActivatedLevelId] = useState(0);
  const [NextEntityLevelActivatedLevelId, setNextEntityLevelActivatedLevelId] = useState(0);
  const [NextEntityLevelActivatedLevelName, setNextEntityLevelActivatedLevelName] = useState("");
  const handleOpenModalNewEntityLevelActivated = () => {
    setOpenNewEntityLevelActivated(prev => !prev);
  };
  useEffect(() => {
    fetchClientStructure();
  }, []);

  useEffect(() => {
    const cType = clientType === null ? "" : clientType;
    if (cType.toLowerCase() !== "peo") {
      setState(
        state
          .filter(l => l.levelId !== 1)
          .map((level, index) => ({
            ...level,
            levelName: isAdd && index === 0 ? `${cType} (Client Type)` : level.levelName,
            disabledInput: index === 0 || !level.isActive
          }))
      );
      setClientLevelSetting(
        state
          .filter(l => l.levelId !== 1)
          .reduce((acc, level) => [...acc, { levelId: level.levelId, isActive: level.isActive, disabledInput: !level.isActive }], [])
      );
    } else {
      setState(
        clientAllLevels.map((level, index) => ({
          ...level,
          levelName: isAdd && index === 0 ? `${level.levelName} (Client Type)` : level.levelName,
          disabledInput: index === 0 || !level.isActive
        }))
      );
      setClientLevelSetting(
        clientAllLevels.reduce((acc, level) => [...acc, { levelId: level.levelId, isActive: level.isActive, disabledInput: !level.isActive }], [])
      );
    }
  }, [clientType]);

  useEffect(() => {
    if (isAdd)
      onChange(
        clientLevelSetting.map(level => ({
          levelId: level.levelId,
          isActive: level.isActive,
          label: state.find(item => item.levelId === level.levelId).levelName,
          relatedId: level.relatedId
        }))
      );
  }, [clientLevelSetting, state]);

  const [availableParents, setAvailableParents] = useState([]);
  const handleChanges = ({ target: { checked } }, levelId) => {
    const fLevel = clientLevelSetting.find(l => l.levelId === levelId);
    if (fLevel?.relatedId) {
      const fLevelSetting = clientLevelSetting.find(l => l.levelId === fLevel.relatedId);
      if (fLevelSetting && !fLevelSetting.isActive) return;
    }
    setClientLevelSetting(prev =>
      prev.map(level => (level.levelId === levelId || level.relatedId === levelId ? { ...level, isActive: checked } : level))
    );
    setState(prev => prev.map(level => (level.levelId === levelId ? { ...level, disabledInput: !checked } : level)));
    if (checked) {
      // first check if there's child entities added
      const theresChildEntities = breadCrumbRoutes.filter(breadcrumb => breadcrumb.levelId >= levelId).length > 0;
      if (theresChildEntities) {
        setOpenNewEntityLevelActivated(checked);
        setNewEntityLevelActivatedLevelName(clientLevels.find(item => item.levelId === fLevel.levelId).levelName);
        setNewEntityLevelActivatedLevelId(fLevel.levelId);
        let previousLevelID = 0;
        let previousLevelIDFound = false;
        for (let i = 0; i < clientLevels.length; i += 1) {
          if (clientLevels[i].levelId === fLevel.levelId) {
            previousLevelIDFound = true;
          }
          if (clientLevels[i].isActive && !previousLevelIDFound) {
            previousLevelID = clientLevels[i].levelId;
          }
        }
        setAvailableParents(
          breadCrumbRoutes
            .filter(breadcrumb => breadcrumb.levelId === previousLevelID)
            .map(filteredBreadCrumb => ({ text: filteredBreadCrumb.name, id: filteredBreadCrumb.id }))
        );
        setNextEntityLevelActivatedLevelId(clientLevels.find(level => level.isActive && level.levelId > fLevel.levelId).levelId);
        setNextEntityLevelActivatedLevelName(clientLevels.find(level => level.isActive && level.levelId > fLevel.levelId).levelName);
      }
    }
  };
  const handleSubmit = () => {
    dispatch(
      setClientStructure(
        {
          clientId,
          clientLevels: clientLevelSetting.map(level => ({ ...level, label: state.find(item => item.levelId === level.levelId).levelName }))
        },
        () => fetchClientStructure(true)
      )
    );
  };
  // const history = useHistory();
  const moveChildEntitiesToNewParent = () => {
    toast.success(`${newEntityLevelActivatedLevelName} successfully added`);
    // return history.push(parentUrl);
  };
  const onError = () => {
    toast.error(`Unable to add the ${newEntityLevelActivatedLevelName}`);
    return hideLoading();
  };
  const handleSave = objData => {
    showLoading();
    const clientState = {
      agentInformation: { agentLocation: { stateId: null }, saveAgent: true },
      clientStatus: { ...objData.clientStatus, isActive: objData.clientStatus.isActive === 1 },
      parentId: objData.basicInformation.parentId,
      basicInformation: {
        ...objData.basicInformation,
        typeId: newEntityLevelActivatedLevelId,
        name: objData.basicInformation.name,
        sizeTypeId: objData.basicInformation.sizeTypeId,
        taxId: objData.basicInformation.taxId,
        statusTypeId: objData.basicInformation.isActive,
        location: { address1: null, city: null, stateId: null, zipCode: null },
        mailingLocation: { address1: null, city: null, stateId: null, zipCode: null }
      },
      operatingStates: [],
      codes: [],
      clientStructure: objData.clientLevelSetting
    };
    dispatch(
      setClientStructure(
        {
          clientId,
          clientLevels: clientLevelSetting.map(level => ({ ...level, label: state.find(item => item.levelId === level.levelId).levelName }))
        },
        () => {
          fetchClientStructure(true);
          dispatch(
            addClientAndMigrateLevel({ newEntity: clientState, levelId: NextEntityLevelActivatedLevelId }, moveChildEntitiesToNewParent, onError)
          );
          setOpenNewEntityLevelActivated(false);
        }
      )
    );
  };
  const [newClient, setNewClient] = useState({
    basicInformation: {
      parentId: null,
      name: null,
      sizeTypeId: null,
      taxId: null
    },
    clientStatus: {
      isActive: true
    }
  });
  const Schema = yup.object().shape({
    basicInformation: yup.object().shape({
      parentId: yup
        .string("Parent is a required field")
        .nullable()
        .required("Parent is a required field"),
      name: yup
        .string("Name is a required field")
        .nullable()
        .required("Name is a required field"),
      sizeTypeId: yup
        .string("Client Size is a required field")
        .nullable()
        .required("Client Size is a required field")
    }),
    clientStatus: yup.object().shape({
      isActive: yup
        .number()
        .typeError("Status Required")
        .test("default", "Status Required", val => [1, 2].some(x => x === val))
    })
  });
  const { handleValidateSchema, validationMessages: error } = useYupValidationSchema(Schema);
  const handleChangeBasicInformation = ({ target: { name, value } }) =>
    setNewClient(prevState => {
      const newstate = { ...prevState, basicInformation: { ...prevState.basicInformation, [name]: value } };
      handleValidateSchema(newstate);
      return newstate;
    });
  const handleChangeSatus = ({ target: { name, value } }) =>
    setNewClient(prevState => {
      const newstate = { ...prevState, clientStatus: { ...prevState.clientStatus, [name]: value } };
      handleValidateSchema(newstate);
      return newstate;
    });
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleSave, "Cancel", "Yes, Create");
  const handleConfirm = () => {
    if (!handleValidateSchema(newClient).isValid) return;
    handleCustomConfirm({
      oData: { ...newClient, clientLevelSetting, codes: state.codes, operatingStates: state.operatingStates },
      titleText: `New Client`,
      messageText: `The following client will be created: ${newClient.basicInformation.name || ""} - ${clientType ||
        ""}. Are you sure you want to proceed?`,
      confirmText: "Yes, Create"
    });
  };
  return (
    <>
      <CollapsibleBox title="Client Structure" name="clientStructure" {...{ open, onToggle }} {...boxProps}>
        {!isAdd && Loading}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Level</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Information</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.map(({ levelId, levelName, tabs, childrenCount, isMainLevel }, index) => {
                const isActive = clientLevelSetting.find(level => level.levelId === levelId)?.isActive || false;
                const Span = p => <span className={clsx({ [classes.inactive]: !isActive })} {...p} />;
                return (
                  <TableRow key={levelId}>
                    <TableCell>
                      <Span>{index}</Span>
                    </TableCell>
                    <TableCell>
                      {((editMode || isAdd) && !isMainLevel && (
                        <InputText
                          name={`level-name-${index}`}
                          value={levelName}
                          onChange={({ target: { value } }) => {
                            setState(prev => prev.map(level => (level.levelId === levelId ? { ...level, levelName: value } : level)));
                          }}
                        />
                      )) || <Span>{levelName}</Span>}
                    </TableCell>
                    <TableCell>
                      <Span>{tabs ? tabs.map(item => item.name).join(", ") : ""}</Span>
                    </TableCell>
                    {((editMode || isAdd) && (
                      <TableCell>
                        {!isMainLevel && index !== 0 && levelId !== 4 && childrenCount === 0 && (
                          <Switch light textChecked="On" textUnchecked="Off" checked={isActive} onChange={event => handleChanges(event, levelId)} />
                        )}
                      </TableCell>
                    )) || (
                      <TableCell>
                        <Span>{`${isActive ? "A" : "Ina"}ctive`}</Span>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {editMode && !isAdd && (
          <Grid container alignItems="flex-start" justify="flex-end" direction="row" style={{ marginTop: "1rem" }}>
            <div className={classes.buttonWrapper}>
              <Button variant="outlined" color="primary" onClick={onToggleEdit}>
                CANCEL
              </Button>
              <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
                SAVE
              </Button>
            </div>
          </Grid>
        )}
      </CollapsibleBox>
      <CustomModal
        {...{
          openModal: openNewEntityLevelActivated,
          title: `Create ${newEntityLevelActivatedLevelName}`,
          onToggle: handleOpenModalNewEntityLevelActivated,
          onAction: handleConfirm,
          actionBtnText: "Create",
          maxWidth: "sm"
        }}
      >
        <Grid container spacing={6} style={{ margin: "-10px" }}>
          <Grid item xs={12}>
            <SelectInput
              name="parentId"
              label="Parent"
              value={newClient.basicInformation.parentId}
              options={availableParents}
              onChange={handleChangeBasicInformation}
              error={error["basicInformation.parentId"]}
            />
          </Grid>
          <Grid item xs>
            <InputText
              name="name"
              label="Name"
              value={newClient.basicInformation.name}
              onChange={handleChangeBasicInformation}
              error={error["basicInformation.name"]}
            />
            <SelectInput
              label="Status"
              name="isActive"
              value={newClient.clientStatus.isActive}
              options={[
                { id: 1, text: "Active" },
                { id: 2, text: "Inactive" }
              ]}
              onChange={handleChangeSatus}
              error={error["clientStatus.isActive"]}
            />
          </Grid>
          <Grid item xs>
            <SelectInput
              label="Size"
              name="sizeTypeId"
              value={newClient.basicInformation.sizeTypeId}
              options={sizeTypes}
              onChange={handleChangeBasicInformation}
              error={error["basicInformation.sizeTypeId"]}
            />
            <FeinInput
              name="taxId"
              value={newClient.basicInformation.taxId}
              label="FEIN Number"
              onChange={handleChangeBasicInformation}
              error={error["basicInformation.taxId"]}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel style={{ color: "red" }}>
              {`Alert! All the existing ${NextEntityLevelActivatedLevelName} are going to be assigned to this ${newEntityLevelActivatedLevelName}`}
            </InputLabel>
          </Grid>
        </Grid>
      </CustomModal>
      <Confirm />
    </>
  );
};

ClientStructure.propTypes = {
  isAdd: PropTypes.bool,
  onChange: PropTypes.func,
  clientType: PropTypes.string,
  actionsAllowed: PropTypes.objectOf(PropTypes.any),
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

ClientStructure.defaultProps = {
  isAdd: false,
  onChange: null,
  clientType: null,
  actionsAllowed: { create: true, update: true }
};

export default ClientStructure;
