import { toast } from "react-toastify";
import { API, ADMIN } from "../../../actions/actionTypes";
import {
  activateDocumentsSuccess,
  delDocumentSuccess,
  getDocumentsActiveSuccess,
  getDocumentsSuccess,
  getDocumentSuccess,
  setDocumentsSuccess
} from "../../../actions/admin/claimsSetting";
import { takeLatest, takeLatestDel, takeLatestSet } from "../../common/sagaGenerator";

const url = `${API.URL}/claimsetting/folder`;

export default [
  takeLatest({
    type: ADMIN.CLAIMSETTING.DOCUMENTS.GET,
    url: ({ id }) => `${url}/${id}`,
    actionSuccess: getDocumentSuccess,
    errorMessage: "Error getting Claim Document Setting"
  }),
  takeLatest({
    type: ADMIN.CLAIMSETTING.DOCUMENTS.GETALL,
    params: ({ getStructure }) => ({ getStructure }),
    url,
    actionSuccess: getDocumentsSuccess,
    errorMessage: "Error getting Claim Documents Settings."
  }),
  takeLatest({
    type: ADMIN.CLAIMSETTING.DOCUMENTS.SET,
    url,
    method: ({ data: { id } }) => (id ? "PUT" : "POST"),
    actionSuccess: setDocumentsSuccess,
    successMessage: "Claim Document Setting Saved",
    errorMessage: "Error setting Claim Document Setting",
    params: ({ data }) => data
  }),
  takeLatest({
    type: ADMIN.CLAIMSETTING.DOCUMENTS.GETACTIVE,
    url: `${url}/active`,
    actionSuccess: getDocumentsActiveSuccess,
    errorMessage: "Error getting Documents settings"
  }),
  takeLatestSet({
    type: ADMIN.CLAIMSETTING.DOCUMENTS.SETACTIVE,
    url: ({ active }) => `${url}/active?active=${active}`,
    actionSuccess: activateDocumentsSuccess,
    onSuccess: ({ active }) => toast.success(`Documents section successfully ${active ? "" : "de"}activated.`),
    onError: ({ active }) => toast.error(`Unable to ${active ? "" : "de"}activate the Documents section`)
  }),
  takeLatestDel({
    type: ADMIN.CLAIMSETTING.DOCUMENTS.DEL,
    url,
    params: ({ id }) => ({ id }),
    actionSuccess: delDocumentSuccess,
    successMessage: "Folder Structure successfully deleted",
    errorMessage: "Unable to delete the Folder Structure"
  })
];
