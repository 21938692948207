import React, { useRef, useState, useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import * as yup from "yup";
import { Grid, Paper, Typography } from "@material-ui/core";
import { CheckboxLabel, SearchLabelInput } from "../../components/common/inputs";
import { useRouteParam, useDebounce } from "../../common";
import { get, set, assocciateClaim } from "../../redux/actions/occurrence";
import useStyles from "./index.styles";
import { API } from "../../redux/actions/actionTypes";
import { api } from "../../api/apiUtils";
import CollapsibleClaimList from "../../components/claims/claim_list/CollapsibleClaimList";
import Form from "../../components/occurrence/Form";
import { FormContextProvider } from "../../components/common/contexts/FormContext";
import Read from "../../components/occurrence/Read";
import CollapsibleBox, { useCollapsibleBox } from "../../components/common/CollapsibleBox";
import useCustomConfirm from "../../components/common/customHooks/useCustomConfirm";
import ActionsAllowed from "../../components/common/contexts/ActionsAllowed";
import useYupValidationSchema from "../../components/common/customHooks/useYupValidationSchema";
import { useLoadingContext } from "../../components/common/Loading";

const OccurrencePage = () => {
  const contextRef = useRef();
  const getOcc = () => contextRef?.current?.getState() || {};
  const occurrenceId = useRouteParam("occurrenceId");
  const dispatch = useDispatch();
  const occurrence = useSelector(({ occurrence: { occurrence: occInfo } }) => occInfo, shallowEqual);
  const routesAllowed = useContext(ActionsAllowed);
  const actionsAllowed = routesAllowed.find(elem => elem.route === "info");
  const [claims, setClaims] = useState([]);
  const [filterClaimSearch, setClaimSearch] = useState({
    sameDol: true,
    sameCity: true,
    sameState: true,
    sameInsured: true,
    searchText: ""
  });
  const search = useDebounce(check => {
    if (filterClaimSearch.searchText.length >= 3) {
      const occ = getOcc();
      api({
        method: "GET",
        url: `${API.URL}/claim/search`,
        data: {
          ...filterClaimSearch,
          dateOfLoss: occ.date,
          stateId: occ.stateId,
          insuredId: occ.insuredId,
          city: occ.city,
          occurrenceId: occ.id,
          catastropheId: null,
          ...check
        }
      })
        .then(data => setClaims(data))
        .catch();
    }
  }, 500);
  const associateClaimToOccurrence = claim => dispatch(assocciateClaim({ claimId: claim.id, occurrenceId: occurrence.id }, search));
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(associateClaimToOccurrence, "Cancel", "Yes, Associate");
  const handleConfirmAssociate = claim =>
    handleCustomConfirm({
      oData: claim,
      titleText: `Associate Claim`,
      messageText: `The following Claim will be associated to this Occurrence: ${claim.number}. Are you sure you want to proceed? `
    });
  const openClaimDetail = claim => api({ method: "GET", url: `${API.URL}/claim/search` }, { params: claim.id }).then(data => setClaims(data));
  const [loadedOcc, setloadedOcc] = useState(false);
  useEffect(() => {
    if (occurrenceId && !loadedOcc) {
      dispatch(get(occurrenceId));
      setloadedOcc(true);
    }
  }, [occurrence]);
  const onChangeSearchClaim = ({ target: { name, value } }) => {
    setClaimSearch(prevSearchClaim => ({ ...prevSearchClaim, [name]: value }));
    search();
  };
  const onToggle = name => setClaims(prevState => prevState.map(obj => (obj.number === name ? Object.assign(obj, { open: !obj.open }) : obj)));
  const searchBy = ["city", "insuredId", "stateId", "date"];
  const classes = useStyles();
  const {
    actions: { hideLoading, showLoading }
  } = useLoadingContext();
  const {
    props: { boxProps, editMode },
    actions: { onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(actionsAllowed?.update, false, hideLoading);
  const actionSave = () => {
    const occ = getOcc();
    showLoading();
    dispatch(set(occ, onSuccessEdit, hideLoading));
  };
  const {
    handleValidateSchema,
    validationMessages: { searchText: error }
  } = useYupValidationSchema(
    yup.object().shape({
      searchText: yup.string().test("len", "Type at least 3 characters", val => val.length > 2)
    })
  );
  useEffect(() => {
    handleValidateSchema(filterClaimSearch);
  }, [filterClaimSearch]);
  const filterProps = (name, label, srch) => ({ name, label, onChange: onChangeSearchClaim, [srch ? "value" : "checked"]: filterClaimSearch[name] });
  return (
    <Grid container spacing={editMode ? 3 : 0} alignItems="stretch">
      <Grid item lg={editMode ? 6 : 12} md={editMode ? 6 : 12} sm={12} xs={12}>
        <CollapsibleBox isCollapse={false} {...boxProps}>
          <FormContextProvider ref={contextRef} initialData={occurrence}>
            {(!editMode || !actionsAllowed.update) && <Read />}
            {editMode && actionsAllowed.update && <Form actionCancel={onToggleEdit} {...{ actionSave, search, searchBy }} />}
          </FormContextProvider>
        </CollapsibleBox>
      </Grid>
      {editMode && actionsAllowed.update && (
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Paper className={classes.paper}>
            <Typography className="tc-title">Associate Claims</Typography>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <CheckboxLabel {...filterProps("sameDol", "Same DOL")} />
              </Grid>
              <Grid item xs={3}>
                <CheckboxLabel {...filterProps("sameCity", "Same City")} />
              </Grid>
              <Grid item xs={3}>
                <CheckboxLabel {...filterProps("sameState", "Same State")} />
              </Grid>
              <Grid item xs={3}>
                <CheckboxLabel {...filterProps("sameInsured", "Same Insured")} />
              </Grid>
            </Grid>
            <SearchLabelInput {...filterProps("searchText", "Claim Number / Claimant Name", true)} onClick={() => search(getOcc())} {...{ error }} />
            <CollapsibleClaimList {...{ claims, onToggle, associate: handleConfirmAssociate, openClaimDetail }} />
          </Paper>
        </Grid>
      )}
      <Confirm />
    </Grid>
  );
};

export default OccurrencePage;
