import { takeLatest } from "redux-saga/effects";
import { CLAIM, API } from "../../actions/actionTypes";
import financialActions, {
  getSuccess,
  getFinancialInfoSuccess,
  getRecoverySuccess,
  postRecoverySuccess,
  setRecoverySuccess,
  sendToPaySuccess,
  setAsVoidedSuccess,
  setBillAsDuplicateSuccess,
  setBillReindexSuccess,
  delBillSuccess
} from "../../actions/claim/financial";
import sagaGenerator, { takeLatestDel } from "../common/sagaGenerator";

import * as docs from "../common/documents";

const url = `${API.URL}/bill`;
const urlPayment = `${API.URL}/schedulePayment`;

export default [
  takeLatest(
    CLAIM.FINANCIAL.BILL.GET,
    sagaGenerator({
      url: `${url}/getAll`,
      params: ({ claimId }) => ({ claimId }),
      actionSuccess: getSuccess,
      errorMessage: "Error loading Financial"
    })
  ),
  takeLatestDel({
    type: CLAIM.FINANCIAL.RECOVERY.DEL,
    url: `${API.URL}/recovery`,
    params: ({ id }) => ({ id }),
    actionSuccess: params => financialActions.deleteRecovery({ success: true, data: params }),
    successMessage: "Recovery successfully deleted",
    errorMessage: "Unable to delete the Recovery"
  }),
  takeLatestDel({
    method: "DELETE",
    type: CLAIM.FINANCIAL.BILL.DEL,
    url: `${API.URL}/bill`,
    params: ({ id }) => ({ id }),
    actionSuccess: delBillSuccess,
    successMessage: "Bill successfully deleted",
    errorMessage: "Unable to delete the Bill"
  }),
  takeLatestDel({
    type: CLAIM.FINANCIAL.SCHEDULEPAY.DEL,
    url: `${API.URL}/schedulePayment/deleteSchedulePayment`,
    params: ({ id, claimId }) => ({ id, claimId }),
    actionSuccess: params => financialActions.deleteSchedulePayment({ success: true, data: params }),
    successMessage: "Schedule Payment successfully deleted",
    errorMessage: "Unable to delete the Schedule Payment"
  }),
  takeLatest(
    CLAIM.FINANCIAL.LIST_INFO.GETALL,
    sagaGenerator({
      url: `${API.URL}/claim/getFinancialAll`,
      params: ({ claimId }) => ({ claimId }),
      actionSuccess: getFinancialInfoSuccess,
      errorMessage: "Error loading Financial"
    })
  ),
  takeLatest(
    CLAIM.FINANCIAL.RECOVERY.GETALL,
    sagaGenerator({
      url: `${API.URL}/recovery/getAll`,
      params: ({ claimId }) => ({ claimId }),
      actionSuccess: getRecoverySuccess,
      errorMessage: "Error loading Recoveries"
    })
  ),
  takeLatest(
    CLAIM.FINANCIAL.SCHEDULEPAY.POST,
    sagaGenerator({
      method: "POST",
      url: `${urlPayment}/addSchedulePayment`,
      params: ({ schedulePayment }) => schedulePayment,
      actionSuccess: getFinancialInfoSuccess,
      successMessage: "Schedule Payment added",
      errorMessage: "Unable to add the Schedule Payment"
    })
  ),
  takeLatest(
    CLAIM.FINANCIAL.SCHEDULEPAY.SET,
    sagaGenerator({
      method: "PUT",
      url: `${urlPayment}/setSchedulePayment`,
      params: ({ schedulePayment }) => schedulePayment,
      actionSuccess: getFinancialInfoSuccess,
      successMessage: "Schedule Payment updated",
      errorMessage: "Unable to update the Schedule Payment"
    })
  ),
  takeLatest(
    CLAIM.FINANCIAL.BILL.POST,
    sagaGenerator({
      method: "POST",
      url,
      params: ({ bill }) => {
        const data = new FormData();
        Object.keys(bill).forEach(prop => {
          if (prop === "files") for (let i = 0; i < bill.files.length; i += 1) data.append("files", bill.files[i]);
          else data.append(prop, bill[prop]);
        });
        return data;
      },
      actionSuccess: getFinancialInfoSuccess,
      successMessage: "Bill successfully added",
      errorMessage: "Unable to add the bill"
    })
  ),
  takeLatest(
    CLAIM.FINANCIAL.BILL.SET,
    sagaGenerator({
      method: "POST",
      url: `${url}/setbill`,
      params: ({ bill }) => {
        const data = new FormData();
        Object.keys(bill).forEach(prop => {
          if (prop === "files") for (let i = 0; i < bill.files.length; i += 1) data.append("files", bill.files[i]);
          else data.append(prop, bill[prop]);
        });
        return data;
      },
      actionSuccess: getFinancialInfoSuccess,
      successMessage: "Bill successfully updated",
      errorMessage: "Unable to update the bill"
    })
  ),
  takeLatest(CLAIM.FINANCIAL.BILL.DOWNLOAD_FILES, docs.downloadFiles(CLAIM.FINANCIAL.BILL.DOWNLOAD_FILES_SUCCESS)),
  takeLatest(
    CLAIM.FINANCIAL.RECOVERY.POST,
    sagaGenerator({
      method: "POST",
      url: `${API.URL}/recovery`,
      params: ({ data }) => data,
      actionSuccess: postRecoverySuccess,
      successMessage: "Recovery successfully added",
      errorMessage: "Unable to add the Recovery"
    })
  ),
  takeLatest(
    CLAIM.FINANCIAL.RECOVERY.SET,
    sagaGenerator({
      method: "PUT",
      url: `${API.URL}/recovery/setRecovery`,
      params: ({ data }) => data,
      actionSuccess: setRecoverySuccess,
      successMessage: "Recovery successfully updated",
      errorMessage: "Unable to update the Recovery"
    })
  ),
  takeLatest(
    CLAIM.FINANCIAL.SENDTOPAY.SET,
    sagaGenerator({
      method: "PUT",
      url: `${API.URL}/financial/sendToPay`,
      params: ({ entities }) => entities,
      actionSuccess: sendToPaySuccess,
      successMessage: "Items was sent to Pay",
      errorMessage: "Unable to send to Pay"
    })
  ),
  takeLatest(
    CLAIM.FINANCIAL.VOIDED.SET,
    sagaGenerator({
      method: "PUT",
      url: `${API.URL}/financial/setAsVoided`,
      params: ({ entities }) => entities,
      actionSuccess: setAsVoidedSuccess,
      successMessage: "Bill successfuly set as Voided",
      errorMessage: "Unable to set Bill as Voided"
    })
  ),
  takeLatest(
    CLAIM.FINANCIAL.BILLDUPLICATE.SET,
    sagaGenerator({
      method: "PUT",
      url: `${API.URL}/bill/markAsDuplicated`,
      params: ({ bill }) => bill,
      actionSuccess: setBillAsDuplicateSuccess,
      successMessage: "Bill successfuly set as Duplicated",
      errorMessage: "Unable to set Bill as Duplicated"
    })
  ),
  takeLatest(
    CLAIM.FINANCIAL.BILLREINDEX.SET,
    sagaGenerator({
      method: "PUT",
      url: `${API.URL}/bill/reindex`,
      params: ({ bill }) => bill,
      actionSuccess: setBillReindexSuccess,
      successMessage: "Bill successfuly returned to indexing",
      errorMessage: "Unable to return the Bill to indexing"
    })
  )
];
