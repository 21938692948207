import { Grid } from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Bar } from "react-chartjs-2";
import BankTransfers from "../../../assets/bank_transfers.svg";
import CheckIssued from "../../../assets/check_issued.svg";
import Total from "../../claims/claim/reserves/CurrentReserveTotal";
import Card from "../../common/overview/Card";
import { useFinancialInfoStyles } from "./index.styles";

const options = {
  maintainAspectRatio: false,
  legend: { position: "bottom", labels: { usePointStyle: true, padding: 20, boxWidth: 6 } },
  scales: {
    yAxes: [{ ticks: { beginAtZero: true } }],
    xAxes: [{ ticks: { autoSkip: false, maxRotation: 45, minRotation: 45 } }]
  }
};

const FinancialInfo = ({ financialInfo }) => {
  const financialClasses = useFinancialInfoStyles();
  const map = (p, date = false) => (financialInfo.barChart || []).map(x => (date ? moment(x[p]).format("MMM") : x[p]));
  const data = {
    labels: map("billDate", true),
    datasets: [
      ["totalPaid", "Total Paid Amount", "#00add0"],
      ["totalPendingPaid", "Pending Payments", "#cecece"]
    ].map(([p, label, color]) => ({ label, backgroundColor: color, borderColor: color, borderWidth: 1, data: map(p) }))
  };
  const total = path => financialInfo?.total?.[path];
  return (
    <Card title="Financial Info">
      <Grid container spacing={3} className={financialClasses.sumGrid}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Total
                {...{
                  css: financialClasses.reserved,
                  classes: financialClasses,
                  title: "Total Paid Amount",
                  value: total("totalPaid")
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Total
                {...{
                  css: financialClasses.total,
                  classes: financialClasses,
                  title: "Pending Payments",
                  value: total("totalPendingPaid")
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} className={financialClasses.center}>
              <CheckIssued className={financialClasses.icon} />
              <div className={financialClasses.text}>Check Issued</div>
              <div className={financialClasses.triangle} />
              <div className={financialClasses.amount}>{total("checkIssued")}</div>
            </Grid>
            <Grid item xs={4} className={financialClasses.center}>
              <BankTransfers className={financialClasses.icon} />
              <div className={financialClasses.text}>Bank Transfers</div>
              <div className={financialClasses.triangle} />
              <div className={financialClasses.amount}>{total("banktransfer")}</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Bar width={100} height={250} {...{ data, options }} />
        </Grid>
      </Grid>
    </Card>
  );
};

FinancialInfo.propTypes = {
  financialInfo: PropTypes.shape({ barChart: PropTypes.arrayOf(PropTypes.shape({})), total: PropTypes.shape({}) }).isRequired
};

export default FinancialInfo;
