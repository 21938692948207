import React, { useContext } from "react";
import { useSelector, shallowEqual } from "react-redux";
import * as docActions from "../../redux/actions/claim/documents";
import { DocumentsBox } from "../common/documents";
import { useRouteParam } from "../../common";
import ActionsAllowedContext from "../common/contexts/ActionsAllowed";

export default () => {
  const parentId = useRouteParam("claimId");
  const documents = useSelector(({ claim: { documents: d } }) => d, shallowEqual);
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "documents");
  const actions = {
    get: docActions.loadByClaimId,
    deleteFile: docActions.deleteFile,
    onUpdateFile: docActions.updateFile,
    uploadFiles: docActions.uploadFiles,
    downloadFiles: docActions.downloadFiles,
    openFile: docActions.openFile
  };
  return <DocumentsBox {...{ parentId, path: "claimId", documents, actions }} toggleViews actionsAllowed={access} />;
};
