import { INITIALREPORT as REPORT, API } from "../../../actions/actionTypes";
import { getSuccess, setSuccess, setData } from "../../../actions/initialReport/claimant";
import { takeLatest, takeLatestSet } from "../../common/sagaGenerator";

const url = `${API.URL}/claim`;

export default [
  takeLatest({
    type: REPORT.INFORMATION.CLAIMANT.GET,
    actionSuccess: getSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading Reported By",
    url: `${url}/claimant`
  }),
  takeLatestSet({
    type: REPORT.INFORMATION.CLAIMANT.SET,
    actionSuccess: setSuccess,
    params: ({ claimId, claimant }) => ({ data: setData(claimant), claimId }),
    successMessage: "Reported By information successfully saved",
    errorMessage: "Unable to save the information",
    url: `${API.URL}/claimant`
  })
];
