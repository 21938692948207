import { CLIENTR as CLIENT, API } from "../../../actions/actionTypes";
import { getSuccess } from "../../../actions/client/insured/contact";
import { takeLatest } from "../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLIENT.INSURED.CONTACTS.GET,
    actionSuccess: getSuccess,
    errorMessage: "Error loading Insured contacts",
    url: `${API.URL}/contact/getAllByInsured`,
    params: ({ id }) => ({ id })
  })
];
