import React, { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { Delete as DeleteIcon } from "@material-ui/icons";
import moment from "moment";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import { useRouteParam } from "../../../../common";
import DataTable from "../../../common/table/MaterialTable";
import { DateTimeInput, SelectInput } from "../../../common/inputs";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import { isDate, isNumber } from "../../../../common/yup";
import useCustomConfirm from "../../../common/customHooks/useCustomConfirm";

const useStyles = makeStyles(() => ({
  inputWidth: {
    width: "140px"
  }
}));

const ClaimAttorney = ({ firms, lawyers, data, actions, ...other }) => {
  const claimId = useRouteParam("claimId");
  const classes = useStyles();
  const { get, post, set, del } = actions || {};
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(({ route }) => route === "legal") || {};
  const {
    props: { boxProps, Loading },
    actions: { showLoading, hideLoading }
  } = useCollapsibleBox();
  const dispatch = useDispatch();
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  const labelColumn = (t, field) => ({
    title: t,
    field,
    editComponent: col => {
      const {
        rowData: { lawFirmId, attorneyId }
      } = col;
      let firm = firms.find(f => f.id === lawFirmId);
      if (!firm?.address1) firm = lawyers.find(f => f.id === attorneyId) || {};
      return <span>{firm[field] || lawyers[field]}</span>;
    }
  });
  const action = (act, fnData, validate) => r => {
    showLoading();
    return new Promise((resolve, reject) => {
      if (!validate || (isDate(r?.effectiveDate) && (!r?.lawFirmId || isNumber(r?.lawFirmId)) && isNumber(r?.attorneyId))) {
        dispatch(act(fnData(r), hideLoading, hideLoading));
        resolve();
        return;
      }
      showLoading();
      reject();
    });
  };
  const inputProps = (col, name, error, date) => {
    const props = {
      name,
      className: classes.inputWidth,
      value: col.rowData[name] || undefined,
      onChange: ({ target: { value } }) => col.onChange(value),
      error: error && !(date ? isDate : isNumber)(col.rowData[name]) ? error : undefined
    };
    switch (name) {
      case "lawFirmId":
        return {
          ...props,
          allowClear: firms.some(({ lawFirmId }) => !lawFirmId),
          options: firms,
          onChange: ({ target: { value } }) => col.onRowDataChange({ ...col.rowData, lawFirmId: value })
        };
      case "attorneyId":
        return {
          ...props,
          allowClear: lawyers.some(({ lawFirmId }) => !lawFirmId),
          options: lawyers,
          onChange: ({ target: { value } }) => col.onRowDataChange({ ...col.rowData, attorneyId: value })
        };
      default:
        return { ...props, ...(date ? { variant: "dialog", clearable: true } : {}) };
    }
  };
  const handleDelete = rowData => {
    if (access.delete) {
      showLoading();
      dispatch(
        del(
          { claimAttorneyId: rowData.claimAttorneyId, claimId, claimantId: rowData.claimantId, insuredId: rowData.insuredId },
          hideLoading,
          hideLoading
        )
      );
    }
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleDelete, "Cancel", "Yes, Delete");
  const handleConfirmDelete = rowData =>
    handleCustomConfirm({
      oData: rowData,
      titleText: `Delete Attorney Information`,
      messageText: (
        <>
          <p>The Attorney information will be deleted.</p>
          <p>Are you sure you want to proceed?</p>
        </>
      )
    });

  const tableProps = {
    title: "",
    columns: [
      {
        title: "Eff.Date",
        field: "effectiveDate",
        editComponent: row => <DateTimeInput {...inputProps(row, "effectiveDate", "Eff. Date Required", true)} />,
        render: row => (row.effectiveDate ? moment(row.effectiveDate).format("M/D/YYYY") : "")
      },
      {
        title: "Exp.Date",
        field: "expirationDate",
        editComponent: row => <DateTimeInput {...inputProps(row, "expirationDate", undefined, true)} />,
        render: row => (row.expirationDate ? moment(row.expirationDate).format("M/D/YYYY") : "")
      },
      {
        title: "Firm Name",
        field: "lawFirm",
        editComponent: row => <SelectInput {...inputProps(row, "lawFirmId")} />
      },
      {
        title: "Lawyer Name",
        field: "attorneyName",
        editComponent: col => {
          const { lawFirmId } = col.rowData;
          const options = lawyers
            .filter(l => (!lawFirmId && !l.lawFirmId) || l.lawFirmId === lawFirmId)
            .map(({ id, fullName }) => ({ id, text: fullName }));
          return <SelectInput {...inputProps(col, "attorneyId", "Lawyer Name Required")} {...{ options }} />;
        }
      },
      labelColumn("Address", "address1"),
      labelColumn("City", "city"),
      labelColumn("State", "state"),
      labelColumn("Zip", "zipCode"),
      labelColumn("Phone", "phone"),
      labelColumn("Fax", "fax"),
      labelColumn("Email", "email")
    ],
    data: data.map(a => ({
      ...a,
      hasFirm: Boolean(a.lawFirmId),
      lawFirm: firms.find(({ id }) => id === a.lawFirmId)?.text,
      attorneyName: lawyers.find(({ id }) => id === a.attorneyId)?.fullName
    })),
    options: { actionsColumnIndex: -1 },
    hideExportButton: true,
    components: { Container: ({ children }) => children },
    editable: {
      ...(access.create ? { onRowAdd: action(post, r => ({ ...r, claimId }), true) } : {}),
      ...(access.update ? { onRowUpdate: action(set, r => r, true) } : {})
    },
    actions: access.delete
      ? [
          {
            icon: () => <DeleteIcon />,
            tooltip: "Delete",
            onClick: (_e, rowData) => {
              _e.stopPropagation();
              handleConfirmDelete(rowData);
            }
          }
        ]
      : {}
  };
  return (
    <CollapsibleBox {...other} {...boxProps}>
      {Loading}
      <form>
        <DataTable {...tableProps} />
        <Confirm />
      </form>
    </CollapsibleBox>
  );
};

ClaimAttorney.propTypes = {
  firms: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  lawyers: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  data: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  actions: PropTypes.shape({}).isRequired
};

export default ClaimAttorney;
