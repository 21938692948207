import { BANKING, CLIENTR as CLIENT, VENDOR } from "./actionTypes";

export const get = (data, onSuccess, onError) => ({ type: BANKING.GET, data, onSuccess, onError });
export const getClientBankingSuccess = accounts => ({ type: CLIENT.BANKING.GET_SUCCESS, accounts });
export const getVendorBankingSuccess = accounts => ({ type: VENDOR.BANKING.GET_SUCCESS, accounts });

export const post = ({ data, entityType }, onSuccess, onError) => ({ type: BANKING.POST, data, entityType, onSuccess, onError });
export const postClientBankingSuccess = accounts => ({ type: CLIENT.BANKING.POST_SUCCESS, accounts });
export const postVendorBankingSuccess = accounts => ({ type: VENDOR.BANKING.POST_SUCCESS, accounts });

export const set = ({ data, entityType }, onSuccess, onError) => ({ type: BANKING.SET, data, entityType, onSuccess, onError });
export const setClientBankingSuccess = accounts => ({ type: CLIENT.BANKING.SET_SUCCESS, accounts });
export const setVendorBankingSuccess = accounts => ({ type: VENDOR.BANKING.SET_SUCCESS, accounts });

export const del = ({ id, entityType, entityId }) => ({ type: BANKING.DEL, id, entityType, entityId });
export const delClientBankingSuccess = accounts => ({ type: CLIENT.BANKING.DEL_SUCCESS, accounts });
export const delVendorBankingSuccess = accounts => ({ type: VENDOR.BANKING.DEL_SUCCESS, accounts });
