import { CLAIM } from "../actionTypes";
import * as docActions from "../common/documents";

const builActionDelete = ({ entity, success, ...rest }) => ({ type: CLAIM.FINANCIAL[entity][success ? "DEL_SUCCESS" : "DEL"], ...rest });

export const get = (claimId, onSuccess, onError) => ({ type: CLAIM.FINANCIAL.BILL.GET, claimId, onSuccess, onError });
export const getFinancialInfo = (claimId, onSuccess, onError) => ({ type: CLAIM.FINANCIAL.LIST_INFO.GETALL, claimId, onSuccess, onError });
export const getSuccess = bill => ({ type: CLAIM.FINANCIAL.BILL.GET_SUCCESS, bill });
export const getFinancialInfoSuccess = data => ({ type: CLAIM.FINANCIAL.LIST_INFO.GETALL_SUCCESS, data });
export const getRecovery = (claimId, onSuccess, onError) => ({ type: CLAIM.FINANCIAL.RECOVERY.GETALL, claimId, onSuccess, onError });
export const getRecoverySuccess = data => ({ type: CLAIM.FINANCIAL.RECOVERY.GETALL_SUCCESS, data });
export const postRecovery = (data, onSuccess, onError) => ({ type: CLAIM.FINANCIAL.RECOVERY.POST, data, onSuccess, onError });
export const postRecoverySuccess = data => ({ type: CLAIM.FINANCIAL.RECOVERY.POST_SUCCESS, data });
export const updateRecovery = (data, onSuccess, onError) => ({ type: CLAIM.FINANCIAL.RECOVERY.SET, data, onSuccess, onError });
export const setRecoverySuccess = data => ({ type: CLAIM.FINANCIAL.RECOVERY.SET_SUCCESS, data });
export const post = (bill, onSuccess, onError) => ({ type: CLAIM.FINANCIAL.BILL.POST, bill, onSuccess, onError });
export const postSuccess = bill => ({ type: CLAIM.FINANCIAL.BILL.POST_SUCCESS, bill });
export const set = (bill, onSuccess, onError) => ({ type: CLAIM.FINANCIAL.BILL.SET, bill, onSuccess, onError });
export const setSuccess = bill => ({ type: CLAIM.FINANCIAL.BILL.SET_SUCCESS, bill });
export const downloadFiles = docActions.downloadFiles(CLAIM.FINANCIAL.BILL.DOWNLOAD_FILES);
export const downloadFilesSuccess = docActions.downloadFiles(CLAIM.FINANCIAL.BILL.DOWNLOAD_FILES_SUCCESS);
export const postSchedulePay = (schedulePayment, onSuccess, onError) => ({
  type: CLAIM.FINANCIAL.SCHEDULEPAY.POST,
  schedulePayment,
  onSuccess,
  onError
});
export const setSchedulePay = (schedulePayment, onSuccess, onError) => ({
  type: CLAIM.FINANCIAL.SCHEDULEPAY.SET,
  schedulePayment,
  onSuccess,
  onError
});
export const postSchedulePaySuccess = bill => ({ type: CLAIM.FINANCIAL.SCHEDULEPAY.POST_SUCCESS, bill });
export const sendToPay = (entities, onSuccess, onError) => ({ type: CLAIM.FINANCIAL.SENDTOPAY.SET, entities, onSuccess, onError });
export const sendToPaySuccess = data => ({ type: CLAIM.FINANCIAL.SENDTOPAY.SET_SUCCESS, data });
export const setAsVoided = (entities, onSuccess, onError) => ({ type: CLAIM.FINANCIAL.VOIDED.SET, entities, onSuccess, onError });
export const setAsVoidedSuccess = data => ({ type: CLAIM.FINANCIAL.VOIDED.SET_SUCCESS, data });
export const setBillAsDuplicate = (bill, onSuccess, onError) => ({ type: CLAIM.FINANCIAL.BILLDUPLICATE.SET, bill, onSuccess, onError });
export const setBillAsDuplicateSuccess = data => ({ type: CLAIM.FINANCIAL.BILLDUPLICATE.SET_SUCCESS, data });
export const setBillReindex = (bill, onSuccess, onError) => ({ type: CLAIM.FINANCIAL.BILLREINDEX.SET, bill, onSuccess, onError });
export const setBillReindexSuccess = data => ({ type: CLAIM.FINANCIAL.BILLREINDEX.SET_SUCCESS, data });
export const delBill = (id, onSuccess, onError) => ({ type: CLAIM.FINANCIAL.BILL.DEL, id, onSuccess, onError });
export const delBillSuccess = data => ({ type: CLAIM.FINANCIAL.BILL.DEL_SUCCESS, data });

export default {
  deleteRecovery: params => builActionDelete({ entity: "RECOVERY", success: params?.success, ...params }),
  deleteSchedulePayment: params => builActionDelete({ entity: "SCHEDULEPAY", success: params?.success, ...params })
};
