import React, { memo } from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Switch } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  label: { fontWeight: 500 },
  unchecked: { color: "#898F91" },
  checked: { color: "#000" },
  pointer: { cursor: "pointer" }
}));

const CustomSwitch = memo(
  ({ textChecked, textUnchecked, checked, light, ...other }) => {
    const classes = useStyles();
    const className = isChecked =>
      clsx({ [classes.label]: !light, [classes.checked]: isChecked ? checked : !checked, [classes.unchecked]: isChecked ? !checked : checked });
    return (
      <Grid container wrap="nowrap" alignItems="center">
        {textUnchecked && (
          <Grid item className={className()}>
            {textUnchecked}
          </Grid>
        )}
        <Switch {...{ color: "primary", ...other, checked }} />
        {textChecked && (
          <Grid item className={className(true)}>
            {textChecked}
          </Grid>
        )}
      </Grid>
    );
  },
  (p, n) => p.checked === n.checked
);

CustomSwitch.propTypes = {
  textChecked: PropTypes.string,
  textUnchecked: PropTypes.string,
  checked: PropTypes.bool,
  light: PropTypes.bool
};

CustomSwitch.defaultProps = {
  textChecked: undefined,
  textUnchecked: undefined,
  checked: undefined,
  light: undefined
};

export default CustomSwitch;
