import { getActions } from "./common";

export default {
  GET: "GET_OCCURRENCE",
  GET_SUCCESS: "GET_OCCURRENCE_SUCCESS",
  GETALL: "GET_OCCURRENCES",
  GETALL_SUCCESS: "GET_OCCURRENCES_SUCCESS",
  GET_RESERVES: "GET_RESERVES",
  GET_RESERVES_SUCCESS: "GET_RESERVES_SUCCESS",
  POST: "POST_OCCURRENCE",
  POST_SUCCESS: "POST_OCCURRENCE_SUCCESS",
  SET: "SET_OCCURRENCE",
  SET_SUCCESS: "SET_OCCURRENCE_SUCCESS",
  DEL: "DEL_OCCURRENCE",
  DEL_SUCCESS: "DEL_OCCURRENCE_SUCCESS",
  ASSOCIATECLAIM: "ASSOCIATECLAIM_OCCURRENCE",
  ASSOCIATECLAIM_SUCCESS: "ASSOCIATECLAIM_OCCURRENCE_SUCCESS",
  DISASSOCIATECLAIM: "DISASSOCIATECLAIM_OCCURRENCE",
  DISASSOCIATECLAIM_SUCCESS: "DISASSOCIATECLAIM_OCCURRENCE_SUCCESS",
  NOTES: {
    POST_SUCCESS: "POST_OCCURRENCENOTE_SUCCESS",
    SET_SUCCESS: "SET_OCCURRENCENOTE_SUCCESS",
    DEL_SUCCESS: "DEL_OCCURRENCENOTE_SUCCESS",
    GET_SUCCESS: "GET_OCCURRENCENOTES_SUCCESS",
    GET_DRAFT_SUCCESS: "GET_OCCURRENCENOTE_DRAFT_SUCCESS",
    POST_DRAFT_SUCCESS: "POST_OCCURRENCENOTE_DRAFT_SUCCESS"
  },
  DIARY: {
    TEMPLATES: getActions("OCCURRENCE_DIARY_TEMPLATES"),
    POST_SUCCESS: "POST_OCCURRENCEDIARIES_SUCCESS",
    SET_SUCCESS: "SET_OCCURRENCEDIARY_SUCCESS",
    DEL_SUCCESS: "DEL_OCCURRENCEDIARY_SUCCESS",
    GET_SUCCESS: "GET_OCCURRENCEDIARY_SUCCESS"
  },
  LOAD_FILES: "LOAD_OCCURRENCE_FILES",
  LOAD_FILES_SUCCESS: "LOAD_OCCURRENCE_FILES_SUCCESS",
  UPLOAD_FILES: "UPLOAD_OCCURRENCE_FILES",
  UPLOAD_FILES_SUCCESS: "UPLOAD_OCCURRENCE_FILES_SUCCESS",
  UPDATE_FILE: "UPDATE_OCCURRENCE_FILE",
  UPDATE_FILE_SUCCESS: "UPDATE_OCCURRENCE_FILE_SUCCESS",
  DELETE_FILE: "DELETE_OCCURRENCE_FILE",
  DELETE_FILE_SUCCESS: "DELETE_OCCURRENCE_FILE_SUCCESS",
  OPEN_FILE: "OPEN_OCCURRENCE_FILE",
  OPEN_FILE_SUCCESS: "OPEN_OCCURRENCE_FILE_SUCCESS",
  GET_FILTER_FIELD: "GET_OCCURRENCE_PROFILE_FILTER_FIELD",
  GET_FILTER_FIELD_SUCCESS: "GET_OCCURRENCE_PROFILE_FILTER_FIELD_SUCCESS",
  RESET: "RESET_OCCURRENCE"
};
