import { Paper, Grid, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useDocuments, useFolders, yupSchema } from "./customHooks";
import AddDocuments from "./AddDocuments";
import DocumentsTable from "./DocumentsTable";
import AssociatedList from "../../AssociatedList";
import useCustomConfirm from "../../customHooks/useCustomConfirm";
import useYupValidationSchema from "../../customHooks/useYupValidationSchema";
import useStyles from "../../../catastrophe/CatastropheAddPage.styles";
import * as catActions from "../../../../redux/actions/catastropheAction";
import * as occActions from "../../../../redux/actions/occurrence";
import useViewer from "../../viewer/useViewer";
import { setExportTitleFileName } from "../../../../common";
import { useLoadingContext } from "../../Loading";

const Documents = memo(({ occurrenceId, catastropheId, actionsAllowed }) => {
  const classes = useStyles();
  const {
    actions: { showLoading, hideLoading }
  } = useLoadingContext();
  const [clearChecks, setClearChecks] = useState(false, true);
  const [{ claimIds, occurrenceIds, newFiles, editFile }, setState] = useState({
    claimIds: [],
    occurrenceIds: [],
    hover: false,
    errorDrag: false,
    newFiles: [],
    editFile: undefined
  });
  const { storeId, associatedList: list, documents, entityId, entityType, isCatastrophe, number } = useDocuments(occurrenceId, catastropheId);
  const associatedList = (list || []).filter(
    ({ folders }) => Array.isArray(folders) && folders.length > 0 && (!editFile || folders.some(({ id: fId }) => fId === editFile.folderId))
  );
  const dispatch = useDispatch();
  const { handleValidateSchema, validationMessages } = useYupValidationSchema(yupSchema);
  const handleValidation = ext => handleValidateSchema({ claimIds, editFile, newFiles, ...(ext || {}) });
  const onCleanup = () => {
    hideLoading();
    setState(ps => ({ ...ps, newFiles: [], editFile: undefined, claimIds: [], occurrenceIds: [] }));
  };
  const actions = isCatastrophe ? catActions : occActions;
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(
    row => {
      showLoading();
      dispatch(actions.deleteFile({ [`${entityType}Id`]: entityId, data: row.id }, onCleanup));
    },
    "Cancel",
    "Yes, Delete"
  );
  useEffect(() => {
    if (storeId !== entityId) {
      showLoading();
      dispatch(actions.get(entityId, hideLoading, hideLoading));
    }
  }, []);
  const folders = useFolders(associatedList, claimIds);
  useEffect(() => {
    if (newFiles.some(({ folderId }) => folderId && !folders.some(({ id }) => id === folderId)))
      setState(ps => {
        const nf = ps.newFiles.map(f => ({ ...f, folderId: folders.some(({ id }) => id === f.folderId) ? f.folderId : undefined }));
        handleValidation({ newFiles: nf });
        return { ...ps, newFiles: nf };
      });
    else handleValidation();
  }, [claimIds]);
  const associatedProps = {
    onEdit: useCallback((vclaimIds, voccurrenceIds) => {
      setClearChecks(false);
      return setState(ps => ({ ...ps, claimIds: vclaimIds, occurrenceIds: voccurrenceIds }));
    }, []),
    relatedToText: " Documents"
  };
  const { onViewFile, fileViewer } = useViewer({
    dispatchAction: data => {
      showLoading();
      return dispatch(actions.openFile({ ...data, onSuccess: hideLoading, onError: hideLoading }));
    }
  });
  const [hideUploadLoad, setHideUploadLoad] = useState(false);
  useEffect(() => {
    if (hideUploadLoad) {
      hideLoading();
      setHideUploadLoad(false);
    }
  }, [newFiles]);
  const addFiles = files => {
    setHideUploadLoad(true);
    setState(ps => ({ ...ps, newFiles: [...ps.newFiles, ...files] }));
  };
  const setFile = ({ target: { name, value } }) => {
    const [oId, pName] = name.split(".");
    if (editFile) {
      setState(ps => ({ ...ps, editFile: { ...ps.editFile, [pName]: value } }));
    } else {
      setState(ps => ({
        ...ps,
        newFiles: [
          ...ps.newFiles.reduce((acc, item) => {
            if (item.id === oId) return [...acc, { ...item, [pName]: value }];
            return [...acc, item];
          }, [])
        ]
      }));
    }
  };
  const delFile = useCallback(fId => () => setState(ps => ({ ...ps, newFiles: ps.newFiles.filter(f => f.id !== fId) })), []);

  const documentsTableProps = {
    onClick: actionsAllowed.update
      ? useCallback(
          row => event => {
            event.preventDefault();
            event.stopPropagation();
            return setState(ps => ({
              ...ps,
              editFile: { ...row, name: row.fileName, type: row.fileType, folderId: row.folderId },
              newFiles: [],
              claimIds: row.claimIds,
              occurrenceIds: row.occurrenceIds
            }));
          },
          []
        )
      : () => {},
    onDelete: actionsAllowed.delete
      ? useCallback(
          row => event => {
            event.preventDefault();
            event.stopPropagation();
            return handleCustomConfirm({
              oData: row,
              titleText: `Delete Document`,
              messageText: (
                <>
                  <p>{`The following document is going to be deleted from the ${entityType} and from all the claims/occurrences associated:`}</p>
                  <p>
                    <b>{row.fileName}</b>
                  </p>
                  <p>Are you sure you want to proceed?</p>
                </>
              )
            });
          },
          []
        )
      : () => {},
    onOpen: useCallback((_e, { id }) => onViewFile(id, { [`${entityType}Id`]: entityId }), [onViewFile, entityType, entityId])
  };
  const onSave = () => {
    showLoading();
    const { isValid, validationMessages: error } = handleValidation();
    if (!isValid) {
      if ((error || {}).toast) toast.warning(error.toast);
      return false;
    }
    return dispatch(
      actions.uploadFiles(
        {
          ...{ files: newFiles, updateFile: editFile, claimIds },
          ...{ [`${entityType}Id`]: entityId, [`${entityType}Ids`]: [entityId] },
          ...(isCatastrophe ? { occurrenceIds } : {})
        },
        onCleanup
      )
    );
  };
  const [allDocuments, setAllDocuments] = useState([]);
  useEffect(() => {
    setAllDocuments(
      (documents || []).map(elem => ({
        ...elem,
        folderName: useFolders(associatedList, elem.claimIds).find(el => el.id === elem.folderId)?.name || ""
      }))
    );
  }, [documents]);
  const exportFileName = setExportTitleFileName(`${number}_Documents`);
  return (
    <Grid container spacing={2}>
      {(actionsAllowed.create || actionsAllowed.update) && (
        <Grid item xs={6}>
          <Paper className="tc-page-content tc-relative">
            {actionsAllowed.create && (
              <AddDocuments
                vFiles={newFiles}
                addFiles={addFiles}
                editFile={editFile}
                folders={folders}
                showLoading={showLoading}
                error={validationMessages}
                setFile={setFile}
                delFile={delFile}
                handleValidation={handleValidation}
              />
            )}

            <AssociatedList {...associatedProps} {...{ entityType, entityId, clearChecks, associatedList, claimIds, occurrenceIds }} />

            {(newFiles.length > 0 || editFile) && (
              <Grid container alignItems="flex-start" justify="flex-end" direction="row" spacing={2}>
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={onCleanup}>
                    CANCEL
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={onSave}>
                    SAVE
                  </Button>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      )}
      <Grid item xs={6}>
        <Paper className={classes.paper}>
          <DocumentsTable documents={allDocuments} actions={documentsTableProps} {...{ actionsAllowed, exportFileName }} />
          {fileViewer}
          <Confirm />
        </Paper>
      </Grid>
    </Grid>
  );
});

Documents.propTypes = {
  occurrenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  catastropheId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  actionsAllowed: PropTypes.objectOf(PropTypes.any)
};

Documents.defaultProps = {
  occurrenceId: null,
  catastropheId: null,
  actionsAllowed: { read: false, create: false, update: false, delete: false }
};

export default Documents;
