import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import Banking from "../../common/banking";
import { getUserAccess } from "../../../common";

export default () => {
  const {
    params: { id }
  } = useRouteMatch();
  const store = useSelector(
    ({
      vendor: {
        profile: { basicInformation }
      },
      profile: { securityProfilesDetails }
    }) => ({ basicInformation, securityProfilesDetails }),
    shallowEqual
  );
  const { basicInformation, securityProfilesDetails } = store;
  const actionsAllowed = getUserAccess(securityProfilesDetails, "VENDORS.BANKING", null, basicInformation?.location?.stateId, null);
  const selector = ({
    client: {
      insured: { accounts }
    }
  }) => accounts;
  return <Banking {...{ consumerId: id, selector, type: "vendor", actionsAllowed }} />;
};
