import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import MoneyLabel from "../../../../../common/MoneyLabel";
import { paths } from "./common";

const PolicyFinancial = ({ financial }) => (
  <Table>
    <TableBody>
      {paths.map(([label, name, percentage]) => (
        <TableRow key={name}>
          <TableCell>{label}</TableCell>
          <TableCell align="right">
            <MoneyLabel value={financial[name]} prefix={percentage ? "% " : undefined} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

PolicyFinancial.propTypes = { financial: PropTypes.objectOf(PropTypes.any) };
PolicyFinancial.defaultProps = { financial: {} };

export default PolicyFinancial;
