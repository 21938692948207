import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import CollapsibleBox from "../../../common/CollapsibleBox";
import Table from "../../../common/table/MaterialTable";
import { setExportTitleFileName } from "../../../../common";

export default () => {
  const {
    reserves: { historyDetails },
    number: claimNumber
  } = useSelector(
    ({
      claim: {
        reserves,
        claim: { number }
      }
    }) => ({ reserves, number }),
    shallowEqual
  );
  const [open, setOpen] = useState(true);
  const onToggle = () => setOpen(!open);
  const tableProps = {
    columns: [
      { title: "Type", field: "type" },
      { title: "Previous Amount", field: "previousAmount", type: "currency" },
      { title: "New Amount", field: "amountReserved", type: "currency" }
    ],
    options: {
      exportFileName: setExportTitleFileName(`${claimNumber}_ReserveHistory`)
    },
    data: historyDetails,
    hidePaging: historyDetails.length <= 5
  };
  return (
    <CollapsibleBox title="Reserve History" name="history" {...{ open, onToggle }}>
      <Table {...tableProps} />
    </CollapsibleBox>
  );
};
