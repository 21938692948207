import { Paper, Grid, Button } from "@material-ui/core";
import React, { useEffect, useContext, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllProcessingQueue } from "../../redux/actions/financial";
import { useLoading } from "../common/Loading";
import MaterialTable from "../common/table/MaterialTable";
import ActionsAllowed from "../common/contexts/ActionsAllowed";
import { setExportTitleFileName } from "../../common";

export default () => {
  const routesAllowed = useContext(ActionsAllowed);
  const dispatch = useDispatch();
  const data = useSelector(({ financial: { processingQueue } }) => processingQueue, shallowEqual);
  const {
    Loading,
    actions: { showLoading, hideLoading }
  } = useLoading(true);
  const access = routesAllowed.find(elem => elem.route === "processingqueue");
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(getAllProcessingQueue(hideLoading, hideLoading));
    }
  }, []);
  const pay = () => {};
  const [selection, setSelection] = useState([]);
  const getButtonToolBar = () => {
    if (!access.create) return null;
    return (
      <Grid item className="tc-pl1">
        <Button variant="contained" color="primary" onClick={() => pay(selection)}>
          ISSUE PAYMENT
        </Button>
      </Grid>
    );
  };
  const countByPage = 50;
  const tableProps = {
    title: "Payments Processing Queue",
    data,
    columns: [
      {
        title: "Client Name",
        field: "clientName",
        width: null,
        cellStyle: { whiteSpace: "nowrap", paddingRight: 0, width: 200 }
      },
      {
        title: "Claim",
        field: "claimNumber",
        render: row => {
          if (row?.claimAllowed) return <Link to={`/claim/${row.claimId}`}>{row.claimNumber}</Link>;
          return row.claimNumber;
        }
      },
      { title: "Payment Type", field: "type", width: null, cellStyle: { whiteSpace: "nowrap", paddingRight: 0, width: 200 } },
      { title: "Type", field: "reserveType", width: null, cellStyle: { whiteSpace: "nowrap", paddingRight: 0, width: 200 } },
      { title: "Description", field: "description", width: null, cellStyle: { whiteSpace: "nowrap", paddingRight: 0, width: 200 } },
      { title: "Status", field: "status" },
      { title: "Payee Name", field: "payeeName", width: null, cellStyle: { whiteSpace: "nowrap", paddingRight: 0, width: 200 } },
      { title: "Payee Type", field: "payeeType", width: null, cellStyle: { whiteSpace: "nowrap", paddingRight: 0, width: 200 } },
      { title: "Service Date", field: "serviceDate", type: "date" },
      { title: "Payment Date", field: "paymentDate", type: "date" }, // in the future
      { title: "Bill/Payment Amount", field: "amount" }
    ],
    hidePaging: data.length <= countByPage,
    options: { pageSize: countByPage, selection: true, exportFileName: setExportTitleFileName("FinancialPaymentsProcessingQueue") },
    ButtonToolBar: getButtonToolBar,
    onSelectionChange: newSelection => setSelection(newSelection),
    localization: {
      toolbar: { nRowsSelected: "{0} Bill(s) / Scheduled Payment(s) selected" }
    }
  };
  return (
    <>
      {Loading}
      <div className="tc-page-content">
        <Paper className="tc-p2">
          <MaterialTable {...tableProps} />
        </Paper>
      </div>
    </>
  );
};
