import PropTypes from "prop-types";
import React from "react";
import Notes from "../../common/lastNotes";
import Card from "../../common/overview/Card";

const LastNotes = ({ notes }) => (
  <Card title="Last Notes">
    <h5>{`Notes (${notes.length < 5 ? notes.length : notes.slice(0, 5).length})`}</h5>
    <Notes notes={notes.length < 5 ? notes : notes.slice(0, 5)} />
  </Card>
);

LastNotes.propTypes = { notes: PropTypes.arrayOf(PropTypes.object).isRequired };

export default LastNotes;
