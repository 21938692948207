import React from "react";
import PropTypes from "prop-types";
import { MenuList, MenuItem } from "@material-ui/core";

const Suggestions = ({ results, onChange }) => {
  const options = results.map(r => (
    <MenuItem key={r.id} onClick={() => onChange(r)}>
      {" "}
      {r.name}
    </MenuItem>
  ));
  return results.length > 0 ? <MenuList>{options}</MenuList> : <></>;
};

Suggestions.propTypes = {
  results: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  onChange: PropTypes.func.isRequired
};

export default Suggestions;
