import { Paper, Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { useOccurrenceCatastropheInfoStyles } from "./index.styles";
import LinkCount from "../../../common/LinkCount";

export default () => {
  const store = useSelector(({ claim: { claim } }) => claim, shallowEqual);
  const { occurrence, catastrophe, occurrenceClaimsCount } = store || {};
  const classes = useOccurrenceCatastropheInfoStyles();
  const dateLabel = (date, label) => {
    return (
      <Grid item className={classes.textEllipsis}>
        {label && <div className={classes.label}>{label}</div>}
        {moment(date).format(`M/D/YYYY`)}
      </Grid>
    );
  };
  const emptyLabel = message => <div className={classes.textEllipsis}>{message}</div>;
  return (
    <Paper className={classes.root}>
      <Grid container spacing={4} alignItems="flex-start" wrap="nowrap">
        <Grid item xs={6}>
          <div className={classes.label}>Occurrence</div>
          {(!occurrence && emptyLabel("No Occurrence")) || (
            <Grid container direction="column" spacing={2} wrap="nowrap">
              <Grid item className={classes.linkOcc} zeroMinWidth>
                <LinkCount count={occurrenceClaimsCount}>
                  <Grid item>
                    <Link to={`/occurrence/${occurrence.id}/info`}>{occurrence.number}</Link>
                  </Grid>
                </LinkCount>
              </Grid>
              <Grid item zeroMinWidth className={classes.textEllipsisWithoutNoWrap}>
                <b>{occurrence.name}</b>
              </Grid>
              {occurrence.date && dateLabel(occurrence.date, "Date")}
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}>
          <div className={classes.label}>Catastrophe</div>
          {(!catastrophe && emptyLabel("No Catastrophe")) || (
            <Grid container direction="column" spacing={2} wrap="nowrap">
              <Grid item zeroMinWidth className={classes.textEllipsis}>
                <Link to={`/catastrophe/${catastrophe.id}/info`}>{catastrophe.number}</Link>
              </Grid>
              <Grid item zeroMinWidth className={classes.textEllipsisWithoutNoWrap}>
                <b>{catastrophe.name}</b>
              </Grid>
              {(catastrophe.dateSince || catastrophe.dateTo) && (
                <Grid item className={classes.textEllipsis}>
                  <Grid container spacing={2}>
                    {catastrophe.dateSince && dateLabel(catastrophe.dateSince, "Since")}
                    {catastrophe.dateTo && dateLabel(catastrophe.dateTo, "To")}
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
