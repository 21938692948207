import React, { useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import qs from "qs";
import { toast } from "react-toastify";
import { Grid, Typography, Avatar } from "@material-ui/core";
import PropTypes from "prop-types";
import { SelectInput, TextInput, CheckboxLabel, PercentInput, MoneyInput } from "../../../../common/inputs";
import LabelValue from "../../../../common/LabelValue";
import InteractiveList from "../../../../common/list/InteractiveList";
import Search from "../../../../common/SearchSuggestions/InputSuggestionsDebounced";
import { API } from "../../../../../redux/actions/actionTypes";

const Benefits = ({ benefits, readOnly, onEdit, onAdd, onDelete, separateChecks, setSepatateCheks, totalAmount, validationMsg }) => {
  const claimantData = useSelector(({ claim }) => claim.claimant, shallowEqual);
  const payeeTypes = useMemo(
    () => [
      { id: 1, text: "Claimant" },
      { id: 2, text: "Vendor" }
    ],
    []
  );

  const getAddress = loc => {
    return `${loc.address1 || ""} ${loc.address1 ? "," : ""} ${loc.city || ""}${loc.city && (loc.state || loc.zipCode) ? ", " : ""}${loc.state ||
      ""}${loc.state ? " " : ""}${loc.zipCode || ""}`.trim();
  };
  const inputProps = (benefit, path, label) => {
    return {
      name: `${path}-${benefit.index}`,
      error: (validationMsg || {})[`paymentBenefitFull[${benefit.index}].${path}`],
      value: benefit[path],
      onChange: ({ target: { value } }) => {
        switch (path) {
          case "payeeTypeId":
            if (value === 1 && !benefits.some(e => e.claimantId === claimantData.id))
              onEdit({
                ...benefit,
                vendorId: null,
                claimantId: value === 1 ? claimantData.id : null,
                payee: value === 1 ? claimantData.fullName : undefined,
                name: value === 1 ? claimantData.fullName : undefined,
                fullAddress: value === 1 ? getAddress(claimantData.location) : undefined,
                [path]: value
              });
            else toast.error("The claimant already in payees");
            break;
          case "percent":
            {
              const amount = totalAmount > 0 && value > 0 ? (totalAmount * value) / 100 : 0;
              onEdit({ ...benefit, amount, [path]: value });
            }
            break;
          case "name":
            onEdit({ ...benefit, [path]: value, payee: value, vendorId: null, fullAddress: undefined });
            break;
          default:
            onEdit({ ...benefit, [path]: value });
        }
      },
      label
    };
  };
  //----------------------------------------
  const onSelect = (op, searchName) => {
    const { address1, address2, city, stateId, state, zipCode } = op || {};
    const ben = benefits.find(e => e.index === parseInt(searchName.split("-")[1], 10));
    if (!benefits.some(e => e.vendorId === op.id))
      onEdit({
        ...ben,
        name: op.name,
        vendorId: op.id,
        claimantId: null,
        fullAddress: getAddress({ address1, address2, city, stateId, state, zipCode }),
        payee: ""
      });
    else toast.error("The vendor already in payees");
  };
  const axiosConfig = { paramsSerializer: params => qs.stringify(params, { indices: false }) };
  //----------------------------------------
  const getRow = (elem, i) => (
    <Grid key={`${elem.index}-detail`} container spacing={2}>
      <Grid item>
        <Avatar style={{ width: "1.7rem", height: "1.7rem", backgroundColor: "#014b65", fontSize: "1rem" }}>{i ? i + 1 : 1}</Avatar>
      </Grid>
      <Grid item xs={12} md={2} className="tc-no-padding-tb">
        <SelectInput
          {...inputProps(elem, "payeeTypeId", "Payee Type", claimantData)}
          options={payeeTypes}
          value={elem.payeeTypeId || (elem.claimantId ? 1 : 2)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} md={3} className="tc-no-padding-tb">
        {elem.payeeTypeId === 1 || elem.claimantId ? (
          <TextInput name={`${elem.index}-claimantFullName`} value={claimantData.fullName} label="Payee" disabled />
        ) : (
          <Search
            {...inputProps(elem, "name", "Payee")}
            queryParams={{ searchText: elem.name }}
            axiosConfig={axiosConfig}
            url={`${API.URL}/bill/getAllvendor`}
            onSelect={onSelect}
            disabled={readOnly}
          />
        )}
      </Grid>
      <Grid item xs={12} md={3} className="tc-no-padding-tb">
        <LabelValue label="Physical Address 1" value={elem.fullAddress} style={{ marginBottom: 0 }} disabled={readOnly} />
      </Grid>
      <Grid item xs={12} md={1}>
        <PercentInput {...inputProps(elem, "percent", "Percent")} disabled={readOnly} />
      </Grid>
      <Grid item xs={12} md={2}>
        <MoneyInput {...inputProps(elem, "amount", "Amount")} disabled />
      </Grid>
    </Grid>
  );

  const payeeList = benefits.map((el, i) => ({
    id: i + 1,
    ...el,
    key: `${i}-benefit-info`,
    primary: getRow(el, i)
  }));

  const headerPayeeList = () => {
    if (benefits.length > 1)
      return (
        <CheckboxLabel
          checked={separateChecks}
          value={separateChecks || undefined}
          name="separateChecks"
          onChange={({ target: { value } }) => {
            setSepatateCheks(ps => ({ ...ps, separateChecks: value }));
          }}
          disabled={readOnly}
          label="Separate Checks"
        />
      );
    return <></>;
  };

  return (
    <>
      <Typography style={{ color: "#428302", display: "inline-block", fontWeight: 500, fontSize: "1rem" }}>Payees</Typography>
      <InteractiveList
        items={payeeList}
        addAction={
          !readOnly
            ? () => {
                onAdd({
                  payeeTypeId: 2,
                  name: "",
                  claimantId: null,
                  vendorId: null,
                  fullAddress: "",
                  percent: undefined,
                  amount: undefined
                });
              }
            : undefined
        }
        deleteAction={
          !readOnly
            ? row => {
                onDelete(row);
              }
            : undefined
        }
        style={{ width: "100%", maxHeight: 150 }}
        Header={headerPayeeList}
        addBtnText="Add Payee"
      />
    </>
  );
};

Benefits.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.shape({})),
  onEdit: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  access: PropTypes.shape({}),
  readOnly: PropTypes.bool,
  separateChecks: PropTypes.bool,
  setSepatateCheks: PropTypes.func.isRequired,
  totalAmount: PropTypes.number,
  validationMsg: PropTypes.shape({})
};

Benefits.defaultProps = {
  access: { read: true, create: true, update: true, delete: true },
  readOnly: false,
  benefits: [],
  separateChecks: false,
  totalAmount: 0,
  validationMsg: {}
};
export default Benefits;
