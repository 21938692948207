import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { get } from "../../../redux/actions/initialReport/activity";
import { useRouteParam } from "../../../common";
import ClaimActivity from "../../common/claims/ClaimActivity";
import ActionsAllowedContext from "../../common/contexts/ActionsAllowed";
import { useLoadingContext } from "../../common/Loading";

const Activity = () => {
  const claimId = useRouteParam("claimId");
  const data = useSelector(
    ({
      initialReport: {
        activity,
        claim: { number: claimNumber }
      }
    }) => ({ activity, claimNumber }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "activity");
  const {
    actions: { showLoading, hideLoading }
  } = useLoadingContext();
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);

  return <ClaimActivity activities={data?.activity || []} claimNumber={data?.claimNumber} />;
};

export default Activity;
