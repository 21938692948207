import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import clsx from "clsx";
import styles from "./index.style";

const LastNotes = ({ notes }) => {
  const classes = styles();

  return (
    <>
      {notes.map(n => (
        <div key={n.id} className={clsx(classes.root, classes.note)}>
          <h4 className={classes.title}>{n.title}</h4>
          <p>{n.note}</p>
          <small className={classes.date}>
            {`${moment(n.createdOn).format("M/D/YYYY")} at ${moment(n.createdOn).format("H:mm a")} by ${n.createdByName}`}
          </small>
        </div>
      ))}
    </>
  );
};

LastNotes.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default LastNotes;
