import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { propByToolbar, propsByToolbar } from "../../common";

const useStyles = width =>
  makeStyles(
    theme => ({
      root: {
        backgroundColor: "#ffffff",
        padding: theme.spacing(2.75, 0),
        width: 0,
        height: "100%",
        overflowX: "hidden",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.4)",
        flexGrow: 0,
        flexShrink: 0,
        zIndex: 1200,
        transition: theme.transitions.create("width", {
          duration: theme.transitions.duration.complex
        }),
        "& > div": { width: width - 40, margin: "0 20px", height: "100%" }
      },
      absolute: {
        position: "absolute",
        top: 0,
        right: 0,
        ...propByToolbar(theme, "paddingTop"),
        paddingBottom: "0 !important",
        "& > div": { margin: "0 !important", width: `${width}px !important` }
      },
      fixed: {
        position: "fixed",
        right: 0,
        paddingBottom: "0 !important",
        ...propsByToolbar(theme, ["top", "height"], (v, p) => (p === "height" ? `calc(100% - ${v}px)` : v)),
        paddingTop: 0,
        "& > div": { margin: "0 !important", width: `${width}px !important` }
      },
      open: { width }
    }),
    { name: "sidepanel" }
  )();

const SidePanel = ({ open, className, width, absolute, fixed, ...other }) => {
  const classes = useStyles(width);
  const css = clsx(classes.root, {
    [classes.absolute]: absolute,
    [className]: Boolean(className),
    [classes.open]: open,
    [classes.fixed]: fixed
  });
  return (
    <div className={css}>
      <div {...other} />
    </div>
  );
};

SidePanel.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.number,
  absolute: PropTypes.bool,
  fixed: PropTypes.bool
};

SidePanel.defaultProps = {
  open: false,
  className: null,
  width: 280,
  absolute: false,
  fixed: false
};

export default SidePanel;
