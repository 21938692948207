import { API, ADMIN } from "../../../actions/actionTypes";
import { postSuccess, getAllSuccess, setSuccess, delSuccess } from "../../../actions/admin/noteTypeSetting";
import { takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "../../common/sagaGenerator";

const url = `${API.URL}/claimsetting/noteType`;

export default [
  takeLatestPost({
    type: ADMIN.NOTE_TYPE.POST,
    url,
    actionSuccess: postSuccess,
    successMessage: "Note type successfully added.",
    errorMessage: "Unable to add the information.",
    params: ({ data }) => data
  }),
  takeLatest({
    type: ADMIN.NOTE_TYPE.GETALL,
    url,
    actionSuccess: getAllSuccess,
    errorMessage: "Unable to get the information."
  }),
  takeLatestSet({
    type: ADMIN.NOTE_TYPE.SET,
    url,
    actionSuccess: setSuccess,
    successMessage: "Note type successfully updated.",
    errorMessage: "Unable to update the information.",
    params: ({ data }) => data
  }),
  takeLatestDel({
    type: ADMIN.NOTE_TYPE.DEL,
    url,
    params: ({ id }) => ({ id }),
    actionSuccess: delSuccess,
    successMessage: "Note type successfully deleted.",
    errorMessage: "Unable to delete the information."
  })
];
