import React from "react";
import PropTypes from "prop-types";
import { IconButton, CircularProgress, Tooltip } from "@material-ui/core";
import { Check, GetApp, NoteAdd } from "@material-ui/icons";
import clsx from "clsx";
import { useProgressButtonStyles } from "./hooks";

const ProgressButton = ({ onClick, loading, progress, upload }) => {
  const classes = useProgressButtonStyles();
  const complete = loading && progress === 100;
  return (
    <div className={classes.wrapper}>
      <Tooltip title={upload ? "Upload files" : "Download files"}>
        <IconButton onClick={onClick} color={upload ? "default" : "primary"} className={clsx(classes.button, { [classes.buttonSuccess]: complete })}>
          {(complete && <Check />) || (upload && <NoteAdd />) || <GetApp />}
        </IconButton>
      </Tooltip>
      {loading && !complete && <CircularProgress size={40} value={progress} className={classes.fabProgress} />}
    </div>
  );
};

export const progressProps = {
  loading: PropTypes.bool,
  progress: PropTypes.number
};

ProgressButton.propTypes = {
  onClick: PropTypes.func,
  upload: PropTypes.bool,
  ...progressProps
};

ProgressButton.defaultProps = {
  onClick: undefined,
  upload: false
};

export default ProgressButton;
