import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../common/CollapsibleBox";
import { get, set } from "../../../redux/actions/initialReport/damagedVehicle";
import { useRouteParam } from "../../../common";
import EditView from "../../common/claims/damagedVehicle/Form";
import ViewMode from "../../common/claims/damagedVehicle/Info";
import ActionsAllowedContext from "../../common/contexts/ActionsAllowed";

export default props => {
  const store = useSelector(({ initialReport: { information } }) => information, shallowEqual);
  const { damagedVehicle } = store || {};
  const claimId = useRouteParam("claimId");
  const dispatch = useDispatch();
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info");
  const {
    props: { boxProps, editMode, loading: isLoading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  const handleSubmit = data => {
    if (access.update) {
      showLoading();
      dispatch(set(data, onSuccessEdit, hideLoading));
    }
  };
  return (
    <CollapsibleBox title="Damaged Vehicle" name="damagedV" {...boxProps} {...props}>
      {Loading}
      {(editMode && <EditView {...{ damagedVehicle, onClose: onToggleEdit, onSave: handleSubmit, isLoading }} />) || (
        <ViewMode {...{ damagedVehicle }} />
      )}
    </CollapsibleBox>
  );
};
