import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    background: "none",
    color: "inherit",
    border: "none",
    padding: 0,
    font: "inherit",
    cursor: "pointer",
    outline: "inherit",
    textAlign: "inherit"
  },
  blue: { color: "#107A98 !important" },
  underline: { textDecoration: "underline" },
  disabled: { color: "rgba(0, 0, 0, 0.36) !important", cursor: "initial !important" }
}));

const LinkButton = forwardRef(({ onClick, className, blue, underline, disabled, ...props }, ref) => {
  const classes = useStyles();
  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onClick === "function") onClick(event);
  };
  return (
    <button
      {...{
        type: "button",
        className: clsx(classes.root, className, { [classes.blue]: blue && !disabled, [classes.disabled]: disabled, [classes.underline]: underline }),
        onClick: handleClick,
        disabled,
        ref,
        ...props
      }}
    />
  );
});

LinkButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  blue: PropTypes.bool,
  underline: PropTypes.bool,
  disabled: PropTypes.bool
};

LinkButton.defaultProps = {
  onClick: undefined,
  className: undefined,
  blue: false,
  underline: false,
  disabled: false
};

export default LinkButton;
