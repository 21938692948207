import React, { useContext, Fragment } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Indexing from "../../components/document/Indexing";
import Reindexing from "../../components/document/Reindexing";
import Review from "../../components/document/Review";
import PageNotAllowed from "../../components/PageNotAllowed";
import ActionsAllowedContext from "../../components/common/contexts/ActionsAllowed";
import Loading from "../../components/common/Loading";

const Routes = props => {
  const { path } = useRouteMatch();
  const routesAllowed = useContext(ActionsAllowedContext);
  const allowed = a => routesAllowed.find(e => e.route === a)?.read;
  return (
    <Fragment key="route-fragment">
      {routesAllowed.length <= 0 ? (
        <Loading />
      ) : (
        <Switch>
          <Route path={`${path}/indexing`} render={() => (allowed("indexing") && <Indexing {...props} />) || <PageNotAllowed />} />
          <Route path={`${path}/reindexing`} render={() => (allowed("reindexing") && <Reindexing />) || <PageNotAllowed />} />
          <Route path={`${path}/review`} render={() => (allowed("review") && <Review />) || <PageNotAllowed />} />
        </Switch>
      )}
    </Fragment>
  );
};
export default Routes;
