import { Grid } from "@material-ui/core";
import { Folder, Image, InsertDriveFile, PictureAsPdf } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";

export const parseFiletype = type => ({
  image: type.indexOf("image/") === 0 || type.indexOf("video/") === 0 || ["PNG", "JPG", "JPEG", "PNG"].indexOf(type) >= 0,
  pdf: ["application/pdf", "PDF"].indexOf(type) >= 0
});

const FileIcon = ({ file, type, ...props }) => {
  if (!file) return <Folder {...props} />;
  if (!type) return <InsertDriveFile {...props} />;
  const { image, pdf } = parseFiletype(type);
  if (image) return <Image {...props} />;
  if (pdf) return <PictureAsPdf {...props} />;
  return <InsertDriveFile {...props} />;
};
FileIcon.propTypes = { file: PropTypes.bool, type: PropTypes.string };
FileIcon.defaultProps = { file: false, type: undefined };

export const FileNameIcon = ({ file, type, name, cssIcon, cssName, ...other }) => (
  <Grid container spacing={1} alignItems="center" {...other}>
    <Grid item className={cssIcon || "tc-lh-1"}>
      <FileIcon {...{ file, type }} />
    </Grid>
    {name && (
      <Grid item className={cssName}>
        {name}
      </Grid>
    )}
  </Grid>
);

FileNameIcon.propTypes = {
  type: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cssIcon: PropTypes.string,
  cssName: PropTypes.string,
  file: PropTypes.bool
};
FileNameIcon.defaultProps = { type: undefined, name: undefined, cssIcon: undefined, cssName: undefined, file: true };

export default FileIcon;
