import { Paper, Grid } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Pie } from "react-chartjs-2";
import { useSelector, shallowEqual } from "react-redux";
import Bar from "../../../common/BarProgress";
import Money from "../../../common/MoneyLabel";
import { useReservesFinancialInfoStyles, useReserveBreakdownStyles } from "./index.styles";

const label = p => {
  let perc = p.toFixed(2);
  if (perc.substr(perc.length - 3) === ".00") perc = perc.substr(0, perc.length - 3);
  return `${perc}%`;
};

const Table = ({ classes, details, amountReserved }) => (
  <table className={classes.table}>
    <tbody>
      {details.map(({ typeId, type, amountReserved: amount }, i) => {
        const percent = amountReserved > 0 ? amount / amountReserved : 0;
        const colors = ["#EADF00", "#73B25D", "#426B3B", "#1599BC", "#004763", "#898F91"];
        return (
          <tr key={typeId}>
            <td>{type}</td>
            <td>
              <Bar {...{ classes, color: colors[i], percent, tooltip: <Money value={amount} /> }} />
            </td>
            <td>{label(percent * 100)}</td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

Table.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  details: PropTypes.arrayOf(PropTypes.object).isRequired,
  amountReserved: PropTypes.number
};
Table.defaultProps = { amountReserved: 0 };

export const ReserveBreakdown = useReserveBreakdownStyles(Table);

export default () => {
  const store = useSelector(({ claim: { reserves } }) => reserves, shallowEqual);
  const { id, amountReserved, totalPaid, outstandingReserve, currentDetails } = store || {};
  const classes = useReservesFinancialInfoStyles();
  const details = currentDetails.filter(x => x.isActive && x.amountReserved > 0 && x.status === "Accepted");
  const any = id && details.some(() => true);
  const tPaidOutstadingR = totalPaid + outstandingReserve;
  const totalPercent = tPaidOutstadingR > 0 ? (totalPaid / tPaidOutstadingR) * 100 : 0;
  const outstandingPercent = tPaidOutstadingR > 0 ? (outstandingReserve / tPaidOutstadingR) * 100 : 0;
  const data = {
    labels: [label(totalPercent), label(outstandingPercent)],
    datasets: [
      {
        data: [totalPercent, outstandingPercent],
        backgroundColor: ["#1599BC", "#A7CE05"],
        hoverBackgroundColor: ["#1599BC", "#A7CE05"],
        borderColor: ["#1599BC", "#A7CE05"],
        borderWidth: [1, 1]
      }
    ]
  };
  const options = {
    legend: { display: false },
    tooltips: {
      callbacks: {
        label: (i, d) => d.labels[i.index]
      },
      displayColors: false
    }
  };
  return (
    <Paper className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs>
          <Grid container spacing={2} style={{ height: "100%" }}>
            <Grid item>
              <div className={classes.title}>Outstanding Reserve</div>
              {any && <div className={classes.label}>Total</div>}
              <div className={clsx(classes.amount, any ? null : classes.noReserve)}>
                {any ? <Money value={amountReserved} /> : "(No Reserve Set)"}
              </div>
              <div className={classes.label}>Outstanding Reserve</div>
              <div className={classes.amount}>
                {(any && (
                  <>
                    <span className={clsx(classes.mark, classes.outstanding)} />
                    <Money value={outstandingReserve} />
                  </>
                )) ||
                  "--"}
              </div>
              <div className={classes.label}>Total Paid</div>
              <div className={classes.amount}>
                {(any && (
                  <>
                    <span className={clsx(classes.mark, classes.total)} />
                    <Money value={totalPaid} />
                  </>
                )) ||
                  "--"}
              </div>
            </Grid>
            {any && (
              <Grid item style={{ paddingTop: 16 }}>
                <Pie data={data} options={options} width={150} />
              </Grid>
            )}
            {!any && (
              <svg width={150} viewBox="0 0 74 74" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(38,38)">
                  <circle r="34" style={{ fill: "#C1C0C0" }} />
                </g>
              </svg>
            )}
          </Grid>
        </Grid>
        <Grid item xs>
          <div className={classes.title} style={any ? { marginBottom: 15 } : null}>
            Reserve Breakdown
          </div>
          {(any && <ReserveBreakdown {...{ details, amountReserved }} />) || (
            <div className={clsx(classes.amount, classes.noReserve)}>(No Reserve Set)</div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
