import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Paper, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import moment from "moment";
import MaterialTable from "../../common/table/MaterialTable";
import { loadPayments } from "../../../redux/actions/vendor";
import { useLoadingContext } from "../../common/Loading";
import { setExportTitleFileName } from "../../../common";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(2)
  }
}));

const PaymentList = ({ vendorId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    actions: { showLoading, hideLoading }
  } = useLoadingContext();

  useEffect(() => {
    showLoading();
    dispatch(loadPayments(vendorId, hideLoading, hideLoading));
  }, []);

  const data = useSelector(({ vendor: { payments } }) => payments, shallowEqual);

  const vendorName = useSelector(
    ({
      vendor: {
        profile: {
          basicInformation: { fullName }
        }
      }
    }) => fullName,
    shallowEqual
  );

  const tableProps = {
    columns: [
      { title: "Claim Related", field: "claimNumber" },
      { title: "Bill #", field: "id" },
      { title: "Type", field: "type" },
      {
        title: "Date of Service",
        field: "serviceDate",
        render: rowData => (rowData.serviceDate ? moment(rowData.serviceDate).format("M/D/YYYY") : "")
      },
      { title: "Description", field: "description" },
      {
        title: "Bill Amount",
        field: "amount",
        render: rowData => {
          return `$ ${(rowData?.amount || 0).toFixed(2)}`;
        }
      },
      {
        title: "Total Paid",
        field: "totalPaid",
        render: rowData => {
          return `$ ${(rowData?.totalPaid || 0).toFixed(2)}`;
        }
      },
      { title: "Payment Date", field: "paymentDate", render: rowData => (rowData.paymentDate ? moment(rowData.paymentDate).format("M/D/YYYY") : "") },
      { title: "Status", field: "status" },
      { title: "Method", field: "paymentMethod" },
      { title: "Check Number", field: "checkNumber" },
      { title: "Location", field: "vendorName" }
    ],
    data,
    options: { exportFileName: setExportTitleFileName(`${vendorName}_Payments`) },
    hidePaging: true
  };

  return (
    <Paper className={classes.root}>
      <MaterialTable {...tableProps} />
    </Paper>
  );
};

PaymentList.propTypes = { vendorId: PropTypes.number.isRequired };

export default PaymentList;
