import React from "react";
import { Paper } from "@material-ui/core";
import { useContext, actionTypes } from "../context";
import { useWitnessStyles } from "./index.styles";
import { BaseClaimWitness } from "../../../common/claims/ClaimWitness";

export default () => {
  const classes = useWitnessStyles();
  const [{ witness }, dispatch] = useContext();
  const onSave = payload =>
    dispatch({
      type: actionTypes.SET_WITNESS,
      payload: {
        ...payload,
        phone: payload?.phone?.target ? payload?.phone?.target?.value : payload?.phone,
        phone2: payload?.phone2?.target ? payload?.phone2?.target?.value : payload?.phone2
      }
    });
  const onDelete = ({ id }) => dispatch({ type: actionTypes.DELETE_WITNESS, payload: id });
  return (
    <Paper className={classes.root}>
      <BaseClaimWitness tableTitle="Witness" data={witness} add={onSave} update={onSave} remove={onDelete} hideExportButton />
    </Paper>
  );
};
