import { Chip, Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import React, { memo } from "react";
import { fileSize } from "../../../../common";
import FileIcon from "../../../common/documents/FileIcon";
import FileInput, { onIdClick } from "../../../common/documents/FileInput";
import { InputText, SelectInput } from "../../../common/inputs";
import LabelValue from "../../../common/LabelValue";
import { useIndexInfo } from "./hooks";

const IndexInfo = memo(props => {
  const { noteTypes, inputProps, onUploadChange, onDeleteFile, id, indexItem } = useIndexInfo(props);
  const { fileName, fileType, folders, size, uploadedByName, note } = indexItem;
  const { file } = note;
  return (
    <>
      <Typography className="tc-title">Document Details</Typography>
      <Grid container spacing={2} wrap="nowrap" alignItems="center" className="tc-mb0">
        <Grid item xs={6}>
          <Grid container spacing={1} alignItems="center" wrap="nowrap">
            <Grid item>
              <FileIcon file type={fileType} />
            </Grid>
            <Grid item className="tc-ellipsis">
              {fileName}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <LabelValue label="Size" value={fileSize(size)} className="tc-mb0" />
        </Grid>
        <Grid item xs={3}>
          <LabelValue label="Uploaded By" value={uploadedByName} className="tc-mb0" />
        </Grid>
      </Grid>
      <InputText {...inputProps("Description", "description")} />
      <Grid container>
        <Grid item xs={6}>
          <SelectInput options={folders} {...inputProps("Folder", "folderId")} />
        </Grid>
      </Grid>
      <Divider className="tc-mb2" />
      <Typography className="tc-title" gutterBottom>
        Add Note
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <SelectInput options={noteTypes} {...inputProps("Note Type", "typeId", true)} allowClear />
        </Grid>
      </Grid>
      <InputText {...inputProps("Title", "title", true)} />
      <InputText {...inputProps("Description", "note", true)} rows={2} />
      <Grid container spacing={1} direction="row-reverse" alignItems="center">
        <Grid item className="tc-pb2">
          <IconButton onClick={onIdClick(id)}>
            <AttachFile fontSize="small" />
          </IconButton>
          <FileInput onChange={onUploadChange} {...{ id }} multiple={false} />
        </Grid>
        {file && (
          <Grid item className="tc-pb2">
            <Chip icon={<FileIcon file type={file.type || file.fileType} />} label={fileName} onDelete={onDeleteFile} />
          </Grid>
        )}
      </Grid>
    </>
  );
});

export default IndexInfo;
