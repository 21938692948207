import { makeStyles } from "@material-ui/core";

const base = theme => ({
  actionsFooter: { margin: `${theme.spacing(1, 0)} !important` },
  root: { padding: theme.spacing(1, 2), marginBottom: theme.spacing(4) },
  formControl: { width: "100%" },
  labelControl: { position: "relative" }
});

const sp = bottom => ({ content: '""', position: "absolute", [`border${bottom ? "Bottom" : "Left"}`]: "solid 1px #e0e0e0" });

export const useCommStyles = makeStyles(theme => ({
  ...base(theme),
  attPopperContainer: { width: 0, height: 0, "& > div": { zIndex: 1 } },
  attPopperContainerInitial: { "& > div": { position: "initial !important" } },
  attTreeContainer: {
    padding: theme.spacing(1),
    backgroundColor: "#FFF",
    maxHeight: "calc(100vh - 140px)",
    overflow: "hidden",
    "overflow-y": "auto"
  },
  attUl: {
    listStyle: "none",
    margin: 0,
    padding: 0,
    "& > li:not(:first-of-type)": {
      paddingLeft: 38,
      position: "relative",
      "&:before": { ...sp(), left: 19, height: "100%" },
      "&:after": { ...sp(true), left: 19, top: 0, height: 19, width: 28 },
      "&:last-of-type:before": { height: 19 },
      "&.att-file:after": { width: 20 }
    }
  },
  attFolder: {
    width: 38,
    height: 38,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:before": { ...sp(), top: 27, left: "50%", height: "50%" }
  }
}));

export default makeStyles(base);
