import React from "react";

const TerraMaps = () => {
  return (
    <iframe
      style={{ height: "973px", width: "1808px", border: "none" }}
      title="terraClaim Dashboard"
      src="https://xd.adobe.com/embed/d9e02da6-a911-4895-89c0-155619114544-8cb3/?fullscreen"
    />
  );
};

export default TerraMaps;
