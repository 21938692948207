import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles, Collapse, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
import { useDebounce } from "../../common";
import { API } from "../../redux/actions/actionTypes";
import { SearchInput } from "../common/inputs";
import LinkButton from "../common/LinkButton";

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => {
  return {
    SearchInput: {
      color: "white"
    },
    resultContainer: {
      width: "90%",
      minHeight: "400px",
      maxHeight: "min-content",
      overflow: "hidden",
      backgroundColor: "#F4F8F9",
      position: "absolute",
      left: "5%",
      top: theme.spacing(8)
    },
    resultContainer2: {
      position: "relative",
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    },
    resultDivContainer: {
      height: "auto",
      maxHeight: "80%",
      overflowY: "auto",
      overflowX: "hidden"
    },
    titleContainer: {
      borderBottom: "solid 0.5px #707070"
    },
    title: {
      display: "inline",
      color: "#004763"
    },
    subtitle: {
      color: "#898F91",
      display: "inline"
    },
    descriptionTitle: {
      color: "#898F91",
      display: "inline"
    },
    descriptionContent: {
      color: "black",
      display: "inline"
    },
    claimNumber: {
      color: "#107A98"
    },
    greenDot: {
      backgroundColor: "#428302",
      width: "5px",
      height: "5px",
      display: "inline",
      paddingRight: "5px",
      borderRadius: "50%",
      fontSize: "5px",
      verticalAlign: "middle"
    },
    processingStatusLabel: {
      display: "inline",
      color: "black",
      marginLeft: "2px"
    },
    processingStatusDiv: {
      backgroundColor: "#D2EEB6",
      "& svg": { color: "#003145" }
    },
    status: { borderRadius: "50%", padding: 5, width: "min-content", "& svg": { fontSize: 14 }, color: "black" },
    claim: { backgroundColor: "#A3B3B9", "& svg": { color: "#003145" } },
    open: { backgroundColor: "#D2EEB6", "& svg": { color: "#428302" } },
    close: { backgroundColor: "#FCB7B0", "& svg": { color: "#E74C3C" } },
    reopen: { backgroundColor: "#7C9DAA", "& svg": { color: "#004763", transform: "scaleX(-1)" } },
    voided: { backgroundColor: "#F6C096", "& svg": { color: "#FE7F1E" } },
    pending: { backgroundColor: "#FCF3BC", "& svg": { color: "#EFD10F" } },
    peopleRolLabel: {
      color: "black",
      fontWeight: "bold"
    },
    claimLink: {
      color: "#107A98",
      fontWeight: "bold"
    }
  };
});

// eslint-disable-next-line no-unused-vars
const SearchBar = props => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [{ filter, results }, setState] = useState({});
  const [searchResultOpen, setSearchResultOpen] = useState(false);
  // const { openModal } = props;
  const globalSearch = useDebounce(query => axios.get(`${API.URL}/claim/globalsearch/${query}`), 500);

  useEffect(() => {
    if (String(filter || "").length >= 3) {
      globalSearch(filter).then(({ data }) => setState(ps => ({ ...ps, results: data })));
      setSearchResultOpen(true);
    } else {
      setState(ps => ({ ...ps, results: undefined }));
      setSearchResultOpen(false);
    }
  }, [filter]);
  const textStatus = (css, text) => {
    return <div className={clsx(classes.status, css)}>{text}</div>;
  };
  const switchStatus = status => {
    switch (status) {
      case "Claim":
        return textStatus(classes.claim, status);
      case "Open":
        return textStatus(classes.open, status);
      case "Closed":
        return textStatus(classes.close, status);
      case "Reopened":
        return textStatus(classes.reopen, status);
      case "Voided":
        return textStatus(classes.voided, status);
      case "Pending":
        return textStatus(classes.pending, status);
      default:
        return null;
    }
  };

  const history = useHistory();
  const redirectToClient = async clientId => {
    const clientUrl = await axios.get(`${API.URL}/client/getClientRoute/?clientId=${clientId}`);
    setState({ filter: "", results: null });
    history.push(`/${clientUrl.data.urlToClient}`);
  };
  const redirectToClaim = async claimId => {
    setState({ filter: "", results: null });
    history.push(`/claim/${claimId}`);
  };
  const redirectToVendor = async vendorId => {
    setState({ filter: "", results: null });
    history.push(`/vendor/${vendorId}/overview`);
  };
  const showClientDependant = person => {
    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.peopleRolLabel}>{`${person.rol}:${person.fullName}`}</div>
          </Grid>
          <Grid item xs={12}>
            <LinkButton className={classes.claimLink} onClick={() => redirectToClient(person.dependencyId)}>
              {`${person.dependency}: ${person.description}`}
            </LinkButton>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.processingStatusLabel}>{`Phone Number:${person.phone}`}</div>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const showClaimDependant = person => {
    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.peopleRolLabel}>{`${person.rol}:${person.fullName}`}</div>
          </Grid>
          <Grid item xs={12}>
            <LinkButton className={classes.claimLink} onClick={() => redirectToClaim(person.dependencyId)}>
              {`${person.dependency}: ${person.description}`}
            </LinkButton>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.processingStatusLabel}>{`Phone Number:${person.phone}`}</div>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <SearchInput
        className={classes.SearchInput}
        name="filter"
        value={filter}
        onChange={({ target: { value } }) => setState(ps => ({ ...ps, filter: value }))}
      />
      <Collapse in={searchResultOpen} timeout="auto">
        <div className={`tc-box-content ${classes.resultContainer}`}>
          <div className={` ${classes.resultContainer2}`}>
            <Grid container spacing={4}>
              <Grid item xs>
                <div className={classes.titleContainer}>
                  <Typography className={classes.title} variant="h6">
                    Claims
                  </Typography>
                  <div className={classes.subtitle}>{` | ${results ? results.claims?.length : 0} results`}</div>
                </div>
                <div className={classes.resultDivContainer}>
                  <Grid container spacing={1}>
                    {results &&
                      results.claims.map(claim => {
                        return (
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12}>
                                <LinkButton
                                  className={classes.claimLink}
                                  onClick={() => {
                                    redirectToClaim(claim.id);
                                  }}
                                >
                                  {claim.number}
                                </LinkButton>
                              </Grid>
                              <Grid item xs={4}>
                                {switchStatus(claim.status)}
                              </Grid>
                              <Grid item xs={8}>
                                <div className={clsx(classes.status, classes.processingStatusDiv)}>
                                  <span className={classes.greenDot}>{` `}</span>
                                  <div className={classes.processingStatusLabel}>{claim.processingStatus}</div>
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.descriptionTitle}>Claimant:</div>
                                <div className={classes.descriptionContent}>{claim.claimantName}</div>
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.descriptionTitle}>Insured:</div>
                                <div className={classes.descriptionContent}>{claim.insuredName}</div>
                              </Grid>
                              <Grid item xs={4}>
                                <div className={classes.descriptionTitle}>LOB:</div>
                                <div className={classes.descriptionContent}>{claim.claimLineOfBusinessCode}</div>
                              </Grid>
                              <Grid item xs={8}>
                                <div className={classes.descriptionTitle}>Date of Loss:</div>
                                <div className={classes.descriptionContent}>{moment(claim.dateofLoss).format("M/D/YYYY")}</div>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                </div>
              </Grid>
              <Grid item xs>
                <div className={classes.titleContainer}>
                  <Typography className={classes.title} variant="h6">
                    People
                  </Typography>
                  <div className={classes.subtitle}>{` | ${results ? results.persons?.length : 0} results`}</div>
                </div>
                <div className={classes.resultDivContainer}>
                  <Grid container spacing={1}>
                    {results &&
                      results.persons.map(person => {
                        switch (person.rol) {
                          case "Employee":
                            return showClientDependant(person);
                          case "Contact":
                            return showClientDependant(person);
                          case "Adjuster":
                            return showClaimDependant(person);
                          case "Claimant":
                            return showClaimDependant(person);
                          case "Driver":
                            return showClaimDependant(person);
                          case "Attorney":
                            return showClaimDependant(person);
                          case "Witness":
                            return showClaimDependant(person);
                          default:
                            return showClientDependant(person);
                        }
                      })}
                  </Grid>
                </div>
              </Grid>
              <Grid item xs>
                <div className={classes.titleContainer}>
                  <Typography className={classes.title} variant="h6">
                    Clients
                  </Typography>
                  <div className={classes.subtitle}>{` | ${results ? results.clients?.length : 0} results`}</div>
                </div>
                <div className={classes.resultDivContainer}>
                  <Grid container spacing={1}>
                    {results &&
                      results.clients.map(client => {
                        return (
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12}>
                                <div className={classes.peopleRolLabel}>{`${client.number} ${client.name}`}</div>
                              </Grid>
                              <Grid item xs={12}>
                                <LinkButton className={classes.claimLink} onClick={() => redirectToClient(client.id)}>
                                  {`Client Type: ${client.type}`}
                                </LinkButton>
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.processingStatusLabel}>{`Phone Number:${client.phone}`}</div>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                </div>
              </Grid>
              <Grid item xs>
                <div className={classes.titleContainer}>
                  <Typography className={classes.title} variant="h6">
                    Vendors
                  </Typography>
                  <div className={classes.subtitle}>{` | ${results ? results.vendors?.length : 0} results`}</div>
                </div>
                <div className={classes.resultDivContainer}>
                  <Grid container spacing={1}>
                    {results &&
                      results.vendors.map(vendor => {
                        return (
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12}>
                                <div className={classes.peopleRolLabel}>{`${vendor.number} ${vendor.fullName}`}</div>
                              </Grid>
                              <Grid item xs={12}>
                                <LinkButton className={classes.claimLink} onClick={() => redirectToVendor(vendor.id)}>
                                  {`Category: ${vendor.category}`}
                                </LinkButton>
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.processingStatusLabel}>{`Phone Number:${vendor.phoneNumber}`}</div>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Collapse>
    </>
  );
};
export default SearchBar;
SearchBar.propTypes = {};

SearchBar.defaultProps = {};
