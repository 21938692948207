import { CLIENT, CLIENTR, CLIENT_DOCUMENTS } from "../actions/actionTypes";
import initialState from "./initialState";

const { client } = initialState;

const clientReducer = (state = client, action) => {
  const { type } = action;
  switch (type) {
    case "CLIENT.RESET_STATE":
      return { ...state, entityList: [] };
    case CLIENTR.LEVELS.GET_SUCCESS:
    case CLIENTR.SETTING.CLIENT_STRUCTURE.SET_SUCCESS:
      return { ...state, clientLevels: action.levels };
    case CLIENTR.ROUTES.GET_SUCCESS:
      return { ...state, breadCrumbRoutes: action.breadCrumbRoutes };
    case CLIENTR.SETTING.GET_SUCCESS:
    case CLIENTR.SETTING.RESERVE_PAYMENT.SET_SUCCESS:
    case CLIENTR.SETTING.BILL_REVIEW.SET_SUCCESS:
    case CLIENTR.SETTING.TEAM.SET_SUCCESS:
      return { ...state, settings: action.settings };
    case CLIENTR.POST_SUCCESS:
      return { ...state, clients: action.clients };
    case CLIENTR.INSUREDS.POST_SUCCESS:
      return { ...state, insureds: action.insureds };
    case CLIENTR.INSURED.EMPLOYEES.GET_SUCCESS:
    case CLIENTR.INSURED.EMPLOYEE.POST_SUCCESS:
    case CLIENTR.INSURED.EMPLOYEE.PUT_SUCCESS:
    case CLIENTR.INSURED.EMPLOYEE.DELETE_SUCCESS:
      return { ...state, employees: action.employees };
    case CLIENTR.LIST.GET_SUCCESS:
      return { ...state, clients: action.clients };
    case CLIENTR.ADDCLIENT.POST_SUCCESS:
    case CLIENTR.LIST.GET_ALL_BY_PARENT_SUCCESS:
      return { ...state, entityList: action.data };
    case CLIENTR.CONTACTS.GET_SUCCESS:
    case CLIENTR.CONTACTS.SET_SUCCESS:
    case CLIENTR.CONTACTS.POST_SUCCESS:
    case CLIENTR.CONTACTS.DEL_SUCCESS:
      return { ...state, contacts: action.contacts };

    case CLIENTR.PROFILE.GET_SUCCESS:
    case CLIENTR.PROFILE.BASIC.SET_SUCCESS:
    case CLIENTR.PROFILE.OPERATING_STATE.POST_SUCCESS:
    case CLIENTR.PROFILE.OPERATING_STATE.SET_SUCCESS:
    case CLIENTR.PROFILE.OPERATING_STATE.DEL_SUCCESS:
    case CLIENTR.PROFILE.STATUS.SET_SUCCESS:
    case CLIENTR.PROFILE.AGENT.SET_SUCCESS:
    case CLIENTR.PROFILE.AREA.POST_SUCCESS:
    case CLIENTR.PROFILE.AREA.SET_SUCCESS:
    case CLIENTR.PROFILE.AREA.DEL_SUCCESS:
      return { ...state, profile: { ...action.profile, financial: action.profile.financial ? action.profile.financial : [] } };
    case CLIENTR.INSURED.PROFILE.GET_SUCCESS:
      return { ...state, insuredProfile: { ...action.profile } };
    case CLIENTR.INSUREDS.DEL_SUCCESS:
      return { ...state, insureds: state.insureds.filter(i => i.id !== action.id) };
    case CLIENT.NOTES.GET_SUCCESS:
    case CLIENT.NOTES.POST_SUCCESS:
    case CLIENT.NOTES.SET_SUCCESS:
    case CLIENT.NOTES.DEL_SUCCESS:
      return { ...state, notes: action.notes };
    case CLIENT.NOTES.GET_DRAFT_SUCCESS:
    case CLIENT.NOTES.POST_DRAFT_SUCCESS:
      return { ...state, draftNote: action.draft };
    case CLIENTR.BANKING.GET_SUCCESS:
    case CLIENTR.BANKING.SET_SUCCESS:
    case CLIENTR.BANKING.DEL_SUCCESS:
    case CLIENTR.BANKING.POST_SUCCESS:
      return { ...state, accounts: action.accounts };
    case CLIENT_DOCUMENTS.GET_SUCCESS:
    case CLIENT_DOCUMENTS.DEL_FILE_SUCCESS:
    case CLIENT_DOCUMENTS.SET_FILE_SUCCESS:
    case CLIENT_DOCUMENTS.POST_FOLDER_SUCCESS:
    case CLIENT_DOCUMENTS.SET_FOLDER_SUCCESS:
    case CLIENT_DOCUMENTS.DEL_FOLDER_SUCCESS:
    case CLIENT_DOCUMENTS.POST_FILES_SUCCESS:
      return { ...state, documents: action.documents };
    case CLIENTR.PROFILE.CODE_PROFILE.POST_SUCCESS:
    case CLIENTR.PROFILE.CODE_PROFILE.DEL_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, industryOperatingStates: { ...state.profile.industryOperatingStates, codes: action.codeInsured } }
      };
    case CLIENTR.INSURED.PROFILE.CODE_PROFILE.POST_SUCCESS:
    case CLIENTR.INSURED.PROFILE.CODE_PROFILE.DEL_SUCCESS:
      return {
        ...state,
        insured: {
          ...state.insured,
          profile: {
            ...state.insured.profile,
            industryOperatingStates: { ...state.insured.profile.industryOperatingStates, codes: action.codeInsured }
          }
        }
      };
    case CLIENTR.INSURED.POLICY.PROPERTY.GET_PROPERTIES_SUCCESS:
    case CLIENTR.INSURED.POLICY.PROPERTY.POST_SUCCESS:
    case CLIENTR.INSURED.POLICY.PROPERTY.SET_SUCCESS:
    case CLIENTR.INSURED.POLICY.PROPERTY.DEL_SUCCESS:
      return { ...state, policy: { ...state.policy, properties: action.propertiesInsured } };
    case CLIENTR.INSURED.POLICY.PROPERTY.GET_SUCCESS:
      return { ...state, policy: { ...state.policy, property: action.propertyInsured } };
    case CLIENTR.INSURED.POLICY.VEHICLE.GET_VEHICLES_SUCCESS:
    case CLIENTR.INSURED.POLICY.VEHICLE.POST_SUCCESS:
    case CLIENTR.INSURED.POLICY.VEHICLE.SET_SUCCESS:
    case CLIENTR.INSURED.POLICY.VEHICLE.DEL_SUCCESS:
      return { ...state, policy: { ...state.policy, vehicles: action.vehiclesInsured } };
    case CLIENTR.POLICY.GET_SUCCESS:
    case CLIENTR.INSURED.POLICY.GET_POLICIES_SUCCESS:
    case CLIENTR.INSURED.POLICY.POST_SUCCESS:
    case CLIENTR.INSURED.POLICY.DEL_SUCCESS:
      return { ...state, policies: action.policies };
    case CLIENTR.INSURED.POLICY.GET_SUCCESS:
    case CLIENTR.INSURED.POLICY.SET_SUCCESS:
    case CLIENTR.INSURED.POLICY.COVERAGE.SET_SUCCESS:
      return {
        ...state,
        policy: {
          ...state.policy,
          insuredPolicy: action.insuredPolicy,
          vehicles: action.insuredPolicy.policyVehicles,
          properties: action.insuredPolicy.listProperties,
          policyCodes: action.insuredPolicy.codeList
        }
      };
    case CLIENTR.INSURED.POLICY.COVERAGE.GET_SUCCESS:
      return { ...state, policy: { ...state.policy, coverage: action.coverage } };
    case CLIENTR.INSURED.POLICY.COVERAGE.DEL_SUCCESS:
      return { ...state, policy: { ...state.policy, insuredPolicy: action.policy } };
    case CLIENTR.INSURED.POLICY.CODE.GET_SUCCESS:
    case CLIENTR.INSURED.POLICY.CODE.POST_SUCCESS:
    case CLIENTR.INSURED.POLICY.CODE.DEL_SUCCESS:
      return { ...state, policy: { ...state.policy, policyCodes: action.codes } };
    case "CLEAR_POLICY":
      return { ...state, policy: client.policy };
    case CLIENTR.INSURED.POLICY.RELATED.GET_SUCCESS:
    case CLIENTR.INSURED.POLICY.RELATED.POST_SUCCESS:
    case CLIENTR.INSURED.POLICY.RELATED.DEL_SUCCESS:
      return { ...state, policy: { ...state.policy, policies: action.policies } };
    case CLIENTR.INSURED.POLICY.AGGREGATE.GET_SUCCESS:
    case CLIENTR.INSURED.POLICY.AGGREGATE.SET_SUCCESS:
      return { ...state, policy: { ...state.policy, aggregateDetails: action.aggregateDetails } };
    case CLIENT.UPLOAD_POLICY_DOCUMENT_SUCCESS:
    case CLIENT.DELETE_POLICY_DOCUMENT_SUCCESS:
      return {
        ...state,
        policy: { ...state.policy, insuredPolicy: { ...state.policy.insuredPolicy, documents: action.documents } } // Todo
      };
    case CLIENTR.LIST.DEL_SUCCESS:
    case CLIENTR.LIST.MOVE_ENTITY_TO_NEW_PARENT_SUCCESS:
      return { ...state, entityList: action.clients };
    case CLIENTR.INSURED.POLICY.FINANCIAL.SET_SUCCESS:
      return { ...state, policy: { ...state.policy, insuredPolicy: { ...state.policy.insuredPolicy, financial: action.financial || {} } } };
    default:
      return state;
  }
};

export default clientReducer;
