import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IconButton, makeStyles } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import MaterialTable from "../../common/table/MaterialTable";
import Card from "../../common/overview/Card";
import { useLoading } from "../../common/Loading";
import { get } from "../../../redux/actions/dashboard/diaries";

const useClaimWithoutActivityStyles = makeStyles(theme => ({
  icon: { color: "#107A98", padding: theme.spacing(1) }
}));

const DiariesList = () => {
  const dispatch = useDispatch();
  const {
    Loading,
    actions: { showLoading, hideLoading }
  } = useLoading(false, true);

  useEffect(() => {
    showLoading();
    dispatch(get(hideLoading, hideLoading));
  }, []);
  const diaries = useSelector(({ dashboard: { diaries: d } }) => d, shallowEqual);

  const tableProps = {
    options: {
      hideExportButton: true,
      paging: false,
      showTitle: false,
      search: false,
      toolbar: false
    },
    columns: [
      {
        title: "Claim #",
        field: "claimNumber",
        render: rowData => (
          <Link to={`/claim/${rowData.claimId}/overview`} style={{ color: "#107A98" }}>
            {rowData.claimNumber}
          </Link>
        )
      },
      {
        title: "Claimant",
        field: "claimantName",
        render: rowData => (
          <Link to={`/claim/${rowData.claimId}/overview`} style={{ color: "#107A98" }}>
            {rowData.claimantName}
          </Link>
        )
      },
      { title: "Diary Name", field: "description" },
      {
        title: "Due Date",
        field: "dueDate",
        render: rowData => (rowData.dueDate ? moment(rowData.dueDate).format("M/D/YYYY") : "")
      },
      { title: "Priority", field: "priority" }
    ],
    data: diaries
  };
  const history = useHistory();
  const classes = useClaimWithoutActivityStyles();
  const showDetail = () => {
    history.push("/dashboard/diaries");
  };
  const widgetActions = () => {
    return (
      <>
        <IconButton size="small" className={classes.icon} onClick={showDetail}>
          <OpenInNew />
        </IconButton>
      </>
    );
  };
  return (
    <>
      {Loading}
      <Card title="Diaries" cardHeaderAction={widgetActions}>
        <MaterialTable {...tableProps} />
      </Card>
    </>
  );
};

DiariesList.propTypes = {};

DiariesList.defaultProps = {};

export default DiariesList;
