import { CLAIM } from "../../actionTypes";

export const get = (claimId, onSuccess, onError) => ({ type: CLAIM.LEGAL.TRIAL.GET, claimId, onSuccess, onError });
export const getSuccess = trial => ({ type: CLAIM.LEGAL.TRIAL.GET_SUCCESS, trial });

export const set = rest => ({ type: CLAIM.LEGAL.TRIAL.SET, ...rest });
export const setSuccess = trial => ({ type: CLAIM.LEGAL.TRIAL.SET_SUCCESS, trial });

export const del = rest => ({ type: CLAIM.LEGAL.TRIAL.DEL, ...rest });
export const delSuccess = trial => ({ type: CLAIM.LEGAL.TRIAL.DEL_SUCCESS, trial });
