import { CLIENTR as CLIENT, API } from "../../../../actions/actionTypes";
import { getCoverageSuccess, setCoverageSuccess, delCoverageSuccess } from "../../../../actions/client/insured/policy/coverage";
import { takeLatest, takeLatestDel, takeLatestSet } from "../../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLIENT.INSURED.POLICY.COVERAGE.GET,
    actionSuccess: getCoverageSuccess,
    errorMessage: "Error loading Insured Coverage",
    url: `${API.URL}/client/insuredcoverage`,
    params: ({ policyId, coverageTypeId }) => ({ policyId, coverageTypeId })
  }),
  takeLatestSet({
    type: CLIENT.INSURED.POLICY.COVERAGE.SET,
    actionSuccess: setCoverageSuccess,
    successMessage: "Coverage information successfully saved.",
    errorMessage: "Unable to save the information ",
    url: `${API.URL}/client/setcoverage`,
    params: ({ coverage }) => coverage
  }),
  takeLatestDel({
    type: CLIENT.INSURED.POLICY.COVERAGE.DEL,
    actionSuccess: delCoverageSuccess,
    successMessage: "Coverage successfully deleted.",
    errorMessage: "Unable to delete the coverage.",
    url: `${API.URL}/client/delcoverage`,
    params:  ({ policyId, coverageTypeId }) => ({ policyId, coverageTypeId })
  })
];
