/* eslint-disable react/forbid-prop-types */
import { Link as LinkIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { Checkbox, Tooltip } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import * as React from "react";
import Count from "../Count";
import { icon } from "../index.styles";

const CustomGroupRow = ({ ...props }) => {
  const rotateIconStyle = isOpen => ({
    transform: isOpen ? "rotate(90deg)" : "none"
  });
  const {
    groupData,
    icons,
    options,
    columns,
    detailPanel,
    actions,
    groups,
    level,
    components,
    getFieldValue,
    onGroupExpandChanged,
    onRowSelected,
    onRowClick,
    onToggleDetailPanel,
    onTreeExpandChanged,
    onEditingCanceled,
    onEditingApproved,
    hasAnyEditingRow,
    isTreeData,
    path,
    localization,
    customGroupConfig,
    onGroupingCheckChanged
  } = props;
  let colSpan = columns.filter(columnDef => !columnDef.hidden).length;
  colSpan = options.selection ? colSpan + 1 : colSpan;
  colSpan = detailPanel ? colSpan + 1 : colSpan;
  colSpan = actions && actions.length > 0 ? colSpan + 1 : colSpan;
  const column = groups[level];
  let detail;
  if (groupData.isExpanded) {
    if (groups.length > level + 1) {
      // Is there another group
      detail = groupData.groups.map((groupd, index) => (
        <components.GroupRow
          actions={actions}
          // eslint-disable-next-line
          key={groupd.value || "" + index}
          columns={columns}
          components={components}
          detailPanel={detailPanel}
          getFieldValue={getFieldValue}
          groupData={groupd}
          groups={groups}
          icons={icons}
          level={level + 1}
          path={[...props.path, index]}
          onGroupExpandChanged={onGroupExpandChanged}
          onRowSelected={onRowSelected}
          onRowClick={onRowClick}
          onToggleDetailPanel={onToggleDetailPanel}
          onTreeExpandChanged={onTreeExpandChanged}
          onEditingCanceled={onEditingCanceled}
          onEditingApproved={onEditingApproved}
          options={options}
          hasAnyEditingRow={hasAnyEditingRow}
          isTreeData={isTreeData}
        />
      ));
    } else {
      detail = groupData.data.map((rowData, index) => {
        if (rowData.tableData.editing) {
          return (
            <components.EditRow
              columns={columns}
              components={components}
              data={rowData}
              icons={icons}
              path={[...path, index]}
              localization={localization}
              // eslint-disable-next-line
              key={index}
              mode={rowData.tableData.editing}
              options={options}
              isTreeData={isTreeData}
              detailPanel={detailPanel}
              onEditingCanceled={onEditingCanceled}
              onEditingApproved={onEditingApproved}
              getFieldValue={getFieldValue}
            />
          );
        }
        return (
          <components.Row
            actions={actions}
            // eslint-disable-next-line
            key={index}
            columns={columns}
            components={components}
            data={rowData}
            // detailPanel={detailPanel} /* error header*/
            getFieldValue={getFieldValue}
            icons={icons}
            path={[...path, index]}
            onRowSelected={onRowSelected}
            onRowClick={onRowClick}
            onToggleDetailPanel={onToggleDetailPanel}
            options={options}
            isTreeData={isTreeData}
            onTreeExpandChanged={onTreeExpandChanged}
            onEditingCanceled={onEditingCanceled}
            onEditingApproved={onEditingApproved}
            hasAnyEditingRow={hasAnyEditingRow}
          />
        );
      });
    }
  }

  const freeCells = [];
  for (let i = 0; i < level; i + 1) {
    freeCells.push(<TableCell padding="checkbox" key={i} />);
  }

  let { value } = groupData;
  if (column.lookup) {
    value = column.lookup[value];
  }

  let { title } = column;
  if (typeof title !== "string") {
    title = React.cloneElement(title);
  }
  const classIcon = icon();
  // const separator = props.options.groupRowSeparator || ": ";
  const changeCheck = () => {
    onGroupingCheckChanged(groupData.data[0]);
  };
  const { check, del, deleteAction } = customGroupConfig;
  const paddingSize = check === false && !groupData.data[0].id ? 15 : 0;
  return (
    <>
      <TableRow>
        {freeCells}
        <TableCell colSpan={colSpan} padding="none" value={value} icons={icons} size="medium" columndef={column}>
          <div
            className="card d-flex flex-row align-items-center justify-content-between p-4"
            style={{
              paddingTop: paddingSize,
              paddingBottom: paddingSize,
              paddingLeft: paddingSize
            }}
          >
            <span className="d-flex align-items-center">
              {check === true && groupData.data[0].occurrenceId ? (
                <Checkbox {...{ checked: groupData.data[0].occurrenceChecked, onChange: changeCheck }} />
              ) : (
                <></>
              )}
              {groupData.data[0].id ? (
                <IconButton
                  style={{
                    transition: "all ease 200ms",
                    ...rotateIconStyle(groupData.isExpanded)
                  }}
                  onClick={() => {
                    props.onGroupExpandChanged(props.path);
                  }}
                >
                  <icons.DetailPanel />
                </IconButton>
              ) : (
                <></>
              )}
              <span className="text-primary">{groupData.value}</span>
              {groupData.data[0].occurrenceId && groupData.data[0].id ? (
                <>
                  <LinkIcon className={classIcon.link} />
                  <Count>{groupData.data.length}</Count>
                </>
              ) : (
                <></>
              )}
              {del === true && groupData.data[0].occurrenceId ? (
                <Tooltip title="Dissociate Occurrence">
                  <DeleteIcon
                    style={{ float: "right", marginRight: 16, marginTop: check === false && groupData.data[0].id ? 12 : 0 }}
                    className={classIcon.delete}
                    {...{
                      onClick: () => {
                        deleteAction({ id: groupData.data[0].occurrenceId, type: "Occurrence", number: groupData.data[0].occurrenceNumber });
                      }
                    }}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
            </span>
          </div>
        </TableCell>
      </TableRow>
      {detail}
    </>
  );
};

CustomGroupRow.defaultProps = {
  columns: [],
  groups: [],
  options: {},
  level: 0
};

CustomGroupRow.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  components: PropTypes.object,
  detailPanel: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.object)]),
  getFieldValue: PropTypes.func,
  groupData: PropTypes.object,
  groups: PropTypes.arrayOf(PropTypes.object),
  hasAnyEditingRow: PropTypes.bool,
  icons: PropTypes.object,
  isTreeData: PropTypes.bool.isRequired,
  level: PropTypes.number,
  localization: PropTypes.object,
  onGroupExpandChanged: PropTypes.func,
  onRowSelected: PropTypes.func,
  onRowClick: PropTypes.func,
  onToggleDetailPanel: PropTypes.func.isRequired,
  onTreeExpandChanged: PropTypes.func.isRequired,
  onEditingCanceled: PropTypes.func,
  onEditingApproved: PropTypes.func,
  options: PropTypes.object,
  path: PropTypes.arrayOf(PropTypes.number),
  customGroupConfig: PropTypes.objectOf(PropTypes.any),
  onGroupingCheckChanged: PropTypes.func
};
CustomGroupRow.defaultProps = {
  data: [],
  actions: [],
  columns: [],
  components: {},
  detailPanel: [],
  getFieldValue: () => {},
  groupData: {},
  groups: [],
  hasAnyEditingRow: false,
  icons: {},
  level: 0,
  localization: {},
  onGroupExpandChanged: PropTypes.func,
  onRowSelected: PropTypes.func,
  onRowClick: PropTypes.func,
  onEditingCanceled: () => {},
  onEditingApproved: () => {},
  options: {},
  path: [],
  customGroupConfig: { check: false, del: false },
  onGroupingCheckChanged: () => {}
};
export default CustomGroupRow;
