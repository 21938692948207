import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Paper, Collapse, Grid, IconButton } from "@material-ui/core";
import SaveAltSharp from "@material-ui/icons/SaveAltSharp";
import styles from "./style";
import Form from "./Form";
import List from "./List";
import Read from "./Read";
import CollapsibleBox from "../CollapsibleBox";
import { getTemplates as getClaimTemplates } from "../../../redux/actions/claim/diary";
import { get, set, post } from "../../../redux/actions/diary";
import { get as getOccurrence, getTemplates as getOccurrenceTemplates } from "../../../redux/actions/occurrence";
import { useDebounce } from "../../../common/index";
import { useDiariesSelector } from "./selectors";
import { useLoadingOrContext } from "../Loading";
import useQueryString from "../customHooks/useQueryString";

const Diary = ({ claimId, occurrenceId, access }) => {
  const entityId = typeof claimId !== "undefined" ? claimId : occurrenceId;
  const entityType = typeof occurrenceId !== "undefined" ? "occurrence" : "claim";
  const [editMode, setEditMode] = useState(entityType !== "claim" && access.create);
  const [openForm, setOpenForm] = useState(entityType !== "claim" && access.create);
  const [diary, setDiary] = useState({ statusId: 1, claimIds: [] });
  const classes = styles();
  const {
    loading: isLoading,
    Loading,
    actions: { showLoading, hideLoading, handleLoading }
  } = useLoadingOrContext(true);
  const loadEdit = useDebounce(() => {
    setEditMode(true);
    setOpenForm(true);
  }, 500);
  const onToggle = () => setOpenForm(!openForm);
  const onCancel = () => {
    onToggle();
    if (entityType !== "claim" && access.create) {
      setEditMode(false);
      setDiary({ statusId: 1, claimIds: [] });
      loadEdit();
    }
    hideLoading();
  };
  const onEdit = useCallback(d => {
    setDiary(d);
    setEditMode(false);
    loadEdit();
  });
  const onAdd = useCallback(() => {
    setOpenForm(true);
    setEditMode(true);
    setDiary({ statusId: 1, claimIds: [] });
  });
  const onView = useCallback(d => {
    setDiary(d);
    setOpenForm(true);
    setEditMode(false);
  });
  const dispatch = useDispatch();
  const store = useDiariesSelector();
  const onSave = d => {
    if (access.update || access.create) {
      showLoading();
      if ((diary || {}).id) return dispatch(set({ diary: d, entityType, onSuccess: onCancel, onError: hideLoading }));
      return dispatch(post({ diary: { ...d, [`${entityType}Id`]: entityId }, entityType, onSuccess: onCancel, onError: hideLoading }));
    }
    return null;
  };
  const { diaryId } = useQueryString();
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get({ id: entityId, entityType, onSuccess: hideLoading, onError: hideLoading }));
      if (entityType === "occurrence") {
        if (!(store.occurrence || {}).id) dispatch(getOccurrence(occurrenceId));
        if (entityId !== store.occurrenceDiaryTemplates.occurrenceId) dispatch(getOccurrenceTemplates(entityId));
      } else if (entityType === "claim" && entityId !== store.claimDiaryTemplates.claimId) dispatch(getClaimTemplates(entityId));
    }
  }, []);

  const print = area => {
    const div = document.createElement("div");
    div.classList.add("print-area");
    document.body.appendChild(div);
    document.querySelector(".print-area").innerHTML = document.getElementById(area).innerHTML;
    window.print();
    document.querySelector(".print-area").innerHTML = "";
    document.querySelector(".print-area").remove();
    return false;
  };

  const downloadPDF = () => {
    return (
      <IconButton onClick={() => print("print-diary")} className={classes.iconExport}>
        <SaveAltSharp />
      </IconButton>
    );
  };
  return (
    <>
      {Loading}
      <Grid container spacing={entityType === "occurrence" ? 2 : 0}>
        <Grid item xs={entityType === "occurrence" && openForm && (access.create || access.update) ? 6 : 12}>
          <Collapse in={openForm} timeout="auto">
            <CollapsibleBox
              {...{
                isCollapse: false,
                title: "",
                name: "diary",
                open: openForm,
                onToggle,
                edit: access.update
                  ? {
                      setEdit: diary.isAssociated === false && !editMode,
                      handleToggle: () => {
                        setEditMode({
                          editMode: !editMode
                        });
                      }
                    }
                  : {},
                header: !editMode && entityType === "claim" ? downloadPDF() : undefined
              }}
            >
              {!editMode && <Read {...{ diary, onCancel }} />}
              {editMode && (access.create || access.update) && <Form {...{ entityType, entityId, onCancel, diary, onSave, isLoading }} />}
            </CollapsibleBox>
          </Collapse>
        </Grid>
        <Grid item xs={entityType === "occurrence" && openForm && (access.create || access.update) ? 6 : 12}>
          <Paper className={classes.root}>
            <List {...{ entityType, onEdit, onAdd, onView, openForm, access, isLoading, setIsLoading: handleLoading, diaryId }} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

Diary.propTypes = {
  claimId: PropTypes.number,
  occurrenceId: PropTypes.number,
  access: PropTypes.objectOf(PropTypes.any)
};

Diary.defaultProps = {
  claimId: undefined,
  occurrenceId: undefined,
  access: { read: true, create: true, update: true, delete: true }
};

export default Diary;
