import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ClaimStatus from "../claims/ClaimStatus";
import ClaimProcessingStatus from "../claims/ClaimProcessingStatus";
import MaterialTable from "./table/MaterialTable";
import { table, tableTheme } from "./index.styles";
import { useDebounce } from "../../common";

const AssociatedList = memo(({ associatedList, onEdit, clearChecks, entityType, entityId, claimIds, occurrenceIds, relatedToText }) => {
  const [assocList, setAssocList] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const distinct = (value, index, self) => self.indexOf(value) === index;
  const updSelectAll = useDebounce(() => {
    const isSelectedAllClaims =
      assocList.filter(elem => elem.id !== 0 && elem.checked === true).length === assocList.filter(elem => elem.id !== 0).length;
    const isSelectedAllOccurrence =
      assocList.filter(elem => elem.occurrenceChecked === true && elem.occurrenceId !== null && elem.occurrenceId !== 0).length ===
      assocList.filter(elem => elem.occurrenceId !== null && elem.occurrenceId !== 0).filter(distinct).length;
    const sel = isSelectedAllClaims && isSelectedAllOccurrence;
    setSelectAll(sel);
  }, 500);
  useEffect(() => {
    if ((associatedList.length > 0 && assocList.length === 0) || clearChecks) {
      setAssocList(
        associatedList.map(c => ({
          ...c,
          tableData: { checked: false, occurrenceChecked: false },
          checked: false,
          occurrenceChecked: false
        }))
      );
      setSelectAll(false);
    }
  }, [associatedList, clearChecks]);
  useEffect(() => {
    if (!isLoad && entityId) {
      setIsLoad(false);
      const claims = claimIds != null ? claimIds : [];
      const occurrences = occurrenceIds != null ? occurrenceIds : [];
      setAssocList(
        associatedList.map(c => ({
          ...c,
          tableData: { checked: claims.indexOf(c.id) > -1, occurrenceChecked: occurrences.indexOf(c.occurrenceId) > -1 },
          checked: claims.indexOf(c.id) > -1,
          occurrenceChecked: occurrences.indexOf(c.occurrenceId) > -1
        }))
      );
      updSelectAll();
    }
  }, [entityId, claimIds, occurrenceIds]);
  const rowsCountByPage = 6;
  const tableProps = {
    columns: [
      {
        title: (() => {
          if (entityType === "occurrence") return "Claim Number";
          const changeSelectAll = () => {
            setAssocList(
              assocList.map(c => ({
                ...c,
                tableData: { checked: !selectAll },
                checked: !selectAll,
                occurrenceChecked: !selectAll
              }))
            );
            const claims = !selectAll === true ? assocList.map(({ id }) => id).filter(e => e !== 0) : [];
            const occ =
              !selectAll === true
                ? assocList
                    .map(({ occurrenceId }) => occurrenceId)
                    .filter(distinct)
                    .filter(e => e !== 0)
                : [];
            onEdit(claims, occ);
            setSelectAll(!selectAll);
          };
          return (
            <div style={{ whiteSpace: "nowrap" }}>
              <Checkbox checked={selectAll} onChange={changeSelectAll} />
              Claim Number
            </div>
          );
        })(),
        render: r => (
          <Grid container spacing={1} wrap="nowrap" alignItems="center">
            <Grid item>
              <Link to={`/claim/${r.id}`}>{r.number}</Link>
            </Grid>
            <Grid item>
              <ClaimStatus status={r.status} />
            </Grid>
            {r.processingStatus && (
              <Grid item>
                <ClaimProcessingStatus processingStatus={r.processingStatus} />
              </Grid>
            )}
          </Grid>
        )
      },
      {
        title: "Claimant",
        field: "claimantFullName",
        width: null,
        cellStyle: {
          whiteSpace: "nowrap",
          paddingRight: 0,
          width: 200
        }
      },
      { title: "DOL", field: "dateofLoss", render: r => (r.dateofLoss ? moment(r.dateofLoss).format("M/D/YYYY") : "") },
      { title: "LOB", field: "claimLineOfBusinessCode" },
      {
        title: "LTG",
        field: "claimLossTypeGroup",
        width: null,
        cellStyle: {
          whiteSpace: "nowrap",
          paddingRight: 0,
          width: 200
        }
      },
      ...(entityType === "occurrence" ? [] : [{ title: "Occurrence", field: "occurrenceName", defaultGroupOrder: 1 }])
    ],
    data: assocList,
    hidePaging: assocList.length <= rowsCountByPage,
    hideToolbar: assocList.length <= rowsCountByPage,
    hideExportButton: true,
    options: {
      search: false,
      selection: true,
      showSelectAllCheckbox: entityType === "occurrence",
      grouping: entityType === "catastrophe",
      pageSize: rowsCountByPage
    },
    onSelectionChange: data => {
      setAssocList(assocList.map(c => ({ ...c, checked: data?.findIndex(elem => elem.id === c.id) > -1 })));
      onEdit(data.map(({ id }) => id).filter(e => e !== 0));
      updSelectAll();
    },
    onGroupingCheckChanged: data => {
      const selected = assocList.find(elem => elem.occurrenceId === data.occurrenceId) || {};
      if (selected.occurrenceChecked === true) {
        const indexOccList = occurrenceIds.indexOf(selected.occurrenceId);
        occurrenceIds.splice(indexOccList, 1);
      } else occurrenceIds.push(selected.occurrenceId);
      onEdit(claimIds, occurrenceIds);
      setAssocList(
        assocList.map(c => ({ ...c, occurrenceChecked: data.occurrenceId === c.occurrenceId ? !c.occurrenceChecked : c.occurrenceChecked }))
      );
      updSelectAll();
    },
    customGroupConfig: { check: true, del: false }
  };

  const classTable = table();
  const dataType = entityType === "occurrence" ? "Claims" : "Claims/Occurrence";
  const showDesc = relatedToText.trim().toLowerCase() === "documents";
  return (
    <div style={{ marginBottom: 20, minHeight: 350 }} className={classTable.fullHeader}>
      <Typography className="tc-title" gutterBottom>
        {`Select ${dataType} to relate the ${relatedToText}`}
      </Typography>
      {showDesc && (
        <Typography className="tc-color-gray">The file/s will be only associated with those claims that contain the selected folder</Typography>
      )}
      <MuiThemeProvider theme={tableTheme}>
        <MaterialTable {...tableProps} />
      </MuiThemeProvider>
    </div>
  );
});

AssociatedList.propTypes = {
  associatedList: PropTypes.arrayOf(PropTypes.any).isRequired,
  relatedToText: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  clearChecks: PropTypes.bool.isRequired,
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.number,
  claimIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  occurrenceIds: PropTypes.arrayOf(PropTypes.number)
};

AssociatedList.defaultProps = {
  entityId: null,
  occurrenceIds: [],
  relatedToText: "Notes"
};

export default AssociatedList;
