import { Grid, Paper, RadioGroup, FormControlLabel, Radio, Button, Typography } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { useRouteParam } from "../../common";
import useCustomConfirm from "../common/customHooks/useCustomConfirm";
import useYupValidationSchema from "../common/customHooks/useYupValidationSchema";
import { SelectInput, TextInput, DateTimeInput } from "../common/inputs";
import MaterialTable from "../common/table/MaterialTable";
import AssociateClaim from "./AssociateClaim";
import AssociatedList from "./AssociatedList";
import AssociateOccurrence from "./AssociateOccurrece";
import useStyles from "./CatastropheAddPage.styles";
import schema from "./yup";

const CatastrophePage = ({ onToggleEdit, onSave }) => {
  const classes = useStyles();

  const [state, setState] = useState({ location: {}, locationList: [] });
  const storeData = useSelector(store => store, shallowEqual);

  const {
    common: { states },
    catastrophe: { catastrophe }
  } = storeData || {};

  const catastropheId = useRouteParam("slug");

  useEffect(() => {
    if (catastrophe.id === catastropheId) {
      setState({
        ...catastrophe,
        location:
          catastrophe.locationList.length > 0 && !catastrophe.multipleLocation
            ? catastrophe.locationList[0]
            : { address1: "", address2: "", city: "", county: "", stateId: null, zipCode: "", stateReportingNumber: "" },
        locationList:
          catastrophe.locationList.length > 0 && !catastrophe.multipleLocation
            ? []
            : catastrophe.locationList.reduce((acc, item) => [...acc, { ...item, newStateid: item.stateId.toString() }], [])
      });
    }
  }, [catastropheId, catastrophe]);
  const { handleValidateSchema, validationMessages } = useYupValidationSchema(schema);
  const handleUpdate = () => {
    const { isValid } = handleValidateSchema(state);
    if (!isValid) {
      if (validationMessages.toast) return toast.warn(validationMessages.toast);
      return false;
    }
    return onSave({
      ...state,
      dateTo: state.dateTo === "" ? null : state.dateTo,
      ...(state.multipleLocation ? {} : { locationList: state.location?.stateId ? [state.location] : [] })
    });
  };

  const DelLocation = ({ id }) =>
    setState(ps => {
      const ns = { ...ps, locationList: ps.locationList.filter(x => x.id !== id) };
      handleValidateSchema(ns);
      return ns;
    });
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(DelLocation, "Cancel", "Yes, Delete");
  const handleConfirmDelete = rowData => {
    handleCustomConfirm({
      oData: rowData,
      titleText: "Delete Location",
      messageText: (
        <>
          <p>The location will be deleted.</p>
          <p>Are you sure you want to proceed?</p>
        </>
      )
    });
  };
  const onChangeState = propsRow => e =>
    propsRow.onRowDataChange({
      ...propsRow.rowData,
      newStateid: parseInt(e.target.value, 10)
    });
  const tablePropsLocation = {
    columns: [
      { title: "City", field: "city" },
      {
        title: "State",
        field: "newStateid",
        validate: rowData => Boolean(rowData.newStateid) || `State is Required `,
        editComponent: propsRow => <SelectInput onChange={onChangeState(propsRow)} value={propsRow.rowData.newStateid} options={states} />,
        render: rowData => rowData?.state
      },
      { title: "State Reporting Number", field: "stateReportingNumber" }
    ],
    data: state.locationList || [],
    options: { search: false },
    onActionSave: location => {
      const maxId = state.locationList.reduce((max, l) => (l.id > max ? l.id : max), 0);
      const newLocation = {
        ...location,
        stateId: parseInt(location.newStateid, 0),
        id: location.id ? location.id : maxId + 1,
        state: (states.find(s => s.id === parseInt(location.newStateid, 0)) || {}).text
      };
      return setState(ps => {
        const ns = {
          ...ps,
          locationList: location.id ? ps.locationList.map(x => (x.id !== location.id ? x : newLocation)) : [...ps.locationList, newLocation]
        };
        handleValidateSchema(ns);
        return ns;
      });
    },
    actions: [
      {
        icon: () => <DeleteIcon />,
        tooltip: "Delete",
        onClick: (_e, rowData) => {
          _e.stopPropagation();
          handleConfirmDelete(rowData);
        }
      }
    ]
  };

  const inputProps = (name, label) => {
    const [a, b] = name.split(".");
    let val = b ? state[a][b] : state[a];
    if (a === "multipleLocation") val = state[a] ? "1" : "0";
    return {
      name,
      label,
      error: validationMessages[name],
      value: val || undefined,
      onChange: ({ target: { name: n, value } }) =>
        setState(ps => {
          const [c, d] = n.split(".");
          const ns = { ...ps, [c]: d ? { ...ps[c], [d]: value } : value };
          if (c === "multipleLocation") ns.multipleLocation = value === "1";
          handleValidateSchema(ns);
          return ns;
        })
    };
  };
  const assocFilter = isClaim =>
    [["id"], ["date", "dateSince"], ["dateTo"], ...(isClaim ? [["multipleLocation"]] : [])].reduce(
      (a, [p1, p2]) => ({ ...a, [p1]: state[p2 || p1] }),
      {
        locationListToFind: state.multipleLocation
          ? state.locationList
          : ((state.location.city || state.location.stateId) && [{ id: 1, ...state.location }]) || []
      }
    );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Paper className={classes.paper}>
            <Typography className="tc-title" gutterBottom>{`Catastrophe # ${state.number}`}</Typography>
            <Grid container spacing={8}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <TextInput {...inputProps("name", "Name")} />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <DateTimeInput {...inputProps("dateSince", "Date Since")} keyboard />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <DateTimeInput {...inputProps("dateTo", "Date To")} keyboard />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <RadioGroup {...inputProps("multipleLocation")} row>
                  <FormControlLabel control={<Radio />} value="0" label="One Location" />
                  <FormControlLabel control={<Radio />} value="1" label="Multiple Location" />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                {(state.multipleLocation && <MaterialTable {...tablePropsLocation} hidePaging />) || (
                  <Grid container spacing={8}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TextInput {...inputProps("location.address1", "Physical Address 1")} />
                      <TextInput {...inputProps("location.city", "City")} />
                      <TextInput {...inputProps("location.county", "County")} />
                      <TextInput {...inputProps("location.stateReportingNumber", "State Reporting Number")} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TextInput {...inputProps("location.address2", "Physical Address 2")} />
                      <SelectInput {...inputProps("location.stateId", "State")} options={states} />
                      <TextInput {...inputProps("location.zipCode", "Zip")} />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextInput {...inputProps("description", "Description")} rows={4} />
              </Grid>
            </Grid>
            <AssociatedList />
            <Grid container alignItems="flex-start" justify="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={onToggleEdit} className={classes.cancelButton}>
                CANCEL
              </Button>
              <Button variant="contained" color="primary" onClick={() => handleUpdate()} className={classes.saveButton}>
                SAVE
              </Button>
            </Grid>
          </Paper>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container className={classes.containerSearch}>
            <Grid item>
              <AssociateOccurrence filter={assocFilter()} />
            </Grid>
            <Grid item>
              <AssociateClaim filter={assocFilter(true)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Confirm />
    </>
  );
};
CatastrophePage.propTypes = { onToggleEdit: PropTypes.func.isRequired, onSave: PropTypes.func.isRequired };
export default CatastrophePage;
