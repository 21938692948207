import { CLAIM, API } from "../../../actions/actionTypes";
import * as actions from "../../../actions/claim/info/claimant";
import { takeLatest } from "../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLAIM.INFO.CLAIMANT.GET,
    actionSuccess: actions.loadByClaimIdSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading claimant",
    url: `${API.URL}/claim/claimant`
  }),
  takeLatest({
    type: CLAIM.INFO.CLAIMANT.SET,
    method: ({ claimant: { id } }) => (id ? "PUT" : "POST"),
    actionSuccess: actions.setSuccess,
    url: `${API.URL}/claimant`,
    params: ({ claimId, claimant }) => ({ data: actions.setData(claimant), claimId }),
    successMessage: "Claimant information successfully saved",
    errorMessage: "Unable to save the information"
  })
];
