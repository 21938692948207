import { API } from "../../actions/actionTypes";
import WIDGET from "../../actions/actionTypes/dashboard/widget";
import { getSuccess } from "../../actions/dashboard/claimWithoutActivity";
import { takeLatest } from "../common/sagaGenerator";

export default [
  takeLatest({
    type: WIDGET.CLAIM_WITHOUT_ACTIVITY.GETALL,
    actionSuccess: getSuccess,
    params: {},
    errorMessage: "Error loading widget Claim Without Activity",
    url: `${API.URL}/report/claimwithoutactivity`
  })
];
