import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import ClaimProcessingStatus from "../../../claims/ClaimProcessingStatus";
import ClaimStatus from "../../../claims/ClaimStatus";
import LinkButton from "../../../common/LinkButton";
import Table from "../../../common/table/MaterialTable";

const ClaimList = ({ actions: { onSelect }, ...other }) => {
  const props = {
    columns: [
      {
        title: "Claim",
        field: "number",
        render: row => (
          <Grid container spacing={1} alignItems="center" wrap="nowrap">
            <Grid item>{row.number}</Grid>
            <Grid item>
              <ClaimStatus status={row.status} />
            </Grid>
            <Grid item>
              <ClaimProcessingStatus processingStatus={row.processingStatus} />
            </Grid>
          </Grid>
        )
      },
      { title: "Claimant", field: "claimantName" },
      { title: "LOB", field: "claimLineOfBusinessCode" }
    ],
    actions: [
      {
        icon: p => (
          <LinkButton blue underline className="tc-bold" {...p} disabled={!!other.disabled}>
            SELECT
          </LinkButton>
        ),
        ...{ custom: true, onClick: (_e, row) => onSelect(row) }
      }
    ],
    ...other
  };
  return <Table {...props} hidePaging hideToolbar hideGroupbar />;
};

ClaimList.propTypes = { actions: PropTypes.objectOf(PropTypes.func).isRequired };

export default ClaimList;
