import { CLIENTR as CLIENT, API } from "../../actions/actionTypes";
import { getSuccess, setSuccess, postSuccess, delSuccess } from "../../actions/client/contact";
import { takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "../common/sagaGenerator";

export default [
  takeLatest({
    type: CLIENT.CONTACTS.GET,
    url: `${API.URL}/client/getAllContactByClientId`,
    params: ({ id }) => ({ id }),
    actionSuccess: getSuccess,
    errorMessage: "Unable to get Contacts"
  }),
  takeLatestDel({
    type: CLIENT.CONTACTS.DEL,
    url: `${API.URL}/client/delClientContact`,
    params: ({ contact }) => contact,
    actionSuccess: delSuccess,
    successMessage: "Contact successfully deleted",
    errorMessage: "Unable to delete the contact"
  }),
  takeLatestPost({
    type: CLIENT.CONTACTS.POST,
    url: `${API.URL}/client/addClientContact`,
    params: ({ contact }) => contact,
    actionSuccess: postSuccess,
    successMessage: "Contact successfully added",
    errorMessage: "Unable to add the contact"
  }),
  takeLatestSet({
    type: CLIENT.CONTACTS.SET,
    url: `${API.URL}/client/setClientContact`,
    params: ({ contact }) => contact,
    actionSuccess: setSuccess,
    successMessage: "Contact successfully updated",
    errorMessage: "Unable to updated the contact"
  })
];
