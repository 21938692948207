import { makeStyles } from "@material-ui/core";

const buildRoot = (theme, top) => ({
  root: { padding: theme.spacing(2.75, 2.75, 0.75, 2.75), [`margin${top ? "Top" : "Bottom"}`]: theme.spacing(2.75) }
});

const marginDivider = theme => ({ marginDivider: { marginBottom: theme.spacing(2) } });

export const useSingleOrMultipleStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2.75),
    marginBottom: theme.spacing(2.75)
  },
  chk: { paddingLeft: theme.spacing(2.75) }
}));

export const useInsuredStyles = makeStyles(theme => ({
  ...buildRoot(theme),
  pr1: { paddingRight: theme.spacing(1) },
  pl1: { paddingLeft: theme.spacing(1) },
  divider: { margin: theme.spacing(0, 0, 2, 0) },
  search: {
    padding: theme.spacing(1, 0),
    "& > div:not(:last-of-type)": {
      marginBottom: theme.spacing(1),
      position: "relative",
      "&:after": {
        position: "absolute",
        content: '""',
        width: "calc(100% - 32px)",
        bottom: theme.spacing(-0.5),
        right: theme.spacing(2),
        borderBottom: "solid 0.5px #898F91"
      }
    }
  },
  arrow: {
    minWidth: 24,
    marginRight: theme.spacing(1),
    "& > button > span > svg": {
      transform: "rotate(-180deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    }
  },
  open: { "& > button > span > svg": { transform: "rotate(0deg)" } }
}));

export const useLineOfBusinessStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(2.75) },
  selects: { padding: theme.spacing(0, 0, 2, 2) },
  coverageTypeGroup: { fontSize: 16, fontWeight: 500, "& > label > span + span": { fontSize: 16, fontWeight: 500 } },
  chk: { marginBottom: 0 }
}));

export const useInsuredVehicleStyles = makeStyles(theme => ({
  ...buildRoot(theme, true),
  pr1: { paddingRight: theme.spacing(1) },
  pl1: { paddingLeft: theme.spacing(1) },
  ...marginDivider(theme)
}));

export const useInsuredPropertyStyles = makeStyles(theme => ({ ...buildRoot(theme, true), ...marginDivider(theme) }));

export const useDamagedPropertyStyles = makeStyles(theme => ({ ...buildRoot(theme), ...marginDivider(theme) }));
