import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { Select, MenuItem, Grid, IconButton, Input } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const SelectBase = memo(
  ({ id, name, options, value, allowClear, onChange, disabled, renderOption, ...other }) => {
    const renderValue = val => {
      const getValue = () => {
        const vals = options.filter(({ id: oid }) => String(oid) === String(val));
        if (!vals.some(() => true)) return "";
        return vals[0].text || "";
      };
      return (
        <Grid container wrap="nowrap">
          <Grid item style={{ flexGrow: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {getValue()}
          </Grid>
          <Grid item style={{ height: 15, marginTop: -2 }}>
            <IconButton
              size="small"
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                if (typeof onChange === "undefined") return false;
                return onChange({ target: { name, value: undefined } });
              }}
            >
              <Close style={{ fontSize: "1.125rem" }} />
            </IconButton>
          </Grid>
        </Grid>
      );
    };
    const [open, setOpen] = useState(false);
    const rOption =
      typeof renderOption === "function"
        ? renderOption
        : option => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.text}
              </MenuItem>
            );
          };
    return (
      <Select
        {...{
          name,
          open,
          input: <Input onClick={disabled ? undefined : () => setOpen(ps => !ps)} />,
          id: id || `select-${name}`,
          value: options.some(({ id: oid }) => String(oid) === String(value)) ? value : "",
          onChange,
          renderValue: allowClear && !disabled ? renderValue : undefined,
          disabled,
          fullWidth: true,
          ...other
        }}
      >
        {options.map(rOption)}
      </Select>
    );
  },
  (p, n) => !["id", "value", "options", "disabled", "onChange"].some(x => p[x] !== n[x])
);

SelectBase.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  allowClear: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  renderOption: PropTypes.func
};

SelectBase.defaultProps = {
  id: undefined,
  onChange: undefined,
  allowClear: false,
  value: "",
  options: [],
  disabled: false,
  renderOption: undefined
};

export default SelectBase;
