import { makeStyles } from "@material-ui/core";

export const useIncidentStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2.75, 2.75, 0.75, 2.75),
    marginBottom: theme.spacing(2.75)
  },
  pr1: { paddingRight: theme.spacing(1) },
  pl1: { paddingLeft: theme.spacing(1) },
  associate: {
    height: "100%",
    "& > div": {
      padding: theme.spacing(2, 2.75),
      backgroundColor: "#F3F3F3"
    }
  }
}));

export const useOccurrenceOptionStyles = makeStyles(theme => ({
  root: { alignItems: "flex-start" },
  option: { display: "block", paddingTop: theme.spacing(1.25) },
  link: { height: "0.5em", color: "#107A98", marginRight: theme.spacing(1) },
  count: {
    display: "inline-flex",
    position: "relative",
    backgroundColor: "#DAEAEF",
    justifyContent: "center",
    alignItems: "center",
    height: 24,
    padding: theme.spacing(0, 1),
    borderRadius: 4,
    top: -2,
    fontSize: 14
  },
  radio: {
    "& > .MuiFormControlLabel-root": {
      marginRight: 0
    }
  },
  number: {
    display: "inline-block",
    padding: theme.spacing(0, 2.75, 0, 0),
    fontSize: 16
  },
  block: {
    display: "block",
    fontSize: 14
  }
}));
