import moment from "moment";
import { SettingsApplications } from "@material-ui/icons";
import React, { memo } from "react";
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import { Checkbox, IconButton } from "@material-ui/core";
import RegexFilter from "../RegexFilter";
import { fileSize } from "../../../common";
import Table from "../table/MaterialTable";
import LinkButton from "../LinkButton";
import useCustomConfirm from "../customHooks/useCustomConfirm";
import { FileNameIcon } from "./FileIcon";

const FolderListView = memo(({ actions, actionsAllowed, downloadArray, filter, folder, path }) => {
  const { openFolder, onCheckItem, onDeleteFile, onDeleteFolder, onUpdateFile, onUpdateFolder, onOpenFile, onIndex } = actions;
  const isClaim = path === "claimId";
  const files = folder.fileList
    .map(f => ({ ...f, description: f.description || "", date: f.uploadedOn, user: f.uploadedByName }))
    .sort((a, b) => a.fileName.localeCompare(b.fileName));
  const folders = folder.folderList
    .map(f => ({ ...f, description: f.description || "", date: f.lastActivity, user: f.createdByName }))
    .sort((a, b) => a.name.localeCompare(b.name));
  const checkedFiles = files.filter(x => downloadArray.some(d => d.file && d.id === x.id)).length;
  const checkedFolders = folders.filter(x => downloadArray.some(d => !d.file && d.id === x.id)).length;
  const allChecked = checkedFiles + checkedFolders > 0 && files.length === checkedFiles && folders.length === checkedFolders;
  const allDisabled = !files.length && !folders.length;
  const onAllChechChange = ({ target: { checked } }) =>
    onCheckItem([...files.map(({ id }) => ({ id, file: true, checked })), ...folders.map(({ id }) => ({ id, file: false, checked }))]);
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(() => {}, "Cancel", "Yes, Delete");
  const handleConfirm = file => {
    handleCustomConfirm({
      oData: { isQueue: folder.isQueue, ...file },
      titleText: `Delete ${file.file ? "Document" : "Folder"}`,
      messageText: (
        <>
          <p>{`The following ${file.file ? "document" : "folder"} is going to be deleted:`}</p>
          <FileNameIcon type={file.fileType} file={!!file.file} name={<b>{(file.file && file.fileName) || file.name}</b>} wrap="nowrap" />
          <p>Are you sure you want to proceed?</p>
        </>
      ),
      onAction: file.file ? onDeleteFile : onDeleteFolder
    });
  };
  const verificationFiles = obj => obj.fileList?.length > 0 || obj.folderList.some(f => verificationFiles(f));
  const tableProps = {
    columns: [
      {
        title: folder.isQueue ? "" : <Checkbox checked={allChecked} onChange={onAllChechChange} color="primary" disabled={allDisabled} />,
        render: r => {
          if (folder.isQueue) {
            if (!actionsAllowed.update || typeof onIndex !== "function") return "";
            return (
              <IconButton onClick={() => onIndex(r)}>
                <SettingsApplications />
              </IconButton>
            );
          }
          const onChange = ({ target: { checked } }) => onCheckItem([{ id: r.id, file: !!r.file, checked }]);
          const checked = downloadArray.some(d => d.id === r.id && !!d.file === !!r.file);
          return <Checkbox {...{ checked, onChange }} color="primary" />;
        },
        sorting: false,
        width: null,
        cellStyle: { width: 1, padding: 0 },
        headerStyle: { padding: 0 }
      },
      {
        title: "Name",
        field: "name",
        editable: (_t, r) => !r.file,
        customSort: (a, b) => {
          if (a.file && !b.file) return 1;
          if (!a.file && b.file) return -1;
          if (a.file && b.file) return a.fileName.localeCompare(b.fileName);
          return a.name.localeCompare(b.name);
        },
        render: r => {
          return (
            <LinkButton onClick={() => (r.file ? onOpenFile({ ...r, folder: r.folder || folder }) : openFolder(r))} style={{ textAlign: "initial" }}>
              <FileNameIcon type={r.fileType} file={!!r.file} name={(r.file && r.fileName) || r.name} wrap="nowrap" />
            </LinkButton>
          );
        }
      },
      { title: "Description", field: "description" },
      {
        title: "Date",
        field: "date",
        type: "date",
        editable: "never",
        render: r => (!isClaim || r.file || (Array.isArray(r.fileList) && r.fileList.length > 0)) && moment(r.date).format("M/D/YYYY")
      },
      { title: "Size", field: "size", editable: "never", render: r => r.file && fileSize(r.size) },
      {
        title: "Uploaded by",
        field: "user",
        editable: "never",
        render: r => (!isClaim || r.file || (Array.isArray(r.fileList) && r.fileList.length > 0)) && r.user
      }
    ],
    data: [
      ...folders.filter(x => !filter || RegexFilter(filter, x.name) || RegexFilter(filter, x.description)),
      ...files.filter(x => !filter || RegexFilter(filter, x.fileName) || RegexFilter(filter, x.description)).map(f => ({ ...f, file: true }))
    ],
    editableAttr: {
      isEditable: r => r.editable !== false && typeof (r.file ? onUpdateFile : onUpdateFolder) === "function",
      isEditHidden: r => typeof (r.file ? onUpdateFile : onUpdateFolder) !== "function"
    },
    onActionSave: actionsAllowed.update && actionsAllowed.create && !folder.isQueue ? f => (f.file ? onUpdateFile : onUpdateFolder)(f) : undefined,
    actions: actionsAllowed.delete
      ? [
          r =>
            r.file || (!isClaim && !verificationFiles(r))
              ? {
                  icon: DeleteIcon,
                  tooltip: "Delete",
                  custom: true,
                  onClick: (event, f) => {
                    event.stopPropagation();
                    handleConfirm(f);
                  }
                }
              : undefined
        ]
      : undefined
  };
  return (
    <>
      <Table {...tableProps} hideToolbar hidePaging />
      <Confirm />
    </>
  );
});

FolderListView.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  actionsAllowed: PropTypes.objectOf(PropTypes.any),
  downloadArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  filter: PropTypes.string.isRequired,
  folder: PropTypes.objectOf(PropTypes.any).isRequired,
  path: PropTypes.string
};

FolderListView.defaultProps = {
  actionsAllowed: { read: true, update: true, create: true },
  path: undefined
};
export default FolderListView;
