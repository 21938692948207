import React, { useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import ClaimForm from "../../../common/claims/claim/Form";
import ClaimViewMode from "../../../common/claims/claim/ViewMode";
import { set } from "../../../../redux/actions/claim";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";

export default props => {
  const claim = useSelector(({ claim: { claim: c } }) => c, shallowEqual);
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info") || {};
  const {
    props: { boxProps, editMode, loading: isLoading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  const dispatch = useDispatch();
  const handleSave = data => {
    showLoading();
    dispatch(set(data, onSuccessEdit, hideLoading));
  };
  return (
    <CollapsibleBox title="Claim" name="claim" {...boxProps} {...props}>
      {Loading}
      {(editMode && access.update && <ClaimForm {...{ claim, onClose: onToggleEdit, onSave: handleSave, isLoading }} />) || (
        <ClaimViewMode {...{ claim }} />
      )}
    </CollapsibleBox>
  );
};
