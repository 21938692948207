import { Grid } from "@material-ui/core";
import React, { useRef } from "react";
import { Redirect } from "react-router-dom";
import useStyles from "./Dashboard.styles";
import DiariesList from "./diaries/List";
import ClaimWithoutActivity from "./claims/ClaimWithoutActivity";
import OpenClaimsByAdjuster from "./openClaimsByAdjuster";
import ExpiredBarChart from "./diaries/ExpiredBarChart";
import ClaimLossesByAdjuster from "./ClaimLossesByAdjuster/ClaimLossesByAdjuster";
import DuplicateBarChart from "./payments/DuplicateBarChart";

export const DashboardRedirect = () => {
  return <Redirect to="/dashboard" />;
};

export default () => {
  const container = useRef(null);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div ref={container} className={`${classes.container} tc-page-container`}>
        <div className={`${classes.content} tc-page-content`}>
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={8}>
              <DiariesList />
            </Grid>
            <Grid item xs={4}>
              <ExpiredBarChart />
            </Grid>
            <Grid item xs={6}>
              <ClaimWithoutActivity />
            </Grid>
            <Grid item xs={6}>
              <OpenClaimsByAdjuster />
            </Grid>
            <Grid item xs={6}>
              <DuplicateBarChart />
            </Grid>
            <Grid item xs={6}>
              <ClaimLossesByAdjuster />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
