/* eslint-disable jsx-a11y/control-has-associated-label */
import { InputLabel, FormControl, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { memo } from "react";
import "react-quill/dist/quill.snow.css"; // ES6
import claimMergeTagOptions from "./claimMergeTags";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  editorSelectMergeTag: {
    top: "-12px",
    width: "150px"
  },
  listSub: {
    position: "relative !important"
  },
  title: { marginBottom: 5, padding: "7px", borderBottom: "1px solid #ccc" }
}));

const CustomToolbar = memo(({ onSelectTag, readOnly, claimMergeTags, toolbarId }) => {
  const classes = useStyles();
  const titles = claimMergeTagOptions
    .filter(t => t.isTitle)
    .map(t => ({
      id: `title-${t.identification}`,
      text: t.text,
      tags: claimMergeTagOptions.filter(o => o.parentId === t.identification).map(({ text }, ix) => ({ id: `tag-${t.identification}-${ix}`, text }))
    }));
  return (
    <div id={toolbarId}>
      <span className="ql-formats">
        <button type="button" className="ql-header" value="1" />
        <button type="button" className="ql-header" value="2" />
        <select className="ql-font ql-picker" defaultValue="" onChange={e => e.persist()}>
          <option value="serif" />
          <option value="monospace" />
          <option />
        </select>
      </span>
      <span className="ql-formats">
        <select className="ql-size ql-picker" defaultValue="" onChange={e => e.persist()}>
          <option value="small" />
          <option value="large" />
          <option value="huge" />
          <option />
        </select>
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-bold" />
        <button type="button" className="ql-italic" />
        <button type="button" className="ql-underline" />
        <button type="button" className="ql-strike" />
        <button type="button" className="ql-blockquote" />
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-list" value="ordered" />
        <button type="button" className="ql-list" value="bullet" />
        <button type="button" className="ql-indent" value="-1" />
        <button type="button" className="ql-indent" value="+1" />
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-link" />
        <button type="button" className="ql-image" />
        <button type="button" className="ql-video" />
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-clean" />
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-align" value="" />
        <button type="button" className="ql-align" value="center" />
        <button type="button" className="ql-align" value="right" />
        <button type="button" className="ql-align" value="justify" />
      </span>
      <span className="ql-formats">
        <select className="ql-color" title="Colour">
          <option value="rgb(255,0,0)" />
          <option value="rgb(0,255,0)" />
          <option value="rgb(0,0,255)" />
          <option value="#FE9A32" />
          <option value="#F7CB4D" />
          <option value="#7BAAF7" />
          <option value="#BA68C8" />
          <option value="#3DC789" />
          <option value="#F7A7C0" />
        </select>
        <select className="ql-background" title="Background">
          <option value="rgb(255,0,0)" />
          <option value="rgb(0,255,0)" />
          <option value="rgb(0,0,255)" />
          <option value="#FE9A32" />
          <option value="#F7CB4D" />
          <option value="#7BAAF7" />
          <option value="#BA68C8" />
          <option value="#3DC789" />
          <option value="#F7A7C0" />
        </select>
      </span>
      {claimMergeTags && !readOnly && (
        <span className="ql-formats">
          <FormControl className={classes.editorSelectMergeTag}>
            <InputLabel htmlFor="grouped-select">Add Merge Tags</InputLabel>
            <Select id="grouped-select" value="">
              {titles.map(title => (
                <div key={title.id}>
                  <Typography className={`tc-title ${classes.title}`}>{title.text}</Typography>
                  {title.tags.map(tag => (
                    <MenuItem key={tag.id} style={{ fontSize: 14 }} onClick={() => onSelectTag(tag.text)}>
                      {tag.text}
                    </MenuItem>
                  ))}
                </div>
              ))}
            </Select>
          </FormControl>
        </span>
      )}
    </div>
  );
});

CustomToolbar.propTypes = { onSelectTag: PropTypes.func, readOnly: PropTypes.bool, claimMergeTags: PropTypes.bool, toolbarId: PropTypes.string };
CustomToolbar.defaultProps = { onSelectTag: () => {}, readOnly: false, claimMergeTags: false, toolbarId: "toolbar" };

export default CustomToolbar;
