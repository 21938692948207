import React from "react";
import * as yup from "yup";
import { dateSchema, isNumber, rootParent } from "../../../../common/yup";

const isNotBill = ctx => {
  const { indexTypeId } = rootParent(ctx);
  return indexTypeId !== 1;
};

export const indexSchema = yup.object().shape({
  indexTypeId: yup.number().required("Type Required"),
  folderId: yup.number().test("default", "Folder Required", (val, ctx) => !isNotBill(ctx) || isNumber(val)),
  billTypeId: yup.number().test("default", "Bill Type Required", (val, ctx) => isNotBill(ctx) || isNumber(val)),
  payee: yup.object().shape({
    amount: yup.number().test("default", "Amount Required", (val, ctx) => isNotBill(ctx) || (isNumber(val) && val > 0)),
    serviceFrom: dateSchema("Date of Service Required", (val, ctx) => isNotBill(ctx) || val),
    serviceTo: dateSchema(),
    id: yup.number().nullable(),
    name: yup.string().test("default", "Payee Required", (val, ctx) => {
      if (isNotBill(ctx) || ctx?.parent?.id) return true;
      if (val)
        return ctx.createError({
          message: (
            <>
              Vendor not found.
              <br />
              Please send it for review
            </>
          ),
          path: "payee.id"
        });
      return false;
    })
  })
});

const string = msg =>
  yup
    .string()
    .nullable()
    .test("default", msg || "At least 3 characters required", v => !v || v.length > 2);

export const searchSchema = yup.object().shape({
  number: string(),
  dateOfLoss: dateSchema("", v => !v),
  claimantName: string(),
  dateTo: dateSchema("", v => !v),
  statusIds: yup
    .array()
    .of(yup.number())
    .nullable(),
  clientNumber: string()
});
