import React, { useState, useContext, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { getRecovery, postRecovery, updateRecovery } from "../../../../redux/actions/claim/financial";
import { useLoading } from "../../../common/Loading";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import { useRouteParam } from "../../../../common";
import CustomModal from "../../../common/CustomModal";
import RecoveryForm from "./RecoveryForm";
import RecoveryList from "./RecoveryList";
import ValidationContext from "./ValidationContext";
import useYupValidationSchema from "../../../common/customHooks/useYupValidationSchema";

const schema = yup.object().shape({
  statusId: yup.string().required("Status is required")
});

const Recovery = () => {
  const claimId = useRouteParam("claimId");
  const dispatch = useDispatch();
  const [fireValidation, setFireValidation] = useState(false);
  const { actions, Loading } = useLoading(true);
  const { hideLoading } = actions;

  const [typeSelected, setTypeSelected] = useState({});
  const [recovery, setRecovery] = useState({});

  const [showTypes, setShowTypes] = useState(false);
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "financial");

  const { handleValidateSchema } = useYupValidationSchema(schema);

  useEffect(() => {
    if (access.read) {
      dispatch(getRecovery(claimId, hideLoading, hideLoading));
    }
  }, []);

  useEffect(() => {
    if (showTypes) setShowTypes(prev => !prev);
  }, [typeSelected]);

  const handleSave = () => {
    const { isValid } = handleValidateSchema(recovery);

    if (!isValid) {
      setFireValidation(true);

      return;
    }
    if (recovery?.id) {
      dispatch(updateRecovery(recovery, setRecovery({}), setTypeSelected({})));
    } else {
      dispatch(postRecovery({ ...recovery, claimId, typeId: typeSelected?.id }, setRecovery({}), setTypeSelected({})));
    }
  };

  // const handleRemove = () => {};

  const handleTypeSelected = useCallback(data => {
    setTypeSelected(data);
  }, []);

  const handleEdit = useCallback(data => {
    setRecovery(data);
  }, []);
  return (
    <div>
      {Loading}
      <RecoveryList {...{ editRecovery: handleEdit, handleTypeSelected }} />
      <CustomModal
        {...{
          openModal: (typeSelected?.id || recovery?.id) ?? false, // it means that the User chose a Schedule type
          title: `${typeSelected?.text || recovery?.type} Recoveries`,
          onToggle: () => {
            setTypeSelected({});
            setRecovery({});
          },
          maxWidth: "md",
          onAction: handleSave,
          actionBtnText: "SAVE"
        }}
      >
        <ValidationContext.Provider value={{ fireValidation }}>
          <RecoveryForm
            {...{
              recovery,
              onChange: setRecovery
            }}
          />
        </ValidationContext.Provider>
      </CustomModal>
    </div>
  );
};

export default Recovery;
