import React, { shallowEqual } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import ClaimStatus from "../claims/ClaimStatus";
import ClaimProcessingStatus from "../claims/ClaimProcessingStatus";
import MaterialTable from "../common/table/MaterialTable";
import { table, tableTheme } from "../common/index.styles";
import { associateCatastrophe as associate } from "../../redux/actions/catastropheAction";
import useCustomConfirm from "../common/customHooks/useCustomConfirm";

const AssociatedList = ({ editMode }) => {
  const storeData = useSelector(store => store.catastrophe, shallowEqual);

  const {
    catastrophe: { id, associatedList }
  } = storeData || {};
  const dispatch = useDispatch();

  const handleDelete = rowData => {
    dispatch(associate({ catastropheId: parseInt(id, 0), type: rowData.type, id: rowData.id, isRemove: true }));
  };

  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleDelete, "Cancel", "Yes, Dissociate");
  const handleConfirmDelete = rowData => {
    handleCustomConfirm({
      oData: rowData,
      titleText: rowData.type === "Claim" ? "Dissociate Claim" : "Dissociate Occurrence",
      messageText:
        rowData.type === "Claim" ? (
          <>
            <p>{`The following Claim will be removed from this Catastrophe: ${rowData.number}.`}</p>
            <p>Are you sure you want to proceed?</p>
          </>
        ) : (
          <>
            <p>{`The following Occurrence will be removed from this Catastrophe: ${rowData.number}.`}</p>
            <p>Are you sure you want to proceed?</p>
          </>
        )
    });
  };

  const claimsColumns = [
    {
      title: "Claim Number",
      width: 280,
      customSort: (a, b) => (a.number || "").localeCompare(b.number || ""),
      render: rowData => (
        <div style={{ display: "flex" }}>
          <span style={{ marginRight: 5 }}>
            <Link to={`/claim/${rowData.id}`}>{rowData.number}</Link>
          </span>
          <span style={{ marginRight: 5 }}>
            <ClaimStatus status={rowData.status} />
          </span>
          <ClaimProcessingStatus processingStatus={rowData.processingStatus} />
        </div>
      )
    },
    { title: "Claimant", field: "claimantFullName", width: 200 },
    {
      title: "DOL",
      field: "dateofLoss",
      render: rowData => (rowData.dateofLoss !== null ? moment(rowData.dateofLoss).format("M/D/YYYY") : "")
    },
    {
      title: "Line of Business",
      field: "claimLineOfBusinessCode"
    },
    { title: "Loss Type", field: "claimLossTypeGroup" },
    {
      title: "Location",
      render: rowData => `${rowData.address1}${rowData.address2 ? `, ${rowData.address2}` : ""}; ${rowData.city} ${rowData.code} ${rowData.zipCode}`
    }
  ];
  const occurrenceColumns = [
    {
      title: "Occurrence",
      field: "occurrenceName",
      width: 50,
      defaultGroupOrder: 1
    }
  ];
  const tableProps = {
    id: "table-associated-claims-occ",
    title: "Claims/Occurrence",
    columns: claimsColumns.concat(occurrenceColumns),
    data: associatedList,
    hidePaging: associatedList.length <= 5,
    options: {
      search: false
    },
    hideExportButton: true,
    grouping: true,
    customGroupConfig: { check: false, del: editMode, deleteAction: handleConfirmDelete },
    actions: editMode
      ? [
          {
            icon: () => <DeleteIcon />,
            tooltip: "Dissociate Claim",
            onClick: (_e, rowData) => {
              _e.stopPropagation();
              handleConfirmDelete({ id: rowData.id, type: "Claim", number: rowData.number });
            }
          }
        ]
      : []
  };
  const classTable = table();
  return (
    <div style={{ marginBottom: 20, minHeight: 350 }} className={classTable.fullHeader}>
      <MuiThemeProvider theme={tableTheme}>
        <MaterialTable {...tableProps} />
      </MuiThemeProvider>
      <Confirm />
    </div>
  );
};

AssociatedList.propTypes = {
  editMode: PropTypes.bool
};

AssociatedList.defaultProps = {
  editMode: true
};
export default AssociatedList;
