import axios from "axios";
import React, { useEffect, useState } from "react";
import { useContext, actionTypes } from "../../context";
import { API } from "../../../../../redux/actions/actionTypes";
import { useDebounce } from "../../../../../common";
import { isBeforeToday } from "../../../../../common/yup";
import Form from "./Form";

export default () => {
  const [
    {
      coverageTypes,
      incident,
      insured: { id: insuredId },
      insProperties
    },
    dispatch,
    ,
    { handleValidation }
  ] = useContext();
  const [loaded, setLoaded] = useState(false);
  const findOccsDebounced = useDebounce(
    params => axios.get(`${API.URL}/occurrence/search`, { params: { ...params, insuredId, shortAddress: true, countTopResults: 3 } }),
    500
  );
  const { address1, city, dateofLoss, occurrenceId, stateId, zipCode } = incident;
  useEffect(() => {
    const params = { date: dateofLoss, stateId, city, zipCode, address1 };
    if (Object.keys(params).some(k => params[k]))
      findOccsDebounced(params).then(({ data }) => {
        dispatch({ type: actionTypes.SET_OCCURRENCES, payload: data });
        if (occurrenceId > 0 && !data.some(({ id }) => id === occurrenceId))
          dispatch({
            type: actionTypes.SET_INCIDENT,
            payload: { name: "occurrenceId", value: -2 }
          });
      });
  }, [address1, city, dateofLoss, stateId, zipCode]);
  const isPropLiab = coverageTypes.some(lob => lob.id === 3 && lob.lossTypeGroups.some(ltg => ltg.checked));
  useEffect(() => {
    if (isPropLiab && dateofLoss && isBeforeToday(dateofLoss) && (dateofLoss !== insProperties.dateofLoss || insuredId !== insProperties.insuredId))
      axios
        .get(`${API.URL}/property/insured`, { params: { insuredId, dateOfLoss: dateofLoss } })
        .then(({ data }) => dispatch({ type: actionTypes.SET_INS_PROPERTIES, payload: { insuredId, dateofLoss, data } }));
  }, [dateofLoss]);
  useEffect(() => {
    if (loaded) handleValidation();
    else setLoaded(true);
  }, [incident]);
  return <Form />;
};
