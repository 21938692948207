import React from "react";
import PropTypes from "prop-types";
import LabelValue from "./LabelValue";

const MultipleLabels = ({ data }) => {
  return data?.map(options => <LabelValue {...options} />);
};

MultipleLabels.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default MultipleLabels;
