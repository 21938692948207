import React, { Fragment, memo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import CodeList from "../../common/code/List";
import CollapsibleBox from "../../common/CollapsibleBox";
import useOnToggleBoxes from "../../common/customHooks/useHandleBox";

const Industry = ({ data, setChange }) => {
  const { open, onToggle } = useOnToggleBoxes({ initialValue: { industry: true } });
  const { codeType: codeTypes, codeDivision, codes: storeCodes } = useSelector(store => store.common);
  const handleAddCode = code => {
    if (data.findIndex(c => c.id === code.id) === -1) {
      const codeType = codeTypes.find(t => t.id === code.codeTypeId).text;
      const codeDivisionName = (codeDivision.find(t => t.id === code.divisionId) || {}).text;
      const codeName = storeCodes.find(t => t.id === code.id).text;
      setChange({ type: "ADD_CODE", payload: { code: { ...code, codeType, codeDivisionName, codeName } } });
    }
  };

  const handleDeleteCode = code => {
    if (data.findIndex(c => c.id === code.id) !== -1) {
      setChange({ type: "REMOVE_CODE", payload: { code } });
    }
  };
  return (
    <Fragment key="Vendor-Add-Industry">
      <CollapsibleBox title="Industry" name="industry" open={open.industry} onToggle={onToggle}>
        <CodeList codes={data} viewMode={false} onAdd={handleAddCode} onDelete={handleDeleteCode} />
      </CollapsibleBox>
    </Fragment>
  );
};

Industry.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setChange: PropTypes.func.isRequired
};

export default memo(Industry);
