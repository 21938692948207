export const initialState = {
  codes: [],
  basicInformation: {},
  clientStatus: {},
  agentInformation: {},
  isSubmitting: false,
  operatingStates: []
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_AGENT_INFORMATION":
      return { ...state, agentInformation: { ...state.agentInformation, [action.payload.name]: action.payload.value } };
    case "ADD_CODE":
      return { ...state, codes: [...state.codes, action.payload.code] };
    case "REMOVE_CODE":
      return { ...state, codes: state.codes.filter(code => code.id !== action.payload.id) };
    case "ADD_OPERATING_STATE":
      return { ...state, operatingStates: [...state.operatingStates, { ...action.payload.data, idN: Math.floor(Math.random() * 25) }] };
    case "SET_OPERATING_STATE":
      return { ...state, operatingStates: state.operatingStates.map(o => (o.idN === action.payload.data.idN ? action.payload.data : o)) };
    case "DELETE_OPERATING_STATE":
      return { ...state, operatingStates: state.operatingStates.filter(o => o.idN !== action.payload.data.idN) };

    default:
      return state;
  }
};
