import { makeStyles, withStyles } from "@material-ui/core";

const useActivityInfoStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(1.5, 4, 2, 3), height: "100%" },
  title: { color: "#428302", fontSize: 16, fontWeight: 500, marginBottom: theme.spacing(2) },
  location: { alignItems: "center", marginBottom: theme.spacing(2) },
  legend: { marginBottom: theme.spacing(1) },
  mark: { display: "inline-block", width: 11, height: 11, borderRadius: 4, marginRight: 8 },
  days1: { backgroundColor: "#428302" },
  days2: { backgroundColor: "#F29120" },
  days3: { backgroundColor: "#E74C3C" },
  progressDays: {
    srtoke: "#000",
    dominantBaseline: "middle",
    textAnchor: "middle",
    fontSize: 30,
    fontWeight: 500
  },
  progressLabel: {
    srtoke: "#000",
    dominantBaseline: "middle",
    textAnchor: "middle",
    fontSize: 8
  }
}));

const useClaimantInfoStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(1.5, 4, 1.5, 3), marginBottom: theme.spacing(2), height: "100%" },
  title: { color: "#428302", fontSize: 16, fontWeight: 500 },
  claimant: { color: "#000", fontSize: 16, fontWeight: 500 },
  label: { fontSize: 11, color: "#898F91" }
}));

const useClaimInfoStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(1.5, 4, 1.5, 3), marginBottom: theme.spacing(2), height: "100%" },
  title: { color: "#428302", fontSize: 16, fontWeight: 500 },
  claimNo: { color: "#000", fontSize: 16, fontWeight: 500 },
  status: { borderRadius: "50%", padding: 5, width: 24, height: 24, "& svg": { fontSize: 14 } },
  open: { backgroundColor: "#D2EEB6", "& svg": { color: "#428302" } },
  close: { backgroundColor: "#FCB7B0", "& svg": { color: "#E74C3C" } },
  reopen: { backgroundColor: "#7C9DAA", "& svg": { color: "#004763", transform: "scaleX(-1)" } },
  voided: { backgroundColor: "#F6C096", "& svg": { color: "#FE7F1E" } },
  pending: { backgroundColor: "#FCF3BC", "& svg": { color: "#EFD10F" } },
  pStatus: { fontSize: 24, marginBottom: -6 },
  accepted: { color: "#428302" },
  denied: { color: "#E74C3C" },
  pPending: { borderRadius: "50%", padding: 3, width: 24, height: 24, border: "solid 2px #efd10f", "& svg": { fontSize: 14, color: "#efd10f" } },
  rectangle: { backgroundColor: "#DAEAEF", borderRadius: 4, padding: theme.spacing(0.125, 1), textAlign: "center" },
  label: { fontSize: 11, color: "#898F91" },
  link: { marginBottom: -3, color: "#107A98" }
}));

const useDiariesInfoStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(3, 5, 3, 5), height: "100%" },
  title: { color: "#428302", fontSize: 16, fontWeight: 500 },
  diaries: {
    "& > div": { padding: theme.spacing(2.75, 0) },
    "& > div:not(:last-of-type)": {
      borderBottom: "solid 0.5px #707070"
    }
  },
  number: {
    backgroundColor: "#107A98",
    borderRadius: "50%",
    color: "#fff",
    width: 20,
    height: 20,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    lineHeight: 1
  },
  label: { color: "#898F91", fontSize: 11, paddingRight: 3 },
  ownerEllipsis: {
    width: "100%",
    whiteSpace: "nowrap",
    display: "inline-flex",
    flexDirection: "column"
  },
  divOwner: { textOverflow: "ellipsis", overflow: "hidden", marginTop: "-3px", whiteSpace: "nowrap" }
}));

const useLitigationInfoStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(1.5, 4, 2, 3), height: "100%" },
  title: { color: "#428302", fontSize: 16, fontWeight: 500, marginBottom: theme.spacing(2.6) },
  label: { fontSize: 11, color: "#898F91" },
  gavel: { width: 16, height: 16, marginRight: 5 }
}));

const useOccurrenceCatastropheInfoStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(3, 5, 3, 5), height: "100%" },
  label: { fontSize: 11, color: "#898F91", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" },
  linkOcc: { height: 36, display: "flex", alignContent: "center", overflow: "hidden" },
  textEllipsis: { overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" },
  textEllipsisWithoutNoWrap: { overflow: "hidden", textOverflow: "ellipsis" }
}));

const usePolicyInfoStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(1.5, 4, 2, 3), height: "100%" },
  title: { color: "#428302", fontSize: 16, fontWeight: 500, marginBottom: theme.spacing(1.5) },
  div: { marginBottom: theme.spacing(0.7) },
  label: { color: "#898F91", fontSize: 11, paddingRight: 3 },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    "& > tbody > tr > td": { borderBottom: "solid 0.5px #898F91", padding: theme.spacing(0.45, 0) },
    "& > tbody > tr > td:first-of-type": { paddingRight: 5 }
  },
  limits: { backgroundColor: "#F4F8F9", padding: theme.spacing(1), borderRadius: 4 }
}));

const useReservesFinancialInfoStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(3, 4, 3, 3), height: "100%" },
  title: { color: "#428302", fontSize: 16, fontWeight: 500, marginBottom: 5 },
  label: { fontSize: 11, color: "#898F91" },
  amount: { marginBottom: theme.spacing(1) },
  noReserve: { color: "#E74C3C" },
  mark: { display: "inline-block", width: 11, height: 11, borderRadius: 4, marginRight: 4 },
  outstanding: { backgroundColor: "#A7CE05" },
  total: { backgroundColor: "#1599BC" }
}));

const useReserveBreakdownStyles = withStyles(theme => ({
  table: {
    width: "100%",
    "& > tbody > tr > td": {
      padding: theme.spacing(0.5, 0),
      whiteSpace: "nowrap"
    },
    "& > tbody > tr > td:nth-of-type(2)": {
      width: "100%",
      padding: theme.spacing(0.5, 2.5)
    }
  },
  progress: {
    width: "100%",
    height: 11,
    backgroundColor: "#F3F3F3",
    borderRadius: 4,
    overflow: "hidden",
    "& > div": {
      cursor: "default",
      height: 11,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.complex
      })
    }
  }
}));

const useTeamInfoStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(1.5, 2, 0, 2), height: "100%" },
  container: { marginBottom: theme.spacing(2) },
  item: { flexGrow: 1, "& > div": { marginBottom: "0 !important" } }
}));

export {
  useActivityInfoStyles,
  useClaimantInfoStyles,
  useClaimInfoStyles,
  useDiariesInfoStyles,
  useLitigationInfoStyles,
  useOccurrenceCatastropheInfoStyles,
  usePolicyInfoStyles,
  useReservesFinancialInfoStyles,
  useTeamInfoStyles,
  useReserveBreakdownStyles
};
