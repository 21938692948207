import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import useYupValidationSchema from "../customHooks/useYupValidationSchema";
import { TextInput } from "../inputs";

const AddFolderDialog = ({ open, onClose, error, folderId, onSubmit }) => {
  const [state, setState] = useState({ name: "", description: "", folderId });

  const { handleValidateSchema, validationMessages } = useYupValidationSchema(
    yup.object().shape({
      name: yup
        .string()
        .nullable()
        .required("Title Required")
    })
  );

  useEffect(() => {
    if (open) setState({ name: "", description: "", folderId });
  }, [open, folderId]);
  const onChange = ({ target: { name, value } }) => {
    handleValidateSchema({ ...state, [name]: value });
    setState({ ...state, [name]: value });
  };

  const errorMessage = error || validationMessages.name;
  return (
    <Dialog {...{ open, onClose }} aria-labelledby="delete-file-dialog">
      <DialogTitle id="delete-file-dialog">Add Folder</DialogTitle>
      <DialogContent>
        <TextInput {...{ value: state.name, name: "name", onChange, error: errorMessage, label: "Folder Name" }} />
        <TextInput {...{ value: state.description, name: "description", onChange, label: "Description", rows: 2 }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={e => {
            const { isValid } = handleValidateSchema(state);
            if (isValid) onSubmit(state)(e);
          }}
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddFolderDialog.propTypes = {
  error: PropTypes.string.isRequired,
  folderId: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

AddFolderDialog.defaultProps = {
  folderId: null
};

export default AddFolderDialog;
