import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { MoreHorizOutlined, CheckCircleOutline, HighlightOffOutlined } from "@material-ui/icons";

const styles = makeStyles(() => ({
  status: { fontSize: 24, marginBottom: -6 },
  accepted: { color: "#428302" },
  denied: { color: "#E74C3C" },
  pending: { borderRadius: "50%", padding: 3, width: 24, height: 24, border: "solid 2px #efd10f", "& svg": { fontSize: 14, color: "#efd10f" } }
}));

const ClaimProcessingStatus = ({ processingStatus, divMode }) => {
  const classes = styles();
  switch (processingStatus) {
    case "Pending":
      if (divMode) {
        return (
          <div className={classes.pending}>
            <MoreHorizOutlined />
          </div>
        );
      }
      return (
        <span className={classes.pending}>
          <MoreHorizOutlined />
        </span>
      );
    case "Accepted":
      return <CheckCircleOutline className={clsx(classes.status, classes.accepted)} viewBox="2 2 20 20" />;
    case "Denied":
      return <HighlightOffOutlined className={clsx(classes.status, classes.denied)} viewBox="2 2 20 20" />;
    default:
      return null;
  }
};

ClaimProcessingStatus.propTypes = {
  processingStatus: PropTypes.string.isRequired,
  divMode: PropTypes.bool
};

ClaimProcessingStatus.defaultProps = {
  divMode: true
};

export default ClaimProcessingStatus;
