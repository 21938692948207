import React from "react";
import PropTypes from "prop-types";
import { TimePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { AccessTime } from "@material-ui/icons";
import { HocInput } from "./HocInput";

const TimeInput = ({ error, format, keyboard, name, onChange, ...other }) => {
  const props = {
    ampm: false,
    name,
    fullWidth: true,
    keyboardIcon: <AccessTime />,
    onChange: m => onChange({ target: { name, value: m && m.isValid() ? m.format("YYYY-MM-DDTHH:mm:00") : "" } }),
    ...other
  };
  if (error) {
    props.helperText = error;
    props.error = true;
  }
  if (keyboard) return <KeyboardTimePicker {...props} />;
  return <TimePicker {...props} />;
};

TimeInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  format: PropTypes.string,
  error: PropTypes.string,
  autoOk: PropTypes.bool,
  keyboard: PropTypes.bool
};

TimeInput.defaultProps = {
  placeholder: null,
  value: null,
  variant: "inline",
  format: "MM/DD/YYYY - HH:mm",
  error: null,
  autoOk: true,
  keyboard: false
};

export default HocInput(TimeInput);
