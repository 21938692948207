import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import LabelValue from "../../../common/LabelValue";
import { box } from "../../../common/index.styles";

const Read = ({ basicInformation }) => {
  const {
    parentId,
    type,
    fullName,
    id,
    category,
    isActive,
    taxIDType,
    taxID,
    phone1,
    fax,
    email,
    webSite,
    description,
    location,
    w9RequestedOn,
    w9ReceivedOn,
    w9EnteredOn,
    hasMultipleLocations,
    mailingLocation
  } = basicInformation;
  const labelValue = (label, value) => <LabelValue {...{ label, value }} />;
  const classesBox = box();
  return (
    <Fragment key="vendor-basic-view">
      <Grid container spacing={2}>
        <Grid item xs={3}>
          {labelValue("Type", type)}
          {labelValue("Name", fullName)}
          {labelValue("Number", id)}
          {labelValue("Description", description)}
        </Grid>
        <Grid item xs={3}>
          {labelValue("Status", isActive ? "Active" : "Inactive")}
          {labelValue("Category", category)}
          {labelValue("Tax Id", taxIDType !== null ? `${taxIDType}: ${taxID || "-"}` : taxID)}
          {labelValue("Phone", phone1)}
        </Grid>
        <Grid item xs={3}>
          {labelValue("Email Address", email)}
          {labelValue("Web Site", webSite)}
          {labelValue("Fax Number", fax)}
        </Grid>
        <Grid item xs={3}>
          <LabelValue label="Address" value={location} isAddress />
          <LabelValue label="Mailing Address" value={mailingLocation || {}} isAddress />
          {parentId === null ? labelValue("Multiple Locations", hasMultipleLocations === true ? "Yes" : "No") : labelValue("Category", category)}
        </Grid>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <Typography variant="subtitle2" className={classesBox.title}>
          W9 Information
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <LabelValue label="Date Requested" value={w9RequestedOn} isDate />
        </Grid>
        <Grid item xs={3}>
          <LabelValue label="Date Received" value={w9ReceivedOn} isDate />
        </Grid>
        <Grid item xs={3}>
          <LabelValue label="Date Entered to the System" value={w9EnteredOn} isDate />
        </Grid>
      </Grid>
    </Fragment>
  );
};
Read.propTypes = {
  basicInformation: PropTypes.objectOf(PropTypes.any).isRequired
};
export default Read;
