import { useDispatch } from "react-redux";
import { setFinancial } from "../../../../../../redux/actions/client/insured/policy/policy";
import { usePolicyContext } from "../context";

export const usePolicyFinancial = onToggle => {
  const {
    actions: { setState, showLoading, hideLoading },
    isAdd,
    policyId,
    loading,
    state: { financial }
  } = usePolicyContext();
  const onChange = ({ target: { name, value } }) => setState(ps => ({ ...ps, financial: { ...ps.financial, [name]: value } }));

  if (isAdd) return { isAdd, onChange, financial };
  const dispatch = useDispatch();
  const onSave = () => {
    showLoading();
    return dispatch(setFinancial({ policyId, ...financial }, onToggle(), hideLoading));
  };
  return { isAdd, loading, onChange, onSave, financial };
};

export const paths = [
  ["Volume Discount", "volumeDiscount", true],
  ["Increased Limits", "increasedLimits"],
  ["Discount factor before modifier", "discountBeforeModifier"],
  ["Experience Modifier", "experienceModifier"],
  ["Discount factor after modifier", "discountAfterModifier"]
];
