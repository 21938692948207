import { takeLatest as sagaTakeLatests } from "redux-saga/effects";
import { toast } from "react-toastify";
import { CATASTROPHE, API } from "../actions/actionTypes";
import { objectToFormData } from "../../api/apiUtils";
import * as docs from "./common/documents";
import rootSagaGenerator from "./common/rootSagaGenerator";
import { takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "./common/sagaGenerator";
import {
  getSuccess,
  getAllSuccess,
  postSuccess,
  setSuccess,
  delSuccess,
  findSuccess,
  associateSuccess,
  getReservesSuccess,
  uploadFilesSuccess,
  deleteFileSuccess,
  getFilterFieldsSuccess
} from "../actions/catastropheAction";

export default rootSagaGenerator({
  name: "catastrophe",
  sagas: [
    takeLatest({
      type: CATASTROPHE.GETALL,
      actionSuccess: getAllSuccess,
      errorMessage: "Error loading catastrophes",
      url: `${API.URL}/catastrophe/getAll`
    }),
    takeLatest({
      type: CATASTROPHE.GET,
      actionSuccess: getSuccess,
      errorMessage: "Error loading catastrophe",
      url: `${API.URL}/catastrophe`,
      params: ({ id }) => ({ id })
    }),
    takeLatestPost({
      type: CATASTROPHE.POST,
      actionSuccess: postSuccess,
      successMessage: "Catastrophe successfully created",
      errorMessage: "Unable to create the catastrophe",
      url: `${API.URL}/catastrophe`,
      params: ({ catastrophe }) => catastrophe
    }),
    takeLatestSet({
      type: CATASTROPHE.SET,
      actionSuccess: setSuccess,
      successMessage: "Information successfully updated",
      errorMessage: "Unable to update the information",
      url: `${API.URL}/catastrophe`,
      params: ({ catastrophe }) => catastrophe
    }),
    takeLatestDel({
      type: CATASTROPHE.DEL,
      actionSuccess: delSuccess,
      successMessage: "Catastrophe successfully deleted.",
      errorMessage: "Unable to delete the catastrophe.",
      url: `${API.URL}/catastrophe`,
      params: ({ id }) => ({ id })
    }),
    takeLatest({
      type: CATASTROPHE.FIND,
      actionSuccess: findSuccess,
      errorMessage: "Error finding catastrophe",
      url: `${API.URL}/catastrophe/find`,
      params: ({ filter }) => filter
    }),
    takeLatestSet({
      type: CATASTROPHE.ASSOCIATE_CATASTROPHE,
      actionSuccess: associateSuccess,
      onSuccess: ({ associated }) => toast.success(`${associated.type} successfully ${associated.isRemove ? "removed" : "associated"}`),
      onError: ({ associated }) =>
        toast.success(`Unable to ${associated.isRemove ? "remove" : "associate"} the ${associated.type} from this catastrophe`),
      method: "PUT",
      url: `${API.URL}/catastrophe/associate`,
      params: ({ associated }) => associated
    }),
    takeLatest({
      type: CATASTROPHE.GET_RESERVES,
      actionSuccess: getReservesSuccess,
      errorMessage: "Error getting Reserves",
      url: `${API.URL}/reserve/catastropheReserves`,
      params: ({ catastropheId }) => catastropheId
    }),
    takeLatestPost({
      type: CATASTROPHE.UPLOAD_FILES,
      url: `${API.URL}/catastrophe/files`,
      params: ({ documents }) => objectToFormData(documents),
      actionSuccess: uploadFilesSuccess,
      successMessage: "Document(s) successfully added.",
      errorMessage: "Unable to add the document(s)."
    }),
    takeLatestDel({
      type: CATASTROPHE.DELETE_FILE,
      actionSuccess: deleteFileSuccess,
      successMessage: "Document successfully deleted. ",
      errorMessage: "Unable to delete the document ",
      url: `${API.URL}/catastrophe/file`,
      isData: true,
      params: ({ data }) => data
    }),
    sagaTakeLatests(CATASTROPHE.OPEN_FILE, docs.openFile(CATASTROPHE.OPEN_FILE_SUCCESS)),
    takeLatest({
      type: CATASTROPHE.GET_FILTER_FIELD,
      actionSuccess: getFilterFieldsSuccess,
      params: ({ catastropheId }) => ({ catastropheId }),
      errorMessage: "Error loading Profile Filters Fields",
      url: `${API.URL}/catastrophe/filterfields`
    })
  ]
});
