import React from "react";
import LinkButton from "../../../../../common/LinkButton";

const yesNo = data => (data === true || (data || "").toLowerCase() === "yes" ? "YES" : "NO");
const columns = onClick => [
  {
    title: "Coverage",
    field: "coverageName",
    ...(onClick
      ? {
          render: r => (
            <LinkButton blue onClick={() => onClick(r)}>
              {r.coverageName}
            </LinkButton>
          )
        }
      : {})
  },
  { title: "Limit", field: "limit", type: "currency" },
  { title: "Statutory Limit", field: "statutoryLimit", render: r => yesNo(r.statutoryLimit) },
  { title: "SIR", field: "sir", type: "currency" },
  { title: "Deductible", field: "deductible", type: "currency" },
  {
    title: "Reporting Threshold",
    field: "reportingThresholdField",
    align: "right",
    render: r => (r.reportingThreshold && r.reportingThresholdField ? `${r.reportingThreshold === "0" ? "$" : "%"}${r.reportingThresholdField}` : "")
  },
  { title: "Different Corridor Amount", field: "differentCorridorAmount", render: r => yesNo(r.differentCorridorAmount) }
];

export default columns;
