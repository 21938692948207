import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import qs from "qs";
import { useSelector, shallowEqual } from "react-redux";
import { Grid, Divider, Button } from "@material-ui/core";
import { DateTimeInput, TextInput, SelectInput, PhoneInput, IntegerInput } from "../../inputs";
import styles from "./style";
import Search from "../../SearchSuggestions/InputSuggestionsDebounced";
import { API } from "../../../../redux/actions/actionTypes";
import { useRouteParam } from "../../../../common";
import validationSchema from "./yup";
import useYupValidationSchema from "../../customHooks/useYupValidationSchema";

const InsuredVehicle = ({ insuredVehicle, onClose, onSave, isLoading }) => {
  const classes = styles();
  const claimId = useRouteParam("claimId");
  const { states, vehicleBodyType, vehicleMake } = useSelector(({ common }) => common, shallowEqual);
  const [state, setState] = useState(insuredVehicle || {});
  const [vehicle, setVehicle] = useState((insuredVehicle || {}).vehicle || {});
  const [driver, setDriver] = useState((insuredVehicle || {}).driver || {});
  const [location, setLocation] = useState(((insuredVehicle || {}).driver || {}).location || {});
  const [loaded, setLoaded] = useState(false);
  const { validationMessages, handleValidateSchema } = useYupValidationSchema(validationSchema);
  const handleValidation = () => handleValidateSchema({ ...state, vehicle, driver: { ...driver, location } });
  useEffect(() => {
    if (loaded) handleValidation();
    else setLoaded(true);
  }, [vehicle, driver, location]);
  const inputProps = (ent, name, aux = true, disabled = false) => {
    const { action, st, error, parse, ...props } = (() => {
      switch (ent) {
        case "vehicle":
          return {
            error: name === "number" && "vehicleId",
            action: setVehicle,
            st: vehicle,
            parse: name === "number" ? val => (val || val === 0 ? String(val) : undefined) : undefined,
            ...(aux
              ? {
                  queryParams: (query => {
                    switch (name) {
                      case "number":
                        return { number: query, vin: null, plateNumber: null };
                      case "vin":
                        return { number: null, vin: query, plateNumber: null };
                      case "plateNumber":
                        return { number: null, vin: null, plateNumber: query };
                      default:
                        return { field: query };
                    }
                  })(vehicle[name]),
                  axiosConfig: { paramsSerializer: params => qs.stringify(params, { indices: false }) },
                  onSelect: op => {
                    setState({ ...state, vehicleId: op.id, vehicle: op });
                    setVehicle(op);
                  },
                  url: `${API.URL}/vehicle/search`
                }
              : {})
          };
        case "driver":
          return { action: setDriver, st: driver, error: `driver.${name}` };
        case "location":
          return { action: setLocation, st: location, error: `driver.location.${name}` };
        default:
          return { action: setState, st: state };
      }
    })();
    return {
      name,
      disabled: disabled || undefined,
      onChange: disabled ? undefined : ({ target: { value } }) => action(ps => ({ ...ps, [name]: value })),
      value: (typeof parse === "function" ? parse : val => val)(st[name]) || undefined,
      error: error ? (validationMessages || {})[error] : undefined,
      ...props
    };
  };
  const handleSubmit = event => {
    event.preventDefault();
    const { isValid } = handleValidation();
    if (isValid) onSave({ ...state, vehicle, driver: { ...driver, location: { ...location } }, claimId });
  };
  return (
    <React.Fragment key="insuredvehicleedit">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs>
            <Search {...inputProps("vehicle", "number")} label="Number *" />
            <Search {...inputProps("vehicle", "vin")} label="Vin" />
            <TextInput {...inputProps("vehicle", "model", false, true)} label="Model" />
          </Grid>
          <Grid item xs>
            <SelectInput {...inputProps("vehicle", "vehicleBodyTypeId", false, true)} label="Vehicle Type" options={vehicleBodyType} />
            <Search {...inputProps("vehicle", "plateNumber")} label="Plate Number" />
            <IntegerInput name="vehicleYear" label="Vehicle Year" value={vehicle.vehicleYear} disabled />
          </Grid>
          <Grid item xs>
            <SelectInput {...inputProps("vehicle", "vehicleMakeId", false, true)} label="Vehicle Make" options={vehicleMake} />
            <SelectInput {...inputProps("vehicle", "vehicleStateId", false, true)} label="Vehicle State" options={states} />
          </Grid>
        </Grid>
        <Divider className={classes.marginDivider} />
        <Grid container spacing={4}>
          <Grid item xs>
            <TextInput {...inputProps("driver", "firstName")} label="Driver's First Name *" />
            <TextInput {...inputProps("driver", "lastName")} label="Driver's Last Name *" />
            <DateTimeInput {...inputProps("driver", "dateOfBirth")} label="Driver's Date of Birth" keyboard />
          </Grid>
          <Grid item xs>
            <TextInput {...inputProps("location", "address1")} label="Driver's Address *" />
            <TextInput {...inputProps("location", "city")} label="Driver's City *" />
            <Grid container>
              <Grid item xs className={classes.pr1}>
                <SelectInput {...inputProps("location", "stateId")} label="Driver's State *" options={states} />
              </Grid>
              <Grid item xs className={classes.pl1}>
                <TextInput {...inputProps("location", "zipCode")} label="Driver's Zip *" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <PhoneInput {...inputProps("driver", "phone")} label="Driver's Phone Number" />
            <TextInput {...inputProps("driver", "email")} label="Driver's Email" />
            <TextInput {...inputProps("driver", "licenseNumber")} label="Driver's License Number" />
          </Grid>
        </Grid>
        <Divider className={classes.marginDivider} />
        <TextInput {...inputProps("state", "damageDescription")} label="Damage Description" rows={4} />
        <Grid container alignItems="flex-start" justify="flex-end" direction="row">
          <div className={classes.buttonWrapper}>
            <Button variant="outlined" color="primary" onClick={onClose}>
              CANCEL
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
              SAVE
            </Button>
          </div>
        </Grid>
      </form>
    </React.Fragment>
  );
};

InsuredVehicle.propTypes = {
  insuredVehicle: PropTypes.objectOf(PropTypes.any).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

InsuredVehicle.defaultProps = {
  isLoading: false
};
export default InsuredVehicle;
