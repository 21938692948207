import { takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { API, ADMIN } from "../../../actions/actionTypes";
import {
  getReservesSuccess,
  postReservesSuccess,
  delReservesSuccess,
  postReserveTypesSuccess,
  getReserveTypesSuccess
} from "../../../actions/admin/claimsSetting";
import sagaGenerator from "../../common/sagaGenerator";

const url = `${API.URL}/claimsetting`;

export default [
  takeLatest(
    ADMIN.CLAIMSETTING.RESERVES.GETALL,
    sagaGenerator({
      url: `${url}/getAllReserveConfig`,
      actionSuccess: getReservesSuccess,
      onError: () => toast.error("Error get claim settings reserve types")
    })
  ),
  takeLatest(
    ADMIN.CLAIMSETTING.RESERVES.POST,
    sagaGenerator({
      url: `${url}/addReserveConfig`,
      method: "POST",
      actionSuccess: postReservesSuccess,
      onSuccess: () => toast.success("Reserve package successfully created"),
      onError: () => toast.error("Unable to create the reserve package"),
      params: ({ reserves }) => reserves
    })
  ),
  takeLatest(
    ADMIN.CLAIMSETTING.RESERVES.DEL,
    sagaGenerator({
      url: `${url}/deleteReserveConfig`,
      method: "DELETE",
      actionSuccess: delReservesSuccess,
      onSuccess: () => toast.success("Reserve package successfully deleted"),
      onError: () => toast.error("Unable to delete the reserve package"),
      params: ({ reserves }) => reserves
    })
  ),
  takeLatest(
    ADMIN.CLAIMSETTING.RESERVES.TYPES.POST,
    sagaGenerator({
      url: `${url}/saveReserveTypes`,
      method: "POST",
      actionSuccess: postReserveTypesSuccess,
      onSuccess: ({ onSuccess }) => {
        toast.success("Reserve package successfully updated");
        onSuccess();
      },
      onError: () => toast.error("Unable to update the reserve package"),
      params: ({ reservetypes }) => reservetypes
    })
  ),
  takeLatest(
    ADMIN.CLAIMSETTING.RESERVES.TYPES.GETALL,
    sagaGenerator({
      url: `${url}/getReserveTypes`,
      actionSuccess: getReserveTypesSuccess,
      onError: () => toast.error("Error get Reserve Types")
    })
  )
];
