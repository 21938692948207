import { makeStyles } from "@material-ui/core";

export const useFormStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  papperWrapper: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(4)
  }
}));

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(4)
  },
  icons: {
    color: "#898F91"
  },
  iconExport: { color: "#212121", padding: theme.spacing(1) }
}));
