import { put, takeLatest as sagaTakeLatests } from "redux-saga/effects";
import { toast } from "react-toastify";
import { DOCUMENTS, API } from "../actions/actionTypes";
import { deleteFileSuccess, getAllSuccess, getReviewSuccess, postSuccess } from "../actions/documents";
import * as docSagas from "./common/documents";
import { takeLatest, takeLatestPost } from "./common/sagaGenerator";
import rootSagaGenerator from "./common/rootSagaGenerator";

const url = `${API.URL}/indexing`;

const onSuccess = action =>
  function* gOnSuccess({ indexingStatusId, data, payload, documents }) {
    const statusId = indexingStatusId || data?.indexingStatusId;
    // eslint-disable-next-line redux-saga/no-unhandled-errors
    yield put(action({ indexingStatusId: statusId, documents: payload || documents }));
  };

export default rootSagaGenerator({
  name: "Diary",
  sagas: [
    takeLatest({
      type: DOCUMENTS.GETALL,
      onSuccess: onSuccess(getAllSuccess),
      params: ({ indexingStatusId: statusId }) => ({ statusId }),
      onError: ({ indexingStatusId }) => toast.error(`Error loading ${indexingStatusId === 1 ? "I" : "Rei"}ndexing files`),
      url
    }),
    sagaTakeLatests(DOCUMENTS.POST_FILES, docSagas.uploadFiles(DOCUMENTS.POST_FILES_SUCCESS)),
    sagaTakeLatests(DOCUMENTS.OPEN_FILE, docSagas.openFile(DOCUMENTS.OPEN_FILE_SUCCESS)),
    sagaTakeLatests(DOCUMENTS.DEL_FILE, docSagas.deleteFile(undefined, { onSuccess: onSuccess(deleteFileSuccess) })),
    takeLatestPost({
      type: DOCUMENTS.POST,
      onSuccess: action => {
        const {
          data: { isReviewCompany, indexTypeId, payee }
        } = action;
        if (indexTypeId === 1 && !payee?.id) toast.success("Vendor successfully sent to review");
        else
          toast.success(
            indexTypeId === 1 && !!payee?.id && isReviewCompany ? "Bill successfully sent to review company" : "Document successfuly indexed"
          );
        return onSuccess(postSuccess)(action);
      },
      params: ({ data }) => data,
      onError: ({ data: { isReviewCompany, indexTypeId, vendorId } }) => {
        if (indexTypeId === 1 && !vendorId) return toast.error("Unable to send vendor to review");
        return toast.error(
          indexTypeId === 1 && vendorId > 0 && isReviewCompany ? "Bill successfully sent to review company" : "Document successfuly indexed"
        );
      },
      errorMessage: "Unable to index document",
      url: `${url}/index`
    }),
    takeLatest({
      type: DOCUMENTS.REVIEW.GET,
      actionSuccess: getReviewSuccess,
      url: `${url}/review`,
      errorMessage: "Unable to get Special Review Queue"
    })
  ]
});
