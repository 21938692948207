import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100vh"
  },
  box: {
    padding: theme.spacing(2)
  },
  container: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(2)
  },
  sidebar: {
    flexGrow: 0,
    flexShrink: 0,
    padding: theme.spacing(2),
    backgroundColor: "white",
    height: "100%",
    overflowY: "auto",
    paddingTop: "27px",
    width: 375,
    zIndex: 1,
    "& .MuiPaper-root": {
      backgroundColor: "#F3F3F3",
      padding: theme.spacing(1.5, 1),
      "&:not(:last-of-type)": {
        marginBottom: theme.spacing(2)
      }
    }
  },
  icon: { fontSize: 24, paddingRight: 10, lineHeight: 1, "& svg": { width: "1em", height: "1em" } },
  number: { fontWeight: 500, fontSize: 24, lineHeight: 1, marginBottom: "5px !important" },
  name: { fontWeight: 500, fontSize: 24, color: "#73B25D", lineHeight: 1 },
  status: { marginLeft: "1rem", borderRadius: "50%", padding: 5, width: 24, height: 24, display: "inline-flex", "& svg": { fontSize: 14 } },
  open: { backgroundColor: "#D2EEB6", "& svg": { color: "#428302" } },
  close: { backgroundColor: "#FCB7B0", "& svg": { color: "#E74C3C" } },
  title: { color: "#428302", fontSize: 16, fontWeight: 500 },
  locationText: { fontWeight: 500 },
  locationTextMBottom: { fontWeight: 50, marginBottom: "4%" },
  label: { fontSize: 11, color: "#898F91" },
  checkCircle: { color: "#73B25D" },
  locationIcon: { color: "#107A98" },
  pending: { color: "#E74C3C" },
  paddingTop: { paddingTop: 20 },
  paddingBottom: { paddingBottom: 20 },
  divider: {
    border: "none",
    height: "1px",
    flexShrink: "0",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    margin: "15px 15px 15px 25px"
  },
  locationTitle: { color: "#70A140", marginBottom: "5%" },
  locationInformation: { display: "flex", maxWidth: "300px", position: "relative", left: "-7px", marginBottom: "1%" },
  marginBottom4: {
    marginBottom: "4%"
  },
  vendorNameEllipsis: {
    width: 205,
    whiteSpace: "nowrap",
    display: "inline-flex"
  },
  cMarginTop: {
    marginTop: "10px !important"
  },
  colorGreenLight: {
    color: "#70A140"
  },
  paymentColor: {
    color: "#898FB6"
  },
  totalPaid: {
    color: "#1599BC",
    marginTop: 10
  },
  claimInvolvedFirstWrapper: { display: "flex", justifyContent: "space-between", maxWidth: "100px", marginBottom: "5% " },
  cFontWeight: 500,
  claimInvolvedSecondWrapper: { display: "flex", justifyContent: "space-between", maxWidth: "145px" },
  cIstatusStyle: {
    position: "relative",
    top: "-1px"
  }
}));
