import { CLIENTR as CLIENT } from "../../actionTypes";

// Get Actions

const getProfile = id => ({ type: CLIENT.INSURED.PROFILE.GET, id });

const getProfileSuccess = profile => ({ type: CLIENT.INSURED.PROFILE.GET_SUCCESS, profile });

// Update Actions

const updateBasic = data => ({ type: CLIENT.INSURED.PROFILE.BASIC.SET, data });

const updateStatus = data => ({ type: CLIENT.INSURED.PROFILE.STATUS.SET, data });

const addCode = (clientId, codeId) => ({ type: CLIENT.INSURED.PROFILE.CODE_PROFILE.POST, clientId, codeId });

const removeCode = (clientId, codeId) => ({ type: CLIENT.INSURED.PROFILE.CODE_PROFILE.DEL, clientId, codeId });

// Update Actions Success

const updateBasicSuccess = profile => ({ type: CLIENT.INSURED.PROFILE.BASIC.SET_SUCCESS, profile });

const updateStatusSuccess = profile => ({ type: CLIENT.INSURED.PROFILE.STATUS.SET_SUCCESS, profile });

const addCodeSuccess = codeInsured => ({ type: CLIENT.INSURED.PROFILE.CODE_PROFILE.POST_SUCCESS, codeInsured });

const removeCodeSuccess = codeInsured => ({ type: CLIENT.INSURED.PROFILE.CODE_PROFILE.DEL_SUCCESS, codeInsured });

export {
  getProfile,
  updateBasic,
  updateStatus,
  addCode,
  removeCode,
  getProfileSuccess,
  updateBasicSuccess,
  updateStatusSuccess,
  addCodeSuccess,
  removeCodeSuccess
};
