import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Grid, IconButton } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import { HorizontalBar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import Card from "../../common/overview/Card";
import { get } from "../../../redux/actions/dashboard/claimWithoutActivity";
import { useLoading } from "../../common/Loading";
import useStyles from "../Dashboard.styles";

const ClaimWithoutActivity = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions, Loading } = useLoading(true);
  const { showLoading, hideLoading } = actions;

  const store = useSelector(
    ({ common: { coverageTypes }, dashboard: { claimWithoutActivity } }) => ({
      coverageTypes,
      claimWithoutActivity
    }),
    shallowEqual
  );
  const { coverageTypes, claimWithoutActivity } = store;

  useEffect(() => {
    showLoading();
    dispatch(get(hideLoading, hideLoading));
  }, []);

  const makeJsonChart = () => {
    const backgroundColor = ["#FE9A32", "#F7CB4D", "#7BAAF7", "#BA68C8"];
    const adjusterIds = [...new Set(claimWithoutActivity.sort((a, b) => a.adjuster.localeCompare(b.adjuster)).map(c => c.adjusterId))];
    const dataJson = {
      labels: [...adjusterIds.map(aId => claimWithoutActivity.find(c => c.adjusterId === aId)?.adjuster)],
      datasets: [
        ...coverageTypes.map((ct, ix) => ({
          label: ct.description,
          backgroundColor: backgroundColor[ix],
          data: [...adjusterIds.map(aId => claimWithoutActivity.filter(c => c.adjusterId === aId && c.claimLineOfBusinessId === ct.id)?.length)]
        }))
      ]
    };
    return dataJson;
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { position: "bottom", labels: { usePointStyle: true, padding: 20, boxWidth: 6 } },
    scales: {
      yAxes: [{ ticks: { beginAtZero: true, maxRotation: 45, minRotation: 45 } }],
      xAxes: [{ ticks: { autoSkip: false, precision: 0 } }]
    }
  };

  const data = makeJsonChart();

  const showDetail = () => {
    history.push("/dashboard/claim-without-activity");
  };
  const widgetActions = () => {
    return (
      <>
        <IconButton size="small" className={classes.icon} onClick={showDetail}>
          <OpenInNew />
        </IconButton>
      </>
    );
  };
  return (
    <>
      {Loading}
      <Card title="Claims with no activity in the last 30 days by Adjuster" cardHeaderAction={widgetActions}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs>
            <HorizontalBar height={275} {...{ data, options }} />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ClaimWithoutActivity;
