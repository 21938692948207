import React, { createRef, useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import { FormControl, FormHelperText, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, MenuList, Popover } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, Close } from "@material-ui/icons";
import { HocInput } from "./HocInput";

const memoProps = ["label", "name", "value", "options", "formatOption", "formatValue"];
const checkMemo = props => (prev, next) => !props.some(prop => prev[prop] !== next[prop]);
checkMemo.propTypes = PropTypes.arrayOf(PropTypes.string).isRequired;

export const BaseCustomSelect = memo(
  ({ classes, className, container, error, label, formatOption, formatValue, value: val, name, options, onChange, onClear, ...other }) => {
    const [{ anchorEl, inputRef }, setState] = useState({ anchorEl: null, inputRef: createRef() });
    const setAnchorEl = (aEl = null) => setState(ps => ({ ...ps, anchorEl: aEl }));
    useEffect(() => {
      setAnchorEl();
    }, [val]);
    const any = Array.isArray(options) && options.some(() => true);
    const fnValue =
      typeof formatValue === "function"
        ? () => formatValue(val, options)
        : () => {
            if (!val || !any) return "";
            const items = options.filter(o => o.id === val);
            if (!items.some(() => true)) return "";
            return items[0].text || "";
          };
    const onClose = () => setAnchorEl();
    const onSelect = option => () => {
      if (typeof onChange === "function") onChange({ target: { name, value: option.id } });
      return onClose();
    };
    const onClick = event => setAnchorEl(event.currentTarget);
    const open = Boolean(anchorEl);
    const fnOption = option =>
      typeof formatOption === "function" ? (
        formatOption(option)
      ) : (
        <MenuItem key={option.id} onClick={onSelect(option)}>
          {option.text}
        </MenuItem>
      );
    const id = `custom-select-${name || "default"}`;
    const popId = `menu-${id}`;
    const Container = container || MenuList;

    const endAdornment = (
      <InputAdornment position="end">
        <Grid container wrap="nowrap">
          {val && !other.disabled && typeof onClear === "function" && (
            <Grid item>
              <IconButton
                size="small"
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  return onClear();
                }}
              >
                <Close style={{ fontSize: 18 }} />
              </IconButton>
            </Grid>
          )}
          <Grid item className={classes.arrow}>
            {(open && <ArrowDropUp />) || <ArrowDropDown />}
          </Grid>
        </Grid>
      </InputAdornment>
    );
    return (
      <FormControl aria-controls={popId} aria-haspopup="true" error={!!error} fullWidth {...{ classes, ...other }}>
        {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
        <Input value={fnValue()} readOnly {...{ className, id, name, inputRef, onClick, endAdornment }} />
        {error && <FormHelperText error>{error}</FormHelperText>}
        <Popover
          id={open ? popId : undefined}
          {...{ anchorEl, onClose, open }}
          keepMounted
          anchorOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          <Container>{any && options.map(fnOption)}</Container>
        </Popover>
      </FormControl>
    );
  },
  checkMemo(memoProps)
);

BaseCustomSelect.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  label: PropTypes.string,
  formatOption: PropTypes.func,
  formatValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.object),
  container: PropTypes.elementType,
  error: PropTypes.string
};

BaseCustomSelect.defaultProps = {
  className: undefined,
  onChange: undefined,
  onClear: undefined,
  label: null,
  formatOption: undefined,
  formatValue: undefined,
  value: "",
  options: [],
  container: undefined,
  name: undefined,
  error: undefined
};

export default HocInput(BaseCustomSelect, theme => ({
  fullWidth: { marginBottom: theme.spacing(2), cursor: "pointer", "& input": { cursor: "pointer !important" }, "& svg": { color: "#757575" } }
}));
