import * as yup from "yup";

const string = msg =>
  yup
    .string()
    .nullable()
    .required(msg);
const number = msg =>
  yup
    .number()
    .nullable()
    .required(msg);

export default yup.object().shape({
  vehicleId: number("Vehicle Required"),
  driver: yup.object().shape({
    location: yup.object().shape({
      address1: string("Driver's Address Required"),
      city: string("Driver's City Required"),
      stateId: number("Driver's State Required"),
      zipCode: string("Driver's Zip Required")
    }),
    firstName: string("Driver's First Name Required"),
    lastName: string("Driver's Last Name Required")
  })
});
