import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import { Divider, Grid, Collapse, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useContext, actionTypes } from "../context";
import { DateTimeInput, SelectInput, TextInput, CheckboxLabel, PhoneInput } from "../../../common/inputs";
import { filterByParentId } from "../../../../common";
import { useBodiliInjuryStyles } from "./index.styles";
// import { CoverageIcon } from "../layout";

const BodilyInjury = memo(({ claimant, index }) => {
  const classes = useBodiliInjuryStyles();
  const [
    { bodilyInjury: ctxBodilyInjury },
    ,
    handleChange,
    {
      step2: { validationMessages },
      handleValidation
    }
  ] = useContext();
  const state = claimant ? claimant.bodilyInjury : ctxBodilyInjury;
  const { hospitalId, stateId, parentBodyPartId, treatmentReceived } = state;
  const [loaded, setLoaded] = useState(false);
  const { injuryType, bodyPart, bodyPartGroup, hospitalLocation, states, treatmentType } = useSelector(({ common }) => common, shallowEqual);
  const isDeath = injuryType.some(t => t.id === state.injuryTypeId && t.isDeath);
  useEffect(() => {
    if (loaded) handleValidation();
    else setLoaded(true);
  }, [state]);
  useEffect(() => {
    if (loaded && isDeath !== state.isDeath) handleChange({ target: { name: "bi.isDeath", value: isDeath } }, actionTypes.SET_BODILY_INJURY);
  }, [isDeath]);
  const { name: hName, phone, address1, address2, city, zipCode } = hospitalLocation.find(({ id }) => id === hospitalId) || {};
  const inputProps = path => ({
    name: `${claimant ? `multi-${claimant.id}-` : ""}bi.${path}`,
    error: validationMessages[`${claimant ? `claimants[${index}].` : ""}bodilyInjury.${path}`],
    onChange: e => handleChange(e, actionTypes.SET_BODILY_INJURY),
    [path === "treatmentReceived" ? "checked" : "value"]: state[path]
  });
  const hStates = states.filter(({ id }) => hospitalLocation.some(h => h.stateId === id));
  const hospitals = hospitalLocation.filter(h => h.stateId === stateId).map(h => ({ id: h.id, text: h.name }));
  const Container = claimant ? React.Fragment : Paper;
  return (
    <Container {...(claimant ? {} : { className: classes.root })}>
      <div className={clsx(classes.mb, { [classes.mt]: Boolean(claimant) })}>
        <Grid container alignItems="center" spacing={2} wrap="nowrap">
          <Grid item>
            <Typography className="tc-title">Bodily Injury</Typography>
          </Grid>
          {/* <Grid item>
            <CoverageIcon text="Bodily Injury" label />
          </Grid> */}
        </Grid>
      </div>
      <Grid container spacing={4}>
        <Grid item xs>
          <SelectInput {...inputProps("injuryTypeId")} label="Injury Type *" options={injuryType} />
          {isDeath && <DateTimeInput {...inputProps("claimantDateofDeath")} label="Claimant Date Of Death" keyboard />}
        </Grid>
        <Grid item xs>
          <SelectInput {...inputProps("parentBodyPartId")} label="Body Part Group *" options={bodyPartGroup} />
          <SelectInput {...inputProps("bodyPartId")} label="Body Part *" options={filterByParentId(bodyPart, parentBodyPartId)} />
        </Grid>
        <Grid item xs>
          <CheckboxLabel label="Initial Treatment Received" {...inputProps("treatmentReceived")} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <TextInput {...inputProps("treatmentDescription")} label="Bodily Injury Description" rows={4} />
        </Grid>
      </Grid>
      <Collapse in={treatmentReceived} timeout="auto">
        <Divider className={classes.marginDevider} />
        <Grid container spacing={4}>
          <Grid item xs>
            <SelectInput {...inputProps("treatmentTypeId")} label="Initial Treatment Type *" options={treatmentType} />
            <TextInput {...inputProps("physicianName")} label="Initial Hospital Physician Name *" />
            <PhoneInput label="Phone number" value={phone} disabled />
          </Grid>
          <Grid item xs>
            <TextInput label="Hospital Name" value={hName} disabled />
            <TextInput label="Hospital Address 1" value={address1} disabled />
            <TextInput label="Hospital Address 2" value={address2} disabled />
          </Grid>
          <Grid item xs>
            <TextInput label="Hospital City" value={city} disabled />
            <SelectInput {...inputProps("stateId")} label="Hospital State *" options={hStates} />
            <SelectInput {...inputProps("hospitalId")} label="Hospital *" options={hospitals} />
            <TextInput label="Hospital Zip" value={zipCode} disabled />
          </Grid>
        </Grid>
      </Collapse>
    </Container>
  );
});

BodilyInjury.propTypes = {
  claimant: PropTypes.objectOf(PropTypes.any),
  index: PropTypes.number
};

BodilyInjury.defaultProps = {
  claimant: undefined,
  index: undefined
};

export default BodilyInjury;
