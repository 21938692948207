import React from "react";
import PropTypes from "prop-types";
import { Grid, Divider } from "@material-ui/core";
import LabelValue from "../../LabelValue";
import MultipleLabels from "../../Labels";

const InsuredVehicleViewMode = ({ insuredVehicle }) => {
  const {
    damageDescription,
    vehicle: { number, vehicleYear, vehicleMake, model, vehicleBody, vin, plateNumber, vehicleState } = {},
    driver: { firstName, lastName, email, middleName, licenseNumber, dateOfBirth, phone, location: driverLocation } = {}
  } = insuredVehicle || {};

  const driverFullName = `${firstName || ""} ${middleName || ""}  ${lastName || ""}`;
  return (
    <React.Fragment key="insuredvehicleread">
      <form style={{ padding: "10px" }}>
        <Grid container spacing={4}>
          <Grid item xs>
            <MultipleLabels
              data={[
                { label: "Vehicle #", value: number || number === 0 ? String(number) : undefined, key: "labelVehicleNumber" },
                { label: "Vehicle Year", value: vehicleYear, key: "labelVehicleYear" },
                { label: "Vehicle Make", value: vehicleMake, key: "labelVehicleMake" }
              ]}
            />
          </Grid>
          <Grid item xs>
            <MultipleLabels
              data={[
                { label: "Vehicle Model", value: model, key: "labelVehicleModel" },
                { label: "Vehicle Body Type", value: vehicleBody, key: "labelVehicleBodyType" },
                { label: "V.I.N. #", value: vin, key: "labelVehicleVin" }
              ]}
            />
          </Grid>
          <Grid item xs>
            <MultipleLabels
              data={[
                { label: "Vehicle Plate Number", value: plateNumber, key: "labelVehiclePlate" },
                { label: "Vehicle State", value: vehicleState, key: "labelVehicleState" }
              ]}
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: "15px 0px 15px 0px" }} />
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <MultipleLabels
              data={[
                { label: "Driver's Name", value: driverFullName, key: "driverfullname" },
                { label: "Driver's Date of Birth", value: dateOfBirth, key: "driverbirthday", isDate: true },
                { label: "Driver's Phone Number", value: phone, key: "driverphone" }
              ]}
            />
          </Grid>
          <Grid item xs>
            <MultipleLabels
              data={[
                { label: "Driver's Email", value: email, key: "driveremail" },
                { label: "Driver's License Number", value: licenseNumber, key: "driverlicense" },
                { label: "Driver's Address", value: driverLocation, key: "driveraddress", isAddress: true }
              ]}
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: "15px 0px 15px 0px" }} />
        <LabelValue label="Damage Description" value={damageDescription} />
      </form>
    </React.Fragment>
  );
};

InsuredVehicleViewMode.propTypes = {
  insuredVehicle: PropTypes.shape({
    location: PropTypes.shape({})
  }).isRequired
};

export default InsuredVehicleViewMode;
