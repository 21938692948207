import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useLoadingContext } from "../../../common/Loading";
import ActivityInfo from "./ActivityInfo";
import BoxItem from "./BoxItem";
import ClaimantInfo from "./ClaimantInfo";
import ClaimInfo from "./ClaimInfo";
import DiariesInfo from "./DiariesInfo";
import LitigationInfo from "./LitigationInfo";
import OccurrenceCatastropheInfo from "./OccurrenceCatastropheInfo";
import PolicyInfo from "./PolicyInfo";
import ReservesFinancialInfo from "./ReservesFinancialInfo";
import TeamInfo from "./TeamInfo";
import { getAllWidget } from "../../../../redux/actions/admin/claimsSetting";
import StretchContainer from "../../../common/StretchContainer";
import { useRouteParam } from "../../../../common";

const TabOverview = () => {
  const claimId = useRouteParam("claimId");
  const useSelectorData = useSelector(
    ({
      claim: {
        claim: { id }
      },
      admin: {
        claimSettings: { widgets }
      }
    }) => ({ storeId: id, widgets }),
    shallowEqual
  );
  const { storeId, widgets } = useSelectorData;
  const show = claimId === storeId;
  const {
    actions: { showLoading, hideLoading, handleSideBar }
  } = useLoadingContext();
  const dispatch = useDispatch();
  useEffect(() => {
    (show ? hideLoading : showLoading)();
    return hideLoading;
  }, [show]);
  useEffect(() => {
    dispatch(getAllWidget());
    handleSideBar(false);
    return () => handleSideBar(true);
  }, []);
  const activityWidget = (widgets || []).some(e => e.name.toLowerCase() === "activity" && e.isActive);
  return (
    <div className="tc-mb1">
      <StretchContainer spacing={2}>
        <Grid item xs={9}>
          <StretchContainer spacing={2}>
            <BoxItem {...{ show }} xs={12}>
              <ClaimInfo />
            </BoxItem>
            <BoxItem {...{ show }} xs={12}>
              <ClaimantInfo />
            </BoxItem>
          </StretchContainer>
        </Grid>
        <BoxItem {...{ show }} xs={3}>
          <OccurrenceCatastropheInfo />
        </BoxItem>
        <Grid item xs={9}>
          <StretchContainer spacing={2}>
            <BoxItem {...{ show }} xs={activityWidget ? 4 : 12}>
              <TeamInfo />
            </BoxItem>
            {activityWidget && show && (
              <BoxItem {...{ show }} xs={8}>
                <ActivityInfo />
              </BoxItem>
            )}
            <BoxItem {...{ show }} xs={12}>
              <PolicyInfo />
            </BoxItem>
          </StretchContainer>
        </Grid>
        <BoxItem {...{ show }} xs={3}>
          <DiariesInfo />
        </BoxItem>
        <BoxItem {...{ show }} xs={9}>
          <ReservesFinancialInfo />
        </BoxItem>
        <BoxItem {...{ show }} xs={3}>
          <LitigationInfo />
        </BoxItem>
      </StretchContainer>
    </div>
  );
};

export default TabOverview;
