import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { Divider, Grid, Button } from "@material-ui/core";
import useStyles from "./claim.style";
import { DateTimeInput, SelectInput, TextInput, TimeInput } from "../../inputs";
import { filterByParentId, anyById } from "../../../../common";
import validationSchema from "./yup";
import useYupValidationSchema from "../../customHooks/useYupValidationSchema";

const ClaimForm = ({ claim, onSave, onClose, isReportOnly, isLoading }) => {
  const classes = useStyles();

  const {
    lineOfBusiness: coverageTypes,
    s: status,
    pStatus: processingStatus,
    st: states,
    claimLossTypeGroups: typeGroups,
    claimLossTypes: lossTypesFull,
    claimCauseCodes: codes
  } = useSelector(
    ({
      common: {
        coverageTypes: lineOfBusiness,
        claimStatus: s,
        processingStatus: pStatus,
        states: st,
        claimLossTypeGroups,
        claimLossTypes,
        claimCauseCodes
      }
    }) => ({ lineOfBusiness, s, pStatus, st, claimLossTypeGroups, claimLossTypes, claimCauseCodes }),
    shallowEqual
  );
  const [state, setState] = useState(claim || {});
  const [location, setLocation] = useState(claim?.location || {});
  const [loaded, setLoaded] = useState(false);
  const { validationMessages, handleValidateSchema } = useYupValidationSchema(validationSchema);
  const handleValidation = () => handleValidateSchema({ ...state, location, processingStatus });
  const { processingStatusId, claimLossTypeGroupId, claimLineOfBusinessId } = state;
  const lobs = coverageTypes.map(ct => ({ ...ct, text: ct.description, description: ct.text }));
  const lossTypeGroups = filterByParentId(typeGroups, claimLineOfBusinessId);
  const lossTypes = filterByParentId(lossTypesFull, claimLossTypeGroupId);
  const causeCodes = filterByParentId(codes, claimLossTypeGroupId);
  const isDenied = processingStatus.find(x => x.id === processingStatusId)?.text === "Denied";
  useEffect(() => {
    let newState = {};
    if (!anyById(lossTypeGroups, claimLossTypeGroupId)) {
      newState = { claimLossTypeGroupId: undefined, claimLossTypeId: undefined, claimCauseCodeId: undefined };
    } else {
      const { claimLossTypeId, claimCauseCodeId } = state;
      if (!anyById(lossTypes, claimLossTypeId)) newState = { ...newState, claimLossTypeId: undefined };
      if (!anyById(causeCodes, claimCauseCodeId)) newState = { ...newState, claimCauseCodeId: undefined };
    }
    if (!isDenied) newState = { ...newState, deniedDescription: "" };
    setState(prevState => ({ ...prevState, ...newState }));
  }, [claimLineOfBusinessId, claimLossTypeGroupId, isDenied]);
  useEffect(() => {
    if (loaded) handleValidation();
    else setLoaded(true);
  }, [state, location]);
  const handleSubmit = event => {
    event.preventDefault();
    const { isValid } = handleValidation();
    if (isValid) onSave({ ...state, location });
  };

  const inputProps = path => {
    const [ent, name] = path.split(".");
    const { st, error, action } = (() => {
      switch (ent) {
        case "location":
          return { st: location, error: "location.", action: setLocation };
        default:
          return { st: state, action: setState };
      }
    })();
    return {
      name: path,
      value: st[name],
      error: (validationMessages || {})[`${error || ""}${name}`],
      onChange: ({ target: { value } }) => action(ps => ({ ...ps, [name]: value }))
    };
  };
  return (
    <React.Fragment key="claimedit">
      <form onSubmit={handleSubmit}>
        {!isReportOnly && (
          <React.Fragment key="claim-update">
            <Grid container spacing={4}>
              <Grid item xs>
                <TextInput {...inputProps("claim.number")} label="Claim #" disabled />
                <SelectInput {...inputProps("claim.claimLineOfBusinessId")} label="Line Of Business *" options={lobs} disabled />
                <DateTimeInput {...inputProps("claim.dateReported")} label="Date Reported *" keyboard />
              </Grid>
              <Grid item xs>
                <SelectInput {...inputProps("claim.statusId")} label="Claim Status *" options={status} />
                <DateTimeInput {...inputProps("claim.statusDate")} label="Claim Status Date" disabled keyboard />
                <DateTimeInput {...inputProps("claim.dateEntry")} label="Claim Entry" disabled keyboard />
              </Grid>
              <Grid item xs>
                <SelectInput {...inputProps("claim.processingStatusId")} label="Processing Status *" options={processingStatus} />
                <DateTimeInput {...inputProps("claim.processingStatusDate")} label="Processing Status Date" disabled keyboard />
                {isDenied && <TextInput {...inputProps("claim.deniedDescription")} label="Claim Denied Description *" rows={4} />}
              </Grid>
            </Grid>
            <Divider className={classes.marginDevider} />
          </React.Fragment>
        )}

        <Grid container spacing={4}>
          <Grid item xs>
            <Grid container>
              <Grid item xs className={classes.pr1}>
                <DateTimeInput {...inputProps("claim.dateofLoss")} label="Date of Loss *" keyboard />
              </Grid>
              <Grid item xs className={classes.pl1}>
                <TimeInput {...inputProps("claim.dateofLoss")} label="Time Of Loss *" keyboard />
              </Grid>
            </Grid>
            <TextInput {...inputProps("location.address1")} label="Address of Loss 1 *" />
            <TextInput {...inputProps("location.address2")} label="Address 2" />
          </Grid>
          <Grid item xs>
            <TextInput {...inputProps("location.city")} label="City *" />
            <SelectInput {...inputProps("location.stateId")} label="State *" options={states} />
            <TextInput {...inputProps("location.zipCode")} label="Zip *" />
          </Grid>
          <Grid item xs>
            <SelectInput {...inputProps("claim.claimLossTypeGroupId")} label="Loss Type Group *" options={lossTypeGroups} disabled />
            <SelectInput {...inputProps("claim.claimLossTypeId")} label="Loss Type *" options={lossTypes} />
            <SelectInput {...inputProps("claim.claimCauseCodeId")} label="Cause Code *" options={causeCodes} />
          </Grid>
        </Grid>
        <TextInput {...inputProps("claim.description")} label="Loss Description" rows={4} />
        <Grid container alignItems="flex-start" justify="flex-end" direction="row">
          <div className={classes.buttonWrapper}>
            <Button variant="outlined" color="primary" onClick={onClose}>
              CANCEL
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
              SAVE
            </Button>
          </div>
        </Grid>
      </form>
    </React.Fragment>
  );
};

ClaimForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  claim: PropTypes.shape({
    location: PropTypes.shape({})
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  isReportOnly: PropTypes.bool,
  isLoading: PropTypes.bool
};

ClaimForm.defaultProps = {
  isReportOnly: false,
  isLoading: false
};

export default ClaimForm;
