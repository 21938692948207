import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  root: { display: "flex", height: "100%", padding: "1rem", alignItems: "center" },
  card: {
    padding: "4.5rem 1.5rem",
    position: "relative",
    textAlign: "center",
    [theme.breakpoints.up("xs")]: {
      "div > &:not(:first-of-type):before": {
        content: '""',
        position: "absolute",
        top: "4.5rem",
        bottom: "4.5rem",
        left: 0,
        borderLeft: "solid 1px #648C9B"
      }
    },
    "& h2": { fontSize: "1.5rem", color: "#004763" },
    "& p": { color: "#000" }
  },
  hover: { "&:hover": { background: "#648C9B" } }
}));
