import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    display: "inline-flex",
    position: "relative",
    backgroundColor: "#DAEAEF",
    justifyContent: "center",
    alignItems: "center",
    height: 24,
    padding: theme.spacing(0, 1),
    marginLeft: theme.spacing(1),
    borderRadius: 4,
    top: -1
  }
}));

const Count = ({ className, ...props }) => {
  const classes = useStyles();
  return <span className={clsx(classes.root, className)} {...props} />;
};

Count.propTypes = {
  className: PropTypes.string
};

Count.defaultProps = {
  className: undefined
};

export default Count;
