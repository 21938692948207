import { Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import ClaimStatus from "../../claims/ClaimStatus";
import ClaimProcessingStatus from "../../claims/ClaimProcessingStatus";
import { setExportTitleFileName } from "../../../common";
import { getExpired } from "../../../redux/actions/dashboard/diaries";
import { useLoading } from "../../common/Loading";
import MaterialTable from "../../common/table/MaterialTable";

// import styles from "../Dashboard.styles";

export default () => {
  const dispatch = useDispatch();
  const expiredDiaries = useSelector(store => store.dashboard.expiredDiaries, shallowEqual);
  //   const classes = styles();
  const {
    actions: { showLoading, hideLoading },
    Loading
  } = useLoading(true);
  useEffect(() => {
    showLoading();
    dispatch(getExpired(hideLoading, hideLoading));
  }, []);
  const tableProps = {
    columns: [
      { title: "Adjuster Name", field: "owner" },
      {
        title: "Claim #",
        field: "claimNumber",
        render: rowData => (
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: 5 }}>
              <Link to={`/claim/${rowData.id}`}>{rowData.claimNumber}</Link>
            </span>
            <span style={{ marginRight: 5 }}>
              <ClaimStatus status={rowData.claimStatus} />
            </span>
            <ClaimProcessingStatus processingStatus={rowData.claimProcessingStatus} />
          </div>
        )
      },
      { title: "Claimant Name", field: "claimantName" },
      {
        title: "Diary Name",
        field: "name",
        render: rowData => (
          <Link to={`/claim/${rowData.claimId}/diaries?diaryId=${rowData.id}`} style={{ color: "#107A98" }}>
            {rowData.name}
          </Link>
        )
      },
      { title: "Priority ", field: "priority" },
      { title: "Status", field: "status" },
      {
        title: "Due Date",
        field: "dueDate",
        render: rowData => (rowData.dueDate ? moment(rowData.dueDate).format("M/D/YYYY") : "")
      }
    ],
    data: expiredDiaries,
    options: {
      pageSize: 50,
      filtering: true,
      exportFileName: setExportTitleFileName("ExpiredDiaries")
    },
    hidePaging: (expiredDiaries || []).length <= 5
  };

  return (
    <>
      {Loading}
      <div className="tc-page-container">
        <div className="tc-page-content">
          <Paper className="tc-relative tc-o-hidden tc-box-content">
            <Typography className="tc-title">Expired Diaries report by Adjuster</Typography>
            <MaterialTable {...tableProps} />
          </Paper>
        </div>
      </div>
    </>
  );
};
