export default {
  claimType: [],
  claimStatus: [],
  gender: [],
  states: [],
  maritalStatus: [],
  processingStatus: [],
  taxType: [],
  coverageTypes: [],
  claimLossTypeGroups: { data: [], map: [] },
  claimLossTypes: { data: [], map: [] },
  claimCauseCodes: { data: [], map: [] },
  departamentTypes: [],
  propertyTypes: [],
  propertyDamagedLossTypes: [],
  propertyDamagedTypes: [],
  bodyPartGroup: [],
  injuryType: [],
  treatmentType: [],
  vehicleBodyType: [],
  vehicleClass: [],
  vehicleMake: [],
  vehicleDamageType: [],
  codeType: [],
  codeDivision: [],
  codes: [],
  bodyPart: { data: [], map: [] },
  paymentMethods: [],
  vendorTypes: [],
  adjusterUsers: [],
  supervisorUsers: [],
  assistantUsers: [],
  lawFirms: [],
  veredictType: [],
  appealStatus: [],
  veredictStatus: [],
  taskStatus: [],
  taskPriority: [],
  taskType: [],
  hospitalLocation: [],
  sizeTypes: [],
  clientTypes: [],
  users: [],
  codetypes: [],
  accountTypes: [],
  insuredLocationTypes: [],
  policyTypes: [],
  policyCoverageTypes: { data: [], map: [] },
  producers: [],
  insurers: [],
  vendorCarriers: [],
  purposeTypes: [],
  rreSources: [],
  corridorErosions: [],
  coverageTriggers: [],
  riskExposureTypes: [],
  insuredCategories: [],
  vendorCategories: [],
  memberCategories: [],
  attachmentBasis: [],
  noteType: [],
  reserveStatus: [],
  timeZone: [],
  autoDayAfterTypes: [],
  claimUserTypes: [],
  communicationType: [],
  basicPeriodTypes: [],
  reindexReasons: [],
  schedulePaymentTypes: [],
  recurrenceTypes: [],
  vendorStatus: [],
  recoveryStatus: [],
  recoveryTypes: [],
  employmentStatus: [],
  lossTimeStatus: [],
  payrollClassCodeTypes: [],
  ratePerTypes: [],
  ediClaimTypes: [],
  contactMethods: [],
  ncciInjuryCodes: [],
  typeOfCoverageCodes: []
};
