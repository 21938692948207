import React, { useContext, shallowEqual } from "react";
import { useSelector } from "react-redux";
import InitialReportNote from "../../common/notes";
import { useRouteParam } from "../../../common";
import ActionsAllowedContext from "../../common/contexts/ActionsAllowed";

const Notes = () => {
  const claimId = useRouteParam("claimId");
  const entityNumber = useSelector(store => store.initialReport.claim.number, shallowEqual);
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "notes");
  const confidentialAccess = routesAllowed.find(elem => elem.route === "notes-confidential");
  const confAccess = {
    confidentialCreate: confidentialAccess.create,
    confidentialUpdate: confidentialAccess.update,
    confidentialDelete: confidentialAccess.delete
  };
  return <InitialReportNote {...{ claimId, entityNumber }} actionsAllowed={{ ...access, ...confAccess }} />;
};

export default Notes;
