import React, { memo } from "react";
import PropTypes from "prop-types";
import { fireDownload } from "../../../api/apiUtils";
import ImageViewer from "./ImageViewer";
import PdfViewer from "./PdfViewer";

const Viewer = memo(({ actions, blob, hideDownload, ...props }) => {
  const onDownload = () => fireDownload(null, blob);
  const { url, filename, image, pdf } = blob || {};
  return (
    <div className="tc-page-container" {...props}>
      {blob && (
        <>
          {image && <ImageViewer {...{ actions, onDownload, hideDownload, filename, url }} />}
          {pdf && <PdfViewer {...{ actions, blob, hideDownload, onDownload }} />}
        </>
      )}
    </div>
  );
});

Viewer.propTypes = { actions: PropTypes.arrayOf(PropTypes.object), blob: PropTypes.shape({}), hideDownload: PropTypes.bool };

Viewer.defaultProps = { actions: [], blob: {}, hideDownload: false };

export default Viewer;
