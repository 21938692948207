import moment from "moment";
import React from "react";
import { Paper } from "@material-ui/core";
import { useContext, actionTypes } from "../context";
import MaterialTable from "../../../common/table/MaterialTable";
import { DateTimeInput, SelectInput } from "../../../common/inputs";
import { isDate, isNumber } from "../../../../common/yup";
import { useAttorneyStyles } from "./index.styles";

export default () => {
  const classes = useAttorneyStyles();
  const [{ attorneys, firms, lawyers }, dispatch] = useContext();
  const inputProps = (col, name, options) => ({
    name,
    onChange: ({ target: { value } }) => {
      if (name === "lawFirmId") return col.onRowDataChange({ ...col.rowData, lawFirmId: value, attorneyId: undefined });
      return col.onChange(value);
    },
    value: col.value || undefined,
    className: classes.inputWidth,
    error: col.helperText,
    options:
      name === "attorneyId"
        ? lawyers
            .filter(l => (!col.rowData.lawFirmId && !l.lawFirmId) || l.lawFirmId === col.rowData.lawFirmId)
            .map(({ id, fullName }) => ({ id, text: fullName }))
        : options || undefined,
    ...(name === "lawFirmId" && lawyers.some(x => !x.lawFirmId) ? { allowClear: true } : {}),
    ...(name === "effectiveDate" ? { maxDate: col.rowData.expirationDate, maxDateMessage: <></> } : {}),
    ...(name === "expirationDate" ? { minDate: col.rowData.effectiveDate, minDateMessage: <></> } : {})
  });
  const editComponent = col => {
    const {
      columnDef: { field },
      rowData: { lawFirmId, attorneyId }
    } = col;
    let firm = firms.find(f => f.id === lawFirmId);
    if (!firm?.address1) firm = lawyers.find(f => f.id === attorneyId) || {};
    return <span>{firm[field]}</span>;
  };
  const tableProps = {
    title: "Claimant Attorney Information",
    columns: [
      {
        title: "Eff.Date",
        field: "effectiveDate",
        validate: r => (r.effectiveDate ? isDate(r.effectiveDate) || "Invalid Date" : "Eff. Date Required"),
        editComponent: col => <DateTimeInput keyboard {...inputProps(col, "effectiveDate")} />,
        render: col => (col.effectiveDate ? moment(col.effectiveDate).format("M/D/YYYY") : "")
      },
      {
        title: "Exp.Date",
        field: "expirationDate",
        validate: r =>
          !r.expirationDate ||
          (!isDate(r.expirationDate) && "Invalid Date") ||
          !isDate(r.effectiveDate) ||
          moment(r.effectiveDate).isSameOrBefore(moment(r.expirationDate)) ||
          "Exp. Date should be after Eff. Date",
        editComponent: col => <DateTimeInput keyboard {...inputProps(col, "expirationDate")} />,
        render: col => (col.expirationDate ? moment(col.expirationDate).format("M/D/YYYY") : "")
      },
      {
        title: "Firm Name",
        field: "lawFirmId",
        validate: r => !r.lawFirmId || isNumber(r.lawFirmId) || "Invalid Firm",
        editComponent: col => <SelectInput {...inputProps(col, "lawFirmId", firms)} />,
        render: ({ lawFirmId }) => firms.find(({ id }) => id === lawFirmId)?.text
      },
      {
        title: "Lawyer Name",
        field: "attorneyId",
        validate: r => isNumber(r.attorneyId) || "Lawyer Required",
        editComponent: col => <SelectInput {...inputProps(col, "attorneyId")} />,
        render: ({ attorneyId }) => lawyers.find(({ id }) => id === attorneyId)?.fullName
      },
      { title: "Address", field: "address1", editComponent },
      { title: "City", field: "city", editComponent },
      { title: "State", field: "state", editComponent },
      { title: "Zip", field: "zipCode", editComponent }
    ],
    data: attorneys,
    options: { actionsColumnIndex: -1 },
    hideExportButton: true,
    onActionSave: payload => new Promise(res => res(dispatch({ type: actionTypes.SET_ATTORNEY, payload }))),
    onDelete: ({ id }) => dispatch({ type: actionTypes.DELETE_ATTORNEY, payload: id })
  };
  return (
    <Paper className={classes.root}>
      <MaterialTable {...tableProps} hidePaging />
    </Paper>
  );
};
