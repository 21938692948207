import { INITIALREPORT as REPORT, API } from "../../../actions/actionTypes";
import { getSuccess, setSuccess } from "../../../actions/initialReport/insuredVehicle";
import { takeLatest, takeLatestPost } from "../../common/sagaGenerator";

const url = `${API.URL}/claim/insuredVehicle`;

export default [
  takeLatest({
    type: REPORT.INFORMATION.INSUREDVEHICLE.GET,
    actionSuccess: getSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading insured vehicle",
    url
  }),
  takeLatestPost({
    type: REPORT.INFORMATION.INSUREDVEHICLE.SET,
    actionSuccess: setSuccess,
    params: ({ insuredVehicle }) => insuredVehicle,
    successMessage: "Insured Vehicle Updated",
    errorMessage: "Error updating Insured Vehicle",
    url
  })
];
