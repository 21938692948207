import React, { Fragment, useEffect, useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Grid, Collapse, Typography, Switch, FormControlLabel } from "@material-ui/core";
import { SelectInput, InputText, PhoneInput, DateTimeInput, TaxSocialSecurityNumber, TaxIdNumber } from "../../common/inputs";
import { box } from "../../common/index.styles";
import CollapsibleBox from "../../common/CollapsibleBox";
import useOnToggleBoxes from "../../common/customHooks/useHandleBox";
import useYupValidationSchema from "../../common/customHooks/useYupValidationSchema";

const Basic = ({ data, setChange, fireValidation }) => {
  const { open, onToggle } = useOnToggleBoxes({ initialValue: { basic: true } });
  const { handleValidateSchema, validationMessages } = useYupValidationSchema(
    yup.object().shape({
      isPerson: yup
        .boolean()
        .nullable()
        .typeError("Type Required")
        .required("Type Required"),
      firstName: yup.string().when("isPerson", {
        is: true,
        then: yup.string().required("First Name Required"),
        otherwise: yup.string().nullable()
      }),
      lastName: yup.string().when("isPerson", {
        is: true,
        then: yup.string().required("Last Name Required"),
        otherwise: yup.string().nullable()
      }),
      name: yup.string().when("isPerson", {
        is: false,
        then: yup.string().required("Name Required"),
        otherwise: yup.string().nullable()
      }),
      categoryId: yup
        .number()
        .nullable()
        .typeError("Category is Required")
        .required("Category is Required"),
      stateId: yup
        .number()
        .nullable()
        .typeError("State is Required")
        .required("State is Required"),
      email: yup.string().email("Invalid Email"),
      address1: yup
        .string()
        .nullable()
        .test("Bank Street Required", "Bank Street Required", (val, ctx) => {
          if (ctx.parent.categoryId === 3 && !val) return false;
          return true;
        }),
      city: yup
        .string()
        .nullable()
        .test("Bank City Required", "Bank City Required", (val, ctx) => {
          if (ctx.parent.categoryId === 3 && !val) return false;
          return true;
        })
    })
  );
  const dataSelector = useSelector(({ common }) => common, shallowEqual);
  const { vendorCategories, memberCategories, taxType, states } = dataSelector || {};

  const {
    isPerson,
    firstName,
    lastName,
    name,
    categoryId,
    isActive,
    taxId,
    phone1,
    fax,
    email,
    webSite,
    description,
    taxIDTypeId,
    location,
    w9RequestedOn,
    w9ReceivedOn,
    w9EnteredOn,
    hasMultipleLocations,
    mailingLocation
  } = data || {};

  useEffect(() => {
    const { isValid } = handleValidateSchema({
      isPerson,
      firstName,
      lastName,
      name,
      categoryId,
      stateId: location?.stateId,
      email,
      address1: location?.address1,
      city: location?.city
    });
    setChange({ type: "SET_VALID_SCHEMA", value: isValid });
  }, [isPerson, firstName, lastName, name, categoryId, fireValidation, location?.stateId, email, location?.address1, location?.city]);

  const handleChange = ({ target: { name: eName, value } }, isLocation) => {
    switch (true) {
      case eName === "isPerson":
        setChange({
          type: "SET_BASIC_INFORMATION_PERSON",
          payload: { isPerson: value === 1, firstName: "", lastName: "", name: "", categoryId: undefined }
        });
        break;
      case isLocation:
        setChange({ type: "SET_BASIC_INFORMATION_LOCATION", payload: { name: eName, value } });
        break;
      default:
        setChange({ type: "SET_BASIC_INFORMATION", payload: { name: eName, value: eName === "isActive" ? value === 1 : value } });
        break;
    }
  };
  const onChangeSwitch = ({ target: { name: tName, checked } }) => {
    setChange({ type: "SET_BASIC_INFORMATION", payload: { name: tName, value: checked } });
  };

  const handleChangeMailingLocation = ({ target: { name: tName, value } }) => {
    setChange({ type: "SET_BASIC_INFORMATION_MAILING_LOCATION", payload: { name: tName, value } });
  };
  const classes = box();
  const styleSwitch = { "aria-label": "primary checkbox" };

  const personTypes = useMemo(
    () => [
      { id: 1, text: "Person" },
      { id: 2, text: "Entity" }
    ],
    []
  );

  const memberCategoryTypes = useMemo(() => memberCategories, [memberCategories]);

  const vendorCategoryTypes = useMemo(() => vendorCategories, [vendorCategories]);

  const activeTypes = useMemo(
    () => [
      { id: 1, text: "Active" },
      { id: 2, text: "Inactive" }
    ],
    []
  );

  return (
    <Fragment key="Vendor-Add-Basic">
      <CollapsibleBox title="Basic Information - Headquarters" name="basic" open={open.basic} onToggle={onToggle}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <SelectInput
              name="isPerson"
              label="Type"
              value={isPerson ? 1 : 2}
              options={personTypes}
              onChange={handleChange}
              error={validationMessages?.isPerson}
            />
            <Collapse in={isPerson}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <InputText name="firstName" label="First Name" value={firstName} onChange={handleChange} error={validationMessages?.firstName} />
                </Grid>
                <Grid item xs>
                  <InputText name="lastName" label="Last Name" value={lastName} onChange={handleChange} error={validationMessages?.lastName} />
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={!isPerson}>
              <InputText name="name" label="Name" value={name} onChange={handleChange} error={validationMessages?.name} />
            </Collapse>
            {isPerson === true ? (
              <SelectInput
                name="categoryId"
                label="Category"
                value={categoryId}
                options={memberCategoryTypes}
                onChange={handleChange}
                error={validationMessages?.categoryId}
              />
            ) : (
              <SelectInput
                name="categoryId"
                label="Category"
                value={categoryId}
                options={vendorCategoryTypes}
                onChange={handleChange}
                error={validationMessages?.categoryId}
              />
            )}
            <InputText name="webSite" label="Web Site" value={webSite} onChange={handleChange} />
          </Grid>
          <Grid item xs={3}>
            <SelectInput name="isActive" label="Status" value={isActive ? 1 : 2} options={activeTypes} onChange={handleChange} />
            <PhoneInput name="phone1" label="Phone" value={phone1} onChange={handleChange} />
            <PhoneInput name="fax" label="Fax Number" value={fax} onChange={handleChange} />
            <SelectInput name="taxIDTypeId" label="Tax ID Type" value={taxIDTypeId} options={taxType} onChange={handleChange} />
          </Grid>
          <Grid item xs={3}>
            <InputText name="email" label="Email Address" value={email} onChange={handleChange} error={validationMessages?.email} />
            <InputText
              name="address1"
              label="Address 1"
              value={location?.address1 || ""}
              onChange={e => handleChange(e, !0)}
              error={validationMessages?.address1}
            />
            <InputText name="address2" label="Address 2" value={location?.address2 || ""} onChange={e => handleChange(e, !0)} />
            {(taxIDTypeId &&
              (taxIDTypeId === 1 ? (
                <TaxSocialSecurityNumber name="taxId" label="Tax ID" value={taxId} onChange={handleChange} />
              ) : (
                <TaxIdNumber name="taxId" label="Tax ID" value={taxId} onChange={handleChange} />
              ))) || <InputText label="Tax ID" />}
          </Grid>
          <Grid item xs={3}>
            <InputText name="city" label="City" value={location?.city || ""} onChange={e => handleChange(e, !0)} error={validationMessages?.city} />
            <SelectInput
              name="stateId"
              label="State"
              value={location?.stateId || null}
              options={states}
              onChange={e => handleChange(e, !0)}
              error={validationMessages?.stateId}
            />
            <InputText name="zipCode" label="Zip" value={location?.zipCode || ""} onChange={e => handleChange(e, !0)} />
            <FormControlLabel
              label="Multiple Locations"
              control={<Switch checked={hasMultipleLocations} onChange={onChangeSwitch} name="hasMultipleLocations" inputProps={styleSwitch} />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <InputText name="address1" label="Mailing Address" value={mailingLocation?.address1 || ""} onChange={handleChangeMailingLocation} />
          </Grid>
          <Grid item xs={3}>
            <InputText name="city" label="Mailing City" value={mailingLocation?.city || ""} onChange={handleChangeMailingLocation} />
          </Grid>
          <Grid item xs={3}>
            <SelectInput
              name="stateId"
              label="Mailing State"
              value={mailingLocation?.stateId || null}
              options={states}
              onChange={handleChangeMailingLocation}
            />
          </Grid>
          <Grid item xs={3}>
            <InputText name="zipCode" label="Mailing Zip" value={mailingLocation?.zipCode || ""} onChange={handleChangeMailingLocation} />
          </Grid>
        </Grid>
        <InputText name="description" label="Description" value={description} rows={3} onChange={handleChange} />
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant="subtitle2" className={classes.title}>
            W9 Information
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <DateTimeInput name="w9RequestedOn" label="Date Requested" value={w9RequestedOn} onChange={handleChange} keyboard />
          </Grid>
          <Grid item xs={3}>
            <DateTimeInput name="w9ReceivedOn" label="Date Received" value={w9ReceivedOn} onChange={handleChange} keyboard />
          </Grid>
          <Grid item xs={3}>
            <DateTimeInput name="w9EnteredOn" label="Date Entered to the System" value={w9EnteredOn} onChange={handleChange} keyboard />
          </Grid>
        </Grid>
      </CollapsibleBox>
    </Fragment>
  );
};

Basic.propTypes = {
  data: PropTypes.shape({}).isRequired,
  setChange: PropTypes.func.isRequired,
  fireValidation: PropTypes.bool.isRequired
};

export default Basic;
