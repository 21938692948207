import React from "react";
import PropTypes from "prop-types";
import { SvgIcon, Grid } from "@material-ui/core";
import ClaimDuplicates from "./ClaimDuplicates";
import ClaimAddHeader from "./ClaimAddHeader";
import ClaimAddFooter from "./ClaimAddFooter";
import { useContext } from "../context";
import Coverage from "../../../../assets/coverage.svg";
import NoCoverage from "../../../../assets/no_coverage.svg";

const CoverageIcon = ({ ltg, lob, text, label }) => {
  const [{ insured }] = useContext();
  const { id: insuredId, coverageTypes } = insured;
  if (!insuredId || !Array.isArray(coverageTypes)) return null;
  const coverage =
    ltg || lob || text
      ? coverageTypes.some(({ ltgId, lobId, text: tx }) => ((!ltg || ltg === ltgId) && (!lob || lob === lobId)) || tx === text)
      : false;
  const icon = (
    <div style={{ height: 24 }}>
      <SvgIcon>{coverage ? <Coverage /> : <NoCoverage />}</SvgIcon>
    </div>
  );
  if (!label) return icon;
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>{icon}</Grid>
      <Grid item style={coverage ? null : { color: "#e74c3c" }}>
        {coverage ? "Coverage" : "Coverage Investigation Required"}
      </Grid>
    </Grid>
  );
};

CoverageIcon.propTypes = {
  ltg: PropTypes.number,
  lob: PropTypes.number,
  text: PropTypes.string,
  label: PropTypes.bool
};

CoverageIcon.defaultProps = {
  ltg: undefined,
  lob: undefined,
  text: undefined,
  label: false
};

export { CoverageIcon, ClaimDuplicates, ClaimAddHeader, ClaimAddFooter };
