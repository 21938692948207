import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 0, 0, 0),
    height: "100%"
  },
  container: {
    padding: theme.spacing(2, 2, 2, 2),
    fontSize: 14
  },
  containerSearch: {
    flexDirection: "column"
  },
  paper: { padding: theme.spacing(2, 3) },
  title: {
    color: "#428302",
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 5
  },
  titleSearch: {
    color: "#004763",
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 5
  },
  containerh: {
    padding: theme.spacing(1, 20, 1, 3),
    backgroundColor: "#F4F8F9",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.4)",
    margin: theme.spacing(0, 0, 0, 0),
    width: "100%",
    zIndex: 1
  },
  titleh: { color: "#004763", fontSize: 24, paddingRight: theme.spacing(10) },
  cancelButton: {
    margin: theme.spacing(3, 1, 1, 0)
  },
  saveButton: {
    margin: theme.spacing(3, 0, 1, 0)
  },
  upload: {
    backgroundColor: "#F3F3F3",
    padding: theme.spacing(3.38, 2.8, 2.7, 2.8),
    textAlign: "center",
    color: "#898F91",
    height: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    "& svg": { fontSize: 66, marginBottom: -12 },
    "& button": { marginTop: theme.spacing(3) }
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    verticalAlign: "middle",
    lineHeight: "1em",
    "& > tbody > tr > td:first-of-type": {
      width: 36,
      padding: theme.spacing(0.75, 0),
      color: "#898F91"
    },
    "& > tbody > tr > td:not(:first-of-type)": { padding: theme.spacing(0.5, 1.5) },
    "& > tbody > tr > td:last-of-type": {
      width: 160
    }
  },
  containerT: {
    padding: "15px"
  }
}));
