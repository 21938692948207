import React from "react";

const Intelligence = () => (
  <iframe
    style={{ height: "973px", width: "1808px", border: "none" }}
    src="https://xd.adobe.com/embed/7a933b8e-10d3-45bd-9e2b-5069a42f66cf-2fc0/"
    title="terraClaim Dashboard"
  />
);

export default Intelligence;
