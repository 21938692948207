import React, { useContext } from "react";
import ClaimDiary from "../../components/common/diary";
import { useRouteParam } from "../../common";
import ActionsAllowedContext from "../../components/common/contexts/ActionsAllowed";

const Diary = () => {
  const claimId = useRouteParam("claimId");
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "diaries");
  return <ClaimDiary claimId={claimId} access={access} />;
};

export default Diary;
