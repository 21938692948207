import React from "react";
import PropTypes from "prop-types";
import { Grid, Divider } from "@material-ui/core";
import { read } from "../index.styles";
import MultipleLabels from "../Labels";
import FormFooter from "../FormFooter";
import LabelValue from "../LabelValue";

const Read = React.memo(({ diary, onCancel }) => {
  const classRead = read();
  const { type, priority, status, dueDate, owner, name, description } = diary;
  return (
    <div className={`${classRead.form} tc-pb0`} id="print-diary">
      <Grid container spacing={4}>
        <Grid item xs>
          <MultipleLabels
            data={[
              { label: "Type", value: type, key: "diary-type" },
              { label: "Due Date", value: dueDate, isDate: true, key: "diary-dueDate" }
            ]}
          />
        </Grid>
        <Divider />
        <Grid item xs>
          <MultipleLabels
            data={[
              { label: "Priority", value: priority, key: "diary-prority" },
              { label: "Owner", value: owner, key: "diary-owner" }
            ]}
          />
        </Grid>
        <Divider />
        <Grid item xs>
          <MultipleLabels
            data={[
              { label: "Status", value: status, key: "diary-status" },
              { label: "Diary Name", value: name, key: "diary-name" }
            ]}
          />
        </Grid>
      </Grid>
      <LabelValue label="Description" value={description} html />
      <FormFooter isAdd={false} editMode={false} onCancel={onCancel} cancelText="Close" />
    </div>
  );
});

Read.propTypes = {
  diary: PropTypes.objectOf(PropTypes.any).isRequired,
  onCancel: PropTypes.func
};

Read.defaultProps = {
  onCancel: () => {}
};

export default Read;
