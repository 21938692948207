import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Button, Avatar } from "@material-ui/core";
import clsx from "clsx";
import { toast } from "react-toastify";
import authorization from "../../auth";
import { delPhoto, set, uploadPhoto } from "../../redux/actions/profile";
import { InputText, PhoneInput, SelectInput } from "../common/inputs";
import LabelValue from "../common/LabelValue";
import LinkButton from "../common/LinkButton";
import { onChoose } from "./menu";
import useStyles from "./MyProfilePage.styles";

const MyProfilePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const [{ hover, error }, setHover] = useState({ hover: false, error: false });
  const { firstName, middleName, lastName, mobilePhoneNumber, businessPhoneNumber, photo, avatarName, securityProfiles, timeZoneId } = state;
  const store = useSelector(({ profile: p, common: { timeZone: t } }) => ({ profile: p, timeZone: t }), shallowEqual);
  const { profile, timeZone } = store || {};
  useEffect(() => {
    if (typeof profile.id === "undefined") return;
    setState(Object.keys(profile).reduce((acc, prop) => ({ ...acc, [prop]: profile[prop] || undefined }), {}));
  }, [profile]);
  const onChange = ({ target: { name, value } }) => setState(ps => ({ ...ps, [name]: value }));
  const onDelete = () => dispatch(delPhoto());
  const onSave = event => {
    event.preventDefault();
    dispatch(set(state));
  };
  const securityProfile = Array.isArray(securityProfiles) && securityProfiles.map(p => p.text).join(", ");
  const validateFile = ({ kind, type }) => kind === "file" && type.indexOf("image/") === 0;
  const onDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
    setHover({ hover: true, error: [...event.dataTransfer.items].some(i => !validateFile(i)) });
  };
  const onDragLeave = () => setHover({ hover: false, error: false });
  const onDrop = event => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;
    setHover({ hover: false, error: false });
    if ([...files].map(f => ({ type: f.type, kind: "file" })).some(i => !validateFile(i))) {
      toast.error("File is not image");
      return;
    }
    dispatch(uploadPhoto(files[0]));
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.row}>
        <Grid item xs={4}>
          <Paper
            {...{
              className: clsx(classes.paper, classes.photoBox, { [classes.hover]: hover, [classes.error]: error }),
              onDragOver,
              onDragLeave,
              onDrop
            }}
          >
            <div className={classes.title}>Profile Picture</div>
            <Grid container alignContent="center" alignItems="center" direction="column">
              <Grid item className={classes.backgroundAvatar}>
                <Avatar alt={avatarName} src={photo} className={classes.userAvatar}>
                  {avatarName}
                </Avatar>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item>Drag and drop your photo to upload</Grid>
                  <Grid item container justify="space-between">
                    <Grid item className={classes.line} />
                    <Grid item>OR</Grid>
                    <Grid item className={classes.line} />
                  </Grid>
                  <Grid item className={classes.uploadButtons}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button variant="contained" color="primary" onClick={onChoose}>
                          Choose File
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" color="primary" onClick={onDelete}>
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.reset}>
                    <LinkButton blue onClick={authorization.resetPassword}>
                      Reset Password
                    </LinkButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper className={classes.paper}>
            <div className={classes.title}>User Information</div>
            <form onSubmit={onSave}>
              <Grid container spacing={2}>
                <Grid item xs={6} className={classes.input}>
                  <InputText name="firstName" label="First Name*" value={firstName} {...{ onChange }} />
                </Grid>
                <Grid item xs={6} className={classes.input}>
                  <InputText name="middleName" label="Middle Name" value={middleName} {...{ onChange }} />
                </Grid>
                <Grid item xs={6} className={classes.input}>
                  <InputText name="lastName" label="Last Name*" value={lastName} {...{ onChange }} />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6} className={classes.input}>
                  <PhoneInput name="mobilePhoneNumber" label="Mobile Phone*" value={mobilePhoneNumber} {...{ onChange }} />
                </Grid>
                <Grid item xs={6} className={classes.input}>
                  <PhoneInput name="businessPhoneNumber" label="Business Phone" value={businessPhoneNumber} {...{ onChange }} />
                </Grid>
                <Grid item xs={6}>
                  <LabelValue label="Profile" value={securityProfile} classes={{ root: classes.mb0 }} />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6} className={classes.input}>
                  <SelectInput name="timeZoneId" label="Time Zone*" options={timeZone} value={timeZoneId} {...{ onChange }} />
                </Grid>
              </Grid>
              <Grid container justify="flex-end">
                <Grid item>
                  <Button type="submit" variant="contained" color="primary">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
        <Grid item xs style={{ display: "none" }}>
          <Paper className={classes.paper}>
            <div className={classes.title}>Change Password</div>
            <Grid container spacing={2}>
              <Grid item xs>
                inputs
              </Grid>
              <Grid item xs>
                Passwords must be at least 80% strong. In order to meet this requirement it must:
                <ul className={classes.ul}>
                  <li>Contain at least 8 characters</li>
                  <li>Include uppercase and lowercase letters</li>
                  <li>Include at least 1 number</li>
                </ul>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Paper className={classes.paper} style={{ display: "none" }}>
        <div className={classes.title}>Last 10 TerraClaim Access</div>
      </Paper>
    </div>
  );
};

export default MyProfilePage;
