import React, { memo } from "react";
import PropTypes from "prop-types";
import { DatePicker, KeyboardDatePicker, KeyboardDateTimePicker, DateTimePicker } from "@material-ui/pickers";
import { HocInput } from "./HocInput";

const DateTimeInput = memo(
  ({ error, onChange, name, keyboard, time, ...other }) => {
    const props = {
      ...other,
      name,
      onChange: m => {
        const format = `YYYY-MM-DDT${time ? "HH:mm:ss" : "00:00:00"}`;
        return onChange({
          target: {
            name,
            value: m && m.isValid() ? m.format(format) : ""
          }
        });
      },
      fullWidth: true
    };
    if (error) {
      props.helperText = error;
      props.error = true;
    }
    if (time) {
      if (props.format === "MM/DD/YYYY") props.format = "MM/DD/YYYY - HH:mm";
      if (keyboard) return <KeyboardDateTimePicker {...props} />;
      return <DateTimePicker {...props} />;
    }
    if (keyboard) return <KeyboardDatePicker {...props} />;
    return <DatePicker {...props} />;
  },
  (p, n) => !["value", "error", "label", "maxDate", "minDate"].some(k => p[k] !== n[k])
);

DateTimeInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  format: PropTypes.string,
  error: PropTypes.string,
  autoOk: PropTypes.bool,
  keyboard: PropTypes.bool,
  time: PropTypes.bool
};

DateTimeInput.defaultProps = {
  placeholder: null,
  value: null,
  variant: "inline",
  format: "MM/DD/YYYY",
  error: null,
  autoOk: true,
  keyboard: false,
  time: false,
  label: undefined
};

export default HocInput(DateTimeInput);
