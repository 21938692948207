import { Grid, Button, Grow } from "@material-ui/core";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useClaimAddFooterStyles } from "./index.styles";
import { useContext, actionTypes } from "../context";
import { mapPostData } from "../mapPostData";
import { post } from "../../../../redux/actions/claim";
import Confirm from "../../../common/Confirm";
import { refPropType } from "../../../../common";

const ClaimAddFooter = memo(({ container }) => {
  const classes = useClaimAddFooterStyles();
  const [state, dispatch, , { validate, clearMessages }] = useContext();
  const [openDialog, setOpenDialog] = useState(false);
  const { START_OVER, PREV_STEP, NEXT_STEP } = actionTypes;
  const storeDispatch = useDispatch();
  const history = useHistory();
  const onChange = type => () => dispatch({ type });
  const onValidate = fn => {
    const { isValid, validationMessages } = validate();
    if (!isValid) {
      if (validationMessages?.toast) toast.warn(validationMessages?.toast);
      return false;
    }
    return fn();
  };
  const onFinish = () => onValidate(() => setOpenDialog(true));
  const { step } = state;
  const confirmProps = () => {
    const val = { messageText: "", onAction: () => {}, onToggleConfirm: () => setOpenDialog(o => !o), openDialog, title: "" };
    if (step === 3) {
      const data = mapPostData(state);
      const { claimants, coverageTypes, multipleClaimants } = data;
      const arr = multipleClaimants ? claimants : coverageTypes;
      if (!Array.isArray(arr) || !arr.some(() => true) || arr.some(c => typeof c.isReportOnly === "undefined"))
        return {
          ...val,
          messageText: "In order to proceed, please select an option to create the records as Claims or Initial Reports",
          okText: "Accept",
          onAction: val.onToggleConfirm,
          title: "Claims / Initial Reports",
          hideCancel: true
        };
      let all = 0;
      const txt = (count, label) => {
        all += count;
        if (!count) return { c: "", s: "" };
        const s = `${label}${count > 1 ? "s" : ""}`;
        return { c: `${count} ${s}`, s };
      };
      const txtClaims = txt(arr.filter(c => !c.isReportOnly).length, "Claim");
      const txtReports = txt(arr.filter(c => c.isReportOnly).length, "Initial Report");
      const pre = `The following record${all > 1 ? "s are" : " is"} going to be created: `;
      const and = txtClaims.c && txtReports.c ? " and " : "";
      return {
        ...val,
        messageText: (
          <>
            <p>{`${pre}${txtClaims.c}${and}${txtReports.c}`}</p>
            <p>Are you sure you want to proceed?</p>
          </>
        ),
        okText: "Yes, Create",
        onAction: () => {
          setOpenDialog(false);
          return storeDispatch(
            post({
              data,
              onSuccess: () => {
                toast.success(`${txtClaims.s}${and}${txtReports.s} successfully created`);
                return history.push("/claims");
              },
              onError: () => toast.error(`Unable to create the ${txtClaims.s}${and}${txtReports.s}`)
            })
          );
        },
        title: "Claims / Initial Reports Creation"
      };
    }
    return val;
  };
  const onNext = () =>
    onValidate(() => {
      if (container?.current) container.current.scroll({ top: 0, behavior: "smooth" });
      return onChange(NEXT_STEP)();
    });
  const onStartOver = () => {
    clearMessages();
    return onChange(START_OVER)();
  };
  return (
    <div className={classes.root} style={{ paddingRight: step < 2 ? 492 : 42 }}>
      <Grid container spacing={2}>
        <Grid item style={{ flexGrow: 1 }}>
          <Button variant="contained" className={classes.btn} onClick={onStartOver}>
            Clear Step
          </Button>
        </Grid>
        <Grid item>
          <Grow in={step > 0}>
            <Button variant="contained" className={classes.btn} onClick={onChange(PREV_STEP)}>
              Back
            </Button>
          </Grow>
        </Grid>
        <Grid item>
          {step < 3 && (
            <Button variant="contained" color="primary" onClick={onNext}>
              Next
            </Button>
          )}
          {step === 3 && (
            <Button variant="contained" color="primary" onClick={onFinish}>
              Finish
            </Button>
          )}
        </Grid>
      </Grid>
      <Confirm {...confirmProps()} />
    </div>
  );
});

ClaimAddFooter.propTypes = { container: refPropType().isRequired };

export default ClaimAddFooter;
