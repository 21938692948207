import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Paper, Grid } from "@material-ui/core";
import { Room } from "@material-ui/icons";
import { useClaimInfoStyles } from "./index.styles";
import ClaimStatus from "../../ClaimStatus";
import ClaimProcessingStatus from "../../ClaimProcessingStatus";
import LossTypeGroupIcon from "../../LossTypeGroupIcon";

export default () => {
  const state = useSelector(({ claim: { claim } }) => claim, shallowEqual);
  const {
    number,
    status,
    processingStatus,
    claimLineOfBusiness,
    // claimLineOfBusinessCode,
    claimLossTypeGroup,
    claimLossType,
    location,
    claimLineOfBusinessId,
    type,
    injuryType
  } = state;
  const { state: stateName } = location || {};
  const classes = useClaimInfoStyles();
  const isClaimWC = claimLineOfBusinessId === 4;
  const showHeadComponent = (title, icon, text) => {
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={2}>
          {icon}
        </Grid>
        <Grid item xs={10}>
          <Grid container alignItems="center" spacing={0}>
            <Grid item xs={12}>
              <div className={classes.label}>{title}</div>
            </Grid>
            <Grid item xs={12}>
              {text}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <Paper className={classes.root}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={2}>
          <div className={classes.title}>Claim</div>
          {number && <div className={classes.claimNo}>{number}</div>}
        </Grid>
        <Grid item xs={3}>
          <Grid container alignItems="center" spacing={0}>
            <Grid item xs={6}>
              {status && showHeadComponent("Claim Status", <ClaimStatus {...{ status }} />, status)}
            </Grid>
            <Grid item xs={6}>
              {processingStatus && showHeadComponent("Processing Status", <ClaimProcessingStatus {...{ processingStatus }} />, processingStatus)}
            </Grid>
          </Grid>
        </Grid>
        {claimLineOfBusiness && (
          <Grid item xs={2}>
            <Grid container alignItems="center" spacing={0}>
              <Grid item xs={12}>
                <div className={classes.label}>Line Of Business</div>
              </Grid>
              <Grid item>
                <div className={classes.rectangle}>{claimLineOfBusiness}</div>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isClaimWC && (
          <Grid item xs={3}>
            <Grid container alignItems="center" spacing={0}>
              <Grid item xs={6}>
                <Grid container alignItems="center" spacing={0}>
                  <Grid item xs={12}>
                    <div className={classes.label}>Nature Of Injury</div>
                  </Grid>
                  <Grid item>{injuryType || "-"}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center" spacing={0}>
                  <Grid item xs={12}>
                    <div className={classes.label}>Claim Type</div>
                  </Grid>
                  <Grid item>{type || "-"}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isClaimWC && claimLossTypeGroup && claimLossType && (
          <Grid item xs={3}>
            {showHeadComponent("Loss Type Group", <LossTypeGroupIcon type={claimLossTypeGroup} />, `${claimLossTypeGroup}: ${claimLossType}`)}
          </Grid>
        )}
        {stateName && (
          <Grid item xs={2}>
            {showHeadComponent("Jurisdiction State", <Room />, stateName)}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
