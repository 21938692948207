import React, { useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../common/CollapsibleBox";
import ClaimForm from "../../common/claims/claim/Form";
import ClaimViewMode from "../../common/claims/claim/ViewMode";
import { set } from "../../../redux/actions/initialReport/claim";
import ActionsAllowedContext from "../../common/contexts/ActionsAllowed";

const ClaimBox = props => {
  const store = useSelector(({ initialReport }) => initialReport, shallowEqual);
  const { claim } = store || {};
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info");
  const {
    props: { boxProps, editMode, loading: isLoading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  const dispatch = useDispatch();
  const onSave = data => {
    if (access.update) {
      showLoading();
      dispatch(set(data, onSuccessEdit, hideLoading));
    }
  };
  return (
    <CollapsibleBox title="Claim" name="claim" {...boxProps} {...props}>
      {Loading}
      {(editMode && <ClaimForm isReportOnly {...{ claim, onClose: onToggleEdit, onSave, isLoading }} />) || (
        <ClaimViewMode isReportOnly {...{ claim }} />
      )}
    </CollapsibleBox>
  );
};

export default ClaimBox;
