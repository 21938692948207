import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { memo } from "react";
import ClaimList from "./ClaimList";
import { BaseCheckboxLabel as CheckboxLabel, DateTimeInput, SearchLabelInput } from "../../../common/inputs";
import { useSearch } from "./hooks";

const ClaimSearch = memo(({ actions, disabled }) => {
  const { state, inputProps, claimStatus } = useSearch(actions, disabled);
  return (
    <div className="tc-page-content">
      <Typography className="tc-title">Claim Search</Typography>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <SearchLabelInput {...inputProps("number", "Claim Number")} />
          <DateTimeInput {...inputProps("dateOfLoss", "DOL Range")} keyboard />
        </Grid>
        <Grid item xs={6}>
          <SearchLabelInput {...inputProps("claimantName", "Claimant Name")} />
          <DateTimeInput {...inputProps("dateTo", "Through")} keyboard />
        </Grid>
      </Grid>
      <Grid container spacing={2} wrap="nowrap" justify="space-between" alignItems="center">
        <Grid item className="tc-label">
          Claim Status
        </Grid>
        {claimStatus.map(({ id, text }) => (
          <Grid item key={`status-${id}`}>
            <CheckboxLabel {...inputProps(`status-${id}`, text, id)} />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={4} style={{ marginBottom: 16 }}>
        <Grid item xs={6}>
          <SearchLabelInput {...inputProps("clientNumber", "Client Number")} />
        </Grid>
      </Grid>
      <Typography className="tc-title">Search Results</Typography>
      <ClaimList {...{ actions, data: state.data, disabled }} />
    </div>
  );
});

ClaimSearch.propTypes = { actions: PropTypes.objectOf(PropTypes.func).isRequired, disabled: PropTypes.bool.isRequired };

export default ClaimSearch;
