import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, IconButton, Avatar, Popover, Button } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import authentication from "../../../auth";
import { get, uploadPhoto, getPhoto } from "../../../redux/actions/profile";
import styles from "./index.styles";
import UploadAvatar from "./Avatar";

export const onChoose = event => {
  event.preventDefault();
  document.querySelector("#photo-input").click();
};

const MyProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = styles();
  const onClose = () => setAnchorEl(null);
  const handleMenu = event => setAnchorEl(event.currentTarget);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get());
    dispatch(getPhoto());
  }, []);
  const store = useSelector(({ profile: p }) => p, shallowEqual);
  const { firstName, lastName, securityProfiles, photo, avatarName } = store || {};
  const open = Boolean(anchorEl);
  const id = open ? "menu-appbar" : undefined;
  const { location, push } = useHistory();
  const onCloseRun = fn => (...args) => {
    setAnchorEl(null);
    if (typeof fn === "function") fn(...args);
  };
  const onNavigate = url => onCloseRun(() => location.pathname !== url && push(url));
  const onUpload = ({ target: { files } }) => dispatch(uploadPhoto(files[0]));
  const profile = Array.isArray(securityProfiles) && securityProfiles.map(p => p.text).join(", ");
  return (
    <>
      <IconButton className={classes.backgroundAvatar} aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
        <Avatar className={classes.userAvatar} alt={firstName} src={photo}>
          {avatarName}
        </Avatar>
      </IconButton>
      <Popover
        {...{ id, open, anchorEl, onClose }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Grid container direction="column" justify="center" className={classes.root}>
          <Grid item>
            <UploadAvatar onClick={onCloseRun(onChoose)} />
            <input id="photo-input" type="file" accept="image/*" onChange={onUpload} style={{ display: "none" }} />
          </Grid>
          <Grid item className={classes.name}>
            {`${firstName} ${lastName}`}
          </Grid>
          {profile && (
            <Grid item className={classes.profile}>
              {profile}
            </Grid>
          )}
          <Grid item container className={classes.buttons}>
            <Grid item xs className={classes.pr}>
              <Button variant="outlined" color="primary" onClick={onNavigate("/myinfo/editprofile")}>
                My Profile
              </Button>
            </Grid>
            <Grid item xs className={classes.pl}>
              <Button variant="outlined" color="primary" onClick={onNavigate("/myinfo/editprofile")}>
                Settings
              </Button>
            </Grid>
          </Grid>
          <Grid item className={classes.mb}>
            <Button onClick={onCloseRun(authentication.resetPassword)} variant="contained" color="primary">
              Reset Password
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onCloseRun(authentication.signOut)} variant="contained" color="primary">
              Logout
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default MyProfileMenu;
