import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Styles from "./index.styles";

const VendorFinancial = ({ financialInfo, ...rest }) => {
  const classes = Styles();
  return (
    <div {...rest}>
      <div className={classes.colorGreenLight}>Financial</div>
      <Grid container spacing={3}>
        <Grid item xs={6} className={classes.cMarginTop}>
          <span className={classes.label}>Total Paid Amount</span>
          <div className={classes.totalPaid}>{`$ ${financialInfo?.total?.totalPaid}`}</div>
        </Grid>
        <Grid item xs={6} className={classes.cMarginTop}>
          <span className={classes.label}>Pending Payment</span>
          <div className={clsx(classes.cMarginTop, classes.paymentColor)}>{`$ ${financialInfo.total?.totalPendingPaid}`}</div>
        </Grid>
      </Grid>
    </div>
  );
};

VendorFinancial.propTypes = {
  financialInfo: PropTypes.shape({ barChart: PropTypes.arrayOf(PropTypes.shape({})), total: PropTypes.shape({}) }).isRequired
};

export default VendorFinancial;
