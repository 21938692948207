import { CLIENTR as CLIENT, API } from "../../../../actions/actionTypes";
import { getCodesSuccess, addCodeSuccess, deleteCodeSuccess } from "../../../../actions/client/insured/policy/code";
import { takeLatest, takeLatestDel, takeLatestPost } from "../../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLIENT.INSURED.POLICY.CODE.GET,
    actionSuccess: getCodesSuccess,
    errorMessage: "Error loading codes",
    url: `${API.URL}/policy/codes`,
    params: ({ policyId }) => ({ policyId })
  }),
  takeLatestPost({
    type: CLIENT.INSURED.POLICY.CODE.POST,
    actionSuccess: addCodeSuccess,
    successMessage: "Class Codes information successfully saved",
    errorMessage: "Unable to save the information",
    url: `${API.URL}/policy/code`,
    params: ({ policycode }) => policycode
  }),
  takeLatestDel({
    type: CLIENT.INSURED.POLICY.CODE.DEL,
    actionSuccess: deleteCodeSuccess,
    successMessage: "Code deleted from Policy",
    errorMessage: "Error deleting code",
    url: `${API.URL}/policy/code`,
    params: ({ policycode }) => policycode
  })
];
