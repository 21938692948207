import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import qs from "qs";
import { Typography, Grid } from "@material-ui/core";
import { SelectInput, TextInput, DateTimeInput, IntegerInput, PhoneInput, MoneyInput, BaseSelectInput } from "../../../common/inputs";
import { API } from "../../../../redux/actions/actionTypes";
import validationSchema from "./yup";
import useYupValidationSchema from "../../../common/customHooks/useYupValidationSchema";
import Search from "../../../common/SearchSuggestions/InputSuggestionsDebounced";
import File from "./BillFile";
import { useLoadingContext } from "../../../common/Loading";
import useAxios from "../../../common/customHooks/useAxios";
import ActionsFooter, { footerAction } from "../../../common/ActionsFooter";
import Confirm from "../../../common/Confirm";

const iniVendor = {
  query: "",
  results: [],
  vendorId: null,
  vendorContanctId: null,
  vendorAddress1: "",
  vendorAddress2: "",
  vendorZipCode: "",
  vendorStateId: null,
  vendorPhone: "",
  vendorCity: "",
  contacts: []
};

const BillForm = ({ onSave, onCancel, data, editMode, onReindex, onDuplicate, onDelete }) => {
  const [bill, setBill] = useState({});
  const [file, setFile] = useState({ deleted: false, filesToUpload: [] });
  const [search, setSearch] = useState(iniVendor);

  const store = useSelector(
    ({ common: { states: s, paymentMethods: pm, vendorTypes: vt, reindexReasons: rr } }) => ({ s, pm, vt, rr }),
    shallowEqual
  );
  // eslint-disable-next-line
  const { s: states, pm: paymentMethods, vt: vendorTypes, rr: reindexReasons } = store || {};
  const { loading } = useLoadingContext();
  const { sendRequest } = useAxios();
  useEffect(() => {
    if (search?.vendorId) {
      const vId = search?.vendorId;
      sendRequest({
        request: {
          method: "GET",
          url: `/vendor/getcontacts?parentId=${vId}`
        },
        onSucces: response => {
          setSearch(prevState => ({
            ...prevState,
            contacts: response.map(i => ({ id: i.id, text: i.fullName }))
          }));
        }
      });
    }
  }, [search.vendorId]);

  useEffect(() => {
    if (data?.typeId && !bill?.type) setBill(data);
    if (data?.id > 0) {
      setFile({ ...data?.file, deleted: false, filesToUpload: [] });
      setSearch(data);
    }
  }, [data]);

  const onSaveFile = d => {
    setFile(ps => ({ ...ps, ...d }));
  };
  const onChangeVenderorInfo = e => {
    const { name, id, phone, stateId, city, address1, address2, zipCode, categoryId } = e;

    if (id) {
      setSearch(prevState => ({
        ...prevState,
        query: name,
        vendorId: id,
        vendorAddress1: address1,
        vendorAddress2: address2,
        vendorZipCode: zipCode,
        vendorStateId: stateId,
        vendorPhone: phone,
        vendorCity: city,
        results: []
      }));
    }
    setBill(prevState => ({ ...prevState, vendorTypeId: categoryId }));
  };
  const onSelect = op => onChangeVenderorInfo(op);
  const querySearchParams = q => {
    return { searchText: q.searchText, vendorCategoryId: q.vendorCategoryId };
  };
  const url = `${API.URL}/bill/getAllvendor`;
  const axiosConfig = { paramsSerializer: params => qs.stringify(params, { indices: false }) };

  const { validationMessages, handleValidateSchema } = useYupValidationSchema(validationSchema);
  const handleValidation = () => {
    return handleValidateSchema({ ...bill, name: search.query, vendorId: search.vendorId });
  };
  const handleInputChange = ({ target: { value } }) => {
    setSearch(prevState => ({ ...prevState, query: value, ...iniVendor }));
  };
  const inputProps = path => {
    const [name] = path.split(".");
    return {
      name: path,
      value: bill[name],
      error: (validationMessages || {})[name],
      onChange: ({ target: { value } }) => {
        setBill(ps => ({ ...ps, [name]: value }));
        if (name === "vendorTypeId") {
          handleInputChange({ target: { value: "" } });
        }
      }
    };
  };
  const inputPropsSearch = path => {
    const [name] = path.split(".");
    const { st, error, action } = (() => {
      return { st: search, action: setSearch };
    })();
    return {
      name: path,
      value: name === "name" ? st.query : st[name],
      error: (validationMessages || {})[`${error || ""}${name}`],
      onChange: ({ target: { value } }) => action(ps => ({ ...ps, ...iniVendor, query: value }))
    };
  };
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (bill.typeId) {
      if (loaded) handleValidation();
      else setLoaded(true);
    }
  }, [bill]);

  const save = event => {
    event.preventDefault();
    const { isValid } = handleValidation();
    if (isValid) {
      const { filesToUpload: FileList } = file;
      let b = {
        typeId: bill.typeId,
        serviceDate: bill.serviceDate,
        amount: bill.amount,
        files: FileList || [],
        description: bill.description || ""
      };

      if (search.vendorId) b = { ...b, vendorId: search.vendorId };
      if (bill?.serviceThrough) b = { ...b, serviceThrough: bill.serviceThrough };
      if (bill?.vendorContactId) b = { ...b, vendorContactId: bill.vendorContactId };
      if (file?.deleted && file?.id) b = { ...b, deleteFileId: file?.id };
      if (bill?.id) b = { ...b, id: bill.id };
      if (bill?.amountDiscount) b = { ...b, amountDiscount: bill.amountDiscount };
      onSave(b);
    }
  };
  const [optionIndexing, setOptionIndexing] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const onChange = ({ target: { value } }) => setBill(ps => ({ ...ps, reindexTypeId: value }));
  const getTextConfirm = type => {
    if (type === "message")
      switch (optionIndexing?.id) {
        case 1: // Return to Indexing
          return (
            <>
              <p>Please select a reason for returning the Bill to Indexing:</p>
              <BaseSelectInput
                label="Reason"
                name="reindexTypeId"
                value={bill.reindexTypeId}
                onChange={onChange}
                options={reindexReasons.filter(({ id }) => ![4, 5].some(i => i === id))}
              />
            </>
          );
        case 2: // Duplicate
          return "This Bill will be marked as duplicated and the Bill status will be changed to 'Voided'. Are you sure you want to proceed?";
        case 3: // Delete
          return "This Bill will be permanently deleted. Are you sure you want to proceed?";
        default:
          return "";
      }
    if (type === "title")
      switch (optionIndexing?.id) {
        case 1:
          return "Return to Indexing?";
        case 2:
          return "Duplicated";
        case 3:
          return "Delete";
        default:
          return "";
      }
    if (type === "ok")
      switch (optionIndexing?.id) {
        case 1:
        case 2:
          return "Yes";
        case 3:
          return "Yes, Delete";
        default:
          return "";
      }
    return "";
  };

  const confirmProps = {
    messageText: getTextConfirm("message"),
    title: getTextConfirm("title"),
    onAction: () => {
      switch (optionIndexing?.id) {
        case 1:
          onReindex(bill);
          break;
        case 2:
          onDuplicate(bill);
          break;
        case 3:
          onDelete(bill);
          break;
        default:
      }
      confirmProps.onToggleConfirm();
    },
    openDialog,
    onToggleConfirm: () => {
      setOpenDialog(ps => !ps);
    },
    okText: getTextConfirm("ok")
  };

  const btns = ["cancel", "rooting", "save"].reduce((acc, item) => {
    if (item === "cancel") return [...acc, footerAction({ id: "cancel", text: "Cancel", outlined: true, primary: true, onClick: onCancel })];
    if (item === "rooting" && bill.id && bill.indexingId)
      return [
        ...acc,
        footerAction({
          id: "indexing",
          isBtnMenu: true,
          menuName: "Routing Options",
          primary: false,
          outlined: true,
          subMenues: [
            { id: 1, text: "Return to Indexing" },
            { id: 2, text: "Mark as Duplicate" },
            { id: 3, text: "Delete" }
          ],
          menuId: "schedulePaymentTypes",
          onClick: option => {
            setOptionIndexing(option);
            setOpenDialog(true);
          }
        })
      ];
    if (item === "save")
      return [...acc, footerAction({ id: "save", text: editMode ? "SAVE" : "ADD", primary: true, type: "submit", disabled: loading, onClick: save })];
    return acc;
  }, []);
  const canEdit = bill?.status === "On Hold" || !editMode;
  return (
    <>
      <div id="billContainer" className="tc-page-container">
        <div className="tc-page-content tc-no-padding">
          <Grid container wrap="nowrap" style={{ height: "100%" }}>
            <Grid item xs={6}>
              <File file={file} billId={data?.id} setFile={onSaveFile} canDelete={!data?.indexingId} />
            </Grid>
            <Grid item xs={8} className="tc-page-container tc-bg-white">
              <div className="tc-page-content">
                <Typography className="tc-title" gutterBottom>
                  Basic Info
                </Typography>
                {!editMode && (
                  <Grid container>
                    <Grid item xs={12}>
                      <TextInput label="Type" value={bill.type} disabled />
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={3}>
                  <Grid item xs>
                    {!!editMode && <TextInput label="Type" value={bill.type} disabled />}
                    <DateTimeInput {...inputProps("serviceDate")} label="Date of service" keyboard disabled={!canEdit} />
                    <MoneyInput {...inputProps("amount")} label="Bill Amount" disabled={!canEdit} />
                  </Grid>
                  <Grid item xs>
                    {!!editMode && <TextInput name="status" label="Status" value={bill.status} disabled />}
                    <DateTimeInput {...inputProps("serviceThrough")} label="Service Through" keyboard disabled={!canEdit} />
                    <MoneyInput {...inputProps("amountDiscount")} label="Amount Discount" disabled={!canEdit} />
                  </Grid>
                </Grid>
                <Typography className="tc-title tc-mt60px" gutterBottom>
                  Provider
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <SelectInput
                      {...inputProps("vendorTypeId")}
                      id="from-communication-editor"
                      label="Vendor Type"
                      options={vendorTypes}
                      disabled={!canEdit}
                    />
                    <Search
                      {...inputPropsSearch("name")}
                      label=" Name"
                      queryParams={querySearchParams({ searchText: search.query, vendorCategoryId: bill.vendorTypeId })}
                      axiosConfig={axiosConfig}
                      {...{ onSelect, url }}
                      disabled={!canEdit}
                    />
                    <TextInput name="vendorTaxId" label="Tax Id #" value={search.vendorTaxId} disabled />
                    <TextInput name="vendorAddress1" label="Address" value={search.vendorAddress1} disabled />
                    <TextInput name="vendorAddress2" label="Address2" value={search.vendorAddress2} disabled />
                  </Grid>
                  <Grid item xs>
                    <TextInput name="vendorCity" label="City" value={search.vendorCity} disabled />
                    <SelectInput name="vendorStateId" id="state" label="State" value={search.vendorStateId} options={states} disabled />
                    <TextInput name="vendorZipCode" label="Zip Code" value={search.vendorZipCode} disabled />
                    <PhoneInput name="vendorPhone" label="Phone" value={search.vendorPhone} disabled />
                    <SelectInput {...inputProps("vendorContactId")} label="Contacts" options={search.contacts} disabled={!canEdit} />
                  </Grid>
                </Grid>
                {editMode && !canEdit && bill.status === "Paid" && (
                  <>
                    <Typography className="tc-title tc-mt60px" gutterBottom>
                      Payment
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs>
                        <SelectInput
                          {...inputProps("paymentMethodId")}
                          id="from-paymentMethodId"
                          label="Payment Method"
                          options={paymentMethods}
                          disabled={!canEdit}
                        />
                        <IntegerInput {...inputProps("checkTransactionNumber")} label="Check/Transaction number" disabled={!canEdit} />
                      </Grid>
                      <Grid item xs>
                        <DateTimeInput {...inputProps("paymentDate")} label="Payment Date" keyboard disabled={!canEdit} />
                        <IntegerInput {...inputProps("checkTransactionAmount")} label="Check/Transaction amount" disabled={!canEdit} />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container>
                  <Grid item xs>
                    <TextInput {...inputProps("description")} label="Description" rows={4} disabled={!canEdit} />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <ActionsFooter
          shadow
          actions={canEdit ? btns : [footerAction({ id: "cancel", text: "Cancel", outlined: true, primary: true, onClick: onCancel })]}
        />
        <Confirm {...confirmProps} />
      </div>
    </>
  );
};

BillForm.propTypes = {
  data: PropTypes.shape({}),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  onReindex: PropTypes.func,
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.func
};

BillForm.defaultProps = {
  data: {},
  editMode: false,
  onReindex: undefined,
  onDuplicate: undefined,
  onDelete: undefined
};
export default BillForm;
