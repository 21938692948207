import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUserAccess, useRouteParam } from "../../common";
import useAxios from "../../components/common/customHooks/useAxios";
import { getLevels } from "../../redux/actions/client/getLevels";

export const useClientWrapper = () => {
  const store = useSelector(
    ({ profile: { securityProfilesDetails }, client: { clientLevels: storeLevels } }) => ({ securityProfilesDetails, storeLevels }),
    shallowEqual
  );
  const { securityProfilesDetails, storeLevels } = store;
  const clientLevels = storeLevels.filter(item => item.isActive);
  const dispatch = useDispatch();
  const clientId = useRouteParam("clientId");
  const [clientProfile, setClientProfile] = useState({ basicInformation: { id: null } });
  const { sendRequest } = useAxios();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getLevels(clientId));
    sendRequest({
      request: { url: `/client/profile?id=${clientId}` },
      onSucces: data => setClientProfile(data)
    });
  }, []);

  const vPathName = pathname.split("/").reverse();
  const vurl = pathname.split("/");
  const levelActivated = vPathName.find(elem => clientLevels.some(l => l.pathRoute === elem));
  const levelIndex = clientLevels.filter(item => item.isActive).findIndex(client => client.pathRoute === levelActivated);
  const level = clientLevels[levelIndex];
  const nextLevel = clientLevels[levelIndex + 1];
  const confTabs = (level || { tabs: [] }).tabs
    .filter(data => data.routeName !== null && data.name !== "Areas")
    .map(tab => ({
      ...tab,
      disabled: tab.routeName === vurl[vurl.length - 1],
      level: level.pathRoute.toLowerCase(),
      levelId: level.levelId,
      nextLevelLink: (nextLevel?.pathRoute || "").toLowerCase() || null,
      nextLevelName: nextLevel?.levelName,
      nextLevelId: nextLevel?.levelId,
      levelIndex
    }));
  const anyLevel = vPathName.some(p => clientLevels.some(l => l.pathRoute === p));
  const currentPath = vurl
    .reduce((a, item, index, array) => {
      if (!Number.isInteger(parseInt(item, 0))) return [...a, item];
      const name = array[index - 1];
      if (!clientLevels.some(l => l.pathRoute === name)) return a;
      return [...a, `:${name}Id`];
    }, [])
    .join("/");

  const routesAllowed = confTabs.reduce((a, { routeName }) => {
    const access = { read: true, update: true, create: true };
    if (!securityProfilesDetails?.length || !clientProfile?.basicInformation?.id) return [...a, { ...access, routeName }];
    const states = clientProfile?.basicInformation.industryOperatingStates?.stateInsured;
    if (!Array.isArray(states) || !states.length) {
      const sAccess = getUserAccess(securityProfilesDetails, "CLIENT.".concat(routeName.toUpperCase()), clientId, null, null);
      return [...a, { ...sAccess, routeName }];
    }
    const route = routeName !== "list" ? routeName.split(" ")[0] : "sublevels";
    states.forEach(({ stateId }) => {
      const rule = getUserAccess(securityProfilesDetails, `CLIENT.${route.toUpperCase()}`, clientId, stateId, null);
      ["read", "create", "update"].forEach(p => {
        access[p] = access[p] && rule[p];
      });
    });
    return [...a, { ...access, routeName }];
  }, []);
  const hideSidebar = pathname.indexOf("overview") > -1;
  return { anyLevel, clientLevels, confTabs, currentPath, hideSidebar, pathname, routesAllowed };
};

export const useClient = () => {
  const [values, setValues] = useState({ padding: true });
  const actions = {
    handlePadding: useCallback(p => setValues(v => ({ ...v, padding: Boolean(p) })))
  };
  return { actions, values };
};
