import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Grid } from "@material-ui/core";
import BarChart from "../../components/common/financial/BarChart";
import PieChart from "../../components/common/financial/PieChart";
import { getReserves, get } from "../../redux/actions/occurrence";

const Financial = () => {
  const {
    params: { occurrenceId }
  } = useRouteMatch();
  const storeData = useSelector(store => store.occurrence, shallowEqual);
  const { reserves, occurrence } = storeData || {};
  const { amountReserved, totalPaid, outstandingReserve, claimReserves, reserveTypes } = reserves;
  const pieData = { amountReserved: amountReserved || 0, totalPaid: totalPaid || 0, outstandingReserve: outstandingReserve || 0 };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getReserves({ occurrenceId }));
    if (!occurrence?.id) {
      dispatch(get(occurrenceId));
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item lg={2} md={2} sm={3} xs={3}>
        <PieChart reserves={pieData} by="occurrence" />
      </Grid>
      <Grid item lg={10} md={10} sm={9} xs={9}>
        <BarChart reserves={claimReserves || []} type="claim" />
        <BarChart reserves={reserveTypes || []} type="type" />
      </Grid>
    </Grid>
  );
};

export default Financial;
