import React, { createContext, useReducer, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { API } from "../../../redux/actions/actionTypes";
import { api } from "../../../api/apiUtils";

export const BankingContext = createContext(null);

const initialState = {
  account: {
    id: 0,
    isNew: true,
    accountName: "",
    bankId: null,
    bankName: "",
    startingCheckNumber: "",
    accountTypeId: null,
    bankAddress: "",
    routingNumber: "",
    bankCity: "",
    fractionABANumber: "",
    accountNumber: "",
    bankStateId: null,
    accountStatus: true,
    disabled: false,
    relatedVendorId: null
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return { account: { ...state.account, [action.payload.name]: action.payload.value } };
    case "SET_ACCOUNT":
      return { account: action.account };
    case "SET_BANK":
      return {
        account: {
          ...state.account,
          bankId: action.bank.bankId,
          bankName: action.bank.bankName,
          bankCity: action.bank.bankCity,
          bankAddress: action.bank.bankAddress,
          bankStateId: action.bank.bankStateId
        }
      };
    default:
      return state;
  }
};

export const BankingContextProvider = ({ children, account, onSubmit }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    if (account.id > 0) dispatch({ type: "SET_ACCOUNT", account });
  }, [account]);

  const store = useSelector(({ common: { states, accountTypes } }) => ({ states, accountTypes }), shallowEqual);
  const { states, accountTypes } = store;

  const [locations, setLocations] = useState([]);
  const { entityId, type } = account || {};
  const callLocations = async () => {
    await api({ url: `${API.URL}/select/vendorlocations`, data: { parentId: entityId } })
      .then(setLocations)
      .catch();
  };

  useEffect(() => {
    if (type === "vendor") callLocations();
  }, [entityId]);

  const handleChange = ({ target }) => dispatch({ type: "SET_FIELD", payload: target });

  const handleSubmit = (e, ...other) => {
    e.preventDefault();
    onSubmit(state, ...other);
  };

  const values = { state, dispatch, states, handleChange, handleSubmit, accountTypes, locations };

  return <BankingContext.Provider value={values}>{children}</BankingContext.Provider>;
};

BankingContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  account: PropTypes.shape({ id: PropTypes.number }),
  onSubmit: PropTypes.func.isRequired
};

BankingContextProvider.defaultProps = { account: { id: 0 } };
