/*eslint-disable */
import React, { useState, useEffect } from "react";
import Confirm from "../Confirm";

const useCustomConfirm = (action, cancelButtonText, confirmButtonText, hideCancel) => {
  const initialValue = {
    openConfirm: false,
    data: null,
    title: "Cancel",
    message: null,
    confirmBtnText: confirmButtonText || "Yes, Delete",
    handleAction: null,
    showConfirm: false,
    actionConfirm: null
  };
  const [state, setState] = useState(initialValue);
  const [showConfirm, setShowConfirm] = useState(false);

  const { openConfirm, data, title, message, confirmBtnText, handleAction } = state;

  useEffect(() => {
    if (openConfirm && message) setShowConfirm(true);
    else setShowConfirm(false);
  }, [openConfirm, data, message]);
  const handleCustomConfirm = ({ oData, titleText, messageText, confirmText, onAction }) => {
    setState(prev => ({
      ...prev,
      openConfirm: !openConfirm,
      data: oData,
      title: titleText,
      message: messageText,
      confirmBtnText: confirmText || prev.confirmBtnText,
      handleAction: onAction || prev.handleAction
    }));
  };
  const confirmConfig = {
    title,
    messageText: message,
    onToggleConfirm: () => setState(initialValue),
    onAction: () => {
      if (handleAction) handleAction(Object.keys(data || {}).length > 0 ? data : null);
      else action(Object.keys(data || {}).length > 0 ? data : null);
      setState(initialValue);
    },
    openDialog: openConfirm,
    okText: confirmButtonText || confirmBtnText,
    noText: cancelButtonText,
    hideCancel
  };

  return {
    handleCustomConfirm,
    ConfirmComponent: () => (showConfirm && <Confirm {...confirmConfig} />) || <></>
  };
};

export default useCustomConfirm;
