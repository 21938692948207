import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100%"
  },
  container: {
    flexGrow: 1,
    overflowY: "auto",
    marginBottom: 62
  },
  content: {
    padding: theme.spacing(2.75)
  },
  root1: {
    padding: theme.spacing(2.75, 2.75, 0.75, 2.75),
    marginTop: theme.spacing(2.75)
  },
  buttonWrapper: {
    "& > *": {
      margin: theme.spacing(1)
    }
  }
}));
const buildRoot = (theme, top) => ({
  root: { padding: theme.spacing(2.75, 2.75, 0.75, 2.75), [`margin${top ? "Top" : "Bottom"}`]: theme.spacing(2.75) }
});
export const useInsuredStyles = makeStyles(theme => ({
  ...buildRoot(theme),
  pr1: { paddingRight: theme.spacing(1) },
  pl1: { paddingLeft: theme.spacing(1) },
  divider: { margin: theme.spacing(0, 0, 2, 0) },
  search: {
    padding: theme.spacing(1, 0),
    "& > div:not(:last-of-type)": {
      marginBottom: theme.spacing(1),
      position: "relative",
      "&:after": {
        position: "absolute",
        content: '""',
        width: "calc(100% - 32px)",
        bottom: theme.spacing(-0.5),
        right: theme.spacing(2),
        borderBottom: "solid 0.5px #898F91"
      }
    }
  },
  arrow: {
    minWidth: 24,
    marginRight: theme.spacing(1),
    "& > button > span > svg": {
      transform: "rotate(-180deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    }
  },
  open: { "& > button > span > svg": { transform: "rotate(0deg)" } }
}));
export const useEmployeeStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2.75, 2.75, 0.75, 2.75),
    marginTop: theme.spacing(2.75)
  },
  sameAsInsured: {
    height: 38,
    padding: "0 0 8px 0 !important",
    marginTop: -6,
    "& > label": { margin: "0 0 !important" }
  },
  head: { marginBottom: theme.spacing(1), alignItems: "center", flexWrap: "nowrap" },
  number: { width: theme.spacing(4), height: theme.spacing(4), color: "#FFFFFF", backgroundColor: "#004763" },
  pr1: { paddingRight: theme.spacing(1) },
  pl1: { paddingLeft: theme.spacing(1) }
}));
export const customRadioStyles = makeStyles(() => ({ label: { fontSize: 14 } }));
export const useClaimAddHeaderStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2, 20, 2, 5.5),
    backgroundColor: "#F4F8F9",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.4)",
    zIndex: 1
  },
  title: { color: "#004763", fontSize: 24, paddingRight: theme.spacing(10) },
  root: {
    padding: 0,
    backgroundColor: "transparent"
  },
  label: { marginTop: "4px !important" },
  active: { color: "#004763 !important" },
  completed: { color: "#428302 !important" },
  icon: { color: "#107A98", fontSize: 20, padding: "0 12px 0 2px", lineHeight: 1, "& > svg": { height: "1em", width: "1em" } },
  count: { paddingLeft: 10, fontWeight: 500, fontSize: 16 },
  duplicateLink: {
    flexGrow: 1,
    alignSelf: "center",
    paddingLeft: "64px !important"
  },
  duplicateLinkViewer: {
    paddingLeft: "calc(33.333333% + 64px) !important"
  },
  number: {
    backgroundColor: "rgb(231, 76, 60) !important",
    borderRadius: "50%",
    color: "#fff",
    width: 18,
    height: 18,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 11,
    lineHeight: 1
  }
}));
