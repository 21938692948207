import { INITIALREPORT as REPORT } from "../actionTypes";

export const get = (id, onSuccess, onError) => ({ type: REPORT.CLAIM.GET, id, onSuccess, onError });

export const getSuccess = data => ({ type: REPORT.CLAIM.GET_SUCCESS, data });

export const set = (claim, onSuccess, onError) => ({ type: REPORT.CLAIM.SET, claim, onSuccess, onError });

export const setSuccess = claim => ({ type: REPORT.CLAIM.SET_SUCCESS, claim });

export const clear = () => ({ type: REPORT.CLEAR });

export const makeClaim = (id, onSuccess, onError) => ({ type: REPORT.MAKE_CLAIM, id, onSuccess, onError });

export const makeClaimSuccess = () => ({ type: REPORT.MAKE_CLAIM_SUCCESS });
