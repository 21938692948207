import { Grid, Tab } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { Fragment, lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useRouteMatch, Route } from "react-router-dom";
import { useLoadingContext } from "../../common/Loading";
import SimpleTabs from "../../common/tabs/SimpleTabs";

const Profile = lazy(() => import("../common/profile"));
const BreadCrumbsNavigation = lazy(() => import("../common/BreadCrumbsNavigation"));
const List = lazy(() => import("../common/list"));
const Notes = lazy(() => import("../common/notes"));
const Documents = lazy(() => import("../common/Documents"));
const Contact = lazy(() => import("../common/contact"));
const PolicyList = lazy(() => import("./PolicyList"));
const InsuredPolicy = lazy(() => import("../insured/policies"));
const Banking = lazy(() => import("../../common/banking"));
const Overview = lazy(() => import("../common/overview"));
const Settings = lazy(() => import("../common/settings"));
const PageNotAllowed = lazy(() => import("../../PageNotAllowed"));
const ClientSidebar = lazy(() => import("../sidebar"));
const Employees = lazy(() => import("../insured/employees"));

const parse = (txt, confTabs) =>
  txt
    .split("/")
    .filter(item => !confTabs.some(t => t.routeName.toLowerCase() === item.toLowerCase()) && item.toLowerCase() !== "add")
    .join("/");

const DinamicTabs = ({ confTabs, clientStructure, routesAllowed }) => {
  const dispatch = useDispatch();
  const { padding, Loading } = useLoadingContext();
  useEffect(() => {
    return () => dispatch({ type: "CLIENT.RESET_STATE" });
  }, []);
  const { url, path } = useRouteMatch();
  const baseUrl = parse(url, confTabs);
  const basePathUrl = parse(path, confTabs);

  const urlValue = (() => {
    if (url.includes("add")) return url.replace("/add", "");
    if (url === basePathUrl) return `${baseUrl}/profile`;
    if (url.includes("/policies/")) return `${baseUrl}/policies`;
    return url;
  })();
  const getClientSection = (item, access) => {
    switch (item.componentName) {
      case "profile":
        return <Profile level={item.level} levelIndex={item.levelIndex} levelId={item.levelId} actionsAllowed={access} />;
      case "list":
        return (
          <List
            level={item.level}
            nextLevel={item.nextLevelLink}
            levelIndex={item.levelIndex}
            mainUrl={baseUrl}
            nextLevelName={item.nextLevelName}
            nextLevelId={item.nextLevelId}
            routesAllowed={routesAllowed}
          />
        );
      case "banking":
        return <Banking type="client" level={item.level} actionsAllowed={access} />;
      case "notes":
        return <Notes level={item.level} actionsAllowed={access} />;
      case "documents":
        return <Documents level={item.level} actionsAllowed={access} />;
      case "contacts":
        return <Contact level={item.level} actionsAllowed={access} />;
      case "policies":
        return <PolicyList level={item.level} />;
      case "policies management":
        return <InsuredPolicy level={item.level} actionsAllowed={access} />;
      case "employees":
        return <Employees level={item.level} actionsAllowed={access} />;
      case "overview":
        return <Overview level={item.level} />;
      case "settings":
        return <Settings level={item.level} clientStructure={clientStructure} actionsAllowed={access} />;
      default:
        return <></>;
    }
  };
  return (
    <Fragment key="Client-Tabs">
      <SimpleTabs value={urlValue}>
        {confTabs
          .filter(elem => routesAllowed.some(item => item.routeName === elem.routeName && item.read))
          .map(item => {
            const gotoUrl = `${baseUrl}/${item.componentName !== "list" ? item.routeName : item.componentName}`;
            return <Tab key={item.routeName} label={item.name} component={Link} to={gotoUrl} value={gotoUrl} disabled={item.disabled} />;
          })}
      </SimpleTabs>
      {Loading}
      <div className={clsx("tc-page-content", { "tc-no-padding": !padding })}>
        <Suspense fallback={<></>}>
          <Grid container wrap="nowrap">
            <Grid item xs>
              <BreadCrumbsNavigation />
            </Grid>
            <Grid item id="clientTopContainer" />
          </Grid>
          {confTabs.map(item => {
            if (!item.routeName) return <></>;
            const pathRoute = item.componentName !== "list" ? item.routeName : item.componentName;
            const access = routesAllowed.find(e => e.routeName === item.routeName.split(" ")[0]) || {};
            return (
              <Route key={item.routeName} path={`${basePathUrl}/${pathRoute}`}>
                {access.read ? getClientSection(item, access) : <PageNotAllowed />}
                <ClientSidebar level={item.level} levelId={item.levelId} />
              </Route>
            );
          })}
        </Suspense>
      </div>
      <div id="clientFooter" />
    </Fragment>
  );
};

DinamicTabs.propTypes = {
  confTabs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  clientStructure: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  routesAllowed: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

DinamicTabs.defaultProps = {};
export default DinamicTabs;
