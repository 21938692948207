import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import { Paper, Grid, Avatar, SvgIcon, Typography } from "@material-ui/core";
import { Description, DirectionsCar, Gavel, Home, Person, Business, Link as LinkIcon, Room } from "@material-ui/icons";
import moment from "moment";
import clsx from "clsx";
import { useContext } from "../context";
import CreateAsTeam from "./CreateAsTeam";
import Timeline from "../../../common/Timeline";
import BodilyInjury from "../../../../assets/bodily_injury.svg";

const Detail = ({ classes, claim, number }) => {
  const [
    {
      insured,
      incident: { dateofLoss, dateReported, occurrences, occurrenceId, stateId },
      documents,
      attorneys
    }
  ] = useContext();
  const {
    claimLossTypes: { data: claimLossTypes },
    gender,
    states
  } = useSelector(({ common }) => common, shallowEqual);
  const type = (_id, list) => {
    if (!list.some(({ id }) => id === _id)) return null;
    return list.filter(({ id }) => id === _id)[0].text;
  };
  const state = type(stateId, states);
  const timeline = [
    { id: 1, label: "Date of Loss", date: dateofLoss },
    { id: 2, label: "Date Reported", date: dateReported }
  ];
  const occurrence = { text: "", count: null };
  if (occurrenceId === -1) occurrence.text = "Create New Occurrence";
  else if (occurrenceId === -2 || !occurrences.some(o => o.id === occurrenceId)) occurrence.text = "No Occurrence";
  else {
    const occ = occurrences.filter(o => o.id === occurrenceId)[0];
    occurrence.text = occ.number;
    occurrence.count = occ.countClaims;
  }
  const { sameAsInsured, isPerson, firstName, middleName, lastName, commercialName, dateofBirth, genderId } = claim.claimant;
  const insuredAddress = (() => {
    let value = "";
    if (insured?.city) {
      value = insured?.city.trim();
      if (insured?.stateCode || insured?.zipCode) value = `${value}, `;
    }
    if (insured?.stateCode) value = `${value}${insured?.stateCode.trim()}${insured?.zipCode ? " " : ""}`;
    value = `${value}${(insured?.zipCode || "").trim()}`;
    if (value) return <div>{value}</div>;
    return null;
  })();
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item>
          <Avatar className={classes.number}>{number}</Avatar>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item>
              <span className={classes.coverageType}>{claim.coverageType}</span>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  {claim.text === "Vehicle Damage" && <DirectionsCar />}
                  {claim.text === "Property Damage" && <Home />}
                  {claim.text === "Bodily Injury" && (
                    <SvgIcon>
                      <BodilyInjury />
                    </SvgIcon>
                  )}
                </Grid>
                <Grid item>{`${claim.text}: ${type(claim.lossTypeId, claimLossTypes)}`}</Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={4}>
              <Typography className={clsx("tc-title", classes.title)}>Claimant</Typography>
              <Grid container spacing={1} className={classes.nameContainer}>
                <Grid item>{isPerson && !sameAsInsured ? <Person /> : <Business />}</Grid>
                <Grid item className={classes.name}>
                  {sameAsInsured ? insured?.name : null}
                  {!sameAsInsured && isPerson ? `${firstName}${middleName ? ` ${middleName}` : ""} ${lastName}` : null}
                  {!sameAsInsured && !isPerson ? commercialName : null}
                </Grid>
              </Grid>
              {isPerson && !sameAsInsured && (
                <Grid container spacing={2} className={classes.details}>
                  <Grid item>
                    <div className={classes.subtitle}>DOB</div>
                    {(dateofBirth && moment(dateofBirth).format("M/D/YYYY")) || "-"}
                  </Grid>
                  <Grid item>
                    <div className={classes.subtitle}>Age</div>
                    {dateofBirth ? moment().diff(moment(dateofBirth), "years") : "-"}
                  </Grid>
                  <Grid item>
                    <div className={classes.subtitle}>Gender</div>
                    {(genderId && type(genderId, gender)) || "-"}
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography className={clsx("tc-title", classes.title)}>Insured</Typography>
              <div>{insured?.name}</div>
              {insured?.address1 && <div>{insured?.address1}</div>}
              {insuredAddress}
            </Grid>
            <Grid item xs={4}>
              {state && (
                <Grid container spacing={1} className={classes.location}>
                  <Grid item>
                    <Room />
                  </Grid>
                  <Grid item>{state}</Grid>
                </Grid>
              )}
              <Timeline {...{ timeline }} />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={4}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item>
                  <div className={classes.subtitle}>Occurrence</div>
                  {occurrence.text}
                </Grid>
                {occurrence.count !== null && (
                  <Grid item>
                    <LinkIcon viewBox="0 7 24 12" className={classes.link} />
                    <span className={classes.count}>{occurrence.count}</span>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1} className={classes.docsAttorney}>
                <Grid item>
                  <Description />
                </Grid>
                <Grid item>{`Documents: ${documents.length}`}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: 24 }}>
              <Grid container spacing={1} className={classes.docsAttorney}>
                <Grid item>
                  <Gavel />
                </Grid>
                <Grid item>{`Claimant Attorney: ${attorneys.length > 0 ? "YES" : "NO"}`}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <CreateAsTeam {...{ classes, claim }} />
      </Grid>
    </Paper>
  );
};

Detail.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  claim: PropTypes.objectOf(PropTypes.any).isRequired,
  number: PropTypes.number.isRequired
};

export default Detail;
