import clsx from "clsx";
import React, { useReducer, useCallback, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import BasicInformationBox from "./BasicInformation";
import AgentInformationBox from "./AgentInformation";
import ClientStructureWrapper from "./ClientStructureWrapper";
import Status from "./Status";
import Industry from "./Industry";
import { initialState, reducer } from "./reducer";
import styles from "../common/list/index.style";
import { addClient } from "../../../redux/actions/client/add";
import useCustomConfirm from "../../common/customHooks/useCustomConfirm";
import ActionsFooter, { footerAction } from "../../common/ActionsFooter";
import { useLoading, useLoadingContext } from "../../common/Loading";

const ClientAdd = ({ showHeaderTitle, actionSave, parentId, nextLevel, levelIndex, nextLevelId, clientLevelAdd, parentUrl }) => {
  const { clientTypes } = useSelector(store => store.common);
  const [state, dispatch] = useReducer(reducer, initialState);
  const loadingCtx = useLoadingContext();
  const loadingHook = !loadingCtx ? useLoading(true) : undefined;
  const {
    Loading,
    loading,
    actions: { showLoading, hideLoading }
  } = loadingCtx ? { ...loadingCtx, Loading: null } : loadingHook;
  const [clientLevelSetting, setClientLevelSetting] = useState([]);
  const [goToClients, setGoToClients] = useState(false);
  const history = useHistory();
  const dispatchAction = useDispatch();
  const { url } = useRouteMatch();
  const classes = styles();
  const config = { position: "static", color: "inherit" };

  const onError = () => {
    if (clientLevelAdd) toast.error(`Unable to add the ${nextLevel}`);
    return hideLoading();
  };
  const transitionToClients = () => {
    if (clientLevelAdd) {
      toast.success(`${nextLevel} successfully added`);
      return history.push(parentUrl);
    }
    return setGoToClients(prevState => !prevState);
  };

  const handleCancel = () => setGoToClients(prevState => !prevState);

  const showAgentInformation = clientLevelAdd ? levelIndex < 1 : true;

  const handleDispatch = useCallback(obj => dispatch(obj), []);

  const handleSave = objData => {
    showLoading();
    const clientState = {
      codes: objData.codes,
      clientStatus: objData.clientStatus ? { ...objData.clientStatus, isActive: objData.clientStatus.isActive === 1 } : {},
      basicInformation: {
        ...objData.basicInformation,
        location: {
          address1: objData.basicInformation.address1,
          city: objData.basicInformation.city,
          stateId: parseInt(objData.basicInformation.stateId, 0),
          zipCode: objData.basicInformation.zip
        },
        mailingLocation: {
          address1: objData.basicInformation.mailingaddress1,
          city: objData.basicInformation.mailingcity,
          stateId: parseInt(objData.basicInformation.mailingstateId, 0),
          zipCode: objData.basicInformation.mailingzip
        }
      },
      operatingStates: objData.operatingStates,
      agentInformation: showAgentInformation
        ? {
            agentName: objData.agentInformation.agentName,
            agentPhone: objData.agentInformation.agentPhone,
            agentLocation: {
              address1: objData.agentInformation.agentAddress1,
              city: objData.agentInformation.agentCity,
              stateId: parseInt(objData.agentInformation.agentStateId, 0),
              zipCode: objData.agentInformation.agentZip
            },
            saveAgent: true
          }
        : {},
      clientStructure: objData.clientLevelSetting
    };

    if (clientLevelAdd) {
      setTimeout(() => {
        dispatchAction(
          actionSave
            ? actionSave({
                data: {
                  ...clientState,
                  parentId
                },
                onSuccess: transitionToClients,
                onError
              })
            : addClient({
                data: {
                  ...clientState,
                  parentId
                },
                onSuccess: hideLoading
              })
        );
      }, 100);
    } else {
      dispatchAction(addClient({ ...clientState, clientStructure: objData.clientLevelSetting }, transitionToClients, onError));
    }
  };

  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleSave, "Cancel", "Yes, Create");

  const handleConfirm = data => {
    const { name } = data.basicInformation || {};
    const clientType = (data.basicInformation.typeId && clientTypes.filter(item => item.id === data.basicInformation.typeId)[0]?.text) || "PEO";
    handleCustomConfirm({
      oData: { ...data, clientLevelSetting, codes: state.codes, operatingStates: state.operatingStates },
      titleText: `New Client`,
      messageText: `The following client will be created: ${name || ""} - ${clientType || ""}. Are you sure you want to proceed?`,
      confirmText: "Yes, Create"
    });
  };
  const isNullOrEmtpy = s => s === null || s === undefined || s === "";
  return (
    <Formik
      initialValues={{ ...initialState }}
      validationSchema={Yup.object().shape({
        basicInformation: Yup.object().shape({
          name: Yup.string().required("Name is a required field"),
          typeId: Yup.string().required("Client Type is a required field"),
          sizeTypeId: Yup.string().required("Client Size is a required field"),
          email: Yup.string().email("Invalid Email")
        }),
        clientStatus: Yup.object().shape({
          isActive: Yup.number()
            .nullable()
            .test("default", "Status Required", val => [1, 2].some(x => x === val))
        })
      })}
      onSubmit={handleConfirm}
    >
      {({ handleSubmit }) => (
        <div className={clsx({ "tc-page-container": !clientLevelAdd })}>
          {goToClients && (
            <Redirect
              to={
                !clientLevelAdd
                  ? "/clients"
                  : url
                      .split("/")
                      .filter(e => e !== "add")
                      .join("/")
              }
            />
          )}

          {showHeaderTitle && (
            <AppBar {...config} className={classes.appNavBarHeader}>
              <Toolbar>
                <Typography variant="h6">ADD CLIENT</Typography>
              </Toolbar>
            </AppBar>
          )}
          {Loading}
          <div className={clsx({ "tc-page-content": !clientLevelAdd })}>
            <BasicInformationBox {...{ nextLevelId }} />

            <Industry codes={state.codes} dispatch={handleDispatch} operatingStates={state.operatingStates} {...{ levelIndex, nextLevelId }} />

            <Status />

            {!clientLevelAdd && showAgentInformation && <AgentInformationBox />}

            {!clientLevelAdd && <ClientStructureWrapper {...{ setClientLevelSetting }} />}
          </div>
          <ActionsFooter
            shadow
            actions={[
              footerAction({ id: "cancel", primary: true, outlined: true, onClick: handleCancel, text: "Cancel" }),
              footerAction({
                id: "save",
                primary: true,
                onClick: handleSubmit,
                text: `Add ${(!isNullOrEmtpy(levelIndex) && nextLevel) || "Client"}`,
                disabled: loading
              })
            ]}
            elementId={clientLevelAdd ? "clientFooter" : undefined}
          />
          <Confirm />
        </div>
      )}
    </Formik>
  );
};

ClientAdd.propTypes = {
  level: PropTypes.string,
  showHeaderTitle: PropTypes.bool,
  actionSave: PropTypes.func,
  parentId: PropTypes.number,
  nextLevel: PropTypes.string,
  levelIndex: PropTypes.number,
  nextLevelId: PropTypes.number,
  clientLevelAdd: PropTypes.bool,
  parentUrl: PropTypes.string
};

ClientAdd.defaultProps = {
  level: "client",
  nextLevel: null,
  parentId: null,
  actionSave: null,
  levelIndex: null,
  nextLevelId: null,
  showHeaderTitle: true,
  clientLevelAdd: false,
  parentUrl: null
};

export default ClientAdd;
