import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Grid, Divider, Paper, Typography } from "@material-ui/core";
import { useContext, actionTypes } from "../context";
import { DateTimeInput, TextInput, SelectInput, PhoneInput } from "../../../common/inputs";
import { useInsuredVehicleStyles } from "./index.styles";

export default () => {
  const classes = useInsuredVehicleStyles();
  const [
    {
      insVehicle,
      insVehicles: { data },
      insVehicle: {
        driver,
        driver: { location }
      }
    },
    ,
    handleChange,
    {
      step1: { validationMessages },
      handleValidation
    }
  ] = useContext();
  const [, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(loaded => {
      if (loaded) handleValidation();
      return true;
    });
  }, [insVehicle]);
  const { states } = useSelector(({ common }) => common, shallowEqual);
  const onChange = e => handleChange(e, actionTypes.SET_INS_VEHICLE);
  const onSelect = e => handleChange(e, actionTypes.SELECT_INS_VEHICLE);
  const onDriverChange = e => handleChange(e, actionTypes.SET_INS_VEHICLE_DRIVER);
  const onLocChange = e => handleChange(e, actionTypes.SET_INS_VEHICLE_DRIVER_LOCATION);
  const selectProps = (name, label, error = false) => ({
    name,
    label,
    value: insVehicle.id,
    onChange: onSelect,
    error: error ? (validationMessages || {})["insVehicle.id"] : undefined,
    options: data.map(v => ({ id: v.id, text: v[name] }))
  });
  const driverProps = (name, label, loc = false) => ({
    name,
    label,
    onChange: loc ? onLocChange : onDriverChange,
    value: (loc ? location : driver)[name],
    error: (validationMessages || {})[`insVehicle.driver${loc ? ".location" : ""}.${name}`]
  });
  return (
    <Paper className={classes.root}>
      <Typography className="tc-title" gutterBottom>
        Insured Vehicle
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs>
          <SelectInput {...selectProps("number", "Vehicle #", true)} />
          <SelectInput {...selectProps("vehicleYear", "Vehicle Year")} />
          <SelectInput {...selectProps("vehicleMake", "Vehicle Make")} />
        </Grid>
        <Grid item xs>
          <SelectInput {...selectProps("model", "Vehicle Model")} />
          <SelectInput {...selectProps("vehicleBody", "Vehicle Body Type")} />
          <SelectInput {...selectProps("vin", "V.I.N. #")} />
        </Grid>
        <Grid item xs>
          <SelectInput {...selectProps("plateNumber", "Vehicle Plate Number")} />
          <TextInput name="vehicleState" label="Vehicle State" value={insVehicle.vehicleState} disabled />
        </Grid>
      </Grid>
      <Divider className={classes.marginDivider} />
      <Grid container spacing={4}>
        <Grid item xs>
          <TextInput {...driverProps("firstName", "Driver's First Name *")} />
          <TextInput {...driverProps("lastName", "Driver's Last Name *")} />
          <DateTimeInput {...driverProps("dateOfBirth", "Driver's Date of Birth")} keyboard />
        </Grid>
        <Grid item xs>
          <TextInput {...driverProps("address1", "Driver's Address *", true)} />
          <TextInput {...driverProps("city", "Driver's City *", true)} />
          <Grid container>
            <Grid item xs className={classes.pr1}>
              <SelectInput {...driverProps("stateId", "Driver's State *", true)} options={states} />
            </Grid>
            <Grid item xs className={classes.pl1}>
              <TextInput {...driverProps("zipCode", "Driver's Zip *", true)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <PhoneInput {...driverProps("phone", "Driver's Phone Number")} />
          <TextInput {...driverProps("email", "Driver's Email")} />
          <TextInput {...driverProps("licenseNumber", "Driver's License Number")} />
        </Grid>
      </Grid>
      <Divider className={classes.marginDivider} />
      <TextInput name="damageDescription" label="Damage Description" value={insVehicle.damageDescription} rows={4} onChange={onChange} />
    </Paper>
  );
};
