import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import { Divider, Grid, Collapse, Button } from "@material-ui/core";
import { CheckboxLabel, DateTimeInput, PhoneInput, SelectInput, TextInput, IntegerInput } from "../../inputs";
import useStyles from "./Style";
import { API } from "../../../../redux/actions/actionTypes";
import { api } from "../../../../api/apiUtils";
import validationSchema from "./yup";
import useYupValidationSchema from "../../customHooks/useYupValidationSchema";

const DamagedVehicleForm = ({ damagedVehicle, onClose, onSave, isLoading }) => {
  const classes = useStyles();
  const { states, vehicleBodyType, vehicleMake, vehicleDamageType, codeType, insurers } = useSelector(({ common }) => common, shallowEqual);
  const [state, setState] = useState(damagedVehicle || {});
  const [vehicle, setVehicle] = useState(state.vehicle || {});
  const [driver, setDriver] = useState(state.driver?.id > 0 ? state.driver : { licenseNumber: "" });
  const [driverLocation, setDriverLocation] = useState(driver.location || {});
  const [vehicleOwner, setVehicleOwner] = useState(state.vehicleOwner || {});
  const [vehicleOwnerLocation, setVehicleOwnerLocation] = useState(vehicleOwner.location || {});
  const [lienHolder, setLienHolder] = useState(state.lienholder || {});
  const [lienHolderLocation, setLienHolderLocation] = useState(lienHolder.location || {});
  const [naicsCodes, setNaicsCodes] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { validationMessages, handleValidateSchema } = useYupValidationSchema(validationSchema);
  const handleValidation = () =>
    handleValidateSchema({
      ...state,
      vehicle,
      driver: { ...driver, location: driverLocation },
      vehicleOwner: { ...vehicleOwner, location: vehicleOwnerLocation },
      lienHolder: { ...lienHolder, location: lienHolderLocation }
    });
  useEffect(() => {
    if (loaded) handleValidation();
    else setLoaded(true);
  }, [state, vehicle, driver, driverLocation, vehicleOwner, vehicleOwnerLocation, lienHolder, lienHolderLocation]);
  const onChangeVehicleDriver = ({ target: { name, value } }) => {
    setState(prevState => ({
      ...prevState,
      [name]: value === 1
    }));
  };
  const onChangeLienHolderCheck = ({ target: { name, value } }) => {
    setState(prevState => ({
      ...prevState,
      [name]: value,
      isLienholderClaimant: !value ? false : state.isLienholderClaimant
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    const { isValid } = handleValidation();
    if (isValid)
      onSave({
        ...state,
        vehicle,
        driver: { ...driver, location: driverLocation },
        lienholder: { ...lienHolder, location: lienHolderLocation },
        vehicleOwner: { ...vehicleOwner, location: vehicleOwnerLocation }
      });
  };
  useEffect(() => {
    if (codeType.length > 0 && naicsCodes.length === 0) {
      const codeTypeId = codeType.find(elem => elem.text === "NAICS").id;
      api({ url: `${API.URL}/select/codes`, data: { codeTypeId, codesList: [] }, onSuccess: ({ data }) => setNaicsCodes(data) });
    }
  }, [codeType]);
  const inputProps = path => {
    const [entName, name] = path.split(".");
    const { st, error, action } = (() => {
      switch (entName) {
        case "driver":
          return { st: driver, error: "driver.", action: setDriver };
        case "driverLocation":
          return { st: driverLocation, error: "driver.location.", action: setDriverLocation };
        case "vehicleOwner":
          return { st: vehicleOwner, error: "vehicleOwner.", action: setVehicleOwner };
        case "vehicleOwnerLocation":
          return { st: vehicleOwnerLocation, error: "vehicleOwner.location.", action: setVehicleOwnerLocation };
        case "vehicle":
          return { st: vehicle, error: "vehicle.", action: setVehicle };
        case "lienHolder":
          return { st: lienHolder, error: "lienHolder.", action: setLienHolder };
        case "lienHolderLocation":
          return { st: lienHolderLocation, error: "lienHolder.location.", action: setLienHolderLocation };
        default:
          return { st: state, action: setState };
      }
    })();
    return {
      name: path,
      [name === "isLienholderClaimant" ? "checked" : "value"]: (st || {})[name] || undefined,
      error: (validationMessages || {})[`${error || ""}${name}`],
      onChange: ({ target: { value } }) => action(ps => ({ ...ps, [name]: value }))
    };
  };
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <CheckboxLabel label="Lienholder" name="hasLienholder" checked={state.hasLienholder} onChange={onChangeLienHolderCheck} />
        </Grid>
        <Grid item xs={4}>
          <Collapse in={state.hasLienholder} timeout="auto">
            <CheckboxLabel label="Lienholder same as Claimant" {...inputProps("state.isLienholderClaimant")} />
          </Collapse>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <SelectInput
            name="isDriverOwner"
            label="Driver"
            value={state.isDriverOwner === true ? 1 : 0}
            options={[
              { id: 0, text: "Different than Owner" },
              { id: 1, text: "Owner" }
            ]}
            onChange={onChangeVehicleDriver}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput {...inputProps("driver.licenseNumber")} label="Driver’s License Number *" />
        </Grid>
      </Grid>
      <Collapse in={!state.isDriverOwner} timeout="auto">
        <Grid container spacing={4}>
          <Grid item xs>
            <TextInput {...inputProps("driver.firstName")} label="Driver’s First Name *" />
            <TextInput {...inputProps("driver.lastName")} label="Driver’s Last Name *" />
            <DateTimeInput {...inputProps("driver.dateOfBirth")} label="Driver’s Date of Birth" keyboard />
          </Grid>
          <Grid item xs>
            <TextInput {...inputProps("driverLocation.address1")} label="Driver’s Address *" />
            <TextInput {...inputProps("driverLocation.city")} label="Driver’s City *" />
            <Grid container spacing={4}>
              <Grid item xs>
                <SelectInput {...inputProps("driverLocation.stateId")} label="Driver’s State *" options={states} />
              </Grid>
              <Grid item xs>
                <TextInput {...inputProps("driverLocation.zipCode")} label="Driver’s Zip *" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <PhoneInput {...inputProps("driver.phone")} label="Driver’s Phone Number" />
            <TextInput {...inputProps("driver.email")} label="Driver’s Email" />
          </Grid>
        </Grid>
      </Collapse>
      <Divider className={classes.marginDevider} />
      <Collapse in={state.hasLienholder && !state.isDriverOwner && state.isLienholderClaimant} timeout="auto">
        <Grid container spacing={4}>
          <Grid item xs>
            <TextInput {...inputProps("vehicleOwner.firstName")} label="Owner’s First Name *" />
            <TextInput {...inputProps("vehicleOwner.lastName")} label="Owner’s Last Name *" />
            <DateTimeInput {...inputProps("vehicleOwner.dateOfBirth")} label="Owner’s Date of Birth" keyboard />
          </Grid>
          <Grid item xs>
            <TextInput {...inputProps("vehicleOwnerLocation.address1")} label="Owner’s Address 1 *" />
            <TextInput {...inputProps("vehicleOwnerLocation.city")} label="Owner’s City *" />
            <Grid container spacing={4}>
              <Grid item xs>
                <SelectInput {...inputProps("vehicleOwnerLocation.stateId")} label="Owner’s State *" options={states} />
              </Grid>
              <Grid item xs>
                <TextInput {...inputProps("vehicleOwnerLocation.zipCode")} label="Owner’s Zip *" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <TextInput {...inputProps("vehicleOwnerLocation.address2")} label="Owner’s Address 2" />
            <PhoneInput {...inputProps("vehicleOwner.phone")} label="Owner’s Phone Number" />
            <TextInput {...inputProps("vehicleOwner.email")} label="Owner’s Email" />
          </Grid>
        </Grid>
        <Divider className={classes.marginDevider} />
      </Collapse>
      <Grid container spacing={5}>
        <Grid item xs>
          <IntegerInput {...inputProps("vehicle.number")} label="Vehicle *" />
          <IntegerInput {...inputProps("vehicle.vehicleYear")} label="Vehicle Year *" />
          <SelectInput {...inputProps("vehicle.vehicleMakeId")} label="Vehicle Make *" options={vehicleMake} />
        </Grid>
        <Grid item xs>
          <TextInput {...inputProps("vehicle.model")} label="Vehicle Model *" />
          <SelectInput {...inputProps("vehicle.vehicleBodyTypeId")} label="Vehicle Body Type *" options={vehicleBodyType} />
          <TextInput {...inputProps("vehicle.vin")} label="V.I.N # *" />
        </Grid>
        <Grid item xs>
          <TextInput {...inputProps("vehicle.plateNumber")} label="Vehicle Plate Number *" />
          <SelectInput {...inputProps("vehicle.vehicleStateId")} label="Vehicle State *" options={states} />
        </Grid>
      </Grid>
      <Divider className={classes.marginDevider} />
      <Grid container spacing={4}>
        <Grid item xs>
          <SelectInput {...inputProps("state.insuranceCarrierId")} label="Carrier or Agency Name" options={insurers} />
        </Grid>
        <Grid item xs>
          <SelectInput {...inputProps("state.naicCodeId")} label="NAIC Code" options={naicsCodes} />
        </Grid>
        <Grid item xs>
          <TextInput {...inputProps("state.policyNumber")} label="Policy Number" />
        </Grid>
      </Grid>
      <Divider className={classes.marginDevider} />
      <Grid container spacing={4}>
        <Grid item xs>
          <TextInput {...inputProps("state.damageDescription")} label="Damage Description" rows={4} />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs>
          <TextInput {...inputProps("state.whereIsDamage")} label="Where can Damage be seen?" />
        </Grid>
        <Grid item xs>
          <SelectInput {...inputProps("state.vehicleDamageTypeId")} label="Car Damage Type Reported" options={vehicleDamageType} />
        </Grid>
        <Grid item xs>
          <IntegerInput {...inputProps("state.estimateAmount")} label="Estimate Amount Reported" />
        </Grid>
      </Grid>
      <Divider className={classes.marginDevider} />
      <Collapse in={state.hasLienholder && !state.isLienholderClaimant} timeout="auto">
        <Grid container spacing={4}>
          <Grid item xs>
            <TextInput {...inputProps("lienHolder.name")} label="LienHolder Name *" />
            <TextInput {...inputProps("lienHolderLocation.address1")} label="LienHolder Address 1 *" />
            <TextInput {...inputProps("lienHolderLocation.address2")} label="LienHolder Address 2" />
          </Grid>
          <Grid item xs>
            <TextInput {...inputProps("lienHolderLocation.city")} label="Lien Holder City *" />
            <SelectInput {...inputProps("lienHolderLocation.stateId")} label="lien Holder State *" options={states} />
            <TextInput {...inputProps("lienHolderLocation.zipCode")} label="LienHolder Zip *" />
          </Grid>
          <Grid item xs>
            <TextInput {...inputProps("lienHolder.phone")} label="LienHolder Phone Number" />
            <TextInput {...inputProps("lienHolder.email")} label="LienHolder Email" />
            <TextInput {...inputProps("lienHolder.mainContactName")} label="LienHolder Main Contact" />
          </Grid>
        </Grid>
      </Collapse>
      <Grid container alignItems="flex-start" justify="flex-end" direction="row">
        <div className={classes.buttonWrapper}>
          <Button variant="outlined" color="primary" onClick={onClose}>
            CANCEL
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
            SAVE
          </Button>
        </div>
      </Grid>
    </form>
  );
};

DamagedVehicleForm.propTypes = {
  damagedVehicle: PropTypes.objectOf(PropTypes.any).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default DamagedVehicleForm;
