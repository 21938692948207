import { Paper, Typography, Button, TableHead, TableCell, TableBody, TableRow, Table, IconButton } from "@material-ui/core";
import { CloudUpload, Delete } from "@material-ui/icons";
import clsx from "clsx";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
import React, { useState, useCallback, useEffect } from "react";
import { useAddDocumentsStyles } from "./customHooks";
import FileIcon from "../FileIcon";
import FileInput, { onClick } from "../FileInput";
import { BaseSelectInput, BaseInputText } from "../../inputs";
import { validateFile } from "../hooks";

const CustomTable = ({ classes, fdr, btn, children }) => {
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>Name</TableCell>
          <TableCell>Description</TableCell>
          {fdr && <TableCell>Folder</TableCell>}
          {btn && <TableCell>&nbsp;</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  );
};

CustomTable.propTypes = {
  fdr: PropTypes.bool.isRequired,
  btn: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired
};

CustomTable.defaultProps = { btn: false };

const FileDetail = ({ name, onChange, description, type, folderId, index, id, onDelete, classes, selectProps, fdr, edit }) => {
  return (
    <TableRow key={`new-file-${id}`}>
      <TableCell>
        <FileIcon file type={type} />
      </TableCell>
      <TableCell>{edit ? name : name.substr(0, name.lastIndexOf("."))}</TableCell>
      <TableCell>
        <BaseInputText name={`${id}.description`} value={description} placeholder="Description" onChange={onChange} />
      </TableCell>
      {fdr && (
        <TableCell className={classes.folder}>
          <BaseSelectInput {...selectProps(id, folderId, index)} />
        </TableCell>
      )}
      {!edit && (
        <TableCell className={classes.del}>
          <IconButton onClick={onDelete(id)}>
            <Delete />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};
FileDetail.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  description: PropTypes.string,
  type: PropTypes.string.isRequired,
  folderId: PropTypes.number,
  id: PropTypes.string,
  index: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  selectProps: PropTypes.func.isRequired,
  fdr: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired
};
FileDetail.defaultProps = {
  folderId: undefined,
  description: "",
  id: ""
};

const AddDocuments = ({ vFiles, addFiles, setFile, delFile, editFile, folders, showLoading, error, handleValidation }) => {
  const fdr = (!editFile || Boolean(editFile.folderId)) && Array.isArray(folders) && folders.length > 0;
  const classes = useAddDocumentsStyles(fdr);
  const ini = { errorDrag: false, hover: false };
  const [state, setState] = useState(ini);
  useEffect(() => {
    handleValidation();
  }, [vFiles]);
  const onUploadChange = useCallback(({ target: { files } }) => {
    showLoading();
    addFiles([...files].map((_f, i) => ({ id: uuid(), description: undefined, folderId: undefined, file: files[i] })));
  }, []);
  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    return setState(ps => ({ ...ps, hover: true, errorDrag: [...event.dataTransfer.items].some(i => !validateFile(i)) }));
  }, []);
  const onDragLeave = useCallback(() => setState(ps => ({ ...ps, hover: false, errorDrag: false })), []);
  const onDrop = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;
    onDragLeave();
    if ([...files].map(f => ({ type: f.type, kind: "file" })).some(i => !validateFile(i))) return toast.error("Invalid files");
    return onUploadChange({ target: { files } });
  }, []);
  const selectProps = (id, value, index) => {
    return {
      name: `${id}.folderId`,
      value,
      error: error[`newFiles[${index}].folderId`],
      onChange: setFile,
      options: folders || [],
      disabled: editFile
    };
  };
  return (
    <div className={classes.root}>
      <Typography className="tc-title">{editFile ? "Edit Document" : "Add Documents"}</Typography>
      {!editFile && (
        <div {...{ onDragOver, onDragLeave, onDrop }} className="tc-upd-dropzone">
          <Paper className={clsx({ "tc-upd-hover": state.hover, "tc-upd-error": state.errorDrag })}>
            <CloudUpload />
            <div>Please upload your file</div>
            <FileInput onChange={onUploadChange} />
            <Button variant="contained" color="primary" onClick={onClick}>
              Select Files
            </Button>
          </Paper>
        </div>
      )}
      {vFiles.length > 0 && (
        <CustomTable btn {...{ classes, fdr }}>
          {vFiles.map(({ id, description, folderId, file: { name, type } }, index) => (
            <FileDetail
              name={name}
              onChange={setFile}
              description={description}
              type={type}
              folderId={folderId}
              index={index}
              id={id ?? index}
              onDelete={delFile}
              classes={classes}
              selectProps={selectProps}
              fdr={fdr}
              key={`${id ?? index}.key`}
              edit={false}
            />
          ))}
        </CustomTable>
      )}
      {editFile && (
        <CustomTable {...{ classes, fdr }}>
          <FileDetail
            edit
            name={editFile.name}
            onChange={setFile}
            description={editFile.description}
            type={editFile.type}
            folderId={editFile.folderId}
            index={1}
            id={editFile.id}
            onDelete={delFile}
            classes={classes}
            selectProps={selectProps}
            fdr={fdr}
            key={`${editFile.id}.key`}
          />
        </CustomTable>
      )}
    </div>
  );
};

AddDocuments.propTypes = {
  error: PropTypes.objectOf(PropTypes.string),
  vFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  addFiles: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
  delFile: PropTypes.func.isRequired,
  editFile: PropTypes.objectOf(PropTypes.any),
  showLoading: PropTypes.func.isRequired,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleValidation: PropTypes.func.isRequired
};

AddDocuments.defaultProps = {
  editFile: undefined,
  error: ""
};

export default AddDocuments;
