import React, { useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { Avatar, Grid, Typography } from "@material-ui/core";
import { Person, Business, Room } from "@material-ui/icons";
import { useClaimDuplicatesStyles } from "./index.styles";
import { useContext, actionTypes } from "../context";
import { mapLineOfBusiness } from "../mapPostData";
import ClaimStatus from "../../ClaimStatus";
import ClaimProcessingStatus from "../../ClaimProcessingStatus";
import Claim from "../../../../assets/claim.svg";
import SidePanel from "../../../common/SidePanel";
import { API } from "../../../../redux/actions/actionTypes";
import HeaderCollapsibleBox from "../../../common/HeaderCollapsibleBox";
import { useDebounce } from "../../../../common";
import LabelValue from "../../../common/LabelValue";

export default () => {
  const classes = useClaimDuplicatesStyles();
  const [
    {
      step,
      multipleClaimants,
      duplicates,
      claimant,
      claimants,
      coverageTypes,
      insured: { id: insuredId },
      incident: { address, city, dateofLoss, stateId, zipCode }
    },
    dispatch
  ] = useContext();
  const duplicatesDebounced = useDebounce(data => axios.post(`${API.URL}/claim/duplicates`, data), 1500);
  const any = arr => arr.some(() => true);
  const keys = obj => any(Object.keys(obj));
  const paramsStep1 = () => {
    let params = {};
    if (insuredId) params = { ...params, insuredId };
    const cts = mapLineOfBusiness(coverageTypes);
    if (any(cts)) params = { ...params, coverageTypes: cts };
    if (keys(params)) return params;
    return {};
  };
  const mapClaimant = c => {
    let params = {};
    if (!c.sameAsInsured) {
      if (c.taxIDTypeId && c.taxID) params = { ...params, taxIDTypeId: c.taxIDTypeId, taxID: c.taxID };
      if (c.isPerson && c.firstName && c.lastName) params = { ...params, firstName: c.firstName, middleName: c.middleName, lastName: c.lastName };
      if (!c.isPerson && c.commercialName) params = { ...params, commercialName: c.commercialName };
      if (keys(params)) params = { ...params, isPerson: c.isPerson };
    }
    return params;
  };
  const paramsStep2 = () => {
    let params = {};
    if (stateId) {
      params = { ...params, stateId };
      if (address) params = { ...params, address };
      if (city) params = { ...params, city };
      if (zipCode) params = { ...params, zipCode };
    }
    if (dateofLoss) params = { ...params, dateofLoss };
    if (multipleClaimants) {
      const clmnts = claimants.map(mapClaimant).filter(c => keys(c));
      if (any(clmnts)) params.claimants = clmnts;
    } else {
      const clmnt = mapClaimant(claimant);
      if (keys(clmnt)) params.claimants = [clmnt];
    }
    params = { ...paramsStep1(), ...params };
    return params;
  };
  const effectDuplicates = fnParams => {
    const params = fnParams();
    if (keys(params)) duplicatesDebounced(params).then(({ data }) => dispatch({ type: actionTypes.SET_DUPLICATES, payload: data }));
    else dispatch({ type: actionTypes.SET_DUPLICATES, payload: [] });
  };

  /* Duplicates Step 1 */
  useEffect(() => {
    if (step === 0) effectDuplicates(paramsStep1);
  }, [insuredId, coverageTypes]);

  /* Duplicates Step 2 */
  useEffect(() => {
    if (step === 1) effectDuplicates(paramsStep2);
  }, [address, city, dateofLoss, stateId, zipCode, claimant, claimants]);

  const onToggle = name => {
    const [, id] = name.split("-");
    return dispatch({ type: actionTypes.TOGGLE_DUPLICATE, payload: id });
  };
  const labelValue = (label, value = null, css = classes.value) => <LabelValue {...{ label, value }} classes={{ value: css }} />;
  const anyDuplicates = any(duplicates);
  return (
    <SidePanel open={step <= 1} width={450}>
      <Grid container className={classes.root}>
        <Grid item>
          <Typography className="tc-title" gutterBottom>
            Claim Duplicates
          </Typography>
        </Grid>
        <Grid item className={classes[anyDuplicates ? "duplicates" : "noDuplicates"]}>
          {(anyDuplicates &&
            duplicates.map(d => (
              <HeaderCollapsibleBox
                {...{
                  key: d.id,
                  title: "Claim #",
                  open: d.open,
                  name: `duplicate-${d.id}`,
                  onToggle,
                  header: (
                    <Grid container alignItems="center" spacing={1} className={classes.claimHeader}>
                      <Grid item className={classes.number}>
                        {d.number}
                      </Grid>
                      <Grid item>
                        <ClaimStatus status={d.status} />
                      </Grid>
                      <Grid item>
                        <ClaimProcessingStatus processingStatus={d.processingStatus} />
                      </Grid>
                    </Grid>
                  )
                }}
              >
                <div style={{ paddingTop: 10 }}>
                  {labelValue(
                    "Claimant",
                    <Grid container alignItems="center">
                      <Grid item>{d.isPerson ? <Person /> : <Business />}</Grid>
                      <Grid item>{d.claimantFullName}</Grid>
                    </Grid>,
                    classes.claimant
                  )}
                  {labelValue(d.taxIDType || "Social Security", d.taxID)}
                  {labelValue("Insured", d.insured)}
                  <Grid container className={classes.grid}>
                    <Grid item>
                      {labelValue("Line Of Business", <span className={classes.lob}>{d.coverageGroup}</span>)}
                      {labelValue("Date of Loss", moment(d.dateofLoss).format("M/D/YYYY"))}
                    </Grid>
                    <Grid item>
                      {labelValue("Loss Type", d.claimLossType)}
                      {labelValue(
                        "Jurisdiction State",
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <Room className={classes.room} viewBox="5 2 14 20" />
                          </Grid>
                          <Grid item>{d.stateCode}</Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </HeaderCollapsibleBox>
            ))) || (
            <div>
              <Avatar className={classes.avatar}>
                <Claim />
              </Avatar>
              No duplicates found
            </div>
          )}
        </Grid>
      </Grid>
    </SidePanel>
  );
};
