const { makeStyles } = require("@material-ui/core");

export default makeStyles(theme => ({
  marginDivider: { marginBottom: theme.spacing(2), marginTop: theme.spacing(2) },
  title: { textAlign: "left", font: "Regular 11px/20px Roboto", letterSpacing: "0", color: "#428302", opacity: "1" },
  information: { textAlign: "left", font: "Regular 11px/20px Roboto", letterSpacing: "0", color: "#000000", opacity: "1" },
  divider: { margin: theme.spacing(1) },
  noPadding: { margin: 0 },
  subtitle: { textAlign: "left", font: "Regular 11px/20px Roboto", letterSpacing: "0", color: "#428302", opacity: "1", marginTop: "20px" },
  sidePanel: { overflow: "hidden", "& > div > div": { width: "523px", margin: "22px 40px auto 40px" } },
  close: { cursor: "pointer" },
  dialogContent: { "& > div": { boxShadow: "none" } }
}));
