import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { get, set } from "../../../redux/actions/initialReport/damagedProperty";
import CollapsibleBox, { useCollapsibleBox } from "../../common/CollapsibleBox";
import PropertyForm from "../../common/property/PropertyForm";
import PropertyContextProvider from "../../common/property/PropertyContext";
import ReadMode from "../../common/property/Read";
import { useRouteParam } from "../../../common";
import ActionsAllowedContext from "../../common/contexts/ActionsAllowed";

const DamagedPropertyBox = props => {
  const claimId = useRouteParam("claimId");
  const dispatch = useDispatch();
  const store = useSelector(({ initialReport: { information } }) => information, shallowEqual);
  const { propertyDamaged } = store || {};
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info");
  const {
    props: { boxProps, editMode: isEdit, loading: isLoading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit: onToggleForm }
  } = useCollapsibleBox(access.update);
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  const onSubmit = statePropDamaged => {
    if (access.update) {
      showLoading();
      dispatch(set(statePropDamaged, onSuccessEdit, hideLoading));
    }
  };
  return (
    <CollapsibleBox title="Damaged Property" name="damagedP" {...boxProps} {...props}>
      {Loading}
      <PropertyContextProvider {...{ isEdit, readMode: !isEdit, initialData: { ...propertyDamaged } }}>
        {(isEdit && <PropertyForm {...{ isEdit, onCancel: onToggleForm, onSubmit, isLoading }} />) || <ReadMode />}
      </PropertyContextProvider>
    </CollapsibleBox>
  );
};

export default DamagedPropertyBox;
