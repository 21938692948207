import { Delete } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { api } from "../../../api/apiUtils";
import { setExportTitleFileName, useRouteParam } from "../../../common";
import { API } from "../../../redux/actions/actionTypes";
import { addCode, deleteCode } from "../../../redux/actions/vendor";
import CollapsibleBox, { useCollapsibleBox } from "../../common/CollapsibleBox";
import useCustomConfirm from "../../common/customHooks/useCustomConfirm";
import useOnToggleBoxes from "../../common/customHooks/useHandleBox";
import useYupValidationSchema from "../../common/customHooks/useYupValidationSchema";
import { BaseSearchSelect } from "../../common/inputs";
import MaterialTable from "../../common/table/MaterialTable";
import { industrySchema } from "./yup";

const includesAndChange = (ps, name, value, names) => (names || [name]).some(x => x === name && ps[name] !== value);

const Industry = ({ actionsAllowed }) => {
  const dispatch = useDispatch();
  const vendorId = useRouteParam("id");
  const dataSelector = useSelector(
    ({
      vendor: {
        profile: { industry: codes, basicInformation }
      },
      common: { codeType }
    }) => ({ codes, basicInformation, codeType }),
    shallowEqual
  );
  const { codes, codeType, basicInformation } = dataSelector;
  const {
    props: { boxProps, Loading },
    actions: { showLoading, hideLoading }
  } = useCollapsibleBox();
  const {
    open: { industry: open },
    onToggle
  } = useOnToggleBoxes({ initialValue: { industry: true } });
  const onDispatch = (action, onDispatchSuccess, onDispatchError) => ({ id, vendorId: vId }) => {
    showLoading();
    const onSuccess = () => {
      hideLoading();
      if (typeof onDispatchSuccess === "function") onDispatchSuccess();
    };
    const onError = () => {
      hideLoading();
      if (typeof onDispatchError === "function") onDispatchError();
    };
    return dispatch(action(!basicInformation?.hasMultipleLocations ? parseInt(vendorId, 0) : vId, id, onSuccess, onError));
  };
  const [{ locations, divisions, codesBy }, setOptions] = useState({ locations: [], divisions: [], codesBy: [] });
  const [newCode, setNewCode] = useState({});
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(onDispatch(deleteCode), "Cancel", " Yes, Delete");
  const onDelete = (_e, oData) =>
    handleCustomConfirm({
      oData,
      titleText: `Delete Code`,
      messageText: (
        <>
          <p>The following code is going to be deleted: </p>
          <p>
            <b>{oData.codeName}</b>
          </p>
          <p>Are you sure you want to proceed?</p>
        </>
      )
    });
  const callApi = async (url, data, opsPaths, fnIfEmpty) => {
    try {
      const d = await api({ url: `${API.URL}/select/${url}`, data });
      setOptions(o => ({ ...o, ...opsPaths.reduce((a, p, i) => ({ ...a, [p]: i === 0 ? d : [] }), {}) }));
      if (!d.length && typeof fnIfEmpty === "function") fnIfEmpty(data);
    } catch (e) {
      setOptions(o => ({ ...o, ...opsPaths.reduce((a, p) => ({ ...a, [p]: [] }), {}) }));
    }
  };
  useEffect(() => {
    if (basicInformation?.hasMultipleLocations) callApi("vendorlocations", { parentId: vendorId }, ["locations"]);
  }, [basicInformation?.hasMultipleLocations]);
  const callCodes = ({ codeTypeId, divisionId }) =>
    callApi("codes", { codeTypeId, codesList: codes.map(({ id }) => id), ...(divisionId ? { divisionId } : {}) }, ["codesBy"]);
  const callDivision = ({ codeTypeId }) => callApi("codedivision", { codeTypeId }, ["divisions", "codesBy"], callCodes);
  const { handleValidateSchema, validationMessages: error } = useYupValidationSchema(industrySchema);
  const onValidate = ext =>
    handleValidateSchema({ hasMultipleLocations: !!basicInformation?.hasMultipleLocations, ...newCode, divisions, ...(ext || {}) });
  const handleChange = fn => ({ target: { name, value } }) =>
    setNewCode(ps => {
      const ns = {
        ...ps,
        id: includesAndChange(ps, name, value, ["codeTypeId", "divisionId"]) ? undefined : ps.id,
        divisionId: includesAndChange(ps, name, value, ["codeTypeId"]) ? undefined : ps.divisionId,
        [name]: value
      };
      if (typeof fn === "function" && includesAndChange(ps, name, value)) fn(ns);
      onValidate(ns);
      return ns;
    });
  const col = (title, field, name) => ({
    title,
    field,
    editComponent: () => {
      const selectProps = {
        ...{ name, value: newCode[name], onChange: handleChange() },
        ...(name === "vendorId" ? { options: locations } : {}),
        ...(name === "id" ? { options: codesBy, disabled: !!divisions.length && !newCode.divisionId } : {}),
        ...(name === "divisionId" ? { options: divisions, disabled: !newCode.codeTypeId, onChange: handleChange(callCodes) } : {}),
        ...(name === "codeTypeId" ? { options: codeType, onChange: handleChange(callDivision) } : {})
      };
      selectProps.disabled = selectProps.disabled || !selectProps.options.length || undefined;
      selectProps.error = (!selectProps.disabled && error[name]) || undefined;
      return <BaseSearchSelect {...selectProps} />;
    }
  });
  const onClearState = res => {
    setNewCode({});
    return res(setOptions(o => ({ ...o, divisions: [], codesBy: [] })));
  };
  const tableProps = {
    columns: [
      ...(basicInformation?.hasMultipleLocations ? [col("Location", "vendorName", "vendorId", "Location Required")] : []),
      col("Type", "codeType", "codeTypeId", "Type Required"),
      col("Division", "codeDivisionName", "divisionId", "Division Required"),
      col("Code Description", "codeName", "id", "Code Required")
    ],
    data: codes,
    hidePaging: true,
    editable: actionsAllowed.create
      ? {
          onRowAddCancelled: () => new Promise(onClearState),
          onRowAdd: () =>
            new Promise((res, rej) => {
              if (onValidate().isValid) return onDispatch(addCode, () => onClearState(res), rej)(newCode);
              return rej();
            })
        }
      : {},
    actions: actionsAllowed.delete ? [{ icon: Delete, tooltip: "Delete Code", onClick: onDelete }] : [],
    options: { headerStyle: { zIndex: 0 }, exportFileName: setExportTitleFileName(`${basicInformation?.name}_IndustryCodes`) },
    componentsAttr: { OverlayLoading: () => <></> }
  };
  return (
    <CollapsibleBox title="Industry" name="industry" open={open} onToggle={onToggle} {...boxProps}>
      {Loading}
      <MaterialTable {...tableProps} />
      <Confirm />
    </CollapsibleBox>
  );
};

Industry.propTypes = {
  actionsAllowed: PropTypes.objectOf(PropTypes.any).isRequired
};

Industry.defaultProps = {};

export default Industry;
