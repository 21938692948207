import clsx from "clsx";
import React, { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import { Grid, Avatar, IconButton, Typography, Collapse } from "@material-ui/core";
import { Delete, KeyboardArrowDown } from "@material-ui/icons";
import { useContext, actionTypes } from "../../context";
import { CheckboxLabel } from "../../../../common/inputs";
import LinkButton from "../../../../common/LinkButton";
import Form from "./Form";

const Claimant = memo(({ classes, claimant, index }) => {
  const [
    { multipleClaimants },
    dispatch,
    handleChange,
    {
      step2: { validationMessages },
      handleValidation
    }
  ] = useContext();
  const [loaded, setLoaded] = useState(false);
  const { id, isPerson, sameAsInsured, firstName, lastName, dateofBirth, order } = claimant;
  const { commercialName, contact, taxIDTypeId, taxID, location, mailAddress } = claimant;
  useEffect(() => {
    if (!loaded) setLoaded(true);
  }, [claimant]);
  useEffect(() => {
    if (loaded) handleValidation();
  }, [isPerson, sameAsInsured, firstName, lastName, dateofBirth, commercialName, contact, taxIDTypeId, taxID, location, mailAddress]);
  const onChange = e => handleChange(e, actionTypes.SET_CLAIMANT);
  const onDelete = () => dispatch({ type: actionTypes.DELETE_CLAIMANT, id, handleValidation });
  const inputProps = path => {
    const val = () => {
      const [name1, name2] = path.split(".");
      if (name2) return ["value", claimant[name1][name2]];
      return [name1 === "phisicalAsMailing" ? "checked" : "value", claimant[name1]];
    };
    const [name, value] = val();
    return {
      onChange: e => handleChange(e, actionTypes.SET_CLAIMANT),
      name: multipleClaimants ? `multi-${id}-${path}` : path,
      error: (validationMessages || {})[`claimant${multipleClaimants ? `s[${index}]` : ""}.${path}`],
      [name]: value
    };
  };
  const title = (
    <Typography className="tc-title">
      {(multipleClaimants && (
        <Grid container spacing={1} alignItems="center" wrap="nowrap" component="span">
          <Grid item component="span">
            <KeyboardArrowDown className={clsx("tc-arrow", { "tc-expand": !claimant.closed })} />
          </Grid>
          <Grid item component="span">
            Claimant
          </Grid>
        </Grid>
      )) ||
        "Claimant"}
    </Typography>
  );
  const onToggle = () => dispatch({ type: actionTypes.SET_CLAIMANT, payload: { name: `multi-${id}-closed`, value: !claimant.closed } });
  return (
    <>
      <Grid container className={classes.head} spacing={2}>
        {multipleClaimants && (
          <Grid item>
            <Avatar className={classes.number}>{order}</Avatar>
          </Grid>
        )}
        <Grid item xs>
          {(multipleClaimants && <LinkButton onClick={onToggle}>{title}</LinkButton>) || title}
        </Grid>
        {!multipleClaimants && (
          <Grid item className={classes.sameAsInsured}>
            <CheckboxLabel name="sameAsInsured" label="Same As Insured" checked={sameAsInsured} {...{ onChange }} />
          </Grid>
        )}
        {multipleClaimants && claimant.sameAsInsured && <Grid item>Same as Insured</Grid>}
        {multipleClaimants && (
          <Grid item>
            <IconButton onClick={onDelete}>
              <Delete />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Collapse in={!multipleClaimants || !claimant.closed} timeout="auto">
        <Form {...{ classes, claimant, inputProps }} />
      </Collapse>
    </>
  );
});

Claimant.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  claimant: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number
};

Claimant.defaultProps = {
  index: undefined
};

export default Claimant;
