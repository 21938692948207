import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { get, set } from "../../../../redux/actions/claim/info/damagedProperty";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import PropertyForm from "../../../common/property/PropertyForm";
import PropertyContextProvider from "../../../common/property/PropertyContext";
import ReadMode from "../../../common/property/Read";
import { useRouteParam } from "../../../../common";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";

const DamagedPropertyBox = props => {
  const claimId = useRouteParam("claimId");
  const dispatch = useDispatch();
  const store = useSelector(
    ({
      claim: {
        propertyDamaged,
        claim: { claimLineOfBusinessId }
      }
    }) => ({ propertyDamaged, claimLineOfBusinessId }),
    shallowEqual
  );
  const { propertyDamaged: initialData, claimLineOfBusinessId } = store;
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info");
  const {
    props: { boxProps, editMode: isEdit, loading: isLoading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  const onSubmit = statePropDamaged => {
    showLoading(true);
    dispatch(set({ ...statePropDamaged, damaged: { ...statePropDamaged.damaged, claimId } }, onSuccessEdit, hideLoading));
  };

  return (
    <CollapsibleBox title="Damaged Property" name="damagedP" {...boxProps} {...props}>
      {Loading}
      <PropertyContextProvider readMode={!isEdit} isPropertyLiability={claimLineOfBusinessId === 3} {...{ isEdit, initialData }}>
        {isEdit && access.update ? <PropertyForm {...{ isEdit, onCancel: onToggleEdit, onSubmit, isLoading }} /> : <ReadMode />}
      </PropertyContextProvider>
    </CollapsibleBox>
  );
};

export default DamagedPropertyBox;
