import { makeStyles } from "@material-ui/core";

export const useListStyles = makeStyles(() => ({
  toolbar: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    padding: 10
  },
  paperContainer: {
    padding: 17
  },
  title: {
    color: "#428302",
    marginBottom: 5,
    cursor: "pointer",
    padding: "7px"
  },
  toolbarButton: {
    marginRight: 10,
    position: "relative"
  },
  toolbarItem: {
    position: "absolute",
    zIndex: "1000",
    background: "#fff"
  }
}));

export const useFooterStyles = makeStyles(() => ({
  cell: {
    fontSize: 14,
    color: "#004763",
    fontWeight: "bold"
  },
  footer: {
    height: "48px",
    background: "#DAEAEF 0% 0% no-repeat padding-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  footerRecovery: {
    height: "48px",
    background: "#DAEAEF 0% 0% no-repeat padding-box",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  container: {
    position: "relative",
    right: "25%"
  },
  containerRecovery: {
    marginRight: "20px"
  },
  containerFinancial: {
    display: "inline-flex",
    position: "relative",
    right: "10%"
  },
  spanContainer: {
    marginLeft: "20px"
  },
  spanTitle: {
    color: "#004763",
    fontWeight: "bold"
  },
  label: {
    // maxWidth: "71%",
    // width: "71%"
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
    marginLeft: "20px"
  }
}));

export const useFormStyles = makeStyles(theme => ({
  removeIcon: {
    float: "right",
    cursor: "pointer"
  },
  root: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(2)
  },
  bar: {
    marginBottom: "1rem"
  },
  title: {
    color: "#428302",
    marginBottom: 20,
    cursor: "pointer"
  },
  papperheader: {
    height: "50px"
  },
  buttons: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  fileZoneRoot: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  spanTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: "50% ",
    position: "relative",
    textDecoration: "underline"
  },
  paper: {
    height: "100%",
    width: "100%",
    padding: 25
  },
  upload: {
    backgroundColor: "#F3F3F3",
    padding: theme.spacing(3.38, 2.8, 2.7, 2.8),
    textAlign: "center",
    color: "#898F91",
    height: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    "& svg": { fontSize: 66, marginBottom: -12 },
    "& button": { marginTop: theme.spacing(3) }
  }
}));

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(2)
  },
  rectanglePaid: { backgroundColor: "#D2EEB6", borderRadius: 4, padding: theme.spacing(0.5, 1), textAlign: "center" },
  rectangleOnHold: { backgroundColor: "#CECECE", borderRadius: 4, padding: theme.spacing(0.5, 1), textAlign: "center" },
  rectangleVoided: { backgroundColor: "#F8D0B2", borderRadius: 4, padding: theme.spacing(0.5, 1), textAlign: "center" },
  rectanglePending: { backgroundColor: "#FCF3BC", borderRadius: 4, padding: theme.spacing(0.5, 1), textAlign: "center" },
  paddingDropdown: {
    padding: "10px 10px"
  },
  table: {
    minWidth: 700,
    background: "#DAEAEF",
    color: "#004763"
  },
  paperContainer: {
    padding: 17
  },
  spanTitle: {
    textDecoration: "underline"
  },
  title: {
    color: "#428302",
    marginBottom: 5,
    cursor: "pointer",
    padding: "7px"
  },
  cellWidth: {
    boxSizing: "border-box",
    width: "calc((100% - 0px) / 10)"
  },
  pl: { paddingLeft: 5 },
  pr: { paddingRight: 5 },
  marginNone: { marginTop: "0px !important" }
}));
