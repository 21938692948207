import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ClickAwayListener, Grow, Paper, MenuItem, MenuList, Popper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: { display: "flex", position: "relative" }
}));

const Menues = ({ menuName, subMenues, onClick, menuId, left, renderOption, color, variant }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const rOption =
    typeof renderOption === "function"
      ? renderOption
      : option => {
          return (
            <MenuItem
              key={option.text}
              value={{ ...option }}
              onClick={() => {
                onClick(option);
                handleClose();
              }}
              style={{ paddingLeft: `${(option.level ?? 0) * 8 + 8}px` }}
              disabled={option.level === 0 && option.subOptions.length > 0}
            >
              {option.text}
            </MenuItem>
          );
        };

  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        color={color}
        variant={variant}
        onClick={handleToggle}
      >
        <Grid container spacing={2} wrap="nowrap" alignItems="center">
          <Grid item style={{ paddingRight: 0 }}>
            {menuName}
          </Grid>
          <Grid item className="tc-mtb-4px">
            <ExpandMoreIcon className={clsx("tc-arrow", { "tc-expand": open })} />
          </Grid>
        </Grid>
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement={`bottom-${left ? "start" : "end"}`}
        style={{ position: "absolute", zIndex: "1000" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id={`menu-list-grow-${menuId}`} onKeyDown={handleListKeyDown}>
                  {subMenues.map(rOption)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

Menues.propTypes = {
  menuName: PropTypes.string.isRequired,
  menuId: PropTypes.string.isRequired,
  subMenues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func.isRequired,
  left: PropTypes.bool,
  renderOption: PropTypes.func,
  color: PropTypes.string,
  variant: PropTypes.string
};
Menues.defaultProps = {
  left: false,
  renderOption: undefined,
  color: "primary",
  variant: "contained"
};

export default Menues;
