import { CLIENTR as CLIENT } from "../../../actions/actionTypes";
import actions from "../../../actions/client/insured";
import profile from "../common/profile";

export default [
  profile({ type: CLIENT.INSURED.PROFILE.GET, actionSuccess: actions.getProfileSuccess }).profile.get,
  profile({ type: CLIENT.INSURED.PROFILE.BASIC.SET, actionSuccess: actions.updateBasicSuccess }).profile.set,
  profile({ type: CLIENT.INSURED.PROFILE.STATUS.SET, actionSuccess: actions.updateStatusSuccess }).profile.status.set,
  profile({ type: CLIENT.INSURED.PROFILE.CODE_PROFILE.POST, actionSuccess: actions.addCodeSuccess }).profile.code.post,
  profile({ type: CLIENT.INSURED.PROFILE.CODE_PROFILE.DEL, actionSuccess: actions.removeCodeSuccess }).profile.code.del
];
