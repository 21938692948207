import { fork } from "redux-saga/effects";
import rootSagaGenerator from "./common/rootSagaGenerator";
import claim from "./claim";
import common from "./common";
import catastrophe from "./catastrophe";
import client from "./client";
import clientDocuments from "./client/documents";
import occurrence from "./occurrence";
import clientInsuredDocuments from "./client/level_insured/documents";
import vendor from "./vendor";
import note from "./notes";
import diary from "./diary";
import documents from "./documents";
import banking from "./banking";
import admin from "./admin";
import initialReport from "./initialReport";
import profile from "./profile";
import automation from "./automation";
import dashboard from "./dashboard";
import financial from "./financial";
import notification from "./notification";

export default rootSagaGenerator({
  name: "root",
  sagas: [
    client,
    claim,
    common,
    catastrophe,
    clientDocuments,
    occurrence,
    clientInsuredDocuments,
    vendor,
    note,
    diary,
    documents,
    banking,
    initialReport,
    admin,
    profile,
    automation,
    dashboard,
    financial,
    notification
  ].map(x => fork(x))
});
