import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { FormControl, FormHelperText, Input, InputLabel, InputAdornment } from "@material-ui/core";
import { BaseFormControlStyleRight, FormControlStyleRight, HocInput } from "./HocInput";

const MoneyInput = ({ classes, className, error, label, name, onChange, adornmentSymbol, ...other }) => {
  const id = `money-${name}`;
  const props = {
    ...other,
    onValueChange: values => {
      onChange({ target: { value: values.floatValue, name } });
    },
    customInput: Input,
    id,
    name,
    startAdornment: adornmentSymbol ? <InputAdornment position="start">{adornmentSymbol}</InputAdornment> : undefined
  };
  return (
    <FormControl fullWidth {...{ classes, className, error: Boolean(error) }}>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <NumberFormat {...props} />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

MoneyInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  decimalScale: PropTypes.number,
  error: PropTypes.string,
  fixedDecimalScale: PropTypes.bool,
  thousandSeparator: PropTypes.bool,
  adornmentSymbol: PropTypes.string
};

MoneyInput.defaultProps = {
  label: null,
  decimalScale: 2,
  error: null,
  fixedDecimalScale: true,
  thousandSeparator: true,
  adornmentSymbol: "$",
  className: undefined
};

export const BaseMoneyInput = HocInput(MoneyInput, BaseFormControlStyleRight);

export default HocInput(MoneyInput, FormControlStyleRight);
