import { makeStyles, Tooltip as BaseTooltip, withStyles } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { memo } from "react";

const useStyles = makeStyles({
  root: { width: 22, height: 19, display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden", flexWrap: "nowrap" }
});

const Tooltip = memo(({ tooltip, ...props }) => {
  const classes = useStyles();
  const warning = (
    <div className={classes.root}>
      <Warning className="tc-color-red" />
    </div>
  );
  if (!tooltip) return warning;
  return (
    <BaseTooltip arrow open placement="top-end" PopperProps={{ disablePortal: true }} {...props}>
      {warning}
    </BaseTooltip>
  );
});

Tooltip.propTypes = { tooltip: PropTypes.bool };
Tooltip.defaultProps = { tooltip: false };

export default withStyles(theme => ({
  tooltip: { backgroundColor: "#E74C3C !important", color: "#FFFFFF !important" },
  tooltipPlacementTop: {
    "@media (min-width:600px)": {
      margin: theme.spacing(1, 0)
    }
  },
  arrow: { color: "#E74C3C" }
}))(Tooltip);
