import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import { loadVendor, setVendor } from "../../../../redux/actions/vendor";
import EditView from "./Form";
import ReadView from "./Read";
import { useRouteParam } from "../../../../common";

const BasicInfo = ({ actionsAllowed, ...props }) => {
  const vendorId = useRouteParam("id");
  const basicInformation = useSelector(
    ({
      vendor: {
        profile: { basicInformation: b }
      }
    }) => b,
    shallowEqual
  );
  const show = basicInformation?.id === vendorId;
  const {
    props: { boxProps, editMode, Loading, loading },
    actions: { onToggleEdit: onClose, showLoading, hideLoading, onSuccessEdit }
  } = useCollapsibleBox(show && actionsAllowed?.update);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!show) {
      showLoading();
      dispatch(loadVendor(vendorId, hideLoading, hideLoading));
    }
  }, []);
  // const onSave = (data) => {
  //   if (basic?.id) {
  //     if (!vendorParentId) return dispatch(setVendor(data));
  //     return dispatch(setLocations(data, onClose));
  //   }
  //   if (!vendorParentId) return dispatch(addVendor(data));
  //   return dispatch(postLocations({ basicInformation: { ...data, parentId: vendorParentId }, industry: [] }, onClose));
  // };
  const onSave = data => {
    showLoading();
    return dispatch(setVendor(data, onSuccessEdit, hideLoading));
  };
  return (
    <CollapsibleBox title="Basic Information - Headquarters" name="basic" {...boxProps} {...props}>
      {Loading}
      {show && editMode && <EditView {...{ basicInformation, loading, onClose, onSave }} />}
      {show && !editMode && <ReadView {...{ basicInformation }} />}
    </CollapsibleBox>
  );
};

BasicInfo.propTypes = {
  actionsAllowed: PropTypes.objectOf(PropTypes.any).isRequired
};
export default BasicInfo;
