import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useLocation } from "react-router-dom";
import { Divider } from "@material-ui/core";
import useStyles from "./index.styles";
import Information from "./Information";
import Locations from "./Locations";
import ClaimInvolves from "./ClaimInvolves";
import Financial from "./Financial";
import { getFinancialInfo, getClaimsInvolved } from "../../../redux/actions/vendor";
import { useRouteParam } from "../../../common";
import Loading from "../../common/Loading";

const VendorSideBar = () => {
  const classes = useStyles();
  const dataSelector = useSelector(({ vendor }) => vendor, shallowEqual);

  const {
    profile: { basicInformation, industry },
    locations,
    claimsInvolved,
    financialInfo
  } = dataSelector || {};

  const dispatch = useDispatch();
  const vendorId = useRouteParam("id");
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    if (basicInformation.id !== vendorId && pathname.indexOf("overview") === -1) {
      setIsLoading(true);
      dispatch(getFinancialInfo(vendorId));
      dispatch(getClaimsInvolved(vendorId));
    }
  }, []);
  useEffect(() => {
    if (basicInformation.id === vendorId) {
      setIsLoading(false);
    }
  }, [basicInformation]);
  return (
    <div className={classes.sidebar}>
      {(isLoading && <Loading float />) || (
        <>
          <Information {...{ basicInformation }} className={classes.box} />
          <Divider />
          <Locations {...{ basicInformation, industry, locations }} className={classes.box} />
          <Divider />
          <ClaimInvolves {...{ claimsInvolved }} className={classes.box} />
          <Divider />
          <Financial {...{ financialInfo }} className={classes.box} />
        </>
      )}
    </div>
  );
};

export default VendorSideBar;
