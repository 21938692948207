import React, { Fragment, useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { useRouteParam } from "../../../common";
import { clear, get, makeClaim } from "../../../redux/actions/initialReport/claim";
import useClaimBoxes from "../../common/customHooks/useClaimBoxes";
import ClaimantBox from "./Claimant";
import ClaimBox from "./Claim";
import DamagedVehicleBox from "./Damagedvehicle";
import WitnessBox from "./WitnessInfo";
import InsuredBox from "./InsuredInformation";
import BodilyInjuryBox from "./BodilyInjury";
import DamagedPropertyBox from "./DamagedProperty";
import InsuredVehicleBox from "./InsuredVehicle";
import ClaimInfo from "./ClaimInfo";
import WcClaimBox from "./WcClaim";
import EmploymentBox from "./Employment";
import ActionsAllowedContext from "../../common/contexts/ActionsAllowed";
import { useLoadingContext } from "../../common/Loading";
import ExpandAll from "../../common/ExpandAll";

export default () => {
  const claimId = useRouteParam("claimId");

  const dispatch = useDispatch();
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info");
  const {
    actions: { showLoading, hideLoading }
  } = useLoadingContext();

  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);

  const store = useSelector(({ initialReport: { claim } }) => claim, shallowEqual);
  const claimLossTypeGroups = useSelector(
    ({
      common: {
        claimLossTypeGroups: { data }
      }
    }) => data,
    shallowEqual
  );
  const { claimLossTypeGroupId, claimLineOfBusiness, dateReported, dateEntry, id, claimLineOfBusinessCode } = store;

  const { showBoxes, expandProps } = useClaimBoxes({
    claimLossTypeGroupId,
    claimLineOfBusiness,
    claimLossTypeGroups,
    components: {
      ClaimantBox,
      EmploymentBox,
      ClaimBox,
      WcClaimBox,
      DamagedVehicleBox,
      WitnessBox,
      InsuredBox,
      BodilyInjuryBox,
      DamagedPropertyBox,
      InsuredVehicleBox
    }
  });

  const history = useHistory();

  const handleSave = () => {
    showLoading();
    return dispatch(
      makeClaim(
        id,
        () => {
          hideLoading();
          dispatch(clear());
          return history.push(`/claim/${id}`);
        },
        hideLoading
      )
    );
  };

  return (
    <Fragment key="information-boxes">
      <ExpandAll elementId="initialReportsTopContainer" className="tc-ml1" {...expandProps} />
      <ClaimInfo data={{ dateEntry, dateReported, initialReport: id, claimLineOfBusinessCode }} onSave={handleSave} />
      {showBoxes.map((item, index) => item.show && item.component(item.name + index))}
    </Fragment>
  );
};
