import { Card as MuiCard, CardHeader, CardContent, Grow } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { memo } from "react";

const Card = memo(({ title, in: collapseIn, cardHeaderAction, ...props }) => (
  <Grow in={collapseIn} timeout="auto">
    <MuiCard className="tc-full-height">
      {!!title && <CardHeader {...{ title, action: cardHeaderAction() }} className="tc-card-header" classes={{ title: "tc-card-title" }} />}
      <CardContent {...props} />
    </MuiCard>
  </Grow>
));

Card.propTypes = { title: PropTypes.string, in: PropTypes.bool, cardHeaderAction: PropTypes.func };
Card.defaultProps = { title: undefined, in: true, cardHeaderAction: () => {} };

export default Card;
