import React from "react";

const FileInput = props => (
  <input
    {...{
      id: "document-upload-file",
      multiple: true,
      accept: "application/pdf,image/png,image/jpg,image/jpeg,video/quicktime,video/avi",
      value: "",
      ...props
    }}
    type="file"
    style={{ display: "none" }}
  />
);

export const onIdClick = id => event => {
  event.preventDefault();
  document.querySelector(`#${id}`).click();
};

export const onClick = onIdClick("document-upload-file");

export default FileInput;
