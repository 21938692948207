import { CLIENTR as CLIENT, API } from "../../actions/actionTypes";
import {
  getSuccess,
  setClientStructureSucess,
  setReservePaymentSucess,
  setBillReviewSucess,
  setTeamSuccess,
  addClientAndMigrateLevelSuccess
} from "../../actions/client/setting";
import { takeLatest, takeLatestPost, takeLatestSet } from "../common/sagaGenerator";

export default [
  takeLatest({
    type: CLIENT.SETTING.GET,
    actionSuccess: getSuccess,
    errorMessage: "Client settings error.",
    url: `${API.URL}/client/getClientSetting`,
    params: ({ clientId }) => ({ clientId })
  }),
  takeLatestSet({
    type: CLIENT.SETTING.CLIENT_STRUCTURE.SET,
    actionSuccess: setClientStructureSucess,
    successMessage: "Client Structure information successfully saved",
    errorMessage: "Unable to save Client Structure information",
    url: `${API.URL}/client/setClientStructure`,
    params: ({ data }) => data
  }),
  takeLatestSet({
    type: CLIENT.SETTING.RESERVE_PAYMENT.SET,
    actionSuccess: setReservePaymentSucess,
    successMessage: "Reserves & Payments Limits information successfully saved",
    errorMessage: "Unable to save Reserves & Payments Limits information",
    url: `${API.URL}/client/setReservePaymentSetting`,
    params: ({ data }) => data
  }),
  takeLatestSet({
    type: CLIENT.SETTING.BILL_REVIEW.SET,
    actionSuccess: setBillReviewSucess,
    successMessage: "Bills Review information successfully saved",
    errorMessage: "Unable to save Bills Review information",
    url: `${API.URL}/client/setClientBillReview`,
    params: ({ data }) => data
  }),
  takeLatestSet({
    type: CLIENT.SETTING.TEAM.SET,
    actionSuccess: setTeamSuccess,
    successMessage: "Management Team information successfully saved",
    errorMessage: "Unable to save Management Team information",
    url: `${API.URL}/client/teamSetting`,
    params: ({ data }) => data
  }),
  takeLatestPost({
    type: CLIENT.ADD.POST_AND_MIGRATE,
    actionSuccess: addClientAndMigrateLevelSuccess,
    successMessage: "Client successfully created and migrate relateds",
    errorMessage: "Unable to save client and migrate relateds",
    url: `${API.URL}/client/addAndMigrate`,
    params: ({ data }) => data
  })
];
