const location = { address1: undefined, city: undefined, zipCode: undefined, stateId: undefined };

export default {
  sameAsInsured: false,
  claimantTypeId: 1,
  isPerson: true,
  firstName: undefined,
  lastName: undefined,
  dateofBirth: undefined,
  genderId: undefined,
  dependents: undefined,
  taxID: undefined,
  phone: undefined,
  mobile: undefined,
  workPhone: undefined,
  workEmail: undefined,
  email: undefined,
  phisicalAsMailing: true,
  location,
  mailAddress: { ...location },
  taxIDTypeId: 1,
  maritalStatusId: 1
};
