import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import MaterialTable from "../table/MaterialTable";
import Confirm from "../Confirm";
import { BaseSearchSelect, BaseMoneyInput as MoneyInput } from "../inputs";
import { array } from "../../../common";
import { isNumber } from "../../../common/yup";

const includesAndChange = (ps, name, value, names) => (names || [name]).some(x => x === name && ps[name] !== value);

const CodeList = ({ title, codes, onAdd, onDelete, viewMode, isAllowedModeEdit, exportFileName, rateColumn }) => {
  const store = useSelector(({ common }) => common, shallowEqual);
  const { codeType, codeDivision, codes: storeCodes } = store;
  const [{ openDialog, toRemove }, setConfirm] = useState({ openDialog: false, toRemove: {} });

  const confirmConfig = {
    title: "Delete Code",
    messageText: (
      <>
        <p>The following code is going to be deleted:</p>
        <p>
          <b>{toRemove?.codeName}</b>
        </p>
        <p>Are you sure you want to proceed?</p>
      </>
    ),
    onToggleConfirm: () => setConfirm(ps => ({ ...ps, openDialog: !ps.openDialog })),
    onAction: () => {
      onDelete(toRemove);
      setConfirm(ps => ({ openDialog: !ps.openDialog, toRemove: {} }));
    },
    openDialog
  };

  const hdlChg = r => ({ target: { name, value } }) => {
    const ns = {
      ...r.rowData,
      id: includesAndChange(r.rowData, name, value, ["codeTypeId", "divisionId"]) ? undefined : r.rowData.id,
      divisionId: includesAndChange(r.rowData, name, value, ["codeTypeId"]) ? undefined : r.rowData.divisionId,
      [name]: value
    };
    const nss = Object.keys(ns).reduce((a, k) => (ns[k] ? { ...a, [k]: ns[k] } : a), {});
    return r.onRowDataChange(nss);
  };

  const col = (t, field, name) => ({
    title: t,
    field,
    type: name === "rate" ? "currency" : undefined,
    validate: d => {
      switch (name) {
        case "codeTypeId":
          return isNumber(d.codeTypeId) || "Type Required";
        case "divisionId":
          return !array(codeDivision).some(cd => cd.codeTypeId === d.codeTypeId) || isNumber(d.divisionId) || "Division Required";
        case "id":
          return isNumber(d.id) || "Code Required";
        default:
          return true;
      }
    },
    editComponent: r => {
      const selectProps = {
        ...{ name, value: r.rowData[name], onChange: hdlChg(r) },
        ...(name === "id"
          ? {
              options: array(storeCodes).filter(
                c =>
                  c.codeTypeId === r.rowData.codeTypeId &&
                  ((!c.divisionId && !r.rowData.divisionId) || c.divisionId === r.rowData.divisionId) &&
                  !codes.some(lc => lc.id === c.id)
              ),
              disabled: !!array(codeDivision).filter(cd => cd.codeTypeId === r.rowData.codeTypeId).length && !r.rowData.divisionId
            }
          : {}),
        ...(name === "divisionId"
          ? { options: array(codeDivision).filter(cd => cd.codeTypeId === r.rowData.codeTypeId), disabled: !r.rowData.codeTypeId }
          : {}),
        ...(name === "codeTypeId" ? { options: codeType } : {})
      };
      if (name === "rate") return <MoneyInput {...selectProps} />;
      selectProps.disabled = selectProps.disabled || !selectProps.options.length || undefined;
      selectProps.error = (!selectProps.disabled && r.helperText) || undefined;
      return <BaseSearchSelect {...selectProps} />;
    }
  });
  const allowed = !viewMode && isAllowedModeEdit;
  const tableProps = {
    columns: [
      col("Type", "codeType", "codeTypeId"),
      col("Division", "codeDivisionName", "divisionId"),
      col("Code Description", "codeName", "id"),
      ...(rateColumn ? [col("Rate", "rate", "rate")] : [])
    ],
    data: codes,
    options: { search: codes.length > 5, exportFileName },
    hidePaging: true,
    editable: allowed
      ? {
          onRowAdd: data => new Promise(res => res(onAdd(data)))
        }
      : {},
    title,
    actions: allowed
      ? [{ icon: DeleteIcon, tooltip: "Delete Code", onClick: (_e, rowData) => setConfirm({ openDialog: true, toRemove: rowData }) }]
      : []
  };

  return (
    <Fragment key="codesFragment">
      <MaterialTable {...tableProps} />
      <Confirm {...confirmConfig} />
    </Fragment>
  );
};

CodeList.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  viewMode: PropTypes.bool,
  title: PropTypes.string,
  isAllowedModeEdit: PropTypes.bool,
  exportFileName: PropTypes.string,
  rateColumn: PropTypes.bool
};

CodeList.defaultProps = {
  viewMode: false,
  onAdd: () => {},
  onDelete: () => {},
  title: undefined,
  isAllowedModeEdit: true,
  exportFileName: "data",
  rateColumn: false
};

export default CodeList;
