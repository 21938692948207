import { CATASTROPHE } from "../actions/actionTypes";
import initialState from "./initialState";

export default function catastropheReducer(state = initialState.catastrophe, action) {
  switch (action.type) {
    case CATASTROPHE.RESET:
      return initialState.catastrophe;
    case CATASTROPHE.GETALL_SUCCESS:
      return { ...state, catastrophes: action.catastrophes };
    case CATASTROPHE.GET_SUCCESS:
      return { ...state, catastrophe: action.catastrophe };
    case CATASTROPHE.POST_SUCCESS:
      return { ...state, catastrophes: action.catastrophes };
    case CATASTROPHE.SET_SUCCESS:
      return { ...state, catastrophe: action.catastrophe };
    case CATASTROPHE.DEL_SUCCESS:
      return { ...state, catastrophes: action.catastrophes };
    case CATASTROPHE.FIND_SUCCESS:
      return { ...state, catastropheDuplicates: action.catastropheDuplicates };
    case CATASTROPHE.ASSOCIATE_CATASTROPHE_SUCCESS:
      return { ...state, catastrophe: action.catastrophe };
    case CATASTROPHE.GET_RESERVES_SUCCESS:
      return { ...state, catastropheReserves: action.catastropheReserves };
    case CATASTROPHE.NOTES.GET_SUCCESS:
    case CATASTROPHE.NOTES.POST_SUCCESS:
    case CATASTROPHE.NOTES.SET_SUCCESS:
    case CATASTROPHE.NOTES.DEL_SUCCESS:
      return { ...state, notes: action.notes };
    case CATASTROPHE.NOTES.GET_DRAFT_SUCCESS:
    case CATASTROPHE.NOTES.POST_DRAFT_SUCCESS:
      return { ...state, draftNote: action.draft };
    case CATASTROPHE.LOAD_FILES_SUCCESS:
      return { ...state, files: action.files };
    case CATASTROPHE.UPLOAD_FILES_SUCCESS:
    case CATASTROPHE.UPDATE_FILE_SUCCESS:
    case CATASTROPHE.DELETE_FILE_SUCCESS:
      return { ...state, catastrophe: { ...state.catastrophe, documents: action.documents } };
    case CATASTROPHE.GET_FILTER_FIELD_SUCCESS:
      return { ...state, filterFields: action.filterFields };
    default:
      return state;
  }
}
