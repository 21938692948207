import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import EditView from "../../../common/claims/damagedVehicle/Form";
import ViewMode from "../../../common/claims/damagedVehicle/Info";
import { set, get } from "../../../../redux/actions/claim/info/damagedVehicle";
import { useRouteParam } from "../../../../common";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";

export default props => {
  const claimId = useRouteParam("claimId");
  const damagedVehicle = useSelector(({ claim: { damagedVehicle: dv } }) => dv || {}, shallowEqual);
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info") || {};
  const {
    props: { boxProps, editMode, loading: isLoading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  const dispatch = useDispatch();
  const handleSubmit = data => {
    showLoading();
    dispatch(set({ ...data, claimId, isDriverOwner: Boolean(data.isDriverOwner) }, onSuccessEdit, hideLoading));
  };
  useEffect(() => {
    if (access.read && (damagedVehicle || {}).claimId !== claimId) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  return (
    <CollapsibleBox title="Damaged Vehicle" name="damagedV" {...boxProps} {...props}>
      {Loading}
      {(editMode && access.update && <EditView {...{ damagedVehicle, onClose: onToggleEdit, onSave: handleSubmit, isLoading }} />) || (
        <ViewMode {...{ damagedVehicle }} />
      )}
    </CollapsibleBox>
  );
};
