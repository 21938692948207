import { Grid, IconButton, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const ViewerTools = ({ actions, hideActions }) => {
  const items = actions.map((a, idx) => ({ ...a, key: a.key || idx + 1 }));
  return (
    <div className="tc-viewer-tools">
      <Grid container spacing={1}>
        {!hideActions &&
          items.map(({ key, icon, tooltip, onClick }) => (
            <Grid item {...{ key }}>
              {(tooltip && (
                <Tooltip title={tooltip}>
                  <IconButton size="small" {...{ onClick }}>
                    {icon}
                  </IconButton>
                </Tooltip>
              )) || (
                <IconButton size="small" {...{ onClick }}>
                  {icon}
                </IconButton>
              )}
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

ViewerTools.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideActions: PropTypes.bool
};

ViewerTools.defaultProps = { hideActions: false };

export default ViewerTools;
