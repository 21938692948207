import React from "react";
import PropTypes from "prop-types";
import { Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  content: { color: "#000000", fontSize: 12 },
  close: { marginRight: -10 },
  actions: { padding: theme.spacing(1, 3, 2.5, 3), "& .MuiButton-root": { lineHeight: 1.5 } }
}));

const Confirm = ({ messageText, title, onAction, openDialog, onToggleConfirm, okText, noText, hideCancel, hideConfirm }) => {
  const classes = useStyles();
  return (
    <Dialog open={openDialog} onClose={onToggleConfirm} PaperComponent={Paper}>
      <DialogContent>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item xs>
            <Typography className="tc-title">{title}</Typography>
          </Grid>
          <Grid item className={classes.close}>
            <IconButton size="small" onClick={onToggleConfirm}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContentText className={classes.content} component={typeof messageText === "string" ? undefined : "div"}>
          {messageText}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {!hideCancel && (
          <Button variant="outlined" autoFocus onClick={onToggleConfirm} color="primary">
            {noText}
          </Button>
        )}
        {!hideConfirm && (
          <Button variant="contained" onClick={onAction} color="primary">
            {okText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

Confirm.propTypes = {
  messageText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onAction: PropTypes.func.isRequired,
  openDialog: PropTypes.bool,
  onToggleConfirm: PropTypes.func.isRequired,
  okText: PropTypes.string,
  noText: PropTypes.string,
  hideCancel: PropTypes.bool,
  hideConfirm: PropTypes.bool
};

Confirm.defaultProps = {
  messageText: "",
  title: "",
  okText: "Delete",
  openDialog: false,
  noText: "Cancel",
  hideCancel: false,
  hideConfirm: false
};

export default Confirm;
