import axios from "axios";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDebounce } from "../../../../common";
import { API } from "../../../../redux/actions/actionTypes";
import { post } from "../../../../redux/actions/claim/wcClaim";
import useYupValidationSchema from "../../../common/customHooks/useYupValidationSchema";
import stateSchema from "../yup";
import initState from "./initState";

const WcClaimAddContext = createContext();
const mapClaimant = c => {
  let params = {};
  if (c.taxIDTypeId && c.taxID) params = { ...params, taxIDTypeId: c.taxIDTypeId, taxID: c.taxID };
  if (c.firstName && c.lastName) params = { ...params, firstName: c.firstName, middleName: c.middleName, lastName: c.lastName };
  return params;
};
const any = arr => arr.some(() => true);
const keys = obj => any(Object.keys(obj));

export const WcClaimAddContextProvider = props => {
  const [state, setState] = useState(initState);
  const [status, setStatus] = useState({ showDocs: false, showDuplicates: false });
  const {
    employee,
    insured: { id: insuredId },
    incident: {
      location: { address, city, dateofLoss, stateId, zipCode }
    },
    validation
  } = state;
  const wcParams = () => {
    let params = {};
    if (insuredId) params = { ...params, insuredId };
    if (stateId) {
      params = { ...params, stateId };
      if (address) params = { ...params, address };
      if (city) params = { ...params, city };
      if (zipCode) params = { ...params, zipCode };
    }
    if (dateofLoss) params = { ...params, dateofLoss };
    if (keys(params)) params = { ...params, isPerson: true };
    const clmnt = mapClaimant(employee);
    if (keys(clmnt)) params.claimants = [clmnt];
    if (keys(params)) return { ...params, isWC: true };
    return {};
  };
  const duplicatesDebounced = useDebounce(data => axios.post(`${API.URL}/claim/duplicates`, data), 1500);
  useEffect(() => {
    const params = wcParams();
    if (keys(params)) duplicatesDebounced(params).then(({ data }) => setState(ps => ({ ...ps, duplicates: data })));
    else setState(ps => ({ ...ps, duplicates: [] }));
  }, [insuredId, address, city, dateofLoss, stateId, zipCode, employee, duplicatesDebounced]);
  const storeDispatch = useDispatch();
  const history = useHistory();
  const { handleValidateSchema, validationMessages: error } = useYupValidationSchema(stateSchema);
  const validateDebounced = useDebounce(ext => handleValidateSchema({ ...state, ...(ext || {}) }), 300);
  useEffect(() => {
    if (validation) validateDebounced();
  }, [validation]);
  const actions = {
    validateDebounced,
    toggleShowDocs: useCallback(() => setStatus(s => ({ ...s, showDocs: !s.showDocs })), []),
    setShowDuplicates: useCallback(d => setStatus(s => ({ ...s, showDuplicates: !!d })), []),
    handleCancel: useCallback(() => history.push("/claims"), []),
    setState,
    handleSave: () => {
      const { isValid } = handleValidateSchema(state);
      if (!isValid) return false;
      const wcClaim = {
        ...Object.keys(state).reduce(
          (a, k) => (["blob", "validation", "fromInitialReport", "employee", "insured"].some(key => key === k) ? a : { ...a, [k]: state[k] }),
          {}
        ),
        insuredId: state.insured.id,
        insuredLevelId: state.insured.insuredLevelId,
        claimAddEmployee: state.employee,
        ...[
          ["typeId", "claimTypeId"],
          ["claimLossTypeGroupId"],
          ["claimLossTypeId"],
          ["claimCauseCodeId"],
          ["dateofLoss"],
          ["dateReported"],
          ["location"],
          ["employeeAccident"],
          ["bodilyInjury"],
          ["reportedBy"],
          ["lateReason"],
          ["contactMethodId"],
          ["stateNumber"],
          ["ediClaimTypeId"]
        ].reduce((a, [p1, p2]) => ({ ...a, [p1]: state.incident[p2 || p1] }), {})
      };
      return storeDispatch(post(wcClaim, actions.handleCancel));
    }
  };
  return <WcClaimAddContext.Provider value={{ actions, error, state, status }} {...props} />;
};

export const useWcClaimAddContext = () => useContext(WcClaimAddContext);

export default WcClaimAddContext;
