import { CLIENTR as CLIENT } from "../../../actionTypes";

export const getPolicies = (insuredId, onSuccess, onError) => ({ type: CLIENT.INSURED.POLICY.GET_POLICIES, insuredId, onSuccess, onError });
export const getPoliciesSuccess = policies => ({ type: CLIENT.INSURED.POLICY.GET_POLICIES_SUCCESS, policies });

export const getPolicy = (id, onSuccess, onError) => ({ type: CLIENT.INSURED.POLICY.GET, id, onSuccess, onError });
export const getPolicySuccess = insuredPolicy => ({ type: CLIENT.INSURED.POLICY.GET_SUCCESS, insuredPolicy });

export const setPolicy = (policy, onSuccess, onError) => ({ type: CLIENT.INSURED.POLICY.SET, policy, onSuccess, onError });
export const setPolicySuccess = insuredPolicy => ({ type: CLIENT.INSURED.POLICY.SET_SUCCESS, insuredPolicy });

export const postPolicy = (policy, onSuccess, onError) => ({ type: CLIENT.INSURED.POLICY.POST, policy, onSuccess, onError });
export const postPolicySuccess = policies => ({ type: CLIENT.INSURED.POLICY.POST_SUCCESS, policies });

export const deletePolicy = (id, insuredId, onSuccess, onError) => ({ type: CLIENT.INSURED.POLICY.DEL, id, insuredId, onSuccess, onError });
export const deletePolicySuccess = policies => ({ type: CLIENT.INSURED.POLICY.DEL_SUCCESS, policies });

export const getPoliciesByPolicyId = policyId => ({ type: CLIENT.INSURED.POLICY.RELATED.GET, policyId });
export const getPoliciesByPolicyIdSuccess = policies => ({ type: CLIENT.INSURED.POLICY.RELATED.GET_SUCCESS, policies });

export const addRelatedPolicy = (policyId, relatedPolicyId, onSuccess, onError) => ({
  type: CLIENT.INSURED.POLICY.RELATED.POST,
  policyId,
  relatedPolicyId,
  onSuccess,
  onError
});
export const addRelatedPolicySuccess = policies => ({ type: CLIENT.INSURED.POLICY.RELATED.POST_SUCCESS, policies });

export const deleteRelatedPolicy = (policyId, relatedPolicyId, onSuccess, onError) => ({
  type: CLIENT.INSURED.POLICY.RELATED.DEL,
  policyId,
  relatedPolicyId,
  onSuccess,
  onError
});
export const deleteRelatedPolicySuccess = policies => ({ type: CLIENT.INSURED.POLICY.RELATED.DEL_SUCCESS, policies });

export const setFinancial = (data, onSuccess, onError) => ({ type: CLIENT.INSURED.POLICY.FINANCIAL.SET, data, onSuccess, onError });
export const setFinancialSuccess = financial => ({ type: CLIENT.INSURED.POLICY.FINANCIAL.SET_SUCCESS, financial });
