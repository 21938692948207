import React, { Fragment } from "react";
import { Grid, Button, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  }
}));

const FormFooter = ({ onCancel, isAdd, type, editMode, cancelText, saveBtnDisabled, ...rest }) => {
  const classes = useStyles();
  return (
    <Fragment key="footer-logic">
      <Grid container alignItems="flex-start" justify="flex-end" direction="row" className="print-hide">
        <div className={classes.root}>
          {(!editMode && (
            <>
              <Button variant="outlined" color="primary" onClick={onCancel}>
                {cancelText}
              </Button>
            </>
          )) || (
            <>
              <Button variant="outlined" color="primary" onClick={onCancel}>
                {cancelText}
              </Button>
              <Button type={type} variant="contained" color="primary" {...{ ...rest }} disabled={saveBtnDisabled}>
                {(!isAdd && "SAVE") || "ADD"}
              </Button>
            </>
          )}
        </div>
      </Grid>
    </Fragment>
  );
};

FormFooter.propTypes = {
  onCancel: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  isAdd: PropTypes.bool,
  type: PropTypes.string,
  editMode: PropTypes.bool,
  saveBtnDisabled: PropTypes.bool
};

FormFooter.defaultProps = {
  type: "submit",
  cancelText: "CANCEL",
  isAdd: false,
  editMode: false,
  saveBtnDisabled: false
};

export default FormFooter;
