import React from "react";
import { Grid, Container, Typography, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import AccessDenied from "../assets/access_denied.svg";
import AccessDeniedBg from "../assets/access_denied_background.png";
import useStyles from "./PageNotAllowed.styles";

const PageNotAllowed = () => {
  const classes = useStyles(AccessDeniedBg);

  return (
    <Container maxWidth={false} className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="center" spacing={3} className={classes.container}>
        <Grid item xs={1} />
        <Grid item xs>
          <Typography variant="h3" gutterBottom className={classes.title}>
            Access denied
          </Typography>
          <Typography variant="subtitle1" gutterBottom className={classes.subtitle}>
            You do not have permission to access to this section.
            <br />
            {`Please, `}
            <b>request access to the administrator.</b>
          </Typography>
          <Button variant="contained" color="primary" className={classes.btnHomePage} component={RouterLink} to="/">
            BACK TO MAIN SECTION
          </Button>
        </Grid>
        <Grid item xs>
          <AccessDenied className={classes.accessDeniedImage} />
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Container>
  );
};

export default PageNotAllowed;
