import PropTypes from "prop-types";
import React, { createContext, useReducer, useEffect, memo } from "react";
import { childrenPropType } from "../../../common";

export const PropertyContext = createContext();

const initialState = {
  isClaimMode: false,
  isPropertyLiability: false,
  damaged: {},
  property: { location: {}, mortgageCompany: { location: {} } },
  insProperties: []
};

const popProperty = (state, p) => {
  const iProp = initialState.property;
  const property = { ...p, location: p.location || iProp.location, mortgageCompany: p.mortgageCompany || iProp.mortgageCompany };
  if (!property.mortgageCompany.location) property.mortgageCompany.location = iProp.mortgageCompany.location;
  return { ...state, damaged: state.damaged ? { ...state.damaged, isClaimantMortgageCompany: false } : {}, property };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_PROPERTY":
      return { ...state, property: action.property };
    case "SET_PROPERTY": {
      if (action.payload.name === "id") {
        const property = state.insProperties.find(x => x.id === action.payload.value) || initialState.property;
        return popProperty(state, property);
      }
      return {
        ...state,
        property: { ...state.property, [action.payload.name]: action.payload.value },
        damaged: {
          ...state.damaged,
          isClaimantMortgageCompany: action.payload.name === "hasMortgage" && !action.payload.value ? false : state.damaged.isClaimantMortgageCompany
        }
      };
    }
    case "SET_LOCATION":
      return { ...state, property: { ...state.property, location: { ...state.property.location, [action.payload.name]: action.payload.value } } };
    case "SET_MORTGAGE_COMPANY":
      return {
        ...state,
        property: { ...state.property, mortgageCompany: { ...state.property.mortgageCompany, [action.payload.name]: action.payload.value } }
      };
    case "SET_MORTGAGE_COMPANY_LOCATION":
      return {
        ...state,
        property: {
          ...state.property,
          mortgageCompany: {
            ...state.property.mortgageCompany,
            location: { ...state.property.mortgageCompany.location, [action.payload.name]: action.payload.value }
          }
        }
      };
    case "LOAD_DAMAGED":
      return { ...state, damaged: action.damaged };
    case "SET_DAMAGED":
      return { ...state, damaged: { ...state.damaged, [action.payload.name]: action.payload.value } };
    case "SET_ISCLAIMMODE":
      return { ...state, isClaimMode: action.isClaimMode, isPropertyLiability: !!action.isPropertyLiability };
    case "SET_HASMORGAGE":
      return { ...state, hasMortgageCompany: action.hasMortgageCompany };
    case "SET_INITIAL_STATE":
      return initialState;
    case "SET_INS_PROPERTIES":
      return { ...state, insProperties: Array.isArray(action.insProperties) ? action.insProperties : initialState.insProperties };
    default:
      return state;
  }
};

const PropertyContextProvider = memo(({ children, isEdit, readMode, initialData, isClaimMode, isPropertyLiability }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (isEdit || readMode) {
      if (initialData?.property?.location || initialData?.location)
        dispatch({ type: "LOAD_PROPERTY", property: initialData?.property || initialData });
      if (initialData?.damaged) {
        dispatch({ type: "LOAD_DAMAGED", damaged: initialData?.damaged });
        dispatch({ type: "SET_ISCLAIMMODE", isClaimMode: true, isPropertyLiability });
      }
    } else {
      dispatch({ type: "SET_INITIAL_STATE" });
      dispatch({ type: "SET_ISCLAIMMODE", isClaimMode, isPropertyLiability });
    }
    dispatch({ type: "SET_INS_PROPERTIES", insProperties: initialData.insProperties });
    return () => dispatch({ type: "SET_INITIAL_STATE" });
  }, [initialData, isEdit]);

  const values = { state, dispatch };

  return <PropertyContext.Provider value={values}>{children}</PropertyContext.Provider>;
});

PropertyContextProvider.propTypes = {
  isEdit: PropTypes.bool,
  readMode: PropTypes.bool,
  children: childrenPropType().isRequired,
  initialData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]))
    .isRequired,
  isClaimMode: PropTypes.bool,
  isPropertyLiability: PropTypes.bool
};

PropertyContextProvider.defaultProps = { isEdit: false, readMode: false, isClaimMode: false, isPropertyLiability: false };

export default PropertyContextProvider;
