import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import moment from "moment";
import LabelValue from "../../LabelValue";

const EmploymentViewMode = ({ employment }) => {
  const {
    jurisdictionState,
    payrollClassCodeType,
    hireDate,
    daysWorkedWeek,
    ratePerTypeId,
    ratePerType,
    ratePerDescription,
    tpaOfficeNumber,
    employmentStatus,
    fullPayInjuryDay,
    salaryContinued,
    occupationDescription
  } = employment || {};
  const yearsTenure = moment().diff(moment(hireDate), "years") > 0 ? `${moment().diff(moment(hireDate), "years")} year(s)` : undefined;
  const monthsTenure = moment().diff(moment(hireDate), "months") > 0 ? `${moment().diff(moment(hireDate), "months")} month(s)` : undefined;
  return (
    <form style={{ padding: "10px" }}>
      <Grid container spacing={4}>
        <Grid item xs>
          <LabelValue label="Jurisdiction State" value={jurisdictionState} />
          <LabelValue label="Hire Date" value={hireDate} isDate />
          <LabelValue label="Tenure" value={yearsTenure || monthsTenure || ""} />
        </Grid>
        <Grid item xs>
          <LabelValue label="Payroll Class Code" value={payrollClassCodeType} />
          <LabelValue label="Rate Per" value={ratePerType} />
          {ratePerTypeId === 4 && <LabelValue label="Rate Per Description" value={ratePerDescription} />}
        </Grid>

        <Grid item xs>
          <LabelValue label="Days Worked/Week" value={daysWorkedWeek} />
          <LabelValue label="Employment Status" value={employmentStatus} />
          <LabelValue label="TPA Office #" value={tpaOfficeNumber} />
        </Grid>
        <Grid item xs>
          <LabelValue label="Full Pay For Day of Injury?" value={fullPayInjuryDay ? "YES" : "NO"} />
          <LabelValue label="Did Salary Continued?" value={salaryContinued ? "YES" : "NO"} />
        </Grid>
        <Grid item xs={12}>
          <LabelValue label="Occupation Description" value={occupationDescription} />
        </Grid>
      </Grid>
    </form>
  );
};

EmploymentViewMode.propTypes = {
  employment: PropTypes.objectOf(PropTypes.any).isRequired
};

export default EmploymentViewMode;
