import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Folder, MoreVert } from "@material-ui/icons";
import { Grid, Typography, Menu, MenuItem, TextField, Badge as BaseBadge, withStyles } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { useDocumentFolderStyles } from "./hooks";

const Badge = withStyles(() => ({
  badge: { height: 32, minWidth: 32, borderRadius: 16, fontSize: 14 },
  anchorOriginBottomRightRectangle: { bottom: 18 }
}))(BaseBadge);

const DocumentFolder = ({
  folder,
  forwardRef,
  openFolder,
  onRename,
  onDelete,
  onDownload,
  onAddFileToFolder,
  onDrop,
  path,
  validateFile,
  actionsAllowed
}) => {
  const classes = useDocumentFolderStyles();
  const [state, setState] = useState({ menu: null, name: "", rename: false, hover: false, errorDrag: false });
  const { menu, name, rename, hover, errorDrag } = state;
  const handleMenu = event => setState({ ...state, rename: false, menu: event.currentTarget });
  const handleCloseMenu = () => setState({ ...state, menu: null });
  const countFiles = fldr => {
    let count = fldr.fileList.length;
    fldr.folderList.forEach(f => {
      count += countFiles(f);
    });
    return count;
  };
  const filesCount = countFiles(folder);
  const onHandleRename = () => setState({ ...state, name: folder.name, rename: true, menu: null });
  const onChange = ({ target: { value } }) => setState({ ...state, name: value });
  const onSave = event => {
    event.preventDefault();
    onRename({ ...folder, name });
  };
  useEffect(() => {
    setState({ ...state, rename: false });
  }, [folder]);
  const onCancelRename = ({ keyCode }) => keyCode !== 27 || setState({ ...state, rename: false });
  const onHandleDelete = () => {
    setState({ ...state, rename: false, menu: null });
    onDelete(folder);
  };
  const onAddFile = event => {
    setState({ ...state, menu: null });
    onAddFileToFolder(folder.id)(event);
  };
  const containerProps = actionsAllowed?.create
    ? {
        onDragOver: event => {
          event.preventDefault();
          event.stopPropagation();
          setState({ ...state, hover: true, errorDrag: [...event.dataTransfer.items].some(i => !validateFile(i)) });
        },
        onDragLeave: () => setState({ ...state, hover: false, errorDrag: false }),
        onDrop: event => {
          containerProps.onDragLeave();
          return onDrop(folder)(event);
        },
        className: clsx(classes.root, hover ? classes.hover : null, hover && errorDrag ? classes.error : null)
      }
    : { className: clsx(classes.root, hover ? classes.hover : null, hover && errorDrag ? classes.error : null) };
  const onHandleDownload = () => {
    setState({ ...state, menu: null });
    onDownload([{ id: folder.id, file: false }]);
  };
  const icon = <Folder className={classes.folder} viewBox="2 6 20 16" onClick={() => openFolder(folder)} />;
  const isClaim = path === "claimId";
  return (
    <div {...containerProps}>
      <div className={classes.container}>
        {(folder.isQueue && (
          <Badge anchorOrigin={{ vertical: "bottom", horizontal: "right" }} badgeContent={filesCount} color="primary">
            {icon}
          </Badge>
        )) ||
          icon}
        {!folder.isQueue && (
          <>
            <MoreVert className={classes.actions} viewBox="4 4 4 16" onClick={handleMenu} />
            <Menu anchorEl={menu} keepMounted open={Boolean(menu)} onClose={handleCloseMenu}>
              {actionsAllowed?.create && <MenuItem onClick={onAddFile}>Add File</MenuItem>}
              {filesCount > 0 && <MenuItem onClick={onHandleDownload}>Download</MenuItem>}
              {onRename && <MenuItem onClick={onHandleRename}>Rename</MenuItem>}
              {onDelete && <MenuItem onClick={onHandleDelete}>Delete</MenuItem>}
            </Menu>
          </>
        )}
      </div>
      {!rename && (
        <Grid container wrap="nowrap" className={classes.nameContainer} onClick={() => openFolder(folder)}>
          <Grid item className={clsx({ [classes.name]: !folder.isQueue })}>
            {folder.name}
          </Grid>
          {!folder.isQueue && <Grid item>{`(${filesCount})`}</Grid>}
        </Grid>
      )}
      {rename && (
        <form onSubmit={onSave}>
          <TextField fullWidth value={name} {...{ onChange }} onKeyUp={onCancelRename} ref={forwardRef} />
        </form>
      )}
      {!folder.isQueue && (!isClaim || filesCount > 0) && <Typography variant="caption">{moment(folder.lastActivity).format("M/D/YYYY")}</Typography>}
    </div>
  );
};

DocumentFolder.propTypes = {
  folder: PropTypes.objectOf(PropTypes.any).isRequired,
  path: PropTypes.string.isRequired,
  forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })]).isRequired,
  openFolder: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func.isRequired,
  onRename: PropTypes.func,
  onAddFileToFolder: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  validateFile: PropTypes.func.isRequired,
  actionsAllowed: PropTypes.shape({}).isRequired
};

DocumentFolder.defaultProps = {
  onDelete: null,
  onRename: null
};

export default DocumentFolder;
