import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import EmploymentInfo from "../../../common/claims/employment/ViewMode";
import EmploymentForm from "../../../common/claims/employment/Form";
import { set, loadByClaimId } from "../../../../redux/actions/claim/info/employment";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import { useRouteParam } from "../../../../common";

export default props => {
  const claimId = useRouteParam("claimId");
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info") || {};
  const {
    props: { boxProps, editMode, Loading, loading },
    actions: { hideLoading, showLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  const store = useSelector(
    ({
      claim: {
        lastClaimId,
        wcClaim: { employment }
      }
    }) => ({ lastClaimId, employment }),
    shallowEqual
  );
  const { lastClaimId, employment } = store;
  const dispatch = useDispatch();
  const handleSubmit = data => {
    showLoading();
    dispatch(set(data, onSuccessEdit, hideLoading));
  };
  useEffect(() => {
    const accessToOverview = routesAllowed.find(elem => elem.route === "overview");
    if (access.read && ((!employment?.id && !accessToOverview.read) || (lastClaimId && lastClaimId !== claimId))) {
      showLoading();
      dispatch(loadByClaimId(claimId, hideLoading, hideLoading));
    }
  }, [employment]);
  return (
    <CollapsibleBox title="Employment" name="employment" {...boxProps} {...props}>
      {Loading}
      {(editMode && access.update && (
        <EmploymentForm {...{ employment, claimId, onClose: onToggleEdit, onSave: handleSubmit, isLoading: loading }} />
      )) || <EmploymentInfo {...{ employment }} />}
    </CollapsibleBox>
  );
};
