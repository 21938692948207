import React from "react";
import { Grid, makeStyles, Paper, Avatar } from "@material-ui/core";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import { deepOrange } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import Read from "../profile/basic/Read";
import useCustomConfirm from "../../common/customHooks/useCustomConfirm";

const useStyles = makeStyles(theme => ({
  rootContainer: {
    padding: "1rem 1.5rem",
    marginBottom: theme.spacing(2)
  },
  rowButton: {
    paddingTop: "17px",
    paddingBottom: "7px"
  },
  container: {
    // width: "1340px"
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500]
  },
  avatar: {
    width: "32px",
    height: "32px"
  },
  formView: {
    paddingBottom: "30px",
    "& hr": {
      margin: "10px 0px 10px 0px"
    }
  },
  title: {},
  root: {
    color: "#898F91",
    fontSize: "11px",
    paddingTop: "10px"
  },
  value: {
    color: "#000000",
    fontSize: "0.875rem"
  }
}));

const Location = ({ data, index, onEdit, onRemove, actionsAllowed }) => {
  const handleDelete = location => {
    onRemove(location.id);
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleDelete, "Cancel", "Yes, Delete");
  const handleConfirm = location => {
    handleCustomConfirm({
      oData: location,
      titleText: `Delete Location`,
      messageText: (
        <>
          <p>The following location is going to be deleted:</p>
          <p>
            <b>{location.fullName}</b>
          </p>
          <p>Are you sure you want to proceed?</p>
        </>
      )
    });
  };
  const classes = useStyles();
  return (
    <Paper className={classes.rootContainer}>
      <Grid container justify="flex-end">
        <Grid item>
          {actionsAllowed.update && <EditIcon onClick={() => onEdit(data)} />}
          {actionsAllowed.delete && <DeleteIcon onClick={() => handleConfirm(data)} />}
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item>
          <Avatar className={classes.avatar}>{index}</Avatar>
        </Grid>
        <Grid item xs>
          <Read basicInformation={data} />
        </Grid>
      </Grid>
      <Confirm />
    </Paper>
  );
};

Location.propTypes = {
  data: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  actionsAllowed: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Location;
