import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import claim from "./claim";
import apiCallsInProgress from "./apiStatusReducer";
import common from "./commonReducer";
import catastrophe from "./catastrophe";
import client from "./client";
import vendor from "./vendor";
import occurrence from "./occurrence";
import initialReport from "./initialReport";
import admin from "./admin";
import profile from "./profile";
import automation from "./automation";
import documents from "./documents";
import dashboard from "./dashboard";
import financial from "./financial";
import notification from "./notification";

const rootReducer = history =>
  combineReducers({
    claim,
    apiCallsInProgress,
    common,
    catastrophe,
    client,
    vendor,
    occurrence,
    initialReport,
    admin,
    profile,
    automation,
    documents,
    dashboard,
    financial,
    notification,
    router: connectRouter(history)
  });

export default rootReducer;
