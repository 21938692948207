/* eslint-disable redux-saga/no-unhandled-errors */
import { put, takeLatest as sagaTakeLatest, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { VENDOR, API } from "../../actions/actionTypes";
import { api } from "../../../api/apiUtils";
import { apiCallError, beginApiCall } from "../../actions/apiStatusActions";
import {
  loadVendorsSuccess,
  loadVendorSuccess,
  setVendorSuccess,
  addCodeSuccess,
  deleteCodeSuccess,
  loadContactsSuccess,
  postContactSuccess,
  setContactSuccess,
  delContactSuccess,
  addVendorSuccess,
  loadFilesSuccess,
  loadPaymentsSuccess,
  getClaimsInvolvedSuccess,
  getFinancialInfoSuccess,
  getLocationsSuccess,
  postLocationsSuccess,
  setLocationsSuccess,
  delLocationsSuccess,
  getLocationSuccess,
  cleanLocationSuccess,
  delVendorSuccess,
  loadPendingVendorsSuccess,
  setStatusSuccess,
  approveSuccess,
  rejectSuccess
} from "../../actions/vendor";
import * as docs from "../common/documents";
import rootSagaGenerator from "../common/rootSagaGenerator";
import sagaGenerator, { getErrorMessage, takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "../common/sagaGenerator";

const approveSaga = approve =>
  takeLatestPost({
    type: approve ? VENDOR.APPROVE : VENDOR.REJECT,
    actionSuccess: approve ? approveSuccess : rejectSuccess,
    successMessage: `Vendor successfuly ${approve ? "approved" : "rejected"}. Incoming bill moved to re-indexing queue.`,
    errorMessage: `Unable to ${approve ? "approve" : "reject"} vendor`,
    url: `${API.URL}/vendor/approve`,
    params: ({ id }) => ({ id, approve })
  });

export default rootSagaGenerator({
  name: "Vendor",
  sagas: [
    sagaTakeLatest(
      VENDOR.LOAD_VENDORS,
      sagaGenerator({
        actionSuccess: loadVendorsSuccess,
        errorMessage: "Unable to load vendors",
        url: `${API.URL}/vendor`
      })
    ),
    sagaTakeLatest(
      VENDOR.GET_PENDING,
      sagaGenerator({
        actionSuccess: loadPendingVendorsSuccess,
        errorMessage: "Unable to load pending investigation vendors",
        url: `${API.URL}/vendor?pending=true`
      })
    ),
    sagaTakeLatest(
      VENDOR.LOCATIONS.GET,
      sagaGenerator({
        actionSuccess: getLocationsSuccess,
        errorMessage: "Error loading Locations",
        url: `${API.URL}/vendor/locations`,
        params: ({ parentId }) => ({ parentId })
      })
    ),
    sagaTakeLatest(
      VENDOR.LOCATIONS.DEL,
      sagaGenerator({
        actionSuccess: delLocationsSuccess,
        successMessage: "Location successfully deleted",
        errorMessage: "Unable to delete the location",
        url: `${API.URL}/vendor/delLocation`,
        method: "DELETE",
        params: ({ location }) => location
      })
    ),
    sagaTakeLatest(
      VENDOR.DEL,
      sagaGenerator({
        actionSuccess: delVendorSuccess,
        successMessage: "Vendor successfully deleted",
        errorMessage: "Unable to delete vendor",
        url: `${API.URL}/vendor`,
        method: "DELETE",
        params: ({ id }) => ({ id })
      })
    ),
    sagaTakeLatest(
      VENDOR.LOCATIONS.POST,
      sagaGenerator({
        actionSuccess: postLocationsSuccess,
        successMessage: "Location successfully added",
        errorMessage: "Unable to add the location",
        url: `${API.URL}/vendor/addLocation`,
        method: "POST",
        params: ({ location }) => location
      })
    ),
    sagaTakeLatest(
      VENDOR.LOCATIONS.SET,
      sagaGenerator({
        actionSuccess: setLocationsSuccess,
        successMessage: "Location successfully updated",
        errorMessage: "Unable to update the location",
        url: `${API.URL}/vendor/setLocation`,
        method: "PUT",
        params: ({ location }) => location
      })
    ),
    sagaTakeLatest(VENDOR.LOCATIONS.CLEAN, function* clearLocation() {
      try {
        yield put(cleanLocationSuccess());
      } catch (err) {
        yield apiCallError(err);
        yield call(toast.error, "Error cleaning location");
      }
    }),
    takeLatest({
      type: VENDOR.LOAD,
      url: `${API.URL}/vendor/profile`,
      params: ({ id }) => ({ id }),
      onSuccess: function* getOnSuccess({ vendor }) {
        if (vendor.basicInformation?.parentId === null) {
          yield put(loadVendorSuccess(vendor));
        } else {
          yield put(getLocationSuccess(vendor));
        }
      },
      payloadPath: "vendor",
      errorMessage: "Unable to load the profile"
    }),
    sagaTakeLatest(
      VENDOR.POST_CONTACT,
      sagaGenerator({
        actionSuccess: postContactSuccess,
        onSuccess: () => toast.success("Contact successfully created"),
        onError: ({ err }) => toast.error(getErrorMessage(err.response.status, "Unable to add the contact")),
        url: `${API.URL}/vendor/addcontact`,
        method: "POST",
        params: ({ data }) => data
      })
    ),
    sagaTakeLatest(
      VENDOR.SET_CONTACT,
      sagaGenerator({
        actionSuccess: setContactSuccess,
        onSuccess: () => toast.success("Contact successfully updated"),
        onError: ({ err }) => toast.error(getErrorMessage(err.response.status, "Unable to update the contact")),
        url: `${API.URL}/vendor/setcontact`,
        method: "PUT",
        params: ({ data }) => data
      })
    ),
    sagaTakeLatest(
      VENDOR.DEL_CONTACT,
      sagaGenerator({
        actionSuccess: delContactSuccess,
        onSuccess: () => toast.success("Contact successfully deleted"),
        onError: ({ err }) => toast.error(getErrorMessage(err.response.status, "Unable to delete the contact")),
        url: `${API.URL}/vendor/delcontact`,
        method: "DELETE",
        params: ({ data }) => data
      })
    ),
    takeLatestSet({
      type: VENDOR.SET,
      url: `${API.URL}/vendor`,
      params: ({ data }) => data,
      actionSuccess: setVendorSuccess,
      successMessage: "Basic Information successfully saved.",
      errorMessage: "Unable to save the information."
    }),
    sagaTakeLatest(VENDOR.ADD, function* addVendor({ data, onSuccess, onError }) {
      try {
        yield put(beginApiCall());
        const vendors = yield call(api, { method: "POST", url: `${API.URL}/vendor`, data });
        yield put(addVendorSuccess(vendors));
        yield call(toast.success, "Vendor successfully created.");
        yield call(onSuccess);
      } catch (err) {
        yield apiCallError(err);
        yield call(toast.error, getErrorMessage(err.response.status, "Unable to create the vendor."));
        yield call(onError);
      }
    }),
    takeLatestPost({
      type: VENDOR.ADD_CODE,
      url: `${API.URL}/vendor/code`,
      params: ({ vendorId, codeId }) => ({ vendorId, codeId }),
      actionSuccess: addCodeSuccess,
      successMessage: "Code successfully added.",
      errorMessage: "Unable to add the code."
    }),
    takeLatestDel({
      type: VENDOR.DELETE_CODE,
      url: `${API.URL}/vendor/code`,
      params: ({ vendorId, codeId }) => ({ vendorId, codeId }),
      actionSuccess: deleteCodeSuccess,
      successMessage: "Code successfully deleted.",
      errorMessage: "Unable to delete the code."
    }),
    sagaTakeLatest(
      VENDOR.LOAD_CONTACTS,
      sagaGenerator({
        actionSuccess: loadContactsSuccess,
        errorMessage: "Error loading contacts",
        url: `${API.URL}/vendor/getcontacts`,
        params: ({ parentId }) => ({ parentId })
      })
    ),
    sagaTakeLatest(
      VENDOR.LOAD_PAYMENTS,
      sagaGenerator({
        actionSuccess: loadPaymentsSuccess,
        onError: ({ err }) => toast.error(getErrorMessage(err.response.status, "Unable to load the payments.")),
        url: `${API.URL}/vendor/payments`,
        params: ({ vendorId }) => ({ vendorId })
      })
    ),
    sagaTakeLatest(VENDOR.GET_CLAIMSINVOLVED, function* getClaimsInvolved({ vendorId }) {
      try {
        yield put(beginApiCall());
        const claimsinvolved = yield call(api, { url: `${API.URL}/vendor/claimsinvolved`, data: { vendorId } });
        yield put(getClaimsInvolvedSuccess(claimsinvolved));
      } catch (err) {
        yield apiCallError(err);
        yield call(toast.error, getErrorMessage(err.response.status, "Unable to load claims involved."));
      }
    }),
    sagaTakeLatest(VENDOR.GET_FINANCIALINFO, function* getFinancialInfo({ vendorId }) {
      try {
        yield put(beginApiCall());
        const financialInfo = yield call(api, { url: `${API.URL}/vendor/financialinfo`, data: { vendorId } });
        yield put(getFinancialInfoSuccess(financialInfo));
      } catch (err) {
        yield apiCallError(err);
        yield call(toast.error, getErrorMessage(err.response.status, "Unable to load financial info."));
      }
    }),
    takeLatest({
      type: VENDOR.LOAD_FILES,
      url: `${API.URL}/vendor/files`,
      params: ({ id }) => ({ id }),
      actionSuccess: loadFilesSuccess,
      errorMessage: "Unable to load load files."
    }),
    sagaTakeLatest(VENDOR.UPLOAD_FILES, docs.uploadFiles(VENDOR.UPLOAD_FILES_SUCCESS)),
    sagaTakeLatest(VENDOR.UPDATE_FILE, docs.updateFile(VENDOR.UPDATE_FILE_SUCCESS)),
    sagaTakeLatest(VENDOR.DELETE_FILE, docs.deleteFile(VENDOR.DELETE_FILE_SUCCESS)),
    sagaTakeLatest(VENDOR.DOWNLOAD_FILES, docs.downloadFiles(VENDOR.DOWNLOAD_FILES_SUCCESS)),
    sagaTakeLatest(VENDOR.OPEN_FILE, docs.openFile(VENDOR.OPEN_FILE_SUCCESS)),
    takeLatestSet({
      type: VENDOR.SET_STATUS,
      actionSuccess: setStatusSuccess,
      errorMessage: "Unable to set Vendor Status",
      url: `${API.URL}/vendor/status`,
      params: ({ data }) => data
    }),
    approveSaga(true),
    approveSaga(false)
  ]
});
