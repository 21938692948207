import { useSelector, shallowEqual } from "react-redux";

const useCommonSelect = list => {
  const selects = useSelector(({ common }) => {
    return list.map(item => common[item]);
  }, shallowEqual);
  return selects;
};

export default useCommonSelect;
