import React from "react";
import Routes from "./Routes";

const Dashboard = () => {
  const weburl = `${process.env.WEB_URL}`;
  const inInTestEnv = weburl === "https://testmanagement.terraclaim.com/index.html";
  /* const inInLocalEnv = weburl === "http://localhost:3000"; */
  return inInTestEnv ? (
    <iframe
      style={{ height: "973px", width: "1808px", border: "none" }}
      title="terraClaim"
      src="https://xd.adobe.com/embed/b2fe9442-36d0-42ff-bcc8-2ef54f1b6991-e7ad/"
    />
  ) : (
    <Routes />
  );
};

export default Dashboard;
