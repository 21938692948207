import React, { useState, useEffect } from "react";
import { Paper, Grid, Collapse, Divider, MenuItem, IconButton, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import axios from "axios";
import clsx from "clsx";
import { useContext, actionTypes } from "../context";
import { API } from "../../../../redux/actions/actionTypes";
import LabelValue from "../../../common/LabelValue";
import Search from "../../../common/SearchSuggestions/InputSuggestionsDebounced";
import CustomSelect from "../../../common/inputs/CustomSelect";
import { useInsuredStyles } from "./index.styles";

export default () => {
  const classes = useInsuredStyles();
  const [
    { incident, insured, insVehicles, insProperties },
    dispatch,
    ,
    {
      step1: { validationMessages: error },
      handleValidation
    }
  ] = useContext();
  const [{ number, name, policyNo, children, loaded }, setState] = useState(insured || {});
  const { location, mailingLocation, phone1, type: iType, sizeTypeName, child } = insured || {};
  const { id: childId, text: childTxt, location: cLocation, phone1: cPhone1, levelId, description } = child || {};
  const getEntity = (url, type) =>
    axios
      .get(`${API.URL}/${url}/insured`, { params: { insuredId: insured.id, dateOfLoss: incident.dateofLoss } })
      .then(({ data }) => dispatch({ type, payload: { insuredId: insured.id, data, dateofLoss: incident.dateofLoss } }));
  useEffect(() => {
    if (loaded) handleValidation();
    setState({ ...insured, loaded: true, children: Array.isArray(insured.children) ? [...insured.children] : [] });
    if (!insured.id) return;
    if (insured.id !== insVehicles.insuredId || incident.dateofLoss !== insVehicles.dateofLoss) getEntity("vehicle", actionTypes.SET_INS_VEHICLES);
    if (insured.id !== insProperties.insuredId || incident.dateofLoss !== insProperties.dateofLoss)
      getEntity("property", actionTypes.SET_INS_PROPERTIES);
  }, [insured]);
  const handleChange = ({ target: { name: field, value } }) => setState(ps => ({ ...ps, [field]: value }));
  const onSelect = op => dispatch({ type: actionTypes.SET_INSURED, payload: op });
  const formatOption = (option, parent = null, level = 0, txt = "") => {
    const chdrn = Array.isArray(option.children) ? option.children : [];
    const any = chdrn.some(() => true);
    const text = `${txt}${level > 0 ? " - " : ""}${option.name}`;
    // 5	Divisions
    // 6	Locations
    // 7	Areas
    const onClick = () =>
      onSelect({
        ...insured,
        child: {
          ...option,
          location: option.levelId < 7 ? option.location : (parent || {}).location,
          phone1: option.levelId < 7 ? option.phone1 : (parent || {}).phone1,
          text
        }
      });
    const onToggle = event => {
      event.preventDefault();
      event.stopPropagation();
      const map = p => (p.id === option.id ? { ...p, close: !p.close } : { ...p, children: p.children.map(map) });
      return setState(ps => ({ ...ps, children: ps.children.map(map) }));
    };
    return (
      <div key={option.id}>
        <MenuItem key={option.id} selected={childId === option.id} {...{ onClick }}>
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item className={clsx(classes.arrow, { [classes.open]: !option.close })} style={{ marginLeft: 24 * level }}>
              {any && (
                <IconButton size="small" onClick={onToggle}>
                  <ExpandMore />
                </IconButton>
              )}
            </Grid>
            <Grid item>{`${option.level}: ${option.name}`}</Grid>
          </Grid>
        </MenuItem>
        {any && <Collapse in={!option.close}>{chdrn.map(c => formatOption(c, parent || option, level + 1, text))}</Collapse>}
      </div>
    );
  };
  const container = p => <div className={classes.search} {...p} />;
  const url = `${API.URL}/insured/find?children=true`;
  const anyChild = Array.isArray(children) && children.some(() => true);
  const formatValue = childId ? () => childTxt || "" : null;
  const onClear = () => onSelect({ ...insured, child: undefined });
  return (
    <Paper className={classes.root}>
      <Typography className="tc-title" gutterBottom>
        Insured Information
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs>
          <Search name="number" value={number} label="Insured #" onChange={handleChange} {...{ onSelect, url }} />
          <Search name="name" value={name} label="Insured Name" onChange={handleChange} {...{ onSelect, url }} error={(error || {})["insured.id"]} />
          <Search name="policyNo" value={policyNo} label="Policy #" onChange={handleChange} {...{ onSelect, url }} />
        </Grid>
        <Grid item xs>
          <LabelValue isShortAddress label="Physical Address" value={location} />
          <LabelValue label="Insured Phone" value={phone1} />
          <LabelValue isShortAddress label="Mailing Address" value={mailingLocation} />
        </Grid>
        <Grid item xs>
          <LabelValue label="Insured Type" value={iType} />
          <LabelValue label="Insured Size" value={sizeTypeName} />
        </Grid>
      </Grid>
      <Collapse in={anyChild}>
        <Divider className={classes.divider} />
        <Typography className="tc-title" gutterBottom>
          Insured Structure
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <CustomSelect label="Level" name="level" value={childId} options={children} {...{ formatOption, formatValue, container, onClear }} />
          </Grid>
          {child && (
            <Grid item xs={8}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <LabelValue isShortAddress label="Physical Address 1" value={cLocation} />
                </Grid>
                <Grid item xs={6}>
                  <LabelValue label="Phone Address 1" value={cPhone1} />
                </Grid>
              </Grid>
              {levelId === 7 && <LabelValue label="Description" value={description} />}
            </Grid>
          )}
        </Grid>
      </Collapse>
    </Paper>
  );
};
