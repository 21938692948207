import { CLAIM } from "../../actionTypes";

export const loadByClaimId = (claimId, onSuccess, onError) => ({ type: CLAIM.INFO.CLAIMANT.GET, claimId, onSuccess, onError });
export const loadByClaimIdSuccess = claimant => ({ type: CLAIM.INFO.CLAIMANT.GET_SUCCESS, claimant });
export const set = (claimant, claimId, onSuccess, onError) => ({ type: CLAIM.INFO.CLAIMANT.SET, claimant, claimId, onSuccess, onError });
export const setSuccess = claimant => ({ type: CLAIM.INFO.CLAIMANT.SET_SUCCESS, claimant });

export const setData = claimant => {
  const { isPerson, phisicalAsMailing, phAddress, mailAddress } = claimant;
  let data = {
    isPerson,
    id: claimant.id,
    taxID: claimant.taxID,
    taxIDTypeId: claimant.taxIDTypeId,
    mobile: claimant.mobile,
    workPhone: claimant.workPhone,
    workEmail: claimant.workEmail,
    email: claimant.email,
    location: phAddress,
    phisicalAsMailing,
    mailingLocation: phisicalAsMailing ? phAddress : mailAddress
  };
  if (isPerson) {
    const { firstName, middleName, lastName, dateofBirth, genderId, maritalStatusId, dependents, phone } = claimant;
    data = { ...data, firstName, middleName, lastName, dateofBirth, genderId, maritalStatusId, dependents, phone };
  } else {
    const { commercialName, contact } = claimant;
    data = { ...data, commercialName, contact };
  }
  return data;
};
