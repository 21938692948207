import { FormControl, FormHelperText, InputLabel, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, memo } from "react";
import { HocInput } from "./HocInput";
// eslint-disable-next-line import/no-named-as-default
import Editor from "../editor/Editor";

const useStyles = height =>
  makeStyles(
    theme => {
      const afterBefore = {
        left: 0,
        right: 0,
        bottom: 0,
        position: "absolute",
        pointerEvents: "none"
      };
      return {
        root: {
          "& .ql-toolbar": {
            padding: theme.spacing(1, 0),
            borderLeft: 0,
            borderRight: 0,
            "&.ql-snow > .ql-formats": {
              marginRight: 0,
              "&:not(:first-of-type)": { paddingLeft: theme.spacing(0.75), borderLeft: "1px solid #ccc" },
              "&:not(:last-of-type)": { paddingRight: theme.spacing(0.75) }
            }
          },
          "& .ql-container": {
            borderLeft: 0,
            borderRight: 0
          },
          "&:not([class*=tc-editor-disabled])": {
            "& .ql-container": {
              borderBottom: 0,
              "&:hover:before": { borderBottom: "2px solid rgba(0, 0, 0, 0.87)" },
              "&:before": {
                ...afterBefore,
                content: '"\\00a0"',
                transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                borderBottom: "1px solid rgba(0, 0, 0, 0.42)"
              },
              "&:after": {
                ...afterBefore,
                content: '""',
                transform: "scaleX(0)",
                transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                borderBottom: "2px solid #107A98"
              }
            }
          },
          "& .ql-editor": {
            minHeight: `${height}px !important`,
            padding: theme.spacing(1.5, 0),
            overflow: "hidden",
            overflowY: "auto",
            overflowX: "auto",
            background: "white"
          }
        },
        disabled: { "& .ql-editor": { color: "rgba(0, 0, 0, 0.38)" }, "& .ql-container": { borderBottom: "1px dotted rgba(0, 0, 0, 0.42)" } },
        focused: { "& .ql-container:after": { transform: "scaleX(1) !important" } },
        label: { display: "block", height: theme.spacing(2), position: "relative" }
      };
    },
    { name: "tc-editor" }
  );

export const BaseEditorInput = memo(
  ({ className, disabled, height, id, label, name, onChange, readOnly, value, error, ...other }) => {
    const [{ inputId, focused }, setState] = useState({ inputId: id || `editor-${name}` });
    const classes = useStyles(height)();
    const handleChange = val => {
      if (typeof onChange !== "function") return false;
      return onChange({ target: { name, value: val } });
    };
    return (
      <FormControl
        fullWidth
        error={!!error}
        className={clsx(classes.root, className, { [classes.focused]: focused && !readOnly && !disabled, [classes.disabled]: disabled || readOnly })}
      >
        {label && (
          <InputLabel shrink htmlFor={inputId} className={classes.label}>
            {label}
          </InputLabel>
        )}
        <Editor
          {...{
            value: value || "",
            onChange: handleChange,
            readOnly: disabled || readOnly,
            onFocus: () => setState(ps => ({ ...ps, focused: true })),
            onBlur: () => setState(ps => ({ ...ps, focused: false })),
            id: inputId,
            name,
            ...other
          }}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  },
  (p, n) => !["value", "error"].some(x => p[x] !== n[x])
);

BaseEditorInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.number,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.string
};

BaseEditorInput.defaultProps = {
  className: undefined,
  disabled: false,
  height: 500,
  id: undefined,
  label: undefined,
  name: undefined,
  onChange: undefined,
  readOnly: false,
  value: undefined,
  error: undefined
};

export default HocInput(BaseEditorInput);
