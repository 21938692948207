import React, { Fragment, useRef } from "react";
import PropTypes from "prop-types";
import { Switch, Redirect, Route, useRouteMatch } from "react-router-dom";
import ClaimsTabs from "../../components/claims/Tabs";
import Claims from "./List";
import InitialReports from "../initialReport/List";
import Occurrences from "../occurrence/List";
import Catastrophes from "../catastrophe/List";
import PageNotAllowed from "../../components/PageNotAllowed";

const Lists = ({ actionsAllowed }) => {
  const { isExact, url, path } = useRouteMatch();
  const container = useRef(null);
  return (
    <Fragment key="occurrences-page">
      {isExact && <Redirect to={`${url}/claims`} />}
      <div ref={container} className="tc-page-container">
        <ClaimsTabs actionsAllowed={actionsAllowed} />
        <Switch>
          <Route
            exact
            path={`${path}/claims`}
            render={() => (actionsAllowed.find(e => e.routeName === "claims-claims")?.read ? <Claims /> : <PageNotAllowed />)}
          />
          <Route
            exact
            path={`${path}/initialReports`}
            render={() => (actionsAllowed.find(e => e.routeName === "initialReport")?.read ? <InitialReports /> : <PageNotAllowed />)}
          />
          <Route
            exact
            path={`${path}/occurrences`}
            render={() => (actionsAllowed.find(e => e.routeName === "occurrence")?.read ? <Occurrences /> : <PageNotAllowed />)}
          />
          <Route
            exact
            path={`${path}/catastrophes`}
            render={() => (actionsAllowed.find(e => e.routeName === "catastrophe")?.read ? <Catastrophes /> : <PageNotAllowed />)}
          />
        </Switch>
      </div>
    </Fragment>
  );
};

Lists.propTypes = {
  actionsAllowed: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Lists;
