import React from "react";
import PropTypes from "prop-types";
import { Grid, Avatar, Divider, makeStyles } from "@material-ui/core";
import moment from "moment";
import LabelValue from "../common/LabelValue";
import Claim from "../../assets/not_duplicates_found.svg";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },
  root: {
    padding: theme.spacing(0),
    height: "100%"
  },
  avatar: {
    width: 130,
    height: 130,
    color: "#898F91",
    fontSize: 118,
    backgroundColor: "#F3F3F3",
    "& > svg": { height: "1em", width: "1 em" },
    margin: "0 auto",
    marginBottom: theme.spacing(-2.25)
  },
  noDuplicates: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    color: "#898F91",
    fontSize: 16,
    paddingBottom: theme.spacing(2.75),
    paddingTop: 45
  },
  noDuplicatesText: { textAlign: "center", color: "#898F91" }
}));

const OccurrenceDuplicates = ({ occurrences }) => {
  const classes = useStyles();
  const duplicateList = occurrences.map(occurrence => (
    <div className={classes.container}>
      <Grid container xs={12}>
        <Grid item xs>
          <LabelValue label="Occurrence Number" value={occurrence.number} />
        </Grid>
        <Grid item xs>
          <LabelValue label="Name" value={occurrence.name} />
        </Grid>
        <Grid item xs>
          <LabelValue label="Date" value={`${moment(occurrence.date).format("M/D/YYYY")}`} />
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid item xs>
          <LabelValue label="Location" value={occurrence.fullAddress} />
        </Grid>
        <Grid item xs>
          <LabelValue label="Insured Name" value={occurrence.insuredName} />
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid item xs>
          <LabelValue label="Description" value={occurrence.description} />
        </Grid>
      </Grid>
      <Divider light />
    </div>
  ));

  return (
    <>
      {occurrences.length === 0 && (
        <Grid container className={classes.root}>
          <Grid item xs={12} className={classes.noDuplicates}>
            <Avatar className={classes.avatar}>
              <Claim />
            </Avatar>
          </Grid>
          <Grid item xs className={classes.noDuplicatesText}>
            No duplicates found
          </Grid>
        </Grid>
      )}
      {duplicateList}
    </>
  );
};

OccurrenceDuplicates.propTypes = {
  occurrences: PropTypes.arrayOf(PropTypes.objectOf).isRequired
};

export default OccurrenceDuplicates;
