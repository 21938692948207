import { CLAIM, API } from "../../../actions/actionTypes";
import * as actions from "../../../actions/claim/info/insured";
import { takeLatest, takeLatestSet } from "../../common/sagaGenerator";

const url = `${API.URL}/claim/insured`;

export default [
  takeLatest({
    type: CLAIM.INFO.INSURED.GET,
    actionSuccess: actions.getSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading insured",
    url
  }),
  takeLatestSet({
    type: CLAIM.INFO.INSURED.SET,
    actionSuccess: actions.setChildSuccess,
    params: ({ id, parentId }) => ({ id, parentId }),
    errorMessage: "Unable to save the information",
    successMessage: "Insured information successfully saved",
    url
  })
];
