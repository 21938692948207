import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Paper, Typography } from "@material-ui/core";
import moment from "moment";
import { Link } from "react-router-dom";
import MaterialTable from "../../common/table/MaterialTable";
import { useLoading } from "../../common/Loading";
import { get } from "../../../redux/actions/dashboard/diaries";
import { setExportTitleFileName } from "../../../common";
import ClaimStatus from "../../claims/ClaimStatus";
import ClaimProcessingStatus from "../../claims/ClaimProcessingStatus";

const ExtendedDiariesList = () => {
  const dispatch = useDispatch();
  const {
    Loading,
    actions: { showLoading, hideLoading }
  } = useLoading(false, true);

  useEffect(() => {
    showLoading();
    dispatch(get(hideLoading, hideLoading));
  }, []);
  const diaries = useSelector(({ dashboard: { diaries: d } }) => d, shallowEqual);

  const tableProps = {
    title: "",
    columns: [
      {
        title: "Claim #",
        field: "claimNumber",
        render: rowData => (
          <div style={{ display: "flex" }}>
            <Link to={`/claim/${rowData.claimId}/overview`} style={{ color: "#107A98" }}>
              {rowData.claimNumber}
            </Link>
            <span style={{ marginRight: 5 }}>
              <ClaimStatus status={rowData.status} />
            </span>
            <ClaimProcessingStatus processingStatus={rowData.claimProcessingStatus} />
          </div>
        )
      },
      {
        title: "Claimant",
        field: "claimantName",
        render: rowData => (
          <Link to={`/claim/${rowData.claimId}/overview`} style={{ color: "#107A98" }}>
            {rowData.claimantName}
          </Link>
        )
      },
      { title: "Diary Type", field: "type" },
      {
        title: "Diary Name",
        field: "name",
        render: rowData => (
          <Link to={`/claim/${rowData.claimId}/diaries?diaryId=${rowData.id}`} style={{ color: "#107A98" }}>
            {rowData.name}
          </Link>
        )
      },
      { title: "Priority", field: "priority" },
      { title: "Status", field: "status" },
      {
        title: "Due Date",
        field: "dueDate",
        render: rowData => (rowData.dueDate ? moment(rowData.dueDate).format("M/D/YYYY") : "")
      }
    ],
    data: diaries,
    hidePaging: diaries.length <= 50,
    options: {
      pageSize: 50,
      filtering: true,
      exportFileName: setExportTitleFileName("DiaryList")
    }
  };
  return (
    <>
      {Loading}
      <div className="tc-page-container">
        <div className="tc-page-content">
          <Paper className="tc-relative tc-o-hidden tc-box-content">
            <Typography className="tc-title">My Diaries</Typography>
            <MaterialTable {...tableProps} />
          </Paper>
        </div>
      </div>
    </>
  );
};

ExtendedDiariesList.propTypes = {};

ExtendedDiariesList.defaultProps = {};

export default ExtendedDiariesList;
