import { DOCUMENTS } from "../actions/actionTypes";
import initialState from "./initialState";

export default (state = initialState.documents, action) => {
  switch (action.type) {
    case DOCUMENTS.GETALL_SUCCESS:
    case DOCUMENTS.DEL_FILE_SUCCESS:
    case DOCUMENTS.POST_SUCCESS: {
      const {
        documents: { indexingStatusId, documents }
      } = action;

      const path = `${indexingStatusId === 1 ? "" : "re"}indexing`;
      return { ...state, [path]: documents };
    }
    case DOCUMENTS.POST_FILES_SUCCESS:
      return { ...state, indexing: action.documents };
    case DOCUMENTS.REVIEW.GET_SUCCESS:
      return { ...state, review: action.documents };
    default:
      return state;
  }
};
