import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Paper, Typography } from "@material-ui/core";
import moment from "moment";
import MaterialTable from "../../common/table/MaterialTable";
import { get } from "../../../redux/actions/dashboard/claimWithoutActivity";
import { useLoading } from "../../common/Loading";
import ClaimStatus from "../../claims/ClaimStatus";

export default () => {
  const dispatch = useDispatch();
  const { actions, Loading } = useLoading(true);
  const { showLoading, hideLoading } = actions;

  const claimsByAdjuster = useSelector(({ dashboard: { openClaimsByAdjuster } }) => openClaimsByAdjuster, shallowEqual);

  useEffect(() => {
    showLoading();
    dispatch(get(hideLoading, hideLoading));
  }, []);

  const tableProps = {
    columns: [
      { title: "Adjuster", field: "adjuster" },
      {
        title: "Claim Number",
        field: "claimNumber",
        render: rowData => (
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: 5 }}>
              <Link to={`/claim/${rowData.id}/overview`} blue>
                {rowData.number}
              </Link>
            </span>
            <span style={{ marginRight: 5 }}>
              <ClaimStatus status="open" />
            </span>
          </div>
        )
      },
      { title: "Claimaint", field: "claimant" },
      { title: "City", field: "city" },
      { title: "State", field: "state" },
      { title: "Date of Loss", field: "dateofLoss", render: rowData => (rowData.dateofLoss ? moment(rowData.dateofLoss).format("M/D/YYYY") : "") },
      { title: "Line of Business", field: "lineOfBussiness" },
      { title: "Loss Type Group", field: "lossTypeGroup" }
    ],
    data: claimsByAdjuster.sort((a, b) => a.adjuster.localeCompare(b.adjuster)),
    options: { search: false },
    hideExportButton: true,
    hidePaging: true
  };
  return (
    <>
      {Loading}
      <div className="tc-page-container">
        <div className="tc-page-content">
          <Paper className="tc-relative tc-o-hidden tc-box-content">
            <Typography className="tc-title">Open Claims Count by Adjuster</Typography>
            <MaterialTable {...tableProps} />
          </Paper>
        </div>
      </div>
    </>
  );
};
