import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useRouteMatch, useLocation } from "react-router-dom";
import { Tab } from "@material-ui/core";
import SimpleTabs from "../common/tabs/SimpleTabs";

const Tabs = ({ actionsAllowed }) => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const [tabsToShow, setTabsToShow] = useState([]);
  const tabsConfig = [
    ["claims", "Claims"],
    ["initialReports", "Initial Report"],
    ["occurrences", "Occurrences"],
    ["catastrophes", "Catastrophes"]
  ];
  const cList = actionsAllowed.find(e => e.routeName === "claims-claims")?.read;
  const irList = actionsAllowed.find(e => e.routeName === "initialReport")?.read;
  const oList = actionsAllowed.find(e => e.routeName === "occurrence")?.read;
  const ctList = actionsAllowed.find(e => e.routeName === "catastrophe")?.read;
  const getGoToList = () => {
    if (cList) return "claims";
    if (irList) return "initialReports";
    if (oList) return "occurrences";
    return "catastrophes";
  };
  useEffect(() => {
    const array = tabsConfig.reduce((acc, t) => {
      const routeName = t[0];
      if (
        (routeName === "claims" && cList) ||
        (routeName === "initialReports" && irList) ||
        (routeName === "occurrences" && oList) ||
        (routeName === "catastrophes" && ctList)
      )
        return [...acc, t];
      return acc;
    }, []);
    setTabsToShow(array);
  }, [cList, irList, oList, ctList]);

  return (
    <SimpleTabs value={url === pathname ? `${url}/${getGoToList()}` : pathname}>
      {tabsToShow.map(([route, label]) => (
        <Tab key={route} label={label} component={Link} to={`${url}/${route}`} value={`${url}/${route}`} />
      ))}
    </SimpleTabs>
  );
};

Tabs.propTypes = {
  actionsAllowed: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Tabs;
