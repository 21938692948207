import { useContext, useMemo, useCallback } from "react";
import qs from "qs";
import { shallowEqual, useSelector } from "react-redux";
import useYupValidationSchema from "../../../common/customHooks/useYupValidationSchema";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import { API } from "../../../../redux/actions/actionTypes";
import { settledSchema } from "./yup";

const useValidation = () => {
  const { handleValidateSchema, validationMessages } = useYupValidationSchema(settledSchema);

  return {
    handleValidateSchema,
    validationMessages
  };
};

const useSettledLogic = () => {
  const initialState = {
    demandAmount: 0,
    authorizedAmount: 0,
    settlementAmount: 0,
    isCaseSettled: false,
    authorizedDate: undefined,
    paymentDate: undefined
  };

  const iniVendor = {
    query: null,
    results: [],
    vendorId: null,
    vendorAddress1: "",
    vendorAddress2: "",
    vendorZipCode: "",
    vendorStateId: null,
    vendorPhone: "",
    vendorCity: ""
  };

  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "legal");

  const selectorData = useSelector(
    ({
      claim: { claimant, filterfields },
      admin: {
        claimSettings: { reserveTypes = [] }
      },
      common: { paymentMethods }
    }) => ({
      claimant,
      filterfields,
      reserveTypes,
      paymentMethods
    }),
    shallowEqual
  );

  const { claimant, filterfields, reserveTypes, paymentMethods } = selectorData || {};

  const { lineOfBusinessId, claimLossTypeGroupId } = filterfields || {};

  const reserveTypeByLob = useMemo(
    () => reserveTypes.filter(elem => elem.claimLineOfBusinessId === lineOfBusinessId && elem.claimLossTypeGroupId === claimLossTypeGroupId),
    [lineOfBusinessId, claimLossTypeGroupId, reserveTypes]
  );

  const getLocationAddress = (payeeTypeId, search, settled) => {
    return payeeTypeId !== 1 ? claimant.location : search?.locationAddress || (!settled.payeeTypeId ? settled : {});
  };

  const vendorUrl = `${API.URL}/bill/getAllvendor`;

  const payeeTypes = useMemo(
    () => [
      { id: 1, text: "Claimant" },
      { id: 2, text: "Vendor" }
    ],
    []
  );

  const onChangeVendorInfo = useCallback((e, setSearch, setSettled) => {
    const { name, id: vendorId, address1, address2, city, stateId, state, zipCode } = e || {};
    setSearch(prevState => ({
      ...prevState,
      query: name,
      vendorId,
      locationAddress: {
        address1,
        address2,
        city,
        stateId,
        state,
        zipCode
      }
    }));

    setSettled(prevState => ({ ...prevState, vendorId }));
  }, []);

  const axiosConfig = { paramsSerializer: params => qs.stringify(params, { indices: false }) };

  const querySearchParams = q => {
    return { searchText: q.searchText, vendorCategoryId: q.vendorCategoryId };
  };

  const inputPropsSearch = (path, search, setSearch, settled, validationMessages) => {
    const { st, action } = (() => {
      return { st: search, action: setSearch };
    })();

    return {
      name: path,
      value: st.query ?? settled?.payee,
      error: settled.vendorId ? "" : (validationMessages || {})?.vendorId,
      onChange: ({ target: { value } }) => action(ps => ({ ...ps, ...iniVendor, query: value }))
    };
  };

  return {
    initialState,
    iniVendor,
    claimClaimant: claimant,
    reserves: reserveTypeByLob,
    paymentMethods,
    getLocationAddress,
    vendorUrl,
    payeeTypes,
    onChangeVendorInfo,
    axiosConfig,
    querySearchParams,
    inputPropsSearch,
    access
  };
};

export { useSettledLogic, useValidation };
