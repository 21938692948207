import { delActions, getActions, getAllActions, setActions } from "../common";

export default {
  ...getActions("AUTO_DIARY"),
  ...getAllActions("AUTO_DIARY"),
  ...delActions("AUTO_DIARY"),
  ...setActions("AUTO_DIARY"),
  RESET: "RESET_AUTO_DIARY",
  ACTIVATE: "ACTIVATE_AUTO_DIARY",
  ACTIVATE_SUCCESS: "ACTIVATE_AUTO_DIARY_SUCCESS"
};
