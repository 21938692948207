import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { NOTES, API } from "../actions/actionTypes";
import { api, objectToFormData } from "../../api/apiUtils";
import { apiCallError, beginApiCall } from "../actions/apiStatusActions";
import { capitalizeFirstLetter } from "../../common";
import * as actions from "../actions/notes";
import rootSagaGenerator from "./common/rootSagaGenerator";
import { getErrorMessage } from "./common/sagaGenerator";
import * as docs from "./common/documents";

const url = `${API.URL}/note`;

const toFormData = ({ file, ...note }) => {
  if (file?.id) return objectToFormData({ note: { ...note, file } });
  return objectToFormData({ note, file });
};

export default rootSagaGenerator({
  name: "Notes",
  sagas: [
    takeLatest(NOTES.GET, function* getAll({ id, entityType, onSuccess, onError }) {
      try {
        yield put(beginApiCall());
        const entityname = entityType.concat("Id");
        const notes = yield call(api, {
          method: "GET",
          url,
          data: { [entityname]: id }
        });
        const fName = "get".concat(capitalizeFirstLetter(entityType)).concat("NotesSuccess");
        const actionSuccess = actions[fName];
        yield put(actionSuccess(notes));
        if (typeof onSuccess === "function") yield call(onSuccess, notes);
      } catch (err) {
        yield apiCallError(err);
        yield call(toast.error, getErrorMessage(err.response?.status, "Unable to load notes."));
        if (typeof onError === "function") yield call(onError);
      }
    }),
    takeLatest(NOTES.POST, function* post({ note, entityType, onSuccess, onError }) {
      try {
        yield put(beginApiCall());
        const notes = yield call(api, { method: "POST", url, data: toFormData(note) });
        const fName = "post".concat(capitalizeFirstLetter(entityType)).concat("NoteSuccess");
        const actionSuccess = actions[fName];
        yield put(actionSuccess(notes));
        yield call(toast.success, `${note.parentNoteId ? "Response" : "Note"} successfully added`);
        if (typeof onSuccess === "function") yield call(onSuccess, notes);
      } catch (err) {
        yield apiCallError(err);
        yield call(toast.error, getErrorMessage(err.response?.status, `Unable to add the ${note.parentNoteId ? "response" : "note"}.`));
        if (typeof onError === "function") yield call(onError);
      }
    }),
    takeLatest(NOTES.SET, function* set({ note, entityType, onSuccess, onError }) {
      try {
        yield put(beginApiCall());
        const notes = yield call(api, { method: "PUT", url, data: toFormData(note) });
        const fName = "set".concat(capitalizeFirstLetter(entityType)).concat("NoteSuccess");
        const actionSuccess = actions[fName];
        yield put(actionSuccess(notes));
        yield call(toast.success, `${note.parentNoteId ? "Response" : "Note"} successfully updated`);
        if (typeof onSuccess === "function") yield call(onSuccess, notes);
      } catch (err) {
        yield apiCallError(err);
        yield call(toast.error, getErrorMessage(err.response?.status, `Unable to update the ${note.parentNoteId ? "response" : "note"}`));
        if (typeof onError === "function") yield call(onError);
      }
    }),
    takeLatest(NOTES.DEL, function* del({ note, entityType, onSuccess, onError }) {
      try {
        const entityname = entityType.concat("Id");
        const values = { id: note.id, [entityname]: note[entityname] };
        yield put(beginApiCall());
        const notes = yield call(api, { method: "DELETE", url, data: values });
        const fName = "del".concat(capitalizeFirstLetter(entityType)).concat("NoteSuccess");
        const actionSuccess = actions[fName];
        yield put(actionSuccess(notes));
        yield call(toast.success, `${note.parentNoteId ? "Response" : "Note"} successfully deleted`);
        if (typeof onSuccess === "function") yield call(onSuccess, note);
      } catch (err) {
        yield apiCallError(err);
        yield call(toast.error, getErrorMessage(err.response?.status, `Unable to delete the ${note.parentNoteId ? "response" : "note"}`));
        if (typeof onError === "function") yield call(onError);
      }
    }),
    takeLatest(NOTES.GET_DRAFT, function* getDraft({ id, entityType, onSuccess, onError }) {
      try {
        yield put(beginApiCall());
        const entityname = entityType.concat("Id");
        const draft = yield call(api, { url: `${url}/draft`, data: { [entityname]: id } });
        const fName = "get".concat(capitalizeFirstLetter(entityType)).concat("DraftSuccess");
        const actionSuccess = actions[fName];
        yield put(actionSuccess(draft));
        if (typeof onSuccess === "function") yield call(onSuccess);
      } catch (err) {
        yield apiCallError(err);
        yield call(toast.error, getErrorMessage(err.response?.status, `Unable to get draft`));
        if (typeof onError === "function") yield call(onError);
      }
    }),
    takeLatest(NOTES.POST_DRAFT, function* postDraft({ note, entityType, onSuccess, onError }) {
      try {
        yield put(beginApiCall());
        const typeId = note.typeId !== 0 ? note.typeId : undefined;
        const draft = yield call(api, { method: "POST", url: `${url}/draft`, data: toFormData({ ...note, typeId }) });
        const fName = "post".concat(capitalizeFirstLetter(entityType)).concat("DraftSuccess");
        const actionSuccess = actions[fName];
        yield put(actionSuccess(draft));
        if (typeof onSuccess === "function") yield call(onSuccess);
        yield call(toast.success, `Draft successfully saved`);
      } catch (err) {
        yield apiCallError(err);
        yield call(toast.error, getErrorMessage(err.response?.status, `Unable to save draft`));
        if (typeof onError === "function") yield call(onError);
      }
    }),
    takeLatest(NOTES.OPEN_FILE, docs.openFile(NOTES.OPEN_FILE_SUCCESS))
  ]
});
