import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import clsx from "clsx";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const CustomModal = withStyles(styles)(
  ({
    openModal,
    title,
    children,
    onToggle,
    onAction,
    cancelBtnText,
    actionBtnText,
    fullWidth,
    maxWidth,
    disableBackdropClick,
    disableEscapeKeyDown,
    disableTitle,
    classes,
    cssDialog,
    loading
  }) => {
    return (
      <Dialog
        onClose={onToggle}
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        {!disableTitle && (
          <DialogTitle onClose={onToggle}>{typeof title === "string" ? <Typography className="tc-title">{title}</Typography> : title}</DialogTitle>
        )}
        <DialogContent className={clsx(classes.dialogContent, cssDialog)}>{children}</DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={onToggle} color="primary">
            {cancelBtnText}
          </Button>
          {onAction && (
            <Button variant="contained" color="primary" onClick={onAction} disabled={loading}>
              {actionBtnText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
);

CustomModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  body: PropTypes.node,
  onToggle: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  actionBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  disableTitle: PropTypes.bool,
  cssDialog: PropTypes.string,
  loading: PropTypes.bool
};

CustomModal.defaultProps = {
  title: "",
  actionBtnText: "OK",
  cancelBtnText: "CANCEL",
  maxWidth: "sm", // xs || sm || md || lg || xl
  fullWidth: true,
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
  disableTitle: false,
  onAction: undefined,
  cssDialog: undefined,
  loading: false
};

export default CustomModal;
