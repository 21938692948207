import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { CheckboxLabel, SearchLabelInput } from "../common/inputs";
import styles from "./Associate.styles";
import { API } from "../../redux/actions/actionTypes";
import { api } from "../../api/apiUtils";
import { associateCatastrophe as associate } from "../../redux/actions/catastropheAction";
import CollapsibleOccurrenceList from "./CollapsibleOccurrenceList";
import CollapsibleBox from "../common/CollapsibleBox";
import { useDebounce } from "../../common";
import useYupValidationSchema from "../common/customHooks/useYupValidationSchema";

const AssociateOccurrence = ({ filter }) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [filterOccurrenceSearch, setOccurrenceSearch] = useState({
    sameDol: true,
    sameCity: true,
    sameState: true,
    sameInsured: true,
    textSearch: ""
  });

  const [open, setOpen] = useState(true);

  const [occurrences, setOccurrences] = useState([]);

  const searchOccurrences = useDebounce(() => {
    const values = {
      ...filterOccurrenceSearch,
      date: filterOccurrenceSearch.sameDol && filter ? filter.date : null,
      dateTo: filterOccurrenceSearch.sameDol && filter ? filter.dateTo : null,
      locationList: filter.locationList?.length
        ? (filter.locationList || []).reduce((acc, item) => {
            let cityData = filterOccurrenceSearch.sameCity ? item.city : null;
            let stateData = filterOccurrenceSearch.sameState ? item.stateId : null;
            if (item.city === null || item.city === "") {
              cityData = filterOccurrenceSearch.sameCity ? "-1" : null;
            }
            if (item.stateId === null) {
              stateData = filterOccurrenceSearch.sameState ? -1 : null;
            }
            return [
              ...acc,
              {
                city: cityData,
                stateId: stateData
              }
            ];
          }, [])
        : [{ stateId: filterOccurrenceSearch.sameState ? -1 : null, city: filterOccurrenceSearch.sameCity ? "-1" : null }]
    };
    if (!filterOccurrenceSearch.sameState && !filterOccurrenceSearch.sameCity && !filterOccurrenceSearch.sameDol) {
      if (filterOccurrenceSearch.textSearch.length > 2) {
        api({ method: "POST", url: `${API.URL}/occurrence/searchf`, data: values }).then(data => setOccurrences(data));
      }
    } else api({ method: "POST", url: `${API.URL}/occurrence/searchf`, data: values }).then(data => setOccurrences(data));
  }, 500);

  useEffect(() => {
    searchOccurrences();
  }, [filter, filterOccurrenceSearch]);

  const onChangeSearchOccurrence = event => {
    const { name, value } = event.target;
    setOccurrenceSearch(prevSearchOccurrence => ({
      ...prevSearchOccurrence,
      [name]: value
    }));
  };
  const onToggleOcc = name => {
    setOccurrences(prevState => prevState.map(obj => (obj.number === name ? Object.assign(obj, { open: !obj.open }) : obj)));
  };

  const associateOccurrence = occurrence => {
    dispatch(associate({ catastropheId: filter.id, type: "Occurrence", id: occurrence.id, isRemove: false }));
  };

  const onToggle = () => {
    setOpen(!open);
  };

  const { handleValidateSchema, validationMessages } = useYupValidationSchema(
    yup.object().shape({
      textSearch: yup.string().test("len", "Type at least 3 characters", val => val.length > 2)
    })
  );

  useEffect(() => {
    handleValidateSchema(filterOccurrenceSearch);
  }, [filterOccurrenceSearch]);

  return (
    <CollapsibleBox title="Associate Occurrence" name="associateOccurrence" {...{ open, onToggle }}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className={classes.titleSearch}>
            <span>Restrict Search to:</span>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <CheckboxLabel label="Same DOL" checked={filterOccurrenceSearch.sameDol} name="sameDol" onChange={onChangeSearchOccurrence} />
            </Grid>
            <Grid item xs={3}>
              <CheckboxLabel label="Same City" checked={filterOccurrenceSearch.sameCity} name="sameCity" onChange={onChangeSearchOccurrence} />
            </Grid>
            <Grid item xs={3}>
              <CheckboxLabel label="Same State" checked={filterOccurrenceSearch.sameState} name="sameState" onChange={onChangeSearchOccurrence} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <SearchLabelInput
                name="textSearch"
                value={filterOccurrenceSearch.textSearch}
                onChange={onChangeSearchOccurrence}
                label="Occurrence Number / Occurrence Name"
                error={validationMessages?.textSearch}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CollapsibleOccurrenceList
        {...{ occurrences, onToggle: onToggleOcc, associate: associateOccurrence, openOccurrenceDetail: searchOccurrences }}
      />
    </CollapsibleBox>
  );
};

AssociateOccurrence.propTypes = {
  filter: PropTypes.shape({
    date: PropTypes.string,
    city: PropTypes.string,
    stateId: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locationList: PropTypes.array,
    multipleLocation: PropTypes.bool,
    dateTo: PropTypes.string
  }).isRequired
};

export default AssociateOccurrence;
