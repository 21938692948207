import React, { useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import { Gavel } from "@material-ui/icons";
import moment from "moment";
import { useLitigationInfoStyles } from "./index.styles";

export default () => {
  const store = useSelector(({ claim }) => claim, shallowEqual);
  const { claimantAttorneys, hearings, trial } = store || {};
  const classes = useLitigationInfoStyles();
  const some = l => l.some(() => true);
  const last = (l, p) =>
    l.sort((a, b) => {
      if (a[p] < b[p]) return 1;
      if (a[p] > b[p]) return -1;
      return 0;
    })[0][p];
  const { isCaseSettled } = trial || {};

  const trialNoticeReceived = useMemo(() => !!trial?.id, [trial]);

  return (
    <Paper className={classes.root}>
      <div className={classes.title}>Litigation Info</div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={8}>
          {(some(claimantAttorneys) && (
            <>
              <Gavel className={classes.gavel} />
              Claimant Attorney
            </>
          )) ||
            "No Litigation"}
        </Grid>
        <Grid item xs={4}>
          <div className={classes.label}>Hearing Date</div>
          {(some(hearings) && moment(last(hearings, "date")).format("M/D/YYYY")) || "--/--/----"}
        </Grid>
        <Grid item xs={8}>
          <div className={classes.label}>Case Settled</div>
          {(isCaseSettled && "Yes") || "No"}
        </Grid>
        <Grid item xs={4}>
          <div className={classes.label}>Trial Notice</div>
          {(trialNoticeReceived && "Yes") || "No"}
        </Grid>
      </Grid>
    </Paper>
  );
};
