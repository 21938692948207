import React from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import TextField from "@material-ui/core/TextField";
import { HocInput } from "./HocInput";
import { refPropType } from "../../../common";

export const BaseTextInput = ({ error, name, rows, forwardRef, ...other }) => {
  let props = {
    ...other,
    name,
    id: `txt-${name || uuid()}`,
    error: Boolean(error),
    helperText: error,
    ref: forwardRef
  };
  if (rows > 0) props = { ...props, multiline: true, rows };
  return <TextField fullWidth {...props} />;
};

BaseTextInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  rows: PropTypes.number,
  forwardRef: refPropType()
};

BaseTextInput.defaultProps = {
  name: "",
  label: null,
  onChange: null,
  placeholder: "",
  value: "",
  error: "",
  rows: 0,
  forwardRef: null
};

export default HocInput(BaseTextInput);
