import { Grid } from "@material-ui/core";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useRouteParam } from "../../../../common";
import { API } from "../../../../redux/actions/actionTypes";
import { getAll as getNoteTypes } from "../../../../redux/actions/admin/noteTypeSetting";
import * as docActions from "../../../../redux/actions/claim/documents";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import useYupValidationSchema from "../../../common/customHooks/useYupValidationSchema";
import FileIcon from "../../../common/documents/FileIcon";
import { validateFile } from "../../../common/documents/hooks";
import { BaseSelectInput } from "../../../common/inputs";
import { useLoadingContext } from "../../../common/Loading";
import useViewer from "../../../common/viewer/useViewer";
import { indexSchema, returnSchema } from "./yup";

export const useDocuments = () => {
  const claimId = useRouteParam("claimId");
  const {
    actions: { loading, handleBreadcrumb, handlePadding, handleSideBar, showLoading, hideLoading }
  } = useLoadingContext();
  const documents = useSelector(({ claim: { documents: d } }) => d, shallowEqual);
  const [{ indexItem }, setState] = useState({});
  const dispatch = useDispatch();
  const onChange = fn => setState(s => ({ ...s, indexItem: fn(s.indexItem) }));
  const onCancelIndex = useCallback(() => setState(s => ({ ...s, indexItem: undefined })), []);
  const { handleValidateSchema, validationMessages: error } = useYupValidationSchema(indexSchema);
  const postData = () => {
    const { id, description, folderId, note } = indexItem;
    return { id, claimId, description, folderId, note };
  };
  const handleValidation = () => {
    const data = postData();
    return { ...handleValidateSchema(data), data };
  };
  const onValidate = fn => () => {
    if (typeof fn !== "function") return false;
    const { isValid, data } = handleValidation();
    if (!isValid) return false;
    showLoading();
    return fn(data, onCancelIndex, hideLoading);
  };
  const actions = {
    get: docActions.loadByClaimId,
    deleteFile: docActions.deleteFile,
    updateFile: docActions.updateFile,
    uploadFiles: docActions.uploadFiles,
    downloadFiles: docActions.downloadFiles,
    openFile: docActions.openFile,
    moveFiles: docActions.moveFiles,
    onIndex: useCallback(
      async ({ id }) => {
        showLoading();
        try {
          const { data } = await axios.get(`${API.URL}/indexing/${id}/${claimId}`);
          hideLoading();
          return setState(s => ({ ...s, indexItem: { ...data, note: {} } }));
        } catch (_e) {
          return hideLoading();
        }
      },
      [showLoading, hideLoading]
    ),
    onCancelIndex,
    showLoading,
    hideLoading,
    onChange: useCallback(({ target: { name, value } }) => onChange(s => ({ ...s, [name]: value })), []),
    onChangeNote: useCallback(({ target: { name, value } }) => onChange(s => ({ ...s, note: { ...s.note, [name]: value } })), []),
    onApprove: onValidate((...p) => dispatch(docActions.approve(...p))),
    onDelete: useCallback(() => {
      showLoading();
      return dispatch(
        docActions.deleteFile({ claimId, indexingStatusId: indexItem.statusId }, indexItem.id, { onSuccess: onCancelIndex, onError: hideLoading })
      );
    }, [claimId, indexItem?.id, hideLoading]),
    onReturnIndex: useCallback(
      reindexTypeId => {
        showLoading();
        return dispatch(docActions.reindex({ claimId, id: indexItem.id, reindexTypeId }, onCancelIndex, hideLoading));
      },
      [claimId, indexItem?.id, hideLoading]
    ),
    handleValidation,
    onOpenFileIndex: useCallback(
      p => {
        showLoading();
        return dispatch(docActions.openFile({ ...p, onSuccess: hideLoading, onError: hideLoading }));
      },
      [hideLoading]
    )
  };
  const routesAllowed = useContext(ActionsAllowedContext);
  const actionsAllowed = routesAllowed.find(elem => elem.route === "documents");
  useEffect(() => {
    dispatch(getNoteTypes());
  }, []);
  useEffect(() => {
    handleSideBar(!indexItem);
    handleBreadcrumb(!indexItem);
    handlePadding(!indexItem);
    if (!indexItem && loading) hideLoading();
    return () => {
      handleSideBar(true);
      handleBreadcrumb(true);
      handlePadding(true);
    };
  }, [indexItem]);
  return { claimId, documents, actions, actionsAllowed, indexItem, error };
};

export const useIndexing = (indexItem, { onDelete, onReturnIndex, onOpenFileIndex }) => {
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "documents");
  const { loading } = useLoadingContext();
  const { fileName, fileType } = indexItem || {};
  const [{ del, open, openDialog, reindexTypeId }, setState] = useState({ open: false, openDialog: false });
  const options = useSelector(({ common: { reindexReasons } }) => reindexReasons, shallowEqual).filter(({ id }) => ![4, 5].some(i => i === id));
  const ref = useRef(null);
  const {
    handleValidateSchema,
    handleValidationMessages: clear,
    validationMessages: { reindexTypeId: error }
  } = useYupValidationSchema(returnSchema);
  const onChange = ({ target: { value } }) =>
    setState(s => {
      const ns = { ...s, reindexTypeId: value };
      handleValidateSchema(ns);
      return ns;
    });
  useEffect(() => {
    clear();
  }, [openDialog]);
  const confirmProps = {
    messageText: del ? (
      <>
        <p>The following document is going to be deleted:</p>
        <Grid container spacing={1} alignItems="center" wrap="nowrap" component="p">
          <Grid item component="span">
            <FileIcon file type={fileType} />
          </Grid>
          <Grid item component="span">
            <b>{fileName}</b>
          </Grid>
        </Grid>
        <p>Are you sure you want to proceed?</p>
      </>
    ) : (
      <>
        <p>Please select a reason for returning the document to Indexing:</p>
        <BaseSelectInput label="Reason" name="reindexTypeId" value={reindexTypeId} {...{ onChange, options, error }} />
      </>
    ),
    title: del ? "Delete Document" : "Return to Indexing",
    onAction: () => {
      if (!del) {
        const { isValid } = handleValidateSchema({ reindexTypeId });
        if (!isValid) return false;
      }
      confirmProps.onToggleConfirm();
      if (del) return onDelete();
      if (typeof onReturnIndex === "function") return onReturnIndex(reindexTypeId);
      return false;
    },
    openDialog,
    onToggleConfirm: () => setState(s => ({ ...s, openDialog: false })),
    okText: del ? "Yes - Delete" : "Save"
  };
  const onSelect = fn => () => {
    setState(ps => ({ ...ps, open: false }));
    if (typeof fn === "function") return fn();
    return false;
  };
  const actions = {
    onClickAway: event => {
      if (ref.current && ref.current.contains(event.target)) return false;
      return setState(ps => ({ ...ps, open: false }));
    },
    onSelect,
    onToggle: () => setState(s => ({ ...s, open: !s.open })),
    onReindex: () => setState(s => ({ ...s, open: false, openDialog: true, del: undefined, reindexTypeId: undefined })),
    onDelete: () => setState(s => ({ ...s, open: false, openDialog: true, del: true }))
  };
  const { onViewFile, fileViewer } = useViewer({ dispatchAction: onOpenFileIndex, modal: false });
  useEffect(() => {
    if (indexItem?.id && indexItem?.claimId) onViewFile(indexItem.id, { claimId: indexItem.claimId });
  }, [indexItem?.id, indexItem?.claimId]);
  return { open, ref, confirmProps, actions, fileViewer, loading, access };
};

export const useIndexInfo = ({ actions: { onChange, onChangeNote, handleValidation }, indexItem, error }) => {
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "documents");
  const { note, claim } = indexItem;
  const store = useSelector(
    ({
      admin: {
        claimSettings: { noteTypes }
      }
    }) => noteTypes,
    shallowEqual
  );
  const noteTypes = store.filter(s => s.parentId === 1 && !["claimLineOfBusinessId", "claimLossTypeGroupId"].some(p => s[p] !== claim[p]));
  const inputProps = (label, name, isNote) => ({
    name,
    label,
    onChange: isNote ? onChangeNote : onChange,
    value: (isNote ? note : indexItem)[name],
    error: error[`${isNote ? "note." : ""}${name}`]
  });
  const onUploadChange = ({ target: { files } }) => {
    if (!validateFile({ type: files[0].type, kind: "file" })) return toast.warn("Invalid file type");
    return onChangeNote({ target: { name: "file", value: files[0] } });
  };
  const onDeleteFile = () => onChangeNote({ target: { name: "file", value: undefined } });
  const id = "claim-document-index-note-file";
  useEffect(() => {
    handleValidation();
  }, [indexItem.description, indexItem.folderId, note]);
  return { noteTypes, inputProps, onUploadChange, onDeleteFile, id, indexItem, access };
};
