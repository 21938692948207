import React, { Fragment, useEffect, useState, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { format } from "date-fns";
import { Paper, Button, MenuItem, Collapse } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { toast } from "react-toastify";
import { get, post, set, getClaimEmails, getTemplates } from "../../../../redux/actions/claim/communications";
import { loadByClaimId } from "../../../../redux/actions/claim/documents";
import Communication from "./Communication";
import { setExportTitleFileName, useRouteParam } from "../../../../common";
import Table from "../../../common/table/MaterialTable";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import Loading, { useLoadingContext } from "../../../common/Loading";
import LinkButton from "../../../common/LinkButton";

export default () => {
  const dispatch = useDispatch();
  const claimId = useRouteParam("claimId");
  const store = useSelector(
    ({
      claim: {
        communication,
        communicationTemplates,
        documents,
        claim: { number }
      },
      common: { communicationType }
    }) => ({
      communication,
      communicationTemplates,
      documents,
      types: communicationType,
      number
    }),
    shallowEqual
  );

  const {
    communication: { communications },
    communicationTemplates: { templates },
    documents,
    types = [],
    number: claimNumber
  } = store || {};
  const [newComm, setNewComm] = useState(false);
  const [communicationType, setCommunicationType] = useState(0);
  const [communicationDetail, setCommunicationDetail] = useState({});
  const [showDetail, setShowDetail] = useState(false);
  const [showTypes, setShowTypes] = useState(false);
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "communications");
  const {
    loading,
    actions: { showLoading, hideLoading }
  } = useLoadingContext();
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
      dispatch(getClaimEmails(claimId));
      dispatch(getTemplates(claimId));
    }
  }, []);
  useEffect(() => {
    if (documents.claimId !== claimId) dispatch(loadByClaimId(claimId));
  }, [documents]);

  useEffect(() => {
    setNewComm(false);
    setCommunicationType(0);
  }, [communications]);

  const onSuccess = () => {
    const communicationTypeSelected = types.find(t => t.id === communicationType);
    const communicationTypeText = (communicationTypeSelected || {}).text;
    toast.success(`${communicationTypeText} successfully ${communicationType !== 1 ? " Print" : "Sent"}`);
    hideLoading();
  };
  const onError = () => {
    const communicationTypeSelected = types.find(t => t.id === communicationType);
    const communicationTypeText = (communicationTypeSelected || {}).text;
    toast.error(`Unable to ${communicationType !== 1 ? "print" : "send"} the ${communicationTypeText}`);
    hideLoading();
  };

  const handleSendCommunication = communication => {
    if (access.create) {
      showLoading();
      dispatch(post({ communication: { ...communication, communicationTypeId: communicationType, claimId } }, onSuccess, onError));
    }
  };

  const handleSetCommunication = communication => {
    if (access.update) {
      const onSuccessSet = () => {
        toast.success("Letter information successfully updated");
        hideLoading();
      };
      const onErrorSet = () => {
        toast.error("Unable to update the letter information");
        hideLoading();
      };
      dispatch(set({ communication: { ...communication, communicationTypeId: communicationType, claimId } }, onSuccessSet, onErrorSet));
      setCommunicationType(0);
      setShowDetail(false);
    }
  };

  const handleOpenDetail = data => {
    setCommunicationDetail(data);
    setShowDetail(true);
  };

  const handleNewCommunication = type => {
    setShowTypes(prev => !prev);
    setCommunicationType(type);
    setNewComm(true);
  };

  const handleCloseNewCommunication = open => {
    setCommunicationType(0);
    setNewComm(open);
  };

  const handleCloseDetail = () => {
    setCommunicationDetail({});
    setShowDetail(false);
  };
  const getButtonToolBar = () => {
    return (
      <div style={{ position: "relative" }}>
        {access.create && (
          <Button
            variant="contained"
            aria-haspopup="true"
            color="primary"
            onClick={() => setShowTypes(prev => !prev)}
            disabled={communicationType > 0}
          >
            New Communication
            {(showTypes && <ExpandLessIcon />) || <ExpandMoreIcon />}
          </Button>
        )}

        <Collapse in={showTypes}>
          <div style={{ position: "absolute", zIndex: "1000", background: "#fff", width: "100%" }}>
            {(types.length > 0 &&
              types.map(type => (
                <MenuItem key={type.id} onClick={() => handleNewCommunication(type.id)}>
                  {type.text}
                </MenuItem>
              ))) || <Loading />}
          </div>
        </Collapse>
      </div>
    );
  };
  const tableProps = {
    title: "",
    columns: [
      {
        title: "Create",
        render: rowData => (rowData.createdOn ? format(new Date(rowData.createdOn), "MM/dd/yyyy") : null)
      },
      { title: "Type", field: "communicationType" },
      { title: "Status", field: "status" },
      { title: "Subject", field: "subject" },
      {
        title: "Detail",
        render: rowData => (
          <LinkButton blue onClick={() => handleOpenDetail(rowData)}>
            Detail
          </LinkButton>
        )
      }
    ],
    data: communications || [],
    options: { actionsColumnIndex: -1, exportFileName: setExportTitleFileName(`${claimNumber}_Communications`) },
    ButtonToolBar: getButtonToolBar
  };

  return (
    <Fragment key="claim-comms-page">
      {newComm && (
        <Collapse in={newComm}>
          <Communication
            communicationDetail={communicationDetail}
            handleCommunication={handleCloseNewCommunication}
            templates={templates}
            onSave={handleSendCommunication}
            isLetter={communicationType === 2}
            communicationType={types.find(t => t.id === communicationType)}
            access={access}
            isLoading={loading}
          />
        </Collapse>
      )}
      {showDetail && (
        <Communication
          isEdit
          templates={templates}
          isLetter={communicationDetail.communicationTypeId === 2}
          onSave={handleSetCommunication}
          communicationDetail={communicationDetail}
          handleCommunication={handleCloseDetail}
          access={access}
          isLoading={loading}
        />
      )}
      <Paper className="tc-box-content">
        <Table {...tableProps} />
      </Paper>
    </Fragment>
  );
};
