import { toast } from "react-toastify";
import { API, ADMIN } from "../../actions/actionTypes";
import { getAllSuccess, addSuccess, setSuccess, delSuccess, getOpenClaimsSuccess } from "../../actions/admin/users";
import { takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "../common/sagaGenerator";

const url = `${API.URL}/user`;

export default [
  takeLatest({
    type: ADMIN.USER.GETALL,
    url,
    actionSuccess: getAllSuccess,
    errorMessage: "Unable to get Users"
  }),
  takeLatestPost({
    type: ADMIN.USER.POST,
    url,
    actionSuccess: addSuccess,
    successMessage: "User successfully created",
    params: ({ data }) => data,
    errorMessage: "Unable to create the user"
  }),
  takeLatestSet({
    type: ADMIN.USER.SET,
    url,
    actionSuccess: setSuccess,
    successMessage: "User Information successfully updated",
    params: ({ data }) => data,
    errorMessage: "Unable to update the user information"
  }),
  takeLatestDel({
    type: ADMIN.USER.DEL,
    url,
    params: ({ userId, reassignUserId }) => ({ userId, reassignUserId }),
    actionSuccess: delSuccess,
    onSuccess: ({ reassignUserId }) => toast.success(`${reassignUserId ? "Claims successfully reassigned and " : ""}User successfully deleted`),
    onError: ({ reassignUserId }) => toast.error(`Unable to ${reassignUserId ? "reassign claims and " : ""}delete the User`)
  }),
  takeLatest({
    type: ADMIN.USER.CLAIMS,
    url: ({ userId }) => `${url}/openClaims/${userId}`,
    actionSuccess: getOpenClaimsSuccess,
    errorMessage: "Unable to get number of open claims associated to User"
  })
];
