import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../common/CollapsibleBox";
import { useRouteParam } from "../../../common";
import ClaimantInfo from "../../common/claims/claimant/ViewMode";
import ClaimantForm from "../../common/claims/claimant/Form";
import { get, set } from "../../../redux/actions/initialReport/claimant";
import ActionsAllowedContext from "../../common/contexts/ActionsAllowed";

const ClaimantBox = props => {
  const store = useSelector(({ initialReport: { information } }) => information, shallowEqual);
  const { claimant } = store || {};
  const { claimLineOfBusinessId } = useSelector(({ initialReport: { claim } }) => claim, shallowEqual);
  const claimId = useRouteParam("claimId");
  const dispatch = useDispatch();
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info") || {};
  const {
    props: { boxProps, editMode, loading: isLoading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  const onSave = data => {
    if (access.update) {
      showLoading();
      dispatch(set(data, claimId, onSuccessEdit, hideLoading));
    }
  };
  return (
    <CollapsibleBox title={claimLineOfBusinessId !== 4 ? "Reported By" : "Employee"} name="claimant" {...boxProps} {...props}>
      {Loading}
      {(editMode && <ClaimantForm isReportOnly {...{ claimant, claimLineOfBusinessId, onClose: onToggleEdit, onSave, isLoading }} />) || (
        <ClaimantInfo isReportOnly {...{ claimant, claimLineOfBusinessId }} />
      )}
    </CollapsibleBox>
  );
};

export default ClaimantBox;
