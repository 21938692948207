import { makeStyles } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { useEffect, useState, shallowEqual } from "react";
import { useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from "../../common/table/MaterialTable";
import { API } from "../../../redux/actions/actionTypes";
import { api } from "../../../api/apiUtils";
import { useLoadingContext } from "../../common/Loading";
import { loadVendor, loadContacts, setContact, postContact, delContact } from "../../../redux/actions/vendor";
import CollapsibleBox from "../../common/CollapsibleBox";
import { BaseSelectInput as SelectInput, PhoneInput } from "../../common/inputs";
import { getUserAccess, setExportTitleFileName } from "../../../common";
import useCustomConfirm from "../../common/customHooks/useCustomConfirm";
import { isEmail, isDate, isNumber } from "../../../common/yup";

const useStyles = makeStyles(() => ({
  select: { minWidth: "200px" },
  icons: { color: "#898F91" },
  title: { color: "#428302", marginBottom: 0, cursor: "pointer" },
  root: { paddingBottom: "20px", paddingTop: "20px" }
}));

export default () => {
  const {
    params: { id }
  } = useRouteMatch();
  const dataSelector = useSelector(
    ({
      common: { memberCategories, contactCategories },
      vendor: {
        profile: { basicInformation },
        contacts
      },
      profile: { securityProfilesDetails }
    }) => ({ memberCategories, contactCategories, basicInformation, contacts, securityProfilesDetails }),
    shallowEqual
  );

  const { memberCategories, contactCategories, basicInformation, contacts, securityProfilesDetails } = dataSelector;

  const dispatch = useDispatch();
  const actionsAllowed = getUserAccess(securityProfilesDetails, "VENDORS.CONTACTS", null, basicInformation?.location?.stateId, null);
  const [locations, setLocations] = useState([]);
  const callLocations = async () => {
    await api({ method: "GET", url: `${API.URL}/select/vendorlocations`, data: { parentId: id } })
      .then(data => setLocations(data))
      .catch();
  };
  useEffect(() => {
    if (basicInformation?.hasMultipleLocations === true) callLocations();
  }, [basicInformation]);
  const {
    actions: { showLoading, hideLoading }
  } = useLoadingContext();
  useEffect(() => {
    showLoading();
    dispatch(loadContacts(id, hideLoading, hideLoading));
    if (!basicInformation.id) {
      dispatch(loadVendor(id, hideLoading, hideLoading));
    }
  }, []);

  const optionsCat = basicInformation?.isPerson === false ? memberCategories.concat(contactCategories) : contactCategories;
  const classes = useStyles();
  const handleDelete = rowData => {
    showLoading();
    dispatch(delContact(rowData, hideLoading, hideLoading));
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleDelete, "Cancel", "Yes, Delete");

  const handleConfirmDelete = data => {
    handleCustomConfirm({
      oData: data,
      titleText: `Delete Contact`,
      messageText: `The following contact is going to be deleted: ${data.firstName} ${data.lastName}. Are you sure you want to proceed?`
    });
  };

  const CustomSelect = props => <SelectInput className={classes.select} {...props} />;

  const inputProps = (col, name, error, date) => ({
    name,
    className: classes.inputWidth,
    value: col.rowData[name] || undefined,
    onChange: ({ target: { value } }) => {
      if (name === "categoryId") {
        return col.onRowDataChange({ ...col.rowData, categoryId: value });
      }
      if (name === "parentId") {
        return col.onRowDataChange({ ...col.rowData, parentId: value });
      }
      return col.onChange(value);
    },
    error: error && !(date ? isDate : isNumber)(col.value) ? error : undefined,
    ...(date ? { variant: "dialog", clearable: true } : {}),
    ...(name === "categoryId" ? { allowClear: optionsCat.some(({ categoryId }) => !categoryId), options: optionsCat } : {}),
    ...(name === "parentId" ? { allowClear: optionsCat.some(({ parentId }) => !parentId), options: locations } : {})
  });
  const onComplete = fn => (...d) => {
    hideLoading();
    return typeof fn !== "function" || fn(...d);
  };
  const tableProps = {
    columns: [
      {
        title: "First Name",
        field: "firstName",
        validate: rowData => (typeof rowData?.firstName !== "string" || !rowData.firstName.trim() ? "First Name is required" : true)
      },
      { title: "Last Name", field: "lastName" },
      { title: "Email", field: "email", validate: ({ email }) => !email || isEmail(email) || "Invalid Email" },
      {
        title: "Work Phone",
        field: "phone1",
        editComponent: row => {
          const value = row.rowData?.value ? row.rowData?.value : row.rowData?.phone1;
          const phone1 = row.rowData?.phone1?.target ? row.rowData?.phone1?.target?.value : value;
          return <PhoneInput name="phone1" label="Work Phone" value={phone1} onChange={row.onChange} />;
        }
      },
      {
        title: "Cell Phone",
        field: "mobile",
        editComponent: row => {
          const value = row.rowData?.value ? row.rowData?.value : row.rowData?.mobile;
          const mobile = row.rowData?.mobile?.target ? row.rowData?.mobile?.target?.value : value;
          return <PhoneInput name="phone1" label="Work Phone" value={mobile} onChange={row.onChange} />;
        }
      },
      {
        title: "Contact Type",
        field: "category",
        editComponent: row => <CustomSelect {...inputProps(row, "categoryId")} />
      },
      {
        title: "Location",
        hidden: !basicInformation?.hasMultipleLocations,
        field: "fullParentName",
        editComponent: row => <CustomSelect {...inputProps(row, "parentId")} />
      }
    ],
    data: contacts.map(c => ({ ...c, fullParentName: locations.find(elem => elem?.id === c.parentId)?.text })),
    options: { exportFileName: setExportTitleFileName(`${basicInformation?.name}_Contacts`) },
    editable: {
      ...(actionsAllowed.update
        ? {
            onRowUpdate: rowData => {
              const parentId = rowData?.parentId?.target ? rowData?.parentId?.target?.value : rowData?.parentId;
              return new Promise((resolve, reject) => {
                showLoading();
                dispatch(
                  setContact(
                    {
                      ...rowData,
                      categoryId: rowData?.category?.target ? rowData?.category?.target?.value : rowData?.categoryId,
                      phone1: rowData?.phone1?.target ? rowData?.phone1?.target?.value : rowData?.phone1,
                      mobile: rowData?.mobile?.target ? rowData?.mobile?.target?.value : rowData?.mobile,
                      parentId: parentId ?? parseInt(id, 10)
                    },
                    onComplete(resolve),
                    onComplete(reject)
                  )
                );
              });
            }
          }
        : {}),
      ...(actionsAllowed.create
        ? {
            onRowAdd: rowData => {
              const parentId = rowData?.parentId?.target ? rowData?.parentId?.target?.value : rowData?.parentId;
              return new Promise((resolve, reject) => {
                showLoading();
                dispatch(
                  postContact(
                    {
                      basicInformation: {
                        ...rowData,
                        id: 0,
                        categoryId: rowData?.categoryId?.target ? rowData?.categoryId?.target?.value : rowData?.categoryId,
                        phone1: rowData?.phone1?.target ? rowData?.phone1?.target?.value : rowData?.phone1,
                        mobile: rowData?.mobile?.target ? rowData?.mobile?.target?.value : rowData?.mobile,
                        isPerson: true,
                        isActive: true,
                        parentId: parentId ?? parseInt(id, 10),
                        vendorType: "Contact"
                      },
                      industry: []
                    },
                    onComplete(resolve),
                    onComplete(reject)
                  )
                );
              });
            }
          }
        : {})
    },
    actions: actionsAllowed.delete ? [{ icon: Delete, tooltip: "Delete Contact", onClick: (_event, rowData) => handleConfirmDelete(rowData) }] : [],
    componentsAttr: { OverlayLoading: () => <></> }
  };
  return (
    <CollapsibleBox isCollapse={false}>
      <MaterialTable {...tableProps} />
      <Confirm />
    </CollapsibleBox>
  );
};
