import { CLIENTR as CLIENT, API } from "../../../../actions/actionTypes";
import {
  getPropertiesSuccess,
  getSuccess,
  addPropertySuccess,
  setPropertySuccess,
  deletePropertySuccess
} from "../../../../actions/client/insured/policy/property";
import { takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "../../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLIENT.INSURED.POLICY.PROPERTY.GET_PROPERTIES,
    actionSuccess: getPropertiesSuccess,
    errorMessage: "Error loading properties",
    url: `${API.URL}/property/propertypolicy`,
    params: ({ policyId }) => ({ policyId })
  }),
  takeLatest({
    type: CLIENT.INSURED.POLICY.PROPERTY.GET,
    actionSuccess: getSuccess,
    errorMessage: "Error loading a property",
    url: `${API.URL}/property`,
    params: ({ propertyId }) => ({ propertyId })
  }),
  takeLatestPost({
    type: CLIENT.INSURED.POLICY.PROPERTY.POST,
    actionSuccess: addPropertySuccess,
    errorMessage: "Unable to save the information",
    successMessage: "Covered Properties information successfully saved.",
    url: `${API.URL}/property/propertypolicy`,
    params: ({ data }) => data
  }),
  takeLatestSet({
    type: CLIENT.INSURED.POLICY.PROPERTY.SET,
    actionSuccess: setPropertySuccess,
    // errorMessage: "Unable to save the information",
    successMessage: "Covered Properties information successfully saved. ",
    url: `${API.URL}/property`,
    params: ({ data }) => data
  }),
  takeLatestDel({
    type: CLIENT.INSURED.POLICY.PROPERTY.DEL,
    actionSuccess: deletePropertySuccess,
    errorMessage: "Error deleting property from policy",
    successMessage: "Property deleted from policy",
    url: `${API.URL}/property/propertypolicy`,
    params: ({ policyId, propertyId }) => ({ policyId, propertyId })
  })
];
