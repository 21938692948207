import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import LinkCount from "../../../common/LinkCount";
import { useCatOccStyles } from "./index.styles";

const ClaimCatastropheInfo = () => {
  const classes = useCatOccStyles();
  const claim = useSelector(({ claim: { claim: c } }) => c, shallowEqual);
  const { occurrenceNumber, catastropheNumber, occurrenceClaimsCount } = claim;
  return (
    <div className={classes.root}>
      <Grid container spacing={4} alignItems="center">
        <Grid item>
          <small>Catastrophe</small>
          <div>{catastropheNumber === null ? "No Catastrophe" : catastropheNumber}</div>
        </Grid>
        <Grid item>
          <small>Occurrence</small>
          <div>
            {(occurrenceNumber && (
              <LinkCount count={occurrenceClaimsCount}>
                <Grid item>{occurrenceNumber}</Grid>
              </LinkCount>
            )) ||
              "No Occurrence"}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ClaimCatastropheInfo;
