import { FINANCIAL, API } from "../actions/actionTypes";
import { getAllHistorySuccess, getAllProcessingQueueSuccess, getAllScheduledSuccess, getAllSettlementsSuccess } from "../actions/financial";
import { takeLatest } from "./common/sagaGenerator";
import rootSagaGenerator from "./common/rootSagaGenerator";

const url = `${API.URL}/financial`;

export default rootSagaGenerator({
  name: "Financial",
  sagas: [
    takeLatest({
      type: FINANCIAL.PROCESSINGQUEUE.GETALL,
      actionSuccess: getAllProcessingQueueSuccess,
      errorMessage: "Unable to get Payment Processing Queue",
      url: `${url}/processingQueue`
    }),
    takeLatest({
      type: FINANCIAL.SCHEDULED.GETALL,
      actionSuccess: getAllScheduledSuccess,
      errorMessage: "Unable to get Scheduled Payments",
      url: `${url}/scheduledPayments`
    }),
    takeLatest({
      type: FINANCIAL.HISTORY.GETALL,
      actionSuccess: getAllHistorySuccess,
      errorMessage: "Unable to get Payments History",
      url: `${url}/paymentsHistory`
    }),
    takeLatest({
      type: FINANCIAL.SETTLEMENTS.GETALL,
      actionSuccess: getAllSettlementsSuccess,
      errorMessage: "Unable to get Settlements",
      url: `${url}/settlements`
    })
  ]
});
