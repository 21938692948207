import React, { Fragment, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ActionsFooter, { footerAction } from "../../common/ActionsFooter";
import Confirm from "../../common/Confirm";
import { approve, reject } from "../../../redux/actions/vendor";
import { useLoadingContext } from "../../common/Loading";

const PendingFooter = () => {
  const dataSelector = useSelector(
    ({
      vendor: {
        profile: {
          basicInformation: { id, statusId: sId }
        }
      }
    }) => ({ vendorId: id, statusId: sId || 1 }),
    shallowEqual
  );
  const {
    actions: { showLoading, hideLoading },
    loading
  } = useLoadingContext();
  const { vendorId, statusId } = dataSelector || {};

  const [{ openDialog, isApprove }, setState] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const confirmProps = {
    messageText: `Are you sure to ${isApprove ? "approve" : "reject"} this Vendor?`,
    title: "Vendor Approval",
    onAction: () => {
      showLoading();
      setState(s => ({ ...s, openDialog: false }));
      const action = isApprove ? approve : reject;
      const onSuccess = isApprove ? hideLoading : () => history.replace("/vendors/pending");
      return dispatch(action(vendorId, onSuccess, hideLoading));
    },
    openDialog,
    onToggleConfirm: () => setState(s => ({ ...s, openDialog: false })),
    okText: `Yes - ${isApprove ? "Approve" : "Reject"}`
  };
  const onClick = appr => () => setState(s => ({ ...s, openDialog: true, isApprove: appr }));
  return (
    <Fragment key="pending-vendor-footer">
      <ActionsFooter
        shadow
        actions={[
          footerAction({ id: "reject", text: "Reject Vendor", className: "tc-button-danger", disabled: loading, onClick: onClick(false) }),
          footerAction({ id: "isApprove", primary: true, text: "Approve Vendor", disabled: statusId !== 5 || loading, onClick: onClick(true) })
        ]}
        elementId="vendorFooter"
      />
      <Confirm {...confirmProps} />
    </Fragment>
  );
};

export default PendingFooter;
