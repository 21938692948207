import React, { memo } from "react";
import PropTypes from "prop-types";
import { Paper, Grid, Typography } from "@material-ui/core";
import { useSingleOrMultipleStyles } from "./index.styles";
import { useContext, actionTypes } from "../context";
import { BaseRadioButtonGroup } from "../../../common/inputs/RadioButtonGroup";

const SingleOrMultiple = memo(
  ({ multipleClaimants }) => {
    const classes = useSingleOrMultipleStyles();
    const [, , handleChange] = useContext();
    const onChange = e => handleChange(e, actionTypes.SET_MULTIPLE_CLAIMANTS);
    const options = [
      { id: 0, text: "Single Claimant" },
      { id: 1, text: "Multiple Claimants" }
    ];
    return (
      <Paper className={classes.root}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography className="tc-title">Claimants</Typography>
          </Grid>
          <Grid item>
            <BaseRadioButtonGroup row value={multipleClaimants ? 1 : 0} name="multipleClaimants" {...{ onChange, options }} />
          </Grid>
        </Grid>
      </Paper>
    );
  },
  (p, n) => p.multipleClaimants === n.multipleClaimants
);

SingleOrMultiple.propTypes = {
  multipleClaimants: PropTypes.bool.isRequired
};

export default SingleOrMultiple;
