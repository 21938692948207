import React, { Fragment } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import Overview from "../../components/vendors/overview";
import Profile from "../../components/vendors/profile";
import Contacts from "../../components/vendors/contacts";
import Files from "../../components/vendors/files";
import Notes from "../../components/vendors/notes";
import Payments from "../../components/vendors/payments";
import Banking from "../../components/vendors/banking";
import Locations from "../../components/vendors/locations/List";
import PageNotAllowed from "../../components/PageNotAllowed";
import Loading from "../../components/common/Loading";

export const routes = [
  ["overview", "Overview", Overview],
  ["profile", "Profile", Profile, true],
  ["locations", "Locations", Locations],
  ["contacts", "Contacts", Contacts, true],
  ["files", "Files", Files, true],
  ["notes", "Notes", Notes, true],
  ["payments", "Payments", Payments],
  ["banking", "Banking", Banking]
];

const Routes = ({ tabs }) => {
  const { path } = useRouteMatch();
  const allowedRoutes = routes.map(([route, , Comp]) => [route, Comp, tabs.some(t => t.route === route)]);
  return (
    <Fragment key="vendorRoute-fragment">
      {(!tabs.length && <Loading absolute />) || (
        <Switch>
          {allowedRoutes.map(([rPath, Comp, allowed]) => (
            <Route key={rPath} path={`${path}/${rPath}`} render={() => (allowed && <Comp />) || <PageNotAllowed />} />
          ))}
        </Switch>
      )}
    </Fragment>
  );
};

Routes.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired
};

Routes.defaultProps = {};

export default Routes;
