import React, { memo } from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import TextField from "@material-ui/core/TextField";
import { HocInput } from "./HocInput";
import { refPropType } from "../../../common";

export const BaseInputText = memo(
  ({ error, name, rows, forwardRef, value, ...other }) => {
    const props = {
      name,
      value: value || "",
      id: `txt-${name || uuid()}`,
      error: Boolean(error),
      helperText: error,
      ref: forwardRef,
      ...other,
      ...(rows > 0 ? { multiline: true, rows } : {})
    };
    return <TextField fullWidth {...props} />;
  },
  (prev, next) => !["id", "value", "error"].some(key => prev[key] !== next[key])
);

BaseInputText.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  rows: PropTypes.number,
  forwardRef: refPropType()
};

BaseInputText.defaultProps = {
  name: "",
  label: null,
  onChange: null,
  placeholder: "",
  value: undefined,
  error: "",
  rows: 0,
  forwardRef: null
};

export default HocInput(BaseInputText);
