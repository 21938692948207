import { takeLatest } from "redux-saga/effects";
import { CLIENTR as CLIENT, API, CLIENT_DOCUMENTS, CLIENT as CLIENTOLD } from "../../actions/actionTypes";
import { getSuccess } from "../../actions/client/policy";
import { takeLatest as takeLatestSg } from "../common/sagaGenerator";
import * as docs from "../common/documents";

export default [
  takeLatestSg({
    type: CLIENT.POLICY.GET,
    actionSuccess: getSuccess,
    errorMessage: "Error loading Client policies",
    url: `${API.URL}/client/policies`,
    params: ({ clientId }) => ({ clientId })
  }),
  takeLatest(CLIENTOLD.UPLOAD_POLICY_DOCUMENT, docs.uploadFiles(CLIENT_DOCUMENTS.POST_FILES_SUCCESS)),
  takeLatest(CLIENTOLD.OPEN_POLICY_DOCUMENT, docs.openFile(CLIENT_DOCUMENTS.OPEN_FILE_SUCCESS)),
  takeLatest(CLIENTOLD.DELETE_POLICY_DOCUMENT, docs.deleteFile(CLIENTOLD.DELETE_POLICY_DOCUMENT_SUCCESS))
];
