import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { Grid, MenuItem } from "@material-ui/core";
import { CheckboxLabel, MoneyInput, DateTimeInput, SelectInput } from "../../../../common/inputs";
import CollapsibleBox, { useCollapsibleBox } from "../../../../common/CollapsibleBox";
import { get, set } from "../../../../../redux/actions/claim/legal/settled";
import { useRouteParam } from "../../../../../common";
import FooterSave from "../../../../common/FooterSave";
import ReadMode from "./Read";
import { useSettledLogic, useValidation } from "../customHooks";
import Benefits from "./Benefits";

export default props => {
  const claimId = useRouteParam("claimId");
  const data = useSelector(({ claim: { settled: s } }) => s, shallowEqual);
  const { initialState, reserves, access } = useSettledLogic();
  const { handleValidateSchema, validationMessages } = useValidation();
  const [settled, setSettled] = useState(initialState);
  const {
    props: { boxProps, editMode, loading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update && (settled.status === "On Hold" || !settled.id));
  const dispatch = useDispatch();
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get({ claimId, onSuccess: hideLoading, onError: hideLoading }));
    }
  }, []);
  useEffect(() => {
    setSettled(data);
  }, [data]);

  const onChange = ({ target: { name, value } }) => {
    setSettled(ps => {
      handleValidateSchema({ ...ps, [name]: value });
      return { ...ps, [name]: value };
    });
  };

  const symbols = Object.getOwnPropertySymbols(settled);
  const handleSave = event => {
    event.preventDefault();
    if (access.update) {
      if (settled.isCaseSettled && !handleValidateSchema(settled).isValid) return hideLoading();
      showLoading();
      const sdata = {
        ...settled,
        claimId,
        vendorBenefits: (settled.benefits || []).reduce((acc, elem) => {
          if (elem.vendorId && !elem.claimantId) return [...acc, elem.vendorId];
          return acc;
        }, []),
        claimantId: (settled.benefits || []).reduce((acc, elem) => {
          if (elem.claimantId && !elem.vendorId) return elem.claimantId;
          return acc;
        }, null)
      };
      dispatch(set({ settled: sdata, onSuccess: onSuccessEdit, onError: hideLoading }));
    }
    return true;
  };
  const mapReserveTypes = reserveTypeList => {
    return [
      ...reserveTypeList.reduce(
        (acc, rt) => [
          ...acc,
          { ...rt, level: rt.level || 0 },
          ...mapReserveTypes(rt.reserveTypes.map(srt => ({ ...srt, text: srt.name, level: (rt.level || 0) + 1 })))
        ],
        []
      )
    ];
  };
  const treeReserveTypes = mapReserveTypes(reserves || []);
  const delPayee = payee => {
    setSettled(ps => ({
      ...ps,
      benefits: ps.benefits.reduce((acc, elem) => {
        if (elem.claimantId === payee.claimantId && elem.vendorId === payee.vendorId) return acc;
        return [...acc, elem];
      }, [])
    }));
  };
  const addPayee = elem => {
    if (
      !settled.benefits.some(e => e.vendorId && e.vendorId === elem.vendorId) ||
      !settled.benefits.some(e => e.claimantId && e.claimantId === elem.claimantId)
    )
      setSettled(ps => ({ ...ps, benefits: [...ps.benefits, elem] }));
    else toast.error("This payee is in the benefit list.");
  };

  return (
    <CollapsibleBox title="Settled" name="settled" {...boxProps} {...props}>
      {Loading}
      {editMode && access.update && !settled.paymentId && !!(settled.status === "On Hold" || !settled.id) ? (
        <form onSubmit={handleSave}>
          <CheckboxLabel
            label="Case Settled"
            checked={settled.isCaseSettled}
            name="isCaseSettled"
            onChange={onChange}
            disabled={settled[symbols[0]]}
          />
          {settled.isCaseSettled && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} className="tc-no-padding-tb">
                  <SelectInput
                    name="reserveTypeId"
                    label="From Reserve Type"
                    options={treeReserveTypes}
                    renderOption={option => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        style={{ paddingLeft: `${(option.level ?? 0) * 8 + 8}px` }}
                        disabled={option.level === 0 && option.reserveTypes.length > 0}
                      >
                        {option.name}
                      </MenuItem>
                    )}
                    error={validationMessages.reserveTypeId}
                    value={settled.reserveTypeId}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} className="tc-no-padding-tb">
                  <MoneyInput name="demandAmount" label="Demand Amount" onChange={onChange} value={settled.demandAmount} />
                </Grid>
                <Grid item xs={12} md={3} className="tc-no-padding-tb">
                  <MoneyInput name="authorizedAmount" label="Authorized Amount" onChange={onChange} value={settled.authorizedAmount} />
                </Grid>
                <Grid item xs={12} md={3} className="tc-no-padding-tb">
                  <DateTimeInput
                    name="authorizedDate"
                    label="Authorized Date"
                    value={settled.authorizedDate}
                    onChange={e => {
                      e.target.value = e.target.value === "" ? undefined : e.target.value;
                      onChange(e);
                    }}
                    keyboard
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} className="tc-no-padding-tb">
                  <MoneyInput
                    name="settlementAmount"
                    label="Settlement Amount"
                    onChange={onChange}
                    value={settled.settlementAmount}
                    error={validationMessages.settlementAmount}
                  />
                </Grid>
                <Grid item xs={12} md={3} className="tc-no-padding-tb">
                  <DateTimeInput
                    name="settlementDate"
                    label="Settlement Date"
                    value={settled.settlementDate}
                    onChange={e => {
                      e.target.value = e.target.value === "" ? undefined : e.target.value;
                      onChange(e);
                    }}
                    keyboard
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Benefits
                    benefits={settled.benefits}
                    claimantAttorneys={props?.claimantAttorneys || []}
                    delAction={delPayee}
                    addAction={addPayee}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <FooterSave onCancel={onToggleEdit} onSave={handleSave} isDisable={loading} />
        </form>
      ) : (
        <ReadMode />
      )}
    </CollapsibleBox>
  );
};
