import { takeLatest, all } from "redux-saga/effects";
import { INSURED_DOCUMENTS } from "../../../actions/actionTypes";
import * as docs from "../../common/documents";

export default function* root() {
  // eslint-disable-next-line redux-saga/no-unhandled-errors
  yield all([
    takeLatest(INSURED_DOCUMENTS.GET, docs.getByParentId(INSURED_DOCUMENTS.GET_SUCCESS)),
    takeLatest(INSURED_DOCUMENTS.DEL_FILE, docs.deleteFile(INSURED_DOCUMENTS.DEL_FILE_SUCCESS)),
    takeLatest(INSURED_DOCUMENTS.SET_FILE, docs.updateFile(INSURED_DOCUMENTS.SET_FILE_SUCCESS)),
    takeLatest(INSURED_DOCUMENTS.POST_FOLDER, docs.addFolder(INSURED_DOCUMENTS.POST_FOLDER_SUCCESS)),
    takeLatest(INSURED_DOCUMENTS.SET_FOLDER, docs.updateFolder(INSURED_DOCUMENTS.SET_FOLDER_SUCCESS)),
    takeLatest(INSURED_DOCUMENTS.DEL_FOLDER, docs.deleteFolder(INSURED_DOCUMENTS.DEL_FOLDER_SUCCESS)),
    takeLatest(INSURED_DOCUMENTS.POST_FILES, docs.uploadFiles(INSURED_DOCUMENTS.POST_FILES_SUCCESS)),
    takeLatest(INSURED_DOCUMENTS.DOWNLOAD_FILES, docs.downloadFiles(INSURED_DOCUMENTS.DOWNLOAD_FILES_SUCCESS))
  ]);
}
