import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: { padding: theme.spacing(1.5, 2, 0, 2), height: "100%" },
  container: { marginBottom: theme.spacing(2) },
  item: { backgroundColor: "#DAEAEF", padding: theme.spacing(2), paddingBottom: "0px" },
  itemRead: { backgroundColor: "#fff", padding: theme.spacing(2), paddingBottom: "0px" },
  itemByDate: { margin: theme.spacing(2), borderLeft: "solid 2px #ccc" },
  title: { color: "#004763", fontSize: 16, fontWeight: 500 },
  time: { color: "#898f91" },
  readIcon: { fontSize: 10, color: "#898f91", verticalAlign: "middle" },
  icon: { fontSize: 10, color: "#107a98", verticalAlign: "middle" },
  divider: { marginTop: theme.spacing(1.5) },
  searchContainer: {
    margin: theme.spacing(2),
    borderBottom: "solid 1px #C4C7C8",
    borderTop: "solid 1px #C4C7C8",
    paddingTop: "15px",
    paddingBottom: "15px"
  }
}));
