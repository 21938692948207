import { INITIALREPORT as REPORT, API } from "../../../actions/actionTypes";
import { getSuccess, setSuccess } from "../../../actions/initialReport/bodilyInjury";
import { takeLatest, takeLatestSet } from "../../common/sagaGenerator";

const url = `${API.URL}/bodilyInjury/`;

export default [
  takeLatest({
    type: REPORT.INFORMATION.BODILYINJURY.GET,
    actionSuccess: getSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading Bodily Injury",
    url: ({ id }) => `${url}${id}`
  }),
  takeLatestSet({
    type: REPORT.INFORMATION.BODILYINJURY.SET,
    actionSuccess: setSuccess,
    params: ({ bodilyInjury }) => bodilyInjury,
    successMessage: "Error saving Bodily Information",
    errorMessage: "Error saving Bodily Information",
    url
  })
];
