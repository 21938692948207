import { useSelector, shallowEqual } from "react-redux";

const useNote = entityType => {
  const claimInfo = entityType === "claim" ? useSelector(({ claim: { claim } }) => claim, shallowEqual) : {};
  return {
    notes: useSelector(({ [entityType]: { notes } }) => notes, shallowEqual),
    draftNote: useSelector(({ [entityType]: { draftNote } }) => draftNote, shallowEqual),
    associatedList:
      (entityType === "occurrence" || entityType === "catastrophe"
        ? useSelector(
            ({
              [entityType]: {
                [entityType]: { associatedList: assoc }
              }
            }) => assoc,
            shallowEqual
          )
        : []) || [],
    noteType: useSelector(
      ({
        admin: {
          claimSettings: { noteTypes }
        }
      }) => noteTypes,
      shallowEqual
    ).filter(
      item =>
        (entityType === "claim" &&
          item.parentId === 1 &&
          item.claimLineOfBusinessId === claimInfo.claimLineOfBusinessId &&
          item.claimLossTypeGroupId === claimInfo.claimLossTypeGroupId) ||
        item.id === 2 ||
        item.id === 3 ||
        (entityType === "occurrence" && item.id === 2) ||
        (entityType === "catastrophe" && item.id === 3)
    ),
    occurrence: entityType === "occurrence" ? useSelector(({ [entityType]: { [entityType]: entity } }) => entity, shallowEqual) : {},
    catastrophe: entityType === "catastrophe" ? useSelector(({ [entityType]: { [entityType]: entity } }) => entity, shallowEqual) : {}
  };
};

export default useNote;
