import { takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { CLAIM, API } from "../../actions/actionTypes";
import * as actions from "../../actions/claim/coverage";
import sagaGenerator, { takeLatestSet } from "../common/sagaGenerator";

export default [
  takeLatest(
    CLAIM.COVERAGE.GET,
    sagaGenerator({
      url: `${API.URL}/claim/policy`,
      params: ({ claimId }) => ({ claimId }),
      actionSuccess: actions.getSuccess,
      onError: () => toast.error("Error loading Coverage")
    })
  ),
  takeLatest(
    CLAIM.COVERAGE.POLICIES_BY_LOB_INSURED.GETALL,
    sagaGenerator({
      url: `${API.URL}/claim/policiesbylob`,
      params: ({ claimId }) => ({ claimId }),
      actionSuccess: actions.getPoliciesByLineOfBusinessSuccess,
      onError: () => toast.error("Error loading Policy By LOB")
    })
  ),
  takeLatestSet({
    type: CLAIM.COVERAGE.POLICIES_BY_LOB_INSURED.SET,
    url: `${API.URL}/claim/activepolicy`,
    actionSuccess: actions.setClaimPolicyAsActiveSuccess,
    successMessage: "Policy successfully applied",
    errorMessage: "Unable to apply the policy",
    params: ({ data }) => data
  })
];
