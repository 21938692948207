import { INITIALREPORT, API } from "../../actions/actionTypes";
import { getAllSuccess, getSecProfFilterSuccess } from "../../actions/initialReport/reports";
import { takeLatest } from "../common/sagaGenerator";

export default [
  takeLatest({
    type: INITIALREPORT.GETALL,
    actionSuccess: getAllSuccess,
    errorMessage: "Error loading reports",
    url: `${API.URL}/initialreport`
  }),
  takeLatest({
    type: INITIALREPORT.GET_SECPROF_FILTER,
    actionSuccess: getSecProfFilterSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading Filters Fields",
    url: `${API.URL}/claim/filterfields`
  })
];
