import { API } from "../../../actions/actionTypes";
import { takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "../../common/sagaGenerator";

export default ({ type, actionSuccess, url }) => ({
  profile: {
    get: takeLatest({
      type,
      actionSuccess,
      params: ({ id }) => ({ id }),
      errorMessage: "Error loading client profile",
      url: `${API.URL}/client/profile`
    }),
    set: takeLatestSet({
      type,
      actionSuccess,
      params: ({ data }) => data,
      url: url ? `${API.URL}/client/${url}` : `${API.URL}/client`
    }),
    status: {
      set: takeLatestSet({
        type,
        actionSuccess,
        params: ({ data }) => data,
        url: `${API.URL}/client/status`
      })
    },
    code: {
      post: takeLatestPost({
        type,
        actionSuccess,
        params: ({ clientId, codeId }) => ({ clientId, codeId }),
        successMessage: "Code successfully Added.",
        errorMessage: "Unable to add the code.",
        url: `${API.URL}/client/code`
      }),
      del: takeLatestDel({
        type,
        actionSuccess,
        params: ({ clientId, codeId }) => ({ clientId, codeId }),
        successMessage: "Code successfully deleted.",
        errorMessage: "Unable to delete the code.",
        url: `${API.URL}/client/code`
      })
    },
    area: {
      del: takeLatestDel({
        type,
        actionSuccess,
        successMessage: "Area successfully deleted.",
        errorMessage: "Unable to delete the Area",
        url: `${API.URL}/client/deleteArea`,
        params: ({ data }) => data
      }),
      post: takeLatestPost({
        type,
        actionSuccess,
        successMessage: "Area successfully added.",
        errorMessage: "Unable to add the Area",
        url: `${API.URL}/client/addArea`,
        params: ({ data }) => data
      }),
      set: takeLatestSet({
        type,
        actionSuccess,
        successMessage: "Area successfully updated ",
        errorMessage: "Unable to update the Area",
        url: `${API.URL}/client/setArea`,
        params: ({ data }) => data
      })
    },
    operating: {
      del: takeLatestDel({
        type,
        actionSuccess,
        successMessage: "Operating State successfully deleted.",
        errorMessage: "Unable to delete the Operating State",
        url: `${API.URL}/client/deleteOperatingState`,
        params: ({ data }) => data
      }),
      post: takeLatestPost({
        type,
        actionSuccess,
        successMessage: "Operating State successfully added.",
        errorMessage: "Unable to add the Operating State",
        url: `${API.URL}/client/addOperatingState`,
        params: ({ data }) => data
      }),
      set: takeLatestSet({
        type,
        actionSuccess,
        successMessage: "Operating State successfully updated ",
        errorMessage: "Unable to update the Operating State",
        url: `${API.URL}/client/setOperatingState`,
        params: ({ data }) => data
      })
    }
  }
});
