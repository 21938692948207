import { CLAIM, API } from "../../../actions/actionTypes";
import {
  getClaimantAttorneySuccess,
  postClaimantAttorneySuccess,
  setClaimantAttorneySuccess,
  delClaimantAttorneySuccess,
  getAttorneyByFirmIdSuccess
} from "../../../actions/claim/legal/attorney";
import { takeLatest, takeLatestPost, takeLatestSet, takeLatestDel } from "../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLAIM.LEGAL.ATTORNEYBYFIRM.GET,
    url: `${API.URL}/attorney/getByFirmId`,
    params: ({ id }) => ({ id }),
    actionSuccess: getAttorneyByFirmIdSuccess,
    errorMessage: "Error loading claimant attorney"
  }),
  takeLatest({
    type: CLAIM.LEGAL.CLAIMANTATTORNEY.GET,
    url: `${API.URL}/attorney/attorneysByClaimant`,
    params: ({ claimId }) => ({ claimId }),
    actionSuccess: getClaimantAttorneySuccess,
    errorMessage: "Error loading claimant attorney"
  }),
  takeLatestPost({
    type: CLAIM.LEGAL.CLAIMANTATTORNEY.POST,
    url: `${API.URL}/attorney/claimantAttorney`,
    params: ({ claimantAttorney }) => claimantAttorney,
    actionSuccess: postClaimantAttorneySuccess,
    successMessage: "Claimant attorney successfully added",
    errorMessage: "Unable to save the information"
  }),
  takeLatestSet({
    type: CLAIM.LEGAL.CLAIMANTATTORNEY.SET,
    url: `${API.URL}/attorney/updateClaimAttorney`,
    params: ({ claimantAttorney }) => claimantAttorney,
    actionSuccess: setClaimantAttorneySuccess,
    successMessage: "Claimant attorney successfully edited",
    errorMessage: "Unable to save the information"
  }),
  takeLatestDel({
    type: CLAIM.LEGAL.CLAIMANTATTORNEY.DEL,
    url: `${API.URL}/attorney/claimAttorney`,
    params: ({ claimantAttorney: { claimAttorneyId, claimId, claimantId, insuredId } }) => ({ claimAttorneyId, claimId, claimantId, insuredId }),
    actionSuccess: delClaimantAttorneySuccess,
    successMessage: "Claimant attorney successfully deleted",
    errorMessage: "Unable to save the information"
  })
];
