import React from "react";
import PropTypes from "prop-types";
import { Input, InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";

const SearchInput = ({ name, ...other }) => {
  const props = {
    ...other,
    name,
    id: `search-${name}`,
    startAdornment: (
      <InputAdornment position="start">
        <Search />
      </InputAdornment>
    )
  };
  return <Input {...props} />;
};

SearchInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

SearchInput.defaultProps = {
  placeholder: "Search...",
  value: ""
};

export default SearchInput;
