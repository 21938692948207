import { CLAIM } from "../../actionTypes";

const get = claimId => ({ type: CLAIM.INFO.WITNESS.GET, claimId });
const getSuccess = witness => ({ type: CLAIM.INFO.WITNESS.GET_SUCCESS, witness });

const getAll = (claimId, onSuccess, onError) => ({ type: CLAIM.INFO.WITNESS.GETALL, claimId, onSuccess, onError });
const getAllSuccess = data => ({ type: CLAIM.INFO.WITNESS.GETALL_SUCCESS, data });

const set = (witness, onSuccess, onError) => ({ type: CLAIM.INFO.WITNESS.SET, witness, onSuccess, onError });
const setSuccess = data => ({ type: CLAIM.INFO.WITNESS.SET_SUCCESS, data });
const post = (witness, onSuccess, onError) => ({ type: CLAIM.INFO.WITNESS.POST, witness, onSuccess, onError });
const postSuccess = data => ({ type: CLAIM.INFO.WITNESS.POST_SUCCESS, data });
const del = (id, onSuccess, onError) => ({ type: CLAIM.INFO.WITNESS.DEL, id, onSuccess, onError });
const delSuccess = data => ({ type: CLAIM.INFO.WITNESS.DEL_SUCCESS, data });

export { getAll, getAllSuccess, get, getSuccess, set, setSuccess, post, postSuccess, del, delSuccess };
