import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import InsuredViewMode from "../../../common/claims/insuredVehicle/ViewMode";
import InsuredVehicleForm from "../../../common/claims/insuredVehicle/Form";
import { set, get } from "../../../../redux/actions/claim/info/insuredVehicle";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import { useRouteParam } from "../../../../common";

export default props => {
  const claimId = useRouteParam("claimId");
  const insuredVehicle = useSelector(({ claim: { insVehicle: iv } }) => iv, shallowEqual);
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info") || {};
  const {
    props: { boxProps, editMode, loading: isLoading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  const dispatch = useDispatch();
  const handleSave = data => {
    showLoading();
    dispatch(set(data, onSuccessEdit, hideLoading));
  };
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  return (
    <CollapsibleBox title="Insured Vehicle" name="insuredV" {...boxProps} {...props}>
      {Loading}
      {(editMode && access.update && <InsuredVehicleForm {...{ insuredVehicle, onClose: onToggleEdit, onSave: handleSave, isLoading }} />) || (
        <InsuredViewMode {...{ insuredVehicle }} />
      )}
    </CollapsibleBox>
  );
};
