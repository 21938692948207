import PropTypes from "prop-types";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { useRouteParam } from "../../../../../common";
import { getPolicy, postPolicy, setPolicy } from "../../../../../redux/actions/client/insured/policy/policy";
import useQueryString from "../../../../common/customHooks/useQueryString";
import useYupValidationSchema from "../../../../common/customHooks/useYupValidationSchema";
import { useLoadingContext } from "../../../../common/Loading";
import { formSchema } from "./yup";

export const initialState = type => ({
  policyTypeId: type.id,
  policyType: type.text,
  financial: {},
  documents: [],
  ...(type.id === 1
    ? {
        aggregateDetails: {
          attachmentBasis: "",
          attachmentBasisId: null,
          attachmentPoint: null,
          longRunFrom: 0,
          longRunTo: 0,
          limit: 0,
          retentionDeductible: null,
          maximumRetention: null
        }
      }
    : { coverage: { coverageDetailList: [] }, coverageList: [], codeList: [], stateList: [] }),
  ...(type.id === 2 ? { vehicles: [] } : {}),
  ...(type.id === 4 ? { properties: [] } : {})
});

const excludeProps = ["aggregateDetails", "coverage", "coverageList", "documents", "policyType", "properties", "vehicles"];

const PolicyContext = createContext();

export const PolicyContextProvider = ({ isAdd, level, ...props }) => {
  const { ptid } = isAdd ? useQueryString() : {};
  const { url } = useRouteMatch();
  const pTypes = useSelector(({ common: { policyTypes } }) => policyTypes, shallowEqual);
  const listRoute = url.substr(0, url.lastIndexOf("/"));
  const policyId = !isAdd ? useRouteParam(`policyId`) : undefined;
  const {
    loading,
    actions: { showLoading, hideLoading }
  } = useLoadingContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const insuredId = useRouteParam(`${level}Id`);
  const policy = !isAdd ? useSelector(({ client: { policy: p } }) => p, shallowEqual) : undefined;
  const type = pTypes.find(t => String(t.id) === String(isAdd ? ptid : policy?.insuredPolicy?.policyTypeId)) || {};
  if (isAdd && !type.id) return <Redirect to={listRoute} />;
  const [state, setState] = useState({ ...initialState(type), insuredId });
  const buildPolicy = p => {
    const pType = pTypes.find(t => t.id === p.policyTypeId) || {};
    const initState = initialState(pType);
    setState({ ...initState, insuredId, ...Object.keys(p).reduce((a, k) => ({ ...a, [k]: p[k] || initState[k] }), {}) });
    return hideLoading();
  };
  useEffect(() => {
    if (!isAdd) {
      showLoading();
      dispatch(
        getPolicy(policyId, hideLoading, () => {
          hideLoading();
          return history.replace(listRoute);
        })
      );
    }
  }, [isAdd, policyId]);
  useEffect(() => {
    if (!isAdd && policy?.insuredPolicy?.id === policyId) buildPolicy(policy.insuredPolicy);
  }, [isAdd, policyId, policy]);
  const [formOpen, setFormOpen] = useState(false);
  const { handleValidateSchema, validationMessages: error } = useYupValidationSchema(formSchema(type.id, isAdd));
  const ready = isAdd || state.id === policyId;
  const actions = {
    hideLoading,
    onValidate: ext => handleValidateSchema({ ...state, ...(ext || {}) }),
    onCancel: () => {
      hideLoading();
      return history.push(listRoute);
    },
    onFormOpen: useCallback(open => setFormOpen(!!open), []),
    onSubmit: (data, onSuccess) => {
      showLoading();
      const params = {
        ...Object.keys(data)
          .filter(k => !excludeProps.some(p => p === k))
          .reduce((a, k) => ({ ...a, [k]: data[k] }), {}),
        insuredId,
        policyTypeId: type.id,
        ...(type.id !== 1 ? { coverageList: data.coverageList } : {}),
        ...(isAdd
          ? {
              documents: data.documents.map(({ file }) => file),
              ...(type.id === 1 ? { aggregateDetails: data.aggregateDetails } : {}),
              ...(type.id === 2
                ? {
                    policyVehicles: data.vehicles.map(v => ({
                      ...v,
                      id: undefined,
                      vehicleYear: parseInt(v.vehicleYear, 0),
                      number: parseInt(v.number, 0)
                    }))
                  }
                : {}),
              ...(type.id === 4 ? { properties: data.properties.map(p => ({ property: { ...p, propertyId: undefined } })) } : {})
            }
          : {})
      };
      if (isAdd) return dispatch(postPolicy(params, actions.onCancel, hideLoading));
      return dispatch(setPolicy(params, onSuccess, hideLoading));
    },
    resetPolicy: () => buildPolicy(policy.insuredPolicy),
    setState,
    showLoading
  };
  return <PolicyContext.Provider value={{ actions, error, formOpen, isAdd, loading, policyId, ready, state, type }} {...props} />;
};

PolicyContextProvider.propTypes = { isAdd: PropTypes.bool, level: PropTypes.string.isRequired };
PolicyContextProvider.defaultProps = { isAdd: false };

export const usePolicyContext = () => useContext(PolicyContext);
