import React, { Fragment } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Basic from "./basic";
import Industry from "./Industry";
import PendingOverview from "./PendingOverview";
import useOnToggleBoxes from "../../common/customHooks/useHandleBox";
import { getUserAccess } from "../../../common";
import PendingFooter from "./PendingFooter";

export default () => {
  const { open, onToggle } = useOnToggleBoxes({ initialValue: { basic: true, industry: true } });
  const dataSelector = useSelector(
    ({
      vendor: {
        profile: {
          basicInformation: { statusId, location }
        }
      }
    }) => ({ pending: (statusId || 1) !== 1, stateId: location?.stateId }),
    shallowEqual
  );

  const { pending, stateId } = dataSelector || {};

  const securityProfilesDetails = useSelector(({ profile: { securityProfilesDetails: d } }) => d, shallowEqual);
  const actionsAllowed = getUserAccess(securityProfilesDetails, "VENDORS.PROFILE", null, stateId, null);
  return (
    <Fragment key="vendor-profile-page">
      {pending && <PendingOverview />}
      <Basic {...{ open: open.basic, onToggle, actionsAllowed }} />
      <Industry {...{ open: open.industry, onToggle, actionsAllowed }} />
      {pending && <PendingFooter />}
    </Fragment>
  );
};
