import { ADMIN } from "../../actionTypes";

const success = type => diaries => ({ type, diaries });

export const getAll = (onSuccess, onError) => ({ type: ADMIN.TEMPLATE_DIARY.GETALL, onSuccess, onError });
export const getAllSuccess = success(ADMIN.TEMPLATE_DIARY.GETALL_SUCCESS);
export const set = (data, onSuccess, onError) => ({ type: ADMIN.TEMPLATE_DIARY.SET, data, onSuccess, onError });
export const setSuccess = success(ADMIN.TEMPLATE_DIARY.SET_SUCCESS);
export const del = (id, onSuccess, onError) => ({ type: ADMIN.TEMPLATE_DIARY.DEL, id, onSuccess, onError });
export const delSuccess = success(ADMIN.TEMPLATE_DIARY.DEL_SUCCESS);
