import * as yup from "yup";
import { isNumber } from "../../../../../common/yup";

const benefit = yup.object().shape({
  payeeTypeId: yup.number().required("Payee Type Required"),
  name: yup.string().required("Payee Required"),
  percent: yup.number().required("Percent is Required")
});

const benefits = yup
  .array()
  .of(benefit)
  .test("benefits", "Add at least one Payee", (val, context) => Boolean(val.length > 0) || context.createError({ path: "toast" }));

const schedule = yup.object().shape({
  recurrenceTypeId: yup.number().required("Recurrence Type is Required"),
  reserveTypeId: yup.number().required("Reserve Type is Required"),
  amount: yup
    .number()
    .nullable()
    .test("", "Amount is Required", v => isNumber(v) > 0),
  benefitStartDate: yup.date().required("Benefit Start Date is Required"),
  numberOfPayments: yup
    .number()
    .nullable()
    .test("", "Number of Payments is Required", v => isNumber(v) > 0),
  paymentBenefitFull: benefits
});

export { schedule, benefits };
