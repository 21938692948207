import SettingIcon from "../../assets/admin_setting.svg";
import UserIcon from "../../assets/user_icon.svg";
import TemplateIcon from "../../assets/icon_template.svg";

export default [
  {
    order: 1,
    section: "claim",
    subRoutes: ["overview", "documents", "notes", "reserves"],
    style: { borderLeft: "initial" },
    Icon: SettingIcon,
    title: "Claims setting",
    text: "The access to manage claim's settings: Overview, Folder Structures, Note Types, and Reserve Types",
    link: "/setting"
  },
  {
    order: 2,
    section: "users",
    subRoutes: [],
    Icon: UserIcon,
    title: "Users",
    text: "The access to create users and assign the corresponding permissions",
    link: "/user"
  },
  {
    order: 3,
    section: "templates",
    subRoutes: ["communications", "diaries"],
    Icon: TemplateIcon,
    title: "Templates Library",
    text: "Templates to use in communications or in diaries",
    link: "/templates"
  }
];
