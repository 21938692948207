import PROFILE from "../actions/actionTypes/profile";
import initialState from "./initialState";

export default function occurrenceReducer(state = initialState.profile, action) {
  switch (action.type) {
    case PROFILE.GET_SUCCESS:
    case PROFILE.SET_SUCCESS: {
      const { profile } = action;
      return { ...profile, photo: state.photo && !profile.photo ? state.photo : profile.photo };
    }
    case PROFILE.POST_SUCCESS:
    case PROFILE.PHOTO.GET_SUCCESS:
      return { ...state, photo: action.photo };
    case PROFILE.DEL_SUCCESS:
      return { ...state, photo: undefined };
    default:
      return state;
  }
}
