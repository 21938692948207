import React, { memo } from "react";
import PropTypes from "prop-types";
import { FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import OccurrenceOption from "./OccurrenceOption";
import { useOccurrenceOptionStyles } from "./index.styles";

const OccurrenceRadioGroup = memo(({ occurrences, ...other }) => {
  const classes = useOccurrenceOptionStyles();
  return (
    <FormControl fullWidth component="fieldset">
      <RadioGroup {...other} className={classes.radio}>
        {occurrences.map(o => (
          <FormControlLabel
            className={classes.root}
            key={o.id}
            control={<Radio />}
            value={o.id}
            label={<OccurrenceOption {...{ option: o, classes }} />}
          />
        ))}
        <FormControlLabel control={<Radio />} value={-1} label="Create New Occurrence" />
        <FormControlLabel control={<Radio />} value={-2} label="No Occurrence" />
      </RadioGroup>
    </FormControl>
  );
});

OccurrenceRadioGroup.propTypes = {
  occurrences: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default OccurrenceRadioGroup;
