import React, { Fragment, memo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import useOnToggleBoxes from "../../common/customHooks/useHandleBox";
import CollapsibleBox from "../../common/CollapsibleBox";
import CodeList from "../../common/code/List";
import MaterialTable from "../../common/table/MaterialTable";
import { IntegerInput, SelectInput } from "../../common/inputs";

const Industry = ({ codes, operatingStates = [], dispatch, levelIndex, nextLevelId }) => {
  const { open, onToggle } = useOnToggleBoxes({ initialValue: { industry: true } });
  const { states, codeType: codeTypes, codeDivision, codes: storeCodes } = useSelector(store => store.common);

  const handleCode = data => {
    const codeType = codeTypes.find(t => t.id === data.codeTypeId).text;
    const codeDivisionName = (codeDivision.find(t => t.id === data.divisionId) || {}).text;
    const codeName = storeCodes.find(t => t.id === data.id).text;
    dispatch({ type: "ADD_CODE", payload: { code: { ...data, codeType, codeDivisionName, codeName } } });
  };

  const handleAddOperatingState = data => {
    dispatch({ type: "ADD_OPERATING_STATE", payload: { data: { ...data, state: states.find(s => s.id === data.stateId).text } } });
  };

  const handleUpdateOperatingState = data => {
    dispatch({ type: "SET_OPERATING_STATE", payload: { data: { ...data, state: states.find(s => s.id === data.stateId).text } } });
  };

  const handleDeleteOperatingState = data => {
    dispatch({ type: "DELETE_OPERATING_STATE", payload: { data } });
  };

  const dataColumns = () => {
    return {
      stateCounter: [
        {
          title: "State",
          field: "stateId",
          editComponent: propsRow => {
            return (
              <SelectInput
                onChange={e => propsRow.onChange(e.target.value)}
                options={states.filter(s => !operatingStates.some(op => op.stateId === s.id && op.text === propsRow.rowData.state))}
                value={propsRow.value || null}
                error={propsRow.value ? "" : "State is required"}
              />
            );
          },
          validate: rowData => Boolean(rowData.stateId) || "State is Required",
          render: rowData => rowData?.state
        },
        {
          title: "Clients",
          field: "clients",
          editComponent: propsRow => {
            return (
              <IntegerInput
                onChange={e => propsRow.onChange(e.target.value)}
                value={propsRow.value || null}
                error={propsRow.value ? "" : "Clients Number is Required"}
              />
            );
          },
          validate: rowData => Boolean(rowData.clients) || "Clients Number is Required",
          render: rowData => rowData?.state
        }
      ]
    };
  };
  const { stateCounter } = dataColumns();

  const handleRemoveCode = data => {
    dispatch({ type: "REMOVE_CODE", payload: { id: data.id } });
  };

  const dataValidation = (state, clients) => {
    return Boolean(state) && Boolean(clients);
  };
  return (
    <Fragment key="industry-Code-List">
      <CollapsibleBox
        title="Industry"
        name="industry"
        {...{
          open: open.industry,
          onToggle
        }}
      >
        <CodeList codes={codes} onAdd={handleCode} onDelete={handleRemoveCode} viewMode={false} />
        {/* // Ids are Division and location level. Operating States shouldn't show in this component. */}

        {![5, 6].some(level => level === levelIndex || level === nextLevelId) && (
          <MaterialTable
            columns={stateCounter}
            editable={{
              onRowAdd: row => {
                return new Promise(resolve => {
                  if (dataValidation(row.stateId, row.clients)) resolve(handleAddOperatingState(row));
                });
              },
              onRowUpdate: row => {
                return new Promise(resolve => {
                  if (dataValidation(row.stateId, row.clients)) resolve(handleUpdateOperatingState(row));
                });
              },
              onRowDelete: row => {
                return new Promise(resolve => {
                  resolve(handleDeleteOperatingState(row));
                });
              }
            }}
            data={operatingStates}
            hidePaging={operatingStates.length < 5}
            options={{ headerStyle: { zIndex: 0 } }}
          />
        )}
      </CollapsibleBox>
    </Fragment>
  );
};

Industry.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatch: PropTypes.func.isRequired,
  operatingStates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  levelIndex: PropTypes.number,
  nextLevelId: PropTypes.number
};

Industry.defaultProps = {
  levelIndex: 0,
  nextLevelId: 0
};

export default memo(Industry);
