import React, { Fragment, useEffect, useRef, useState } from "react";
import { Redirect, useRouteMatch, useLocation, Link } from "react-router-dom";
import { Tab } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getUserAccess } from "../../common";
import DocumentRoutes from "./Routes";
import SimpleTabs from "../../components/common/tabs/SimpleTabs";
import ActionsAllowed from "../../components/common/contexts/ActionsAllowed";
import { getReserveTypes } from "../../redux/actions/admin/claimsSetting";

export default () => {
  const { isExact, url } = useRouteMatch();
  const { pathname } = useLocation();
  const store = useSelector(({ profile }) => ({ userProfile: profile }), shallowEqual);
  const { userProfile } = store || {};
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getReserveTypes());
    // return () => dispatch(reset());
  }, []);
  const [tabsConfig] = useState([
    ["indexing", "Indexing"],
    ["reindexing", "Re-Indexing"],
    ["review", "Special review queue"]
  ]);
  const routesAllowed = tabsConfig.reduce((acc, [route]) => {
    if (!userProfile.securityProfilesDetails?.length) return acc;
    const routeAccess = {
      ...getUserAccess(userProfile.securityProfilesDetails, `DOCUMENT.${route.toUpperCase()}`, null, null, null),
      route
    };
    return [...acc, routeAccess];
  }, []);
  const isRouteRead = r => routesAllowed.some(({ route, read }) => route === r && read);
  const tabsToShow = tabsConfig.reduce((acc, [route, label]) => (isRouteRead(route) ? [...acc, [route, label]] : acc), []);
  const container = useRef(null);
  return (
    <Fragment key="claim-page">
      {isExact && <Redirect to={`${url}/${tabsToShow[0][0]}`} />}
      <div ref={container} className="tc-page-container">
        <SimpleTabs value={url === pathname ? `${url}/indexing` : pathname}>
          {tabsToShow.map(([route, label]) => {
            return <Tab key={route} label={label} component={Link} to={`${url}/${route}`} value={`${url}/${route}`} />;
          })}
        </SimpleTabs>
        <ActionsAllowed.Provider value={routesAllowed}>
          <DocumentRoutes containerRef={container} />
        </ActionsAllowed.Provider>
      </div>
    </Fragment>
  );
};
