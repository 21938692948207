import * as yup from "yup";
import { isBeforeToday, isNumber, rootParent, isEmail } from "../../../../common/yup";

const evalClaimant = (val, fnValidate, validated) => validated || (typeof fnValidate === "function" ? fnValidate : Boolean)(val);
const evalMailing = fnValidate => (val, context) => {
  const { phisicalAsMailing } = rootParent(context);
  return evalClaimant(val, fnValidate, phisicalAsMailing);
};
const string = () => yup.string().nullable();
const number = () => yup.number().nullable();
const date = () => yup.date().nullable();

export default yup.object().shape({
  isPerson: yup.boolean(),
  phisicalAsMailing: yup.boolean(),
  firstName: string().test("isPerson", "First Name Required", (v, c) => evalClaimant(!c?.parent?.isPerson || v)),
  lastName: string().test("isPerson", "Last Name Required", (v, c) => evalClaimant(!c?.parent?.isPerson || v)),
  dateofBirth: date().test("isPerson", "Date of Birth Valid Required", (v, c) => evalClaimant(!c?.parent?.isPerson || isBeforeToday(v))),
  commercialName: string().test("isNotPerson", "Commercial Name Required", (v, c) => evalClaimant(c?.parent?.isPerson || v)),
  contact: string().test("isNotPerson", "Contact Name Required", (v, c) => evalClaimant(c?.parent?.isPerson || v)),
  taxIDTypeId: number().required("Tax ID Type Required"),
  taxID: string().required("Tax ID Required"),
  genderId: number()
    .moreThan(0, "Gender Required")
    .required("Gender Required"),
  workEmail: string().test("", "Invalid Email", v => !v || isEmail(v)),
  email: string().test("", "Invalid Email", v => !v || isEmail(v)),
  location: yup.object().shape({
    address1: string().required("Address 1 Required"),
    city: string().required("City Required"),
    stateId: number().required("State Required"),
    zipCode: string().required("Zip Code Required")
  }),
  mailAddress: yup.object().shape({
    address1: string().test("default", "Address 1 Required", evalMailing()),
    city: string().test("default", "City Required", evalMailing()),
    stateId: number().test("default", "State Required", evalMailing(isNumber)),
    zipCode: string().test("default", "Zip Code Required", evalMailing())
  })
});
