import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 0, 0, 0),
    height: "100%"
  },
  container: {
    padding: theme.spacing(2, 2, 2, 2)
  },
  claimContainer: { padding: theme.spacing(0, 0.6, 0, 0), margin: theme.spacing(0, 0, 0, 0) },
  claimNo: { color: "#000", fontSize: 19, fontWeight: 500, padding: theme.spacing(0.4, 1, 1, 2) },
  claimant: { color: "#000", fontSize: 15, fontWeight: 500 },
  spnIcon: { "& svg": { fontSize: 20 } },
  label: { fontSize: 12, color: "#898F91" },
  claiminfo: { fontSize: 14, color: "#000" },
  link: { marginBottom: -3, color: "#107A98" },
  status: { borderRadius: "50%", padding: 5, width: 24, height: 24, "& svg": { fontSize: 14 } },
  open: { backgroundColor: "#D2EEB6", "& svg": { color: "#428302" } },
  close: { backgroundColor: "#FCB7B0", "& svg": { color: "#E74C3C" } },
  reopen: { backgroundColor: "#7C9DAA", "& svg": { color: "#004763", transform: "scaleX(-1)" } },
  voided: { backgroundColor: "#F6C096", "& svg": { color: "#FE7F1E" } },
  pending: { backgroundColor: "#FCF3BC", "& svg": { color: "#EFD10F" } },
  pStatus: { fontSize: 20, marginBottom: -6 },
  accepted: { color: "#428302" },
  denied: { color: "#E74C3C" },
  pPending: { borderRadius: "50%", padding: 3, width: 24, height: 24, border: "solid 2px #efd10f", "& svg": { fontSize: 14, color: "#efd10f" } },
  rectangle: { backgroundColor: "#DAEAEF", borderRadius: 4, padding: theme.spacing(0.5, 1) },
  associateButton: { fontSize: 14 },
  exitToApp: { color: "#707070", padding: theme.spacing(0, 0, 0, 0), margin: theme.spacing(0, 0, 0, 0), width: 24, height: 24 },
  padding: { padding: theme.spacing(1, 1, 1, 2.6) },
  item: { padding: theme.spacing(0, 0, 2.5, 0) },
  claimantSection: { padding: theme.spacing(0, 2) },
  containerAssoc: { alignSelf: "flex-end", marginRight: 0, textAlign: "right" }
}));
