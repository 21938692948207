import React, { memo } from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel, FormHelperText, FormControl } from "@material-ui/core";
import { HocInput } from "./HocInput";

export const BaseCheckboxLabel = memo(
  ({ checked, name, onChange, error, ...other }) => {
    const onCheckChange = ({ target: { checked: isChecked } }) => onChange({ target: { name, value: isChecked } });
    const control = <Checkbox {...{ checked, onChange: onCheckChange }} color="primary" />;
    return (
      <FormControl error={Boolean(error)}>
        <FormControlLabel {...{ control, ...other }} />
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  },
  (p, n) => !["checked", "error", "disabled"].some(k => p[k] !== n[k])
);

BaseCheckboxLabel.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string
};

BaseCheckboxLabel.defaultProps = {
  checked: false,
  name: "",
  error: "",
  onChange: () => {}
};

export default HocInput(BaseCheckboxLabel);
