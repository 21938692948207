import { CLAIM, API } from "../../actions/actionTypes";
import { getSuccess } from "../../actions/claim/activity";
import { takeLatest } from "../common/sagaGenerator";

export default [
  takeLatest({
    type: CLAIM.ACTIVITY.GET,
    actionSuccess: getSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading Claim Activity",
    url: `${API.URL}/claimactivity/`
  })
];
