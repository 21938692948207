import { AppBar, CssBaseline, Drawer, Grid, Hidden, IconButton, Toolbar } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import Logo from "../../assets/headerLogo.svg";
import AppContext from "../common/contexts/Container";
import MyProfileMenu from "../myinfo/menu";
import NotificationMenu from "../notification";
import SignedInLinks from "./SignedInLinks";
import styles from "./Index.styles";
import SearchBar from "./Search";
import CustomModal from "../common/CustomModal";

const Layout = props => {
  const { notFoundActive } = useContext(AppContext);
  // eslint-disable-next-line no-unused-vars
  const [openGlobalSearchModal, setOpenGlobalSearchModal] = useState();
  const handleOpenModal = () => setOpenGlobalSearchModal(prev => !prev);
  const handleOpenSearchResult = () => {};

  const classes = styles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => setMobileOpen(s => !s);
  return (
    <div className="tc-page-container">
      <CssBaseline />
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          {!notFoundActive && (
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
              <Menu />
            </IconButton>
          )}
          <div className={classes.title}>
            <Logo className={classes.logo} />
            <div className={classes.searchBar}>
              <SearchBar openModal={setOpenGlobalSearchModal} />
            </div>
          </div>
          <CustomModal
            openModal={openGlobalSearchModal}
            title="Policy/ Certificate"
            onToggle={handleOpenModal}
            onAction={handleOpenSearchResult}
            actionBtnText="APPLY THIS POLICY TO THE CLAIM"
            maxWidth="lg"
          >
            <div style={{ width: "100%", backgroundColor: "grey" }}>
              <Grid />
            </div>
          </CustomModal>
          {!notFoundActive && (
            <>
              <NotificationMenu />
              <MyProfileMenu />
            </>
          )}
        </Toolbar>
      </AppBar>
      {!notFoundActive && (
        <nav>
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{ paper: classes.drawerPaper }}
              ModalProps={{ keepMounted: true }}
            >
              <SignedInLinks />
            </Drawer>
          </Hidden>
        </nav>
      )}
      <Grid container wrap="nowrap" className="tc-page-content tc-no-padding" alignItems="stretch">
        {!notFoundActive && (
          <Hidden xsDown implementation="css" className={classes.sideMenu}>
            <SignedInLinks />
          </Hidden>
        )}
        <Grid item xs className="tc-page-content tc-no-padding" {...props} />
      </Grid>
    </div>
  );
};

export default Layout;
