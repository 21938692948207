import React, { useContext, useMemo, memo, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import moment from "moment";
import { Grid, Paper, IconButton, TableRow, TableCell, TableFooter, Typography, Button } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { MTableBody } from "material-table";
import financialActions, {
  /* downloadFiles, */ postSchedulePay,
  setSchedulePay,
  sendToPay,
  delBill
} from "../../../../redux/actions/claim/financial";
import useStyles from "./styles";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import { icon } from "../../../common/index.styles";
import LinkButton from "../../../common/LinkButton";
import { openFile } from "../../../../redux/actions/claim/documents";
import useViewer from "../../../common/viewer/useViewer";
import { useLoading } from "../../../common/Loading";
import useCustomConfirm from "../../../common/customHooks/useCustomConfirm";
import { setExportTitleFileName, useRouteParam } from "../../../../common";
import BtnMenues from "../../../common/menu/ButtonMenu";
import MaterialTable from "../../../common/table/MaterialTable";
import useAxios from "../../../common/customHooks/useAxios";
import Modal from "./schedulePayment/Form";

const StatusBox = ({ statusId, status }) => {
  const classes = useStyles();
  const body = () => {
    if (typeof statusId === "undefined") return <div className={classes.rectanglePending}>{status}</div>;
    switch (status) {
      case "Pending":
        return <div className={classes.rectanglePending}>{status}</div>;
      case "Paid":
        return <div className={classes.rectanglePaid}>{status}</div>;
      case "Voided":
        return <div className={classes.rectangleVoided}>{status}</div>;
      default:
        return <div className={classes.rectangleOnHold}>{status}</div>;
    }
  };
  return body();
};
const FinancialList = memo(({ onChange, setAddMode, setEditMode, setRowEdit }) => {
  const { sendRequest } = useAxios();
  const selectorData = useSelector(
    ({ common: { coverageTypes, schedulePaymentTypes, recurrenceTypes }, claim, admin }) => ({
      claimLineOfBusinessList: coverageTypes,
      claim,
      admin,
      schedulePaymentTypes,
      recurrenceTypes
    }),
    shallowEqual
  );

  const routesAllowed = useContext(ActionsAllowedContext);
  const claimId = useRouteParam("claimId");
  const access = routesAllowed.find(elem => elem.route === "financial");
  const dispatch = useDispatch();
  const { actions, Loading, loading } = useLoading(false, true);
  const { hideLoading, showLoading } = actions;

  const [scheduleModal, setScheduleModal] = useState({ showModal: false, data: {}, typeSelected: {}, mode: "edit" });

  const { onViewFile, fileViewer } = useViewer({
    dispatchAction: p => {
      showLoading();
      return dispatch(openFile({ ...p, onSuccess: hideLoading, onError: hideLoading }));
    }
  });
  const { create, update, delete: accessToDelete } = access || {};

  // const onDownload = rows => {
  //   const fileIds = rows.filter(({ file }) => Boolean(file)).map(({ file: { id } }) => ({ id, file: true }));
  //   const billIds = rows.filter(({ file }) => Boolean(file)).map(({ id }) => id);
  //   return dispatch(downloadFiles({ billIds, data: fileIds }));
  // };

  const {
    admin: {
      claimSettings: { reserveTypes = [] }
    },
    claim: {
      financial: { financialList },
      filterfields: claimFilterfields,
      claim: { number: claimNumber }
    },
    schedulePaymentTypes,
    recurrenceTypes
  } = selectorData || {};

  const classes = useStyles();
  const classIcon = icon();
  const { lineOfBusinessId, claimLossTypeGroupId } = claimFilterfields || {};

  const reserveTypeByLob = useMemo(
    () =>
      reserveTypes
        .filter(elem => elem.claimLineOfBusinessId === lineOfBusinessId && elem.claimLossTypeGroupId === claimLossTypeGroupId)
        .map(elem => ({ ...elem, text: elem.name, subOptions: elem.reserveTypes.map(el => ({ ...el, text: el.name, subOptions: [] })) })),
    [lineOfBusinessId, claimLossTypeGroupId, reserveTypes]
  );
  const mapReserveTypes = reserveTypeList => {
    return [
      ...reserveTypeList.reduce(
        (acc, rt) => [
          ...acc,
          { ...rt, level: rt.level || 0 },
          ...mapReserveTypes(rt.reserveTypes.map(srt => ({ ...srt, text: srt.name, level: (rt.level || 0) + 1 })))
        ],
        []
      )
    ];
  };
  const treeReserveTypes = mapReserveTypes(reserveTypeByLob);
  const [openModal, setOpenModal] = useState(false);
  const handleEditSchedule = (row, editMode) => {
    if (row.type === "Schedule Payment") {
      showLoading();
      sendRequest({
        request: {
          method: "GET",
          url: `/schedulepayment/getSchedulePayment?id=${row.id}&claimId=${claimId}`
        },
        onSucces: data => {
          setOpenModal(true);
          setScheduleModal({ showModal: true, data, typeSelected: {}, mode: editMode ? "edit" : "read" });
          return hideLoading();
        }
      });
    }
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm();
  const getButtonToolBar = () =>
    create && (
      <Grid item>
        <Grid container justify="flex-end" spacing={1}>
          <Grid item>
            <BtnMenues
              menuName="ADD SCHEDULE PAYMENT"
              subMenues={schedulePaymentTypes}
              menuId="schedulePaymentTypes"
              onClick={option => {
                setOpenModal(true);
                setScheduleModal({ showModal: true, data: { typeId: option.id }, typeSelected: option, mode: "add" });
              }}
            />
          </Grid>
          <Grid item>
            <BtnMenues
              menuName="ADD BILL"
              subMenues={treeReserveTypes}
              menuId="reserveTypes"
              onClick={option => {
                onChange(option);
                setAddMode(true);
                setEditMode(false);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );

  const getTotal = (list, type) =>
    (list || financialList).reduce((acc, b) => acc + (b.scheduledPaymentDetailId === null ? b[type] || 0 : 0), 0).toFixed(2);

  const handleConfirmDelete = data => {
    handleCustomConfirm({
      titleText: `Delete ${data.billId ? "Bill" : "Schedule Payment"}`,
      messageText: `The following ${data?.billId ? "Bill" : "Schedule Payment"} is going to be deleted. Are you sure you want to proceed?`,
      onAction: () => {
        const { deleteSchedulePayment } = financialActions;
        const { id } = data;
        if (data.billId) dispatch(delBill(id));
        else dispatch(deleteSchedulePayment({ id, claimId }));
      }
    });
  };

  const tableProps = {
    title: "Bills & Payments",
    columns: [
      {
        title: "Reserve Type",
        field: "reserveType",
        width: null,
        cellStyle: { whiteSpace: "nowrap", paddingRight: 0, width: 200 },
        render: rowData => {
          if (rowData.fileId || (!rowData.scheduledPaymentDetailId && rowData.type === "Schedule Payment"))
            return (
              <LinkButton
                blue
                onClick={() => {
                  return rowData?.fileId
                    ? onViewFile(rowData.fileId, { billId: rowData.billId })
                    : handleEditSchedule(rowData, rowData.status === "On Hold");
                }}
              >
                {rowData.reserveType}
              </LinkButton>
            );
          if (rowData.type === "Schedule Payment Detail")
            return (
              <>
                <span className="tc-bold">Payment Date:</span>
                <span className="tc-pl2">{moment(rowData.paymentDate).format("M/D/YYYY")}</span>
              </>
            );
          return rowData.reserveType;
        }
      },
      {
        title: "Description",
        field: "description",
        width: null,
        cellStyle: { paddingRight: 0, width: 300 }
      },
      {
        title: "Status",
        field: "status",
        render: rowData => {
          const { status, statusId } = rowData;
          return <StatusBox {...{ status, statusId }} />;
        }
      },
      {
        title: "Payees",
        field: "payeeName",
        width: null,
        cellStyle: { paddingRight: 0, width: 200 }
      },
      { title: "Amount", field: "amount", render: rowData => <span>{`$ ${(rowData?.amount || 0).toFixed(2)}`}</span> },
      { title: "Total Paid", field: "totalPaid", render: rowData => <span>{`$ ${(rowData?.totalPaid || 0).toFixed(2)}`}</span> },
      {
        title: "",
        width: null,
        cellStyle: { whiteSpace: "nowrap", paddingRight: 0, paddingLeft: 0, width: 30 },
        render: rowData => (
          <>
            <Grid container wrap="nowrap">
              <Grid item xs={6}>
                {rowData.status !== "On Hold" && (rowData.billId || rowData.type === "Schedule Payment") && (
                  <LinkButton
                    blue
                    onClick={() => {
                      if (rowData.billId) {
                        setRowEdit(rowData);
                        setEditMode(true);
                      } else handleEditSchedule(rowData, false);
                    }}
                  >
                    View
                  </LinkButton>
                )}
                {!!(update && rowData?.settledId === null && rowData.status === "On Hold" && rowData.scheduledPaymentDetailId === null) && (
                  <IconButton
                    className={classIcon.table}
                    onClick={() => {
                      if (rowData.billId) {
                        setRowEdit(rowData);
                        setEditMode(true);
                      } else handleEditSchedule(rowData, true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={6}>
                {!!(accessToDelete && rowData?.settledId === null && rowData.status === "On Hold" && rowData.scheduledPaymentDetailId === null) && (
                  <IconButton className={classIcon.table} onClick={() => handleConfirmDelete(rowData)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </>
        )
      }
    ],
    data: financialList,
    parentChildData: (row, rows) => rows.find(a => a.type === "Schedule Payment" && a.id === row.scheduledPaymentId),
    options: {
      actionsColumnIndex: -1,
      selection: true,
      selectionProps: rowData => ({
        disabled: rowData.type === "Schedule Payment Detail" || rowData.status !== "On Hold", // !rowData?.fileId,
        color: "primary"
      }),
      hidePaging: true,
      showSelectAllCheckbox: false,
      showTextRowsSelected: true,
      exportButton: true,
      customGroupConfig: { check: false },
      exportFileName: setExportTitleFileName(`${claimNumber}_Bill&Payments`)
    },
    actions: [
      // {
      //   tooltip: "Download files",
      //   icon: () => <GetApp />,
      //   onClick: (_e, rows) => {
      //     onDownload(rows);
      //   }
      // },
      {
        tooltip: "Payment Queue",
        icon: () => (
          <Button aria-haspopup="true" color="primary" variant="contained" style={{ whiteSpace: "nowrap" }}>
            Send To Payment Queue
          </Button>
        ),
        onClick: (_e, rows) => {
          const entities = {
            claimId,
            billIds: rows.reduce((acc, e) => {
              if (e.billId) return [...acc, e.billId];
              return acc;
            }, []),
            settledIds: rows.reduce((acc, e) => {
              if (e.settledId) return [...acc, e.settledId];
              return acc;
            }, []),
            scheduledPaymentIds: rows.reduce((acc, e) => {
              if (e.type === "Schedule Payment") return [...acc, e.id];
              return acc;
            }, [])
          };
          dispatch(
            sendToPay(
              entities,
              () => {
                hideLoading();
              },
              hideLoading
            )
          );
        }
      }
    ],
    componentsAttr: {
      Body: tProps => {
        return (
          <>
            <MTableBody {...tProps} />
            <TableFooter>
              <TableRow style={{ backgroundColor: "#DAEAEF" }}>
                <TableCell colSpan="5">{/* <Typography className="tc-bold tc-label tc-blue tc-nowrap ">Issue Payment</Typography> */}</TableCell>
                <TableCell align="left">
                  <Typography className="tc-bold tc-label tc-blue tc-nowrap">Total</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className="tc-bold tc-label tc-blue tc-nowrap">{`$ ${getTotal(financialList, "amount")}`}</Typography>
                </TableCell>
                <TableCell align="left" colSpan="3">
                  <Typography className="tc-bold tc-label tc-blue tc-nowrap">{`$ ${getTotal(financialList, "totalPaid")}`}</Typography>
                </TableCell>
              </TableRow>
            </TableFooter>
          </>
        );
      }
    },
    ButtonToolBar: getButtonToolBar,
    localization: {
      toolbar: { nRowsSelected: "{0} Row(s) selected" }
    }
  };
  const onToggleModal = () => {
    setScheduleModal(sp => ({ ...sp, showModal: false }));
    setOpenModal(false);
  };

  const saveModal = data => {
    if (data.paymentBenefitFull.length === 0) return toast.error("Should have at least one payee.");
    if (data.paymentBenefitFull.reduce((acc, item) => item.percent + acc, 0) !== 100) return toast.error("Payees: The total percent should be %100.");
    const schedule = {
      ...data,
      paymentBenefits: data.paymentBenefitFull.map(item => ({
        id: item.id,
        schedulePaymentId: item.schedulePaymentId,
        vendorId: item.vendorId,
        claimantId: item.claimantId,
        percent: item.percent,
        description: item.description
      }))
    };
    if (data.id)
      dispatch(
        setSchedulePay(
          {
            ...schedule,
            claimId
          },
          () => {
            hideLoading();
            onToggleModal();
          },
          hideLoading
        )
      );
    else
      dispatch(
        postSchedulePay(
          {
            ...schedule,
            claimId
          },
          () => {
            hideLoading();
            onToggleModal();
          },
          hideLoading
        )
      );
    return schedule;
  };
  return (
    <>
      {Loading}

      <Paper className={classes.paperContainer}>
        <MaterialTable {...tableProps} />
        {openModal && (
          <Modal
            showModal={scheduleModal.showModal}
            data={scheduleModal.data}
            typeSelected={scheduleModal.typeSelected}
            mode={scheduleModal.mode}
            treeReserveTypes={treeReserveTypes}
            recurrenceTypes={recurrenceTypes}
            onToggleModal={onToggleModal}
            onSave={saveModal}
            loading={loading}
            access={access}
          />
        )}
      </Paper>

      {fileViewer}
      <Confirm />
    </>
  );
});

FinancialList.propTypes = {
  onChange: PropTypes.func.isRequired,
  setAddMode: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
  setRowEdit: PropTypes.func.isRequired
};

export default FinancialList;
