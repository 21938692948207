import React, { useEffect, useMemo } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { HorizontalBar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import Card from "../../common/overview/Card";
import { getDuplicatesForChart } from "../../../redux/actions/dashboard/payments";
import { useLoading } from "../../common/Loading";
import { getReserveTypes } from "../../../redux/actions/admin/claimsSetting";

const useDuplicateStyles = makeStyles(theme => ({
  icon: { color: "#107A98", padding: theme.spacing(1) }
}));

const DuplicateBarChart = () => {
  const dispatch = useDispatch();
  const {
    actions: { showLoading, hideLoading },
    Loading
  } = useLoading(true);
  useEffect(() => {
    showLoading();
    dispatch(getReserveTypes());
    dispatch(getDuplicatesForChart(hideLoading, hideLoading));
  }, []);
  const store = useSelector(
    ({
      dashboard: { duplicatePaymentsChart },
      admin: {
        claimSettings: { reserveTypes }
      }
    }) => ({
      duplicatePaymentsChart,
      reserveTypes
    }),
    shallowEqual
  );
  const { duplicatePaymentsChart, reserveTypes } = store;

  const data = useMemo(() => {
    const backgroundColor = ["#FE9A32", "#F7CB4D", "#7BAAF7", "#BA68C8", "#3DC789", "#F7A7C0", "#8C392D"];
    const distinct = (value, index, self) => self.indexOf(value) === index;
    const adjusterIds = (duplicatePaymentsChart || []).map(c => c.adjusterId).filter(distinct);
    const reserveTypesToShow = reserveTypes.filter(e =>
      duplicatePaymentsChart.some(el => el.reserveTypeId === e.id || e.reserveTypes.some(elem => elem.id === el.reserveTypeId))
    );
    return {
      labels: adjusterIds.map(aId => duplicatePaymentsChart.find(c => c.adjusterId === aId)?.adjusterName),
      datasets: [
        ...reserveTypesToShow.map((elem, ix) => ({
          label: elem.name,
          backgroundColor: backgroundColor[ix],
          data: [
            ...adjusterIds.map(aId =>
              duplicatePaymentsChart.reduce(
                (acc, c) =>
                  c.adjusterId === aId && (c.reserveTypeId === elem.id || elem.reserveTypes.some(e => e.id === c.reserveTypeId))
                    ? acc + c.countDuplicated
                    : acc,
                0
              )
            )
          ]
        }))
      ]
    };
  }, [reserveTypes, duplicatePaymentsChart]);
  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { position: "bottom", labels: { usePointStyle: true, padding: 10, boxWidth: 6 } },
    scales: {
      yAxes: [{ ticks: { beginAtZero: true, maxRotation: 45, minRotation: 45 } }],
      xAxes: [{ ticks: { autoSkip: false, precision: 0, beginAtZero: true } }]
    }
  };
  const history = useHistory();
  const showDetail = () => {
    history.push("/dashboard/duplicatepayments");
  };
  const classes = useDuplicateStyles();
  const widgetActions = () => {
    return (
      <>
        <IconButton size="small" className={classes.icon} onClick={showDetail}>
          <OpenInNew />
        </IconButton>
      </>
    );
  };
  return (
    <>
      {Loading}
      <Card title="Duplicate payments report by reserve type by adjuster" cardHeaderAction={widgetActions}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs>
            <div>
              <HorizontalBar data={data} height={275} options={barOptions} />
            </div>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default DuplicateBarChart;
