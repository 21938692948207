import { makeStyles, withStyles } from "@material-ui/core";

export const useClaimAddFooterStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "#ffffff",
    padding: "13px 0 13px 122px",
    zIndex: 10,
    transition: theme.transitions.create("padding-right", {
      duration: theme.transitions.duration.complex
    })
  },
  btn: {
    backgroundColor: "#DAEAEF",
    color: "#107A98",
    "&:hover": { backgroundColor: "#CADADF" }
  }
}));

export const useClaimAddHeaderStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2, 20, 2, 5.5),
    backgroundColor: "#F4F8F9",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.4)"
  },
  title: { color: "#004763", fontSize: 24, paddingRight: theme.spacing(10) },
  root: {
    padding: 0,
    backgroundColor: "transparent"
  },
  label: { marginTop: "4px !important" },
  active: { color: "#004763 !important" },
  completed: { color: "#428302 !important" }
}));

export const withStepStyles = withStyles({
  active: { color: "#004763 !important" }
});

export const useClaimDuplicatesStyles = makeStyles(theme => ({
  root: { height: "100%", flexDirection: "column", flexWrap: "nowrap" },
  duplicates: { flexGrow: 1 },
  noDuplicates: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    color: "#898F91",
    fontSize: 16,
    paddingBottom: theme.spacing(2.75)
  },
  avatar: {
    width: 108,
    height: 108,
    color: "#898F91",
    fontSize: 58,
    backgroundColor: "#F3F3F3",
    "& > svg": { height: "1em", width: "1em" },
    margin: "0 auto",
    marginBottom: theme.spacing(2.25)
  },
  claimHeader: { top: theme.spacing(-1), position: "relative", marginBottom: theme.spacing(-1) },
  number: { fontSize: 24, fontWeight: 500 },
  claimant: { minHeight: "initial !important", padding: "0 !important", fontWeight: 500 },
  value: { minHeight: "initial !important", padding: "0 !important", fontSize: 14 },
  grid: { flexWrap: "nowrap" },
  lob: { display: "inline-block", padding: "5px 13px", backgroundColor: "#DAEAEF", borderRadius: 4 },
  room: { fontSize: 18, width: "initial" }
}));
