import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import moment from "moment";
import { Grid, MenuItem } from "@material-ui/core";
import { SelectInput, TextInput, DateTimeInput, IntegerInput } from "../../../../common/inputs";
import LabelValue from "../../../../common/LabelValue";
import CustomModal from "../../../../common/CustomModal";
import { schedule as validationSchema } from "./yup";
import useYupValidationSchema from "../../../../common/customHooks/useYupValidationSchema";
import Benefits from "./Benefits";

const SchedulePaymentForm = ({ showModal, data, typeSelected, mode, treeReserveTypes, recurrenceTypes, onToggleModal, onSave, access }) => {
  const [schedule, setSchedule] = useState({});
  const { validationMessages, handleValidateSchema } = useYupValidationSchema(validationSchema);
  const editBenefit = benefit => {
    setSchedule(ps => ({
      ...ps,
      paymentBenefitFull: schedule.paymentBenefitFull.map(elem => {
        if (benefit.index === elem.index) {
          const newElem = { ...elem, ...benefit };
          return newElem;
        }
        return elem;
      })
    }));
  };
  const addBenefit = benefit => {
    setSchedule(ps => ({
      ...ps,
      paymentBenefitFull: [...schedule.paymentBenefitFull, { ...benefit, index: schedule.paymentBenefitFull.length, schedulePaymentId: schedule.id }]
    }));
  };
  const delBenefit = benefit => {
    setSchedule(ps => ({
      ...ps,
      paymentBenefitFull: schedule.paymentBenefitFull.reduce((acc, el) => {
        if (el.index === benefit.index) return acc;
        return [...acc, el];
      }, [])
    }));
  };
  useEffect(() => {
    const d = {
      ...data,
      paymentBenefitFull: (data?.paymentBenefitFull || []).map((elem, index) => ({
        ...elem,
        amount: elem?.percent && data?.amount ? (data?.amount * elem?.percent) / 100 : 0.0,
        name: elem.payee ? elem.payee : elem.name,
        fullAddress: `${elem.address1 || ""} ${elem.address1 ? "," : ""} ${elem.city || ""}${
          elem.city && (elem.state || elem.zipCode) ? ", " : ""
        }${elem.state || ""}${elem.state ? " " : ""}${elem.zipCode || ""}`.trim(),
        index,
        payeeTypeId: elem.claimantId ? 1 : 2
      }))
    };
    setSchedule(d);
  }, [data]);
  useEffect(() => {
    setSchedule(sp => ({
      ...sp,
      totalAmountScheduled: sp.numberOfPayments && sp.amount ? sp.numberOfPayments * sp.amount : 0,
      paymentBenefitFull: (sp?.paymentBenefitFull || []).map(elem => ({
        ...elem,
        amount: elem?.percent && sp.amount ? (sp.amount * elem?.percent) / 100 : 0.0
      }))
    }));
  }, [schedule.amount, schedule.numberOfPayments]);
  useEffect(() => {
    handleValidateSchema(schedule);
  }, [
    schedule.amount,
    schedule.numberOfPayments,
    schedule.reserveTypeId,
    schedule.benefitStartDate,
    schedule.numberOfPayments,
    schedule.recurrenceTypeId,
    schedule.paymentBenefitFull
  ]);

  const inputProps = (path, label) => {
    const [name] = path.split(".");
    return {
      name: path,
      error: (validationMessages || {})[`${name}`],
      value: schedule[name] || undefined,
      onChange: ({ target: { value } }) => {
        setSchedule(ps => ({ ...ps, [name]: value }));
      },
      label
    };
  };
  const calculateNextPaymentDate = () => {
    const date = new Date(schedule.benefitStartDate);
    if (!schedule.recurrenceTypeId || !schedule.benefitStartDate) return null;
    if (schedule.numberOfPayments === 1) return date;
    switch (schedule.recurrenceTypeId) {
      case 1:
        return moment(date)
          .add(1, "days")
          .format("M/D/YYYY");
      case 2:
        return moment(date)
          .add(7, "days")
          .format("M/D/YYYY");
      default:
        return moment(date)
          .add(1, "M")
          .format("M/D/YYYY");
    }
  };
  const calculateEndDate = () => {
    if (!schedule.recurrenceTypeId || !schedule.benefitStartDate) return null;
    const date = new Date(schedule.benefitStartDate);
    const count = schedule.numberOfPayments ? schedule.numberOfPayments - 1 : 0;
    switch (schedule.recurrenceTypeId) {
      case 1:
        return moment(date)
          .add(count, "d")
          .format("M/D/YYYY");
      case 2:
        return moment(date)
          .add(count, "w")
          .format("M/D/YYYY");
      default:
        return moment(date)
          .add(count, "M")
          .format("M/D/YYYY");
    }
  };
  const addMode = mode === "add" && access?.create;
  // const editMode = mode === "edit";
  const readOnly = mode === "read" && access?.read;
  const getTitle = () => {
    if (!readOnly) return `${addMode ? "New" : "Edit"} Schedule Payment`;
    return "Schedule Payment";
  };
  return (
    <CustomModal
      openModal={showModal}
      title={getTitle()}
      onToggle={() => {
        onToggleModal();
      }}
      maxWidth="md"
      onAction={() => {
        if (handleValidateSchema(schedule).isValid) onSave(schedule);
        if (validationMessages.toast) toast.error(validationMessages.toast);
      }}
      actionBtnText={addMode ? "ADD" : "SAVE"}
    >
      <div style={{ maxHeight: 1500 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.54)" }}>
            <div className="tc-p1">
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <LabelValue label="Schedule Payment Type" value={schedule.type ?? typeSelected?.text} disabled={readOnly} />
                </Grid>
                <Grid item xs={12} md={3} className="tc-no-padding-tb">
                  <SelectInput
                    {...inputProps("reserveTypeId", "From Reserve Type")}
                    options={treeReserveTypes}
                    renderOption={option => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        style={{ paddingLeft: `${(option.level ?? 0) * 8 + 8}px` }}
                        disabled={option.level === 0 && option.reserveTypes.length > 0}
                      >
                        {option.name}
                      </MenuItem>
                    )}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={12} md={6} className="tc-no-padding-tb">
                  <TextInput {...inputProps("description", "Description")} rows={1} disabled={readOnly} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} className="tc-no-padding-tb">
                  <IntegerInput {...inputProps("amount", "Amount")} disabled={readOnly} />
                </Grid>
                <Grid item xs={12} md={3} className="tc-no-padding-tb">
                  <IntegerInput {...inputProps("numberOfPayments", "Number Of Payments")} disabled={readOnly} />
                </Grid>
                <Grid item xs={12} md={3} className="tc-no-padding-tb">
                  <SelectInput {...inputProps("recurrenceTypeId", "Recurrence")} options={recurrenceTypes} disabled={readOnly} />
                </Grid>
                <Grid item xs={12} md={3} className="tc-no-padding-tb">
                  <DateTimeInput {...inputProps("benefitStartDate", "Benefit Start Date")} keyboard disabled={readOnly} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <LabelValue label="Total Amount For Schedule" value={schedule.totalAmountScheduled} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <LabelValue label="Next Payment Date" value={schedule.paymentDate || calculateNextPaymentDate()} isDate />
                </Grid>
                <Grid item xs={12} md={3}>
                  <LabelValue label="Benefit End Date" value={calculateEndDate()} />
                </Grid>
                {!addMode && (
                  <Grid item xs={12} md={3}>
                    <LabelValue label="Payment Schedule Status" value={schedule.status} />
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={3}>
                {!!(schedule.status === "Paid" || schedule.status === "Partial Paid") && (
                  <Grid item xs={12} md={3} className="tc-no-padding-tb">
                    <LabelValue label="Last Payment Date" value={schedule.lastPaymentDate} />
                  </Grid>
                )}
                {!!(schedule.status === "Paid" || schedule.status === "Partial Paid") && (
                  <Grid item xs={12} md={3} className="tc-no-padding-tb">
                    <LabelValue label="Number Paid" value={schedule.numberPaid} />
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="tc-p1">
              <Benefits
                benefits={schedule.paymentBenefitFull}
                onEdit={editBenefit}
                onAdd={addBenefit}
                onDelete={delBenefit}
                separateChecks={schedule.separateChecks}
                setSepatateCheks={setSchedule}
                totalAmount={schedule.amount}
                validationMsg={validationMessages}
                readOnly={readOnly}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </CustomModal>
  );
};

SchedulePaymentForm.propTypes = {
  data: PropTypes.shape({}).isRequired,
  showModal: PropTypes.bool.isRequired,
  typeSelected: PropTypes.shape({}),
  mode: PropTypes.string.isRequired,
  treeReserveTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  recurrenceTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onToggleModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  access: PropTypes.shape({}).isRequired
};

SchedulePaymentForm.defaultProps = {
  typeSelected: null
};

export default SchedulePaymentForm;
