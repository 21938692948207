import { ADMIN } from "../actionTypes";

const getAll = (onSuccess, onError) => ({ type: ADMIN.NOTE_TYPE.GETALL, onSuccess, onError });
const getAllSuccess = data => ({ type: ADMIN.NOTE_TYPE.GETALL_SUCCESS, data });

const post = (data, onSuccess, onError) => ({ type: ADMIN.NOTE_TYPE.POST, data, onSuccess, onError });
const postSuccess = data => ({ type: ADMIN.NOTE_TYPE.POST_SUCCESS, data });

const del = (id, onSuccess, onError) => ({ type: ADMIN.NOTE_TYPE.DEL, id, onSuccess, onError });
const delSuccess = data => ({ type: ADMIN.NOTE_TYPE.DEL_SUCCESS, data });

const set = (data, onSuccess, onError) => ({ type: ADMIN.NOTE_TYPE.SET, data, onSuccess, onError });
const setSuccess = data => ({ type: ADMIN.NOTE_TYPE.SET_SUCCESS, data });

export { post, postSuccess, getAll, getAllSuccess, set, setSuccess, del, delSuccess };
