import React, { useState, useEffect, shallowEqual } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import Loading from "../../components/common/Loading";
import MaterialTable from "../../components/common/table/MaterialTable";
import { getAll } from "../../redux/actions/claim";
import ClaimStatus from "../../components/claims/ClaimStatus";
import ClaimProcessingStatus from "../../components/claims/ClaimProcessingStatus";
import { getUserAccess, setExportTitleFileName } from "../../common";
import BtnMenues from "../../components/common/menu/ButtonMenu";
import DateFilter from "../../components/common/table/DateFilter";

const List = () => {
  const [loading, setLoading] = useState(true);
  const store = useSelector(
    ({ claim: { claims }, profile: { securityProfilesDetails } }) => ({ claimsList: claims, secProfs: securityProfilesDetails }),
    shallowEqual
  );

  const { claimsList, secProfs } = store || {};
  const dispatch = useDispatch();
  const hideLoading = () => {
    setLoading(false);
  };
  useEffect(() => {
    dispatch(getAll(hideLoading, hideLoading));
  }, []);
  const access = getUserAccess(secProfs, "CLAIM.LIST", null, null, null);
  const history = useHistory();
  const types = [
    { id: 1, text: "Property & Casualty" },
    { id: 2, text: "Workers Compensation" }
  ];
  const handleNewClaim = type => {
    if (type === 1) history.push("/claim");
    else history.push("/wcClaim");
  };

  const getButtonToolBar = () => {
    if (!access.create) return null;
    return (
      <BtnMenues
        menuName="Add Claim"
        subMenues={types}
        menuId="addClaim"
        onClick={option => {
          handleNewClaim(option.id);
        }}
      />
    );
  };

  const getNextTab = (clientId, stateId, lobId) => {
    if (getUserAccess(secProfs, "CLAIM.OVERVIEW", clientId, stateId, lobId).read) return "overview";
    if (getUserAccess(secProfs, "CLAIM.INFO", clientId, stateId, lobId).read) return "info";
    if (getUserAccess(secProfs, "CLAIM.COVERAGE", clientId, stateId, lobId).read) return "coverage";
    if (getUserAccess(secProfs, "CLAIM.DOCUMENTS", clientId, stateId, lobId).read) return "documents";
    if (getUserAccess(secProfs, "CLAIM.NOTES", clientId, stateId, lobId).read) return "notes";
    if (getUserAccess(secProfs, "CLAIM.LEGAL", clientId, stateId, lobId).read) return "legal";
    if (getUserAccess(secProfs, "CLAIM.RESERVES", clientId, stateId, lobId).read) return "reserves";
    if (getUserAccess(secProfs, "CLAIM.FINANCIAL", clientId, stateId, lobId).read) return "financial";
    if (getUserAccess(secProfs, "CLAIM.DIARIES", clientId, stateId, lobId).read) return "diaries";
    if (getUserAccess(secProfs, "CLAIM.COMMUNICATIONS", clientId, stateId, lobId).read) return "communications";
    if (getUserAccess(secProfs, "CLAIM.ACTIVITY", clientId, stateId, lobId).read) return "activity";
    return "";
  };

  const exportFileName = setExportTitleFileName("ClaimList");

  const tableProps = {
    title: "Claim List",
    columns: [
      {
        title: "Claim Number",
        field: "number",
        customSort: (a, b) => (a.number || "").localeCompare(b.number || ""),
        render: rowData => (
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: 5 }}>
              <Link to={`/claim/${rowData.id}/${getNextTab(rowData.rootClientId, rowData.stateId, rowData.claimLineOfBusinessId)}`}>
                {rowData.number}
              </Link>
            </span>
            <span style={{ marginRight: 5 }}>
              <ClaimStatus status={rowData.status} />
            </span>
            <ClaimProcessingStatus processingStatus={rowData.processingStatus} />
          </div>
        )
      },
      { title: "Claimant", field: "claimant" },
      { title: "Date of Loss", field: "dateofLoss", type: "date", filterComponent: DateFilter },
      { title: "Insured", field: "insured" },
      {
        title: "Line of Business",
        field: "claimLineOfBusinessCode",
        render: r => {
          if (r.claimLineOfBusinessId !== 4) return `${r.claimLineOfBusinessCode} - ${r.claimLossTypeGroup}`;
          return r.claimLineOfBusinessCode;
        }
      },
      { title: "Claim Type", field: "type" },
      { title: "Adjuster", field: "adjuster" },
      { title: "Supervisor", field: "supervisor" }
    ],
    data: claimsList,
    hidePaging: claimsList.length <= 50,
    options: {
      pageSize: 50,
      filtering: true,
      exportFileName
    },
    ButtonToolBar: getButtonToolBar
  };

  return (
    <>
      {loading && <Loading float />}
      <div className="tc-page-content">
        <Paper className="tc-p2">
          <MaterialTable {...tableProps} />
        </Paper>
      </div>
    </>
  );
};

export default List;
