import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import MaterialTable from "../../../common/table/MaterialTable";
import { get, set, post, del } from "../../../../redux/actions/claim/legal/hearing";
import { useRouteParam } from "../../../../common";
import useCustomConfirm from "../../../common/customHooks/useCustomConfirm";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import { isDate } from "../../../../common/yup";
import { DateTimeInput } from "../../../common/inputs";

const style = makeStyles(() => ({ inputWidth: { width: "140px" } }));

export default props => {
  const classes = style();
  const claimId = useRouteParam("claimId");
  const data = useSelector(({ claim: { hearings } }) => hearings, shallowEqual);
  const dispatch = useDispatch();
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "legal");
  const {
    props: { boxProps, Loading },
    actions: { showLoading, hideLoading }
  } = useCollapsibleBox();
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  const onDeleteHearing = hearingInfo => {
    showLoading();
    return dispatch(del(hearingInfo, hideLoading, hideLoading));
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(onDeleteHearing, "Cancel", "Yes, Delete");
  const handleConfirmDelete = rowData => {
    handleCustomConfirm({
      oData: rowData,
      titleText: `Delete Hearing Information`,
      messageText: `The Hearing information will be deleted. Are you sure you want to proceed?`
    });
  };
  const tableProps = {
    columns: [
      {
        title: "Date",
        field: "date",
        type: "date",
        validate: row => isDate(row.date) || "Date Required",
        editComponent: row => (
          <DateTimeInput
            className={classes.inputWidth}
            name="date"
            value={row.value || undefined}
            onChange={e => {
              e.target.value = e.target.value === "" ? undefined : e.target.value;
              row.onChange(e.target.value);
            }}
            error={isDate(row.value) ? "" : "Date Required"}
            variant="dialog"
            clearable
          />
        )
      },
      { title: "Venue", field: "venue", validate: row => (row.venue !== "" && row.venue !== undefined) || "Venue Required" }
    ],
    data,
    hideExportButton: true,
    actions: [
      {
        icon: () => <DeleteIcon />,
        tooltip: "Delete",
        onClick: (_e, rowData) => {
          _e.stopPropagation();
          handleConfirmDelete(rowData);
        }
      }
    ].filter(() => access.delete),
    editable: {
      onRowAdd: access.create
        ? dataRow =>
            new Promise(resolve => {
              showLoading();
              dispatch(post({ claimId, ...dataRow }, hideLoading, hideLoading));
              resolve();
            })
        : undefined,
      onRowUpdate: access.update
        ? dataRow =>
            new Promise(resolve => {
              showLoading();
              dispatch(set(dataRow, hideLoading, hideLoading));
              resolve();
            })
        : undefined
    }
  };
  return (
    <CollapsibleBox title="Hearing" name="hearing" {...boxProps} {...props}>
      {Loading}
      <MaterialTable {...tableProps} />
      <Confirm />
    </CollapsibleBox>
  );
};
