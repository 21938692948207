import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import { useRouteParam } from "../../../../common";
import ClaimWitness from "../../../common/claims/ClaimWitness";
import { getAll, post, set, del } from "../../../../redux/actions/claim/info/witness";
import useCustomConfirm from "../../../common/customHooks/useCustomConfirm";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";

const WitnessInfoBox = props => {
  const store = useSelector(({ claim }) => claim, shallowEqual);
  const { claim, witnesses } = store || {};
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info");
  const {
    props: { boxProps, Loading },
    actions: { showLoading, hideLoading }
  } = useCollapsibleBox();
  const claimId = useRouteParam("claimId");
  const dispatch = useDispatch();
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(getAll(claimId, hideLoading, hideLoading));
    }
  }, []);
  const handleDelete = data => {
    showLoading();
    dispatch(del(data.id, hideLoading, hideLoading));
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleDelete, "Cancel", "Yes, Delete");
  const handleConfirmDelete = data => {
    handleCustomConfirm({
      oData: data,
      titleText: `Delete Witness`,
      messageText: `The following witness is going to be deleted: ${data.firstName} ${data.lastName}. Are you sure you want to proceed?`
    });
  };
  const update = data => {
    if (access.update) {
      showLoading();
      dispatch(set(data, hideLoading, hideLoading));
    }
  };
  const add = data => {
    if (access.create) {
      showLoading();
      dispatch(post({ ...data, claimId }, hideLoading, hideLoading));
    }
  };
  const remove = data => {
    if (access.delete) {
      handleConfirmDelete(data);
    }
  };
  return (
    <CollapsibleBox title="Witness" name="witness" {...boxProps} {...props}>
      {Loading}
      {<ClaimWitness {...{ data: witnesses, add, update, remove, exportFileName: `TerraClaim_${claim.number}_Witness` }} />}
      <Confirm />
    </CollapsibleBox>
  );
};

export default WitnessInfoBox;
