import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Button } from "@material-ui/core";
import moment from "moment";
import { DateTimeInput, SelectInput, TextInput } from "../../inputs";
import { BaseRadioButtonGroup } from "../../inputs/RadioButtonGroup";
import validationSchema from "./yup";
import useYupValidationSchema from "../../customHooks/useYupValidationSchema";

const EmploymentForm = ({ employment, claimId, onClose, onSave, isLoading }) => {
  const selects = useSelector(store => store.common);
  const { states, employmentStatus, payrollClassCodeTypes, ratePerTypes } = selects;
  const [state, setState] = useState({
    ...employment,
    id: employment.id ?? undefined,
    fullPayInjuryDay: employment.fullPayInjuryDay ? 1 : 0,
    salaryContinued: employment.salaryContinued ? 1 : 0
  });
  const [loaded, setLoaded] = useState(false);
  const { validationMessages, handleValidateSchema } = useYupValidationSchema(validationSchema);
  const handleValidation = () => handleValidateSchema({ ...state });
  useEffect(() => {
    if (loaded) handleValidation();
    else setLoaded(true);
  }, [state]);
  const onChange = ({ target: { name: path, value } }) => {
    const [, name] = path.split(".");
    return setState(prevState => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = event => {
    event.preventDefault();
    const { isValid } = handleValidation();
    if (isValid) onSave({ ...state, claimId, fullPayInjuryDay: state.fullPayInjuryDay === 1, salaryContinued: state.salaryContinued === 1 });
  };
  const inputProps = path => {
    const [, name] = path.split(".");
    return {
      name: path,
      value: state[name] || undefined,
      onChange,
      error: (validationMessages || {})[name]
    };
  };
  const options = [
    { id: 1, text: "YES" },
    { id: 0, text: "NO" }
  ];
  const daysWorkedWeekOptions = [1, 2, 3, 4, 5, 6, 7].map(x => ({ id: x, text: x }));
  const yearsTenure = moment().diff(moment(state.hireDate), "years") > 0 ? `${moment().diff(moment(state.hireDate), "years")} year(s)` : undefined;
  const monthsTenure =
    moment().diff(moment(state.hireDate), "months") > 0 ? `${moment().diff(moment(state.hireDate), "months")} month(s)` : undefined;
  return (
    <Fragment key="employment-form-box">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs>
            <SelectInput {...inputProps("state.jurisdictionStateId")} options={states} label="Jurisdiction State *" />
            <DateTimeInput {...inputProps("state.hireDate")} label="Hire Date" keyboard />
            <TextInput value={yearsTenure || monthsTenure || ""} label="Tenure" disabled />
          </Grid>
          <Grid item xs>
            <SelectInput {...inputProps("state.payrollClassCodeTypeId")} options={payrollClassCodeTypes} label="Payroll Class Code *" />
            <SelectInput {...inputProps("state.ratePerTypeId")} label="Rate Per" options={ratePerTypes} />
            {state.ratePerTypeId === 4 && <TextInput label="Rate Per Description" {...inputProps("state.ratePerDescription")} />}
          </Grid>
          <Grid item xs>
            <SelectInput {...inputProps("state.daysWorkedWeek")} options={daysWorkedWeekOptions} label="Days Worked/Week" allowClear />
            <SelectInput {...inputProps("state.employmentStatusId")} options={employmentStatus} label="Employment Status *" />
            <TextInput {...inputProps("state.tpaOfficeNumber")} label="TPA Office #" />
          </Grid>
          <Grid item xs>
            <BaseRadioButtonGroup
              name="state.fullPayInjuryDay"
              options={options}
              onChange={onChange}
              value={state.fullPayInjuryDay ? parseInt(state.fullPayInjuryDay, 0) : 0}
              label="Full Pay For Day of Injury?"
              row
            />
            <BaseRadioButtonGroup
              name="state.salaryContinued"
              options={options}
              onChange={onChange}
              value={state.salaryContinued ? parseInt(state.salaryContinued, 0) : 0}
              label="Did Salary Continued?"
              row
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput {...inputProps("state.occupationDescription")} label="Occupation Description" />
          </Grid>
        </Grid>
        <Grid container justify="flex-end" spacing={1}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={onClose}>
              CANCEL
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
              SAVE
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

EmploymentForm.propTypes = {
  employment: PropTypes.objectOf(PropTypes.any).isRequired,
  claimId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

EmploymentForm.defaultProps = {
  isLoading: false
};

export default EmploymentForm;
