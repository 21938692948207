import { Divider } from "@material-ui/core";
import React, { Fragment } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useRouteParam } from "../../../../common";
import Loading from "../../../common/Loading";
import ClaimCatastropheInfo from "./ClaimCatastropheInfo";
import ClaimDayInfo from "./ClaimDayInfo";
import ClaimPanelInfo from "./ClaimPanelInfo";
import useStyles from "./index.styles";
import LastActivityPanelInfo from "./LastActivityPanelInfo";
import LitigationPanelInfo from "./LitigationPanelInfo";
import LobPanelInfo from "./LobPanelInfo";
import ReservesPanelInfo from "./ReservesPanelInfo";

export default () => {
  const classes = useStyles();
  const claimId = useRouteParam("claimId");
  const { id } = useSelector(({ claim: { claim } }) => claim, shallowEqual);
  return (
    <div className={classes.bar}>
      {(claimId === id && (
        <Fragment key="claim-sidebar">
          <ClaimPanelInfo />
          <Divider />
          <LobPanelInfo />
          <Divider />
          <ClaimDayInfo />
          <Divider />
          <ClaimCatastropheInfo />
          <Divider />
          <LitigationPanelInfo />
          <Divider />
          <ReservesPanelInfo />
          <LastActivityPanelInfo />
        </Fragment>
      )) || <Loading />}
    </div>
  );
};
