import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useFileDropzoneStyles } from "./hooks";

const FileDropzone = ({ hover, errorDrag, ...other }) => {
  const classes = useFileDropzoneStyles();
  return (
    <div className={clsx(classes.root, hover && classes.hover, errorDrag && classes.error)} {...other}>
      &nbsp;
    </div>
  );
};

FileDropzone.propTypes = {
  hover: PropTypes.bool.isRequired,
  errorDrag: PropTypes.bool.isRequired
};

export default FileDropzone;
