import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";

const DeleteFolderDialog = ({ open, onClose, onSubmit }) => {
  return (
    <Dialog {...{ open, onClose }} aria-labelledby="delete-folder-dialog">
      <DialogTitle id="delete-folder-dialog">Delete Folder</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure to delete this folder?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          No
        </Button>
        <Button onClick={onSubmit} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteFolderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default DeleteFolderDialog;
