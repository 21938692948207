import { makeStyles } from "@material-ui/core";

const sel = ix => `tr > & > span > div[data-rbd-draggable-id="${ix}"]:before`;
const selUnSort = ix => `tr > & > div[data-rbd-draggable-id="${ix}"]:before`;

const CurrentReserveStyles = makeStyles(theme => ({
  tableHead: {
    [`${sel(1)}, ${sel(2)}, ${sel(3)}`]: { content: '""', width: 8, height: 8, borderRadius: "50%", display: "inline-block", marginRight: 8 },
    [sel(1)]: { backgroundColor: "#1599BC" },
    [sel(2)]: { backgroundColor: "#898F91" },
    [sel(3)]: { backgroundColor: "#A7CE05" },
    [`${selUnSort(1)}, ${selUnSort(2)}, ${selUnSort(3)}`]: {
      content: '""',
      width: 8,
      height: 8,
      borderRadius: "50%",
      display: "inline-block",
      marginRight: 8
    },
    [selUnSort(1)]: { backgroundColor: "#1599BC" },
    [selUnSort(2)]: { backgroundColor: "#898F91" },
    [selUnSort(3)]: { backgroundColor: "#A7CE05" }
  },
  root: { padding: theme.spacing(1, 2), marginBottom: theme.spacing(2) },
  title: { color: "#428302", flexGrow: 1 },
  sumItem: { backgroundColor: "#F3F3F3", borderRadius: 4, padding: theme.spacing(2, 1.5) },
  reserved: { backgroundColor: "#1599BC" },
  total: { backgroundColor: "#898F91" },
  oustanding: { backgroundColor: "#A7CE05" },
  sumTitle: { color: "#898F91", fontWeight: 500 },
  sumAmount: { color: "#000000", fontSize: 24, fontWeight: 500, /* marginTop: theme.spacing(1.5), */ float: "right" },
  sumGrid: { margin: theme.spacing(2, 0, 2.25, 0) },
  // sumGridItem: { minWidth: 220 },
  bar: { paddingTop: "40px" },
  textAlignRight: { textAlign: "right" }
}));

export default CurrentReserveStyles;
