import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { memo } from "react";
import ActionsFooter, { footerAction } from "../../../common/ActionsFooter";
import ClaimSearch from "./ClaimSearch";
import { useIndex } from "./hooks";
import IndexInfo from "./IndexInfo";

const IndexFile = memo(({ actions, indexItem, loading }) => {
  const {
    state: { step, claim },
    indexActions,
    indexActions: { onCancel, onReviewCompany, onReviewVendor, onSave },
    error,
    warning,
    fileViewer,
    blob
  } = useIndex(actions, indexItem);
  return (
    <>
      <div className="tc-page-content tc-no-padding">
        <Grid container wrap="nowrap" style={{ height: "100%" }}>
          <Grid item xs={7}>
            {fileViewer}
          </Grid>
          <Grid item xs={5} style={{ backgroundColor: "#ffffff" }}>
            <div className="tc-page-container">
              {step === 0 && <ClaimSearch actions={indexActions} disabled={!blob} />}
              {step === 1 && <IndexInfo actions={indexActions} {...{ claim, error, warning }} />}
            </div>
          </Grid>
        </Grid>
      </div>
      <ActionsFooter
        shadow
        actions={[
          footerAction({ id: "cancel", primary: true, outlined: true, text: "Cancel", onClick: onCancel, disabled: loading }),
          ...(step === 1
            ? [
                footerAction({ id: "index", primary: true, text: "Index Document", onClick: onSave, disabled: warning || loading }),
                ...(claim?.indexTypeId === 1 && !warning
                  ? [footerAction({ id: "review", primary: true, text: "Send to Review Company", onClick: onReviewCompany, disabled: loading })]
                  : []),
                ...(claim?.indexTypeId === 1 && warning
                  ? [footerAction({ id: "review", primary: true, text: "Send Vendor For Review", onClick: onReviewVendor, disabled: loading })]
                  : [])
              ]
            : [])
        ]}
      />
    </>
  );
});

IndexFile.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  indexItem: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired
};

export default IndexFile;
