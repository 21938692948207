import { getAllActions, getActions, setActions, postActions, documents, delActions, baseActions } from "./common";

const getSetActions = path => ({ ...getActions(path), ...setActions(path), ...delActions(path) });

const getPostActions = path => ({ ...getActions(path), ...postActions(path), ...setActions(path) });

const getPostSetActions = path => ({ ...getSetActions(path), ...postActions(path) });

const allActions = path => ({ ...getPostActions(path), ...setActions(path), ...delActions(path) });

export default {
  ...getPostSetActions("CLAIM"),
  GETALL: "GET_CLAIMS",
  GETALL_SUCCESS: "GET_CLAIMS_SUCCESS",
  OVERVIEW: getActions("CLAIM_OVERVIEW"),
  USER: setActions("CLAIM_USER"),
  WCCLAIM: getPostSetActions("CLAIM_WCCLAIM"),
  INFO: {
    CLAIMANT: getSetActions("CLAIM_CLAIMANT"),
    EMPLOYMENT: getSetActions("CLAIM_EMPLOYMENT"),
    INSURED: getSetActions("CLAIM_INSURED"),
    PROPERTY: getSetActions("CLAIM_PROPERTY"),
    INSVEHICLE: getSetActions("CLAIM_INSVEHICLE"),
    BODILYINJURY: getSetActions("CLAIM_BODILYINJURY"),
    DMVEHICLE: getSetActions("CLAIM_DMVEHICLE"),
    WITNESS: {
      ...getAllActions("CLAIM_WITNESS"),
      ...postActions("CLAIM_WITNESS"),
      ...delActions("CLAIM_WITNESS"),
      ...getSetActions("CLAIM_WITNESS")
    }
  },
  COVERAGE: {
    ...getActions("CLAIM_COVERAGE"),
    POLICIES_BY_LOB_INSURED: {
      ...getAllActions("CLAIM_POLICIES_BY_INSURED_LOB"),
      ...setActions("SET_CLAIM_POLICIES_ACTIVE")
    }
  },
  DOCUMENTS: {
    ...documents("CLAIM"),
    APPROVE: "APPROVE_CLAIMFILE",
    APPROVE_SUCCESS: "APPROVE_CLAIMFILE_SUCCESS",
    REINDEX: "REINDEX_CLAIMFILE",
    REINDEX_SUCCESS: "REINDEX_CLAIMFILE_SUCCESS",
    MOVE_FILE: "MOVE_CLAIMFILE",
    MOVE_FILE_SUCCESS: "MOVE_CLAIMFILE_SUCCESS"
  },
  NOTES: {
    POST_SUCCESS: "POST_CLAIMNOTE_SUCCESS",
    SET_SUCCESS: "SET_CLAIMNOTE_SUCCESS",
    DEL_SUCCESS: "DEL_CLAIMNOTE_SUCCESS",
    GET_SUCCESS: "GET_CLAIMNOTES_SUCCESS",
    GET_DRAFT_SUCCESS: "GET_CLAIMNOTE_DRAFT_SUCCESS",
    POST_DRAFT_SUCCESS: "POST_CLAIMNOTE_DRAFT_SUCCESS"
  },
  LEGAL: {
    ATTORNEYBYFIRM: getActions("CLAIM_ATTORNEYBYFIRM"),
    CLAIMANTATTORNEY: baseActions("CLAIM_CLAIMANTATTORNEY"),
    DEFENDANTATTORNEY: baseActions("CLAIM_DEFENDANTATTORNEY"),
    MEDITATION_ARBITRATION: getPostSetActions("CLAIM_MEDITATION_ARBITRATION"),
    HEARING: baseActions("CLAIM_HEARING"),
    TRIAL: getSetActions("CLAIM_TRIAL"),
    SETTLED: getSetActions("CLAIM_SETTLED")
  },
  RESERVES: getSetActions("CLAIM_RESERVES"),
  FINANCIAL: {
    RECOVERY: {
      ...getAllActions("CLAIM_RECOVERY_LIST"),
      ...getPostActions("CLAIM_CLAIM_RECOVERY"),
      ...getSetActions("CLAIM_CLAIM_RECOVERY"),
      ...delActions("CLAIM_CLAIM_RECOVERY")
    },
    LIST_INFO: getAllActions("CLAIM_LIST_INFO"),
    BILL: {
      ...allActions("CLAIM_BILL"),
      DOWNLOAD_FILES: "CLAIM_BILL_DOWNLOAD_FILES",
      DOWNLOAD_FILES_SUCCESS: "CLAIM_BILL_DOWNLOAD_FILES_SUCCESS"
    },
    SCHEDULEPAY: {
      ...allActions("CLAIM_SCHEDULE_PAYMENT")
    },
    SENDTOPAY: {
      ...setActions("CLAIM_FINANCIAL_SENDTOPAY")
    },
    BILLDUPLICATE: {
      ...setActions("CLAIM_FINANCIAL_BILL_DUPLICATE")
    },
    BILLREINDEX: {
      ...setActions("CLAIM_FINANCIAL_BILL_REINDEX")
    },
    VOIDED: {
      ...setActions("CLAIM_FINANCIAL_VOIDED")
    }
  },
  DIARY: {
    TEMPLATES: getActions("CLAIM_DIARY_TEMPLATES"),
    POST_SUCCESS: "POST_CLAIMDIARIES_SUCCESS",
    SET_SUCCESS: "SET_CLAIMDIARY_SUCCESS",
    DEL_SUCCESS: "DEL_CLAIMDIARY_SUCCESS",
    GET_SUCCESS: "GET_CLAIMDIARY_SUCCESS"
  },
  ACTIVITY: getActions("CLAIM_ACTIVITY"),
  COMMUNICATION: {
    ...getPostActions("CLAIM_COMMUNICATION"),
    TEMPLATES: getActions("CLAIM_COMMUNICATION_TEMPLATES"),
    CLAIMEMAILS: {
      ...getAllActions("CLAIM_COMMUNICATION_EMAILS")
    }
  },
  RESET: "CLAIM_STATE_RESET",
  GET_SECPROF_FILTER: "CLAIM_GET_SECPROF_FILTER",
  GET_SECPROF_FILTER_SUCCESS: "CLAIM_GET_SECPROF_FILTER_SUCCESS"
};
