import * as yup from "yup";
import useYupValidationSchema from "../customHooks/useYupValidationSchema";

const val = (msg, fnYup = "string") =>
  yup[fnYup]()
    .nullable()
    .required(msg);

export default () =>
  useYupValidationSchema(
    yup.object().shape({
      bankName: val("Bank Name Required"),
      accountName: val("Account Name Required"),
      accountTypeId: val("Account Type Required", "number"),
      accountNumber: val("Account Number Required")
    })
  );
