import * as yup from "yup";
import { dateSchema, isDate, isNumber } from "../../../../common/yup";

const number = () => yup.number().nullable();

const testTreatment = (val, ctx) => !ctx?.parent?.treatmentReceived || isNumber(val);

export default yup.object().shape({
  injuryType: yup.array().of(yup.object()),
  injuryTypeId: number().required("Injury Type Required"),
  claimantDateofDeath: dateSchema(
    "Claimant Date of Death Required",
    (v, c) => !Array.isArray(c?.parent?.injuryType) || !c.parent.injuryType.some(x => x.id === c.parent.injuryTypeId && x.isDeath) || isDate(v)
  ),
  parentBodyPartId: number().required("Body Part Group Required"),
  bodyPartId: number().required("Body Part Required"),
  treatmentReceived: yup.boolean(),
  treatmentTypeId: number().test("treatmentReceived", "Initial Treatment Type Required", testTreatment),
  hospitalId: number().test("treatmentReceived", "Hospital Name Required", testTreatment),
  hospitalStateId: number().test("treatmentReceived", "Hospital State Required", testTreatment)
});
