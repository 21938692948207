import { Paper, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState, useCallback } from "react";
import { useDamagedPropertyStyles } from "../index.styles";
import { useContext } from "../../context";
// import { CoverageIcon } from "../../layout";
import Form from "./Form";

const DamagedProperty = memo(({ claimant, index }) => {
  const classes = useDamagedPropertyStyles();
  const [{ damagedProperty }, , , { handleValidation }] = useContext();
  const state = (claimant || { damagedProperty }).damagedProperty;
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (loaded) handleValidation();
    else setLoaded(true);
  }, [state]);
  const inputProps = useCallback(
    path => ({
      name: `${claimant ? `multi-${claimant.id}-` : ""}dp.${path}`,
      error: `${claimant ? `claimants[${index}].` : ""}damagedProperty.${path}`
    }),
    []
  );
  const Container = claimant ? React.Fragment : Paper;
  return (
    <Container {...(claimant ? {} : { className: classes.root })}>
      <div className={clsx(classes.mb, { [classes.mt]: Boolean(claimant) })}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography className="tc-title">Damaged Property</Typography>
          </Grid>
          {/* <Grid item>
            <CoverageIcon text="Property Damage" label />
          </Grid> */}
        </Grid>
      </div>
      <Form {...{ state, inputProps }} />
    </Container>
  );
});

DamagedProperty.propTypes = { claimant: PropTypes.objectOf(PropTypes.any), index: PropTypes.number };
DamagedProperty.defaultProps = { claimant: undefined, index: undefined };

export default DamagedProperty;
