import { CLIENTR as CLIENT } from "../../../actionTypes";

const getDetail = policyId => ({ type: CLIENT.INSURED.POLICY.AGGREGATE.GET, policyId });

const getSuccess = aggregateDetails => ({ type: CLIENT.INSURED.POLICY.AGGREGATE.GET_SUCCESS, aggregateDetails });

const set = (aggregateDetails, onSuccess, onError) => ({ type: CLIENT.INSURED.POLICY.AGGREGATE.SET, aggregateDetails, onSuccess, onError });

const setSuccess = aggregateDetails => ({ type: CLIENT.INSURED.POLICY.AGGREGATE.SET_SUCCESS, aggregateDetails });

export { getDetail, getSuccess, set, setSuccess };
