import { call, all } from "redux-saga/effects";
import { toast } from "react-toastify";

export default ({ sagas, name }) =>
  function* root() {
    try {
      yield all(sagas);
    } catch (err) {
      if (process.env.NODE_ENV !== "production") yield call(toast.error, `(saga/${name})Something was wrong in ${name} saga file`);
      yield call(toast.error, `Error ${name}`);
    }
  };
