import React, { Fragment, memo } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { Formik } from "formik";
import useOnToggleBoxes from "../../../common/customHooks/useHandleBox";
import CollapsibleBox from "../../../common/CollapsibleBox";
import { SelectInput, InputText, PhoneInput } from "../../../common/inputs";

const AgentInformationForm = memo(({ agentInformation, handleChange }) => {
  const states = useSelector(({ common: { states: s } }) => s);
  const { open, onToggle } = useOnToggleBoxes({ initialValue: { agentInformation: true } });
  const { agentName, agentPhone, agentAddress1, agentCity, agentStateId, agentZip } = agentInformation || {};

  return (
    <CollapsibleBox
      title="Agent Information"
      name="agentInformation"
      {...{
        open: open.agentInformation,
        onToggle
      }}
    >
      <Formik>
        <Fragment key="Client-Add-Basic-Information">
          <Grid container spacing={4}>
            <Grid item xs>
              <InputText name="agentInformation.agentName" label="Agent Name" value={agentName} onChange={handleChange} />
              <InputText name="agentInformation.agentCity" label="Agent City" value={agentCity} onChange={handleChange} />
            </Grid>
            <Grid item xs>
              <PhoneInput name="agentInformation.agentPhone" label="Agent Phone" value={agentPhone} onChange={handleChange} />
              <SelectInput name="agentInformation.agentStateId" label="Agent State" value={agentStateId} onChange={handleChange} options={states} />
            </Grid>
            <Grid item xs>
              <InputText name="agentInformation.agentAddress1" label="Agent Address" value={agentAddress1} onChange={handleChange} />
              <InputText name="agentInformation.agentZip" label="Agent Zip" value={agentZip} onChange={handleChange} />
            </Grid>
          </Grid>
        </Fragment>
      </Formik>
    </CollapsibleBox>
  );
});

AgentInformationForm.propTypes = {
  agentInformation: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired
};

export default AgentInformationForm;
