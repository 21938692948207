import { Grid, Typography, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Fragment, memo, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import useQueryString from "../../common/customHooks/useQueryString";
import { SelectInput } from "../../common/inputs";
import { BaseRadioButtonGroup } from "../../common/inputs/RadioButtonGroup";
import styles from "./index.styles";

const CreateAsTeam = memo(({ createAsTeam, error, setState }) => {
  const { adjusterUsers, supervisorUsers, assistantUsers } = useSelector(({ common }) => common, shallowEqual);
  const { ir } = useQueryString();
  useEffect(() => {
    setState(ps => ({ ...ps, fromInitialReport: ir === "1", createAsTeam: { ...ps.createAsTeam, isReportOnly: ir === "1" } }));
  }, [ir]);
  const classes = styles();
  const isReportOnly = [true, false].indexOf(createAsTeam.isReportOnly) >= 0 ? (createAsTeam.isReportOnly === true && 1) || 0 : null;
  const disabled = isReportOnly !== 0;
  const handleChange = ({ target: { name: path, value } }) => {
    const [, name] = path.split(".");
    setState(state => ({
      ...state,
      createAsTeam: {
        ...state.createAsTeam,
        [name]: name !== "isReportOnly" ? value : value === "true",
        ...(name === "isReportOnly" && value === "true"
          ? ["adjusterUserId", "supervisorUserId", "assistantUserId"].reduce((a, k) => ({ ...a, [k]: undefined }), {})
          : {})
      },
      validation: new Date().getTime()
    }));
  };
  const inputProps = name => ({
    value: createAsTeam[name.split(".")[1]],
    name,
    onChange: handleChange,
    disabled,
    error: error[name]
  });
  const options = [
    { id: true, text: "Report Only" },
    { id: false, text: "Claim" }
  ];
  const reqLabel = label => `${label}${createAsTeam.isReportOnly ? "" : " *"}`;
  return (
    <Fragment key="team-form-box">
      <form>
        <Paper className={classes.root1}>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <div className={classes.createAs}>
                <Typography className="tc-title">Create As</Typography>
                <BaseRadioButtonGroup
                  name="createAsTeam.isReportOnly"
                  options={options}
                  value={createAsTeam.isReportOnly}
                  row
                  onChange={handleChange}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <SelectInput options={adjusterUsers} {...inputProps("createAsTeam.adjusterUserId")} label={reqLabel("Adjuster")} />
            </Grid>
            <Grid item xs={3}>
              <SelectInput options={supervisorUsers} {...inputProps("createAsTeam.supervisorUserId")} label={reqLabel("Supervisor")} />
            </Grid>
            <Grid item xs={3}>
              <SelectInput options={assistantUsers} {...inputProps("createAsTeam.assistantUserId")} label="Assistant" />
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Fragment>
  );
});

CreateAsTeam.propTypes = {
  createAsTeam: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.string).isRequired,
  setState: PropTypes.func.isRequired
};

export default CreateAsTeam;
