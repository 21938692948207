import { makeStyles } from "@material-ui/core";

export const useClaimantStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2.75, 2.75, 0.75, 2.75),
    marginTop: theme.spacing(2.75)
  },
  sameAsInsured: {
    height: 38,
    padding: "0 0 8px 0 !important",
    marginTop: -6,
    "& > label": { margin: "0 0 !important" }
  },
  head: { marginBottom: theme.spacing(1), alignItems: "center", flexWrap: "nowrap" },
  number: { width: theme.spacing(4), height: theme.spacing(4), color: "#FFFFFF", backgroundColor: "#004763" },
  pr1: { paddingRight: theme.spacing(1) },
  pl1: { paddingLeft: theme.spacing(1) }
}));

export const useAddClaimantStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2.75)
  }
}));

export const useDamagedVehicleStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2.75, 2.75, 0.75, 2.75),
    marginTop: theme.spacing(2.75)
  },
  mt: { marginTop: theme.spacing(2) },
  mb: { marginBottom: theme.spacing(1) },
  marginDivider: {
    marginBottom: theme.spacing(2)
  }
}));

export const useDamagedPropertyStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2.75, 2.75, 0.75, 2.75),
    marginTop: theme.spacing(2.75)
  },
  mt: { marginTop: theme.spacing(2.5) },
  mb: { marginBottom: theme.spacing(1) },
  marginDivider: {
    marginBottom: theme.spacing(2)
  }
}));

export const useBodiliInjuryStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2.75, 2.75, 0.75, 2.75),
    marginTop: theme.spacing(2.75)
  },
  mt: { marginTop: theme.spacing(2.5) },
  mb: { marginBottom: theme.spacing(1) },
  pr1: {
    paddingRight: theme.spacing(1)
  },
  pl1: {
    paddingLeft: theme.spacing(1)
  },
  marginDevider: {
    marginBottom: theme.spacing(2)
  }
}));
