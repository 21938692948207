import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100%"
  },
  container: {
    flexGrow: 1,
    overflowY: "auto",
    marginBottom: 62
  },
  content: {
    padding: theme.spacing(2.75)
  }
}));
