import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Paper, Typography } from "@material-ui/core";
import moment from "moment";
import MaterialTable from "../../common/table/MaterialTable";
import { get } from "../../../redux/actions/dashboard/claimsLossesByAdjusterDetail";
import { useLoading } from "../../common/Loading";
import ClaimStatus from "../../claims/ClaimStatus";
import ClaimProcessingStatus from "../../claims/ClaimProcessingStatus";
import styles from "./ClaimLossesByAdjusterDetail.styles";

const ClaimLossesByAdjusterDetail = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { actions, Loading } = useLoading(true);
  const { showLoading, hideLoading } = actions;

  const store = useSelector(({ dashboard: { claimsLossesByAdjusterDetail } }) => ({ claimsLossesByAdjusterDetail }), shallowEqual);

  const { claimsLossesByAdjusterDetail } = store || {};
  const [fomatedClaimsLossesByAdjuster, setFormatedClaimsLossesByAdjuster] = useState(claimsLossesByAdjusterDetail);

  useEffect(() => {
    setFormatedClaimsLossesByAdjuster(claimsLossesByAdjusterDetail.map(item => ({ ...item, totalincurred: item.amount + item.reserved })));
  }, [claimsLossesByAdjusterDetail]);

  useEffect(() => {
    showLoading();
    dispatch(get(hideLoading, hideLoading));
  }, []);

  const tableProps = {
    columns: [
      { title: "Adjuster", field: "adjuster" },
      {
        title: "Claim Number",
        field: "number",
        render: rowData => (
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: 5 }}>
              <Link to={`/claim/${rowData.id}/overview`} blue>
                {rowData.number}
              </Link>
            </span>
            <span style={{ marginRight: 5 }}>
              <ClaimStatus status={rowData.status} />
            </span>
            <ClaimProcessingStatus processingStatus={rowData.processingStatus} />
          </div>
        )
      },
      { title: "Claimant", field: "claimant" },
      { title: "Date of Loss", field: "dateofLoss", render: rowData => (rowData.dateOfLoss ? moment(rowData.dateOfLoss).format("M/D/YYYY") : "") },
      { title: "Description", field: "description" },
      { title: "TotalPaid", field: "amount" },
      { title: "Outstanding Reserve ", field: "reserved" },
      { title: "Total incurred ", field: "totalincurred" }
    ],
    data: fomatedClaimsLossesByAdjuster,
    hidePaging: true
  };
  const config = { position: "static", color: "inherit" };
  return (
    <div className="tc-page-container">
      <AppBar {...config} className={classes.appNavBarHeader}>
        <Toolbar>
          <Typography variant="h6">Client List</Typography>
        </Toolbar>
      </AppBar>
      <div className="tc-page-content">
        <Paper className="tc-relative tc-o-hidden tc-box-content">
          {Loading}
          <MaterialTable {...tableProps} />
        </Paper>
      </div>
    </div>
  );
};

ClaimLossesByAdjusterDetail.propTypes = {};

ClaimLossesByAdjusterDetail.defaultProps = {};

export default ClaimLossesByAdjusterDetail;
