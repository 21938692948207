import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FormControl, FormLabel, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import HtmlParser from "./HtmlParser";
import MoneyLabel from "./MoneyLabel";

const lpStyles = makeStyles(theme => ({
  root: { marginBottom: theme.spacing(2) },
  value: {
    minHeight: "1.1876em",
    margin: 0,
    fontSize: "0.875rem",
    display: "block",
    padding: "6px 0 7px",
    boxSizing: "content-box",
    color: "#000000"
  }
}));

const LabelValue = ({
  label,
  value,
  classes: css,
  isDate,
  isShortAddress,
  isAddress,
  isMoney,
  isInteger,
  formatHH,
  showAddress2,
  html,
  ...other
}) => {
  const styles = { ...lpStyles(), label: "tc-label tc-shrink" };
  const classes = Object.keys(styles).reduce((acc, k) => ({ ...acc, [k]: clsx(styles[k], css[k]) }), {});
  const getfullAddress = () => {
    const { address1, address2, city, county, state, zipCode } = value;
    const mainAddress = `${address1 || ""}${address1 && address2 ? ", " : ""}${address2 || ""}`.trim();
    const complementAddress = `${county || ""}  ${city || ""}${city && (state || zipCode) ? ", " : ""}${state || ""}${state ? " " : ""}${zipCode ||
      ""}`.trim();
    return (
      <Fragment key="full-address">
        {mainAddress}
        {mainAddress && complementAddress ? <br /> : ""}
        {complementAddress}
      </Fragment>
    );
  };
  const getCompactAddress = () => {
    const { address1, address2, city, stateCode, zipCode } = value;
    const bef = `${showAddress2 ? address2 : address1 || ""} ${city || ""}`.trim();
    const aft = `${stateCode || ""} ${zipCode || ""}`.trim();
    return `${bef}${bef && aft ? ", " : ""}${aft}` || "-";
  };
  const getAddressLabel = () => (isShortAddress ? getCompactAddress() : getfullAddress());
  const renderValue = () => {
    if (isMoney || isInteger) return <MoneyLabel {...{ value, isInteger }} />;
    if (isAddress || isShortAddress) {
      if (!value || typeof value !== "object") return "-";
      const { address1, address2, city, county, state, zipCode } = value;
      return (
        <Fragment key="address-label-container">
          {(showAddress2 && address2) || state || address1 || county || city || zipCode ? getAddressLabel() : "-"}
        </Fragment>
      );
    }
    if (isDate) {
      if (formatHH && value) return moment(value).format("HH:mm");
      if (value) return moment(value).format("M/D/YYYY");
    }
    if (html && !!value) return <HtmlParser {...{ value }} />;
    return value || "-";
  };

  return (
    <Fragment key="label">
      <FormControl fullWidth component="fieldset" className={classes.root} {...other}>
        <FormLabel className={classes.label}>{label}</FormLabel>
        <FormLabel className={classes.value}>{renderValue()}</FormLabel>
      </FormControl>
    </Fragment>
  );
};

LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  classes: PropTypes.objectOf(PropTypes.string),
  isDate: PropTypes.bool,
  isShortAddress: PropTypes.bool,
  isAddress: PropTypes.bool,
  isMoney: PropTypes.bool,
  isInteger: PropTypes.bool,
  showAddress2: PropTypes.bool,
  formatHH: PropTypes.bool,
  html: PropTypes.bool
};

LabelValue.defaultProps = {
  value: null,
  classes: {},
  isDate: false,
  isShortAddress: false,
  isAddress: false,
  isMoney: false,
  isInteger: false,
  showAddress2: false,
  formatHH: false,
  html: false
};

export default LabelValue;
