import * as yup from "yup";

const number = () => yup.number().nullable();
const date = () => yup.date().nullable();

export default yup.object().shape({
  serviceDate: date().required("Service Date Required"),
  amount: number().required("Bill Amount Required"),
  vendorTypeId: number(),
  name: yup.string().when("vendorId", {
    is: vendorId => vendorId !== "",
    then: yup.string().required("Vendor Required")
  })
});
