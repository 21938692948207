import { CLIENTR as CLIENT } from "../../actionTypes";

export const clearLocation = () => ({ type: CLIENT.INSURED.LOCATION.CLEAR });

export const getAllLocations = id => ({ type: CLIENT.INSURED.LOCATION.GET_ALL, id });

export const getSuccess = locations => ({ type: CLIENT.INSURED.LOCATION.GET_ALL_SUCCESS, locations });

export const addLocation = (data, onSuccess) => ({ type: CLIENT.INSURED.LOCATION.POST, data, onSuccess });

export const addSuccess = locations => ({ type: CLIENT.INSURED.LOCATION.POST_SUCCESS, locations });

export const setLocation = (data, onSuccess) => ({ type: CLIENT.INSURED.LOCATION.SET, data, onSuccess });

export const setSuccess = locations => ({ type: CLIENT.INSURED.LOCATION.SET_SUCCESS, locations });

// Insured Location
export const getLocationtById = id => ({ type: CLIENT.INSURED.LOCATION.GET, id });

export const getByIdSuccess = iLocation => ({ type: CLIENT.INSURED.LOCATION.GET_SUCCESS, iLocation });
