import DateFnsUtils from "@date-io/date-fns";
import { TextField, Tooltip } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import PropTypes from "prop-types";
import React from "react";

const startAdornment = (
  <Tooltip title="Filter">
    <FilterList style={{ marginRight: 8 }} />
  </Tooltip>
);

const TextFieldComponent = ({ InputProps, hideFilterIcon, ...props }) => (
  <TextField {...props} InputProps={{ ...(InputProps || {}), startAdornment: hideFilterIcon ? undefined : startAdornment }} />
);
TextFieldComponent.propTypes = { hideFilterIcon: PropTypes.bool, InputProps: PropTypes.objectOf(PropTypes.any) };
TextFieldComponent.defaultProps = { hideFilterIcon: false, InputProps: undefined };

const DateFilter = props => {
  const { columnDef, onFilterChanged, hideFilterIcon: hf } = props;
  const hideFilterIcon = hf || columnDef.hideFilterIcon;
  const pickerProps = {
    value: columnDef.tableData.filterValue || null,
    onChange: date => onFilterChanged(columnDef.tableData.id, date),
    format: "M/d/yyyy",
    placeholder: columnDef.filterPlaceholder,
    TextFieldComponent: p => <TextFieldComponent {...p} {...{ hideFilterIcon }} />
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker clearable {...pickerProps} />
    </MuiPickersUtilsProvider>
  );
};

DateFilter.propTypes = {
  columnDef: PropTypes.objectOf(PropTypes.any).isRequired,
  hideFilterIcon: PropTypes.bool,
  onFilterChanged: PropTypes.func.isRequired
};
DateFilter.defaultProps = { hideFilterIcon: false };

export default DateFilter;
