import React, { memo } from "react";
import PropTypes from "prop-types";
import { FormHelperText, InputLabel, FormControl } from "@material-ui/core";
import SelectBase from "./SelectBase";
import { HocInput } from "./HocInput";

export const BaseSelectInput = memo(
  ({ id, name, label, onChange, value, error, options, disabled, labelClass, allowClear, renderOption, ...other }) => {
    return (
      <FormControl fullWidth {...other} error={!!error} {...{ disabled }}>
        {label && (
          <InputLabel htmlFor={name} className={labelClass}>
            {label}
          </InputLabel>
        )}
        <SelectBase {...{ id, name, options, value, onChange, disabled, allowClear, renderOption }} />
        {typeof error !== "boolean" && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  },
  (prev, next) => !["id", "value", "options", "disabled", "error", "onChange"].some(key => prev[key] !== next[key])
);

BaseSelectInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  allowClear: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.bool]),
  options: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  labelClass: PropTypes.string,
  renderOption: PropTypes.func
};

BaseSelectInput.defaultProps = {
  id: undefined,
  onChange: undefined,
  allowClear: false,
  label: null,
  value: "",
  error: undefined,
  options: [],
  disabled: false,
  labelClass: "",
  renderOption: undefined
};

export default HocInput(BaseSelectInput);
