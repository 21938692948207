import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  appNavBarHeader: { backgroundColor: "#F4F8F9", [theme.breakpoints.up("sm")]: { minWidth: "initial !important" }, "& h6": { color: "#004763" } },
  container: {
    padding: theme.spacing(1, 3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  footerButton: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  footer: {
    background: "#fff"
  }
}));
