import { CATASTROPHE } from "./actionTypes";
import * as docActions from "./common/documents";

export const getAll = (onSuccess, onError) => ({ type: CATASTROPHE.GETALL, onSuccess, onError });
export const getAllSuccess = catastrophes => ({ type: CATASTROPHE.GETALL_SUCCESS, catastrophes });

export const get = (id, onSuccess, onError) => ({ type: CATASTROPHE.GET, id, onSuccess, onError });
export const getSuccess = catastrophe => ({ type: CATASTROPHE.GET_SUCCESS, catastrophe });

export const set = (catastrophe, onSuccess, onError) => ({ type: CATASTROPHE.SET, catastrophe, onSuccess, onError });
export const setSuccess = catastrophe => ({ type: CATASTROPHE.SET_SUCCESS, catastrophe });

export const post = (catastrophe, onSuccess, onError) => ({ type: CATASTROPHE.POST, catastrophe, onSuccess, onError });
export const postSuccess = catastrophes => ({ type: CATASTROPHE.POST_SUCCESS, catastrophes });

export const del = id => ({ type: CATASTROPHE.DEL, id });
export const delSuccess = catastrophes => ({ type: CATASTROPHE.DEL_SUCCESS, catastrophes });

export const findCatastrophes = filter => ({ type: CATASTROPHE.FIND, filter });
export const findSuccess = catastropheDuplicates => ({ type: CATASTROPHE.FIND_SUCCESS, catastropheDuplicates });

export const associateCatastrophe = associated => ({ type: CATASTROPHE.ASSOCIATE_CATASTROPHE, associated });
export const associateSuccess = catastrophe => ({ type: CATASTROPHE.ASSOCIATE_CATASTROPHE_SUCCESS, catastrophe });

export const getReserves = (catastropheId, onSuccess, onError) => ({ type: CATASTROPHE.GET_RESERVES, catastropheId, onSuccess, onError });
export const getReservesSuccess = catastropheReserves => ({ type: CATASTROPHE.GET_RESERVES_SUCCESS, catastropheReserves });

export const loadFiles = id => ({ type: CATASTROPHE.LOAD_FILES, id });
export const loadFilesSuccess = files => ({ type: CATASTROPHE.LOAD_FILES_SUCCESS, files });

export const uploadFiles = (documents, onSuccess) => ({ type: CATASTROPHE.UPLOAD_FILES, documents, onSuccess });
export const uploadFilesSuccess = documents => ({ type: CATASTROPHE.UPLOAD_FILES_SUCCESS, documents });

export const deleteFile = (data, onSuccess) => ({ type: CATASTROPHE.DELETE_FILE, data, onSuccess });
export const deleteFileSuccess = documents => ({ type: CATASTROPHE.DELETE_FILE_SUCCESS, documents });

export const openFile = docActions.openFile(CATASTROPHE.OPEN_FILE);

export const getFilterFields = (catastropheId, onSuccess) => ({ type: CATASTROPHE.GET_FILTER_FIELD, catastropheId, onSuccess });
export const getFilterFieldsSuccess = filterFields => ({ type: CATASTROPHE.GET_FILTER_FIELD_SUCCESS, filterFields });

export const reset = () => ({ type: CATASTROPHE.RESET });
