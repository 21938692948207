const location = { address1: undefined, address2: undefined, city: undefined, stateId: undefined, zipCode: undefined };

export default {
  damaged: {
    amountEntireLoss: undefined,
    departamentTypeId: undefined,
    description: undefined,
    hasMortgagedProperty: false,
    isClaimantMortgageCompany: false,
    propertyDamagedLossTypeId: undefined,
    propertyDamagedTypeId: undefined,
    reportNumber: undefined
  },
  property: {
    id: undefined,
    propertyTypeId: undefined,
    hasMortgage: false,
    location,
    mortgageCompany: {
      email: undefined,
      location,
      mainContactName: undefined,
      name: undefined,
      phone: undefined
    }
  }
};
