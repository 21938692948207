import { CLIENTR as CLIENT } from "../../../actionTypes";

const generic = type => props => ({ type, ...props });
const success = type => propertiesInsured => ({ type, propertiesInsured });

export const get = (propertyId, onSuccess, onError) => ({ type: CLIENT.INSURED.POLICY.PROPERTY.GET, propertyId, onSuccess, onError });
export const getSuccess = propertyInsured => ({ type: CLIENT.INSURED.POLICY.PROPERTY.GET_SUCCESS, propertyInsured });

export const getProperties = generic(CLIENT.INSURED.POLICY.PROPERTY.GET_PROPERTIES);
export const getPropertiesSuccess = success(CLIENT.INSURED.POLICY.PROPERTY.GET_PROPERTIES_SUCCESS);

export const addProperty = generic(CLIENT.INSURED.POLICY.PROPERTY.POST);
export const addPropertySuccess = success(CLIENT.INSURED.POLICY.PROPERTY.POST_SUCCESS);

export const setProperty = generic(CLIENT.INSURED.POLICY.PROPERTY.SET);
export const setPropertySuccess = success(CLIENT.INSURED.POLICY.PROPERTY.SET_SUCCESS);

export const deleteProperty = generic(CLIENT.INSURED.POLICY.PROPERTY.DEL);
export const deletePropertySuccess = success(CLIENT.INSURED.POLICY.PROPERTY.DEL_SUCCESS);
