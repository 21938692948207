import React, { useContext, Fragment } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ProcessingQueue from "../../components/financial/ProcessingQueue";
import Scheduled from "../../components/financial/Scheduled";
import History from "../../components/financial/History";
import Settlements from "../../components/financial/Settlement";
import PageNotAllowed from "../../components/PageNotAllowed";
import ActionsAllowedContext from "../../components/common/contexts/ActionsAllowed";
import Loading from "../../components/common/Loading";

const Routes = props => {
  const { path } = useRouteMatch();
  const routesAllowed = useContext(ActionsAllowedContext);
  const allowed = a => routesAllowed.find(e => e.route === a)?.read;
  return (
    <Fragment key="route-fragment">
      {routesAllowed.length <= 0 ? (
        <Loading />
      ) : (
        <Switch>
          <Route
            path={`${path}/processingqueue`}
            render={() => (allowed("processingqueue") && <ProcessingQueue {...props} />) || <PageNotAllowed />}
          />
          <Route path={`${path}/scheduled`} render={() => (allowed("scheduled") && <Scheduled />) || <PageNotAllowed />} />
          <Route path={`${path}/history`} render={() => (allowed("history") && <History />) || <PageNotAllowed />} />
          <Route path={`${path}/settlements`} render={() => (allowed("settlements") && <Settlements />) || <PageNotAllowed />} />
        </Switch>
      )}
    </Fragment>
  );
};
export default Routes;
