/* eslint-disable react/no-danger */
import PropTypes from "prop-types";
import React from "react";

const HtmlParser = ({ value, ...other }) => !!value && <span dangerouslySetInnerHTML={{ __html: value }} {...other} />;

HtmlParser.propTypes = { value: PropTypes.string };
HtmlParser.defaultProps = { value: undefined };

export default HtmlParser;
