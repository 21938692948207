import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { Grid, Breadcrumbs, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import useStyles from "./BreadCrumbs.style";

const BreadCrumbsNavigation = ({ breadData, activeTitle, tabs, min, gutterBottom }) => {
  const location = useLocation();
  const classes = useStyles();

  const getTitle = () => {
    const { pathname } = location;
    const lastSegment = pathname.split("/").slice(-1)[0];
    let title = `{title}: ${lastSegment}`;
    if (tabs.length > 0) {
      const label = (tabs.filter(value => value[0] === lastSegment)[0] || [])[1];
      title = `{title}: ${label}`;
    }
    const { name } = breadData;
    title = title.replace("{title}", name);

    return title;
  };

  return (
    <div className={clsx({ [classes.container]: !min })}>
      <Grid container className={clsx(classes[min ? "minimum" : "wrapper"], { [classes.gutterBottom]: gutterBottom })}>
        <Grid item xs>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {(typeof breadData === "string" && <Typography>{breadData}</Typography>) || <Link to={breadData.to}>{breadData.linkTitle}</Link>}
            {typeof activeTitle === "string" && <Typography>{activeTitle || getTitle()}</Typography>}
            {Array.isArray(activeTitle) &&
              activeTitle.map(
                ({ to, title }) =>
                  (to && (
                    <Link key={to} to={to}>
                      {title}
                    </Link>
                  )) || <Typography key={title}>{title}</Typography>
              )}
          </Breadcrumbs>
        </Grid>
      </Grid>
    </div>
  );
};

BreadCrumbsNavigation.propTypes = {
  breadData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      linkTitle: PropTypes.string,
      to: PropTypes.string,
      name: PropTypes.string
    })
  ]).isRequired,
  tabs: PropTypes.instanceOf(Array),
  activeTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({ to: PropTypes.string, title: PropTypes.string }))]),
  min: PropTypes.bool,
  gutterBottom: PropTypes.bool
};

BreadCrumbsNavigation.defaultProps = {
  tabs: [],
  activeTitle: "",
  min: false,
  gutterBottom: false
};

export default BreadCrumbsNavigation;
