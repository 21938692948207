import React, { memo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Paper, Grid, Typography } from "@material-ui/core";
import { DateTimeInput, TimeInput, InputText, SelectInput } from "../../../../common/inputs";
import { useContext, actionTypes } from "../../context";
import OccurrenceRadioGroup from "./OccurrenceRadioGroup";
import { useIncidentStyles } from "./index.styles";

const IncidentForm = memo(() => {
  const [
    { incident, occurrences },
    ,
    handleChange,
    {
      step2: { validationMessages }
    }
  ] = useContext();
  const classes = useIncidentStyles();
  const { states } = useSelector(({ common }) => common, shallowEqual);
  const inputProps = name => ({
    name,
    onChange: e => handleChange(e, actionTypes.SET_INCIDENT),
    value: incident[name],
    error: validationMessages[`incident.${name}`]
  });

  return (
    <Paper className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={8}>
          <Typography className="tc-title" gutterBottom>
            Incident Description
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs>
              <DateTimeInput {...inputProps("dateofLoss")} label="Date of Loss *" keyboard />
              <Grid container>
                <Grid item xs className={classes.pr1}>
                  <TimeInput {...inputProps("timeofInjury")} label="Time of Injury *" keyboard />
                </Grid>
                <Grid item xs className={classes.pl1}>
                  <DateTimeInput {...inputProps("dateReported")} label="Reported Date*" keyboard />
                </Grid>
              </Grid>
              <InputText {...inputProps("address1")} label="Address of Loss 1 *" />
            </Grid>
            <Grid item xs>
              <InputText {...inputProps("city")} label="City *" />
              <SelectInput {...inputProps("stateId")} label="State *" options={states} />
              <InputText {...inputProps("zipCode")} label="Zip *" />
            </Grid>
          </Grid>
          <InputText {...inputProps("description")} label="Description" rows={3} />
        </Grid>
        <Grid item xs={4} className={classes.associate}>
          <Paper>
            <div className={classes.title}>Associate Occurrence</div>
            <OccurrenceRadioGroup {...{ occurrences, ...inputProps("occurrenceId") }} />
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
});

export default IncidentForm;
