import React, { useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Chip, Collapse, Grid, IconButton } from "@material-ui/core";
import { VpnKey, Reply, Delete, Edit, Clear, Save, Link as LinkIcon } from "@material-ui/icons";
import clsx from "clsx";
import moment from "moment";
import ResponseButton from "./ResponseButton";
import styles from "./Note.styles";
import NoteForm from "./NoteForm";
import AssociateNoteContext from "./AssociateNoteContext";
import Count from "../Count";
import { icon } from "../index.styles";
import useCustomConfirm from "../customHooks/useCustomConfirm";
import useYupValidationSchema from "../customHooks/useYupValidationSchema";
import FileIcon from "../documents/FileIcon";
import schemaValidation from "./yupSchema";
import { array } from "../../../common";

const Note = ({ actions, note, isResponse, hideReply, editHideFirstRow, actionsAllowed, doubleColumn }) => {
  const { add, edit, del, onOpen } = actions;
  const [validationEditMessages, setValidationEditMessages] = useState({});
  const [validationMessagesResp, setValidationMessagesResp] = useState({});
  const [state, setState] = useState({});
  const responses = (note.responses || []).length;
  const hasResponses = !isResponse && responses > 0;
  const classes = styles();
  const [open, setOpen] = useState(true);
  const [isEdit, setEdit] = useState(false);
  const [responseNote, setResponseNote] = useState({});
  const [willRespond, setWillRespond] = useState(false);
  const responseRef = useRef(null);
  const associateNote = useContext(AssociateNoteContext);
  const { allEntityIds, entityType } = associateNote;

  const { handleValidateSchema } = useYupValidationSchema(schemaValidation);

  const onCancel = fromEdit => () => {
    if (fromEdit) {
      setEdit(false);
      return setValidationEditMessages({});
    }
    setWillRespond(false);
    return setValidationMessagesResp({});
  };

  const handleChange = ({ target: { name, value } }) =>
    setState(ps => {
      const ns = typeof value === "function" ? value(ps) : { ...ps, [name]: value };
      const { validationMessages } = handleValidateSchema(ns);
      setValidationEditMessages(validationMessages);
      return ns;
    });

  const handleChangeResponse = ({ target: { name, value } }) =>
    setResponseNote(ps => {
      const ns = typeof value === "function" ? value(ps) : { ...ps, [name]: value };
      const { validationMessages } = handleValidateSchema(ns);
      setValidationMessagesResp(validationMessages);
      return ns;
    });
  const NoteChip = props => <Chip variant="outlined" size="small" {...props} className={classes.chip} />;
  const onWillRespond = () => {
    setResponseNote({
      ...allEntityIds,
      parentNoteId: note.id,
      typeId: note.typeId,
      isConfidential: note.isConfidential
    });

    setWillRespond(true);
    const i = setInterval(() => {
      if (responseRef.current) {
        clearInterval(i);
        window.scrollTo({ top: responseRef.current.offsetTop - 80, behavior: "smooth" });
      }
    }, 100);
  };
  const onEditNote = () => {
    const { validationMessages, isValid } = handleValidateSchema(state);
    if (isValid) {
      edit({ note: state, onSuccess: onCancel(true) });
    } else {
      setValidationMessagesResp(validationMessages);
    }
  };
  const onAddReply = () => {
    const { validationMessages, isValid } = handleValidateSchema(responseNote);
    if (isValid) {
      const stateNote = {
        ...responseNote,
        ...allEntityIds,
        parentNoteId: note.id,
        typeId: note.typeId,
        isConfidential: note.isConfidential
      };
      add({ note: stateNote, onSuccess: onCancel() });
    } else setValidationMessagesResp(validationMessages);
  };
  const countAssociated = (note.claimIds?.length + note.occurrenceIds?.length).toString();
  const classIcon = icon();

  const handleDelete = noteItem => {
    del({ note: noteItem });
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleDelete, "Cancel", "Yes, Delete");
  const handleConfirmDelete = noteItem => {
    const type = noteItem.parentNoteId == null ? "Note" : "Response";
    let message = "";
    switch (entityType) {
      case "claim":
        message =
          noteItem.parentNoteId == null
            ? `The note and all the replays associated are going to be deleted.`
            : `The response associated to the note will be deleted.`;
        break;
      case "occurrence":
        message = "The note will be deleted from the occurrences and from all the claims associated.";
        break;
      case "catastrophe":
        message = "The note will be deleted from the Catastrophe and from all the claims/occurrences associated.";
        break;
      default:
        message = "The note will be deleted.";
    }
    handleCustomConfirm({
      oData: noteItem,
      titleText: `Delete ${type}`,
      messageText: (
        <>
          <p>{message}</p>
          <p>Are you sure you want to proceed?</p>
        </>
      )
    });
  };
  const canDelete = note.canEdit && actionsAllowed.delete && (!note.isConfidential || actionsAllowed.confidentialDelete);
  const canUpdate = note.canEdit && actionsAllowed.update && (!note.isConfidential || actionsAllowed.confidentialUpdate);
  const canCreate = actionsAllowed.create && (!note.isConfidential || actionsAllowed.confidentialCreate);
  const onEdit = () => {
    if (doubleColumn) return edit(note, responseRef);
    setState(note);
    return setEdit(true);
  };
  return (
    <>
      <div className={clsx(classes.root, { [classes.response]: isResponse, [classes.note]: !isResponse })}>
        {isEdit && !doubleColumn && (canCreate || canUpdate) ? (
          <>
            <NoteForm
              hideFirstRow={isResponse || editHideFirstRow}
              onChange={handleChange}
              data={state}
              actionsAllowed={actionsAllowed}
              {...{ validationMessages: validationEditMessages }}
            />
            <div className={classes.actions}>
              <IconButton onClick={onCancel(true)}>
                <Clear fontSize="small" className={classes.actionIcons} />
              </IconButton>
              <IconButton onClick={onEditNote}>
                <Save fontSize="small" className={classes.actionIcons} />
              </IconButton>
            </div>
          </>
        ) : (
          <>
            <h4 className={classes.title}>
              {note.title}
              {!isResponse && (
                <>
                  {note.type && <NoteChip label={note.type} />}
                  {note.isConfidential && <NoteChip label="Confidential" />}
                  {(note.occurrenceId || note.catastropheId) && !note.isNoteAssociated && (
                    <>
                      <LinkIcon className={classIcon.link} />
                      <Count>{countAssociated}</Count>
                    </>
                  )}
                </>
              )}
            </h4>
            {note.allowed && (
              <div className={classes.actions}>
                {!hideReply && !isResponse && canCreate && (
                  <IconButton onClick={onWillRespond}>
                    <Reply fontSize="small" className={classes.actionIcons} />
                  </IconButton>
                )}
                {canDelete && (
                  <IconButton onClick={() => handleConfirmDelete(note)}>
                    <Delete fontSize="small" className={classes.actionIcons} />
                  </IconButton>
                )}
                {canUpdate && (
                  <IconButton onClick={onEdit}>
                    <Edit fontSize="small" className={classes.actionIcons} />
                  </IconButton>
                )}
              </div>
            )}
            {note.allowed ? (
              <>
                <p>{note.note}</p>
                <Grid container spacing={1}>
                  {array(note.files).map(file => (
                    <Grid item key={file.id}>
                      <Chip icon={<FileIcon file type={file.fileType} />} label={file.fileName} onClick={() => onOpen(file.id, note.id)} />
                    </Grid>
                  ))}
                </Grid>
                <small className={classes.date}>
                  {`${moment(note.updatedOn || note.createdOn).format("M/D/YYYY [at] H:mm a")} by ${note.updatedByName || note.createdByName}`}
                </small>
              </>
            ) : (
              <div className={classes.confidential}>
                <VpnKey className={classes.confidentialIcon} />
              </div>
            )}
          </>
        )}
        {(willRespond || (note.allowed && hasResponses)) && (
          <ResponseButton {...{ onClick: () => setOpen(ps => (hasResponses ? !ps : ps)), open: open || willRespond, responses }} />
        )}
      </div>
      {willRespond && (
        <ul ref={responseRef} className={clsx(classes.list, { withreplies: hasResponses && open })}>
          <li>
            <div className={clsx(classes.root, classes.response)}>
              <NoteForm
                hideFirstRow
                onChange={handleChangeResponse}
                data={{ ...responseNote, parentNoteId: note.id }}
                {...{ validationMessages: validationMessagesResp }}
              />
              <div className={classes.actions}>
                <IconButton onClick={onCancel()}>
                  <Clear fontSize="small" />
                </IconButton>
                <IconButton onClick={onAddReply}>
                  <Save fontSize="small" />
                </IconButton>
              </div>
            </div>
          </li>
        </ul>
      )}
      {note.allowed && hasResponses && (
        <Collapse in={open}>
          <ul className={classes.list}>
            {note.responses.map(n => (
              <li key={n.id}>
                <Note {...{ actions, note: n }} isResponse actionsAllowed={actionsAllowed} />
              </li>
            ))}
          </ul>
        </Collapse>
      )}
      <Confirm />
    </>
  );
};

Note.propTypes = {
  note: PropTypes.objectOf(PropTypes.any).isRequired,
  isResponse: PropTypes.bool,
  hideReply: PropTypes.bool,
  editHideFirstRow: PropTypes.bool,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  actionsAllowed: PropTypes.objectOf(PropTypes.any),
  doubleColumn: PropTypes.bool
};

Note.defaultProps = {
  isResponse: false,
  hideReply: false,
  editHideFirstRow: false,
  actionsAllowed: { create: true, update: true, delete: true, confidentialCreate: true, confidentialUpdate: true, confidentialDelete: true },
  doubleColumn: false
};

export default Note;
