import { API, DOCUMENTS } from "./actionTypes";
import * as docActions from "./common/documents";

const success = type => documents => ({ type, documents });

export const getAll = (indexingStatusId, onComplete) => ({ type: DOCUMENTS.GETALL, indexingStatusId, onSuccess: onComplete, onError: onComplete });
export const getAllSuccess = success(DOCUMENTS.GETALL_SUCCESS);
export const deleteFile = docActions.withParams(DOCUMENTS.DEL_FILE, `${API.URL}/indexing`);
export const deleteFileSuccess = success(DOCUMENTS.DEL_FILE_SUCCESS);
export const uploadFiles = docActions.uploadFiles(DOCUMENTS.POST_FILES, `${API.URL}/indexing`);
export const downloadFiles = docActions.downloadFiles(DOCUMENTS.DOWNLOAD_FILES);
export const openFile = docActions.openFile(DOCUMENTS.OPEN_FILE);
export const post = ({ data, statusId, onSuccess, onError }) => ({ type: DOCUMENTS.POST, data, indexingStatusId: statusId, onSuccess, onError });
export const postSuccess = success(DOCUMENTS.POST_SUCCESS);
export const getReview = onComplete => ({ type: DOCUMENTS.REVIEW.GET, ...onComplete });
export const getReviewSuccess = success(DOCUMENTS.REVIEW.GET_SUCCESS);
