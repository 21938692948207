import React from "react";
import PropTypes from "prop-types";
import { Grid, Divider } from "@material-ui/core";
import LabelValue from "../../LabelValue";
import MultipleLabels from "../../Labels";

const initialLianHolder = {
  lienHolder: {
    name: "",
    phone: "",
    email: "",
    mainContactName: "",
    zipCode: "",
    location: { address1: "", address2: "", city: "", state: "", zipCode: "" }
  }
};

const DamagedVehicleInfo = ({ damagedVehicle }) => {
  const {
    hasLienholder,
    isLienholderClaimant,
    isDriverOwner,
    insuranceCarrier,
    policyNumber,
    damageDescription,
    whereIsDamage,
    estimateAmount,
    naicCode,
    driver,
    vehicleOwner,
    vehicle,
    lienholder
  } = damagedVehicle || {};
  const { licenseNumber: dLicenseNumber, firstName: dFirstName, lastName: dLastName, dateOfBirth: dDateOfBirth, phone: dPhone, email: dEmail } =
    driver || {};
  const { dateOfBirth: oDateOfBirth, firstName: oFirstName, lastName: oLastName, phone: oPhone, email: oEmail } = vehicleOwner || {};
  const {
    number: vNumber,
    vehicleYear: vYear,
    model: vModel,
    vin: vVin,
    plateNumber: vPlateNumber,
    vehicleState: vVehicleState,
    vehicleBody: vVehicleBody,
    vehicleMake: vVehicleMake
  } = vehicle || {};
  const { name: lName, phone: lPhone, email: lEmail, mainContactName: lMainContactName, location: lLocation } = lienholder || initialLianHolder;

  const driverFullName = `${dFirstName} ${dLastName}`;
  const ownerFullName = `${oFirstName} ${oLastName}`;

  return (
    <React.Fragment key="damagedvehicleinfo">
      <form style={{ padding: "10px" }}>
        <Grid container spacing={4}>
          <Grid item xs>
            <LabelValue label="Driver" value={isDriverOwner === true ? "Owner" : "Different than Owner"} />
          </Grid>
          <Grid item xs>
            <LabelValue label="Driver’s License Number" value={dLicenseNumber} />
          </Grid>
          <Grid item xs>
            <LabelValue label="Driver’s Phone Number" value={dPhone} />
          </Grid>
        </Grid>
        {hasLienholder && !isDriverOwner && (
          <>
            <Grid container spacing={4}>
              <Grid item xs>
                <MultipleLabels
                  data={[
                    { label: "Driver’s Name", value: driverFullName, key: "damagedV_driver_name" },
                    { label: "Driver’s Date of Birth", value: dDateOfBirth, isDate: true, key: "damagedV_driver_birth_date" }
                  ]}
                />
              </Grid>
              <Grid item xs>
                <LabelValue label="Driver’s Address" value={driver.location} isAddress />
              </Grid>
              <Grid item xs>
                <LabelValue label="Driver’s Email" value={dEmail} />
              </Grid>
            </Grid>
            <Divider style={{ margin: "15px 0px 15px 0px" }} />
          </>
        )}
        {hasLienholder && !isDriverOwner && isLienholderClaimant && (
          <>
            <Grid container spacing={4}>
              <Grid item xs>
                <MultipleLabels
                  data={[
                    { label: "Owner Name", value: ownerFullName, key: "damagedV_owner_name" },
                    { label: "Owner’s Date of Birth", value: oDateOfBirth, isDate: true, key: "damagedV_owner_date_birth" }
                  ]}
                />
              </Grid>
              <Grid item xs>
                <LabelValue label="Owner’s Address" value={vehicleOwner.location} isAddress />
              </Grid>
              <Grid item xs>
                <MultipleLabels
                  data={[
                    { label: "Owner’s Phone Number", value: oPhone, key: "damagedV_owner_phone" },
                    { label: "Owner’s Email", value: oEmail, key: "damagedV_owner_email" }
                  ]}
                />
              </Grid>
            </Grid>
            <Divider style={{ margin: "15px 0px 15px 0px" }} />
          </>
        )}
        <Grid container spacing={5}>
          <Grid item xs>
            <MultipleLabels
              data={[
                { label: "Vehicle", value: vNumber, key: "damagedV_vehicle" },
                { label: "Vehicle Year", value: vYear, key: "damagedV_vehicle_year" },
                { label: "Vehicle Make", value: vVehicleMake, key: "damagedV_vehicle_make" }
              ]}
            />
          </Grid>
          <Grid item xs>
            <MultipleLabels
              data={[
                { label: "Vehicle Model", value: vModel, key: "damagedV_vehicle_model" },
                { label: "Vehicle Body Type", value: vVehicleBody, key: "damagedV_vehicle_body_type" },
                { label: "V.I.N #", value: vVin, key: "damagedV_vehicle_vin" }
              ]}
            />
          </Grid>
          <Grid item xs>
            <MultipleLabels
              data={[
                { label: "Vehicle Plate Number", value: vPlateNumber, key: "damagedV_vehicle_plate" },
                { label: "Vehicle State", value: vVehicleState, key: "damagedV_vehicle_state" }
              ]}
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: "15px 0px 15px 0px" }} />
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <LabelValue label="Carrier or Agency Name" value={insuranceCarrier} />
          </Grid>
          <Grid item xs={3}>
            <LabelValue label="Policy Number" value={policyNumber} />
          </Grid>
        </Grid>
        <Divider style={{ margin: "15px 0px 15px 0px" }} />
        <Grid container spacing={4}>
          <Grid item xs>
            <LabelValue label="Damage Description" value={damageDescription} />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <LabelValue label="Where can Damage be seen?" value={whereIsDamage} />
          </Grid>
          <Divider style={{ margin: "15px 0px 15px 0px" }} />
          <Grid item xs={3}>
            <LabelValue label="NAIC Code" value={naicCode} />
          </Grid>
          <Grid item xs={3}>
            <LabelValue label="Estimate Amount Reported" value={estimateAmount} />
          </Grid>
        </Grid>
        {hasLienholder && !isLienholderClaimant && (
          <>
            <Divider style={{ margin: "15px 0px 15px 0px" }} />
            <Grid container spacing={4}>
              <Grid item xs>
                <MultipleLabels
                  data={[
                    { label: "LienHolder Name", value: lName, key: "damagedV_LienHolder_name" },
                    { label: "LienHolder Address", value: lLocation || {}, key: "damagedV_LienHolder_address1", isAddress: true }
                  ]}
                />
              </Grid>
              <Grid item xs>
                <MultipleLabels
                  data={[
                    { label: "LienHolder Phone Number", value: lPhone, key: "damagedV_LienHolder_phone" },
                    { label: "LienHolder Email", value: lEmail, key: "damagedV_LienHolder_email" },
                    { label: "LienHolder Main Contact", value: lMainContactName, key: "damagedV_LienHolder_contact" }
                  ]}
                />
              </Grid>
            </Grid>
          </>
        )}
      </form>
    </React.Fragment>
  );
};

DamagedVehicleInfo.propTypes = {
  damagedVehicle: PropTypes.objectOf(PropTypes.any).isRequired
};

export default DamagedVehicleInfo;
