import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Paper, Typography, makeStyles, Collapse, Grid } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { useLoading } from "./Loading";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(3)
  },
  title: { color: "#428302", display: "inline-block" },
  pointer: { cursor: "pointer" },
  expand: { color: "#428302" }
}));

const CollapsibleBox = ({ className, cssTitle, title, header, children, open, name, onToggle, edit: { setEdit, handleToggle }, isCollapse }) => {
  const classes = useStyles();
  const onClick = isCollapse && typeof onToggle === "function" ? () => onToggle(name) : undefined;
  return (
    <Paper className={clsx(classes.root, className)}>
      <Grid container alignItems="center" spacing={1} className={classes.header}>
        {isCollapse && (
          <Grid item>
            <KeyboardArrowDown {...{ onClick }} className={clsx("tc-arrow", classes.expand, classes.pointer, { "tc-expand": open })} />
          </Grid>
        )}
        <Grid item style={{ flexGrow: 1 }}>
          {title && (
            <Typography variant="subtitle2" {...{ onClick }} className={clsx(classes.title, cssTitle, { [classes.pointer]: isCollapse })}>
              {title}
            </Typography>
          )}
        </Grid>
        {header}
        {setEdit && open && (
          <Grid item>
            <Typography variant="subtitle2" onClick={() => handleToggle()}>
              <EditIcon />
            </Typography>
          </Grid>
        )}
      </Grid>
      {(isCollapse && (
        <Collapse in={open} timeout="auto">
          {children}
        </Collapse>
      )) || <>{children}</>}
    </Paper>
  );
};

CollapsibleBox.propTypes = {
  className: PropTypes.string,
  cssTitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  header: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  open: PropTypes.bool,
  name: PropTypes.string,
  onToggle: PropTypes.func,
  edit: PropTypes.shape({ setEdit: PropTypes.bool, handleToggle: PropTypes.func }),
  isCollapse: PropTypes.bool
};

CollapsibleBox.defaultProps = {
  className: undefined,
  cssTitle: undefined,
  title: undefined,
  name: undefined,
  header: undefined,
  edit: { setEdit: false, handleToggle: null },
  isCollapse: true,
  open: true,
  onToggle: () => {}
};

export const useCollapsibleBox = (editAllowed = false, withLoading = true, fnSuccess, relativeNoOverflow = false) => {
  const [editMode, setEditMode] = useState(false);
  const { actions, ...loading } = withLoading ? useLoading(false, true) : { actions: {} };
  const onToggleEdit = useCallback(() => setEditMode(s => !s), []);
  const onSuccessEdit = useCallback(() => {
    if (typeof actions.hideLoading === "function") actions.hideLoading();
    if (typeof fnSuccess === "function") fnSuccess();
    return setEditMode(false);
  }, [actions.hideLoading, fnSuccess]);
  const edit = editAllowed ? { setEdit: !editMode, handleToggle: onToggleEdit } : undefined;
  const boxProps = { className: withLoading || relativeNoOverflow ? "tc-relative tc-o-hidden" : undefined, edit };
  return { props: { ...loading, boxProps, editMode }, actions: { ...actions, onSuccessEdit, onToggleEdit } };
};

export default CollapsibleBox;
