export default {
  bodyPartId: undefined,
  claimantDateofDeath: undefined,
  hospitalId: undefined,
  stateId: undefined,
  injuryTypeId: undefined,
  parentBodyPartId: undefined,
  physicianName: undefined,
  treatmentDescription: undefined,
  treatmentReceived: false,
  treatmentTypeId: undefined
};
