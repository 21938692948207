import { VENDOR } from "../actions/actionTypes";
import initialState from "./initialState";

const { vendor } = initialState;

export default (state = vendor, action) => {
  switch (action.type) {
    case VENDOR.LOAD_SUCCESS:
    case VENDOR.SET_SUCCESS:
      return { ...state, profile: action.profile };
    case VENDOR.ADD_CODE_SUCCESS:
    case VENDOR.DELETE_CODE_SUCCESS:
      return { ...state, profile: { ...state.profile, industry: action.industry } };
    case VENDOR.LOAD_VENDORS_SUCCESS:
    case VENDOR.ADD_VENDOR_SUCCESS:
    case VENDOR.DEL_SUCCESS:
      return { ...state, vendors: action.vendors };
    case VENDOR.LOAD_PAYMENTS_SUCCESS:
      return { ...state, payments: action.payments };
    case VENDOR.GET_CLAIMSINVOLVED_SUCCESS:
      return { ...state, claimsInvolved: action.claimsInvolved };
    case VENDOR.GET_FINANCIALINFO_SUCCESS:
      return { ...state, financialInfo: action.financialInfo };
    case VENDOR.LOAD_FILES_SUCCESS:
      return { ...state, files: action.files };
    case VENDOR.UPLOAD_FILES_SUCCESS:
    case VENDOR.UPDATE_FILE_SUCCESS:
    case VENDOR.DELETE_FILE_SUCCESS:
      return { ...state, files: action.documents };
    case VENDOR.NOTES.GET_SUCCESS:
    case VENDOR.NOTES.POST_SUCCESS:
    case VENDOR.NOTES.SET_SUCCESS:
    case VENDOR.NOTES.DEL_SUCCESS:
      return { ...state, notes: action.notes };
    case VENDOR.NOTES.GET_DRAFT_SUCCESS:
    case VENDOR.NOTES.POST_DRAFT_SUCCESS:
      return { ...state, draftNote: action.draft };
    case VENDOR.LOAD_CONTACTS_SUCCESS:
    case VENDOR.POST_CONTACT_SUCCESS:
    case VENDOR.DEL_CONTACT_SUCCESS:
    case VENDOR.SET_CONTACT_SUCCESS:
      return { ...state, contacts: action.contacts };
    case VENDOR.BANKING.GET_SUCCESS:
    case VENDOR.BANKING.POST_SUCCESS:
    case VENDOR.BANKING.SET_SUCCESS:
    case VENDOR.BANKING.DEL_SUCCESS:
      return { ...state, accounts: action.accounts };
    case VENDOR.LOCATIONS.GET_SUCCESS:
    case VENDOR.LOCATIONS.POST_SUCCESS:
    case VENDOR.LOCATIONS.SET_SUCCESS:
    case VENDOR.LOCATIONS.DEL_SUCCESS:
      return { ...state, locations: action.locations };
    case VENDOR.LOCATIONS.LOCATION_GET_SUCCESS:
      return { ...state, location: action.location };
    case VENDOR.LOCATIONS.CLEAN_SUCCESS:
      return { ...state, location: vendor.location };
    case VENDOR.GET_PENDING_SUCCESS:
      return { ...state, pending: action.vendors };
    case VENDOR.SET_STATUS_SUCCESS:
    case VENDOR.APPROVE_SUCCESS: {
      const { basicInformation } = action;
      return { ...state, profile: { ...state.profile, basicInformation } };
    }
    case VENDOR.REJECT_SUCCESS:
    case VENDOR.CLEAN:
      return { ...state, profile: vendor.profile };
    default:
      return state;
  }
};
