import { CLIENTR as CLIENT } from "../actionTypes";

const get = (clientId, onSuccess, onError) => ({ type: CLIENT.SETTING.GET, clientId, onSuccess, onError });
const getSuccess = settings => ({ type: CLIENT.SETTING.GET_SUCCESS, settings });

// Client-Structure
const setClientStructure = (data, onSuccess) => ({ type: CLIENT.SETTING.CLIENT_STRUCTURE.SET, data, onSuccess });
const setClientStructureSucess = levels => ({ type: CLIENT.SETTING.CLIENT_STRUCTURE.SET_SUCCESS, levels });

// Client-Structure
const setReservePayment = (data, onSuccess, onError) => ({ type: CLIENT.SETTING.RESERVE_PAYMENT.SET, data, onSuccess, onError });
const setReservePaymentSucess = settings => ({ type: CLIENT.SETTING.RESERVE_PAYMENT.SET_SUCCESS, settings });

// Client-Structure
const setBillReview = (data, onSuccess, onError) => ({ type: CLIENT.SETTING.BILL_REVIEW.SET, data, onSuccess, onError });
const setBillReviewSucess = settings => ({ type: CLIENT.SETTING.BILL_REVIEW.SET_SUCCESS, settings });

const setTeam = (data, onSuccess, onError) => ({ type: CLIENT.SETTING.TEAM.SET, data, onSuccess, onError });
const setTeamSuccess = settings => ({ type: CLIENT.SETTING.TEAM.SET_SUCCESS, settings });

// Client-Structure
const addClientAndMigrateLevel = (data, onSuccess, onError) => ({ type: CLIENT.ADD.POST_AND_MIGRATE, data, onSuccess, onError });
const addClientAndMigrateLevelSuccess = clients => ({
  type: CLIENT.ADD.POST_AND_MIGRATE_SUCCESS,
  clients
});

export {
  setBillReview,
  setBillReviewSucess,
  get,
  getSuccess,
  setClientStructure,
  setClientStructureSucess,
  setReservePayment,
  setReservePaymentSucess,
  setTeam,
  setTeamSuccess,
  addClientAndMigrateLevel,
  addClientAndMigrateLevelSuccess
};
