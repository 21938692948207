import React, { memo } from "react";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import { FormControl, Input, InputLabel, FormHelperText } from "@material-ui/core";
import { HocInput } from "./HocInput";

const maskIdNumber = [/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

const IdNumber = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={maskIdNumber}
      guide={false}
    />
  );
};

IdNumber.propTypes = {
  inputRef: PropTypes.func.isRequired
};

const TaxIdNumber = memo(
  ({ classes, className, error, label, name, ...other }) => {
    const id = name || uuid();
    return (
      <FormControl fullWidth error={Boolean(error)} {...{ classes, className }}>
        <InputLabel htmlFor={id} shrink>
          {label}
        </InputLabel>
        <Input
          {...{
            ...other,
            name,
            id,
            inputComponent: IdNumber,
            placeholder: "  -       "
          }}
        />
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  },
  (prev, next) => prev.value === next.value
);

TaxIdNumber.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string
};

TaxIdNumber.defaultProps = {
  name: null,
  value: "",
  error: "",
  onChange: () => {}
};

export default HocInput(TaxIdNumber);
