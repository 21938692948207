import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as LinkIcon } from "@material-ui/icons";
import { Link, useRouteMatch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import { Grid } from "@material-ui/core";
import { MTableHeader } from "material-table";
import ClaimStatus from "../../claims/ClaimStatus";
import ClaimProcessingStatus from "../../claims/ClaimProcessingStatus";
import CollapsibleBox from "../CollapsibleBox";
import MaterialTable from "../table/MaterialTable";
import styles from "./Index.styles";
import Count from "../Count";
import { icon } from "../index.styles";
import { setExportTitleFileName } from "../../../common";

const BarChart = ({ reserves, type }) => {
  const classes = styles();
  const [open, setOpen] = useState(true);

  const storeOcurrence = useSelector(store => store.occurrence, shallowEqual);
  const {
    occurrence: { number: ocurrenceNumber }
  } = storeOcurrence || {};

  const storeCatastrophe = useSelector(store => store.catastrophe, shallowEqual);
  const {
    catastrophe: { number: catastropheNumber }
  } = storeCatastrophe || {};

  const onToggle = () => setOpen(!open);
  const { path } = useRouteMatch();
  const showGroping = path.indexOf("/catastrophe/") > -1;
  const classIcon = icon();
  const amountColumns = [
    {
      title: "Amount Reserved",
      field: "amountReserved",
      type: "currency"
    },
    { title: "Total Paid", field: "totalPaid", type: "currency" },
    { title: "Outstanding Reserve", field: "outstandingReserve", type: "currency" }
  ];
  const claimColumns = [
    {
      title: "Claim Number",
      width: 280,
      render: rowData => (
        <div style={{ display: "flex" }}>
          <span style={{ marginRight: 5 }}>
            <Link to={`/claim/${rowData.claimId}`}>{rowData.claimNumber}</Link>
          </span>
          <span style={{ marginRight: 5 }}>
            <ClaimStatus status={rowData.claimStatus} />
          </span>
          <ClaimProcessingStatus processingStatus={rowData.processingStatus} />
        </div>
      )
    },
    { title: "Claimant", field: "claimant" },

    { title: "Line of business", field: "claimLineOfBusiness" }
  ];
  const groupingColumn = { title: "Occurrence", field: "occurrenceName", width: 50, defaultGroupOrder: 1 };

  const occurrenceColumns = [
    {
      title: "Occurrence",
      render: rowData => {
        return <Link to={`/occurrence/${rowData.occurrenceId}`}>{rowData.occurrenceNumber}</Link>;
      }
    },
    { title: "Name", field: "name" },
    {
      title: "Claims Associated",
      field: "claimsAssociated",
      render: rowData => {
        return (
          <>
            <LinkIcon className={classIcon.link} />
            <Count>{rowData.claimsAssociated}</Count>
          </>
        );
      }
    }
  ];
  const typeColumns = [{ title: "Type", field: "type" }];
  const columnsTable = () => {
    if (type === "type") {
      return typeColumns.concat(amountColumns);
    }
    if (type === "claim") {
      return showGroping ? claimColumns.concat(amountColumns).concat(groupingColumn) : claimColumns.concat(amountColumns);
    }
    return occurrenceColumns.concat(amountColumns);
  };
  const propsT = showGroping ? { grouping: true, customGroupConfig: { check: false } } : {};

  const exportFileName = () => {
    if (!type) return setExportTitleFileName("Occurrence");

    const number = showGroping ? catastropheNumber : ocurrenceNumber;
    let title = setExportTitleFileName(`${number}_{type}`);
    switch (type) {
      case "claim":
        title = title.replace("{type}", "ReservesByClaim");
        break;
      case "occurrence":
        title = title.replace("{type}", "ReservesByOccurrence");
        break;

      default:
        title = title.replace("{type}", "ReservesBreakdownbyReserveType");
        break;
    }

    return title;
  };

  const tableProps = {
    columns: columnsTable(),
    data: reserves ? reserves.filter(x => x.amountReserved > 0) : [],
    options: {
      search: false,
      exportFileName: exportFileName()
    },
    componentsAttr: {
      Header: props => (
        <MTableHeader
          {...props}
          classes={{ header: (type === "claim" && classes.tableClaim) || (type === "occurrence" && classes.tableOcurrence) || classes.tableType }}
        />
      )
    },
    ...propsT
  };
  const labelX = () => {
    if (type === "claim") {
      return "claimNumber";
    }
    if (type === "occurrence") {
      return "occurrenceNumber";
    }
    return "type";
  };
  const data = {
    labels: reserves ? reserves.reduce((acc, item) => [...acc, item.amountReserved > 0 ? item[labelX()] : []], []) : [],
    datasets: [
      {
        label: "Reserved",
        backgroundColor: "#1599BC",
        hoverBackgroundColor: "#1599BC",
        borderColor: "#1599BC",
        borderWidth: 1,
        data: reserves ? reserves.reduce((acc, item) => [...acc, item.amountReserved > 0], []) : []
      },
      {
        label: "Paid",
        backgroundColor: "#CECECE",
        hoverBackgroundColor: "#CECECE",
        borderColor: "#CECECE",
        borderWidth: 1,
        data: reserves ? reserves.reduce((acc, item) => [...acc, item.totalPaid], []) : []
      }
    ]
  };
  const title = () => {
    if (type === "claim") {
      return "Reserves by Claims";
    }
    if (type === "occurrence") {
      return "Reserves by Occurrence";
    }
    return "Reserves Breakdown by Reserve Type";
  };
  const name = () => {
    if (type === "claim") {
      return "reserveByClaims";
    }
    if (type === "occurrence") {
      return "reserveByOccurrence";
    }
    return "reserveByType";
  };
  return (
    <CollapsibleBox title={title()} name={name()} {...{ open, onToggle }}>
      <Grid container spacing={5}>
        <Grid item xs={7}>
          <MaterialTable {...tableProps} />
        </Grid>
        <Grid item xs={5}>
          <div className={classes.bar}>
            <Bar
              data={data}
              width={100}
              height={250}
              options={{
                maintainAspectRatio: false
              }}
            />
          </div>
        </Grid>
      </Grid>
    </CollapsibleBox>
  );
};
BarChart.propTypes = {
  reserves: PropTypes.arrayOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired
};

export default BarChart;
