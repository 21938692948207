import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import { Grid } from "@material-ui/core";
import LabelValue from "../../LabelValue";
import MultipleLabels from "../../Labels";

const ClaimViewMode = ({ claim, isReportOnly }) => {
  const processingStatusList = useSelector(({ common: { processingStatus: pStatus } }) => pStatus, shallowEqual);
  const {
    number,
    dateReported,
    statusDate,
    dateEntry,
    processingStatusDate,
    dateofLoss,
    description,
    claimLineOfBusiness,
    claimLossType,
    claimLossTypeGroup,
    claimCauseCode,
    status,
    processingStatus,
    processingStatusId,
    deniedDescription
  } = claim || {};

  const isDenied = processingStatusList.find(x => x.id === processingStatusId)?.text === "Denied";
  return (
    <React.Fragment key="claimedit">
      <form style={{ padding: "10px" }}>
        {!isReportOnly && (
          <Grid container spacing={4}>
            <Grid item xs>
              <MultipleLabels
                data={[
                  { label: "Claim #", value: number, key: "claim_number" },
                  { label: "Line Of Business", value: claimLineOfBusiness, key: "claim_line_of_business" },
                  { label: "Date Reported", value: dateReported, isDate: true, key: "claim_date_reported" }
                ]}
              />
            </Grid>
            <Grid item xs>
              <MultipleLabels
                data={[
                  { label: "Claim Status", value: status, key: "claim_status" },
                  { label: "Claim Status Date", value: statusDate, isDate: true, key: "claim_status_date" },
                  { label: "Claim Entry", value: dateEntry, isDate: true, key: "claim_entry" }
                ]}
              />
            </Grid>
            <Grid item xs>
              <MultipleLabels
                data={[
                  { label: "Processing Status", value: processingStatus, key: "claim_proccessing_status" },
                  { label: "Processing Status Date", value: processingStatusDate, isDate: true, key: "claim_status_date" }
                ]}
              />
              {isDenied === true && <LabelValue label="Claim Denied Description" value={deniedDescription || ""} />}
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <LabelValue label="Date of Loss" value={dateofLoss} isDate />
            <Grid container>
              <Grid item>
                <LabelValue label="Time Of Loss" value={dateofLoss} isDate formatHH />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <LabelValue label="Address" value={claim.location} isAddress />
          </Grid>
          <Grid item xs={3}>
            <MultipleLabels
              data={[
                { label: "Loss Type Group", value: claimLossTypeGroup, key: "claim_type_group" },
                { label: "Loss Type", value: claimLossType, key: "claim_loss_type" },
                { label: "Cause Code", value: claimCauseCode, key: "claim_couse_code" }
              ]}
            />
          </Grid>
        </Grid>
        <LabelValue label="Loss Description" value={description} />
      </form>
    </React.Fragment>
  );
};

ClaimViewMode.propTypes = {
  claim: PropTypes.objectOf(PropTypes.any).isRequired,
  isReportOnly: PropTypes.bool
};

ClaimViewMode.defaultProps = {
  isReportOnly: false
};

export default ClaimViewMode;
