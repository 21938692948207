import { useEffect, useRef, useCallback } from "react";

export default (fn, params = []) => {
  const ref = useRef(false);
  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);
  const isMounted = useCallback(() => ref.current, []);
  return useEffect(() => () => !isMounted() && fn(), [...params, isMounted]);
};
