import React, { Fragment, useState, useEffect, useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import CurrentReserve from "./CurrentReserve";
import ReserveHistory from "./ReserveHistory";
import ReplaceReserve from "./ReplaceReserve";
import { get } from "../../../../redux/actions/claim/reserves";
import { useRouteParam } from "../../../../common";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import { useLoadingContext } from "../../../common/Loading";

export default () => {
  const claimId = useRouteParam("claimId");
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(true);
  const routesAllowed = useContext(ActionsAllowedContext);
  const [access] = useState(routesAllowed.find(elem => elem.route === "reserves"));
  const {
    actions: { showLoading, hideLoading }
  } = useLoadingContext();
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  const { isAsig: isAsignedToClaim } = useSelector(
    ({
      claim: {
        claim: { supervisorId: supId, adjusterId: adjId },
        filterfields: { supervisorId: ffsupId, adjusterId: ffadjId }
      },
      profile: p
    }) => ({
      isAsig: (supId ?? ffsupId) === p?.id || (adjId ?? ffadjId) === p?.id
    }),
    shallowEqual
  );
  const onToggle = () => setCurrent(ps => !ps);
  const actionsAllowed = { ...access, update: access.update && isAsignedToClaim };
  return (
    <Fragment key="fragment-claim-reserves">
      {(current && <CurrentReserve access={actionsAllowed} onToggle={onToggle} />) || <ReplaceReserve {...{ onToggle, access: actionsAllowed }} />}
      <ReserveHistory />
    </Fragment>
  );
};
