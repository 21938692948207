import { CLAIM, API } from "../../../actions/actionTypes";
import * as actions from "../../../actions/claim/info/employment";
import { takeLatest } from "../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLAIM.INFO.EMPLOYMENT.GET,
    actionSuccess: actions.loadByClaimIdSuccess,
    params: ({ id }) => ({ id }),
    errorMessage: "Error loading employment",
    url: `${API.URL}/employment`
  }),
  takeLatest({
    type: CLAIM.INFO.EMPLOYMENT.SET,
    method: ({ employment: { id } }) => (id ? "PUT" : "POST"),
    actionSuccess: actions.setSuccess,
    url: `${API.URL}/employment`,
    params: ({ employment }) => ({ ...employment }),
    successMessage: "Employment information successfully saved",
    errorMessage: "Unable to save the information"
  })
];
