import { OCCURRENCE, CLAIM, DIARY } from "./actionTypes";

export const get = ({ id, entityType, onSuccess, onError }) => ({ type: DIARY.GET, id, entityType, onSuccess, onError });
export const getClaimDiariesSuccess = diaries => ({ type: CLAIM.DIARY.GET_SUCCESS, diaries });
export const getOccurrenceDiariesSuccess = diaries => ({ type: OCCURRENCE.DIARY.GET_SUCCESS, diaries });

export const set = ({ diary, entityType, onSuccess, onError }) => ({ type: DIARY.SET, diary, entityType, onSuccess, onError });
export const setClaimDiarySuccess = diaries => ({ type: CLAIM.DIARY.SET_SUCCESS, diaries });
export const setOccurrenceDiarySuccess = diaries => ({ type: OCCURRENCE.DIARY.SET_SUCCESS, diaries });

export const post = ({ diary, entityType, onSuccess, onError }) => ({ type: DIARY.POST, diary, entityType, onSuccess, onError });
export const postClaimDiarySuccess = diaries => ({ type: CLAIM.DIARY.POST_SUCCESS, diaries });
export const postOccurrenceDiarySuccess = diaries => ({ type: OCCURRENCE.DIARY.POST_SUCCESS, diaries });

export const del = ({ diary, entityType, onSuccess, onError }) => ({ type: DIARY.DEL, diary, entityType, onSuccess, onError });
export const delClaimDiarySuccess = diaries => ({ type: CLAIM.DIARY.DEL_SUCCESS, diaries });
export const delOccurrenceDiarySuccess = diaries => ({ type: OCCURRENCE.DIARY.DEL_SUCCESS, diaries });
