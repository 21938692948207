import { ADMIN } from "../actionTypes";

export const reset = () => ({ type: ADMIN.SECURITYPROFILE.RESET });

export const get = (id, onSuccess, onError) => ({ type: ADMIN.SECURITYPROFILE.GET, id, onSuccess, onError });
export const getSuccess = securityProfile => ({ type: ADMIN.SECURITYPROFILE.GET_SUCCESS, securityProfile });

export const set = (data, onSuccess, onError) => ({ type: ADMIN.SECURITYPROFILE.SET, data, onSuccess, onError });
export const setSuccess = () => ({ type: ADMIN.SECURITYPROFILE.GET_SUCCESS });

export const post = (data, onSuccess, onError) => ({ type: ADMIN.SECURITYPROFILE.POST, data, onSuccess, onError });
export const postSuccess = () => ({ type: ADMIN.SECURITYPROFILE.POST_SUCCESS });

export const getAll = (onSuccess, onError) => ({ type: ADMIN.SECURITYPROFILE.GETALL, onSuccess, onError });
export const getAllSuccess = securityProfiles => ({ type: ADMIN.SECURITYPROFILE.GETALL_SUCCESS, securityProfiles });

export const deleteSecurityProfile = (id, onSuccess, onError) => ({ type: ADMIN.SECURITYPROFILE.DEL, id, onSuccess, onError });
export const deleteSecurityProfileSuccess = securityProfiles => ({ type: ADMIN.SECURITYPROFILE.DEL_SUCCESS, securityProfiles });
