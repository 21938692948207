import React, { useState } from "react";
import { Paper, Grid, Button, Menu, MenuItem, Typography } from "@material-ui/core";
import { useContext, actionTypes } from "../context";
import Count from "../../../common/Count";
import { useAddClaimantStyles } from "./index.styles";

export default () => {
  const classes = useAddClaimantStyles();
  const [{ claimants }, dispatch] = useContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const onAdd = (payload = {}) => dispatch({ type: actionTypes.ADD_CLAIMANT, payload });
  const onClick = event => (claimants.some(x => x.sameAsInsured) ? onAdd() : setAnchorEl(event.currentTarget));
  const onClose = () => setAnchorEl(null);
  const onOption = payload => () => {
    onAdd(payload);
    onClose();
  };
  return (
    <Paper className={classes.root}>
      <Grid container alignItems="center" spacing={2} wrap="nowrap">
        <Grid item xs>
          <Typography className="tc-title">Claimants</Typography>
        </Grid>
        <Grid item>
          Number of Claims to be created
          <Count>{claimants.length}</Count>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" {...{ onClick }}>
            Add Claimant
          </Button>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} {...{ onClose }}>
            <MenuItem onClick={onOption({ sameAsInsured: true })}>Same as Insured</MenuItem>
            <MenuItem onClick={onOption()}>New Claimant</MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Paper>
  );
};
