import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import IndexFile from "./indexFile/index";
import List from "./list/index";
import { useLoading } from "../../common/Loading";
import { deleteFile, getAll, uploadFiles, openFile, post } from "../../../redux/actions/documents";

const Indexing = ({ statusId, access, exportFileName }) => {
  const isReindex = statusId === 4;
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const {
    loading,
    actions: { showLoading, hideLoading },
    Loading
  } = useLoading(true);
  const callbackDispatch = (action, del, onSuccess) => (params, data) => {
    showLoading();
    const onComplete = {
      onSuccess: (...success) => {
        if (typeof onSuccess === "function") onSuccess(...success);
        return hideLoading();
      },
      onError: hideLoading
    };
    if (del) return dispatch(action(params, data, onComplete));
    return dispatch(action({ ...params, statusId, ...onComplete }));
  };
  const onCancel = useCallback(() => setState(ps => ({ ...ps, indexItem: undefined })), []);
  const actions = {
    showLoading,
    hideLoading,
    onIndex: useCallback(indexItem => setState(ps => ({ ...ps, indexItem })), []),
    onCancel,
    openFile: useCallback(callbackDispatch(openFile), []),
    deleteFile: useCallback(callbackDispatch(deleteFile, true), []),
    save: useCallback(callbackDispatch(post, false, onCancel), []),
    ...(isReindex ? {} : { uploadFiles: useCallback(callbackDispatch(uploadFiles), []) })
  };
  const { indexItem } = state;
  useEffect(() => {
    if (access?.read) {
      actions.showLoading();
      dispatch(getAll(statusId, actions.hideLoading));
    }
  }, []);
  return (
    <>
      {Loading}
      {(indexItem && <IndexFile {...{ actions, indexItem, loading }} />) || <List {...{ actions, isReindex, access, exportFileName }} />}
    </>
  );
};

Indexing.propTypes = {
  statusId: PropTypes.oneOf([1, 4]).isRequired,
  exportFileName: PropTypes.string,
  access: PropTypes.shape({}).isRequired
};

Indexing.defaultProps = {
  exportFileName: ""
};

export default Indexing;
