import { NOTIFICATION } from "../actions/actionTypes";
import initialState from "./initialState";

export default function notificationReducer(state = initialState.notification, action) {
  switch (action.type) {
    case NOTIFICATION.GET_SUCCESS:
    case NOTIFICATION.SET_READ_SUCCESS:
      return { ...state, notifications: action.notifications };
    default:
      return state;
  }
}
