import * as yup from "yup";

const number = () => yup.number().nullable();
const string = () => yup.string().nullable();

const testNote = (name, type, msg) =>
  type()
    .nullable()
    .test("default", msg, (val, ctx) => {
      const some = ["typeId", "title", "note", "file"].some(p => p !== name && Boolean(ctx.parent[p]));
      return !some || Boolean(val);
    });

export const indexSchema = yup.object().shape({
  folderId: number().required("Folder Required"),
  note: yup.object().shape({
    typeId: testNote("typeId", number, "Type Required"),
    title: testNote("title", string, "Title Required"),
    note: testNote("title", string, "Description Required")
  })
});

export const returnSchema = yup.object().shape({
  reindexTypeId: number().required("Reason Required")
});
