import React, { useState } from "react";
import InitialLoading, { useInitialLoading } from "../layout/InitialLoading";
import { childrenPropType } from "../../common";
import AppContext from "./contexts/Container";

const AppContextProvider = ({ children }) => {
  const [notFoundActive, setNotFoundActive] = useState(false);
  const exceptedRoutesForCheck = ["myinfo", "reports", "intelligence", "terramaps", "dashboard"];
  const { userStatus, onRetry } = useInitialLoading();
  return (
    <AppContext.Provider value={{ exceptedRoutesForCheck, setNotFoundActive, notFoundActive }}>
      {(userStatus.dbActive && children) || <InitialLoading {...{ userStatus, onRetry }} />}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = { children: childrenPropType().isRequired };

export default AppContextProvider;
