import React, { useState, Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import { Route, Link, useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { CheckCircleOutline, HighlightOff } from "@material-ui/icons";
import { Button, Paper, AppBar, Toolbar, Typography, Grid } from "@material-ui/core";
import clsx from "clsx";
import DeleteIcon from "@material-ui/icons/Delete";
import MaterialTable from "../../../common/table/MaterialTable";
import styles from "./index.style";
import { add, getByParentId, del, changeEntitysParent } from "../../../../redux/actions/client/list";
import { useRouteParam, getUserAccess, setExportTitleFileName } from "../../../../common";
import useCustomConfirm from "../../../common/customHooks/useCustomConfirm";
import ClientAdd from "../../client_add";
import { useLoading, useLoadingContext } from "../../../common/Loading";
import CustomModal from "../../../common/CustomModal";
import { SelectInput } from "../../../common/inputs";

const List = ({ level, nextLevel, mainUrl, levelIndex, nextLevelName, nextLevelId, routesAllowed }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clientId = useRouteParam(level.concat("Id"));
  const { url, isExact } = useRouteMatch();
  const [access, setAccess] = useState({ read: false, create: false, update: false });
  const [selection, setSelection] = useState([]);
  const [newParentName, setNewParentName] = useState();
  const store = useSelector(
    ({
      client: {
        entityList,
        profile: { basicInformation },
        breadCrumbRoutes
      },
      profile: { securityProfilesDetails }
    }) => ({ entityList, basicInformation, securityProfilesDetails, breadCrumbRoutes }),
    shallowEqual
  );
  const { entityList, securityProfilesDetails, basicInformation, breadCrumbRoutes } = store;
  const [newParent, setNewParent] = useState(basicInformation.id);

  const loadingCtx = useLoadingContext();
  const loadingHook = !loadingCtx ? useLoading(null, true) : undefined;
  const {
    Loading,
    loading,
    actions: { showLoading, hideLoading }
  } = loadingCtx ? { ...loadingCtx, Loading: null } : loadingHook;
  const [openDialogChangeParent, setOpenDialogChangeParent] = React.useState(false);
  const handleOpenModalChangeParent = () => {
    setOpenDialogChangeParent(prev => !prev);
  };
  const sameLevelClientIdLabel = `Select ${basicInformation.levelName}`;
  const sameLevelClientClients = breadCrumbRoutes
    .filter(elem => elem.levelId === basicInformation.levelId && elem.id !== basicInformation.id)
    .map(({ id, name }) => ({ id, text: name }));

  const handleSameLevelClientsChange = event => {
    setNewParent(event.target.value);
    setNewParentName(breadCrumbRoutes.find(elem => elem.levelId === basicInformation.levelId && elem.id === event.target.value)?.name);
  };
  const moveEntityToNewParentSuccess = () => {
    hideLoading();
    setOpenDialogChangeParent(false);
    toast.success(`${nextLevelName} successfully moved to ${newParentName}.`);
  };
  const moveEntityToNewParentError = () => {
    hideLoading();
    setOpenDialogChangeParent(false);
    toast.success(`Error ${nextLevelName} not changed.`);
  };
  const moveEntityToNewParent = () => {
    const selectedEntities = selection.map(({ id }) => id);
    dispatch(
      changeEntitysParent(
        { parentId: basicInformation.id, newParentId: newParent, entities: selectedEntities },
        moveEntityToNewParentSuccess,
        moveEntityToNewParentError
      )
    );
  };

  useEffect(() => {
    if (isExact) {
      showLoading();
      dispatch(getByParentId(clientId !== undefined ? clientId : null, hideLoading, hideLoading));
      if (routesAllowed.length === 0) {
        if (securityProfilesDetails?.length > 0) {
          setAccess(getUserAccess(securityProfilesDetails, "CLIENT.LIST", null, null, null));
        }
      } else {
        setAccess(routesAllowed.find(elem => elem.routeName === "list"));
      }
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const classes = styles();
  const getStatusIcon = isActive => {
    const div = (css, icon) => <div className={clsx(css)}>{icon}</div>;
    switch (isActive) {
      case true:
        return div(classes.active, <CheckCircleOutline />);
      case false:
        return div(classes.inactive, <HighlightOff />);
      default:
        return null;
    }
  };

  const baseUrl = mainUrl && nextLevel ? `${mainUrl}/` : "client";

  const handleDelete = ({ id }) => {
    const client = entityList.filter(e => e.id === id)[0];
    if (client)
      dispatch(
        del({
          client,
          onSuccess: () => toast.success(`${nextLevel || "Client"} successfully deleted.`),
          onError: () =>
            toast.error(`Unable to remove the ${nextLevel || "client"}. Client can't be deleted because it's associated to other entities`) // errorMessage: "Client can't be deleted because it's associated to other entities"
        })
      );
  };

  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleDelete, "Cancel", "Yes, Delete");

  const getNextTab = () => {
    if (baseUrl !== "client") {
      if (routesAllowed.find(elem => elem.routeName === "profile")?.read) return "profile";
      return routesAllowed.find(elem => elem.read === true)?.routeName;
    }
    if (getUserAccess(securityProfilesDetails, "CLIENT.OVERVIEW", null, null, null).read) return "overview";
    if (getUserAccess(securityProfilesDetails, "CLIENT.PROFILE", null, null, null).read) return "profile";
    if (getUserAccess(securityProfilesDetails, "CLIENT.LIST", null, null, null).read) return "list";
    if (getUserAccess(securityProfilesDetails, "CLIENT.SETTINGS", null, null, null).read) return "settings";
    return "profile";
  };

  const nextTab = getNextTab();

  const getButtonToolBar = () => {
    if (!access.create) return null;
    return (
      <Grid item className="tc-pl1">
        <Button variant="contained" color="primary" onClick={() => history.replace(mainUrl ? `${url}/add` : "/client")}>
          {`Add ${nextLevelName || "Client"}`}
        </Button>
      </Grid>
    );
  };
  const getButtonToolBarPrevoiusSearch = () => {
    if (!access.create) return null;
    if (levelIndex < 1) return null;
    return (
      <Grid item className="tc-pl1">
        <Button type="link" color="primary" onClick={handleOpenModalChangeParent} className={classes.changeParentBUtton}>
          {`Move selected ${nextLevelName} to another ${basicInformation.levelName}`}
        </Button>
      </Grid>
    );
  };

  const exportFileName = () => {
    if (levelIndex > -1) return setExportTitleFileName(`${basicInformation?.name}_${nextLevelName}_List`);

    return setExportTitleFileName("ClientsList");
  };

  const tableProps = {
    options: {
      selection: levelIndex >= 1,
      exportFileName: exportFileName(),
      filtering: true,
      pageSize: 50
    },
    columns: [
      {
        title: "Number",
        field: "number",
        render: rowData =>
          nextLevel !== "area" ? (
            <Link to={`${baseUrl}${nextLevel ? rowData.levelRouteName : ""}/${rowData.id}/${nextTab}`}>{rowData.number}</Link>
          ) : (
            <div>{rowData.number}</div>
          )
      },
      {
        title: "Name",
        field: "name",
        render: rowData => (
          <div style={{ display: "flex" }}>
            {nextLevel !== "area" ? (
              <Link to={`${baseUrl}${nextLevel ? rowData.levelRouteName : ""}/${rowData.id}/${nextTab}`}>{rowData.name}</Link>
            ) : (
              <div>{rowData.name}</div>
            )}
            {getStatusIcon(rowData.isActive)}
          </div>
        )
      },
      { title: "Type", field: "type" },
      { title: "Size", field: "sizeTypeName" }
    ],
    data: loading ? [] : entityList,
    paginationToolBar: true,
    hidePaging: entityList ? entityList.length <= 50 : false,
    actions: [
      ...(access.delete
        ? [
            {
              icon: p => <DeleteIcon {...p} className={classes.icons} />,
              tooltip: "Delete Insured",
              position: "row",
              onClick: (_event, rowData) => {
                handleCustomConfirm({
                  oData: { id: rowData.id },
                  titleText: `Delete ${nextLevelName || "Client"}`,
                  messageText: (
                    <>
                      <p>{`The following ${nextLevelName || "client"} is going to be deleted:`}</p>
                      <p>
                        <b>{rowData.name}</b>
                      </p>
                      <p>Are you sure you want to proceed?</p>
                    </>
                  )
                });
              }
            }
          ]
        : [])
    ],
    ButtonToolBar: getButtonToolBar,
    ButtonToolBarPreviousSearch: nextLevelName && access.create && levelIndex > 0 ? getButtonToolBarPrevoiusSearch : null
  };
  const config = { position: "static", color: "inherit" };

  const showAppBar = levelIndex === undefined;

  return (
    <Fragment key="clientListWrapper">
      {isExact && (
        <div className={clsx({ "tc-page-container": showAppBar })}>
          {showAppBar && (
            <AppBar {...config} className={classes.appNavBarHeader}>
              <Toolbar>
                <Typography variant="h6">Client List</Typography>
              </Toolbar>
            </AppBar>
          )}
          <div className={clsx({ "tc-page-content": showAppBar })}>
            {Loading}
            <Paper className={classes.clientListPaper}>
              <MaterialTable
                {...tableProps}
                onSelectionChange={newSelection => {
                  setSelection(newSelection);
                }}
              />
              <Confirm />
            </Paper>
          </div>
        </div>
      )}
      <Route
        path={`${url}/add`}
        component={() => (
          <ClientAdd
            showHeaderTitle={false}
            actionSave={add}
            parentId={clientId}
            nextLevel={nextLevelName}
            levelIndex={levelIndex}
            nextLevelId={nextLevelId}
            level={level}
            parentUrl={url}
            clientLevelAdd
          />
        )}
      />
      <CustomModal
        {...{
          openModal: openDialogChangeParent,
          title: `Move selected ${nextLevelName} to another ${basicInformation.levelName}`,
          onToggle: handleOpenModalChangeParent,
          onAction: moveEntityToNewParent,
          actionBtnText: "Move",
          maxWidth: "sm",
          classes
        }}
      >
        <div style={{ margin: "0 16px" }}>
          <SelectInput
            name="parentId"
            value={newParent}
            label={sameLevelClientIdLabel}
            options={sameLevelClientClients}
            onChange={handleSameLevelClientsChange}
          />
        </div>
      </CustomModal>
    </Fragment>
  );
};

List.propTypes = {
  level: PropTypes.string,
  nextLevel: PropTypes.string,
  mainUrl: PropTypes.string,
  levelIndex: PropTypes.number,
  nextLevelName: PropTypes.string,
  nextLevelId: PropTypes.number,
  routesAllowed: PropTypes.arrayOf(PropTypes.any)
};

List.defaultProps = {
  level: "client",
  nextLevel: null,
  mainUrl: null,
  nextLevelName: null,
  routesAllowed: [],
  levelIndex: undefined,
  nextLevelId: undefined
};

export default List;
