export default {
  initialReports: [],
  claim: {},
  activity: [],
  information: {
    insuredVehicle: { vehicle: {}, driver: { location: {} } },
    damagedVehicle: { vehicle: {}, driver: { location: {} }, vehicleOwner: { location: {} }, lienHolder: { location: {} } },
    claimant: {},
    reportOfLoss: {},
    witnesses: [],
    insured: { location: {}, contact: {} },
    propertyDamaged: {},
    bodilyInjury: {}
  },
  filterfields: {}
};
