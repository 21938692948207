import * as yup from "yup";
import moment from "moment";

export const isEmail = email => {
  try {
    yup
      .string()
      .nullable()
      .email()
      .required()
      .validateSync(email);
    return true;
  } catch (_e) {
    return false;
  }
};

export const dateSchema = (message = "", fnValidate) =>
  yup
    .string()
    .nullable()
    .test("default", message, (val, ctx) => {
      if (message && !(typeof fnValidate === "function" ? fnValidate : v => !!v)(val, ctx)) return false;
      try {
        if (moment("1900-01-01").isAfter(moment(val)) || moment("2100-01-01").isBefore(moment(val)))
          return ctx.createError({ message: "Date out of range" });
      } catch (_e) {
        /* ignored */
      }
      return true;
    });

export const isDate = date => {
  try {
    dateSchema("Invalid Date").validateSync(date);
    return true;
  } catch (_e) {
    return false;
  }
};

export const beforeTodaySchema = (message = "", fnValidate) => {
  return yup
    .string()
    .nullable()
    .test("default", message, (val, ctx) => {
      if (message && !(typeof fnValidate === "function" ? fnValidate : v => Boolean(v))(val, ctx)) return false;
      try {
        if (moment("1900-01-01").isAfter(moment(val)) || moment("2100-01-01").isBefore(moment(val)))
          return ctx.createError({ message: "Date out of range" });
        if (moment(val).isAfter(moment(new Date()))) return ctx.createError({ message: "Date greater than today" });
      } catch (_e) {
        /* ignored */
      }
      return true;
    });
};

export const isBeforeToday = date => {
  try {
    beforeTodaySchema().validateSync(date);
    return true;
  } catch (_e) {
    return false;
  }
};

export const afterDateSchema = (minDate, message = "") => {
  return yup
    .string()
    .nullable()
    .test("dateAfterDate", message, (val, ctx) => {
      try {
        const myDate = moment(new Date(val));
        const myMinDate = moment(new Date(minDate));
        if (myDate.isBefore(myMinDate)) {
          return ctx.createError({ message: "Date greater than date" });
        }
      } catch (_e) {
        /* ignored */
      }
      return true;
    });
};

export const isAfterDate = (date, minDate) => {
  try {
    if (minDate) afterDateSchema(minDate).validateSync(date);
    return true;
  } catch (_e) {
    return false;
  }
};

export const beforeDateSchema = (maxDate, message = "") => {
  return yup
    .string()
    .nullable()
    .test("dateBeforeDate", message, (val, ctx) => {
      try {
        const myDate = moment(new Date(val));
        const myMaxDate = moment(new Date(maxDate));
        if (myDate.isAfter(myMaxDate)) {
          return ctx.createError({ message: "Date previuows than date" });
        }
      } catch (_e) {
        /* ignored */
      }
      return true;
    });
};

export const isBeforeDate = (date, maxDate) => {
  try {
    if (maxDate) beforeDateSchema(maxDate).validateSync(date);
    return true;
  } catch (_e) {
    return false;
  }
};

export const isNumber = number => {
  try {
    yup
      .number()
      .required()
      .validateSync(number);
    return true;
  } catch (_e) {
    return false;
  }
};

export const rootParent = context => {
  if (Array.isArray(context?.from) && context.from.length > 0) return context.from[context.from.length - 1].value || {};
  return {};
};
