import { Grid, makeStyles, Paper, Avatar } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { memo } from "react";
import Confirm from "../Confirm";
import useConfirm from "../customHooks/useConfirm";
import LabelValue from "../LabelValue";

const useStyles = makeStyles(theme => ({
  rootContainer: { padding: "1rem 1.5rem", marginBottom: theme.spacing(2) },
  avatar: { width: "2rem", height: "2rem" }
}));

const Account = memo(({ data, index, onEdit, onRemove, actionsAllowed }) => {
  const { confirmConfig, handle } = useConfirm(
    onRemove,
    "Delete Bank Account",
    <>
      <p>The Bank Account will be deleted.</p>
      <p>Are you sure you want to proceed?</p>
    </>,
    "Cancel",
    "Yes, Delete"
  );
  const classes = useStyles();
  return (
    <Paper className={classes.rootContainer}>
      <Grid container justify="flex-end">
        <Grid item>
          {!!actionsAllowed.update && <Edit onClick={() => onEdit(data)} />}
          {!!actionsAllowed.delete && <Delete onClick={() => handle(data)} />}
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item>
          <Avatar className={classes.avatar}>{index}</Avatar>
        </Grid>
        <Grid item xs>
          <LabelValue label="Account Name" value={data.accountName} />
          <LabelValue label="Bank Name" value={data.bankName} />
          <LabelValue label="Starting Check Number" value={data.startingCheckNumber} />
        </Grid>
        <Grid item xs>
          <LabelValue label="Account Number" value={data.accountNumber} />
          <LabelValue label="Bank Location" value={data.bankAddress} />
          {data.vendorName && <LabelValue label="Location" value={data.vendorName} />}
        </Grid>
        <Grid item xs>
          <LabelValue label="Account Type" value={data.accountType} />
          <LabelValue label="Routing Number" value={data.routingNumber} />
        </Grid>
        <Grid item xs>
          <LabelValue label="Account Status" value={data.accountStatus ? "Active" : "Inactive"} />
          <LabelValue label="Fraction ABA Number" value={data.fractionABANumber} />
        </Grid>
      </Grid>
      <Confirm {...confirmConfig} />
    </Paper>
  );
});

Account.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  index: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  actionsAllowed: PropTypes.objectOf(PropTypes.any)
};
Account.defaultProps = { data: {}, actionsAllowed: { delete: false, update: false } };

export default Account;
