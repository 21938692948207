import { CLIENTR as CLIENT } from "../../actions/actionTypes";
import actions from "../../actions/client/clientActions";
import profile from "./common/profile";

export default [
  profile({ type: CLIENT.PROFILE.GET, actionSuccess: actions.getProfileSuccess }).profile.get,
  profile({ type: CLIENT.INSURED.PROFILE.GET, actionSuccess: actions.getInsuredProfileSuccess }).profile.get,
  profile({ type: CLIENT.PROFILE.BASIC.SET, actionSuccess: actions.updateBasicSuccess }).profile.set,
  profile({
    type: CLIENT.PROFILE.AGENT.SET,
    actionSuccess: actions.setAgentSuccess,
    setSuccessMessage: "Agent information successfully saved.",
    setErrorMessage: "Unable to save the information.",
    url: "setAgent"
  }).profile.set,
  profile({ type: CLIENT.PROFILE.STATUS.SET, actionSuccess: actions.updateStatusSuccess }).profile.status.set,
  profile({ type: CLIENT.PROFILE.CODE_PROFILE.POST, actionSuccess: actions.addCodeSuccess }).profile.code.post,
  profile({ type: CLIENT.PROFILE.CODE_PROFILE.DEL, actionSuccess: actions.removeCodeSuccess }).profile.code.del,
  profile({ type: CLIENT.PROFILE.AREA.POST, actionSuccess: actions.addAreaSuccess }).profile.area.post,
  profile({ type: CLIENT.PROFILE.AREA.SET, actionSuccess: actions.setAgentSuccess }).profile.area.set,
  profile({ type: CLIENT.PROFILE.AREA.DEL, actionSuccess: actions.deleteAreaSuccess }).profile.area.del,
  profile({ type: CLIENT.PROFILE.OPERATING_STATE.POST, actionSuccess: actions.addOperatingStateSuccess }).profile.operating.post,
  profile({ type: CLIENT.PROFILE.OPERATING_STATE.SET, actionSuccess: actions.setOperatingStateSuccess }).profile.operating.set,
  profile({ type: CLIENT.PROFILE.OPERATING_STATE.DEL, actionSuccess: actions.deleteOperatingStateSuccess }).profile.operating.del
];
