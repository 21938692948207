import { CLAIM, API } from "../../../actions/actionTypes";
import { objectToFormData } from "../../../../api/apiUtils";
import {
  getSuccess,
  getAllSuccess,
  setSuccess,
  postSuccess,
  getOverviewSuccess,
  setUserSuccess,
  getSecProfFilterSuccess
} from "../../../actions/claim";
import { takeLatest, takeLatestSet, takeLatestPost } from "../../common/sagaGenerator";

const url = `${API.URL}/claim`;

export default [
  takeLatest({
    type: CLAIM.GET,
    actionSuccess: getSuccess,
    params: ({ id }) => id,
    errorMessage: "Error getting claim.",
    url: ({ id }) => `${url}/${id}`
  }),
  takeLatest({
    type: CLAIM.GETALL,
    actionSuccess: getAllSuccess,
    errorMessage: "Error getting claims",
    url
  }),
  takeLatestPost({
    type: CLAIM.POST,
    params: ({ data }) => objectToFormData(data),
    url,
    actionSuccess: postSuccess
  }),
  takeLatestSet({
    type: CLAIM.SET,
    actionSuccess: setSuccess,
    params: ({ claim }) => claim,
    successMessage: "Claim information successfully saved",
    errorMessage: "Unable to save the information",
    url
  }),
  takeLatest({
    type: CLAIM.OVERVIEW.GET,
    actionSuccess: getOverviewSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading Claim data",
    url: `${url}/overview`
  }),
  takeLatestSet({
    type: CLAIM.USER.SET,
    actionSuccess: setUserSuccess,
    params: ({ claim }) => claim,
    url: `${url}/setuser`
  }),
  takeLatest({
    type: CLAIM.GET_SECPROF_FILTER,
    actionSuccess: getSecProfFilterSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading Filters Fields",
    url: `${url}/filterfields`
  })
];
