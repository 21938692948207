import { CLIENTR as CLIENT } from "../../../actionTypes";

const generic = type => props => ({ type, ...props });
const success = type => vehiclesInsured => ({ type, vehiclesInsured });

export const loadVehicles = policyId => ({ type: CLIENT.INSURED.POLICY.VEHICLE.GET_VEHICLES, policyId });

export const loadVehiclesSet = generic(CLIENT.INSURED.POLICY.VEHICLE.GET_VEHICLES);
export const loadVehiclesSuccess = success(CLIENT.INSURED.POLICY.VEHICLE.GET_VEHICLES_SUCCESS);

export const addVehicle = generic(CLIENT.INSURED.POLICY.VEHICLE.POST);
export const addVehicleSuccess = success(CLIENT.INSURED.POLICY.VEHICLE.POST_SUCCESS);

export const setVehicle = generic(CLIENT.INSURED.POLICY.VEHICLE.SET);
export const setVehicleSuccess = success(CLIENT.INSURED.POLICY.VEHICLE.SET_SUCCESS);

export const deleteVehicle = generic(CLIENT.INSURED.POLICY.VEHICLE.DEL);
export const deleteVehicleSuccess = success(CLIENT.INSURED.POLICY.VEHICLE.DEL_SUCCESS);
