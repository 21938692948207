import { toast } from "react-toastify";
import { API, AUTOMATION } from "../../actions/actionTypes";
import { activateSuccess, delSuccess, getAllSuccess, getSuccess, setSuccess } from "../../actions/automation/diaries";
import { takeLatest, takeLatestDel, takeLatestSet } from "../common/sagaGenerator";

const url = `${API.URL}/automation/diary`;

export default [
  takeLatest({
    type: AUTOMATION.DIARIES.GET,
    url: ({ id }) => `${url}/${id}`,
    actionSuccess: getSuccess,
    errorMessage: "Unable to get Diary Workflow"
  }),
  takeLatest({
    type: AUTOMATION.DIARIES.GETALL,
    url,
    actionSuccess: getAllSuccess,
    errorMessage: "Unable to get Diary Workflows"
  }),
  takeLatestSet({
    type: AUTOMATION.DIARIES.ACTIVATE,
    url: `${url}/active`,
    params: ({ id, active }) => ({ id, active }),
    actionSuccess: activateSuccess,
    onSuccess: ({ active }) => toast.success(`Diary Workflow successfully ${active ? "A" : "Dea"}ctivated`),
    onError: ({ active }) => toast.error(`Unable to ${active ? "A" : "Dea"}ctivate the Diary Workflow`)
  }),
  takeLatestDel({
    type: AUTOMATION.DIARIES.DEL,
    url: ({ id }) => `${url}/${id}`,
    isData: true,
    actionSuccess: delSuccess,
    successMessage: "Diary Workflow successfully deleted",
    errorMessage: "Unable to delete the Diary Workflow"
  }),
  takeLatest({
    type: AUTOMATION.DIARIES.SET,
    url,
    params: ({ data }) => data,
    method: ({ data: { id } }) => (id ? "PUT" : "POST"),
    actionSuccess: setSuccess,
    onSuccess: ({ data: { id } }) => toast.success(`Diary Workflow successfully ${id ? "upd" : "cre"}ated`),
    onError: ({ data: { id } }) => toast.error(`Unable to ${id ? "upd" : "cre"}ate the Diary Workflow`)
  })
];
