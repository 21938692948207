import * as Msal from "msal";

const SESSION_STORAGE = "sessionStorage";
const AUTHORIZATION_KEY = "Authorization";

const config = {
  instance: "https://terraclaim.b2clogin.com/tfp/terraclaim.onmicrosoft.com/",
  signInPolicy: "B2C_1A_signup_signin",
  resetPasswordPolicy: "B2C_1A_PasswordReset",
  clientId: `${process.env.CLIENT_ID}`,
  scopes: [`${process.env.SCOPES}`],
  redirectUri: `${process.env.WEB_URL}`
};

const state = {
  stopLoopingRedirect: false,
  launchApp: undefined,
  accessToken: undefined
};

const helpers = {
  isFunction: fn => typeof fn === "function",
  acquireToken: successCallback => {
    const user = window.msal.getUser(config.scopes);
    if (!user) window.msal.loginRedirect(config.scopes);
    else
      window.msal.acquireTokenSilent(config.scopes).then(
        accessToken => {
          window.sessionStorage.setItem(AUTHORIZATION_KEY, `Bearer ${accessToken}`);
          state.accessToken = accessToken;
          if (helpers.isFunction(state.launchApp)) state.launchApp();
          if (helpers.isFunction(successCallback)) successCallback();
        },
        error => error && window.msal.acquireTokenRedirect(config.scopes)
      );
  },
  isPolicy: policy => (window.msal?.authorityInstance?.canonicalAuthority || "").toLowerCase().indexOf((policy || "").toLowerCase()) > -1,
  resetPassword: () => {
    helpers.buildMsal(config.resetPasswordPolicy);
    window.msal.loginRedirect();
  },
  // eslint-disable-next-line no-unused-vars
  authCallback: (errorDesc, token, error, tokenType) => {
    if (errorDesc) {
      if (errorDesc.indexOf("AADB2C90118") > -1) return helpers.resetPassword();
      if (errorDesc.indexOf("AADB2C90091") === -1) {
        state.stopLoopingRedirect = true;
        return false;
      }
    }
    helpers.buildMsal(config.signInPolicy);
    return helpers.acquireToken();
  },
  cleanUpStorage: () => window.sessionStorage.removeItem(AUTHORIZATION_KEY),
  buildMsal: policy =>
    !helpers.isPolicy(policy) &&
    new Msal.UserAgentApplication(config.clientId, `${config.instance}${policy}`, helpers.authCallback, {
      cacheLocation: SESSION_STORAGE,
      validateAuthority: false,
      redirectUri: config.redirectUri,
      postLogoutRedirectUri: config.postLogoutRedirectUri
    })
};

const authorization = {
  initialize: () => {
    helpers.cleanUpStorage();
    helpers.buildMsal(config.signInPolicy);
  },
  run: launchApp => {
    if (window.msal.isCallback(window.location.hash) || window.parent !== window || window.opener || state.stopLoopingRedirect) return false;
    state.launchApp = launchApp;
    return helpers.acquireToken();
  },
  signOut: () => window.msal.logout(),
  getAccessToken: () => state.accessToken,
  resetPassword: helpers.resetPassword,
  acquireToken: helpers.acquireToken
};

export default authorization;
