export default {
  address1: undefined,
  city: undefined,
  dateofLoss: undefined,
  timeofInjury: undefined,
  entryDate: undefined,
  description: undefined,
  occurrenceId: -2,
  stateId: undefined,
  zipCode: undefined
};
