import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { Grid, SvgIcon, Button, IconButton } from "@material-ui/core";
import {
  DirectionsCar,
  Home,
  CheckCircleOutline,
  HighlightOffOutlined,
  MoreHorizOutlined,
  LockOpen,
  LockOutlined,
  Replay,
  NotInterested,
  HourglassEmpty,
  Person,
  Place,
  ExitToApp
} from "@material-ui/icons";
import clsx from "clsx";
import useStyles from "./CollapsibleClaimList.styles";
import HeaderCollapsibleBox from "../../common/HeaderCollapsibleBox";
import BodilyInjury from "../../../assets/bodily_injury.svg";

const CollapsibleClaimList = ({ claims, onToggle, associate, openClaimDetail }) => {
  const classes = useStyles();

  const ClaimHeader = ({ claim }) => {
    const viewClaimDetail = (c, event) => {
      event.stopPropagation();
      openClaimDetail(c);
    };
    return (
      <Grid container className={classes.claimContainer}>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>{claim.number && <div className={classes.claimNo}>{claim.number}</div>}</Grid>
            {claim.status && (
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    {claim.status === "Open" && (
                      <div className={clsx(classes.status, classes.open)}>
                        <LockOpen />
                      </div>
                    )}
                    {claim.status === "Closed" && (
                      <div className={clsx(classes.status, classes.close)}>
                        <LockOutlined />
                      </div>
                    )}
                    {claim.status === "Reopened" && (
                      <div className={clsx(classes.status, classes.reopen)}>
                        <Replay />
                      </div>
                    )}
                    {claim.status === "Voided" && (
                      <div className={clsx(classes.status, classes.voided)}>
                        <NotInterested />
                      </div>
                    )}
                    {claim.status === "Pending" && (
                      <div className={clsx(classes.status, classes.pending)}>
                        <HourglassEmpty />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {claim.processingStatus && (
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <div>
                      {claim.processingStatus === "Pending" && (
                        <div className={classes.pPending}>
                          <MoreHorizOutlined />
                        </div>
                      )}
                      {claim.processingStatus === "Accepted" && (
                        <CheckCircleOutline className={clsx(classes.pStatus, classes.accepted)} viewBox="2 2 20 20" />
                      )}
                      {claim.rocessingStatus === "Denied" && (
                        <HighlightOffOutlined className={clsx(classes.pStatus, classes.denied)} viewBox="2 2 20 20" />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6} className={classes.claimantSection}>
          <div className={classes.label}>Claimant</div>
          <Grid container alignItems="center">
            <Grid item xs={1}>
              <span className={clsx(classes.spnIcon)}>
                <Person />
              </span>
            </Grid>
            <Grid item xs={11}>
              {claim.claimantName && <span className={classes.claimantName}>{claim.claimantName}</span>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Grid container alignItems="flex-start" justify="flex-end" direction="row">
            <Grid item>
              <IconButton aria-label="delete" size="small" className={clsx(classes.exitToApp)} onClick={event => viewClaimDetail(claim, event)}>
                <ExitToApp />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  ClaimHeader.propTypes = {
    claim: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])).isRequired
  };

  const actionAssociate = (c, event) => {
    event.stopPropagation();
    associate(c);
  };
  const claimItems = claims.map(claim => (
    <HeaderCollapsibleBox
      header={<ClaimHeader {...{ claim }} />}
      title="Claim #"
      name={claim.number}
      key={claim.number}
      {...{ open: claim.open, onToggle }}
    >
      <Grid container className={classes.padding}>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <div className={classes.label}>Jurisdiction State</div>
          <Grid container alignItems="center" className={classes.item}>
            <Grid item xs={2}>
              <span className={clsx(classes.spnIcon)}>
                <Place />
              </span>
            </Grid>
            <Grid item xs={10}>
              {claim.stateCode && <div className={classes.claiminfo}>{claim.stateCode}</div>}
            </Grid>
          </Grid>
          <div className={classes.label}>Date of Loss</div>
          <Grid container alignItems="center">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {claim.dateOfLoss && <div className={classes.claiminfo}>{moment(claim.dateOfLoss).format("M/D/YYYY")}</div>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <div className={classes.label}>Insured</div>
          <Grid container alignItems="center" className={classes.item}>
            <Grid item xs={11}>
              {claim.insuredName && (
                <Link to={`/insured/${claim.insuredId}`} className={classes.link}>
                  {claim.insuredName}
                </Link>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <div className={classes.label}>Line Of Business</div>
                <Grid container alignItems="center">
                  {claim.coverageTypeCode && (
                    <Grid item>
                      <div className={classes.rectangle}>{claim.coverageTypeCode}</div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <div className={classes.label}>Loss Type</div>
                <Grid container alignItems="center">
                  {claim.claimLossTypeGroup && claim.claimLossType && (
                    <Grid item>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          {claim.claimLossTypeGroup === "Vehicle Damage" && <DirectionsCar />}
                          {claim.claimLossTypeGroup === "Property Damage" && <Home />}
                          {claim.claimLossTypeGroup === "Bodily Injury" && (
                            <SvgIcon>
                              <BodilyInjury />
                            </SvgIcon>
                          )}
                        </Grid>
                        <Grid item>{`${claim.claimLossTypeGroup}`}</Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3} className={classes.containerAssoc}>
          <Button variant="contained" color="primary" onClick={event => actionAssociate(claim, event)} className={classes.associateButton}>
            ASSOCIATE
          </Button>
        </Grid>
      </Grid>
    </HeaderCollapsibleBox>
  ));

  return <>{claimItems}</>;
};

CollapsibleClaimList.propTypes = {
  claims: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  onToggle: PropTypes.func.isRequired,
  associate: PropTypes.func.isRequired,
  openClaimDetail: PropTypes.func.isRequired
};

CollapsibleClaimList.defaultProps = {};

export default CollapsibleClaimList;
