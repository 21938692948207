import { FormControl, FormHelperText, IconButton, InputLabel } from "@material-ui/core";
import { Close, ArrowDropUp } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactSelect, { components as ReactSelectComps } from "react-select";
import { HocInput } from "./HocInput";

const components = {
  Control: other => (
    <ReactSelectComps.Control
      className={clsx("tc-srch-sel-control", { "tc-srch-sel-focused": !!other.isFocused, "tc-srch-sel-disabled": !!other.isDisabled })}
      {...other}
    />
  ),
  IndicatorSeparator: () => <></>,
  ClearIndicator: ({ innerProps }) => (
    <IconButton size="small" {...innerProps}>
      <Close style={{ fontSize: "1.125rem" }} />
    </IconButton>
  ),
  DropdownIndicator: ({ innerProps, selectProps }) => (
    <ArrowDropUp
      className={clsx("tc-srch-sel-arrow tc-arrow", {
        "tc-expand": !!selectProps?.menuIsOpen,
        "tc-srch-sel-arrow-disabled": !!selectProps?.isDisabled
      })}
      {...innerProps}
    />
  )
};

const styles = {
  control: () => ({}),
  input: css => ({ ...css, margin: undefined, paddingBottom: undefined, paddingTop: undefined }),
  clearIndicator: css => ({ ...css, color: undefined, label: "clearIndicator", padding: undefined }),
  singleValue: css => ({ ...css, margin: "0 0.25rem 0 0" }),
  valueContainer: css => ({ ...css, padding: "0.375rem 0 0.4375rem 0", minHeight: "1.1876em" }),
  menuPortal: css => ({ ...css, zIndex: 9999 }),
  menu: css => ({ ...css, marginTop: undefined, marginBottom: undefined })
};

export const BaseSearchSelect = ({
  allowClear,
  classes,
  className,
  components: componentsAttr,
  disabled,
  isClearable,
  isDisabled,
  error,
  label,
  name,
  onChange,
  options: opts,
  value: val,
  ...other
}) => {
  const [open, setOpen] = useState(false);
  const options = opts.map(item => ({ value: item.id, label: item.text }));
  const value = options.find(item => item.value === val) || null;
  const onToggle = o => () => setOpen(!!o);
  const selectProps = {
    ...{ menuPosition: "fixed", name, options, value },
    ...{ onMenuOpen: onToggle(true), onMenuClose: onToggle(), onFocus: onToggle(true), onBlur: onToggle() },
    onChange: op => (typeof onChange === "function" ? onChange({ target: { name, value: op?.value || undefined } }) : false),
    components: { ...components, ...(componentsAttr || {}) },
    styles,
    ...other,
    ...{ isClearable: !!allowClear || !!isClearable, isDisabled: !!isDisabled || !!disabled }
  };
  return (
    <FormControl fullWidth error={!!error} {...{ classes, className }}>
      {label && <InputLabel shrink={!!value || open}>{label}</InputLabel>}
      <ReactSelect placeholder="" className={clsx("tc-srch-sel-container", { "tc-srch-sel-error": !!error })} {...selectProps} />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

components.ClearIndicator.propTypes = { innerProps: PropTypes.objectOf(PropTypes.any).isRequired };
components.DropdownIndicator.propTypes = {
  innerProps: PropTypes.objectOf(PropTypes.any).isRequired,
  selectProps: PropTypes.objectOf(PropTypes.any).isRequired
};

BaseSearchSelect.propTypes = {
  allowClear: PropTypes.bool,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  components: PropTypes.objectOf(PropTypes.func),
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
BaseSearchSelect.defaultProps = {
  allowClear: false,
  isClearable: false,
  disabled: false,
  isDisabled: false,
  classes: undefined,
  className: undefined,
  components: undefined,
  error: undefined,
  label: undefined,
  name: undefined,
  onChange: undefined,
  options: [],
  value: undefined
};

const SearchSelect = HocInput(BaseSearchSelect);

export default SearchSelect;
