import React from "react";
import PropTypes from "prop-types";
import ClaimStatus from "../../claims/ClaimStatus";
import Styles from "./index.styles";

const VendorClaimInvolves = ({ claimsInvolved, ...rest }) => {
  const { totalClaims = 0, openClaims = 0 } = claimsInvolved || {};
  const statusStyle = {
    position: "relative",
    top: "-1px"
  };

  const classes = Styles();
  return (
    <div {...rest}>
      <div className={classes.claimInvolvedFirstWrapper}>
        <ClaimStatus status="Claim" propStyles={statusStyle} />
        <span> Claims</span>
        <span className={classes.cFontWeight}>{`(${totalClaims})`}</span>
      </div>
      <div className={classes.claimInvolvedSecondWrapper}>
        <span>Open Claims</span>
        <span>{openClaims}</span>
        <ClaimStatus status="Open" propStyles={statusStyle} />
      </div>
    </div>
  );
};

VendorClaimInvolves.propTypes = {
  claimsInvolved: PropTypes.shape({}).isRequired
};

export default VendorClaimInvolves;
