import { API } from "../../actions/actionTypes";
import { fireDownload, fireNewTab, objectToFormData } from "../../../api/apiUtils";
import sagaGenerator from "./sagaGenerator";

const getByParentId = (typeSuccess, props) =>
  sagaGenerator({
    typeSuccess,
    errorMessage: "Error loading documents",
    url: ({ url }) => url || `${API.URL}/folder`,
    params: ({ data }) => data,
    payloadPath: "documents",
    ...(props || {})
  });

const deleteFile = (typeSuccess, props) =>
  sagaGenerator({
    typeSuccess,
    successMessage: "Document successfully deleted",
    errorMessage: "Unable to delete the document",
    method: "DELETE",
    url: ({ url }) => url || `${API.URL}/file`,
    params: ({ data }) => data,
    isData: true,
    payloadPath: "documents",
    ...(props || {})
  });

const updateFile = (typeSuccess, props) =>
  sagaGenerator({
    typeSuccess,
    successMessage: "Document successfully updated",
    errorMessage: "Unable to update the document",
    url: ({ url }) => url || `${API.URL}/file`,
    params: ({ data }) => data,
    method: "PUT",
    payloadPath: "documents",
    ...(props || {})
  });

const addFolder = (typeSuccess, props) =>
  sagaGenerator({
    typeSuccess,
    successMessage: "Folder successfully added",
    errorMessage: "Unable to add the folder",
    url: ({ url }) => url || `${API.URL}/folder`,
    params: ({ data }) => data,
    method: "POST",
    payloadPath: "documents",
    ...(props || {})
  });

const updateFolder = (typeSuccess, props) =>
  sagaGenerator({
    typeSuccess,
    successMessage: "Folder successfully updated",
    errorMessage: "Unable to updated the folder",
    url: ({ url }) => url || `${API.URL}/folder`,
    params: ({ data }) => data,
    method: "PUT",
    payloadPath: "documents",
    ...(props || {})
  });

const deleteFolder = (typeSuccess, props) =>
  sagaGenerator({
    typeSuccess,
    successMessage: "Folder successfully deleted",
    errorMessage: "Unable to delete the folder",
    url: ({ url }) => url || `${API.URL}/folder`,
    method: "DELETE",
    params: ({ data }) => data,
    isData: true,
    payloadPath: "documents",
    ...(props || {})
  });

const uploadFiles = (typeSuccess, props) =>
  sagaGenerator({
    typeSuccess,
    successMessage: "Document(s) successfully added",
    errorMessage: "Unable to add the document(s)",
    method: "POST",
    url: ({ url }) => url || `${API.URL}/file`,
    params: ({
      data: {
        data: { files, folderId },
        ...ids
      }
    }) => objectToFormData({ data: { files, data: folderId, ...ids } }),
    payloadPath: "documents",
    apiConfig: ({ onUploadProgress }) => ({ onUploadProgress }),
    ...(props || {})
  });

const commonDownload = onCommonSuccess => (typeSuccess, props) =>
  sagaGenerator({
    typeSuccess,
    errorMessage: "Unable to download the document(s)",
    method: "POST",
    url: ({ url }) => url || `${API.URL}/file/download`,
    params: ({ data }) => data,
    apiConfig: ({ onDownloadProgress, onDownloadSuccess }) => ({
      onDownloadProgress,
      onSuccess: onDownloadSuccess || onCommonSuccess,
      responseType: "blob"
    }),
    ...(props || {})
  });

const downloadFiles = commonDownload(fireDownload);

const openFile = commonDownload(fireNewTab);

const moveFiles = (typeSuccess, props) =>
  sagaGenerator({
    typeSuccess,
    successMessage: "File(s) successfully moved",
    errorMessage: "Unable to move the file(s)",
    method: "POST",
    url: ({ url }) => url || `${API.URL}/file/move`,
    params: ({ data }) => data,
    payloadPath: "documents",
    ...(props || {})
  });

export { getByParentId, deleteFile, updateFile, addFolder, updateFolder, deleteFolder, uploadFiles, downloadFiles, openFile, moveFiles };
