import { Checkbox, Grid } from "@material-ui/core";
import { Folder } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import { FileNameIcon } from "../../../common/documents/FileIcon";

const any = arr => Array.isArray(arr) && !!arr.length;

const anyFiles = tree => !tree?.isQueue && (any(tree?.fileList) || (any(tree?.folderList) && tree.folderList.some(anyFiles)));

const FolderTree = ({ attachedFileIds, classes, tree, level, onChange }) => {
  const hasFiles = anyFiles(tree);
  if (!level && !hasFiles) return <i>No claim files</i>;
  const folders = any(tree?.folderList) ? tree.folderList.filter(f => !f.isQueue) : [];
  const files = any(tree?.fileList) ? tree?.fileList : [];
  const checked = id => any(attachedFileIds) && attachedFileIds.some(x => x === id);
  return (
    hasFiles && (
      <ul className={classes.attUl}>
        {!!level && tree.name && (
          <li>
            <Grid container wrap="nowrap" alignItems="center">
              <Grid item className={classes.attFolder}>
                <Folder />
              </Grid>
              <Grid item>{tree.name}</Grid>
            </Grid>
          </li>
        )}
        {any(folders) && (
          <li>
            {folders.map(t => (
              <FolderTree key={`folder-${tree.id}-${t.id}`} tree={t} level={level + 1} {...{ attachedFileIds, classes, onChange }} />
            ))}
          </li>
        )}
        {files.map(f => (
          <li key={f.id} className="att-file">
            <Grid container wrap="nowrap" alignItems="center">
              <Grid item>
                <Checkbox size="small" color="primary" {...{ onChange }} name={f.id} value={f.id} checked={checked(f.id)} />
              </Grid>
              <Grid item xs>
                <FileNameIcon file name={f.fileName} type={f.fileType} />
              </Grid>
            </Grid>
          </li>
        ))}
      </ul>
    )
  );
};

FolderTree.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  tree: PropTypes.objectOf(PropTypes.any).isRequired,
  level: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  attachedFileIds: PropTypes.arrayOf(PropTypes.string)
};
FolderTree.defaultProps = { level: 0, attachedFileIds: [] };

export default FolderTree;
