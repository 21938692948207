import React from "react";
import { Badge, Avatar, Fab } from "@material-ui/core";
import { shallowEqual, useSelector } from "react-redux";
import { PhotoCamera } from "@material-ui/icons";
import { useAvatarStyles } from "./index.styles";

export default props => {
  const classes = useAvatarStyles();
  const store = useSelector(({ profile: p }) => p, shallowEqual);
  const { photo, avatarName } = store || {};
  const badgeContent = (
    <Fab size="small" color="primary">
      <PhotoCamera />
    </Fab>
  );
  return (
    <div className={classes.root}>
      <Badge
        {...{
          ...props,
          badgeContent,
          overlap: "circle",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          },
          className: classes.badge
        }}
      >
        <Avatar alt="Travis Howard" src={photo} className={classes.avatar}>
          {avatarName}
        </Avatar>
      </Badge>
    </div>
  );
};
