import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { Room } from "@material-ui/icons";
import moment from "moment";
import Style from "./index.styles";

const VendorLocations = ({ basicInformation, locations, ...rest }) => {
  const vendorClasses = Style();

  const { fullName, category, phone1, location = {}, w9ReceivedOn } = basicInformation || {};

  const exists = s => s && s.trim();

  const setLocationAddress = data => {
    const comma = (exists(data?.address1) || exists(data?.address2)) && exists(data?.city) && exists(data?.state) ? "," : "";
    const dash = (exists(data?.address1) || exists(data?.address2) || exists(data?.city) || exists(data?.state)) && exists(data?.zipCode) ? "-" : "";
    return `${data?.address1 || data?.address2 || ""}${comma} ${data?.city || ""} ${data?.state || ""} ${dash} ${data?.zipCode || ""}`.trim();
  };

  return (
    <div {...rest}>
      <div className={vendorClasses.locationTitle}>Location</div>
      <div className={vendorClasses.locationInformation}>
        <div>
          <Room className={vendorClasses.locationIcon} />
        </div>
        <span className={vendorClasses.locationText}>{`Headquarters: ${fullName}`}</span>
      </div>
      <div className={vendorClasses.locationTextMBottom}>{Object.keys(location).length > 0 && setLocationAddress(location)}</div>
      <Grid container alignItems="stretch">
        <Grid item xs={4}>
          <div className={vendorClasses.label}>Category</div>
          {category}
        </Grid>
        <Grid item xs={4}>
          <div className={vendorClasses.label}>Phone</div>
          {phone1}
        </Grid>
        <Grid item xs={4}>
          <div className={vendorClasses.label}>W9 Submission</div>
          {w9ReceivedOn ? moment(w9ReceivedOn).format("M/D/YYYY") : <span>PENDING</span>}
        </Grid>
      </Grid>
      {locations.length > 0 &&
        locations
          .filter(l => l.isActive)
          .map(item => (
            <div key={item.id}>
              <hr className={vendorClasses.divider} />
              <div className={vendorClasses.locationInformation}>
                <div>
                  <Room className={vendorClasses.locationIcon} />
                </div>
                <span className={vendorClasses.locationText}>{item.fullName}</span>
              </div>
              <div className={vendorClasses.locationTextMBottom}>{setLocationAddress(item)}</div>
              <Grid container alignItems="stretch">
                <Grid item xs={4}>
                  <div className={vendorClasses.label}>Category</div>
                  {item.category}
                </Grid>
                <Grid item xs={4}>
                  <div className={vendorClasses.label}>Phone</div>
                  {item.phone1}
                </Grid>
                <Grid item xs={4}>
                  <div className={vendorClasses.label}>W9 Submission</div>
                  {item.w9ReceivedOn ? moment(item.w9ReceivedOn).format("M/D/YYYY") : <span>PENDING</span>}
                </Grid>
              </Grid>
            </div>
          ))}
    </div>
  );
};

VendorLocations.propTypes = {
  basicInformation: PropTypes.shape({}).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default VendorLocations;
