import React, { useEffect } from "react";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Grid, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import { Link as LinkIcon, Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import MaterialTable from "../table/MaterialTable";
import { del } from "../../../redux/actions/diary";
import { icon } from "../index.styles";
import Count from "../Count";
import LinkButton from "../LinkButton";
import useCustomConfirm from "../customHooks/useCustomConfirm";
import { useListSelector } from "./selectors";
import { setExportTitleFileName } from "../../../common";

const List = ({ entityType, onEdit, onAdd, onView, openForm, access, setIsLoading, diaryId }) => {
  const diaries = useListSelector(entityType);
  const { number: claimNumber } = useSelector(
    ({
      claim: {
        claim: { number }
      }
    }) => ({ number }),
    shallowEqual
  );

  const { number: occurrenceNumber } = useSelector(
    ({
      occurrence: {
        occurrence: { number }
      }
    }) => ({ number }),
    shallowEqual
  );

  const exportFileName =
    entityType === "claim" ? setExportTitleFileName(`${claimNumber}_Diaries`) : setExportTitleFileName(`${occurrenceNumber}_Diaries`);

  const dispatch = useDispatch();
  const handleDelete = rowData => {
    if (access.delete) {
      setIsLoading(true);
      dispatch(
        del({
          diary: rowData,
          entityType,
          onSuccess: () => setIsLoading(false),
          onError: () => setIsLoading(false)
        })
      );
    }
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleDelete, "Cancel", "Yes, Delete");
  const handleConfirmDelete = data =>
    handleCustomConfirm({
      oData: data,
      titleText: `Delete Diary`,
      messageText: (
        <>
          <p>{`The Diary will be deleted${entityType === "occurrence" ? "from the occurrences and from all the claims associated" : ""}.`}</p>
          <p>Are you sure you want to proceed?</p>
        </>
      )
    });
  const classIcon = icon();
  const actionAdd = [
    {
      icon: p => (
        <Button {...p} variant="contained" color="primary" style={{ marginLeft: 10 }}>
          Add Diary
        </Button>
      ),
      custom: true,
      isFreeAction: true,
      onClick: () => onAdd()
    }
  ];
  const actions = access.create ? actionAdd : [];
  const tableProps = {
    columns: [
      ...[
        {
          title: "Created Date",
          field: "createdOn",
          render: rowData => (rowData.createdOn ? moment(rowData.createdOn).format("M/D/YYYY") : null)
        },
        { title: "Owner", field: "owner" },
        {
          title: "Name",
          field: "name",
          render: rowData => (
            <LinkButton blue onClick={() => onView(rowData)}>
              {rowData.name}
            </LinkButton>
          ),
          customSort: (a, b) => (a.name || "").localeCompare(b.name || "")
        },
        { title: "Priority", field: "priority" },
        { title: "Type", field: "type" },
        { title: "Status", field: "status" },
        {
          title: "Due Date",
          field: "dueDate",
          type: "date",
          render: rowData => (rowData.dueDate ? moment(rowData.dueDate).format("M/D/YYYY") : null)
        },
        {
          title: "Completed Date",
          field: "completedDate",
          type: "date",
          render: rowData => (rowData.completedDate ? moment(rowData.completedDate).format("M/D/YYYY") : null)
        }
      ],
      ...(entityType === "claim"
        ? []
        : [
            {
              title: "Claims",
              render: rowData => {
                return (
                  <>
                    <LinkIcon className={classIcon.link} />
                    <Count>{rowData?.claimIds?.length || 0}</Count>
                  </>
                );
              }
            }
          ]),
      ...[
        {
          title: "",
          render: rowData => {
            if (rowData.isAssociated) return null;
            if (!access.update && !access.delete) return null;
            return (
              <Grid container wrap="nowrap">
                <Grid item>
                  {access.update && (
                    <IconButton className={classIcon.table} onClick={() => onEdit(rowData)}>
                      <EditIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid item>
                  {access.delete && (
                    <IconButton className={classIcon.table} onClick={() => handleConfirmDelete(rowData)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            );
          }
        }
      ]
    ],
    data: diaries,
    options: {
      exportFileName
    },
    actions: access.update && openForm ? [] : actions
  };
  useEffect(() => {
    if (diaryId) {
      const diaryData = diaries.find(myDiary => myDiary.id === diaryId * 1);
      if (diaryData) onView(diaryData);
    }
  }, [diaries]);
  return (
    <>
      <MaterialTable {...tableProps} hidePaging />
      <Confirm />
    </>
  );
};

List.propTypes = {
  openForm: PropTypes.bool.isRequired,
  entityType: PropTypes.string.isRequired,
  onToggleForm: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  access: PropTypes.objectOf(PropTypes.any),
  setIsLoading: PropTypes.func.isRequired,
  diaryId: PropTypes.string
};

List.defaultProps = {
  onToggleForm: () => {},
  access: { read: true, create: true, update: true, delete: true },
  diaryId: null
};
export default List;
