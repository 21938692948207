import { Tab } from "@material-ui/core";
import React, { Fragment, useRef, useState, useEffect, useCallback } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Redirect, useRouteMatch, useLocation, Link } from "react-router-dom";
import { useRouteParam, getUserAccess } from "../../common";
import ActionsAllowed from "../../components/common/contexts/ActionsAllowed";
import Loading, { LoadingContextProvider } from "../../components/common/Loading";
import SimpleTabs from "../../components/common/tabs/SimpleTabs";
import { getFilterFields, reset } from "../../redux/actions/occurrence";
import OccurrenceRoutes, { routes } from "./Routes";

export default () => {
  const [state, setState] = useState({ padding: true, breadcrumb: true });
  const occurrenceId = useRouteParam("occurrenceId");
  const { isExact, url } = useRouteMatch();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const store = useSelector(
    ({
      profile,
      occurrence: {
        filterFields,
        occurrence: { number }
      }
    }) => ({ userProfile: profile, filters: filterFields, number }),
    shallowEqual
  );
  const { userProfile, filters, number } = store;
  useEffect(() => {
    dispatch(getFilterFields(occurrenceId));
    return () => dispatch(reset());
  }, []);
  const routesAllowed = routes.reduce((acc, [route]) => {
    if (!filters?.codeName || !userProfile.securityProfilesDetails?.length) return acc;
    const routeAccess = {
      ...getUserAccess(userProfile.securityProfilesDetails, `OCCURRENCE.${route.toUpperCase()}`, filters.clientId, filters.stateId),
      route
    };
    if (route === "notes")
      return [
        ...acc,
        routeAccess,
        {
          ...getUserAccess(userProfile.securityProfilesDetails, "OCCURRENCE.NOTES.CONFIDENTIAL", filters.clientId, filters.stateId),
          route: "notes-confidential"
        }
      ];
    return [...acc, routeAccess];
  }, []);
  const isRouteRead = r => routesAllowed.some(({ route, read }) => route === r && read);
  const tabsToShow = routes.reduce((acc, [route, label]) => (isRouteRead(route) ? [...acc, [route, label]] : acc), []);
  const handlePadding = useCallback(p => setState(ps => ({ ...ps, padding: p })), []);
  const handleBreadcrumb = useCallback(b => setState(ps => ({ ...ps, breadcrumb: b })), []);
  const container = useRef(null);
  const { padding, breadcrumb } = state;
  const breadcrumbs = {
    breadData: {
      linkTitle: `OCCURRENCE`,
      to: "/claims/occurrences"
    },
    activeTitle: (() => {
      const occurrence = `${number || `Occurrence ${occurrenceId}`}`;
      const label = (tabsToShow.find(([route]) => pathname === `${url}/${route}`) || ["", ""])[1];
      if (!label) return occurrence;
      return `${occurrence}: ${label}`;
    })()
  };
  return (
    <Fragment key="occurrences-page-container">
      <div ref={container} className="tc-page-container">
        {(!tabsToShow.length && <Loading float />) || (
          <Fragment key="occurrence-page">
            {isExact && <Redirect to={`${url}/${tabsToShow[0][0]}`} />}
            <SimpleTabs value={url === pathname ? `${url}/${tabsToShow[0][0]}` : pathname}>
              {tabsToShow.map(([route, label]) => (
                <Tab key={route} label={label} component={Link} to={`${url}/${route}`} value={`${url}/${route}`} />
              ))}
            </SimpleTabs>
            <LoadingContextProvider float actions={{ handlePadding, handleBreadcrumb }} values={{ padding, breadcrumb, breadcrumbs }}>
              <ActionsAllowed.Provider value={routesAllowed}>
                <OccurrenceRoutes containerRef={container} />
              </ActionsAllowed.Provider>
            </LoadingContextProvider>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};
