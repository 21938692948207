import { makeStyles } from "@material-ui/core";

export const useAttorneyStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2.75),
    marginBottom: theme.spacing(2.75)
  },
  inputWidth: {
    width: "140px"
  }
}));

export const useDocumentsStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2.75),
    marginBottom: theme.spacing(2.75)
  },
  upload: {
    backgroundColor: "#F3F3F3",
    padding: theme.spacing(3.38, 2.8, 2.7, 2.8),
    textAlign: "center",
    color: "#898F91",
    height: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    "& svg": { fontSize: 66, marginBottom: -12 },
    "& button": { marginTop: theme.spacing(3) }
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    verticalAlign: "middle",
    lineHeight: "1em",
    "& > tbody > tr > td:first-of-type": {
      width: 36,
      padding: theme.spacing(0.75, 0),
      color: "#898F91"
    },
    "& > tbody > tr > td:not(:first-of-type)": { padding: theme.spacing(0.5, 1.5) },
    "& > tbody > tr > td:nth-of-type(3)": {
      verticalAlign: "top"
    },
    "& > tbody > tr > td:nth-of-type(4)": {
      width: 200,
      verticalAlign: "top"
    },
    "& > tbody > tr > td:nth-of-type(5)": {
      width: 54
    }
  }
}));

export const useWitnessStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2.75),
    marginBottom: theme.spacing(2.75)
  },
  title: {
    color: "#428302",
    marginBottom: 5
  }
}));
