import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const MoneyLabel = ({ value, isInteger, ...props }) => (
  <NumberFormat
    style={{ whiteSpace: "nowrap" }}
    value={value || 0}
    displayType="text"
    thousandSeparator
    fixedDecimalScale
    {...(isInteger ? {} : { prefix: "$ ", decimalScale: 2 })}
    {...props}
  />
);

MoneyLabel.propTypes = { value: PropTypes.number, isInteger: PropTypes.bool };

MoneyLabel.defaultProps = { value: undefined, isInteger: false };

export default MoneyLabel;
