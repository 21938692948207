import axios from "axios";
import qs from "qs";

export const api = async ({ url, method = "GET", data, onSuccess, ...other }) => {
  // eslint-disable-next-line no-undef
  axios.defaults.headers.common.Authorization = sessionStorage.getItem("Authorization");
  const dataOrParams = other.dataOrParams || (["GET", "DELETE"].includes(method) ? "params" : "data");
  const options = { url, method, [dataOrParams]: data, ...other };
  if (method === "GET") options.paramsSerializer = params => qs.stringify(params, { indices: false });
  const then = typeof onSuccess === "function" ? onSuccess : response => response.data;
  const response = await axios.request(options);
  return then(response);
};

export async function handleResponse(response) {
  if (response.ok) return response.json();
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error(`API call failed. ${error}`);
  throw error;
}

export const objectToFormData = (obj, form, namespace) => {
  const fd = form || new FormData();
  let formKey;
  Object.keys(obj).forEach(property => {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      if (namespace) formKey = `${namespace}[${property}]`;
      else formKey = property;
      const oProp = obj[property];
      const isFile = oProp instanceof File;
      if (typeof oProp !== "undefined" && oProp !== null)
        if (typeof oProp === "object" && !isFile)
          // if the property is an object, but not a File, use recursivity.
          objectToFormData(oProp, fd, formKey);
        else fd.append(formKey, oProp); // if it's a string or a File object
    }
  });
  return fd;
};

export const filenameFromHeader = (headers, defaultName) => {
  const contentDisposition = headers["content-disposition"];
  if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches.length > 1) {
      return matches[1].replace(/['"]/g, "");
    }
  }
  return defaultName || "filename";
};

export const fileProps = ({ headers, data }, defaultName) => {
  const filename = (() => {
    const contentDisposition = headers["content-disposition"];
    if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches.length > 1) {
        return matches[1].replace(/['"]/g, "");
      }
    }
    return defaultName || "filename";
  })();
  const file = new File([data], filename, { type: data.type });
  const url = URL.createObjectURL(file);
  return { filename, url, type: data.type };
};

export const fireNewTab = (file, props) => {
  const { filename, url } = props || fileProps(file);
  window.open(url, filename);
};

export const fireDownload = (file, props) => {
  const { filename, url } = props || fileProps(file);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const formatMoney = (value = "", currencyType = "USD") =>
  (value || "").toLocaleString("en-US", {
    style: "currency",
    currency: currencyType
  });
