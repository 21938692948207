import { toast } from "react-toastify";
import { objectToFormData } from "../../../../api/apiUtils";
import { API, ADMIN } from "../../../actions/actionTypes";
import { getAllSuccess, setSuccess, delSuccess } from "../../../actions/admin/templates/communications";
import { takeLatest, takeLatestDel } from "../../common/sagaGenerator";

const url = `${API.URL}/communication/template`;

export default [
  takeLatest({
    type: ADMIN.TEMPLATE_COMMUNICATION.GETALL,
    url,
    actionSuccess: getAllSuccess,
    errorMessage: "Unable to get Communication Templates"
  }),
  takeLatest({
    type: ADMIN.TEMPLATE_COMMUNICATION.SET,
    url,
    method: ({ data: { id } }) => (id ? "PUT" : "POST"),
    params: ({ data }) => objectToFormData(data),
    actionSuccess: setSuccess,
    onSuccess: ({ data: { id } }) => toast.success(`Communication Template successfully ${id ? "upd" : "cre"}ated`),
    onError: ({ data: { id } }) => toast.error(`Unable ${id ? "upd" : "cre"}ate the Communication Template`)
  }),
  takeLatestDel({
    type: ADMIN.TEMPLATE_COMMUNICATION.DEL,
    url,
    params: ({ id }) => ({ id }),
    actionSuccess: delSuccess,
    successMessage: "Communication Template successfully deleted",
    errorMessage: "Unable to delte the Communication Template"
  })
];
