import { CLIENTR as CLIENT } from "../actionTypes";

const addInsured = (data, onSuccess) => ({ type: CLIENT.INSUREDS.POST, data, onSuccess });

const addInsuredSuccess = insured => ({ type: CLIENT.INSUREDS.POST_SUCCESS, insured });

// Actions

const getInsureds = id => ({ type: CLIENT.INSUREDS.GET, id });

const deleteInsured = id => ({ type: CLIENT.INSUREDS.DEL, id });

// Success

const getInsuredsSuccess = insureds => ({ type: CLIENT.INSUREDS.GET_SUCCESS, insureds });

const deleteInsuredSuccess = id => ({ type: CLIENT.INSUREDS.DEL_SUCCESS, id });

export { getInsureds, getInsuredsSuccess, deleteInsured, deleteInsuredSuccess, addInsured, addInsuredSuccess };
