const initialState = {
  basicInformation: {
    isPerson: false,
    isActive: true,
    location: { address1: "", address2: "", city: "", stateId: "", zipCode: "" },
    mailingLocation: { address1: "", address2: "", city: "", stateId: undefined, zipCode: "" }
  },
  validSchema: false,
  industry: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_VALID_SCHEMA":
      return { ...state, validSchema: action.value };
    case "SET_BASIC_INFORMATION_PERSON":
      return { ...state, basicInformation: { ...state.basicInformation, ...action.payload } };
    case "SET_BASIC_INFORMATION":
      return { ...state, basicInformation: { ...state.basicInformation, [action.payload.name]: action.payload.value } };
    case "SET_BASIC_INFORMATION_LOCATION":
      return {
        ...state,
        basicInformation: { ...state.basicInformation, location: { ...state.basicInformation.location, [action.payload.name]: action.payload.value } }
      };
    case "SET_BASIC_INFORMATION_MAILING_LOCATION":
      return {
        ...state,
        basicInformation: {
          ...state.basicInformation,
          mailingLocation: { ...state.basicInformation.mailingLocation, [action.payload.name]: action.payload.value }
        }
      };
    case "ADD_CODE":
      return { ...state, industry: [...state.industry, action.payload.code] };
    case "REMOVE_CODE":
      return { ...state, industry: state.industry.filter(code => code.id !== action.payload.code.id) };
    default:
      return state;
  }
};

export { initialState, reducer };
