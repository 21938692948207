import * as yup from "yup";
import { array } from "../../../common";
import { isNumber } from "../../../common/yup";

const boolean = () => yup.boolean().nullable();
const string = () => yup.string().nullable();
const number = () => yup.number().nullable();

const test = person => (val, ctx) => {
  const { isPerson } = ctx?.parent;
  return person !== isPerson || Boolean(val);
};

export const profileSchema = yup.object().shape({
  isPerson: boolean().required("Type Required"),
  firstName: string().test("default", "First Name Required", test(true)),
  lastName: string().test("default", "Last Name Required", test(true)),
  name: string().test("default", "Name Required", test(false)),
  categoryId: number().required("Category Required"),
  email: string().email("Invalid Email"),
  address1: string().test("Bank Street Required", "Bank Street Required", (val, ctx) => {
    if (ctx.parent.categoryId === 3 && !val) return false;
    return true;
  }),
  city: string().test("Bank City Required", "Bank City Required", (val, ctx) => {
    if (ctx.parent.categoryId === 3 && !val) return false;
    return true;
  })
});

export const industrySchema = yup.object().shape({
  hasMultipleLocations: boolean(),
  vendorId: number().test("", "Location Required", (val, ctx) => !ctx.parent?.hasMultipleLocations || isNumber(val)),
  codeTypeId: number().required("Type Required"),
  divisionId: number().test("", "Division Required", (v, c) => !array(c?.parent?.divisions).length || isNumber(v)),
  id: number().required("Code Required")
});
