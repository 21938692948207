import { jsPDF as JsPDF } from "jspdf";
import moment from "moment";

const inlineCss = {
  container: `display: block; width:595px; height:842px;`,
  note: `width:520px; font-family: 'Roboto', Helvetica, Arial; font-size: 10px; color: #1f1f1f; font-weight: 400; line-height: 1.43; letter-spacing: 0.01071em; margin-top: 16px; margin-left:40px; margin-bottom: 16px; padding: 16px; border-radius: 4px; background-color: #f2f2f2;`,
  response: `width:460px; font-family: 'Roboto', Helvetica, Arial; font-size: 10px; color: #1f1f1f; font-weight: 400; line-height: 1.43; letter-spacing: 0.01071em; margin-left: 100px; margin-bottom: 16px; padding: 16px; border-radius: 4px; background-color: #dbebf0;`,
  title: `display: block; margin-top: 0px; margin-bottom: 8px; margin-block-end: 8px; margin-block-start: 0px; margin-inline-end: 0px; margin-inline-start: 0px;`,
  tag: `height: 24px; display: inline-flex; align-items: center; vertical-align: middle; justify-content: center; font-size: 10px; font-weight: initial; text-transform: uppercase; box-sizing: border-box; white-space: nowrap; text-decoration: none; border: 1px solid #bababa; border-radius: 16px; background-color: transparent; padding: 0; margin-left: 16px; outline: 0;`,
  textTag: `padding-left: 8px;padding-right: 8px;`,
  paragraph: `margin-top: 0;margin-bottom: 6px;`,
  author: `display: block;text-align: right;`,
  notification: `margin-bottom: -40px;width: 38px; height: 38px; background-color: #fff; position: relative; display: inline-block; border-radius: 50%; border: 1px solid #BBBBBB; text-align: center; align-items: center; vertical-align: middle; justify-content: center; `,
  wrapperIconComment: `position: absolute;top: 11px;left: 12px;`,
  contentIconComment: `content: ""; position: absolute; width: 12px; height: 2px; background: black; left: 0;`,
  badge: `display: flex; min-width:20px;height: 20px;position: absolute;top: -8px;right: -8px;border-radius: 50%; color: #fff; background-color: #107A98; text-align: center; align-items: center; vertical-align: middle; justify-content: center;`
};

const ExportPDFId = "WRAPPER-NOTES";

const buildTag = ({ tag, elementId, style }) => {
  const element = document.createElement(tag);
  if (elementId) element.setAttribute("id", elementId);
  if (style) element.style.cssText = style;
  return element;
};

const makeChip = textChip => {
  const div = buildTag({ tag: "div", style: inlineCss.tag });
  const span = buildTag({ tag: "span", style: inlineCss.textTag });
  const text = document.createTextNode(textChip);
  span.appendChild(text);
  div.appendChild(span);
  return div;
};

const makeTitle = ({ n, isResponse }) => {
  const h4 = buildTag({ tag: "h4", style: inlineCss.title });
  const titleText = document.createTextNode(n.title);
  h4.appendChild(titleText);
  if (!isResponse && n.type) h4.appendChild(makeChip(n.type));
  if (!isResponse && n.isConfidential) h4.appendChild(makeChip("Confidential"));
  return h4;
};

const makeDescription = n => {
  const p = buildTag({ tag: "p", style: inlineCss.paragraph });
  const textDescription = document.createTextNode(n.note);
  p.appendChild(textDescription);
  return p;
};

const makeAuthor = n => {
  const small = buildTag({ tag: "small", style: inlineCss.author });
  const text = document.createTextNode(
    `${moment(n.updatedOn || n.createdOn).format("M/D/YYYY [at] H:mm a")} by ${n.updatedByName || n.createdByName}`
  );
  small.appendChild(text);
  return small;
};

const makeChipComment = numberOfComments => {
  const div = buildTag({ tag: "div", style: inlineCss.notification });
  const icon = buildTag({ tag: "span", style: inlineCss.wrapperIconComment });
  icon.appendChild(buildTag({ tag: "span", style: `${inlineCss.contentIconComment} top: 2px;` }));
  icon.appendChild(buildTag({ tag: "span", style: `${inlineCss.contentIconComment} top: 6px;` }));
  icon.appendChild(buildTag({ tag: "span", style: `${inlineCss.contentIconComment} top: 10px;` }));
  div.appendChild(icon);

  const badge = buildTag({ tag: "span", style: inlineCss.badge });
  const textBadge = document.createTextNode(numberOfComments.toString());
  badge.appendChild(textBadge);
  div.appendChild(badge);

  return div;
};

const makeNote = ({ n, isResponse }) => {
  const div = buildTag({ tag: "div", elementId: `note-${n.id}`, style: isResponse ? inlineCss.response : inlineCss.note });
  div.appendChild(makeTitle({ n, isResponse }));
  div.appendChild(makeDescription(n));
  div.appendChild(makeAuthor(n));
  if (n.responses.length > 0) div.appendChild(makeChipComment(n.responses.length));

  return div;
};

const addPage = newId => {
  const pageId = `page-notes-${newId}`;
  const divExport = document.getElementById(ExportPDFId);
  const container = buildTag({ tag: "div", elementId: pageId, style: inlineCss.container });
  divExport.appendChild(container);
  return pageId;
};

const forEach = (data, callBack) => {
  for (let i = 0; i < data.length; i += 1) callBack(data[i]);
};

const needNewPage = containerId => {
  // 'p', 'pt', [ 595.28,  841.89] --a4
  let sumSize = 0;
  forEach(document.querySelectorAll(`#${containerId}`)[0].childNodes, el => {
    sumSize += el.offsetHeight;
  });
  return sumSize > 595;
};

const createWrapperExportPDF = () => {
  document.body.appendChild(buildTag({ tag: "div", elementId: ExportPDFId, style: "position: absolute; top: 0px; left: -999px;" }));
};
const cleanUpWrapperExportPDF = () => {
  const node = document.getElementById(ExportPDFId);
  if (node) while (node.firstChild) node.removeChild(node.lastChild);
};

const deleteWrapperExportPDF = () => {
  document.getElementById(ExportPDFId).remove();
};

const buildHtml2PDF = notes2PDF => {
  if (!document.getElementById(ExportPDFId)) createWrapperExportPDF();
  else cleanUpWrapperExportPDF();

  let lastId = 0;
  let currentPageId = `page-notes-${lastId}`;
  let currentPage = null;
  notes2PDF
    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
    .forEach((n, i) => {
      if (i === 0 || needNewPage(currentPageId)) {
        currentPageId = addPage(lastId);
        lastId += 1;
      }
      currentPage = document.getElementById(currentPageId);
      currentPage.appendChild(makeNote({ n, isResponse: false }));

      if (n.responses.length > 0) {
        n.responses
          .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
          .forEach(r => {
            if (needNewPage(currentPageId)) {
              currentPageId = addPage(lastId);
              lastId += 1;
            }
            currentPage = document.getElementById(currentPageId);
            currentPage.appendChild(makeNote({ n: r, isResponse: true }));
          });
      }
    });

  return document.getElementById(ExportPDFId).innerHTML;
};

const exportPDF = ({ data, number, loading }) => {
  if (data.length > 0) {
    loading.show();
    const fileName = `TerraClaim_${number}_Notes`;
    const pdf = new JsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
      hotfixes: ["px_scaling"]
    });
    pdf.html(buildHtml2PDF(data).replace("left: -999px;", "left: 0px;"), {
      html2canvas: { scale: 1 },
      callback: () => {
        pdf.deletePage(pdf.internal.getNumberOfPages());
        pdf.save(fileName);
        deleteWrapperExportPDF();
        loading.hide();
      }
    });
  }
};

export default exportPDF;
