import { takeLatest } from "redux-saga/effects";
import { objectToFormData } from "../../../api/apiUtils";
import { API, CLAIM } from "../../actions/actionTypes";
import { approveSuccess, reindexSuccess } from "../../actions/claim/documents";
import * as docs from "../common/documents";
import { takeLatestPost } from "../common/sagaGenerator";

export default [
  takeLatest(CLAIM.DOCUMENTS.GET, docs.getByParentId(CLAIM.DOCUMENTS.GET_SUCCESS)),
  takeLatest(CLAIM.DOCUMENTS.DEL_FILE, docs.deleteFile(CLAIM.DOCUMENTS.DEL_FILE_SUCCESS)),
  takeLatest(CLAIM.DOCUMENTS.SET_FILE, docs.updateFile(CLAIM.DOCUMENTS.SET_FILE_SUCCESS)),
  takeLatest(CLAIM.DOCUMENTS.POST_FOLDER, docs.addFolder(CLAIM.DOCUMENTS.POST_FOLDER_SUCCESS)),
  takeLatest(CLAIM.DOCUMENTS.SET_FOLDER, docs.updateFolder(CLAIM.DOCUMENTS.SET_FOLDER_SUCCESS)),
  takeLatest(CLAIM.DOCUMENTS.DEL_FOLDER, docs.deleteFolder(CLAIM.DOCUMENTS.DEL_FOLDER_SUCCESS)),
  takeLatest(CLAIM.DOCUMENTS.POST_FILES, docs.uploadFiles(CLAIM.DOCUMENTS.POST_FILES_SUCCESS)),
  takeLatest(CLAIM.DOCUMENTS.DOWNLOAD_FILES, docs.downloadFiles(CLAIM.DOCUMENTS.DOWNLOAD_FILES_SUCCESS)),
  takeLatest(CLAIM.DOCUMENTS.OPEN_FILE, docs.openFile(CLAIM.DOCUMENTS.OPEN_FILE_SUCCESS)),
  takeLatest(CLAIM.DOCUMENTS.MOVE_FILE, docs.moveFiles(CLAIM.DOCUMENTS.MOVE_FILE_SUCCESS)),
  takeLatestPost({
    type: CLAIM.DOCUMENTS.APPROVE,
    actionSuccess: approveSuccess,
    successMessage: "Document successfuly approved",
    errorMessage: "Unable to approve the document",
    url: `${API.URL}/indexing/approve`,
    params: ({ data }) => objectToFormData(data)
  }),
  takeLatestPost({
    type: CLAIM.DOCUMENTS.REINDEX,
    actionSuccess: reindexSuccess,
    successMessage: "Document successfuly returned to indexing",
    errorMessage: "Unable to return the document to indexing",
    url: `${API.URL}/indexing/reindex`,
    params: ({ data }) => data
  })
];
