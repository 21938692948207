import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";
import { Grid, Button, Collapse, Typography, Switch, FormControlLabel } from "@material-ui/core";
import { SelectInput, InputText, PhoneInput, DateTimeInput } from "../../../common/inputs";
import useCustomConfirm from "../../../common/customHooks/useCustomConfirm";
import useYupValidationSchema from "../../../common/customHooks/useYupValidationSchema";
import { profileSchema } from "../yup";

const Form = ({ onClose, onSave, loading, basicInformation, parentId }) => {
  const store = useSelector(
    ({ common: { states, vendorCategories, memberCategories, taxType } }) => ({
      states,
      vendorCategories,
      memberCategories,
      taxIdType: taxType
    }),
    shallowEqual
  );
  const [state, setState] = useState(basicInformation);
  const { handleValidateSchema, validationMessages } = useYupValidationSchema(profileSchema);
  const { states, vendorCategories, memberCategories, taxIdType } = store;
  const vendorParentId = parentId || basicInformation.parentId;
  const { id, isPerson, isActive, location, hasMultipleLocations, disabledMultipleLocation, mailingLocation } = state;
  const isActiveLoaded = () => {
    if (isActive === (null || undefined)) {
      return 1;
    }
    return isActive ? 1 : 2;
  };
  useEffect(() => {
    if (basicInformation?.id) setState(basicInformation);
  }, [basicInformation]);

  useEffect(() => {
    handleValidateSchema({
      isPerson,
      firstName: state.firstName,
      lastName: state.lastName,
      name: state.name,
      categoryId: state.categoryId,
      stateId: location?.stateId,
      email: state.email,
      address1: location?.address1,
      city: location?.city
    });
  }, [isPerson, state.firstName, state.lastName, state.name, state.categoryId, location?.stateId, state.email, location?.address1, location?.city]);

  const onChange = ({ target: { name, value, checked } }) =>
    setState(ps => {
      if (name === "isPerson") {
        const category = (value === 1 ? memberCategories : vendorCategories).find(c => c.id === ps.categoryId) || {};
        const newState = {
          ...ps,
          isPerson: value === 1,
          firstName: undefined,
          lastName: undefined,
          name: undefined,
          categoryId: category.id,
          category: category.text
        };

        return newState;
      }
      if (name === "hasMultipleLocations") return { ...ps, [name]: checked };
      if (name === "isActive") return { ...ps, [name]: value === 1 };
      const newState = { ...ps, [name]: value || undefined };

      return newState;
    });

  const onLocationChange = ({ target: { name, value } }) => setState(ps => ({ ...ps, location: { ...ps.location, [name]: value || undefined } }));

  const onMailingLocationChange = ({ target: { name, value } }) => {
    setState(ps => ({ ...ps, mailingLocation: { ...ps.mailingLocation, [name]: value || undefined } }));
  };

  const onDispatch = () => onSave(state, vendorParentId);

  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(onDispatch, "Cancel", "Yes, Create");

  const onSubmit = event => {
    event.preventDefault();
    const { isValid } = handleValidateSchema({ ...state, address1: location?.address1, city: location?.city });
    if (!isValid) return false;
    if (!id && vendorParentId)
      return handleCustomConfirm({
        titleText: `Add Location`,
        messageText: (
          <>
            <p>The following Location will be created:</p>
            <p>
              <b>{state.name}</b>
            </p>
            <p>Are you sure you want to proceed?</p>
          </>
        )
      });
    return onDispatch();
  };

  const MultipleLocations = (
    <Switch checked={hasMultipleLocations} {...{ onChange }} name="hasMultipleLocations" inputProps={{ "aria-label": "primary checkbox" }} />
  );
  const inputProps = (name, label, isLocation) => ({
    name,
    label,
    value: (isLocation ? (location || {})[name] : state[name]) || undefined,
    error: (validationMessages || {})[name],
    onChange: isLocation ? onLocationChange : onChange
  });
  return (
    <form {...{ onSubmit }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <SelectInput
            {...{ ...inputProps("isPerson", "Type"), value: isPerson ? 1 : 2 }}
            options={[
              { id: 1, text: "Person" },
              { id: 2, text: "Entity" }
            ]}
            disabled={Boolean(vendorParentId)}
          />
          <Collapse in={isPerson}>
            <Grid container spacing={2}>
              <Grid item xs>
                <InputText {...inputProps("firstName", "First Name")} />
              </Grid>
              <Grid item xs>
                <InputText {...inputProps("lastName", "Last Name")} />
              </Grid>
            </Grid>
          </Collapse>
          <Collapse in={!isPerson}>
            <InputText {...inputProps("name", "Name")} />
          </Collapse>
          {isPerson && <SelectInput {...inputProps("categoryId", "Category")} options={memberCategories} />}
          {!isPerson && <SelectInput {...inputProps("categoryId", "Category")} options={vendorCategories} />}
          <InputText {...inputProps("webSite", "Web Site")} />
        </Grid>
        <Grid item xs={3}>
          <SelectInput
            {...{ ...inputProps("isActive", "Status"), value: isActiveLoaded() }}
            options={[
              { id: 1, text: "Active" },
              { id: 2, text: "Inactive" }
            ]}
          />
          <PhoneInput {...inputProps("phone1", "Phone")} />
          <PhoneInput {...inputProps("fax", "Fax Number")} />
          <SelectInput {...inputProps("taxIDTypeId", "Tax ID Type")} options={taxIdType} />
        </Grid>
        <Grid item xs={3}>
          <InputText {...inputProps("email", "Email Address")} />
          <InputText {...inputProps("address1", "Address 1", true)} />
          <InputText {...inputProps("address2", "Address 2", true)} />
          <InputText {...inputProps("taxID", "Tax ID")} />
        </Grid>
        <Grid item xs={3}>
          <InputText {...inputProps("city", "City", true)} />
          <SelectInput {...inputProps("stateId", "State", true)} options={states} />
          <InputText {...inputProps("zipCode", "Zip", true)} />
          {!vendorParentId && <FormControlLabel label="Multiple Locations" control={MultipleLocations} disabled={disabledMultipleLocation} />}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <InputText
            name="address1"
            id="mailing_address1"
            label="Mailing Address"
            value={(mailingLocation || {}).address1 || undefined}
            onChange={onMailingLocationChange}
          />
        </Grid>
        <Grid item xs={3}>
          <InputText
            name="city"
            id="mailing_city"
            label="Mailing City"
            value={(mailingLocation || {}).city || undefined}
            onChange={onMailingLocationChange}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectInput
            name="stateId"
            id="mailing_stateId"
            label="Mailing State"
            value={(mailingLocation || {}).stateId || undefined}
            onChange={onMailingLocationChange}
            options={states}
          />
        </Grid>
        <Grid item xs={3}>
          <InputText
            name="zipCode"
            id="mailing_zipCode"
            label="Mailing Zip"
            value={(mailingLocation || {}).zipCode || undefined}
            onChange={onMailingLocationChange}
          />
        </Grid>
      </Grid>
      <InputText {...inputProps("description", "Description")} rows={3} />
      <Grid item style={{ flexGrow: 1 }}>
        <Typography className="tc-title" gutterBottom>
          W9 Information
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <DateTimeInput {...inputProps("w9RequestedOn", "Date Requested")} keyboard />
        </Grid>
        <Grid item xs={3}>
          <DateTimeInput {...inputProps("w9ReceivedOn", "Date Received")} keyboard />
        </Grid>
        <Grid item xs={3}>
          <DateTimeInput {...inputProps("w9EnteredOn", "Date Entered to the System")} keyboard />
        </Grid>
      </Grid>
      <Grid container justify="flex-end" spacing={2}>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={onClose}>
            CANCEL
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {id > 0 ? "SAVE" : "ADD"}
          </Button>
        </Grid>
      </Grid>
      <Confirm />
    </form>
  );
};

Form.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  basicInformation: PropTypes.objectOf(PropTypes.any).isRequired,
  parentId: PropTypes.number
};
Form.defaultProps = {
  parentId: null
};
export default Form;
