import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link as LinkIcon } from "@material-ui/icons";

const OccurrenceOption = ({ classes, option: { number, date, fullAddress, claimsAssociatedCount } }) => {
  return (
    <span className={classes.option}>
      <span className={classes.number}>{number}</span>
      <LinkIcon viewBox="0 7 24 12" className={classes.link} />
      <span className={classes.count}>{claimsAssociatedCount}</span>
      <span className={classes.block}>{`DOL ${moment(date).format("M/D/YYYY")}`}</span>
      <span className={classes.block}>{fullAddress}</span>
    </span>
  );
};

OccurrenceOption.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  option: PropTypes.shape({
    number: PropTypes.string,
    date: PropTypes.any,
    fullAddress: PropTypes.string,
    claimsAssociatedCount: PropTypes.number
  }).isRequired
};

export default OccurrenceOption;
