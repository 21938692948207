import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { useRootStyles } from "./hooks";
import DocumentFolder from "./DocumentFolder";
import ProgressButton, { progressProps } from "./ProgressButton";
import RegexFilter from "../RegexFilter";
import { focusRef } from "../../../common";

const RootView = ({ filter, folder: { folderList }, downloadState, uploadState, ...other }) => {
  const classes = useRootStyles();
  const folders = folderList.filter(x => !filter || RegexFilter(filter, x.name));
  const forwardRef = focusRef(null);
  return (
    <div className={classes.wrapper}>
      <Grid container className={classes.root}>
        {folders.map(folder => (
          <Grid item key={folder.id}>
            <DocumentFolder {...{ folder, forwardRef, ...other }} />
          </Grid>
        ))}
      </Grid>
      <div className={clsx(classes.feedback, { [classes.loading]: downloadState.loading || uploadState.loading })}>
        {downloadState.loading && <ProgressButton {...downloadState} />}
        {uploadState.loading && <ProgressButton {...uploadState} upload />}
      </div>
    </div>
  );
};

RootView.propTypes = {
  filter: PropTypes.string.isRequired,
  folder: PropTypes.objectOf(PropTypes.any).isRequired,
  downloadState: PropTypes.shape(progressProps).isRequired,
  uploadState: PropTypes.shape(progressProps).isRequired
};

export default RootView;
