import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Brightness1Rounded, Search, KeyboardArrowDown } from "@material-ui/icons";
import clsx from "clsx";
import { Grid, Divider, IconButton, Button } from "@material-ui/core";
import useNotificationStyles from "./index.styles";
import { get, setRead } from "../../redux/actions/notification";
import { TextInput, DateTimeInput } from "../common/inputs";
import LinkButton from "../common/LinkButton";
import useAxios from "../common/customHooks/useAxios";

const Notifications = ({ onClose }) => {
  const notificationsByUser = useSelector(store => store.notification.notifications, shallowEqual);
  const classes = useNotificationStyles();
  const [state, setState] = useState({});
  const [search, setSearch] = useState(false);
  const { dateFrom, dateTo, keyword } = state;
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();
  const hideLoading = () => {
    // setIsLoading(false);
  };
  useEffect(() => {
    // setIsLoading(true);

    dispatch(get(hideLoading, hideLoading));
  }, []);
  useEffect(() => {
    setNotifications(notificationsByUser);
  }, [notificationsByUser]);
  const distinct = (value, index, self) => self.indexOf(value) === index;
  const dates = notifications.map(d => moment(d.date).format("M/D/YYYY")).filter(distinct);
  const notificationByDate = date => {
    return notifications.filter(n => moment(n.date).format("M/D/YYYY") === date);
  };
  const onUpdateRead = id => {
    dispatch(setRead(id, hideLoading, hideLoading));
  };
  const onClickLink = id => {
    onClose();
    onUpdateRead(id);
  };
  const notification = item => {
    const iTime = moment(new Date(item.date).getTime()).format("h:mm a");
    return (
      <div className={item.read ? classes.itemRead : classes.item}>
        <Grid container wrap="nowrap" spacing={1} alignItems="center">
          <Grid item xs>
            <div>
              <small>{iTime}</small>
            </div>
            {item.description}
            <Link to={item.route} onClick={() => onClickLink(item.id)}>
              {item.routeLabel}
            </Link>
          </Grid>
          <Grid item>
            <IconButton size="small" className={item.read ? classes.readIcon : classes.icon} onClick={() => onUpdateRead(item.id)}>
              <Brightness1Rounded style={{ fontSize: 14 }} />
            </IconButton>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </div>
    );
  };
  const onChange = ({ target: { name: n, value } }) => {
    setState(ps => {
      return { ...ps, [n]: value };
    });
  };
  const dateLabel = date => {
    return date === moment(new Date()).format("M/D/YYYY") ? "Today" : date;
  };
  const { sendRequest } = useAxios();
  const onSearch = () => {
    if (dateFrom !== undefined && dateTo !== undefined)
      sendRequest({
        request: {
          method: "POST",
          url: `/notification/search`,
          data: state
        },
        onSucces: response => {
          setNotifications(response);
        }
      });
  };

  return (
    <>
      {/* {isLoading && <Loading float />} */}
      <div className="tc-page-content">
        <Grid container alignItems="center">
          <Grid item xs>
            <Button variant="contained" color="primary" onClick={() => setSearch(ps => !ps)}>
              <Grid container alignItems="center">
                <Grid item>Search</Grid>
                <Grid item>
                  <KeyboardArrowDown className={clsx("tc-arrow", { "tc-expand": !search })} />
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid item>
            <LinkButton blue onClick={() => onUpdateRead()}>
              Mark All as Read
            </LinkButton>
          </Grid>
        </Grid>
        {search && (
          <div className={classes.searchContainer}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <DateTimeInput name="dateFrom" label="Date From" value={dateFrom} onChange={onChange} keyboard />
              </Grid>
              <Grid item xs>
                <DateTimeInput name="dateTo" label="Date To" value={dateTo} onChange={onChange} keyboard />
              </Grid>
              <Grid item xs>
                <TextInput name="keyword" label="Keywords" value={keyword} onChange={onChange} />
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={() => onSearch()}>
                  <Search />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        )}
        {dates.map(d => (
          <Grid container className="tc-p2">
            <Grid item xs={12} className={classes.title}>
              {dateLabel(d)}
            </Grid>
            <Grid item xs={12} className={classes.itemByDate}>
              {notificationByDate(d).map(n => notification(n))}
            </Grid>
          </Grid>
        ))}
      </div>
    </>
  );
};

Notifications.propTypes = {
  onClose: PropTypes.func.isRequired
};
export default Notifications;
