import { Divider, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { memo } from "react";
import ActionsFooter, { footerAction } from "../../../common/ActionsFooter";
import Confirm from "../../../common/Confirm";
import ClaimInfo from "../../../document/common/indexFile/ClaimInfo";
import { useIndexing } from "./hooks";
import IndexInfo from "./IndexInfo";

const Indexing = memo(({ actions, actions: { onApprove, onCancelIndex }, indexItem, indexItem: { claim }, ...props }) => {
  const {
    confirmProps,
    actions: { onReindex, onDelete },
    fileViewer,
    loading,
    access
  } = useIndexing(indexItem, actions);
  return (
    <div className="tc-page-container">
      <div className="tc-page-content tc-no-padding">
        <Grid container wrap="nowrap" style={{ height: "100%" }}>
          <Grid item xs={7}>
            {fileViewer}
          </Grid>
          <Grid item xs={5} style={{ backgroundColor: "#ffffff" }}>
            <div className="tc-page-container">
              <div className="tc-page-content">
                <ClaimInfo {...{ claim }} />
                <Divider className="tc-mb2" />
                <IndexInfo {...{ actions, indexItem, ...props }} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <ActionsFooter
        shadow
        actions={[
          footerAction({ id: "cancel", primary: true, outlined: true, text: "Cancel", onClick: onCancelIndex, disabled: loading }),
          footerAction({
            id: "indexing",
            isBtnMenu: true,
            menuName: "Routing Options",
            primary: false,
            outlined: true,
            subMenues: [
              { id: 1, text: "Return to Indexing" },
              { id: 2, text: "Delete" }
            ],
            menuId: "schedulePaymentTypes",
            onClick: option => {
              if (option.id === 1 && access.update) onReindex();
              if (option.id === 2 && access.delete) onDelete();
            }
          }),
          footerAction({ id: "approve", primary: true, text: "Approve Document", onClick: onApprove, disabled: loading || undefined })
        ]}
      />
      <Confirm {...confirmProps} />
    </div>
  );
});

Indexing.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  indexItem: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Indexing;
