import { SvgIcon } from "@material-ui/core";
import { DirectionsCar, Home } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import BodilyInjury from "../../assets/bodily_injury.svg";

const LossTypeGroupIcon = ({ type, ...props }) => {
  switch (type) {
    case "Vehicle Damage":
      return <DirectionsCar {...props} />;
    case "Property Damage":
      return <Home {...props} />;
    case "Bodily Injury":
      return (
        <SvgIcon {...props}>
          <BodilyInjury />
        </SvgIcon>
      );
    default:
      return null;
  }
};

LossTypeGroupIcon.propTypes = { type: PropTypes.string };

LossTypeGroupIcon.defaultProps = { type: undefined };

export default LossTypeGroupIcon;
