import { Grid } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const CustomBadge = ({ css, cssTitle, children }) => (
  <Grid container alignItems="center" wrap="nowrap">
    <span className={clsx("tc-badge", css)} />
    {children && (
      <Grid item className={clsx("tc-pl1", cssTitle)}>
        {children}
      </Grid>
    )}
  </Grid>
);

CustomBadge.propTypes = {
  css: PropTypes.string.isRequired,
  cssTitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

CustomBadge.defaultProps = { cssTitle: null, children: null };

export default CustomBadge;
