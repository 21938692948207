import { INITIALREPORT as REPORT, API } from "../../../actions/actionTypes";
import { getSuccess, setSuccess } from "../../../actions/initialReport/damagedProperty";
import { takeLatest } from "../../common/sagaGenerator";

const url = `${API.URL}/claim/propertyDamaged`;

export default [
  takeLatest({
    type: REPORT.INFORMATION.PROPERTY.GET,
    actionSuccess: getSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading Damaged Property",
    url
  }),
  takeLatest({
    type: REPORT.INFORMATION.PROPERTY.SET,
    actionSuccess: setSuccess,
    method: "POST",
    params: ({ property }) => property,
    successMessage: "Property Damaged Updated",
    errorMessage: "Error updating Propertyt",
    url
  })
];
