import { Tab } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { getUserAccess, useRouteParam } from "../../common";
import BreadCrumbsNavigation from "../../components/common/breadCrumbs/BreadCrumbsNavigation";
import { LoadingContextProvider, useLoadingContext } from "../../components/common/Loading";
import SimpleTabs from "../../components/common/tabs/SimpleTabs";
import VendorSideBar from "../../components/vendors/sidebar/Sidebar";
import { loadVendor, clear, getFinancialInfo } from "../../redux/actions/vendor";
import Routes, { routes } from "./Routes";
import ActionsAllowed from "../../components/common/contexts/ActionsAllowed";

const VendorPageWrapper = () => {
  const { url, isExact } = useRouteMatch();
  const { pathname } = useLocation();
  const vendorId = useRouteParam("id");
  const store = useSelector(({ profile, vendor }) => ({ profile, vendor }), shallowEqual);

  const { profile: userProfile, vendor: vendorInfo } = store || {};
  const basicInfo = vendorInfo.profile?.basicInformation || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const { breadcrumb, Loading, padding } = useLoadingContext();
  useEffect(() => {
    dispatch(getFinancialInfo(vendorId));
    if (basicInfo.id !== vendorId) dispatch(loadVendor(vendorId));
    return () => dispatch(clear);
  }, [vendorId]);
  const pending = basicInfo.statusId !== 1;
  const routesAllowed = [];
  const tabs = routes.reduce((acc, [route, label, , isPending]) => {
    if (!userProfile.securityProfilesDetails?.length || basicInfo.id !== vendorId) return acc;
    if (pending && !isPending) return acc;
    if (route === "locations" && !basicInfo.hasMultipleLocations) return acc;
    const access = getUserAccess(
      userProfile.securityProfilesDetails,
      `VENDORS.${route !== "locations" ? route.toUpperCase() : "LIST"}`,
      null,
      basicInfo.location?.stateId,
      null
    );
    if (access.read) return [...acc, { route, label, to: `${url}/${route}`, value: `${url}/${route}` }];
    routesAllowed.push({ ...access, route });
    return acc;
  }, []);
  useEffect(() => {
    if (isExact && tabs.length) history.replace(`${url}/${tabs[0].route}`);
  }, [isExact, tabs.length]);
  const showSidebar = !pending && !isExact && pathname.indexOf("overview") === -1;
  const title = tabs.length ? `Vendors${pending ? ": Pending Investigation" : ""}` : undefined;
  const value = (() => {
    if (!tabs.some(({ route }) => `${url}/${route}` === pathname)) return false;
    if (url === pathname) return `${url}/overview`;
    return pathname;
  })();
  const bcLabel = tabs.find(({ route }) => `${url}/${route}` === value)?.label || "";
  const breadcrumbs = {
    breadData: {
      linkTitle: `VENDORS${pending ? ": PENDING INVESTIGATION" : ""}`,
      to: `/vendors/${pending ? "pending" : "vendors"}`
    },
    activeTitle: `${basicInfo.fullName || ""}${basicInfo.fullName ? ": " : ""}${bcLabel}`
  };
  return (
    <div className="tc-flex tc-flex-nowrap tc-full-height">
      <div className="tc-page-container tc-flex-grow">
        {tabs.length > 0 && (
          <SimpleTabs {...{ title, value }}>
            {tabs.map(({ route, label, to, value: val }) => (
              <Tab key={route} {...{ label, to }} value={val} component={Link} />
            ))}
          </SimpleTabs>
        )}
        {Loading}
        <div className={clsx("tc-page-content", { "tc-no-padding": !padding })}>
          {breadcrumb && value && <BreadCrumbsNavigation min gutterBottom {...breadcrumbs} />}
          <ActionsAllowed.Provider value={routesAllowed}>
            <Routes {...{ tabs }} />
          </ActionsAllowed.Provider>
        </div>
        <div id="vendorFooter" />
      </div>
      {showSidebar && <VendorSideBar />}
    </div>
  );
};

const VendorPage = () => {
  const [values, setState] = useState({ padding: true, breadcrumb: true });
  const actions = {
    handlePadding: useCallback(padding => setState(s => ({ ...s, padding })), []),
    handleBreadcrumb: useCallback(breadcrumb => setState(s => ({ ...s, breadcrumb })), [])
  };
  return (
    <LoadingContextProvider float {...{ values, actions }}>
      <VendorPageWrapper />
    </LoadingContextProvider>
  );
};

export default VendorPage;
