import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@material-ui/core";

const styles = makeStyles(theme => ({
  root: {
    listStyle: "none",
    padding: 0,
    "& > li": {
      position: "relative",
      "& > div": {
        padding: theme.spacing(0.25, 0.25, 0.25, 2),
        backgroundColor: "#F4F8F9",
        borderRadius: theme.spacing(0.5)
      },
      "&:before": {
        content: '""',
        position: "absolute",
        width: theme.spacing(1),
        height: theme.spacing(1),
        borderRadius: "50%",
        backgroundColor: "#107A98",
        top: theme.spacing(-0.5),
        left: theme.spacing(-0.5)
      }
    },
    "& > li:not(:last-of-type)": {
      paddingBottom: theme.spacing(1),
      borderLeft: "dashed 0.5px #107A98"
    }
  },
  label: { fontSize: 11, color: "#898F91", paddingRight: 4 }
}));

const Timeline = ({ timeline }) => {
  const classes = styles();
  return (
    <ul className={classes.root}>
      {timeline.map(({ id: i, label, date }) => (
        <li key={i}>
          <div>
            <span className={classes.label}>{`${label}:`}</span>
            {(date && moment(date).format("M/D/YYYY")) || "--/--/----"}
          </div>
        </li>
      ))}
    </ul>
  );
};

Timeline.propTypes = {
  timeline: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), label: PropTypes.string, date: PropTypes.string })
  ).isRequired
};

export default Timeline;
