import { Grid } from "@material-ui/core";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import LabelValue from "../../../../../common/LabelValue";

const AggregateRead = () => {
  const aggregateDetails = useSelector(
    ({
      client: {
        policy: {
          insuredPolicy: { aggregateDetails: d }
        }
      }
    }) => d,
    shallowEqual
  );

  const { attachmentBasis, longRunFrom, longRunTo, premium, attachmentPoint, maximumRetention, limit, retentionDeductible } = aggregateDetails || {};
  return (
    <Grid container spacing={4}>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <LabelValue label="AttachmentBasis" value={attachmentBasis} />
        <Grid container>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <LabelValue label="LR From" value={longRunFrom} isInteger />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <LabelValue label="LR To" value={longRunTo} isInteger />
          </Grid>
        </Grid>
        <LabelValue label="Premium" value={premium} isMoney />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <LabelValue label="Attachment" value={attachmentPoint} isMoney />
        <LabelValue label="Maximum Retention" value={maximumRetention} isMoney />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <LabelValue label="Aggregate Limit" value={limit} isMoney />
        <LabelValue label="Agg. Retention/ Deducible" value={retentionDeductible} isMoney />
      </Grid>
    </Grid>
  );
};

export default AggregateRead;
