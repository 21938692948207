import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../common/CollapsibleBox";
import InsuredViewMode from "../../common/claims/insuredVehicle/ViewMode";
import InsuredVehicleForm from "../../common/claims/insuredVehicle/Form";
import { useRouteParam } from "../../../common";
import { get, set } from "../../../redux/actions/initialReport/insuredVehicle";
import ActionsAllowedContext from "../../common/contexts/ActionsAllowed";

export default props => {
  const claimId = useRouteParam("claimId");
  const store = useSelector(({ initialReport: { information } }) => information, shallowEqual);
  const { insuredVehicle } = store || {};
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info");
  const {
    props: { boxProps, editMode, loading: isLoading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  const dispatch = useDispatch();
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  const handleSave = data => {
    if (access.update) {
      showLoading();
      dispatch(set(data, onSuccessEdit, hideLoading));
    }
  };
  return (
    <CollapsibleBox title="Insured Vehicle" name="insuredV" {...boxProps} {...props}>
      {Loading}
      {editMode ? (
        <InsuredVehicleForm {...{ insuredVehicle, onClose: onToggleEdit, onSave: handleSave, isLoading }} />
      ) : (
        <InsuredViewMode {...{ insuredVehicle }} />
      )}
    </CollapsibleBox>
  );
};
