import { Grid, Paper, IconButton, Chip } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import * as yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { array, useRouteParam } from "../../../../common";
import { isEmail } from "../../../../common/yup";
import { openFile } from "../../../../redux/actions/claim/documents";
import ActionsFooter, { footerAction } from "../../../common/ActionsFooter";
import useCustomConfirm from "../../../common/customHooks/useCustomConfirm";
import FileIcon from "../../../common/documents/FileIcon";
import { BaseEditorInput, SelectInput, TextInput } from "../../../common/inputs";
import TagInput from "../../../common/inputs/TagInput2";
import { useLoadingContext } from "../../../common/Loading";
import Popper from "../../../common/Popper";
import useViewer from "../../../common/viewer/useViewer";
import FolderTree from "./FolderTree";
import { useCommStyles } from "./index.styles";
import useYupValidationSchema from "../../../common/customHooks/useYupValidationSchema";

const findTemplates = (templates, communicationTypeId) =>
  array(templates).reduce((a, { id, name, body, types, attachments }) => {
    if (!array(types).some(t => t.id === communicationTypeId)) return a;
    return [...a, { id, text: name, body, attachments }];
  }, []);

const Communication = props => {
  const store = useSelector(
    ({
      common: { states },
      claim: {
        communication: { claimEmails },
        documents
      }
    }) => ({ states, claimEmails, documents }),
    shallowEqual
  );
  const { documents, states, claimEmails } = store;
  const claimId = useRouteParam("claimId");
  const [communication, setCommunication] = useState({ body: "" });
  const [initialLetterStatusId, setInitialLetterStatusId] = useState(null);
  const [, setTagData] = useState([]);
  const { handleCommunication, onSave, isLetter, communicationDetail, isEdit, communicationType, templates, isLoading, access } = props;
  const typeTemplates = findTemplates(templates, communicationType?.id);
  const { handleValidateSchema, validationMessages: error } = useYupValidationSchema(
    yup.object().shape({
      body: yup
        .string()
        .nullable()
        .required("Body is Required"),
      subject: yup
        .string()
        .nullable()
        .required("Subject is Required"),
      sentTo: yup
        .array()
        .nullable()
        .of(yup.string())
        .test("", "To is Required", (v, c) => {
          if (!Array.isArray(v) || !v.length) return false;
          if (v.some(e => !isEmail(e))) return c.createError({ message: "Some emails are invalid" });
          return true;
        })
    })
  );
  const handleSendCommunication = () =>
    onSave({
      ...communication,
      sentTo: !isLetter ? array(communication.sentTo).join(",") : "",
      isEmail: !isLetter
    });

  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(
    handleSendCommunication,
    "Cancel",
    `Yes, ${!isLetter ? "Send" : "Print"}`
  );
  useEffect(() => {
    if (!!claimEmails.length && !isEdit) setTagData(claimEmails);
    if (!!claimEmails.length && isEdit && communication.sentTo) {
      setTagData(communication.sentTo.split(",").map(item => ({ email: item })));
    }
  }, [claimEmails, communication]); // TODO: check claim emails functionality
  useEffect(() => {
    if (isEdit) {
      setCommunication(communicationDetail);
      setInitialLetterStatusId((communicationDetail || {}).letterStatusId);
    }
  }, []);
  const classes = useCommStyles();
  const {
    sentTo,
    attachedFileIds,
    body,
    subject,
    letterMailingAddress,
    letterStateId,
    letterCity,
    templateId,
    letterZipCode,
    trackingCode,
    shippingCompany,
    letterStatusId
  } = communication;
  const allFiles = array(!isLetter && claimId === documents.claimId && documents.allFiles);
  const onChange = ({ target: { name, value } }) =>
    setCommunication(s => {
      let ext = {};
      if (name === "templateId") {
        const templateSelected = typeTemplates.find(t => t.id === value);
        ext = {
          body: templateSelected?.body || s.body,
          attachedFileIds: [
            ...array(s.attachedFileIds).filter(id => allFiles.some(all => all.id === id)),
            ...array(templateSelected?.attachments).map(a => a.id)
          ]
        };
      }
      handleValidateSchema({ ...s, [name]: value, ...ext });
      return { ...s, [name]: value, ...ext };
    });

  const handleSendConfirm = () => {
    if (!handleValidateSchema(communication).isValid) return false;
    return handleCustomConfirm({
      titleText: isLetter ? "Letter" : "Email",
      messageText: `Are you sure you want to ${isLetter ? "print" : "send"} this ${isLetter ? "Letter" : "Email"}?`
    });
  };

  const disabledLocation = initialLetterStatusId === 3 && isEdit;

  const showButtonSave = (isEdit && isLetter) || !isEdit;
  const onDeleteFile = id => setCommunication(c => ({ ...c, attachedFileIds: array(c.attachedFileIds).filter(i => i !== id) }));
  const attachedFiles = isEdit
    ? array(communication.attachedFiles)
    : array(attachedFileIds).reduce((a, attId) => {
        let att = allFiles.find(f => f.id === attId);
        if (!att) {
          att = typeTemplates.reduce((a2, t) => {
            if (t.id !== templateId) return a2;
            const att2 = array(t.attachments).find(at => at.id === attId);
            if (!att2) return a2;
            return { ...att2, communicationTemplateId: t.id };
          }, undefined);
          if (!att) return a;
        }
        return [...a, att];
      }, []);
  const attRef = useRef(null);
  const [{ attOpen, initOpen }, setAttOpen] = useState({ attOpen: false, initOpen: false });
  useEffect(() => {
    if (attOpen && !initOpen) setAttOpen(ps => ({ ...ps, initOpen: true }));
  }, [attOpen, initOpen]);
  const onAttTogle = () => setAttOpen(ps => ({ ...ps, attOpen: !ps.attOpen }));
  const onClickAway = event => {
    if (attRef.current && attRef.current.contains(event.target)) return false;
    return setAttOpen(ps => ({ ...ps, attOpen: false }));
  };
  const filesTree = isLetter || claimId !== documents.claimId ? { fileList: [], folderList: [] } : documents.tree;
  const onAttChange = ({ target: { value, checked } }) =>
    setCommunication(c => ({
      ...c,
      attachedFileIds: [...(Array.isArray(c.attachedFileIds) ? c.attachedFileIds : []).filter(x => x !== value), ...(checked ? [value] : [])]
    }));
  const dispatch = useDispatch();
  const {
    actions: { showLoading, hideLoading }
  } = useLoadingContext();
  const { onViewFile, fileViewer } = useViewer({
    dispatchAction: d => {
      showLoading();
      return dispatch(openFile({ ...d, onSuccess: hideLoading, onError: hideLoading }));
    }
  });
  const onClickFile = f => () =>
    onViewFile(f.id, {
      ...(isEdit ? { communicationDetailId: communication.id } : {}),
      ...(!isEdit && f.communicationTemplateId ? { communicationTemplateId: f.communicationTemplateId } : {}),
      ...(!isEdit && !f.communicationTemplateId ? { claimId } : {})
    });
  return (
    <>
      <Paper className="tc-box-content tc-mb2">
        {!isLetter ? (
          <>
            <TagInput
              name="sentTo"
              value={sentTo}
              label="To"
              onChange={isEdit ? undefined : onChange}
              validationMessage="Invalid Email"
              error={error.sentTo}
              disabled={isEdit}
              onValidate={isEmail}
            />
            <Grid container spacing={3}>
              {!isEdit && !!typeTemplates.length && (
                <Grid item xs={12} md={5}>
                  <SelectInput
                    allowClear
                    name="templateId"
                    label="Communication Template"
                    value={templateId}
                    options={typeTemplates}
                    onChange={onChange}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={isEdit || !typeTemplates.length ? 12 : 7}>
                <TextInput name="subject" label="Subject" value={subject} onChange={onChange} disabled={isEdit} error={error.subject} />
              </Grid>
            </Grid>
            <Grid container spacing={2} className={clsx({ "tc-mb1": !isEdit || !!attachedFiles.length })} wrap="nowrap">
              <Grid item xs container spacing={1}>
                {attachedFiles.map(att => (
                  <Grid item key={att.id}>
                    <Chip
                      label={att.fileName}
                      icon={<FileIcon file type={att.fileType} />}
                      onDelete={isEdit ? undefined : () => onDeleteFile(att.id)}
                      onClick={onClickFile(att)}
                    />
                  </Grid>
                ))}
              </Grid>
              {!isEdit && (
                <Grid item>
                  <IconButton ref={attRef} size="small" onClick={onAttTogle}>
                    <AttachFile />
                  </IconButton>
                  <div className={clsx(classes.attPopperContainer, { [classes.attPopperContainerInitial]: !initOpen })}>
                    <Popper
                      open={attOpen}
                      {...{ onClickAway }}
                      anchorEl={attRef.current}
                      placement="left-start"
                      transformOrigin="right top"
                      keepMounted
                    >
                      <div className={classes.attTreeContainer}>
                        <FolderTree tree={filesTree} onChange={onAttChange} {...{ attachedFileIds, classes }} />
                      </div>
                    </Popper>
                  </div>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={3}>
              {!isEdit && (
                <Grid item xs>
                  <SelectInput name="templateId" label="Communication Templates" value={templateId} options={typeTemplates} onChange={onChange} />
                </Grid>
              )}
              <Grid item xs>
                <TextInput name="subject" label="Subject" value={subject} onChange={onChange} disabled={isEdit} />
              </Grid>
              <Grid item xs>
                <SelectInput
                  name="letterStatusId"
                  label="Status"
                  value={letterStatusId}
                  options={[
                    { id: 1, text: "Printed" },
                    { id: 2, text: "Pending delivery" },
                    { id: 3, text: "Letter Sent" }
                  ]}
                  onChange={onChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs>
                <TextInput
                  name="letterMailingAddress"
                  label="Mailing Address"
                  value={letterMailingAddress}
                  onChange={onChange}
                  withActionButton
                  disabled={disabledLocation}
                />
              </Grid>
              <Grid item xs>
                <SelectInput
                  name="letterStateId"
                  label="State"
                  value={letterStateId}
                  options={states}
                  onChange={onChange}
                  disabled={disabledLocation}
                />
              </Grid>
              <Grid item xs>
                <TextInput name="letterCity" label="City" value={letterCity} onChange={onChange} disabled={disabledLocation} />
              </Grid>
              <Grid item xs>
                <TextInput name="letterZipCode" label="Zip Code" value={letterZipCode} onChange={onChange} disabled={disabledLocation} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs>
                <TextInput name="trackingCode" label="Tracking Code" value={trackingCode} onChange={onChange} disabled={disabledLocation} />
              </Grid>
              <Grid item xs>
                <TextInput name="shippingCompany" label="Shipping Company" value={shippingCompany} onChange={onChange} disabled={disabledLocation} />
              </Grid>
            </Grid>
          </>
        )}
        <BaseEditorInput value={body} name="body" onChange={onChange} readOnly={isEdit} claimMergeTags error={error.body} />
        <ActionsFooter
          nopadding
          className={classes.actionsFooter}
          actions={[
            footerAction({ id: "cancel", outlined: true, primary: true, text: "Cancel", onClick: () => handleCommunication(false) }),
            ...(showButtonSave && (access.create || access.update)
              ? [
                  footerAction({
                    id: "save",
                    primary: true,
                    text: isLetter ? "Save" : "Send",
                    onClick: isEdit ? handleSendCommunication : handleSendConfirm,
                    disabled: isLoading
                  })
                ]
              : [])
          ]}
        />
      </Paper>
      <Confirm />
      {fileViewer}
    </>
  );
};

Communication.propTypes = {
  handleCommunication: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isLetter: PropTypes.bool.isRequired,
  communicationDetail: PropTypes.shape({}),
  isEdit: PropTypes.bool,
  communicationType: PropTypes.shape({}),
  templates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  access: PropTypes.shape({ create: PropTypes.bool, update: PropTypes.bool }).isRequired,
  isLoading: PropTypes.bool.isRequired
};

Communication.defaultProps = {
  communicationDetail: {},
  communicationType: {},
  isEdit: false
};

export default Communication;
