import { Collapse, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { array } from "../../../common";
import Confirm from "../Confirm";
import { CustomSelect } from "../inputs";
import LinkButton from "../LinkButton";
import { FileNameIcon } from "./FileIcon";

const initState = { error: false, folderId: null, step: 0, details: false };

const reduceFolders = (folderList, folder) =>
  array(folderList).reduce((a, f) => {
    if (f.isQueue) return a;
    return [...a, { id: f.id, name: f.name, folders: reduceFolders(f.folderList, folder), disabled: folder?.id === f.id }];
  }, []);

export const findFolder = (data, id) => {
  let f = data.folders.find(x => x.id === id);
  for (let i = 0; i < data.folders.length && !f; i += 1) {
    f = findFolder(data.folders[i], id);
  }
  return f;
};

const MoveFilesDialog = memo(({ downloadArray, folder, open, onClose, onMoveFiles, documents: { tree, allFiles } }) => {
  const downloadFiles = downloadArray.reduce((a, f) => {
    const file = allFiles.find(x => x.id === f.id);
    if (!file) return a;
    return [...a, file];
  }, []);
  const folders = reduceFolders(tree.folderList, folder);
  const [{ error: err, folderId, folderName, step, details }, setState] = useState(initState);

  useEffect(() => {
    if (open) setState(initState);
  }, [open]);
  const formatOption = (o, level = 0) => {
    const onClick = () => setState(ps => ({ ...ps, folderId: o.id, folderName: o.name, error: false }));
    return (
      <div key={o.id}>
        <MenuItem disabled={o.disabled} selected={folderId === o.id} {...{ onClick }} style={{ paddingLeft: 29 * level + 16 }}>
          <FileNameIcon name={o.name} />
        </MenuItem>
        {o.folders.map(f => formatOption(f, level + 1))}
      </div>
    );
  };
  const formatValue = id => findFolder({ folders }, id)?.name || "";
  const error = err ? "Folder Required" : undefined;
  const confirmProps = {
    title: "Move Files",
    messageText: (step === 0 && (
      <>
        <p> Select the destination Folder </p>
        <CustomSelect label="Destination Folder" value={folderId} options={folders} {...{ formatOption, formatValue, error }} />
      </>
    )) || (
      <>
        <p>
          {`You are about to move ${downloadFiles.length} file(s) to the folder `}
          <b>{`${folderName}.`}</b>
        </p>
        <p>Are you sure you want to proceed?</p>
        <small>
          <LinkButton blue className="tc-mb1" onClick={() => setState(ps => ({ ...ps, details: !ps.details }))}>
            {`${details ? "Hide" : "Show"} details`}
          </LinkButton>
        </small>
        <Collapse in={details}>
          {downloadFiles.map(f => (
            <div key={f.id}>
              <FileNameIcon file type={f.fileType} name={f.fileName} />
            </div>
          ))}
        </Collapse>
      </>
    ),
    onAction: event => {
      event.preventDefault();
      if (step === 0) return setState(ps => ({ ...ps, error: !folderId, step: folderId ? 1 : 0 }));
      return onMoveFiles({ folderId, fileIds: downloadFiles.map(f => f.id) });
    },
    openDialog: open,
    onToggleConfirm: onClose,
    okText: "Move Files"
  };
  return <Confirm {...confirmProps} />;
});

MoveFilesDialog.propTypes = {
  downloadArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  folder: PropTypes.objectOf(PropTypes.any),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  documents: PropTypes.objectOf(PropTypes.any).isRequired,
  onMoveFiles: PropTypes.func.isRequired
};

MoveFilesDialog.defaultProps = { folder: undefined };

export default MoveFilesDialog;
