import { makeStyles } from "@material-ui/core";

const styles = makeStyles(theme => ({
  label: { color: "#004763", fontSize: 16, marginBottom: theme.spacing(2.75) },
  paper: { padding: theme.spacing(2.75), marginBottom: theme.spacing(2.75) },
  title: { marginBottom: theme.spacing(1.5) },
  number: { width: theme.spacing(4), height: theme.spacing(4), color: "#FFFFFF", backgroundColor: "#004763" },
  coverageType: {
    display: "inline-flex",
    position: "relative",
    backgroundColor: "#DAEAEF",
    justifyContent: "center",
    alignItems: "center",
    height: 24,
    padding: theme.spacing(0, 1),
    borderRadius: 4,
    top: -1
  },
  nameContainer: { alignItems: "center", lineHeight: 1, marginBottom: theme.spacing(1.5) },
  name: { fontSize: 16, fontWeight: 500 },
  createAs: {
    padding: theme.spacing(1, 2.75),
    backgroundColor: "#D2EEB6",
    borderRadius: 4,
    marginBottom: theme.spacing(1.25)
  },
  error: { backgroundColor: "#FCB7B0", "& > .tc-title": { color: "#E74C3C" } },
  radio: { flexDirection: "row", flexWrap: "nowrap" },
  users: {
    padding: theme.spacing(1, 2.75, 0, 2.75),
    backgroundColor: "#F3F3F3",
    borderRadius: 4
  },
  usersDisabled: { "& > .tc-title": { color: "#898F91" } },
  details: { justifyContent: "space-between", paddingRight: theme.spacing(3) },
  subtitle: { color: "#7F7F7F", fontSize: 11 },
  link: { height: "0.5em", color: "#107A98", marginRight: theme.spacing(1) },
  count: {
    display: "inline-flex",
    position: "relative",
    backgroundColor: "#DAEAEF",
    justifyContent: "center",
    alignItems: "center",
    height: 24,
    padding: theme.spacing(0, 1),
    borderRadius: 4,
    top: -2,
    fontSize: 14
  },
  location: { alignItems: "center", marginBottom: theme.spacing(2), marginLeft: -9 },
  docsAttorney: { alignItems: "center", lineHeight: 1 }
}));

const customRadioStyles = makeStyles(() => ({ label: { fontSize: 14 } }));

export { styles, customRadioStyles };
