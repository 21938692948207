import { Divider, Grid, MenuItem, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { API } from "../../../../redux/actions/actionTypes";
import { SelectInput, DateTimeInput, MoneyInput } from "../../../common/inputs";
import LabelValue from "../../../common/LabelValue";
import Search from "../../../common/SearchSuggestions/InputSuggestionsDebounced";
import ClaimInfo from "./ClaimInfo";
import Tooltip from "./Tooltip";
import UsersSelect from "./UsersSelect";

const IndexInfo = ({ actions, claim, error, warning }) => {
  const store = useSelector(
    ({
      admin: {
        claimSettings: { reserveTypes: rt }
      }
    }) => rt,
    shallowEqual
  );
  const reserveTypes = (store || [])
    .filter(t => !["claimLineOfBusinessId", "claimLossTypeGroupId"].some(p => t[p] !== claim[p]))
    .sort((a, b) => a.name.localeCompare(b.name));
  const types = [
    { id: 1, text: "Bill" },
    { id: 2, text: "Document" }
  ];
  const { onChange, onChangePayee, onChangeType, onSelectPayee: onSelect, onFocus } = actions;
  const inputProps = (name, label, isPayee, isSeach) => {
    let prs = { name, label, value: claim[name], onChange, error: error[name] };
    if (!isPayee) return prs;
    prs = { ...prs, value: claim.payee[name], onChange: onChangePayee, error: error[`payee.${name}`] };
    if (!isSeach) return prs;
    return {
      ...prs,
      onSelect,
      url: `${API.URL}/bill/getAllvendor`,
      onFocus: onFocus(true),
      onLostFocus: onFocus(false),
      inputClassName: warning ? "tc-color-red" : undefined,
      endAdornment: warning && <Tooltip title={error["payee.id"]} tooltip={name === "name"} />
    };
  };
  const mapFolders = folders => {
    return [
      ...folders.reduce(
        (a1, f1) => [
          ...a1,
          { ...f1, level: f1.level || 0 },
          ...mapFolders(claim?.folders.filter(ff => ff.folderId === f1.id).map(ff => ({ ...ff, level: (f1.level || 0) + 1 })))
        ],
        []
      )
    ];
  };

  const tree = mapFolders(claim?.folders.filter(f => f.folderId === null));

  const folders = tree.map(({ id, name, level }) => ({
    id,
    text: name,
    level
  }));

  const mapReserveTypes = reserveTypeList => {
    return [
      ...reserveTypeList.reduce(
        (acc, rt) => [
          ...acc,
          { ...rt, level: rt.level || 0 },
          ...mapReserveTypes(rt.reserveTypes.map(srt => ({ ...srt, level: (rt.level || 0) + 1 })))
        ],
        []
      )
    ];
  };

  const treeReserveTypes = mapReserveTypes(reserveTypes);

  return (
    <div className="tc-page-content">
      <ClaimInfo {...{ claim }} />
      <Divider className="tc-mb2" />
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography className="tc-title" gutterBottom>
            Document Type
          </Typography>
          <SelectInput {...{ ...inputProps("indexTypeId"), onChange: onChangeType }} options={types} />
        </Grid>
        {claim.indexTypeId === 1 && (
          <Grid item xs={6}>
            <Typography className="tc-title" gutterBottom>
              Bill Type
            </Typography>
            <SelectInput
              {...inputProps("billTypeId")}
              options={treeReserveTypes}
              renderOption={option => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  style={{ paddingLeft: `${(option.level ?? 0) * 8 + 8}px` }}
                  disabled={option.level === 0 && option.reserveTypes.length > 0}
                >
                  {option.name}
                </MenuItem>
              )}
            />
          </Grid>
        )}
        {claim.indexTypeId === 2 && (
          <Grid item xs={6}>
            <Typography className="tc-title" gutterBottom>
              Claim Folder
            </Typography>
            <SelectInput
              {...inputProps("folderId")}
              options={folders}
              renderOption={option => (
                <MenuItem key={option.id} value={option.id} style={{ paddingLeft: `${(option.level ?? 0) * 8 + 8}px` }}>
                  {option.text}
                </MenuItem>
              )}
            />
          </Grid>
        )}
      </Grid>
      {claim.indexTypeId === 1 && (
        <>
          <Divider className="tc-mb2" />
          <Typography className="tc-title" gutterBottom>
            Bill Information
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <MoneyInput {...inputProps("amount", "Bill Amount", true)} />
              <DateTimeInput {...inputProps("serviceFrom", "Date of Service", true)} keyboard />
              <DateTimeInput {...inputProps("serviceTo", "Service Through", true)} keyboard />
            </Grid>
            <Grid item xs={6}>
              <Search {...inputProps("name", "Payee Name", true, true)} />
              <Search {...inputProps("taxID", "Payee TAX ID", true, true)} />
              <LabelValue label="Address" value={claim.payee} isShortAddress />
            </Grid>
          </Grid>
        </>
      )}
      <Divider className="tc-mb2" />
      <Typography className="tc-title">Diaries Assignments</Typography>
      <div className="tc-label tc-shrink">A diary for document review will be assigned to the following users:</div>
      <UsersSelect {...inputProps("userIds")} {...{ claim }} />
    </div>
  );
};

IndexInfo.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  claim: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])).isRequired,
  warning: PropTypes.bool.isRequired
};

export default IndexInfo;
