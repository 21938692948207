import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { CheckboxLabel, SearchLabelInput } from "../common/inputs";
import styles from "./Associate.styles";
import { API } from "../../redux/actions/actionTypes";
import { api } from "../../api/apiUtils";
import { associateCatastrophe as associate } from "../../redux/actions/catastropheAction";
import CollapsibleClaimList from "../claims/claim_list/CollapsibleClaimList";
import CollapsibleBox from "../common/CollapsibleBox";
import useCustomConfirm from "../common/customHooks/useCustomConfirm";
import { useDebounce } from "../../common";
import useYupValidationSchema from "../common/customHooks/useYupValidationSchema";

const AssociateClaim = ({ filter }) => {
  const classes = styles();
  const [filterClaimSearch, setClaimSearch] = useState({
    sameDol: true,
    sameCity: true,
    sameState: true,
    sameInsured: false,
    searchText: ""
  });

  const [claims, setClaims] = useState([]);

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const searchClaims = useDebounce(() => {
    const { date: dateOfLoss, locationList = [], id = 0, multipleLocation, dateTo } = filter || {};

    const singleLocationData = () => {
      const data =
        Array.isArray(locationList) && locationList.length > 0 && !multipleLocation
          ? locationList
              .map(l => {
                let cityData = filterClaimSearch.sameCity ? l.city : null;
                let stateData = filterClaimSearch.sameState ? l.stateId : null;
                if (l.city === null || l.city === "") {
                  cityData = filterClaimSearch.sameCity ? "-1" : null;
                }
                if (l.stateId === null) {
                  stateData = filterClaimSearch.sameState ? -1 : null;
                }
                return { city: cityData, stateId: stateData };
              })
              .find(Boolean)
          : {
              stateId: null,
              city: null
            };
      return {
        city: data.city,
        stateId: data.stateId
      };
    };

    const multipleLocationData = () => {
      const emptyLocation =
        Array.isArray(locationList) && locationList.length === 0
          ? [{ stateId: filterClaimSearch.sameState ? -1 : null, city: filterClaimSearch.sameCity ? "-1" : null }]
          : [];
      return locationList.length !== 0
        ? locationList.reduce((acc, item) => {
            let cityData = filterClaimSearch.sameCity ? item.city : null;
            let stateData = filterClaimSearch.sameState ? item.stateId : null;
            if (item.city === null || item.city === "") {
              cityData = filterClaimSearch.sameCity ? "-1" : null;
            }
            if (item.stateId === null) {
              stateData = filterClaimSearch.sameState ? -1 : null;
            }
            return [
              ...acc,
              {
                city: filterClaimSearch.sameCity && filter ? cityData : null,
                stateId: filterClaimSearch.sameState && filter ? stateData : null
              }
            ];
          }, [])
        : emptyLocation;
    };

    const values = {
      ...filterClaimSearch,
      dateOfLoss,
      dateTo,
      rangeDate: true,
      ...singleLocationData(),
      locationList: multipleLocationData(),
      catastropheId: id
    };

    if (!filterClaimSearch.sameState && !filterClaimSearch.sameCity && !filterClaimSearch.sameDol) {
      if (filterClaimSearch.searchText.length > 2) {
        api({ method: "POST", url: `${API.URL}/claim/searchF`, data: values }).then(data => setClaims(data));
      }
    } else api({ method: "POST", url: `${API.URL}/claim/searchF`, data: values }).then(data => setClaims(data));
  }, 500);

  useEffect(() => {
    searchClaims();
  }, [filter, filterClaimSearch]);

  const onChangeSearchClaim = event => {
    const { name, value } = event.target;
    setClaimSearch(prevSearchClaim => ({
      ...prevSearchClaim,
      [name]: value
    }));
    // if (name !== "searchText") searchClaims();
  };

  const onToggleCl = name => {
    setClaims(prevState => prevState.map(obj => (obj.number === name ? Object.assign(obj, { open: !obj.open }) : obj)));
  };

  const associateClaim = claim => {
    const { id } = filter || {};
    dispatch(associate({ catastropheId: id, type: "Claim", id: claim.id, isRemove: false }));
  };

  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(associateClaim, "Cancel", "Yes, Associate");

  const handleConfirmAssociate = claim => {
    handleCustomConfirm({
      oData: claim,
      titleText: `Associate Claim`,
      messageText: `The following Claim will be associated to this Catastrophe: ${claim.number}. Are you sure you want to proceed?`
    });
  };

  const onToggle = () => {
    setOpen(!open);
  };

  const { handleValidateSchema, validationMessages } = useYupValidationSchema(
    yup.object().shape({
      searchText: yup.string().test("len", "Type at least 3 characters", val => val.length > 2)
    })
  );

  useEffect(() => {
    handleValidateSchema(filterClaimSearch);
  }, [filterClaimSearch]);

  return (
    <CollapsibleBox title="Associate Claim" name="associateClaim" {...{ open, onToggle }}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className={classes.titleSearch}>
            <span>Restrict Search to:</span>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <CheckboxLabel label="Same DOL" checked={filterClaimSearch.sameDol} name="sameDol" onChange={onChangeSearchClaim} />
            </Grid>
            <Grid item xs={3}>
              <CheckboxLabel label="Same City" checked={filterClaimSearch.sameCity} name="sameCity" onChange={onChangeSearchClaim} />
            </Grid>
            <Grid item xs={3}>
              <CheckboxLabel label="Same State" checked={filterClaimSearch.sameState} name="sameState" onChange={onChangeSearchClaim} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <SearchLabelInput
                name="searchText"
                label="Claim Number / Claimant Name"
                onClick={() => {
                  searchClaims();
                }}
                value={filterClaimSearch.searchText}
                onChange={onChangeSearchClaim}
                error={validationMessages?.searchText}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CollapsibleClaimList {...{ claims, onToggle: onToggleCl, associate: handleConfirmAssociate, openClaimDetail: searchClaims }} />
      <Confirm />
    </CollapsibleBox>
  );
};

AssociateClaim.propTypes = {
  filter: PropTypes.shape({}).isRequired
};

export default AssociateClaim;
