import React, { useState } from "react";
import Confirm from "../Confirm";

const useConfirm = (action, title, messageText, cancelButtonText, confirmButtonText) => {
  const [state, setState] = useState({
    openConfirm: false,
    data: {}
  });

  const { openConfirm, data } = state;

  const handle = rowData => {
    setState({ openConfirm: !openConfirm, data: rowData });
  };

  const confirmConfig = {
    title,
    messageText,
    onToggleConfirm: () => setState({ ...state, openConfirm: !openConfirm }),
    onAction: () => {
      if (data) {
        const { id } = data;
        action(id);
      } else {
        action();
      }

      setState({ openConfirm: !openConfirm, data: {} });
    },
    openDialog: openConfirm,
    okText: confirmButtonText,
    noText: cancelButtonText
  };

  return {
    confirmConfig,
    openConfirm,
    handle,
    confirmComponent: () => <Confirm {...confirmConfig} />
  };
};

export default useConfirm;
