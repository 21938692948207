import React, { useEffect, useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import InsuredInformation from "../../../common/claims/InsuredInformation";
import { get, setChild } from "../../../../redux/actions/claim/info/insured";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import { useRouteParam } from "../../../../common";

export default props => {
  const dispatch = useDispatch();
  const claimId = useRouteParam("claimId");
  const store = useSelector(
    ({
      claim: {
        claim: { id, claimLineOfBusinessId },
        insured
      }
    }) => ({ insured, id, claimLineOfBusinessId }),
    shallowEqual
  );
  const { insured, id: storeId, claimLineOfBusinessId } = store || {};
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info") || {};
  const {
    props: { boxProps, editMode, loading: isLoading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update && Array.isArray(insured?.children) && !!insured.children.length);
  useEffect(() => {
    if ((!insured?.id && !access.read) || storeId !== claimId) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, [insured]);
  const onSave = childId => {
    if (access.read) {
      showLoading();
      dispatch(setChild(childId, claimId, onSuccessEdit, hideLoading));
    }
  };
  return (
    <CollapsibleBox
      title={claimLineOfBusinessId !== 4 ? "Insured" : "Employer/Insured"}
      name="insured"
      className="tc-relative tc-o-hidden"
      {...boxProps}
      {...props}
    >
      {Loading}
      <InsuredInformation {...{ insured, editMode, onClose: onToggleEdit, onSave, isLoading }} />
    </CollapsibleBox>
  );
};
