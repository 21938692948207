import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { Fragment, memo } from "react";
import MaterialTable from "../../../../../common/table/MaterialTable";
import columns from "./columns";

const ReadOnlyCoverage = memo(({ insuredPolicy, title }) => {
  const { cashFlowValue, coverageList, appCashFlowValue, lumpSumValue, lumpSum, basicPeriodType, stateList } = insuredPolicy;
  const cashFlow = cashFlowValue === "YES";
  const tableProps = {
    title,
    columns: columns(),
    data: coverageList || [],
    hidePaging: (coverageList || []).length <= 5,
    options: { search: false }
  };
  return (
    <Fragment key="coveragesfragment">
      <MaterialTable {...tableProps} />
      <div className={clsx("tc-mt1", { "tc-mb1": cashFlow })}>
        <span className="tc-color-gray">Cash Flow Protection Endorsement:</span>
        {` ${cashFlowValue}`}
      </div>
      {cashFlow && (
        <Fragment key="coverage-cash-flow">
          <div className="tc-mb1">
            <span className="tc-color-gray">Per Occurrence Sir Applied to Cash Flow Endorsement:</span>
            {` ${appCashFlowValue}`}
          </div>
          <Grid container spacing={4} wrpa="nowrap">
            <Grid item>
              <span className="tc-color-gray">Lump-Sum Settlements Excluded from Paid Losses: </span>
              {` ${lumpSumValue}`}
            </Grid>
            {lumpSum && (
              <Grid item>
                <span className="tc-color-gray">Basic Period Duration Info:</span>
                {` ${basicPeriodType ?? ""}`}
              </Grid>
            )}
          </Grid>
        </Fragment>
      )}
      <br />
      <Typography className="tc-title tc-mb1">Covered States</Typography>
      {(stateList || [])
        .map(({ name }) => name)
        .sort((a, b) => a.localeCompare(b))
        .join(", ")}
    </Fragment>
  );
});

ReadOnlyCoverage.propTypes = { insuredPolicy: PropTypes.objectOf(PropTypes.any).isRequired, title: PropTypes.string };
ReadOnlyCoverage.defaultProps = { title: undefined };

export default ReadOnlyCoverage;
