import OCCURRENCE from "./actionTypes/occurrence";
import { API } from "./actionTypes";
import * as docActions from "./common/documents";

export const getAll = (onSuccess, onError) => ({ type: OCCURRENCE.GETALL, onSuccess, onError });
export const getAllSuccess = occurrences => ({ type: OCCURRENCE.GETALL_SUCCESS, occurrences });

export const get = (id, onSuccess, onError) => ({ type: OCCURRENCE.GET, id, onSuccess, onError });
export const getSuccess = occurrence => ({ type: OCCURRENCE.GET_SUCCESS, occurrence });

export const set = (occurrence, onSuccess, onError) => ({ type: OCCURRENCE.SET, occurrence, onSuccess, onError });
export const setSuccess = occurrence => ({ type: OCCURRENCE.SET_SUCCESS, occurrence });

export const post = (occurrence, onSuccess, onError) => ({ type: OCCURRENCE.POST, occurrence, onSuccess, onError });
export const postSuccess = occurrences => ({ type: OCCURRENCE.POST_SUCCESS, occurrences });

export const del = id => ({ type: OCCURRENCE.DEL, id });
export const delSuccess = occurrences => ({ type: OCCURRENCE.DEL_SUCCESS, occurrences });

export const assocciateClaim = (claimAssociatedToOcc, onSuccess) => ({ type: OCCURRENCE.ASSOCIATECLAIM, claimAssociatedToOcc, onSuccess });
export const assocciateClaimSuccess = occurrence => ({ type: OCCURRENCE.ASSOCIATECLAIM_SUCCESS, occurrence });

export const disassocciateClaim = (claimAssociatedToOcc, onSuccess) => ({ type: OCCURRENCE.DISASSOCIATECLAIM, claimAssociatedToOcc, onSuccess });
export const disassocciateClaimSuccess = occurrence => ({ type: OCCURRENCE.DISASSOCIATECLAIM_SUCCESS, occurrence });

export const getReserves = ({ occurrenceId }) => ({ type: OCCURRENCE.GET_RESERVES, occurrenceId });
export const getReservesSuccess = reserves => ({ type: OCCURRENCE.GET_RESERVES_SUCCESS, reserves });

export const loadFiles = id => ({ type: OCCURRENCE.LOAD_FILES, id });
export const loadFilesSuccess = files => ({ type: OCCURRENCE.LOAD_FILES_SUCCESS, files });

export const uploadFiles = (documents, onSuccess) => ({ type: OCCURRENCE.UPLOAD_FILES, documents, onSuccess });
export const uploadFilesSuccess = documents => ({ type: OCCURRENCE.UPLOAD_FILES_SUCCESS, documents });

export const updateFile = data => ({ type: OCCURRENCE.UPDATE_FILE, data, url: `${API.URL}/OCCURRENCE/file` });

export const deleteFile = (data, onSuccess) => ({ type: OCCURRENCE.DELETE_FILE, data, onSuccess });
export const deleteFileSuccess = documents => ({ type: OCCURRENCE.DELETE_FILE_SUCCESS, documents });

export const getTemplates = occurrenceId => ({ type: OCCURRENCE.DIARY.TEMPLATES.GET, occurrenceId });
export const getTemplatesSuccess = templates => ({ type: OCCURRENCE.DIARY.TEMPLATES.GET_SUCCESS, templates });

export const openFile = docActions.openFile(OCCURRENCE.OPEN_FILE);

export const getFilterFields = (occurrenceId, onSuccess) => ({ type: OCCURRENCE.GET_FILTER_FIELD, occurrenceId, onSuccess });
export const getFilterFieldsSuccess = filterFields => ({ type: OCCURRENCE.GET_FILTER_FIELD_SUCCESS, filterFields });

export const reset = () => ({ type: OCCURRENCE.RESET });
