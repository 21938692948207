import React, { useState, useEffect, Fragment, useContext, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { Collapse, Grid } from "@material-ui/core";
import { CheckboxLabel, DateTimeInput, SelectInput } from "../../../common/inputs";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import { get, set, del as delTrial } from "../../../../redux/actions/claim/legal/trial";
import { useRouteParam } from "../../../../common";
import FooterSave from "../../../common/FooterSave";
import ReadMode from "./readMode/LegalTrialInfo";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import useYupValidationSchema from "../../../common/customHooks/useYupValidationSchema";
import { trialSchema } from "./yup";

export default props => {
  const claimId = useRouteParam("claimId");
  const store = useSelector(({ claim, common }) => ({ claim, common }), shallowEqual);
  const {
    claim: { trial: dataTrial },
    common: { veredictType, appealStatus, veredictStatus }
  } = store || {};
  const data = useMemo(() => ({ ...dataTrial, trialNoticeReceived: !!dataTrial?.id }), [dataTrial]);
  const [trial, setTrial] = useState({});
  const [firstChangeState, setFirstChangeState] = useState(false);
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = useMemo(() => routesAllowed.find(elem => elem.route === "legal"), routesAllowed);
  const {
    props: { boxProps, editMode, loading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  const dispatch = useDispatch();
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  useEffect(() => {
    setTrial(data);
  }, [data]);
  const { trialNoticeReceived, trialDate, veredictTypeId, veredictStatusId, appealed, appealDate, appealStatusId } = trial || {};
  const { handleValidateSchema, validationMessages } = useYupValidationSchema(trialSchema);
  useEffect(() => {
    if (firstChangeState && Object.keys(trial || {}).length > 0) handleValidateSchema(trial);
    if (!editMode) {
      setTrial(data);
      setFirstChangeState(prev => !prev);
    }
  }, [trial, editMode]);
  const objectReset = ({ ...rest }) => ({ appealDate: undefined, appealStatusId: undefined, appealed: undefined, ...rest });
  const handleChange = ({ target: { name, value } }) => {
    if (!firstChangeState) setFirstChangeState(prev => !prev);

    setTrial(ps => {
      let valueToSet = {};
      if (["trialNoticeReceived", "appealed"].indexOf(name) > -1 && !value) {
        if (name === "trialNoticeReceived") return {};
        valueToSet = objectReset({});
      }
      return { ...ps, [name]: value, ...valueToSet };
    });
  };
  const handleSave = () => {
    if (!access.update) return false;
    if (!trial.trialNoticeReceived) {
      if (!data.id) {
        onToggleEdit();
        return toast.success("Information successfully saved");
      }
      showLoading();
      return dispatch(delTrial({ claimId, onSuccess: onSuccessEdit, onError: hideLoading }));
    }
    if (!handleValidateSchema(trial || {}).isValid) return false;
    showLoading();
    return dispatch(set({ trial: { ...trial, claimId }, onSuccess: onSuccessEdit, onError: hideLoading }));
  };
  const errorBinding = elementName => ({ error: validationMessages[elementName] });
  return (
    <CollapsibleBox title="Trial" name="trial" {...boxProps} {...props}>
      {Loading}
      {(editMode && (
        <Fragment key="editMode">
          <Grid container spacing={4}>
            <Grid item xs>
              <CheckboxLabel
                label="Trial Notice Received"
                checked={trialNoticeReceived}
                name="trialNoticeReceived"
                onChange={handleChange}
                {...errorBinding("trialNoticeReceived")}
              />
              <Collapse in={trialNoticeReceived} timeout="auto">
                <DateTimeInput
                  name="trialDate"
                  label="Trial Date"
                  value={trialDate || undefined}
                  onChange={e => {
                    e.target.value = e.target.value === "" ? undefined : e.target.value;
                    handleChange(e);
                  }}
                  keyboard
                  {...errorBinding("trialDate")}
                />
              </Collapse>
            </Grid>
            <Grid item xs>
              <Collapse in={trialNoticeReceived} timeout="auto">
                <SelectInput name="veredictTypeId" label="Veredict" value={veredictTypeId} options={veredictType} onChange={handleChange} />
                <SelectInput
                  name="veredictStatusId"
                  label="Status"
                  value={veredictStatusId}
                  options={veredictStatus}
                  onChange={handleChange}
                  {...errorBinding("veredictStatusId")}
                />
              </Collapse>
            </Grid>
            <Grid item xs>
              {trialNoticeReceived && (
                <>
                  <CheckboxLabel label="Appealed" checked={appealed} name="appealed" onChange={handleChange} {...errorBinding("appealed")} />
                  <Collapse in={appealed} timeout="auto">
                    <DateTimeInput
                      name="appealDate"
                      label="Appealed Date Filed"
                      value={appealDate || undefined}
                      onChange={e => {
                        e.target.value = e.target.value === "" ? undefined : e.target.value;
                        handleChange(e);
                      }}
                      keyboard
                      {...errorBinding("appealDate")}
                    />
                    <SelectInput
                      name="appealStatusId"
                      label="Status"
                      value={appealStatusId}
                      options={appealStatus}
                      onChange={handleChange}
                      {...errorBinding("appealStatusId")}
                    />
                  </Collapse>
                </>
              )}
            </Grid>
          </Grid>
          <FooterSave onCancel={onToggleEdit} onSave={handleSave} isDisable={loading} />
        </Fragment>
      )) || <ReadMode />}
    </CollapsibleBox>
  );
};
