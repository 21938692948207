import { Divider } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLastActivityStyles } from "./index.styles";

const LastActivityPanelInfo = () => {
  const store = useSelector(({ claim: { claim } }) => claim, shallowEqual);
  const { lastActivityOn, lastActivityByName } = store || {};
  if (!lastActivityOn && !lastActivityByName) return null;
  const classes = useLastActivityStyles();
  return (
    <>
      <Divider />
      <small className={classes.root}>{`Last Update On ${moment(lastActivityOn).format("M/D/YYYY")} By ${lastActivityByName}`}</small>
    </>
  );
};

export default LastActivityPanelInfo;
