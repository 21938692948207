import { API } from "../../actions/actionTypes";
import payments from "../../actions/actionTypes/dashboard/payments";
import { getDuplicatesSuccess, getDuplicatesForChartSuccess } from "../../actions/dashboard/payments";
import { takeLatest } from "../common/sagaGenerator";

export default [
  takeLatest({
    type: payments.DUPLICATES.GETALL,
    actionSuccess: getDuplicatesSuccess,
    errorMessage: "Unable to get Possible Duplicate Payments",
    url: `${API.URL}/report/duplicatepayments`
  }),
  takeLatest({
    type: payments.DUPLICATES_COUNT.GETALL,
    actionSuccess: getDuplicatesForChartSuccess,
    errorMessage: "Unable to get Possible Duplicate Payments for chart",
    url: `${API.URL}/report/duplicatepaymentschart`
  })
];
