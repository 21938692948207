import React, { useState, memo, useCallback } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { GetApp, Print } from "@material-ui/icons";
import ViewerTools from "./Tools";

const PdfViewer = memo(({ actions, blob, hideDownload, onDownload }) => {
  const [{ mounted, iFrame }, setState] = useState({ mounted: false });
  const ref = useCallback(node => setState({ mounted: node != null, iFrame: node }), []);
  const onPrint = () => typeof iFrame?.contentWindow?.print === "function" && iFrame.contentWindow.print();
  const { url, filename } = blob || {};
  return (
    <>
      <ViewerTools
        hideActions={!mounted}
        actions={[
          ...actions,
          ...(hideDownload ? [] : [{ key: "btn-download", icon: <GetApp />, onClick: onDownload, tooltip: "Download File" }]),
          { key: "btn-print", icon: <Print />, onClick: onPrint, tooltip: "Print" }
        ]}
      />
      <div className={clsx("tc-page-content", "tc-no-padding tc-no-scroll")}>
        <iframe title={filename} ref={ref} src={`${url}#toolbar=0&zoom=100`} style={{ width: "100%", height: "100%", border: 0 }} />
      </div>
    </>
  );
});

PdfViewer.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  blob: PropTypes.shape({}),
  hideDownload: PropTypes.bool,
  onDownload: PropTypes.func
};

PdfViewer.defaultProps = { actions: [], blob: {}, hideDownload: false, onDownload: () => {} };

export default PdfViewer;
