import { CLAIM } from "../actionTypes";

const get = (claimId, onSuccess, onError) => ({ type: CLAIM.COMMUNICATION.GET, claimId, onSuccess, onError });
const getSuccess = communications => ({ type: CLAIM.COMMUNICATION.GET_SUCCESS, communications });
const getClaimEmails = claimId => ({ type: CLAIM.COMMUNICATION.CLAIMEMAILS.GETALL, claimId });
const getClaimEmailsSuccess = data => ({ type: CLAIM.COMMUNICATION.CLAIMEMAILS.GETALL_SUCCESS, data });
const post = (data, onSuccess, onError) => ({ type: CLAIM.COMMUNICATION.POST, ...data, onSuccess, onError });
const postSuccess = communications => ({ type: CLAIM.COMMUNICATION.POST_SUCCESS, communications });
const set = (data, onSuccess, onError) => ({ type: CLAIM.COMMUNICATION.SET, ...data, onSuccess, onError });
const setSuccess = communications => ({ type: CLAIM.COMMUNICATION.SET_SUCCESS, communications });
const getTemplates = claimId => ({ type: CLAIM.COMMUNICATION.TEMPLATES.GET, claimId });
const getTemplatesSuccess = templates => ({ type: CLAIM.COMMUNICATION.TEMPLATES.GET_SUCCESS, templates });

export { get, getSuccess, getClaimEmails, getClaimEmailsSuccess, post, postSuccess, set, setSuccess, getTemplates, getTemplatesSuccess };
