import { INITIALREPORT as REPORT, API } from "../../../actions/actionTypes";
import { getSuccess, setSuccess } from "../../../actions/initialReport/damagedVehicle";
import { takeLatest, takeLatestPost } from "../../common/sagaGenerator";

const url = `${API.URL}/claim/damagedVehicle`;

export default [
  takeLatest({
    type: REPORT.INFORMATION.DAMAGEDVEHICLE.GET,
    actionSuccess: getSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading Damaged Vehicle",
    url
  }),
  takeLatestPost({
    type: REPORT.INFORMATION.DAMAGEDVEHICLE.SET,
    actionSuccess: setSuccess,
    params: ({ dmVehicle }) => dmVehicle,
    successMessage: "Damaged Vehicle Updated",
    errorMessage: "Error updating Damaged Vehicle",
    url
  })
];
