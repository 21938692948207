import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, IconButton, Paper, Grid } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import Loading from "../../components/common/Loading";
import MaterialTable from "../../components/common/table/MaterialTable";
import { getAll, del } from "../../redux/actions/occurrence";
import useCustomConfirm from "../../components/common/customHooks/useCustomConfirm";
import useLoading from "../../components/common/customHooks/useIsSubmitting";
import { getUserAccess, setExportTitleFileName } from "../../common";
import DateFilter from "../../components/common/table/DateFilter";

const Occurrences = () => {
  const occurrences = useSelector(({ occurrence: { occurrences: occ } }) => occ, shallowEqual);
  const secProfs = useSelector(({ profile: { securityProfilesDetails } }) => securityProfilesDetails, shallowEqual);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useLoading();
  const hideLoading = () => {
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(getAll(hideLoading, hideLoading));
  }, []);
  const [hideCancel, setHideCancel] = useState(false);
  const [confirmButtonText, setConfirmButtonText] = useState("Yes, Delete");
  const access = getUserAccess(secProfs, "OCCURRENCE.LIST", null, null, null);
  const handleDelete = row => {
    if (!hideCancel) dispatch(del(row.id));
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleDelete, "Cancel", confirmButtonText, hideCancel);
  const handleConfirmDelete = item => {
    setHideCancel(item.claimsAssociatedCount > 0);
    setConfirmButtonText(item.claimsAssociatedCount > 0 ? "Accept" : "Yes, Delete");
    const message =
      item.claimsAssociatedCount === 0
        ? `The following Occurrence will be deleted: ${item.number}. Are you sure you want to proceed? `
        : `Unable to delete this Occurrence since there are claims associated. In order to delete the Occurrence, please remove the claims associated.`;
    handleCustomConfirm({ oData: item, titleText: `Delete Occurrence`, messageText: message });
  };
  const history = useHistory();
  const getButtonToolBar = () => {
    if (!access.create) return null;
    return (
      <Grid item className="tc-pl1">
        <Button variant="contained" color="primary" onClick={() => history.push("/occurrence")}>
          Add Occurrence
        </Button>
      </Grid>
    );
  };
  const tableProps = {
    columns: [
      {
        title: "Number",
        field: "number",
        render: rowData => {
          return <Link to={`/occurrence/${rowData.id}`}>{rowData.number}</Link>;
        }
      },
      { title: "Date", field: "date", type: "date", filterComponent: DateFilter },
      { title: "Name", field: "name", customSort: (a, b) => (a.name || "").toLowerCase().localeCompare((b.name || "").toLowerCase()) },
      {
        title: "Location",
        field: "fullAddress",
        customSort: (a, b) => (a.fullAddress || "").toLowerCase().localeCompare((b.fullAddress || "").toLowerCase())
      },
      {
        title: "Insured",
        field: "insuredName",
        customSort: (a, b) => (a.insuredName || "").toLowerCase().localeCompare((b.insuredName || "").toLowerCase())
      },
      { title: "Claims Associated", field: "claimsAssociatedCount" },
      {
        title: "Catastrophe Associated",
        field: "catastropheNumber",
        render: rowData => <Link to={`/catastrophe/${rowData.catastropheId}`}>{rowData.catastropheNumber}</Link>
      }
    ],
    data: occurrences,
    hidePaging: occurrences.length <= 50,
    options: { pageSize: 50, exportFileName: setExportTitleFileName(`OccurrenceList`), filtering: true },
    actions: [
      ...(access.delete
        ? [
            {
              icon: p => (
                <IconButton {...p} className="tc-ml1">
                  <DeleteIcon />
                </IconButton>
              ),
              custom: true,
              onClick: (_e, r) => handleConfirmDelete(r)
            }
          ]
        : [])
    ],
    ButtonToolBar: getButtonToolBar
  };

  return (
    <>
      {isLoading && <Loading float />}
      <div className="tc-page-content">
        <Paper className="tc-p2">
          <MaterialTable {...tableProps} />
          <Confirm />
        </Paper>
      </div>
    </>
  );
};

export default Occurrences;
