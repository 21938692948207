import * as yup from "yup";
import { rootParent } from "../../common/yup";

export default yup.object().shape({
  name: yup.string().required("Name is Required"),
  dateSince: yup
    .date()
    .nullable()
    .required()
    .typeError("Date Since is Required"),
  location: yup.object().shape({
    stateId: yup
      .string()
      .nullable()
      .test("", "State is Required", (v, c) => {
        return Boolean(v) || !!c.from[1].value.multipleLocation;
      })
  }),
  multipleLocation: yup
    .boolean()
    .test("", "Location is Required", (v, c) => !v || !!rootParent(c).locationList?.length || c.createError({ path: "toast" }))
});

export const addSchema = yup.object().shape({
  name: yup.string().required("Name is Required"),
  dateSince: yup
    .date()
    .nullable()
    .required()
    .typeError("Date Since is Required"),
  location: yup.object().shape({
    stateId: yup.number().test("", "State is Required", (v, c) => {
      return Boolean(v) || c.from[1].value.multipleLocation === "1";
    })
  }),
  multipleLocation: yup
    .number()
    .test("", "Location is Required", (v, c) => (v !== "1" || c.from[0].value.locationList.length > 0 ? true : c.createError({ path: "toast" })))
});
