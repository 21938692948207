import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import ClaimStatus from "../../claims/ClaimStatus";
import Card from "../../common/overview/Card";
import { useClaimsInvolvedStyles } from "./index.styles";

const ClaimsInvolved = props => {
  const claimsInvolvedClasses = useClaimsInvolvedStyles();
  const { totalClaims, closedClaims, openClaims } = props;
  return (
    <Card title="Claims Involved">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <div className={claimsInvolvedClasses.box}>
            <Grid container alignItems="center" className={claimsInvolvedClasses.titleBox}>
              <Grid item xs={12}>
                Claims
              </Grid>
              <Grid item xs={2}>
                <ClaimStatus status="Claim" />
              </Grid>
              <Grid item className={claimsInvolvedClasses.count}>
                {totalClaims}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={claimsInvolvedClasses.box}>
            <Grid container alignItems="center" className={claimsInvolvedClasses.titleBox}>
              <Grid item xs={12} className={claimsInvolvedClasses.label} style={{ color: "#E74C3C" }}>
                Closed Claims
              </Grid>
              <Grid item xs={2}>
                <ClaimStatus status="Closed" />
              </Grid>
              <Grid item className={claimsInvolvedClasses.count}>
                {closedClaims}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={claimsInvolvedClasses.box}>
            <Grid container alignItems="center" className={claimsInvolvedClasses.titleBox}>
              <Grid item xs={12} className={claimsInvolvedClasses.label} style={{ color: "#70A140" }}>
                Open Claims
              </Grid>
              <Grid item xs={1}>
                <ClaimStatus status="Open" />
              </Grid>
              <Grid item className={claimsInvolvedClasses.count}>
                {openClaims}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

ClaimsInvolved.propTypes = { totalClaims: PropTypes.number, closedClaims: PropTypes.number, openClaims: PropTypes.number };

ClaimsInvolved.defaultProps = { totalClaims: 0, closedClaims: 0, openClaims: 0 };

export default ClaimsInvolved;
