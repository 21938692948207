import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Typography, Grid, IconButton, Tooltip, Badge } from "@material-ui/core";
import { Apps, Dehaze, CreateNewFolder, List, SyncAlt, Error } from "@material-ui/icons";
import { SearchInput } from "../inputs";
import LinkButton from "../LinkButton";
import ProgressButton, { progressProps } from "./ProgressButton";

const DocumentsHeader = props => {
  const { breadcrumbs, classes, downloadArray, filter, level, openFolder, toggleViews, viewType, downloadState, uploadState, actionsAllowed } = props;
  const {
    actions: { goRoot, onAddFile, onAddFolder, onDownload, onFilterChange, onMoveFiles, setViewType }
  } = props;
  const move = onMoveFiles && actionsAllowed.create && (!toggleViews || (viewType !== 1 && level > 0)) && downloadArray.some(x => x.file);
  const anyFolder = move && downloadArray.some(x => !x.file);
  return (
    <Grid container alignItems="center">
      <Grid item className={classes.title}>
        <Typography variant="subtitle2">
          {breadcrumbs.length > 0 ? (
            <>
              <LinkButton onClick={goRoot}>Documents</LinkButton>
              {breadcrumbs.map((b, bi) => (
                <Fragment key={b.id}>
                  {" > "}
                  {bi === breadcrumbs.length - 1 ? b.name : <LinkButton onClick={() => openFolder(b, bi)}>{b.name}</LinkButton>}
                </Fragment>
              ))}
            </>
          ) : (
            "Documents"
          )}
        </Typography>
      </Grid>
      <Grid item>
        <SearchInput name="term" value={filter} onChange={onFilterChange} />
      </Grid>

      {toggleViews &&
        level === 0 &&
        [
          [0, Apps, "Grid View", classes.pl],
          [2, List, "List View"],
          [1, Dehaze, "All Files View"]
        ].map(([val, Comp, tooltip, css]) => (
          <Grid key={val} item className={css}>
            <Tooltip title={tooltip}>
              <IconButton className={viewType === val ? classes.iconActive : null} onClick={viewType === val ? undefined : () => setViewType(val)}>
                <Comp />
              </IconButton>
            </Tooltip>
          </Grid>
        ))}
      {move && (
        <Grid item>
          <Tooltip title={anyFolder ? "Only files can be moved" : "Move files to another folder"}>
            <div>
              <IconButton disabled={anyFolder} color="primary" onClick={onMoveFiles}>
                {(anyFolder && (
                  <Badge badgeContent={<Error className="tc-color-red" />}>
                    <SyncAlt />
                  </Badge>
                )) || <SyncAlt />}
              </IconButton>
            </div>
          </Tooltip>
        </Grid>
      )}
      {(!toggleViews || viewType === 1 || level > 0) && ((downloadArray.length > 0 && actionsAllowed.read) || downloadState.loading) && (
        <Grid item>
          <ProgressButton {...downloadState} onClick={onDownload} />
        </Grid>
      )}
      {viewType !== 1 && onAddFolder && actionsAllowed.create && (
        <Grid item>
          <Tooltip title="Create Folder">
            <IconButton onClick={onAddFolder}>
              <CreateNewFolder />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      {(!toggleViews || (viewType !== 1 && level > 0)) && actionsAllowed.create && (
        <Grid item>
          <ProgressButton {...uploadState} onClick={onAddFile} upload />
        </Grid>
      )}
    </Grid>
  );
};

DocumentsHeader.propTypes = {
  downloadArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleViews: PropTypes.bool.isRequired,
  viewType: PropTypes.number.isRequired,
  level: PropTypes.number.isRequired,
  filter: PropTypes.string.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  openFolder: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  downloadState: PropTypes.shape(progressProps).isRequired,
  uploadState: PropTypes.shape(progressProps).isRequired,
  actionsAllowed: PropTypes.objectOf(PropTypes.any)
};
DocumentsHeader.defaultProps = { actionsAllowed: { read: true, update: true, create: true } };

export default DocumentsHeader;
