import React from "react";
import { Typography } from "@material-ui/core";
import { useContext } from "../context";
import Detail from "./Detail";
import { styles } from "./index.styles";

export default () => {
  const [
    state,
    ,
    ,
    {
      step4: { validationMessages }
    }
  ] = useContext();
  const { multipleClaimants, coverageTypes, claimant, claimants } = state;
  const classes = styles();
  const coverages = coverageTypes.reduce(
    (aCt, ct, lobIx) => [
      ...aCt,
      ...ct.lossTypeGroups
        .map((ltg, ltgIx) => ({ ...ltg, ltgIx, lobIx }))
        .filter(ltg => ltg.checked)
        .map(ltg => ({ ...ltg, coverageType: ct.text, divId: `coverage-${ct.id}-${ltg.id}` }))
    ],
    []
  );
  const errors = ({ ltgIx, lobIx, cIx }) => {
    const path = multipleClaimants ? `claimants[${cIx}]` : `coverageTypes[${lobIx}].lossTypeGroups[${ltgIx}]`;
    return {
      errors: {
        supervisorUserId: (validationMessages || {})[`${path}.supervisorUserId`],
        assistantUserId: (validationMessages || {})[`${path}.assistantUserId`]
      }
    };
  };
  return (
    <>
      <Typography variant="subtitle2" className={classes.label}>
        The following Records are going to be created:
      </Typography>
      {!multipleClaimants &&
        coverages.map((c, i) => <Detail key={c.divId} number={i + 1} {...{ classes }} claim={{ ...c, claimant, ...errors(c) }} />)}
      {multipleClaimants &&
        claimants
          .sort((a, b) => (a.id > b.id ? 1 : -1))
          .map((c, cIx) => {
            if (!coverages.some(() => true)) return null;
            const ct = { ...coverages[0], cIx };
            const { adjusterUserId, supervisorUserId, assistantUserId, isReportOnly } = c;
            const claim = {
              ...ct,
              divId: `claimant-${c.id}-${ct.id}`,
              adjusterUserId,
              supervisorUserId,
              assistantUserId,
              isReportOnly,
              claimant: c,
              ...errors(ct)
            };
            return <Detail key={c.id} number={cIx + 1} {...{ classes, claim }} />;
          })}
    </>
  );
};
