import React from "react";
import PropTypes from "prop-types";
import { Grid, Grow } from "@material-ui/core";

const BoxItem = ({ show, xs, ...other }) => {
  if (!xs)
    return (
      <Grow in={show} timeout="auto">
        <div {...other} />
      </Grow>
    );
  return (
    <Grow in={show}>
      <Grid item xs={xs} {...other} />
    </Grow>
  );
};

BoxItem.propTypes = {
  show: PropTypes.bool.isRequired,
  xs: PropTypes.number
};

BoxItem.defaultProps = {
  xs: null
};

export default BoxItem;
