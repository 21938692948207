const location = { address2: undefined, address1: undefined, city: undefined, zipCode: undefined, stateId: undefined };

export default {
  isLienholderClaimant: false,
  hasLienholder: false,
  isDriverOwner: false,
  vehicle: {
    number: undefined,
    model: undefined,
    plateNumber: undefined,
    vehicleYear: undefined,
    vehicleMakeId: undefined,
    vehicleBodyTypeId: undefined,
    vin: undefined,
    vehicleStateId: undefined
  },
  driver: {
    licenseNumber: undefined,
    firstName: undefined,
    lastName: undefined,
    location,
    dateofBirth: undefined,
    phone: undefined,
    email: undefined
  },
  vehicleOwner: {
    location,
    firstName: undefined,
    lastName: undefined,
    dateofBirth: undefined,
    phone: undefined,
    email: undefined
  },
  lienHolder: {
    email: undefined,
    location,
    mainContactName: undefined,
    name: undefined,
    phone: undefined
  },
  damageDescription: undefined,
  estimateAmount: undefined,
  vehicleDamageTypeId: undefined,
  whereIsDamage: undefined,
  insuranceCarrier: undefined,
  nAICCodeId: undefined,
  policyNumber: undefined
};
