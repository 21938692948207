import { Grid } from "@material-ui/core";
import { Business, Person } from "@material-ui/icons";
import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import ClaimProcessingStatus from "../../ClaimProcessingStatus";
import ClaimStatus from "../../ClaimStatus";
import { useClaimPanelStyles } from "./index.styles";

const ClaimPanelInfo = () => {
  const classes = useClaimPanelStyles();
  const claim = useSelector(({ claim: c }) => c, shallowEqual);
  const users = useSelector(({ common: { adjusterUsers, supervisorUsers } }) => ({ adjusterUsers, supervisorUsers }), shallowEqual);
  const { adjusterUsers, supervisorUsers } = users;
  const {
    claim: { number, status, processingStatus, adjusterId, supervisorId },
    claimant: { isPerson, commercialName, fullName },
    insured: { name }
  } = claim;
  const user = (label, arr, id) => {
    const value = Array.isArray(arr) && arr.find(a => a.id === id)?.text;
    return (
      <Grid item>
        <small>{label}</small>
        <div>{value || "-"}</div>
      </Grid>
    );
  };
  return (
    <div className={classes.root}>
      <div className="tc-mb1">
        <small>Claim #</small>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>{number && <div className={classes.claimNo}>{number}</div>}</Grid>
          <Grid item>
            <ClaimStatus {...{ status }} />
          </Grid>
          <Grid item>
            <ClaimProcessingStatus {...{ processingStatus }} />
          </Grid>
        </Grid>
      </div>
      {((isPerson && fullName) || commercialName) && (
        <div className="tc-mb1">
          <small>Claimant</small>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item className={classes.icon}>
              {isPerson ? <Person /> : <Business />}
            </Grid>
            <Grid item className={classes.claimant}>
              {isPerson ? fullName : commercialName}
            </Grid>
          </Grid>
        </div>
      )}
      {name && (
        <div className="tc-mb2">
          <small>Insured</small>
          <div>{name}</div>
        </div>
      )}
      <Grid container wrap="nowrap" spacing={2}>
        {user("Adjuster", adjusterUsers, adjusterId)}
        {user("Supervisor", supervisorUsers, supervisorId)}
      </Grid>
    </div>
  );
};

export default ClaimPanelInfo;
