import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { CloudUpload, Delete } from "@material-ui/icons";
import { Paper, Button } from "@material-ui/core";
import { useFormStyles } from "./styles";
import FileInput, { onClick } from "../../../common/documents/FileInput";
import FileDropzone from "../../../common/documents/FileDropzone";
import { validateFile } from "../../../common/documents/hooks";
import useViewer from "../../../common/viewer/useViewer";
import { useLoading } from "../../../common/Loading";
import { openFile } from "../../../../redux/actions/claim/documents";
import { parseFiletype } from "../../../common/documents/FileIcon";

const File = ({ billId, file, setFile, canDelete }) => {
  const ini = { errorDrag: false, hover: false };
  const [state, setState] = useState(ini);
  //---------------------------------------
  const dispatch = useDispatch();
  const { actions, Loading } = useLoading(false, true);
  const { hideLoading, showLoading } = actions;
  const { onViewFile, fileViewer, setNewBlob } = useViewer({
    modal: false,
    dispatchAction: p => {
      showLoading();
      return dispatch(openFile({ ...p, onSuccess: hideLoading, onError: hideLoading }));
    },
    headerActions: ["btn-upload", "btn-delete"].reduce((acc, key) => {
      if (canDelete) {
        if (key === "btn-upload")
          return [
            ...acc,
            {
              key,
              icon: <CloudUpload />,
              onClick,
              tooltip: "Upload File"
            }
          ];
        if (key === "btn-delete")
          return [
            ...acc,
            {
              key: "btn-delete",
              icon: <Delete />,
              onClick: () => {
                if (file?.id) {
                  setFile({ ...file, deleted: true, filesToUpload: [] });
                } else {
                  setNewBlob(undefined);
                  setFile({ ...file, filesToUpload: [] });
                }
              },
              tooltip: "Delete File"
            }
          ];
      }

      return acc;
    }, [])
  });
  useEffect(() => {
    if (file?.id) onViewFile(file?.id, { billId });
  }, [file?.id]);
  //--------------------------------------
  const getFileName = () => {
    const uploadedFileName = file?.filesToUpload?.length > 0 ? file?.filesToUpload[0].name : "";
    const name = file?.id && !file?.deleted ? file?.fileName : "";
    return name !== "" ? name : uploadedFileName;
  };

  const onDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
    setState({ ...state, hover: true, errorDrag: [...event.dataTransfer.items].some(i => !validateFile(i)) });
  };
  const onDragLeave = () => setState({ ...state, hover: false, errorDrag: false });

  const onUploadChange = event => {
    event.preventDefault();
    event.stopPropagation();
    const {
      target: { files }
    } = event;
    if ([...files].map(f => ({ type: f.type, kind: "file" })).some(i => !validateFile(i))) return toast.error("Invalid files");
    setNewBlob({ filename: files[0].name, url: URL.createObjectURL(files[0]), type: files[0].type, ...parseFiletype(files[0].type) });
    return setFile({ ...file, filesToUpload: [files[0]] });
  };

  const onDrop = event => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;
    onDragLeave();
    if ([...files].map(f => ({ type: f.type, kind: "file" })).some(i => !validateFile(i))) return toast.error("Invalid files");
    setNewBlob({ filename: files[0].name, url: URL.createObjectURL(files[0]), type: files[0].type, ...parseFiletype(files[0].type) });
    return setFile({ ...file, filesToUpload: [files[0]] });
  };

  const classes = useFormStyles();
  const filename = getFileName();
  return (
    <div style={{ display: "block", height: "100%" }}>
      {Loading}
      {filename !== "" ? (
        <>{fileViewer}</>
      ) : (
        <div {...{ onDragOver, onDragLeave, onDrop }}>
          <Paper className={classes.upload}>
            <FileDropzone {...state} />
            <div style={{ zIndex: 2 }}>
              <CloudUpload />
              <div>Please upload your file</div>
              <Button variant="contained" color="primary" {...{ onClick }}>
                Select Files
              </Button>
            </div>
          </Paper>
        </div>
      )}
      <FileInput onChange={onUploadChange} multiple={false} />
    </div>
  );
};
File.propTypes = {
  file: PropTypes.shape({}),
  setFile: PropTypes.func.isRequired,
  canDelete: PropTypes.bool,
  billId: PropTypes.number
};
File.defaultProps = {
  file: { filesToUpload: [] },
  canDelete: true,
  billId: undefined
};

export default File;
