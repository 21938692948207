import { FINANCIAL } from "../actions/actionTypes";
import initialState from "./initialState";

export default (state = initialState.financial, action) => {
  switch (action.type) {
    case FINANCIAL.PROCESSINGQUEUE.GETALL_SUCCESS:
      return { ...state, processingQueue: action.processingQueue };
    case FINANCIAL.SCHEDULED.GETALL_SUCCESS:
      return { ...state, scheduled: action.scheduled };
    case FINANCIAL.HISTORY.GETALL_SUCCESS:
      return { ...state, history: action.history };
    case FINANCIAL.SETTLEMENTS.GETALL_SUCCESS:
      return { ...state, settlements: action.settlements };
    default:
      return state;
  }
};
