import { ADMIN } from "../actionTypes";

export const getAll = (id, onSuccess, onError) => ({ type: ADMIN.USER.GETALL, id, onSuccess, onError });
export const getAllSuccess = users => ({ type: ADMIN.USER.GETALL_SUCCESS, users });

export const add = ({ data, onSuccess, onError }) => ({ type: ADMIN.USER.POST, data, onSuccess, onError });
export const addSuccess = users => ({ type: ADMIN.USER.POST_SUCCESS, users });

export const set = ({ data, onSuccess, onError }) => ({ type: ADMIN.USER.SET, data, onSuccess, onError });
export const setSuccess = users => ({ type: ADMIN.USER.SET_SUCCESS, users });

export const del = (userId, reassignUserId, onSuccess, onError) => ({ type: ADMIN.USER.DEL, userId, reassignUserId, onSuccess, onError });
export const delSuccess = users => ({ type: ADMIN.USER.DEL_SUCCESS, users });

export const getOpenClaims = (userId, onSuccess, onError) => ({ type: ADMIN.USER.CLAIMS, userId, onSuccess, onError });
export const getOpenClaimsSuccess = () => ({ type: ADMIN.USER.CLAIMS_SUCCESS });
