import React, { memo } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { TextField } from "@material-ui/core";
import { FormControlStyleRight, HocInput } from "./HocInput";

export const BaseIntegerInput = memo(
  ({ error, name, onChange, ...other }) => {
    const props = {
      ...other,
      name,
      id: `int-${name}`,
      onKeyPress: e => typeof [...Array(10).keys()].find(x => String(x) === e.key) === "undefined" && e.preventDefault(),
      onValueChange: values => {
        if (typeof onChange !== "function") return;
        onChange({ target: { value: values.floatValue, name } });
      },
      error: !!error,
      customInput: TextField,
      thousandSeparator: true,
      decimalScale: 0
    };
    if (error) props.helperText = error;
    return <NumberFormat fullWidth {...props} />;
  },
  (prev, next) => !["value", "error"].some(key => prev[key] !== next[key])
);

BaseIntegerInput.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.string
};

BaseIntegerInput.defaultProps = {
  value: null,
  label: undefined,
  error: null,
  onChange: undefined
};

export default HocInput(BaseIntegerInput, FormControlStyleRight);
