import rootSagaGenerator from "../common/rootSagaGenerator";
import users from "./users";
import securityProfiles from "./securityProfiles";
import claimSetting from "./claimSetting";
import templates from "./templates";

export default rootSagaGenerator({
  name: "Admin",
  sagas: [...users, ...securityProfiles, ...claimSetting, ...templates]
});
