import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import CustomBadge from "../../../common/CustomBadge";
import Money from "../../../common/MoneyLabel";

const CurrentReserveTotal = ({ css, classes, title, value, ...other }) => (
  <Grid item xs className={classes.sumGridItem} {...other}>
    <Grid className={classes.sumItem} container alignItems="center">
      <Grid item xs={6} style={{ height: "min-content" }}>
        <CustomBadge css={css} cssTitle={classes.sumTitle}>
          {title}
        </CustomBadge>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.sumAmount}>
          <Money value={value} />
        </div>
      </Grid>
    </Grid>
  </Grid>
);

CurrentReserveTotal.propTypes = {
  css: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number
};

CurrentReserveTotal.defaultProps = { value: 0 };

export default CurrentReserveTotal;
