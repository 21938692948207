import React from "react";
import { useContext } from "../context";
import Insured from "./Insured";
import SingleOrMultiple from "./SingleOrMultiple";
import LineOfBusiness from "./LineOfBusiness";
import InsuredVehicle from "./InsuredVehicle";
import InsuredProperty from "./InsuredProperty";

export default () => {
  const [{ multipleClaimants, coverageTypes, insVehicles, insProperties }] = useContext();
  const any = desc => coverageTypes.some(x => x.description === desc && x.lossTypeGroups.some(ltg => ltg.checked));
  const insVehicle = insVehicles.data.length > 0 && any("Automobile Liability");
  const insProperty = insProperties.data.length > 0 && any("Property Liability");
  return (
    <>
      <SingleOrMultiple {...{ multipleClaimants }} />
      <Insured />
      <LineOfBusiness />
      {insVehicle && <InsuredVehicle />}
      {insProperty && <InsuredProperty />}
    </>
  );
};
