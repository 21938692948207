import { LinearProgress, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { createContext, useCallback, useContext, useState } from "react";

const useStyles = makeStyles({
  root: { flexGrow: 1 },
  float: { position: "relative", height: 0, zIndex: 11 },
  absolute: { position: "absolute", top: 0, left: 0, width: "100%", zIndex: 11 }
});

const Loading = ({ float, absolute, ...props }) => {
  const classes = useStyles();
  const css = { [classes.root]: !float && !absolute, [classes.float]: float && !absolute, [classes.absolute]: absolute && !float };
  return (
    <div className={clsx(css)} {...props}>
      <LinearProgress />
    </div>
  );
};

Loading.propTypes = { float: PropTypes.bool, absolute: PropTypes.bool };
Loading.defaultProps = { float: false, absolute: false };

export default Loading;

export const useLoading = (float, absolute) => {
  const [loading, setLoading] = useState(false);
  const actions = {
    showLoading: useCallback(() => setLoading(true), []),
    hideLoading: useCallback(() => setLoading(false), []),
    handleLoading: useCallback(l => setLoading(!!l), [])
  };
  const Component = loading && <Loading float={float || false} absolute={absolute || false} />;
  return { Loading: Component, loading, actions };
};

const LoadingContext = createContext();

export const LoadingContextProvider = ({ absolute, actions, float, values, ...props }) => {
  const hook = useLoading(float, absolute);
  const value = { ...(values || {}), ...hook, actions: { ...(actions || {}), ...hook.actions } };
  return <LoadingContext.Provider value={value} {...props} />;
};

LoadingContextProvider.propTypes = {
  absolute: PropTypes.bool,
  actions: PropTypes.objectOf(PropTypes.func),
  float: PropTypes.bool,
  values: PropTypes.objectOf(PropTypes.any)
};
LoadingContextProvider.defaultProps = { absolute: false, actions: undefined, float: false, values: undefined };

export const useLoadingContext = () => useContext(LoadingContext);

export const useLoadingOrContext = (float, absolute) => {
  const loadingCtx = useLoadingContext();
  const loadingHook = !loadingCtx ? useLoading(float, absolute) : undefined;
  return loadingCtx ? { ...loadingCtx, Loading: null } : loadingHook;
};
