import React, { useEffect, Fragment, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Grid, Button, Paper, makeStyles } from "@material-ui/core";
import Form from "./Form";
import Account from "./Account";
import { BankingContextProvider } from "./BankingContext";
import { get, post, set, del } from "../../../redux/actions/banking";
import { useRouteParam } from "../../../common";
import NoRecords from "../NoRecords";
import { useLoadingOrContext } from "../Loading";

const useStyles = makeStyles({ root: { padding: "1rem 1.5rem", marginBottom: "1rem" } });

const Banking = React.memo(({ type, level, actionsAllowed }) => {
  const entityId = useRouteParam(level ? `${level}Id` : "id");
  const accounts = useSelector(({ client, vendor }) => (level ? client : vendor).accounts, shallowEqual);
  const {
    loading,
    Loading,
    actions: { showLoading, hideLoading }
  } = useLoadingOrContext(false, true);
  const [{ show, account }, setState] = useState({ show: false, account: {} });
  const dispatch = useDispatch();
  const classes = useStyles();

  const toggleForm = () => setState(ps => ({ ...ps, show: !ps.show, account: { type, entityId } }));
  useEffect(() => {
    showLoading();
    dispatch(get({ id: entityId, entityType: type }, hideLoading, hideLoading));
  }, []);

  useEffect(() => {
    if (show) {
      setState(ps => ({ ...ps, show: !ps.show }));
    }
  }, [accounts]);

  const onSubmit = info => {
    const data = { ...info.account, [`${type}Id`]: Number(entityId) };
    const action = data.id > 0 ? set : post;
    showLoading();
    return dispatch(action({ data, entityType: type }, hideLoading, hideLoading));
  };

  const accountProps = {
    onEdit: useCallback(data => setState(ps => ({ show: !ps.show, account: { ...data, type, entityId } })), []),
    onRemove: useCallback(id => dispatch(del({ id, entityType: type, entityId })), []),
    actionsAllowed
  };

  return (
    <Fragment key="banking-index">
      {Loading}
      {
        <>
          <Paper className={classes.root}>
            <Grid container justify="flex-end">
              {actionsAllowed.create === true && (
                <Button variant="contained" color="primary" onClick={toggleForm} disabled={show}>
                  Add Account
                </Button>
              )}
            </Grid>
            {show && (
              <BankingContextProvider {...{ account, onSubmit, toggleForm }}>
                <Form onCancel={toggleForm} {...{ loading }} />
              </BankingContextProvider>
            )}
          </Paper>
          {accounts.map((b, ix) => (
            <Account key={b.id} data={b} index={ix + 1} {...accountProps} />
          ))}
          {!accounts.length && <NoRecords />}
        </>
      }
    </Fragment>
  );
});

Banking.propTypes = {
  selector: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  level: PropTypes.string,
  actionsAllowed: PropTypes.objectOf(PropTypes.any)
};

Banking.defaultProps = {
  level: "",
  actionsAllowed: { create: true, update: true, delete: true }
};

export default Banking;
