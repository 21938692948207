import { INITIALREPORT as REPORT, API } from "../../actions/actionTypes";
import { getSuccess, makeClaimSuccess, setSuccess } from "../../actions/initialReport/claim";
import { takeLatest, takeLatestSet } from "../common/sagaGenerator";

const url = `${API.URL}/claim`;

export default [
  takeLatest({
    type: REPORT.CLAIM.GET,
    actionSuccess: getSuccess,
    params: ({ id }) => id,
    errorMessage: "Error loading Claim",
    url: ({ id }) => `${url}/${id}`
  }),
  takeLatestSet({
    type: REPORT.CLAIM.SET,
    actionSuccess: setSuccess,
    params: ({ claim }) => claim,
    successMessage: "Claim Saved",
    errorMessage: "Error saving claim",
    url
  }),
  takeLatestSet({
    type: REPORT.MAKE_CLAIM,
    actionSuccess: makeClaimSuccess,
    url: ({ id }) => `${url}/makeclaim?claimId=${id}`,
    successMessage: "Claim successfully created",
    errorMessage: "Unable to convert to a claim"
  })
];
