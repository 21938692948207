const expand = (type, url, params, other, data) => ({ type, url, data: { ...params, ...(data ? { data } : {}) }, ...(other || {}) });

export const withParams = (type, url) => (params, data, other) => expand(type, url, params, other, data);

export const getByParentId = (type, url) => (params, other) => expand(type, url, params, other);

export const loadByParentId = (params, type) => ({ type, ...params });

const noMapPaths = ["indexingStatusId", "data"];

const paths = [
  "claimId",
  "occurrenceId",
  "catastropheId",
  "noteId",
  "clientId",
  "policyId",
  "billId",
  "vendorId",
  "communicationTemplateId",
  "communicationDetailId",
  ...noMapPaths
];

const mapActionProps = props =>
  ["onDownloadProgress", "onDownloadSuccess", "onUploadProgress", "onSuccess", "onError"].reduce((acc, p) => ({ ...acc, [p]: props[p] }), {});

const mapPath = (list, single) => {
  const res = [...(Array.isArray(list) ? list : []), ...(typeof single !== "undefined" ? [single] : [])];
  if (res.length > 0) return res;
  return undefined;
};

const mapData = (props, noMap) =>
  paths.reduce((acc, p) => {
    if (noMap || noMapPaths.indexOf(p) >= 0) return { ...acc, [p]: props[p] };
    return { ...acc, [`${p}s`]: mapPath(props[`${p}s`], props[p]) };
  }, {});

const download = (type, url) => props => ({ type, data: mapData(props), ...mapActionProps(props), url });

export const uploadFiles = (type, url) => props => ({ type, data: mapData(props, true), ...mapActionProps(props), url });

export const moveFiles = (type, url) => props => ({ type, url, data: mapData(props, true), ...mapActionProps(props) });

export const downloadFiles = download;

export const openFile = download;
