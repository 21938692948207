import React, { useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import qs from "qs";
import PropTypes from "prop-types";
import { InputText, SelectInput } from "../inputs";
import { BankingContext } from "./BankingContext";
import FormFooter from "../FormFooter";
import Search from "../SearchSuggestions/InputSuggestionsDebounced";
import { API } from "../../../redux/actions/actionTypes";
import useYup from "./yup";

const url = `${API.URL}/banking/banksearch`;

const BankingForm = ({ onCancel, loading }) => {
  const { handleValidateSchema, validationMessages } = useYup();

  const { state, handleChange, handleSubmit, states, accountTypes, locations, dispatch } = useContext(BankingContext);
  const {
    isNew,
    accountName,
    accountTypeId,
    accountNumber,
    accountStatus,
    bankName,
    bankAddress,
    bankCity,
    bankStateId,
    startingCheckNumber,
    routingNumber,
    fractionABANumber,
    relatedVendorId
  } = state.account || {};
  const accountStatusList = [
    { id: 1, text: "Active" },
    { id: 2, text: "Inactive" }
  ];
  const accountStatusId = accountStatus === true ? 1 : 2;
  const handleChangeStatus = () => {
    dispatch({ type: "SET_FIELD", payload: { name: "accountStatus", value: accountStatusId === 2 } });
  };
  const onSelect = op => {
    return dispatch({ type: "SET_BANK", bank: { ...op } });
  };
  const querySearchParams = query => {
    return { searchText: query };
  };
  const axiosConfig = { paramsSerializer: params => qs.stringify(params, { indices: false }) };

  useEffect(() => {
    handleValidateSchema({ accountName, accountTypeId, accountNumber, bankName, bankAddress, bankCity, bankStateId });
  }, [accountName, accountTypeId, accountNumber, bankName, bankAddress, bankCity, bankStateId]);

  const onSubmit = e => {
    e.preventDefault();
    const { isValid } = handleValidateSchema({ accountName, accountTypeId, accountNumber, bankName, bankAddress, bankCity, bankStateId });
    if (isValid) {
      handleSubmit(e);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Search
            name="bankName"
            value={bankName}
            label="Bank Name"
            queryParams={querySearchParams(bankName)}
            axiosConfig={axiosConfig}
            onChange={handleChange}
            {...{ onSelect, url }}
            error={validationMessages?.bankName}
          />
          <InputText name="accountName" label="Account Name" value={accountName} onChange={handleChange} error={validationMessages?.accountName} />
          <InputText name="startingCheckNumber" label="Starting Check Number" value={startingCheckNumber} onChange={handleChange} />
        </Grid>
        <Grid item xs>
          <InputText
            name="bankAddress"
            label="Bank Street"
            value={bankAddress}
            onChange={handleChange}
            disabled
            error={validationMessages?.bankAddress}
          />
          <SelectInput
            name="accountTypeId"
            label="Account Type"
            value={accountTypeId}
            options={accountTypes}
            onChange={handleChange}
            error={validationMessages?.accountTypeId}
          />
          <InputText name="routingNumber" label="Routing Number" value={routingNumber} onChange={handleChange} />
        </Grid>
        <Grid item xs>
          <InputText name="bankCity" label="Bank City" value={bankCity} onChange={handleChange} disabled error={validationMessages?.bankCity} />
          <InputText
            name="accountNumber"
            label="Account Number"
            value={accountNumber}
            onChange={handleChange}
            error={validationMessages?.accountNumber}
          />
          <InputText name="fractionABANumber" label="Fraction ABA Number" value={fractionABANumber} onChange={handleChange} />
        </Grid>
        <Grid item xs>
          <SelectInput
            name="bankStateId"
            label="Bank State"
            value={bankStateId}
            options={states}
            onChange={handleChange}
            disabled
            error={validationMessages?.bankStateId}
          />
          <SelectInput
            name="accountStatusId"
            label="Account Status"
            value={accountStatusId}
            options={accountStatusList}
            onChange={handleChangeStatus}
          />

          {locations.length > 1 && (
            <SelectInput name="relatedVendorId" label="Location" value={relatedVendorId} options={locations} onChange={handleChange} />
          )}
        </Grid>
      </Grid>
      <FormFooter isAdd={isNew} editMode onCancel={onCancel} saveBtnDisabled={loading} />
    </form>
  );
};

BankingForm.propTypes = { onCancel: PropTypes.func.isRequired, loading: PropTypes.bool.isRequired };

export default BankingForm;
