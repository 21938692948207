import * as yup from "yup";
import { dateSchema as date, isBeforeToday, isAfterDate, isNumber, isDate } from "../../../../common/yup";

const number = () => yup.number().nullable();
const testTreatment = (val, ctx) => !ctx?.parent?.treatmentReceived || isNumber(val);

export default yup.object().shape({
  employeeAccident: yup.object().shape({
    froiReceivedDate: date("FROI Received Date Required").test("isDate", "FROI Received Date has to be before today.", v => isBeforeToday(v))
  }),
  dateReported: date("Date Reported Required")
    .test("isDate", "Date reported has to be before today.", v => isBeforeToday(v))
    .test("isDateBeforeDate", "Date reported has to be after Date of Loss.", (v, c) => {
      return isAfterDate(v, c?.parent?.dateofLoss);
    }),
  claimLossTypeId: yup.number().required("Loss Type Required"),
  claimCauseCodeId: yup.number().required("Cause Code Required"),
  dateofLoss: date("Date of Injury Required"),
  location: yup.object().shape({
    address1: yup
      .string()
      .nullable()
      .required("Address Required"),
    city: yup
      .string()
      .nullable()
      .required("City Required"),
    stateId: yup
      .number()
      .nullable()
      .required("State Required"),
    zipCode: yup
      .string()
      .nullable()
      .required("Zip Required")
  }),
  bodilyInjury: yup.object().shape({
    isDeath: yup.boolean().nullable(),
    claimantDateofDeath: date("Date of Death Required", (v, c) => !c?.parent?.isDeath || isDate(v)),
    injuryTypeId: yup.number().required("Injury Type Required"),
    parentBodyPartId: yup.number().required("Body Part Group Required"),
    bodyPartId: yup.number().required("Body Part Required"),
    // injuryCauseId: yup.number().required("Cause Code Required"),
    treatmentReceived: yup.boolean(),
    treatmentTypeId: number().test("treatmentReceived", "Initial Treatment Type Required", testTreatment)
    // hospitalId: number().test("treatmentReceived", "Hospital Name Required", testTreatment)
  })
});
