import insured from "./insured";
import incident from "./incident";
import claimant from "./claimant";
import damagedVehicle from "./damagedVehicle";
import damagedProperty from "./damagedProperty";
import bodilyInjury from "./bodilyInjury";

export default {
  step: 0,
  multipleClaimants: false,
  duplicates: [],
  insured,
  coverageTypes: [],
  insProperties: { data: [] },
  insVehicles: { data: [] },
  insVehicle: { driver: { location: {} }, vehicle: {}, vehicleState: undefined },
  insProperty: {},
  incident,
  occurrences: [],
  claimant,
  claimants: [],
  damagedVehicle,
  damagedProperty,
  bodilyInjury,
  witness: [],
  documents: [],
  attorneys: [],
  folderSettings: [],
  firms: [],
  lawyers: [],
  fromInitialReport: false
};
