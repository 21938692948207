import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import MaterialTable from "../../../common/table/MaterialTable";
import { getPoliciesByLineOfBusiness } from "../../../../redux/actions/claim/coverage";
import { getPolicy } from "../../../../redux/actions/client/insured/policy/policy";
import useStyles from "./index.styles";
import { colors } from "../../../common/index.styles";
import DocumentInformation from "./DocumentInformation";
import InsuredInfo from "./InsuredInfo";
import CustomModal from "../../../common/CustomModal";
import SidePanel from "../../../common/SidePanel";
import { useRouteParam } from "../../../../common";
import LinkButton from "../../../common/LinkButton";
import { useLoadingContext } from "../../../common/Loading";

const PolicySidePanel = ({ openPanel, onTogglePanel, onSave }) => {
  const classes = useStyles();
  const claimId = useRouteParam("claimId");
  const [openCustomModal, setOpenCustomModal] = useState(false);
  const [policySelected, setPolicy] = useState({});
  const dispatch = useDispatch();
  const handleOpenModal = () => setOpenCustomModal(prev => !prev);
  const storeData = useSelector(({ claim }) => claim, shallowEqual);
  const { policy, insured, coveragePoliciesByLob: policies } = storeData || {};
  const {
    actions: { showLoading, hideLoading }
  } = useLoadingContext();
  useEffect(() => {
    if (claimId) dispatch(getPoliciesByLineOfBusiness(claimId));
  }, [claimId]);

  useEffect(() => {
    if (openCustomModal) handleOpenModal();
    if (openPanel) onTogglePanel();
  }, [policy]);

  const handleOpenPolicy = ({ id, containsDamagedProperty }) => {
    showLoading();
    return dispatch(
      getPolicy(
        id,
        payload => {
          hideLoading();
          onTogglePanel();
          handleOpenModal();
          setPolicy({ ...payload, containsDamagedProperty });
        },
        hideLoading
      )
    );
  };

  const handleSetPolicyAsActive = () => onSave(policySelected);

  const tableProps = {
    title: "",
    width: 555,
    columns: [
      {
        title: "Number",
        render: rowData => (
          <LinkButton blue onClick={() => handleOpenPolicy(rowData)}>
            {rowData.number}
          </LinkButton>
        )
      },
      { title: "Policy Type", field: "policyType" },
      { title: "Eff. Date", render: rowData => (rowData.effectiveDate ? moment(rowData.effectiveDate).format("M/D/YYYY") : null) },
      { title: "Exp. Date", render: rowData => (rowData.expirationDate ? moment(rowData.expirationDate).format("M/D/YYYY") : null) }
    ],
    data: policies || [],
    paging: false,
    search: false,
    actions: [],
    options: {
      toolbar: false,
      headerStyle: {
        backgroundColor: colors.gray,
        color: "#000000"
      },
      search: false,
      paging: false
    }
  };

  return (
    <>
      <SidePanel open={openPanel} width={604} className={classes.sidePanel} fixed>
        <Grid container>
          <Grid item style={{ flexGrow: 1 }}>
            <Typography gutterBottom className="tc-title">
              Select Policy
            </Typography>
          </Grid>
          <Grid item>
            <Close onClick={onTogglePanel} className={classes.close} />
          </Grid>
        </Grid>
        <InsuredInfo isActive={insured.isActive} name={insured.name} number={insured.number} level={insured.levelName} levelId={insured.levelId} />
        <MaterialTable {...tableProps} />
      </SidePanel>
      <CustomModal
        openModal={openCustomModal}
        title="Policy/ Certificate"
        onToggle={handleOpenModal}
        onAction={handleSetPolicyAsActive}
        actionBtnText="APPLY THIS POLICY TO THE CLAIM"
        maxWidth="lg"
        classes={useStyles}
      >
        <div style={{ padding: "0 16px" }}>
          <DocumentInformation {...{ policy: policySelected, classes }} />
        </div>
      </CustomModal>
    </>
  );
};

PolicySidePanel.propTypes = {
  openPanel: PropTypes.bool.isRequired,
  onTogglePanel: PropTypes.func,
  onSave: PropTypes.func.isRequired
};
PolicySidePanel.defaultProps = {
  onTogglePanel: () => {}
  // handlePolicySelected: () => {}
};

export default PolicySidePanel;
