import OCCURRENCE from "../actions/actionTypes/occurrence";
import initialState from "./initialState";

export default function occurrenceReducer(state = initialState.occurrence, action) {
  switch (action.type) {
    case OCCURRENCE.RESET:
      return initialState.occurrence;
    case OCCURRENCE.GET_SUCCESS:
    case OCCURRENCE.ASSOCIATECLAIM_SUCCESS:
    case OCCURRENCE.DISASSOCIATECLAIM_SUCCESS:
      return { ...state, occurrence: action.occurrence };
    case OCCURRENCE.GETALL_SUCCESS:
    case OCCURRENCE.POST_SUCCESS:
    case OCCURRENCE.DEL_SUCCESS:
      return { ...state, occurrences: action.occurrences };
    case OCCURRENCE.SET_SUCCESS:
      return { ...state, occurrence: { ...state.occurrence, ...action.occurrence } };
    case OCCURRENCE.NOTES.GET_SUCCESS:
    case OCCURRENCE.NOTES.POST_SUCCESS:
    case OCCURRENCE.NOTES.SET_SUCCESS:
    case OCCURRENCE.NOTES.DEL_SUCCESS:
      return { ...state, notes: action.notes };
    case OCCURRENCE.NOTES.GET_DRAFT_SUCCESS:
    case OCCURRENCE.NOTES.POST_DRAFT_SUCCESS:
      return { ...state, draftNote: action.draft };
    case OCCURRENCE.DIARY.GET_SUCCESS:
    case OCCURRENCE.DIARY.POST_SUCCESS:
    case OCCURRENCE.DIARY.SET_SUCCESS:
    case OCCURRENCE.DIARY.DEL_SUCCESS:
      return { ...state, diaries: action.diaries };
    case OCCURRENCE.GET_RESERVES_SUCCESS:
      return { ...state, reserves: action.reserves };
    case OCCURRENCE.LOAD_FILES_SUCCESS:
      return { ...state, files: action.files };
    case OCCURRENCE.UPLOAD_FILES_SUCCESS:
    case OCCURRENCE.UPDATE_FILE_SUCCESS:
    case OCCURRENCE.DELETE_FILE_SUCCESS:
      return { ...state, occurrence: { ...state.occurrence, documents: action.documents } };
    case OCCURRENCE.DIARY.TEMPLATES.GET_SUCCESS:
      return { ...state, diaryTemplates: action.templates };
    case OCCURRENCE.GET_FILTER_FIELD_SUCCESS:
      return { ...state, filterFields: action.filterFields };
    default:
      return state;
  }
}
