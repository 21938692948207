import React, { useState, useEffect, Fragment, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Collapse, Grid } from "@material-ui/core";
import { CheckboxLabel, DateTimeInput, SelectInput, InputText } from "../../../common/inputs";
import CollapsibleBox, { useCollapsibleBox } from "../../../common/CollapsibleBox";
import { get, set, post } from "../../../../redux/actions/claim/legal/mediationArbitration";
import { useRouteParam } from "../../../../common";
import FooterSave from "../../../common/FooterSave";
import ReadMode from "./readMode/MediationArbitration";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import useYupValidationSchema from "../../../common/customHooks/useYupValidationSchema";
import { mediationArbitrationSchema } from "./yup";

export default props => {
  const claimId = useRouteParam("claimId");
  const store = useSelector(({ common, claim }) => ({ common, claim }), shallowEqual);
  const [state, setState] = useState({ location: {} });
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "legal");
  const dispatch = useDispatch();
  const {
    props: { boxProps, editMode, loading, Loading },
    actions: { showLoading, hideLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  useEffect(() => {
    if (access.read) {
      showLoading();
      dispatch(get(claimId, hideLoading, hideLoading));
    }
  }, []);
  const {
    common: { states },
    claim: { mediationArbitration }
  } = store || {};
  const { date, description, arbitration, arbitrationFirstName, arbitrationLastName, location } = state || {};
  const { address1, city, stateId } = location || {};
  useEffect(() => {
    setState(mediationArbitration);
    onToggleEdit();
  }, [mediationArbitration]);
  const { handleValidateSchema, validationMessages } = useYupValidationSchema(mediationArbitrationSchema);
  const onChange = ({ target: { name, value } }) => {
    if (name.indexOf("location") > -1) return setState(ps => ({ ...ps, location: { ...ps.location, [name.split(".")[1]]: value } }));
    return setState(ps => {
      handleValidateSchema({ ...ps, [name]: value });
      return { ...ps, [name]: value };
    });
  };
  const handleSave = () => {
    if (access.update) {
      if (!handleValidateSchema(state).isValid) return;
      showLoading();
      dispatch((state.id ? set : post)({ ...state, claimId }, onSuccessEdit, hideLoading));
    }
  };
  const onChangeArbitration = ({ target: { name, value } }) =>
    setState(ps => ({ ...ps, [name]: value, arbitrationFirstName: "", arbitrationLastName: "" }));
  return (
    <CollapsibleBox title="Mediation/Arbitration" name="mediationArbitration" {...props} {...boxProps}>
      {Loading}
      {(editMode && (
        <Fragment key="editMode">
          <Grid container spacing={4}>
            <Grid item xs>
              <DateTimeInput
                name="date"
                label="Date"
                value={date}
                onChange={({ target: { value } }) => onChange({ target: { name: "date", value: value || undefined } })}
                keyboard
                error={validationMessages.date}
              />
            </Grid>
            <Grid item xs>
              <InputText name="location.address1" label="Address" value={address1} onChange={onChange} />
            </Grid>
            <Grid item xs>
              <InputText name="location.city" label="City" value={city} onChange={onChange} />
            </Grid>
            <Grid item xs>
              <SelectInput name="location.stateId" label="State" value={stateId} options={states} onChange={onChange} />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs>
              <InputText name="description" label="Description" value={description} onChange={onChange} rows={4} />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item lg={2} md={2} sm={12} xs={12}>
              <CheckboxLabel label="Arbitration" checked={arbitration} name="arbitration" onChange={onChangeArbitration} />
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Collapse in={arbitration} timeout="auto">
                <InputText
                  name="arbitrationFirstName"
                  label="Arbitration First Name"
                  value={arbitrationFirstName}
                  onChange={onChange}
                  error={validationMessages.arbitrationFirstName}
                />
              </Collapse>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Collapse in={arbitration} timeout="auto">
                <InputText
                  name="arbitrationLastName"
                  label="Arbitration Last Name"
                  value={arbitrationLastName}
                  onChange={onChange}
                  error={validationMessages.arbitrationLastName}
                />
              </Collapse>
            </Grid>
          </Grid>
          <FooterSave onCancel={onToggleEdit} onSave={handleSave} isDisable={loading} />
        </Fragment>
      )) || <ReadMode />}
    </CollapsibleBox>
  );
};
