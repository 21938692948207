import { Checkbox, Chip, ListItemText, MenuItem } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { MultipleSelectInput } from "../../../common/inputs";

const UsersSelect = ({ claim, onChange, ...props }) => {
  const users = useSelector(({ common: { users: u } }) => u, shallowEqual);
  const userProps = {
    ...props,
    onChange,
    options: users
      .map(({ id, text }) => {
        const roles = [
          ["supervisorId", "Supervisor"],
          ["adjusterId", "Adjuster"],
          ["assistantId", "Assistant"]
        ].reduce((acc, [path, name]) => ((claim || {})[path] === id ? [...acc, name] : acc), []);
        if (roles.length === 0) return { id, text };
        return { id, text, description: `${roles.join(" - ")}` };
      })
      .sort((a, b) => {
        if (!a.description && b.description) return 1;
        if (a.description && !b.description) return -1;
        return a.text.localeCompare(b.text);
      }),
    renderOptions: (options, value) =>
      options.map(({ id, text, description }) => (
        <MenuItem key={`userId-item-${id}`} value={id}>
          <Checkbox checked={value.includes(id)} />
          <ListItemText primary={text} secondary={description} />
        </MenuItem>
      )),
    renderValue: (selected, options) => (
      <div className="tc-flex tc-flex-wrap tc-m-2px">
        {options
          .filter(({ id }) => selected.some(s => s === id))
          .map(({ id, text }) => (
            <Chip key={id} label={text} icon={<Person />} className="tc-m2px" />
          ))}
      </div>
    )
  };
  return <MultipleSelectInput {...userProps} />;
};

UsersSelect.propTypes = { claim: PropTypes.objectOf(PropTypes.any).isRequired, onChange: PropTypes.func.isRequired };

export default UsersSelect;
