import React, { useEffect, useState, Fragment } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { Collapse, Grid, Button } from "@material-ui/core";
import { CheckboxLabel, DateTimeInput, PhoneInput, SelectInput, TextInput, IntegerInput } from "../../inputs";
import useStyles from "./Claimant.style";
import validationSchema from "./yup";
import useYupValidationSchema from "../../customHooks/useYupValidationSchema";

const ClaimantForm = ({ claimant, claimLineOfBusinessId, onClose, onSave, isLoading }) => {
  const classes = useStyles();
  const { gender, maritalStatus, states, taxType } = useSelector(
    ({ common }) => ({
      gender: common.gender,
      maritalStatus: common.maritalStatus,
      states: common.states,
      taxType: common.taxType
    }),
    shallowEqual
  );
  const { location, mailingLocation } = claimant;
  const [state, setState] = useState({ ...claimant, claimantTypeId: claimant.isPerson ? 1 : 2 });
  const [phAddress, setPhAddress] = useState(location || { id: 0 });
  const [mailAddress, setMailAddress] = useState(mailingLocation || { id: 0 });
  const [phisicalAsMailing, setPhAsMail] = useState(phAddress.id === mailAddress.id);
  const [loaded, setLoaded] = useState(false);
  const { validationMessages, handleValidateSchema } = useYupValidationSchema(validationSchema);
  const handleValidation = () => handleValidateSchema({ ...state, phisicalAsMailing, location: phAddress, mailAddress });
  useEffect(() => {
    if (loaded) handleValidation();
    else setLoaded(true);
  }, [state, phAddress, mailAddress, phisicalAsMailing]);
  const onChange = fn => ({ target: { name: path, value } }) => {
    const [, name] = path.split(".");
    if (name === "claimantTypeId")
      return fn(prevState => ({
        ...prevState,
        [name]: value,
        isPerson: value === 1,
        firstName: undefined,
        middleName: undefined,
        lastName: undefined,
        commercialName: undefined,
        maritalStatusId: undefined,
        genderId: undefined,
        dependents: undefined,
        phone: undefined,
        contact: undefined
      }));
    return fn(prevState => ({ ...prevState, [name]: value }));
  };
  const onPhAsMailChange = ({ target: { value } }) => {
    if (value) setMailAddress({ id: phAddress.id });
    else setMailAddress({ id: -1 });
    setPhAsMail(value);
  };
  const handleSubmit = event => {
    event.preventDefault();
    const { isValid } = handleValidation();
    if (isValid) onSave({ ...state, phisicalAsMailing, phAddress, mailAddress });
  };
  const inputProps = path => {
    const [ent, name] = path.split(".");
    const { st, error, action } = (() => {
      switch (ent) {
        case "phAddress":
          return { st: phAddress, error: "location.", action: setPhAddress };
        case "mailAddress":
          return { st: mailAddress, error: "mailAddress.", action: setMailAddress };
        default:
          return { st: state, action: setState };
      }
    })();
    return {
      name: path,
      value: st[name] || undefined,
      onChange: onChange(action),
      error: (validationMessages || {})[`${error || ""}${name}`]
    };
  };
  const claimantTypes = [
    { id: 1, text: "Person" },
    { id: 2, text: "Entity" }
  ];
  return (
    <Fragment key="claimant-form-box">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs>
            {claimLineOfBusinessId !== 4 && <SelectInput label="Claimant Type" {...inputProps("claimant.claimantTypeId")} options={claimantTypes} />}
            <Collapse in={state.isPerson} timeout="auto">
              <TextInput {...inputProps("claimant.firstName")} label="First Name *" />
              <TextInput {...inputProps("claimant.middleName")} label="Middle Name" />
              <TextInput {...inputProps("claimant.lastName")} label="Last Name *" />
            </Collapse>
            <Collapse in={!state.isPerson} timeout="auto">
              <TextInput {...inputProps("claimant.commercialName")} label="Commercial Name *" />
              <TextInput {...inputProps("claimant.contact")} label="Main Contact *" />
            </Collapse>
            <Collapse in={state.isPerson} timeout="auto">
              <DateTimeInput {...inputProps("claimant.dateofBirth")} label="Date of Birth *" keyboard />
              <Grid container>
                <Grid item xs className={classes.pr1}>
                  <SelectInput {...inputProps("claimant.genderId")} label="Gender" options={gender} />
                </Grid>
                <Grid item xs className={classes.pl1}>
                  <SelectInput {...inputProps("claimant.maritalStatusId")} label="Marital Status" options={maritalStatus} />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid item xs>
            <Collapse in={state.isPerson} timeout="auto">
              <IntegerInput {...inputProps("claimant.dependents")} label="Dependents" />
            </Collapse>
            <Grid container>
              <Grid item xs={7} className={classes.pr1}>
                <SelectInput {...inputProps("claimant.taxIDTypeId")} label="Tax ID Type *" options={taxType} />
              </Grid>
              <Grid item xs={5} className={classes.pl1}>
                <TextInput {...inputProps("claimant.taxID")} label="Tax ID # *" />
              </Grid>
            </Grid>
            <TextInput {...inputProps("phAddress.address1")} label="Address 1 *" />
            <TextInput {...inputProps("phAddress.address2")} label="Address 2" />
            <TextInput {...inputProps("phAddress.city")} label="City *" />
            <SelectInput {...inputProps("phAddress.stateId")} label="State *" options={states} />
          </Grid>
          <Grid item xs>
            <TextInput {...inputProps("phAddress.zipCode")} label="Zip *" />
            <Collapse in={state.isPerson} timeout="auto">
              <PhoneInput {...inputProps("claimant.phone")} label="Home Phone" />
              <PhoneInput {...inputProps("claimant.mobile.1")} label="Cell Phone" />
            </Collapse>
            <PhoneInput {...inputProps("claimant.workPhone")} label="Work Phone" />
            <TextInput {...inputProps("claimant.workEmail")} label="Work Email" />
            <TextInput {...inputProps("claimant.email")} label="Personal Email" />
            <Collapse in={!state.isPerson} timeout="auto">
              <PhoneInput {...inputProps("claimant.mobile.2")} label="Cell Phone" />
            </Collapse>
          </Grid>
        </Grid>
        <CheckboxLabel
          label="Use Physical Address as Mailing Address"
          name="address.phisicalAsMailing"
          checked={phisicalAsMailing}
          onChange={onPhAsMailChange}
        />
        <Collapse in={!phisicalAsMailing} timeout="auto">
          <Grid container spacing={4}>
            <Grid item xs>
              <TextInput {...inputProps("mailAddress.address1")} label="Mailing Address *" />
              <TextInput {...inputProps("mailAddress.city")} label="City *" />
            </Grid>
            <Grid item xs>
              <SelectInput {...inputProps("mailAddress.stateId")} label="State *" options={states} />
            </Grid>
            <Grid item xs>
              <TextInput {...inputProps("mailAddress.zipCode")} label="Zip *" />
            </Grid>
          </Grid>
        </Collapse>

        <Grid container alignItems="flex-start" justify="flex-end" direction="row">
          <div className={classes.buttonWrapper}>
            <Button variant="outlined" color="primary" onClick={onClose}>
              CANCEL
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
              SAVE
            </Button>
          </div>
        </Grid>
      </form>
    </Fragment>
  );
};

ClaimantForm.propTypes = {
  claimant: PropTypes.objectOf(PropTypes.any).isRequired,
  claimLineOfBusinessId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

ClaimantForm.defaultProps = {
  isLoading: false
};

export default ClaimantForm;
