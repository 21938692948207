import React, { useContext } from "react";
import Indexing from "./common";
import ActionsAllowed from "../common/contexts/ActionsAllowed";
import { setExportTitleFileName } from "../../common";

export default () => {
  const routesAllowed = useContext(ActionsAllowed);
  const access = routesAllowed.find(elem => elem.route === "reindexing");
  return <Indexing statusId={4} access={access} exportFileName={setExportTitleFileName("DocumentsReIndexingList")} />;
};
