import { CLAIM, API } from "../../../actions/actionTypes";
import { getSuccess, setSuccess } from "../../../actions/claim/legal/settled";
import { takeLatest, takeLatestSet } from "../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLAIM.LEGAL.SETTLED.GET,
    url: `${API.URL}/trial/settledInformation`,
    params: ({ claimId }) => ({ claimId }),
    actionSuccess: getSuccess,
    errorMessage: "Error loading settled information"
  }),
  takeLatestSet({
    type: CLAIM.LEGAL.SETTLED.SET,
    url: `${API.URL}/trial/settledInformation`,
    params: ({ settled }) => settled,
    actionSuccess: setSuccess,
    successMessage: "Settled information successfully saved",
    errorMessage: "Unable to save the information"
  })
];
