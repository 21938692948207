import { Tab } from "@material-ui/core";
import React, { Fragment, useRef, useEffect, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation, useRouteMatch, Link } from "react-router-dom";
import { useRouteParam, getUserAccess } from "../../common";
import ActionsAllowed from "../../components/common/contexts/ActionsAllowed";
import Loading, { LoadingContextProvider } from "../../components/common/Loading";
import SimpleTabs from "../../components/common/tabs/SimpleTabs";
import { getSecProfFilter } from "../../redux/actions/initialReport/reports";
import ReportRoutes, { routes } from "./Routes";

export default () => {
  const [state, setState] = useState({ padding: true, breadcrumb: true });
  const claimId = useRouteParam("claimId");
  const { isExact, url } = useRouteMatch();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const store = useSelector(
    ({
      profile,
      initialReport: {
        filterfields,
        claim: { number }
      },
      admin: {
        claimSettings: { documents }
      }
    }) => ({ userProfile: profile, filters: filterfields, isDocumentTabActive: documents, number }),
    shallowEqual
  );
  const { userProfile, filters, isDocumentTabActive, number } = store;
  useEffect(() => {
    dispatch(getSecProfFilter(claimId));
  }, []);
  const routesAllowed = routes.reduce((acc, [route]) => {
    if (!userProfile.securityProfilesDetails?.length || !filters?.lineOfBusinessId) return acc;
    const iClaimId = userProfile.claimIds.indexOf(claimId);
    const hasPermissionByClaim = userProfile.claimRestriction === true ? iClaimId === -1 : iClaimId > -1;
    const routeAccess = {
      ...getUserAccess(
        userProfile.securityProfilesDetails,
        `CLAIM.${route.toUpperCase()}`,
        filters.clientId,
        filters.stateId,
        filters.lineOfBusinessId
      ),
      route
    };
    routeAccess.read = ((route === "documents" && isDocumentTabActive) || route !== "documents") && routeAccess.read && hasPermissionByClaim;
    if (route === "notes") {
      const accessNoteConf = getUserAccess(
        userProfile.securityProfilesDetails,
        "CLAIM.NOTES.CONFIDENTIAL",
        filters.clientId,
        filters.stateId,
        filters.lineOfBusinessId
      );
      return [...acc, routeAccess, { ...accessNoteConf, route: "notes-confidential" }];
    }
    return [...acc, routeAccess];
  }, []);
  const isRouteRead = r => routesAllowed.some(({ route, read }) => route === r && read);
  const tabsToShow = routes.reduce((acc, [route, label]) => (isRouteRead(route) ? [...acc, [route, label]] : acc), []);
  const handlePadding = useCallback(p => setState(ps => ({ ...ps, padding: p })), []);
  const handleBreadcrumb = useCallback(b => setState(ps => ({ ...ps, breadcrumb: b })), []);
  const container = useRef(null);
  const { padding, breadcrumb } = state;
  const breadcrumbs = {
    breadData: {
      linkTitle: "INITIAL REPORT",
      to: "/claims/initialReports"
    },
    activeTitle: (() => {
      const claim = `${number || `Initial Report ${claimId}`}`;
      const label = (tabsToShow.find(([route]) => pathname === `${url}/${route}`) || ["", ""])[1];
      if (!label) return claim;
      return `${claim}: ${label}`;
    })()
  };
  return (
    <div ref={container} className="tc-page-container">
      {(!tabsToShow.length && <Loading float />) || (
        <Fragment key="initial-report-page">
          {isExact && <Redirect to={`${url}/${tabsToShow[0][0]}`} />}
          <SimpleTabs value={url === pathname ? `${url}/overview` : pathname}>
            {tabsToShow.map(([route, label]) => (
              <Tab key={route} label={label} component={Link} to={`${url}/${route}`} value={`${url}/${route}`} />
            ))}
          </SimpleTabs>
          <LoadingContextProvider float actions={{ handlePadding, handleBreadcrumb }} values={{ padding, breadcrumb, breadcrumbs }}>
            <ActionsAllowed.Provider value={routesAllowed}>
              <ReportRoutes containerRef={container} />
            </ActionsAllowed.Provider>
          </LoadingContextProvider>
        </Fragment>
      )}
    </div>
  );
};
