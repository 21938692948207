import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  pr1: {
    paddingRight: theme.spacing(1)
  },
  pl1: {
    paddingLeft: theme.spacing(1)
  },
  marginDevider: {
    marginBottom: theme.spacing(2)
  },
  buttonWrapper: {
    "& > *": {
      margin: theme.spacing(1)
    }
  }
}));
