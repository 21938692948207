import React, { useMemo } from "react";
import PropTypes from "prop-types";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { makeStyles, Paper, Box } from "@material-ui/core";
import moment from "moment";
import MaterialTable from "../table/MaterialTable";
import { setExportTitleFileName } from "../../../common";
import HtmlParser from "../HtmlParser";

const useStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(1, 2) }
}));

const ClaimActivity = ({ activities, ...props }) => {
  const classes = useStyles();

  const getDescriptionFromJson = object =>
    Object.keys(object).reduce((acc, property) => {
      let value = object[property];
      if (property.toLowerCase().includes("date"))
        if (property === "DateOfLoss") value = `${moment(value).format("M/D/YYYY")} at ${moment(value).format("h:mm a")}`;
        else value = `${moment(value).format("M/D/YYYY")}`;
      if (property.toLowerCase().includes("time")) value = `${moment(value).format("h:mm a")}`;
      const label = property.replace(/([a-z])([A-Z])/g, "$1 $2");
      return `${acc}${acc ? ", " : ""} ${label[0].toUpperCase() + label.slice(1)}: ${value}`;
    }, "");

  const customDetailPanel = rowData => {
    return (
      <Box display={{ xs: "block", sm: "block", md: "block", lg: "block", xl: "none" }} style={{ textAlign: "left", padding: 16 }}>
        <span style={{ fontWeight: 700, marginRight: 10 }}>Description:</span>
        <HtmlParser value={rowData.description} />
      </Box>
    );
  };
  const vActivities = useMemo(
    () =>
      activities.map(elem => {
        const jsonNewDesc = elem.jsonNewDetail ? `${getDescriptionFromJson(elem.jsonNewDetail)}` : "";
        const jsonOldDesc = elem.jsonOldDetail ? `<br/>Previous information:<br/>${getDescriptionFromJson(elem.jsonOldDetail)}` : "";
        const newDescription = `${elem.description} ${jsonNewDesc} ${jsonOldDesc}`;
        return {
          ...elem,
          generatedOn: `${moment(elem.generatedOn).format("M/D/YYYY")} at ${moment(elem.generatedOn).format("h:mm a")}`,
          description: newDescription
        };
      }) || [],
    [activities]
  );
  const tableProps = {
    columns: [
      {
        title: "Date",
        align: "left",
        field: "generatedOn",
        sorting: true,
        width: null,
        cellStyle: { whiteSpace: "nowrap", paddingRight: 0, width: 200 }
      },
      {
        title: "Activity Type",
        align: "left",
        field: "type",
        width: null,
        cellStyle: { whiteSpace: "nowrap", paddingRight: 0, width: 250 }
      },
      {
        title: "User",
        align: "left",
        field: "user",
        width: null,
        cellStyle: { paddingRight: 0, width: 250 }
      },
      {
        title: "Description",
        align: "left",
        field: "description",
        hidden: isWidthDown("lg", props?.width),
        cellStyle: { paddingRight: 0 },
        render: r => <HtmlParser value={r.description} />
      }
    ],
    hidePaging: activities.length <= 50,
    data: vActivities,
    options: { pageSize: 50, exportFileName: setExportTitleFileName(`${props?.claimNumber}_Activity`) },
    detailPanel: isWidthDown("lg", props?.width) ? customDetailPanel : undefined
  };
  return (
    <Paper className={classes.root}>
      <MaterialTable {...tableProps} />
    </Paper>
  );
};

ClaimActivity.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  claimNumber: PropTypes.string
};

ClaimActivity.defaultProps = {
  claimNumber: ``
};

export default withWidth()(ClaimActivity);
