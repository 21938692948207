import { getAllActions, getActions, setActions, delActions, postActions } from "./common";

const getSetActions = path => ({ ...getActions(path), ...setActions(path) });

export default {
  ...getAllActions("INITIAL_REPORT"),
  ...getActions("INITIAL_REPORT_SECPROF_FILTER"),
  CLAIM: {
    ...getSetActions("INITIAL_REPORT_CLAIM")
  },
  INFORMATION: {
    BODILYINJURY: getSetActions("INITIAL_REPORT_INFORMATION_BODILYINJURY"),
    PROPERTY: getSetActions("INITIAL_REPORT_INFORMATION_PROPERTY"),
    CLAIMANT: {
      ...getSetActions("INITIAL_REPORT_INFORMATION_CLAIMANT")
    },
    DAMAGEDVEHICLE: {
      ...getSetActions("INITIAL_REPORT_INFORMATION_DAMAGEDVEHICLE")
    },
    INSUREDVEHICLE: getSetActions("INITIAL_REPORT_INFORMATION_INSUREDVEHICLE"),
    REPORTOFLOSS: {
      ...getActions("INITIAL_REPORT_INFORMATION_REPORTOFLOSS")
    },
    WITNESS: {
      ...getAllActions("INITIAL_REPORT_INFORMATION_WITNESS"),
      ...postActions("INITIAL_REPORT_INFORMATION_WITNESS"),
      ...delActions("INITIAL_REPORT_INFORMATION_WITNESS"),
      ...getSetActions("INITIAL_REPORT_INFORMATION_WITNESS")
    },
    INSURED: getSetActions("INITIAL_REPORT_INFORMATION_INSURED")
  },
  ACTIVITY: getActions("INITIAL_REPORT_ACTIVITY"),
  GET_SECPROF_FILTER: "INITIAL_REPORT_GET_SECPROF_FILTER",
  GET_SECPROF_FILTER_SUCCESS: "INITIAL_REPORT_GET_SECPROF_FILTER_SUCCESS",
  CLEAR: "INITIAL_REPORT_CLEAR",
  MAKE_CLAIM: "INITIAL_REPORT_MAKE_CLAIM",
  MAKE_CLAIM_SUCCESS: "INITIAL_REPORT_MAKE_CLAIM_SUCCESS"
};
