import React from "react";
import PropTypes from "prop-types";
import Form from "./BillForm";

const Add = ({ billType, ...other }) => {
  const data = billType?.id ? { typeId: billType?.id, type: billType?.name } : {};
  return <Form {...{ data }} {...other} />;
};
Add.propTypes = { billType: PropTypes.shape({}) };
Add.defaultProps = { billType: {} };

export default Add;
