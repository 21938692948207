import PropTypes from "prop-types";
import React, { memo, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { BaseInputText as InputText, BaseSelectInput as SelectInput } from "../../../../common/inputs";
import FileIcon from "../../../../common/documents/FileIcon";

const DocumentRow = memo(({ doc, index, actions, folders, validationMessages }) => {
  const {
    id,
    description,
    folderId,
    file: { name, type }
  } = doc;
  const { onChange, onDelete, handleValidation } = actions;
  useEffect(() => {
    if (folderId && Object.keys(validationMessages || {}).length > 0) handleValidation();
  }, [folderId]);
  return (
    <tr>
      <td>
        <FileIcon file {...{ type }} />
      </td>
      <td>{name.substr(0, name.lastIndexOf("."))}</td>
      <td>
        <InputText name={`${id}.description`} value={description} placeholder="Description" onChange={onChange} style={{ marginBottom: 0 }} />
      </td>
      <td>
        <SelectInput
          name={`${id}.folderId`}
          value={folderId}
          placeholder="Folder *"
          onChange={onChange}
          options={folders}
          error={(validationMessages || {})[`documents[${index}].folderId`]}
        />
      </td>
      <td>
        <IconButton size="small" onClick={onDelete(id)}>
          <Delete />
        </IconButton>
      </td>
    </tr>
  );
});

DocumentRow.propTypes = {
  doc: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
  validationMessages: PropTypes.objectOf(PropTypes.string)
};

DocumentRow.defaultProps = {
  validationMessages: undefined
};

export default DocumentRow;
