import React, { useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CollapsibleBox, { useCollapsibleBox } from "../../common/CollapsibleBox";
import EmploymentInfo from "../../common/claims/employment/ViewMode";
import EmploymentForm from "../../common/claims/employment/Form";
import { set } from "../../../redux/actions/claim/info/employment";
import ActionsAllowedContext from "../../common/contexts/ActionsAllowed";
import { useRouteParam } from "../../../common";

export default props => {
  const claimId = useRouteParam("claimId");
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "info") || {};
  const {
    props: { boxProps, editMode, Loading, loading },
    actions: { hideLoading, showLoading, onSuccessEdit, onToggleEdit }
  } = useCollapsibleBox(access.update);
  const employment = useSelector(store => store.claim.wcClaim.employment, shallowEqual);

  const dispatch = useDispatch();
  const handleSubmit = data => {
    showLoading();
    dispatch(set(data, onSuccessEdit, hideLoading));
  };
  return (
    <CollapsibleBox title="Employment" name="employment" {...boxProps} {...props}>
      {Loading}
      {(editMode && access.update && (
        <EmploymentForm {...{ employment, claimId, onClose: onToggleEdit, onSave: handleSubmit, isLoading: loading }} />
      )) || <EmploymentInfo {...{ employment }} />}
    </CollapsibleBox>
  );
};
