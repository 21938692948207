/*eslint-disable */
import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Button, Paper, Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Loading from "../../components/common/Loading";
import MaterialTable from "../../components/common/table/MaterialTable";
import { loadVendors, delVendor } from "../../redux/actions/vendor";
import { getUserAccess, setExportTitleFileName } from "../../common";
import useCustomConfirm from "../../components/common/customHooks/useCustomConfirm";
import ConfirmNotAllowed from "../../components/common/Confirm";
import { icon } from "../../components/common/index.styles";

const List = () => {
  const store = useSelector(({ profile, vendor }) => ({ profile, vendor }), shallowEqual);

  const {
    profile: { securityProfilesDetails: secProfs },
    vendor: { vendors }
  } = store || {};
  const [addVendor, setAddVendor] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const hideLoading = () => {
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(loadVendors(hideLoading, hideLoading));
  }, []);
  const access = getUserAccess(secProfs, "VENDORS.LIST", null, null, null);

  const handleDelete = rowData => {
    dispatch(delVendor(rowData.id));
  };
  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handleDelete, "Cancel", "Yes, Delete", false);
  const handleConfirmDelete = data => {
    const vendorName = data.isPerson ? data.firstName.concat(" ").concat(data.lastName) : data.name;
    handleCustomConfirm({
      oData: data,
      titleText: `Delete Vendor`,
      messageText: `The following vendor is going to be deleted: ${vendorName} . Are you sure you want to proceed?`
    });
  };
  const classIcon = icon();
  const [open, setOpen] = useState(false);
  const onToggleConfirm = () => {
    setOpen(prev => !prev);
  };
  const [notAllowedMessage, setNotAllowedMessage] = useState("");

  const deleteOpt = {
    icon: () => <DeleteIcon className={classIcon.table} />,
    tooltip: "Delete Vendor",
    onClick: (_event, rowData) => {
      if (getUserAccess(secProfs, "VENDORS.LIST", null, rowData.stateId, null).delete) {
        handleConfirmDelete(rowData);
      } else {
        const vendorName = rowData.isPerson ? rowData.firstName.concat(" ").concat(rowData.lastName) : rowData.name;
        setNotAllowedMessage(`You don't have permissions for to delete the following vendor:  ${vendorName}`);
        onToggleConfirm(!open);
      }
    }
  };

  const getButtonToolBar = () => {
    if (!access.create) return null;
    return (
      <Grid item className="tc-pl1">
        <Button variant="contained" color="primary" onClick={() => setAddVendor(!addVendor)}>
          Add Vendor
        </Button>
      </Grid>
    );
  };

  const tableProps = {
    columns: [
      {
        title: "Number",
        field: "number",
        render: r => <Link to={`/vendor/${r.id}`}>{r.number}</Link>,
        width: null,
        cellStyle: { width: 1 },
        headerStyle: { paddingRight: 0 }
      },
      {
        title: "Name",
        field: "name",
        render: r => <Link to={`/vendor/${r.id}`}>{r.name}</Link>
      },
      { title: "Type", field: "type" },
      { title: "Category", field: "category" },
      { title: "Address", field: "address1" },
      {
        title: "City",
        field: "city",
        customSort: (a, b) => (a.city || "").localeCompare(b.city || "")
      },
      { title: "State", field: "state" }
    ],
    data: vendors,
    hidePaging: vendors.length <= 50,
    options: { pageSize: 50, exportFileName: setExportTitleFileName(`VendorsList`), filtering: true },
    actions: [...(access.delete ? [deleteOpt] : [])],
    ButtonToolBar: getButtonToolBar
  };

  return (
    <Fragment key="clientListWrapper">
      {loading && <Loading float />}
      <div className="tc-page-content">
        {addVendor && access.create === true && <Redirect to="/vendor" />}
        <Paper className="tc-p2">
          <MaterialTable {...tableProps} />
        </Paper>
        <Confirm />
        <ConfirmNotAllowed
          messageText={notAllowedMessage}
          title="Delete Vendor"
          okText="Close"
          hideCancel
          onToggleConfirm={onToggleConfirm}
          openDialog={open}
          onAction={onToggleConfirm}
        />
      </div>
    </Fragment>
  );
};
export default List;
