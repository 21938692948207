import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { SelectInput, InputText, PhoneInput } from "../inputs";
import { PropertyContext } from "./PropertyContext";

const Mortgage = ({ validationMessages }) => {
  const { state, dispatch } = useContext(PropertyContext);
  const { states } = useSelector(store => store.common);
  const disabled = state.isPropertyLiability;

  const iProps = (pre, name, label, src, type) => ({
    id: `dmg-prop-${pre}-${name}`,
    name,
    label,
    disabled,
    value: (src || {})[name],
    onChange: ({ target: { value } }) => dispatch({ type, payload: { name, value } }),
    error: (validationMessages || {})[name]
  });

  const mtgProps = (name, label) => iProps("mtg", name, label, state.property?.mortgageCompany, "SET_MORTGAGE_COMPANY");
  const locProps = (name, label) => iProps("mtg-loc", name, label, state.property?.mortgageCompany?.location, "SET_MORTGAGE_COMPANY_LOCATION");

  return (
    <Fragment key="mortgage-form-component">
      <Grid container spacing={4}>
        <Grid item xs>
          <InputText {...mtgProps("name", "Mortgage Company Name")} />
          <InputText {...locProps("address1", "Address 1")} />
          <InputText {...locProps("address2", "Address 2")} />
        </Grid>
        <Grid item xs>
          <InputText {...locProps("city", "City")} />
          <SelectInput {...locProps("stateId", "State")} options={states} />
          <InputText {...locProps("zipCode", "Zip")} />
        </Grid>
        <Grid item xs>
          <PhoneInput {...mtgProps("phone", "Mortgage Company Phone Number")} />
          <InputText {...mtgProps("email", "Mortgage Company Email")} />
          <InputText {...mtgProps("mainContactName", "Mortgage Company Main Contact")} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

Mortgage.propTypes = { validationMessages: PropTypes.shape({}).isRequired };

export default Mortgage;
