import React, { useState, useEffect, useContext, Fragment } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import CollapsibleBox from "../../../common/CollapsibleBox";
import useOnToggleBoxes from "../../../common/customHooks/useHandleBox";
import useStyles from "./index.styles";
import DocumentInformation from "./DocumentInformation";
import PolicySidePanel from "./PolicySidePanel";
import { get, setClaimPolicyAsActive } from "../../../../redux/actions/claim/coverage";
import useCustomConfirm from "../../../common/customHooks/useCustomConfirm";
import ActionsAllowedContext from "../../../common/contexts/ActionsAllowed";
import { useRouteParam } from "../../../../common";
import { useLoadingContext } from "../../../common/Loading";
import NoRecords from "../../../common/NoRecords";

export default () => {
  const claimId = useRouteParam("claimId");
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    actions: { hideLoading, showLoading, handleLoading }
  } = useLoadingContext();
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const hideFirstLoading = () => setIsFirstLoading(false);
  const {
    open: { coverage: open },
    onToggle
  } = useOnToggleBoxes({ initialValue: { coverage: true } });
  const [openPolicyPanel, onTogglePolicyPanel] = useState(false);
  const store = useSelector(({ claim: { policy, claim } }) => ({ policy, claim }), shallowEqual);
  const { policy, claim: claimInfo } = store;
  const routesAllowed = useContext(ActionsAllowedContext);
  const access = routesAllowed.find(elem => elem.route === "coverage");
  useEffect(() => {
    const accessToOverview = routesAllowed.find(elem => elem.route === "overview");
    if (access.read && ((isFirstLoading && !policy?.id && !accessToOverview.read) || (claimInfo?.id && claimInfo?.id !== claimId))) {
      dispatch(get(claimId, hideFirstLoading, hideFirstLoading));
    }
    if (policy.id > 0 || (accessToOverview.read && claimInfo.id === claimId)) hideFirstLoading();
  }, [claimInfo]);
  useEffect(() => {
    handleLoading(isFirstLoading);
  }, [isFirstLoading]);
  const handleTogglePolicyPanel = () => onTogglePolicyPanel(prev => !prev);
  const handlePolicyAsActive = policySelected => {
    showLoading();
    dispatch(
      setClaimPolicyAsActive(
        {
          claimLineOfBusinessId: claimInfo.claimLineOfBusinessId,
          insuredId: claimInfo.insuredId,
          claimId: claimInfo.id,
          policyId: policySelected.id
        },
        hideLoading
      )
    );
  };

  const { handleCustomConfirm, ConfirmComponent: Confirm } = useCustomConfirm(handlePolicyAsActive, "Cancel", "Yes, Apply policy");

  const handleConfirmSelectedPolicy = policySelected => {
    handleCustomConfirm({
      oData: policySelected,
      titleText: `Policy Selection`,
      messageText: (
        <>
          <p>The following policy will be applied to the claim:</p>
          <p>
            <b>{`${policySelected.policyType}: ${policySelected.number}.`}</b>
          </p>
          {claimInfo.claimLineOfBusinessId === 3 && !policySelected.containsDamagedProperty && (
            <p className="tc-color-red">
              <b>This policy does not cover the selected Damaged Property.</b>
            </p>
          )}
          <p>Are you sure you want to proceed?</p>
        </>
      )
    });
  };

  const header = open && access.update && (
    <Grid item>
      <Button size="small" variant="contained" color="primary" onClick={handleTogglePolicyPanel}>
        Select Policy
      </Button>
    </Grid>
  );
  return (
    <Fragment key="claim-coverage-page">
      <CollapsibleBox title="Policy/ Certificate" name="coverage" {...{ header, open, onToggle }}>
        {(policy?.id > 0 && <DocumentInformation policy={policy} classes={classes} />) || <NoRecords isPaper={false} />}
      </CollapsibleBox>
      <PolicySidePanel openPanel={openPolicyPanel && access.update} onTogglePanel={handleTogglePolicyPanel} onSave={handleConfirmSelectedPolicy} />
      <Confirm />
    </Fragment>
  );
};
