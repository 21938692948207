import { INITIALREPORT as REPORT, API } from "../../../actions/actionTypes";
import { getAllSuccess, postSuccess, setSuccess, delSuccess } from "../../../actions/initialReport/witness";
import { takeLatest, takeLatestDel, takeLatestPost, takeLatestSet } from "../../common/sagaGenerator";

const url = `${API.URL}/witness`;

export default [
  takeLatest({
    type: REPORT.INFORMATION.WITNESS.GETALL,
    actionSuccess: getAllSuccess,
    params: ({ claimId }) => ({ claimId }),
    errorMessage: "Error loading Witnesses",
    url: `${url}/witnesses`
  }),
  takeLatestPost({
    type: REPORT.INFORMATION.WITNESS.POST,
    actionSuccess: postSuccess,
    params: ({ witness }) => witness,
    url,
    errorMessage: "Unable to add witness",
    successMessage: "Witness successfully saved"
  }),
  takeLatestSet({
    type: REPORT.INFORMATION.WITNESS.SET,
    actionSuccess: setSuccess,
    params: ({ witness }) => witness,
    url,
    successMessage: "Witness Updated",
    errorMessage: "Error updating Witness"
  }),
  takeLatestDel({
    type: REPORT.INFORMATION.WITNESS.DEL,
    actionSuccess: delSuccess,
    params: ({ id }) => ({ id }),
    successMessage: "Witness deleted",
    errorMessage: "Error deleting witness",
    url
  })
];
