import { CLIENTR as CLIENT } from "../../actionTypes";

export const getEmployees = (id, onSuccess, onError) => ({ type: CLIENT.INSURED.EMPLOYEES.GET, id, onSuccess, onError });

export const getEmployeesSuccess = employees => ({ type: CLIENT.INSURED.EMPLOYEES.GET_SUCCESS, employees });

export const addEmployee = (employee, onSuccess, onError) => ({ type: CLIENT.INSURED.EMPLOYEE.POST, employee, onSuccess, onError });

export const addEmployeeSuccess = employees => ({ type: CLIENT.INSURED.EMPLOYEE.POST_SUCCESS, employees });

export const editEmployee = (employee, onSuccess, onError) => ({ type: CLIENT.INSURED.EMPLOYEE.PUT, employee, onSuccess, onError });

export const editEmployeeSuccess = employees => ({ type: CLIENT.INSURED.EMPLOYEE.PUT_SUCCESS, employees });

export const deleteEmployee = (employeeId, clientId, onSuccess, onError) => ({
  type: CLIENT.INSURED.EMPLOYEE.DELETE,
  employeeId,
  clientId,
  onSuccess,
  onError
});

export const deleteEmployeeSuccess = employees => ({ type: CLIENT.INSURED.EMPLOYEE.DELETE_SUCCESS, employees });
