import { makeStyles } from "@material-ui/core";
import { colors } from "../index.styles";

export default makeStyles(theme => ({
  root: { position: "relative", padding: theme.spacing(2), borderRadius: 4, "& h4": { marginTop: 0 }, "& p": { marginTop: 0, marginBottom: 6 } },
  note: { backgroundColor: "rgba(0, 0, 0, 0.05)", marginBottom: theme.spacing(2) },
  response: { backgroundColor: "rgba(16, 122, 152, 0.15)" },
  title: { marginBottom: theme.spacing(1) },
  chip: { marginLeft: theme.spacing(2), fontWeight: "initial", textTransform: "uppercase" },
  date: { display: "block", textAlign: "right" },
  actions: { position: "absolute", top: 7, right: 7 },
  confidential: {
    margin: theme.spacing(0, -2, -2, -2),
    textAlign: "center",
    padding: 60,
    background: "rgba(117, 117, 117, 0.3)",
    borderRadius: "0 0 4px 4px",
    backdropFilter: "blur(47px)"
  },
  confidentialIcon: { color: "rgba(117, 117, 117, 0.44)", fontSize: 44 },
  actionIcons: { color: colors.color898F91 },
  list: {
    margin: theme.spacing(0, 0, 0, 4),
    listStyle: "none",
    padding: 0,
    "& li": {
      padding: theme.spacing(0, 0, 2, 4),
      position: "relative",
      borderLeft: "1px solid #BBBBBB",
      "&:after": {
        top: 0,
        left: 0,
        width: 32,
        height: 27,
        content: '""',
        display: "inline-block",
        position: "absolute",
        borderBottom: "1px solid #BBBBBB"
      }
    },
    "&:not(.withreplies) li": {
      "&:last-child": { borderLeft: "none" },
      "&:last-child:after": { borderLeft: "1px solid #BBBBBB" }
    }
  },
  paper: { padding: theme.spacing(2), marginBottom: theme.spacing(2) },
  saveDraft: { backgroundColor: "#DAEAEF", borderRadius: 4 }
}));
