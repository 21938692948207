import { Collapse, Paper } from "@material-ui/core";
import React from "react";
import { useContext } from "../context";
import Incident from "./incident";
import Claimant from "./claimant";
import AddClaimant from "./AddClaimant";
import DamagedVehicle from "./DamagedVehicle";
import DamagedProperty from "./damagedProperty";
import BodilyInjury from "./BodilyInjury";
import { useClaimantStyles } from "./index.styles";

export default () => {
  const classes = useClaimantStyles();
  const [{ multipleClaimants, coverageTypes, claimant: c, claimants }] = useContext();
  const anyLob = text => coverageTypes.some(x => x.lossTypeGroups.some(ltg => ltg.text === text && ltg.checked));
  const { damagedVehicle, damagedProperty, bodilyInjury } = {
    damagedVehicle: anyLob("Vehicle Damage"),
    damagedProperty: anyLob("Property Damage"),
    bodilyInjury: anyLob("Bodily Injury")
  };
  return (
    <>
      <Incident />
      {(multipleClaimants && (
        <React.Fragment key="claim-add-claimants">
          <AddClaimant />
          {claimants.map((claimant, index) => {
            return (
              <Paper className={classes.root} key={`claimant-${claimant.id}`}>
                <Claimant {...{ claimant, index, classes }} />
                <Collapse in={!claimant.closed} timeout="auto">
                  {damagedVehicle && <DamagedVehicle {...{ claimant, index }} />}
                  {damagedProperty && <DamagedProperty {...{ claimant, index }} />}
                  {bodilyInjury && <BodilyInjury {...{ claimant, index }} />}
                </Collapse>
              </Paper>
            );
          })}
        </React.Fragment>
      )) || (
        <React.Fragment key="claim-add-claimants">
          <Paper className={classes.root}>
            <Claimant claimant={c} {...{ classes }} />
          </Paper>
          {damagedVehicle && <DamagedVehicle />}
          {damagedProperty && <DamagedProperty />}
          {bodilyInjury && <BodilyInjury />}
        </React.Fragment>
      )}
    </>
  );
};
