import clsx from "clsx";
import React, { Fragment, useContext } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import BreadCrumbsNavigation from "../../components/common/breadCrumbs/BreadCrumbsNavigation";
import ActionsAllowedContext from "../../components/common/contexts/ActionsAllowed";
import { useLoadingContext } from "../../components/common/Loading";
import PageNotAllowed from "../../components/PageNotAllowed";
import Documents from "./Documents";
import Financial from "./Financial";
import Info from "./Info";
import Notes from "./Notes";

export const routes = [
  ["info", "Catastrophe Info", Info],
  ["financial", "Financial", Financial],
  ["notes", "Notes", Notes],
  ["documents", "Documents", Documents]
];

const Routes = props => {
  const { path } = useRouteMatch();
  const routesAllowed = useContext(ActionsAllowedContext);
  const parsedRoutes = routes.map(([key, , Component]) => ({
    key,
    path: `${path}/${key}`,
    Component,
    isAllowed: routesAllowed.some(({ route, read }) => route === key && read)
  }));
  const { breadcrumb, breadcrumbs, Loading, padding } = useLoadingContext();
  return (
    <Fragment key="route-fragment">
      {Loading}
      {routesAllowed.length > 0 && (
        <div className={clsx("tc-page-content", { "tc-no-padding": !padding })}>
          {breadcrumb && <BreadCrumbsNavigation min gutterBottom {...breadcrumbs} />}
          <Switch>
            {parsedRoutes.map(({ Component, isAllowed, ...other }) => (
              <Route {...other} render={() => (isAllowed && <Component {...props} />) || <PageNotAllowed />} />
            ))}
          </Switch>
        </div>
      )}
    </Fragment>
  );
};

export default Routes;
