import { ADMIN } from "../actions/actionTypes";
import initialState from "./initialState/admin";

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN.USER.GETALL_SUCCESS:
      return { ...state, user: { ...state.user, users: action.users } };
    case ADMIN.SECURITYPROFILE.GET_SUCCESS:
      return { ...state, user: { ...state.user, securityProfile: action.securityProfile } };
    case ADMIN.SECURITYPROFILE.RESET:
      return { ...state, user: { ...state.user, securityProfile: initialState.user.securityProfile } };
    case ADMIN.SECURITYPROFILE.GETALL_SUCCESS:
    case ADMIN.SECURITYPROFILE.DEL_SUCCESS:
      return { ...state, user: { ...state.user, securityProfiles: action.securityProfiles } };
    case ADMIN.NOTE_TYPE.GETALL_SUCCESS:
    case ADMIN.NOTE_TYPE.POST_SUCCESS:
    case ADMIN.NOTE_TYPE.SET_SUCCESS:
    case ADMIN.NOTE_TYPE.DEL_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, noteTypes: action.data } };
    case ADMIN.CLAIMSETTING.JURISDICTIONSTATE.GETALL_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, jurisdictionStates: action.jurisdictionStates } };
    case ADMIN.CLAIMSETTING.JURISDICTIONSTATE.POST_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, jurisdictionStates: action.jurisdictionStates } };
    case ADMIN.CLAIMSETTING.JURISDICTIONSTATE.SET_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, jurisdictionStates: action.jurisdictionStates } };
    case ADMIN.CLAIMSETTING.JURISDICTIONSTATE.DEL_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, jurisdictionStates: action.jurisdictionStates } };
    case ADMIN.CLAIMSETTING.LINEOFBUSINESS.GETALL_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, lineOfBusiness: action.lineOfBusiness } };
    case ADMIN.CLAIMSETTING.LINEOFBUSINESS.POST_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, lineOfBusiness: action.lineOfBusiness } };
    case ADMIN.CLAIMSETTING.LINEOFBUSINESS.SET_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, lineOfBusiness: action.lineOfBusiness } };
    case ADMIN.CLAIMSETTING.LINEOFBUSINESS.DEL_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, lineOfBusiness: action.lineOfBusiness } };
    case ADMIN.CLAIMSETTING.TIMELINEDATE.GETALL_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, timelineDates: action.timelineDates } };
    case ADMIN.CLAIMSETTING.TIMELINEDATE.SET_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, timelineDates: action.timelineDates } };
    case ADMIN.CLAIMSETTING.WIDGET.GETALL_SUCCESS:
    case ADMIN.CLAIMSETTING.WIDGET.SET_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, widgets: action.widgets } };
    case ADMIN.CLAIMSETTING.RESERVES.GETALL_SUCCESS:
    case ADMIN.CLAIMSETTING.RESERVES.POST_SUCCESS:
    case ADMIN.CLAIMSETTING.RESERVES.DEL_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, reserves: action.reserves } };
    case ADMIN.CLAIMSETTING.RESERVES.TYPES.POST_SUCCESS:
    case ADMIN.CLAIMSETTING.RESERVES.TYPES.GETALL_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, reserveTypes: action.reservetypes } };
    case ADMIN.CLAIMSETTING.DOCUMENTS.GETALL_SUCCESS:
    case ADMIN.CLAIMSETTING.DOCUMENTS.SET_SUCCESS:
    case ADMIN.CLAIMSETTING.DOCUMENTS.SETACTIVE_SUCCESS:
    case ADMIN.CLAIMSETTING.DOCUMENTS.DEL_SUCCESS:
      return { ...state, claimSettings: { ...state.claimSettings, documents: { ...action.documents, synced: true } } };
    case ADMIN.CLAIMSETTING.DOCUMENTS.GETACTIVE_SUCCESS:
      return {
        ...state,
        claimSettings: { ...state.claimSettings, documents: { ...state.claimSettings.documents, active: action.active, synced: true } }
      };
    case ADMIN.TEMPLATE_COMMUNICATION.GETALL_SUCCESS:
    case ADMIN.TEMPLATE_COMMUNICATION.POST_SUCCESS:
    case ADMIN.TEMPLATE_COMMUNICATION.SET_SUCCESS:
    case ADMIN.TEMPLATE_COMMUNICATION.DEL_SUCCESS: {
      if (Array.isArray(action.communications)) return { ...state, templates: { ...state.templates, communications: action.communications } };
      return state;
    }
    case ADMIN.TEMPLATE_DIARY.GETALL_SUCCESS:
    case ADMIN.TEMPLATE_DIARY.POST_SUCCESS:
    case ADMIN.TEMPLATE_DIARY.SET_SUCCESS:
    case ADMIN.TEMPLATE_DIARY.DEL_SUCCESS: {
      if (Array.isArray(action.diaries)) return { ...state, templates: { ...state.templates, diaries: action.diaries } };
      return state;
    }
    default:
      return state;
  }
};
