import React, { Fragment } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Grid, Typography } from "@material-ui/core";

import LabelValue from "../../../../common/LabelValue";
import { CheckboxLabel } from "../../../../common/inputs";

export default () => {
  const store = useSelector(
    ({
      claim: {
        claim: { id },
        trial
      },
      common: { veredictType, appealStatus, veredictStatus }
    }) => ({ id, trial, veredictType, appealStatus, veredictStatus }),
    shallowEqual
  );
  const { id, trial, veredictType, appealStatus, veredictStatus } = store;
  const { claimId, trialDate, veredictTypeId, veredictStatusId, appealed, appealDate, appealStatusId } = trial || {};

  return (
    (id === claimId && trial?.id !== undefined && (
      <Fragment key="readMode">
        <Grid container spacing={4}>
          <Grid item xs>
            <CheckboxLabel label="Trial Notice Received" checked={!!trial?.id} disabled />
            <LabelValue label="Trial Date" value={trialDate || undefined} isDate />
          </Grid>
          <Grid item xs>
            <LabelValue
              label="Veredict"
              value={veredictTypeId && veredictType.length > 0 ? veredictType.filter(v => v.id === veredictTypeId)[0].text : undefined}
            />
            <LabelValue label="Status" value={(veredictStatus || []).find(v => v.id === veredictStatusId)?.text} />
          </Grid>
          <Grid item xs>
            <CheckboxLabel label="Appealed" checked={appealed} disabled />
            <LabelValue label="Appealed Date Filed" value={appealDate || undefined} isDate />
            <LabelValue
              label="Status"
              value={appealStatusId && appealStatus.length > 0 ? appealStatus.filter(v => v.id === appealStatusId)[0].text : undefined}
            />
          </Grid>
        </Grid>
      </Fragment>
    )) || (
      <Typography className="tc-nodata" gutterBottom>
        No Trial Information
      </Typography>
    )
  );
};
