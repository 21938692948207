import { CLIENTR as CLIENT, API } from "../../../../actions/actionTypes";
import { getSuccess, setSuccess } from "../../../../actions/client/insured/policy/aggregate";
import { takeLatest, takeLatestSet } from "../../../common/sagaGenerator";

export default [
  takeLatest({
    type: CLIENT.INSURED.POLICY.AGGREGATE.GET,
    actionSuccess: getSuccess,
    errorMessage: "Error loading aggregate details",
    url: `${API.URL}/policy/aggregate`,
    params: ({ policyId }) => ({ policyId })
  }),
  takeLatestSet({
    type: CLIENT.INSURED.POLICY.AGGREGATE.SET,
    actionSuccess: setSuccess,
    successMessage: "Aggregate Details successfully saved",
    errorMessage: "Unable to save Aggregate Details",
    url: `${API.URL}/policy/aggregate`,
    params: ({ aggregateDetails }) => aggregateDetails
  })
];
