import { CLIENTR as CLIENT } from "../../../actionTypes";

const getCodes = policyId => ({ type: CLIENT.INSURED.POLICY.CODE.GET, policyId });
const getCodesSuccess = codes => ({ type: CLIENT.INSURED.POLICY.CODE.GET_SUCCESS, codes });

const addPolicyCode = (policycode, onSuccess, onError) => ({ type: CLIENT.INSURED.POLICY.CODE.POST, policycode, onSuccess, onError });
const addCodeSuccess = codes => ({ type: CLIENT.INSURED.POLICY.CODE.POST_SUCCESS, codes });

const deletePolicyCode = policycode => ({ type: CLIENT.INSURED.POLICY.CODE.DEL, policycode });
const deleteCodeSuccess = codes => ({ type: CLIENT.INSURED.POLICY.CODE.DEL_SUCCESS, codes });

export { getCodes, getCodesSuccess, addPolicyCode, addCodeSuccess, deletePolicyCode, deleteCodeSuccess };
