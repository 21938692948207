import React, { useRef, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useContext, actionTypes } from "./context";
import { ClaimDuplicates, ClaimAddHeader, ClaimAddFooter } from "./layout";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import styles from "./index.styles";
import { getDocuments } from "../../../redux/actions/admin/claimsSetting";

export default () => {
  const classes = styles();
  const container = useRef(null);
  const {
    common: { coverageTypes, claimLossTypeGroups }
  } = useSelector(({ common }) => ({ common }), shallowEqual);
  const storeDispatch = useDispatch();
  const [{ step }, dispatch] = useContext();
  useEffect(() => {
    storeDispatch(getDocuments(true, ({ settings }) => dispatch({ type: actionTypes.SET_FOLDER_SETTINGS, settings })));
  }, []);
  useEffect(() => {
    if (coverageTypes.some(() => true)) dispatch({ type: actionTypes.SET_INIT_COVERAGE_TYPES, payload: { coverageTypes, claimLossTypeGroups } });
  }, [coverageTypes, claimLossTypeGroups]);
  return (
    <div className={classes.root}>
      <div ref={container} className={classes.container}>
        <ClaimAddHeader />
        <div className={classes.content}>
          {step === 0 && <Step1 />}
          {step === 1 && <Step2 />}
          {step === 2 && <Step3 />}
          {step === 3 && <Step4 />}
        </div>
      </div>
      <ClaimAddFooter {...{ container }} />
      <ClaimDuplicates />
    </div>
  );
};
