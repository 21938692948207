import { NOTIFICATION, API } from "../actions/actionTypes";
import rootSagaGenerator from "./common/rootSagaGenerator";
import { takeLatest, takeLatestSet } from "./common/sagaGenerator";
import { getSuccess, setReadSuccess } from "../actions/notification";

export default rootSagaGenerator({
  name: "notification",
  sagas: [
    takeLatest({
      type: NOTIFICATION.GET,
      actionSuccess: getSuccess,
      errorMessage: "Error loading Notifications",
      url: `${API.URL}/notification`
    }),
    takeLatestSet({
      type: NOTIFICATION.SET_READ,
      actionSuccess: setReadSuccess,
      successMessage: "Information successfully updated",
      errorMessage: "Unable to update the information",
      url: `${API.URL}/notification/updateread`,
      params: ({ id }) => ({ id })
    })
  ]
});
