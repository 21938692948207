import { Grid, makeStyles } from "@material-ui/core";
import { Link as LinkIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(theme => ({
  link: { marginBottom: -3, color: "#107A98" },
  rectangle: { backgroundColor: "#DAEAEF", borderRadius: 4, padding: theme.spacing(0.5, 1) }
}));

const LinkCount = ({ count, children, ...other }) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" spacing={1} wrap="nowrap" {...other}>
      {children}
      <Grid item>
        <LinkIcon className={classes.link} />
      </Grid>
      <Grid item>
        <div className={classes.rectangle}>{count}</div>
      </Grid>
    </Grid>
  );
};

LinkCount.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node
};

LinkCount.defaultProps = {
  count: 0,
  children: undefined
};

export default LinkCount;
