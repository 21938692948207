import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import { Paper, Grid, Typography } from "@material-ui/core";
import { useDiariesInfoStyles } from "./index.styles";

export default () => {
  const data = useSelector(({ claim }) => claim.diaries, shallowEqual);
  const classes = useDiariesInfoStyles();

  // eslint-disable-next-line
  const cmp = (x, y) => (x > y ? 1 : x < y ? -1 : 0);

  return (
    <Paper className={classes.root}>
      <div className={classes.title}>Upcoming Diaries</div>
      <div className={classes.diaries}>
        {data
          .filter(_x => _x.statusId !== 3)
          .sort((a, b) => {
            return cmp(
              [cmp(new Date(a.dueDate), new Date(b.dueDate)), cmp(a.statusId, b.statusId)],
              [cmp(new Date(b.dueDate), new Date(a.dueDate)), cmp(b.statusId, a.statusId)]
            );
          })
          .filter((_x, i) => i < 4)
          .map(({ id, name, dueDate, owner }, i) => (
            <div key={id}>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={2}>
                  <span className={classes.number}>{i + 1}</span>
                </Grid>
                <Grid item xs={10}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography noWrap className={classes.label}>
                        Name:
                      </Typography>
                    </Grid>
                    <Grid item className={classes.divOwner}>
                      {name}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography noWrap className={classes.label}>
                        Due Date:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography noWrap className={classes.label}>
                        Owner:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.divOwner}>
                      {moment(dueDate).format("M/D/YYYY")}
                    </Grid>
                    <Grid item xs={6} className={classes.divOwner}>
                      {owner || "-"}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ))}
      </div>
    </Paper>
  );
};
