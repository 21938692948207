import { makeStyles } from "@material-ui/core";

const ReplaceReserveStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(2)
  },
  gridContainer: { alignItems: "center", paddingBottom: theme.spacing(2) },
  title: { color: "#428302" },
  actions: {
    textAlign: "right",
    "& button:not(:last-of-type)": { marginRight: theme.spacing(1) }
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: theme.spacing(2),
    "& th": {
      textAlign: "left",
      padding: theme.spacing(2),
      borderBottom: "1px solid rgb(224, 224, 224)"
    },
    "& td": {
      padding: theme.spacing(2),
      borderBottom: "1px solid rgb(224, 224, 224)",
      "& div": {
        marginBottom: "0 !important"
      }
    }
  },
  money: {
    textAlign: "right"
  },
  total: {
    "& td": {
      color: "#004763",
      backgroundColor: "#DAEAEF",
      fontWeight: 500
    },
    inputWidth: {
      width: "140px"
    }
  },
  paddingLeft40: { paddingLeft: `${theme.spacing(5)}px !important` },
  titleRight: { color: "#000", textAlign: "right" },
  rowHead: {
    textAlign: "right",
    color: "#757575",
    padding: 0,
    fontSize: "0.875rem",
    fontFamily: "'Roboto', 'Helvetica', Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.00938em"
  },
  rowHeadLeft: {
    textAlign: "left",
    color: "#757575",
    padding: 0,
    fontSize: "0.875rem",
    fontFamily: "'Roboto', 'Helvetica', Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.00938em"
  }
}));

export default ReplaceReserveStyles;
