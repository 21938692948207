import React, { useState } from "react";
import PropTypes from "prop-types";
import { InputAdornment, FormControl, Input, InputLabel, CircularProgress } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import Suggestions from "./index";
import { HocInput } from "../inputs/HocInput";

const InputSuggestions = ({ classes, className, name, value, onChange, label, loading, onSelect, results }) => {
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setTimeout(() => setFocused(false), 300);
  const options = results.filter(x => Boolean(x[name])).map(x => ({ id: x.id, name: x[name] }));
  const endAdornment = <InputAdornment position="end">{loading ? <CircularProgress color="inherit" size={20} /> : <Search />}</InputAdornment>;
  const id = `search-${name}`;
  return (
    <FormControl fullWidth {...{ classes, className }}>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <Input {...{ name, value, onChange, onFocus, onBlur, endAdornment }} autoComplete="off" />
      {focused && <Suggestions {...{ onChange: onSelect, results: options }} />}
    </FormControl>
  );
};

InputSuggestions.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired
};

InputSuggestions.defaultProps = {
  label: null,
  loading: false
};

export default HocInput(InputSuggestions);
