import { toast } from "react-toastify";
import { API, ADMIN } from "../../../actions/actionTypes";
import { getAllWidgetSuccess, setWidgetSuccess } from "../../../actions/admin/claimsSetting";
import { takeLatest, takeLatestSet } from "../../common/sagaGenerator";

const url = `${API.URL}/claimsetting`;

export default [
  takeLatest({
    type: ADMIN.CLAIMSETTING.WIDGET.GETALL,
    url: `${url}/getWidgets`,
    actionSuccess: getAllWidgetSuccess,
    errorMessage: "Error getting widgets info."
  }),
  takeLatestSet({
    type: ADMIN.CLAIMSETTING.WIDGET.SET,
    url: `${url}/setWidget`,
    actionSuccess: setWidgetSuccess,
    onSuccess: ({ data }) => (data.isActive ? toast.success("Widget successfully activated") : toast.success("Widget successfully deactivated")),
    onError: ({ data }) => (data.isActive ? toast.success("Unable to activate the widget") : toast.success("Unable to deactivate the widget")),
    params: ({ data }) => data
  })
];
