import { AUTOMATION } from "../actionTypes";

const success = type => diaries => ({ type, diaries });

export const get = (id, onSuccess, onError) => ({ type: AUTOMATION.DIARIES.GET, id, onSuccess, onError });
export const getSuccess = success(AUTOMATION.DIARIES.GET_SUCCESS);

export const getAll = (onSuccess, onError) => ({ type: AUTOMATION.DIARIES.GETALL, onSuccess, onError });
export const getAllSuccess = success(AUTOMATION.DIARIES.GETALL_SUCCESS);

export const activate = (id, active, onSuccess, onError) => ({ type: AUTOMATION.DIARIES.ACTIVATE, id, active, onSuccess, onError });
export const activateSuccess = success(AUTOMATION.DIARIES.ACTIVATE_SUCCESS);

export const del = (id, onSuccess, onError) => ({ type: AUTOMATION.DIARIES.DEL, id, onSuccess, onError });
export const delSuccess = success(AUTOMATION.DIARIES.DEL_SUCCESS);

export const set = (data, onSuccess, onError) => ({ type: AUTOMATION.DIARIES.SET, data, onSuccess, onError });
export const setSuccess = success(AUTOMATION.DIARIES.SET_SUCCESS);
